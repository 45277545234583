/* eslint-disable no-param-reassign */
import {
  AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList,
} from '@angular/core';
import { StepComponent } from '../step/step.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  animations: [],
})
export class StepperComponent implements AfterContentInit {
  @Output() callBackSubmit = new EventEmitter<any>();
  @Input() loading! : boolean;

  @ContentChildren(StepComponent) public stepList?: QueryList<StepComponent>;

  public currentStepIndex: number = 0;
  public steps: StepComponent[] = [];
  public isNextStep: boolean = false;
  public isPreviousStep: boolean = false;

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.steps = this.stepList?.toArray() || [];
      this.handleStepClass();
    });
  }

  handleStepClass() {
    this.steps!.forEach((step: StepComponent, index: number) => {
      if (index < this.currentStepIndex) {
        step.type = 'previous';
      } else if (index === this.currentStepIndex) {
        step.type = 'current';
      } else if (index > this.currentStepIndex) {
        step.type = 'next';
      }
    });
  }

  passNextStep() {
    this.currentStepIndex += 1;
    this.isPreviousStep = false;
    this.isNextStep = true;
    this.handleStepClass();
  }

  backPreviousStep() {
    this.currentStepIndex -= 1;
    this.isNextStep = false;
    this.isPreviousStep = true;
    this.handleStepClass();
  }

  isLastStep() {
    return this.currentStepIndex === (this.steps.length - 1);
  }

  isFirstStep() {
    return this.currentStepIndex === 0;
  }

  onSubmit() {
    this.callBackSubmit.emit();
  }

  onClickHandler() {
    if (this.steps[this.currentStepIndex].valid) {
      if (this.isLastStep()) {
        this.onSubmit();
      } else {
        this.passNextStep();
      }
    }
  }
}
