<article (click)="handleDataClick(unit.unitId)">
  <aside>
    <span>{{ util.addLeadingZeros(unit.unitId, 5) }}</span>
  </aside>
  <main>
    <header>
      <strong>{{ unit.name }}</strong>
      <app-status
        [color]="
          unit.active ? StatusColorEnum.success : StatusColorEnum.warning
        "
        [image]="
          unit.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="unit.active ? 'Ativo' : 'Inativo'"
      ></app-status>
    </header>
  </main>
</article>
