<div class="consumption-tab consumption-container">
  <div class="consumption-card">
    <app-card
      title="Custo Variável com Consumo"
      icon="./assets/icons/velocimeter-primary.svg"
      [accordion]="true"
      [paddingHorizontal]="isLoading"
    >
      <app-skeleton-line *ngIf="isLoading" [line]="6" body></app-skeleton-line>
      <section
        *ngIf="!isMobile && !isLoading"
        class="consumption-tab__table desktop"
        body
      >
        <p>Informar o consumo inicial do Cavalo Mecânico em KM/L.</p>
        <header class="consumption-tab__table-header">
          <span>ANO DO CONTRATO</span>
          <span>ANO SAFRA</span>
          <span>CONSUMO</span>
        </header>
        <div class="consumption-tab__table-line">
          <span>
            <b> {{ consumptionList[0].contractYear }}º </b>
          </span>
          <span>{{ consumptionList[0].harvestYear }}</span>
          <span>
            <app-input
              [showTitle]="false"
              [height]="'2.3rem'"
              placeholder="0,00"
              [errorMessage]="errorMessage"
              [control]="consumptionForm.controls.tractorConsumption"
              [mask]="eMaskType.money"
              [showSideIcon]="true"
              sideIconLabel=",00"
              [sideIconType]="eSideIconType.text"
              [enableMessageError]="false"
              [disabled]="shouldDisableFields"
              (updateValue)="handleUpdateValue()"
            ></app-input>
          </span>
        </div>
        <div
          *ngFor="let item of consumptionListNext"
          class="consumption-tab__table-line"
        >
          <span>
            <b> {{ item.contractYear }}º </b>
          </span>
          <span>{{ item.harvestYear }}</span>
          <span>{{ item.tractorConsumption }}</span>
        </div>
      </section>
      <section
        *ngIf="isMobile && !isLoading"
        class="consumption-tab__table mobile"
        body
      >
        <p>Informar o consumo inicial do Cavalo Mecânico em KM/L.</p>
        <div class="consumption-tab__table-card">
          <div class="consumption-tab__table-title">
            <b>{{ consumptionList[0].contractYear }}º ano de contrato</b>
          </div>
          <div class="consumption-tab__table-values">
            <span>ANO SAFRA:</span>
            <span>{{ consumptionList[0].harvestYear }}</span>
          </div>
          <div class="consumption-tab__table-values">
            <span>CONSUMO:</span>
            <span>
              <app-input
                [showTitle]="false"
                [height]="'2.3rem'"
                placeholder="0,00"
                [errorMessage]="errorMessage"
                [control]="consumptionForm.controls.tractorConsumption"
                [mask]="eMaskType.money"
                [showSideIcon]="true"
                sideIconLabel=",00"
                [sideIconType]="eSideIconType.text"
                [enableMessageError]="false"
                [disabled]="shouldDisableFields"
                (updateValue)="handleUpdateValue()"
              ></app-input>
            </span>
          </div>
        </div>
        <div
          *ngFor="let item of consumptionListNext"
          class="consumption-tab__table-card"
        >
          <div class="consumption-tab__table-title">
            <b>{{ item.contractYear }}º ano de contrato</b>
          </div>
          <div class="consumption-tab__table-values">
            <span>ANO SAFRA:</span>
            <span>{{ item.harvestYear }}</span>
          </div>
          <div class="consumption-tab__table-values">
            <span>CONSUMO:</span>
            <span>{{ item.tractorConsumption }}</span>
          </div>
        </div>
      </section>
    </app-card>
  </div>
</div>
