<div class="base">
  <div class="container">
    <app-card
      [paddingHorizontal]="false"
      title="Cadastros no PIMS"
      icon="../../../../assets/icons/solicitation-primary-600.svg"
    >
      <div class="content" body>
        <div class="input-container">
          <app-input
            [control]="searchControl.controls.search"
            [label]="'Empresa PIMS'"
            [placeholder]="'Nome, CNPJ ou Código do Transportador'"
            [errorMessage]="'Digite no minímo três caracteres para pesquisar'"
            [search]="true"
            [loading]="searchLoading"
            (searchClick)="searchCompanies()"
          ></app-input>

          <app-primary-button
            [label]="'Salvar'"
            (clickButton)="save()"
            [loading]="saveLoading"
            [enabled]="true"
          ></app-primary-button>
        </div>
        <div class="data" *ngIf="companiesList.length > 0">
          <div class="header">
            <app-check-box
              (check)="checkAll()"
              (removeCheck)="removeAllChecks()"
            ></app-check-box>
            <span>CD_TRANSP</span>
            <span>CNPJ</span>
            <span>DESCRIÇÃO</span>
          </div>
          <div
            class="rows"
            *ngFor="let item of companiesList; let i = index"
            [ngClass]="item.checked ? 'checked' : ''"
          >
            <app-check-box
              (check)="onCheck(item)"
              (removeCheck)="onRemoveCheck(item)"
              [value]="item.checked"
            ></app-check-box>
            <span>{{ item.transporterId }}</span>
            <span>{{ item.transporterCnpj | cnpj }}</span>
            <span>{{ item.transporterName }}</span>
          </div>
        </div>
        <div class="data-card">
          <div
            class="card"
            *ngFor="let item of companiesList; let i = index"
            [ngClass]="item.checked ? 'checked' : ''"
          >
            <div class="id">
              <span>{{ item.transporterId }}</span>
            </div>
            <div class="items">
              <div><span>CNPJ: </span> {{ item.transporterCnpj | cnpj }}</div>
              <div><span>NOME: </span>{{ item.transporterName }}</div>
            </div>
            <app-check-box
              (check)="onCheck(item)"
              (removeCheck)="onRemoveCheck(item)"
              [value]="item.checked"
            ></app-check-box>
          </div>
        </div>
      </div>
    </app-card>
    <div class="return">
      <app-secondary-button
        [label]="'Voltar'"
        (clickButton)="return()"
        [enabled]="true"
        [icon]="'../../../../../../assets/icons/arrow-previous-primary-600.svg'"
      ></app-secondary-button>
    </div>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
