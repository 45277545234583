export class FleetCapitalModel {
  [key: string]: any;

  tractorDepreciation!: number;
  trailerDepreciation!: number;
  tractorCapitalRemuneration!: number;
  trailerCapitalRemuneration!: number;
  tractorCapitalRemunerationOnTaxes!: number;
  trailerCapitalRemunerationOnTaxes!: number;
  capitalRemunerationOnTaxes!: number;
  totalRemuneration!: number;
}
