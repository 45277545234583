<div class="info" [ngClass]="tooltipDirection">
  <label class="info-title">
    <span class="info-text" innerHTML="{{ infoText }}"></span>
    <app-tooltip
      *ngIf="tooltipText !== null"
      [top]="tooltipDirection === 'top'"
      [bottom]="tooltipDirection === 'bottom'"
    >
      <div class="tooltip-info">
        <div class="text" innerHTML="{{ tooltipText }}"></div>
      </div>
    </app-tooltip>
  </label>
  <label class="info-value info-total" innerHTML="{{ displayValue }}"></label>
</div>
