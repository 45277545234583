import { StatusColorEnum } from './../../../../../shared/enums/StatusColor';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { GetUnitCategoryModel } from 'src/app/services/unit/models/get-unit-category-model';

@Component({
  selector: 'app-unit-category-detail',
  templateUrl: './unit-category-detail.component.html',
  styleUrls: ['./unit-category-detail.component.scss'],
})
export class UnitCategoryDetailComponent {
  @Input() unitCategories: GetUnitCategoryModel[] = [];
  @Input() unitId: string = '';

  public StatusColorEnum = StatusColorEnum;
  constructor(public util: Utils, public router: Router) {}

  navigate(categoryId: number) {
    this.router.navigate([
      `admin/unit/${this.unitId}/category/${categoryId.toString()}`,
    ]);
  }
}
