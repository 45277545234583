export class OperationalTeamCalcDriverModel {
  paycheckWithCharges: number = 0;
  paycheck: number = 0;
  salaryWithAgreement: number = 0;
  inItinereHours: number = 0;
  inItinereCost: number = 0;
  nightAdditional: number = 0;
  dsr: number = 0;
  paycheckTotal: number = 0;
  provisionsCharge: number = 0;
  totalCharge: number = 0;
  toxicologicalExam: number = 0;
  totalToxicologicalExam: number = 0;
  foodTicket: number = 0;
  mealTicket: number = 0;
  basicBasket: number = 0;
  transportationVoucher: number = 0;
  healthCare: number = 0;
  lifeInsurance: number = 0;
  pcmso: number = 0;
  ppra: number = 0;
  plr: number = 0;
  totalBenefit: number = 0;
  driverHoursCost: number = 0;
  quantitativeHours: number = 0;
  qualitativeHours: number = 0;
  totalRemunerationWithoutCharges: number = 0;
  totalRemunerationWithCharges: number = 0;
}
