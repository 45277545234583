<app-primary-button
  *ngIf="type === eButtonsType.primary"
  [label]="label"
  [enabled]="enabled"
  [icon]="icon"
  [right]="right"
  (clickButton)="onClick($event)"
  [color]="color"
  [loading]="loading"
  [animation]="animation"
  [progress]="progress"
  [progressValue]="progressValue"
></app-primary-button>

<app-secondary-button
  *ngIf="type === eButtonsType.secondary"
  [label]="label"
  [enabled]="enabled"
  [icon]="icon"
  [right]="right"
  [loading]="loading"
  [animation]="animation"
  (clickButton)="onClick($event)"
></app-secondary-button>

<app-navigate-button
  *ngIf="type === eButtonsType.navigate"
  [label]="label"
  [enabled]="enabled"
  [icon]="icon"
  [right]="right"
  (clickButton)="onClick($event)"
></app-navigate-button>
