import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ButtonColorEnum } from '../../../shared/buttons/enums/button-color.enum';
import { SolicitationJustify } from './model/solicitation-justify.model';

@Component({
  selector: 'app-solicitation-approval-modal',
  templateUrl: './solicitation-approval-modal.component.html',
  styleUrls: ['./solicitation-approval-modal.component.scss'],
})
export class SolicitationApprovalModalComponent implements OnInit, OnChanges {
  @Input() showModal: boolean = false;
  @Input() required: boolean = false;
  @Input() title: string = '';
  @Input() buttonLabel: string = '';
  @Input() buttonImage: string = '';
  @Input() buttonType!: ButtonsType;
  @Input() approval: boolean = true;
  @Input() buttonColor: ButtonColorEnum = ButtonColorEnum.primary;

  @Output() clickButton: EventEmitter<SolicitationJustify> = new EventEmitter();

  public eButtonsType = ButtonsType;
  public eButtonColor = ButtonColorEnum;
  public enableButton: boolean = false;
  public description: string = '';
  public sending: boolean = false;

  public form = this.formBuilder.group({
    justify: [''],
  });

  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.buildAppropriateModal();

    this.form.get('justify')?.valueChanges.subscribe((value) => {
      if (this.required && value === '') {
        this.enableButton = false;
      } else {
        this.enableButton = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { required } = changes;

    if (required) {
      this.required = required.currentValue;
      this.buildAppropriateModal();
    }
  }

  buildAppropriateModal() {
    if (this.required) {
      this.enableButton = false;
      this.description = '';
      setTimeout(() => {
        this.form.controls.justify.addValidators(Validators.required);
      }, 0);
    } else {
      this.enableButton = true;
      this.description = '(opcional)';
      this.form.controls.justify.removeValidators(Validators.required);
    }
  }

  onClick(cancel: boolean) {
    this.sending = true;

    if (this.form.controls.justify.value === '' && !cancel && this.required) {
      this.form.controls.justify.setErrors(Validators.required);
      this.sending = false;
      return;
    }

    const obj = new SolicitationJustify();

    if (cancel) {
      obj.approved = false;
      obj.disapproved = false;
      obj.justification = null;
      this.sending = false;
    } else {
      obj.approved = this.approval;
      obj.disapproved = !obj.approved;
      obj.justification = this.form.controls.justify.value;
    }

    this.clickButton.emit(obj);
    this.showModal = false;
    this.sending = false;
    this.form.controls.justify.patchValue('');
    this.form.controls.justify.setErrors(null);
  }
}
