export enum MaskTypeEnum {
  cpf = 'cpf',
  cnpj = 'cnpj',
  cep = 'cep',
  money = 'money',
  phone = 'phone',
  number = 'number',
  username = 'username',
  decimal = 'decimal',
  monthYear = 'monthYear',
}
