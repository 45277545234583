import { UpdateSupportTeamDetailModel } from './update-support-team-detail.model';

export class UpdateMaintenenceAndWarehouseModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.maintenanceSupervisor =new UpdateSupportTeamDetailModel();
    this.warehouseAssistant =new UpdateSupportTeamDetailModel();
    this.storekeeperAssistant =new UpdateSupportTeamDetailModel();
    this.nightStorekeeperAssistant = new UpdateSupportTeamDetailModel();
    this.electrician = new UpdateSupportTeamDetailModel();
    this.nightElectrician = new UpdateSupportTeamDetailModel();
    this.mechanic = new UpdateSupportTeamDetailModel();
    this.nightMechanic = new UpdateSupportTeamDetailModel();
    this.tireRepairman = new UpdateSupportTeamDetailModel();
    this.nightTireRepairman = new UpdateSupportTeamDetailModel();
    this.welder = new UpdateSupportTeamDetailModel();
    this.mechanicalAssistant = new UpdateSupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  maintenanceSupervisor: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  warehouseAssistant: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  storekeeperAssistant: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  nightStorekeeperAssistant: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  electrician: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  nightElectrician: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  mechanic: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  nightMechanic: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  tireRepairman: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  nightTireRepairman: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  welder: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  mechanicalAssistant: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
}
