<div class="container">
  <section>
    <header>
      <app-primary-button
        label="NOVA LANÇAMENTO"
        icon="../../../../assets/icons/add-common-100.svg"
        [color]="ButtonColorEnum.primary"
        [right]="false"
        [loading]="fixedCostLoading"
        [enabled]="true"
        (click)="CreateFixedCost()"
      ></app-primary-button>
    </header>
    <app-fixed-costs-filter
    #filter
    (filterEvent)="getFixedCosts($event)"></app-fixed-costs-filter>
    <app-fixed-costs-list
    #list
    [fixedCosts]="fixedCosts"></app-fixed-costs-list>
  </section>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
