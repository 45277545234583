<div class="card-content" [ngClass]="{ checked: solicitation.checked }">
  <div class="check-box">
    <div
      class="id"
      [ngClass]="{ 'center-id': !(!isHeader && approvalVisible) }"
    >
      {{ util.addLeadingZeros(solicitation.id, 5) }}
    </div>
    <div class="check" *ngIf="!isHeader && approvalVisible">
      <app-check-box
        (check)="onCheck()"
        (removeCheck)="onRemoveCheck()"
        [disabled]="isDisable()"
        [value]="solicitation.checked"
      ></app-check-box>
    </div>
  </div>
  <div class="info" (click)="viewSolicitation(solicitation.id)">
    <div class="data-info">
      <div class="title">
        {{ solicitation.title }}
      </div>
      <div class="data">
        <div class="date">
          <img src="../../../../../assets/icons/calendar-primary-600.svg" />
          <div class="date-info">
            {{ solicitation.openingDate | date : "dd/MM/yyyy" }}
            <div
              class="date-info-completion"
              *ngIf="solicitation.completionDate !== null"
            >
              {{ solicitation.completionDate | date : "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
        <div class="unit-name">
          <img src="../../../../../assets/icons/units-primary-600.svg" />
          <div class="name">{{ solicitation.unitName }}</div>
        </div>
        <div class="company-name">
          <img src="../../../../../assets/icons/operator-primary-600.svg" />
          <div class="name">{{ solicitation.fantasyName }}</div>
        </div>
        <div class="category-name">
          <img src="../../../../../assets/icons/categories-primary-600.svg" />
          <div class="name">{{ solicitation.categoryName }}</div>
        </div>
      </div>
    </div>
    <div class="status">
      <div class="status-solicitation">
        <app-status
          [color]="getColorText(solicitation.status)"
          [image]="getImage(solicitation.status)"
          [status]="getStatusName(solicitation.status)"
        ></app-status>
      </div>
      <div
        class="value"
        [ngClass]="{ negative: isNegative, variable: isVarible }"
      >
        {{ solicitation.valor | currency : "BRL" }}
      </div>
    </div>
  </div>
</div>
