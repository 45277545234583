import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
  public maxKmPlanned: number = Infinity;

  // Inputs
  @Input() isLoading: boolean = false;
  @Input() shouldDisableFields: boolean = false;
  @Input() disableFieldKM: boolean = false;
  @Input() maintenanceForm = new FormGroup({
    tractorOwnMaintenance: new FormControl(''),
    tractorFactoryMaintenance: new FormControl(''),
    trailerMaintenance: new FormControl(''),
    kmPlanned: new FormControl(''),
  });
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enums
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
