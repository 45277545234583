import { StorageService } from 'src/app/common/services/storage.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SolicitationStatus } from 'src/app/shared/enums/SolicitationStatus';
import { Utils } from 'src/app/common/utils/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { SolicitationDetailModel } from '../../models/solicitation-detail.model';
import { SolicitationStatusEnum } from '../../enums/solicitation-status.enum';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-solicitation-detail',
  templateUrl: './solicitation-detail.component.html',
  styleUrls: ['./solicitation-detail.component.scss'],
})
export class SolicitationDetailComponent implements OnInit {
  @Input() isHeader: boolean = false;
  @Input() solicitation = new SolicitationDetailModel();
  @Input() isPairRow: boolean = false;
  @Input() approvalVisible: boolean = false;
  @Output() check: EventEmitter<any> = new EventEmitter();
  @Output() removeCheck: EventEmitter<any> = new EventEmitter();

  public breakpointBase: number = 1025;
  public widthBreakpoint: number = 0;
  public isNegative: boolean = false;
  public isVarible: boolean = false;

  constructor(
    public util: Utils,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngOnInit(): void {
    this.approvalVisible = this.canApproveSolicitation();
    this.isNegative = this.solicitation.type === 'Desconto';
    this.isVarible = this.solicitation.type === 'Outros Custos';
  }

  onCheck() {
    this.solicitation.checked = true;
    this.check.emit(this.solicitation);
  }

  onRemoveCheck() {
    this.solicitation.checked = false;
    this.removeCheck.emit(this.solicitation);
  }

  getLoggedUserData() {
    return this.storageService.getLoggedUserData();
  }

  canApproveSolicitation() {
    let result = false;
    const role = this.getLoggedUserData();

    if (role.userRoleName === 'Triagem') {
      result =
        this.solicitation?.status === SolicitationStatusEnum.AwaitingScreening;
    }
    if (role.userRoleName === 'Analista Suprimentos') {
      result =
        this.solicitation.status === SolicitationStatusEnum.AwaitingScreening ||
        this.solicitation?.status ===
          SolicitationStatusEnum.AwaitingSupplyValitation ||
        this.solicitation?.status ===
          SolicitationStatusEnum.AwaitingValueReview;
    }
    if (role.userRoleName === 'Analista Custos') {
      result =
        this.solicitation?.status ===
          SolicitationStatusEnum.AwaitingScreening ||
        this.solicitation?.status ===
          SolicitationStatusEnum.AwaitingFinantialValidation ||
        this.solicitation?.status ===
          SolicitationStatusEnum.AwaitingValueReview ||
        this.solicitation?.status === SolicitationStatusEnum.Closing;
    }
    if (role.userRoleName === 'Supervisor') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingOperationalValidation;
    }
    if (role.userRoleName === 'Gerente Operação') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingOperationManager;
    }
    if (role.userRoleName === 'Diretor Operação') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingOperationDirector;
    }
    if (role.userRoleName === 'Gerente Corporativo') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingCorporateManager;
    }
    if (role.userRoleName === 'Diretor Corporativo') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingCorporateDirector;
    }
    if (role.userRoleName === 'Coordenador Suprimentos') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingSupplyCoordinator;
    }
    if (role.userRoleName === 'Coordenador Corporativo') {
      result =
        this.solicitation?.status ===
        SolicitationStatusEnum.AwaitingCorporateCoordinator;
    }
    if (role.userRoleName === 'Analista Pagamento') {
      result = false;
    }

    return result;
  }

  isDisable() {
    return (
      this.solicitation?.status === SolicitationStatus.Concluido ||
      this.solicitation?.status === SolicitationStatus.ReprovadoCorporativo
    );
  }

  viewSolicitation(id: number) {
    this.router.navigate([`/solicitation/${id}`]);
  }

  getImage(status: string) {
    if (status === undefined) return '';
    if (status.includes('Concluído')) {
      return '../../../assets/icons/Success-green.svg';
    }
    if (status.includes('Reprovado')) {
      return '../../../assets/icons/Exclude.svg';
    }
    return '../../../assets/icons/Timer-yellow.svg';
  }

  getColorText(status: string) {
    if (status === undefined) return StatusColorEnum.attention;
    if (status.includes('Concluído')) {
      return StatusColorEnum.success;
    }
    if (status.includes('Reprovado')) {
      return StatusColorEnum.warning;
    }
    return StatusColorEnum.attention;
  }

  getStatusName(status: string) {
    if (
      status !== undefined &&
      (status.includes('Reprovado') || status.includes('Aguardando'))
    ) {
      return status.replace('Aguardando ', '').replace('Reprovado ', '');
    }
    return status;
  }
}
