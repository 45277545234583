import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/common/guards/auth-guard.service';
import { BenefitComponent } from '../dfdv-sumarize/benefit/benefit.component';
import { ChargesComponent } from '../dfdv-sumarize/charges/charges.component';
import { DfdvSummarizeComponent } from '../dfdv-sumarize/dfdv-summarize.component';
import { DriverFactorComponent } from '../dfdv-sumarize/driver-factor/driver-factor.component';
import { FleetComponent } from '../dfdv-sumarize/fleet/fleet.component';
import { GeneralCostsComponent } from '../dfdv-sumarize/general-costs/general-costs.component';
import { OperationalTeamComponent } from '../dfdv-sumarize/operational-team/operational-team.component';
import { PlantComponent } from '../dfdv-sumarize/plant/plant.component';
import { ProfitComponent } from '../dfdv-sumarize/profit/profit.component';
import { RvParameterComponent } from '../dfdv-sumarize/rv-parameter/rv-parameter.component';
import { ScopeComponent } from '../dfdv-sumarize/scope/scope.component';
import { SummaryComponent } from '../dfdv-sumarize/summary/summary.component';
import { SupportTeamComponent } from '../dfdv-sumarize/support-team/support-team.component';
import { TableComponent } from '../dfdv-sumarize/table/table.component';
import { TaxComponent } from '../dfdv-sumarize/tax/tax.component';
import { VariableCostsComponent } from '../dfdv-sumarize/variable-costs/variable-costs.component';
import { DfdvComponent } from '../dfdv.component';
import { DfdvRouteComponent } from './dfdv-route.component';

const routes: Routes = [
  {
    path: 'dfdv',
    component: DfdvRouteComponent,
    data: { breadcrumb: null, icon: null },
    children: [
      {
        path: '',
        component: DfdvComponent,
        data: { breadcrumb: 'Despesas Fixas e Variáveis', icon: 'dfdv-icon' },
      },
      {
        path: ':id',
        data: {
          breadcrumb: 'Despesas Fixas e Variáveis',
          icon: 'dfdv-icon',
        },
        children: [
          {
            path: '',
            component: DfdvSummarizeComponent,
            data: { breadcrumb: null, icon: null },
          },
          {
            path: 'driver-factor',
            component: DriverFactorComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Fator Motorista', icon: null },
          },
          {
            path: 'general-cost',
            component: GeneralCostsComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Despesas Gerais', icon: null },
          },
          {
            path: 'support-team',
            component: SupportTeamComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Equipe Apoio', icon: null },
          },
          {
            path: 'operational-team',
            component: OperationalTeamComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Equipe Operacional', icon: null },
          },
          {
            path: 'variable-cost',
            component: VariableCostsComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Custo Variável (CV)', icon: null },
          },
          {
            path: 'tax',
            component: TaxComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Impostos', icon: null },
          },
          {
            path: 'fleet',
            component: FleetComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Frota', icon: null },
          },
          {
            path: 'charge',
            component: ChargesComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Encargos', icon: null },
          },
          {
            path: 'scope',
            component: ScopeComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Escopo', icon: null },
          },
          {
            path: 'benefit',
            component: BenefitComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Benefícios', icon: null },
          },
          {
            path: 'profit',
            component: ProfitComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Lucro', icon: null },
          },
          {
            path: 'rv-parameter',
            component: RvParameterComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Parâmetros RV', icon: null },
          },
          {
            path: 'plant',
            component: PlantComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Usina', icon: null },
          },
          {
            path: 'summary',
            component: SummaryComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Resumo', icon: null },
          },
          {
            path: 'table',
            component: TableComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: 'Tabela', icon: null },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DfdvSummarizeRoutingModule { }
