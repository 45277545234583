import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { of } from 'rxjs';
import { GetOperationPimsModel } from './models/get-operation-pims.model';
import { NewOperationPimsModel } from './models/new-operation-pims.model';
import { PatchOperationPimsModel } from './models/patch-operation-pims.model';

@Injectable({
  providedIn: 'root',
})
export class OperationService extends BaseService {
  constructor(private http: HttpClient) {
    super('operations');
  }
  getActiveOperations() {
    return this.http.get<GetOperationPimsModel[]>(`${this.url}/actives`);
  }

  getOperations() {
    return this.http.get<GetOperationPimsModel[]>(this.url);
  }

  getOperation(id: string) {
    return this.http.get<GetOperationPimsModel>(`${this.url}/${id}`);
  }

  updateOperation(model: PatchOperationPimsModel) {
    return this.http.patch<PatchOperationPimsModel>(this.url, model);
  }

  createOperation(request: NewOperationPimsModel[]) {
    return this.http.post<NewOperationPimsModel[]>(`${this.url}`, request);
  }
}
