export function cnpjValidation(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g,'');

  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os números são iguais
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Validação do primeiro dígito verificador
  let soma = 0;
  let peso = 5;

  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = (peso === 2) ? 9 : peso - 1;
  }

  let digito = 11 - (soma % 11);

  if (digito > 9) {
    digito = 0;
  }

  if (parseInt(cnpj.charAt(12)) !== digito) {
    return false;
  }

  // Validação do segundo dígito verificador
  soma = 0;
  peso = 6;

  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = (peso === 2) ? 9 : peso - 1;
  }

  digito = 11 - (soma % 11);

  if (digito > 9) {
    digito = 0;
  }

  if (parseInt(cnpj.charAt(13)) !== digito) {
    return false;
  }

  return true;
}
