export class GetUnitCategoryModel {
  unitName: string = '';
  categoryId!: number;
  categoryPimsId!: number;
  categoryName: string = '';
  supervisorId: string = '';
  supervisorName: string = '';
  operationManagerId: string = '';
  operationManagerName: string = '';
  operatorId: string = '';
  operatorName: string = '';
  operationDirectorId!: string;
  operationDirectorName!: string;
  active!: boolean;
  subcategories: string[] = [];
}
