export class FleetTractorModel {
  [key: string]: any;

  active!: boolean;
  quantity!: number;
  tractorFinancingStatus!: string;
  tractorBrand!: string;
  tractorModel!: string;
  capitalRemuneration!: number;
  tractorCost!: number;
  tractorTireCost!: number;
  tractorPisCofinsCost!: number;
  tractorIcmsCost!: number;
  tractorNetValueDepreciation!: number;
  tractorNetValueRemuneration!: number;
  tractorYear!: number;
  tractorAcquisitionDate!: Date;
  tractorDepreciationPeriod!: number;
  tractorUsageCycle!: number;
  tractorResidual!: number;
}
