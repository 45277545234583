import { Component, Input } from '@angular/core';
import { StatusColorEnum } from '../enums/StatusColor';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @Input() status: string = '';
  @Input() image: string = '';
  @Input() color: StatusColorEnum = StatusColorEnum.primary;

  getColor() {
    return this.color.toString();
  }
}
