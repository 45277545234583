<div class="container">
  <div class="button-container">
    <app-buttons
      class="button"
      label="NOVA SUBCATEGORIA"
      [color]="eButtonsColor.primary"
      [type]="eButtonsType.primary"
      [loading]="loadingButton"
      [enabled]="enableButton"
      icon="../../../../assets/icons/add-common-100.svg"
      (clickButton)="handleButtonClick()"
    ></app-buttons>
  </div>
  <div class="card-container">
    <app-card
      title="Subcategorias"
      icon="../../../../assets/icons/subcategories-primary-600.svg"
      [paddingHorizontal]="false"
    >
      <app-subcategory-list
        *ngIf="!isLoading && subcategories.length > 0 && !error"
        [subcategories]="subcategories"
        body
      ></app-subcategory-list>
      <div class="skeleton" body *ngIf="isLoading">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <div
        body
        class="not-found"
        *ngIf="!isLoading && subcategories.length === 0 && !error"
      >
        <app-not-found></app-not-found>
      </div>
      <div
        body
        class="error"
        *ngIf="!isLoading && subcategories.length === 0 && error"
      >
        <app-error (reloadCallBack)="reload()"></app-error>
      </div>
    </app-card>
  </div>
</div>
