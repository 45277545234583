<div class="container">
  <app-card
    title="Valor Limite das Solicitações"
    icon="../../../../assets/icons/solicitation-primary-600.svg"
  >
    <div class="body" body *ngIf="!isLoading && !error">
      <p>
        Favor informar os seguintes dados, para que possamos atualizar o valor
        limite das solicitações.
      </p>
      <div class="input-box">
        <app-input
          label="Limite Gerente de Operação (R$)"
          errorMessage="Este campo é obrigatório"
          [control]="form.controls.operationManagerLimit"
          [mask]="MaskTypeEnum.money"
        ></app-input>
        <app-input
          label="Limite Gerente Corporativo (R$)"
          errorMessage="Este campo é obrigatório"
          [control]="form.controls.corporateManagerLimit"
          [mask]="MaskTypeEnum.money"
        ></app-input>
      </div>
      <div class="buttons">
        <app-secondary-button
          label="VOLTAR"
          icon="../../../../assets/icons/arrow-slate100.svg"
          [right]="false"
          [enabled]="true"
          (click)="navigateBack()"
        ></app-secondary-button>
        <app-primary-button
          label="SALVAR"
          [color]="ButtonColorEnum.primary"
          [loading]="sending"
          [enabled]="form.valid"
          (click)="updateValueLimits()"
        ></app-primary-button>
      </div>
    </div>
    <div class="skeleton" body *ngIf="isLoading">
      <app-skeleton-input [input]="2"></app-skeleton-input>
      <app-skeleton-box [box]="2"></app-skeleton-box>
    </div>
    <div body class="error" *ngIf="!isLoading && error">
      <app-error (reloadCallBack)="reload()"></app-error>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
