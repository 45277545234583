/* eslint-disable max-len */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { FleetCapitalModel } from 'src/app/services/dfdv/fleet/models/fleet-capital.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table-capital',
  templateUrl: './fleet-table-capital.component.html',
  styleUrls: ['./fleet-table-capital.component.scss']
})
export class FleetTableCapitalComponent implements OnChanges {

  @Input() isLoading: boolean = false;
  @Input() capitalList: FleetCapitalModel[] = [];
  @Input() rowStatus: FleetTableRowStatusEnum[] = [];

  public capitalHeader: string[] = [
    'ATIVO/PARADO',
    'DEPRECIAÇÃO CAVALO MECÂNICO',
    'DEPRECIAÇÃO CONJUNTO',
    'REM. K CAVALO MECÂNICO',
    'REM. K CONJUNTO',
    'REM. K SOBRE IMPOSTOS CAV. MECÂNICO',
    'REM. K SOBRE IMPOSTOS CONJUNTO',
    'REM. K SOBRE IMPOSTOS',
    'TOTAL REM. FIXA',
  ];

  public capitalContent: string[][] = [];
  public capitalActiveContent: string[] = [];

  public capitalStopedContent: string[] = [];

  public capitalRowStatus: FleetTableRowStatusEnum[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['capitalList']) {
      this.buildTable();
    }
  }

  constructor(public utils: Utils) {
  }

  buildTable() {
    const columnTotalsActive = Array(this.capitalHeader.length - 1).fill(0);
    const columnTotalsStopped = Array(this.capitalHeader.length - 1).fill(0);
    
    if (!this.capitalList?.length) {
      const initialValues = Array(this.capitalHeader.length - 1).fill('-');
      this.capitalActiveContent = initialValues;
      this.capitalStopedContent = initialValues;
      return;
    }

    this.capitalContent = [];
    this.capitalActiveContent = [];
    this.capitalStopedContent = [];
    this.capitalRowStatus = [];

    
    this.capitalRowStatus = this.rowStatus;

    this.capitalList.forEach((x, index) => {
      const keys = Object.keys(x).filter((z) => z !== 'active');

      if (this.capitalRowStatus[index] === FleetTableRowStatusEnum.ACTIVE) {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsActive[i] += x[key];
          }
        });
      } else {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsStopped[i] += x[key];
          }
        });
      }

      this.capitalContent.push([
        (index + 1).toString(),
        this.utils.toMoney(x.tractorDepreciation.toString()),
        this.utils.toMoney(x.trailerDepreciation.toString()),
        this.utils.toMoney(x.tractorCapitalRemuneration.toString()),
        this.utils.toMoney(x.trailerCapitalRemuneration.toString()),
        this.utils.toMoney(x.tractorCapitalRemunerationOnTaxes.toString()),
        this.utils.toMoney(x.trailerCapitalRemunerationOnTaxes.toString()),
        this.utils.toMoney(x.capitalRemunerationOnTaxes.toString()),
        this.utils.toMoney(x.totalRemuneration.toString()),
      ]);
    });

    const activeItemCount = this.capitalRowStatus.filter((x) => x === FleetTableRowStatusEnum.ACTIVE).length;
    const columnActiveAverages = columnTotalsActive.map((total) => (activeItemCount > 0 ? total / activeItemCount : 0));
    this.capitalActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.capitalActiveContent.length; i++) {
      if(this.capitalActiveContent[i] !== '-') {
        this.capitalActiveContent[i] = this.utils.toMoney(this.capitalActiveContent[i]);
      }
    }

    const stoppedItemCount = this.capitalRowStatus.filter((x) => x === FleetTableRowStatusEnum.STOPED).length;
    const columnStoppedAverages = columnTotalsStopped.map((total) => (stoppedItemCount > 0 ? total / stoppedItemCount : 0));
    this.capitalStopedContent = columnStoppedAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.capitalStopedContent.length; i++) {
      if(this.capitalStopedContent[i] !== '-') {
        this.capitalStopedContent[i] = this.utils.toMoney(this.capitalStopedContent[i]);
      }
    }
  }
}
