import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState, ILoadingState } from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { ListErrorModel } from 'src/app/shared/modal/models/list-error.model';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { ModalActionTypeEnum, ModalConfirmTypeEnum } from './enums/modal-type.enum';
import { ModalConfirmationAttachModel, ModalConfirmationModel } from './models/modal-confirmation.model';

@Component({
  selector: 'app-sumarize-header',
  templateUrl: './sumarize-header.component.html',
  styleUrls: ['./sumarize-header.component.scss'],
})
export class SumarizeHeaderComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() errorMessage: string = '';
  @Input() currentPageTitle: string = '';
  @Input() isLoadingEQ: boolean = false;
  // Loading Controls
  isLoading: boolean = false;
  isSaving: boolean = false;
  isDeleting: boolean = false;
  isSendingToBID: boolean = false;
  isApprovingBID: boolean = false;
  isReprovingBID: boolean = false;
  isEffectingPending: boolean = false;
  isEffectingNew: boolean = false;
  isCancelling: boolean = false;
  isFinalizing: boolean = false;
  isNewVersion: boolean = false;
  isShowNewVersion: boolean = false;

  // Enable Controls
  enableSave: boolean = false;
  enableDelete: boolean = false;
  enableSendToBID: boolean = false;
  enableApproveBID: boolean = false;
  enableReproveBID: boolean = false;
  enableEffectPending: boolean = false;
  enableEffectNew: boolean = false;
  enableCancel: boolean = false;
  enableFinalize: boolean = false;
  enableNewVersion: boolean = false;
  enableShowNewVersion: boolean = false;

  // Modal  Controls
  modalActionType!: ModalActionTypeEnum;
  modalConfirmType!: ModalConfirmTypeEnum;
  modalModel: ModalModel = new ModalModel();
  modalConfirmationModel: ModalConfirmationModel = new ModalConfirmationModel();
  modalConfirmationAttachModel: ModalConfirmationAttachModel = new ModalConfirmationAttachModel();

  // Enums
  ButtonColorEnum = ButtonColorEnum;
  ModalActionTypeEnum = ModalActionTypeEnum;

  // Variables
  isUserRaizen: boolean = false;
  userRole!: string;
  dfdv!: GetDfdvModel;
  dfdvErrorObject: ListErrorModel[] = [];
  formModalAttach = this.formBuilder.group({ attachments: [[] as File[]] });

  // Outputs
  @Output() save: EventEmitter<any> = new EventEmitter();

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;
  public dfdvUtils!: DfdvUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: DfdvService,
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private store: Store<{ dfdv: IDfdvState, loading: ILoadingState }>,
  ) { }

  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.route.paramMap.subscribe((params) => {
      const id = params.get('id');

      this.loadingStore$.pipe(takeUntil(this.destroy$))
        .subscribe((isLoading) => { this.isLoading = isLoading.loading; });

      if (!this.isLoading) {
        this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
          if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
            this.store.dispatch(DfdvActions.loadGetDfdvModel({ id: id ?? '0' }));
          }
        });
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };
      this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
      this.handleEnableButtons();
    });
  }

  handleEnableButtons() {
    const status = this.dfdv?.dfdvStatusName;
    if (status) {
      const isUserRaizen = this.dfdvUtils.isUserRaizen();
      const isPreliminary = this.dfdvUtils.isPreliminary();
      const isDraft = this.dfdvUtils.isDraft();
      const isNew = this.dfdvUtils.isNew();
      const isPending = this.dfdvUtils.isPending();
      const isCurrent = this.dfdvUtils.isCurrent();

      const enableForOPL = this.dfdvUtils.isEnableForOPL();
      this.enableSave = this.dfdvUtils.isEnableToEdit();
      this.enableDelete = isUserRaizen && (isDraft || isPreliminary);
      this.enableSendToBID = enableForOPL;
      this.enableApproveBID = isUserRaizen && isPending;
      this.enableReproveBID = isUserRaizen && isPending;
      this.enableEffectPending = isUserRaizen && isPending;
      this.enableEffectNew = isUserRaizen && isNew;
      this.enableCancel = isUserRaizen && isCurrent;
      this.enableFinalize = isUserRaizen && isCurrent;
      this.enableNewVersion = isUserRaizen && isCurrent && this.dfdv.nextVersionId === null;
      this.enableShowNewVersion = isCurrent && this.dfdv.nextVersionId > 0;
    }
  }

  handleShowConfirmation(type: ModalActionTypeEnum) {
    this.modalActionType = type;

    switch (type) {
      case ModalActionTypeEnum.Send_to_BID:
        this.setModalConfirmation(
          'Tem certeza que deseja enviar a DFDV para BID?',
          'Ao prosseguir, você está de acordo com todos os valores cotados, e esses valores não poderão mais ser alterados nessa etapa do BID.',
          'Confirmar',
        );
        break;
      case ModalActionTypeEnum.Approve_BID:
        this.setModalConfirmation(
          'Tem certeza que deseja aprovar essa DFDV?',
          'Ao prosseguir, essa DFDV será arquivada e uma nova DFDV será criada para participação no próximo BID.',
          'Prosseguir',
        );
        break;
      case ModalActionTypeEnum.Reprove_BID:
        this.setModalConfirmation(
          'Tem certeza que deseja reprovar essa DFDV?',
          'Ao prosseguir, a Empresa referente a essa DFDV não participará mais do BID.',
          'Prosseguir',
        );
        break;
      case ModalActionTypeEnum.Effect_Pending:
        this.setModalConfirmation(
          'Tem certeza que deseja efetivar essa DFDV?',
          'Ao prosseguir, a Empresa referente a essa DFDV será efetivada, e as demais DFDVs com status Pendente dessa Unidade/Categoria serão reprovadas automaticamente.',
          'Prosseguir',
        );
        break;
      case ModalActionTypeEnum.Effect_New:
        this.setModalConfirmationAttach(
          'Tem certeza que deseja efetivar essa DFDV?',
          'Ao prosseguir, a DFDV Vigente será arquivada e essa nova DFDV entrará em vigor em seu lugar. Para isso, é necessário enviar as evidências das alterações realizadas.',
          'Evidênicias',
          '(arquivos suportados: .xlsx, .docx, .pdf, .jpg, .png, limite individual: 25 MB; limite total: 50 MB)',
          '.xlsx,.docx,.pdf,.jpg,.png',
          50,
          'Prosseguir',
        );
        break;
      case ModalActionTypeEnum.Cancel:
        this.setModalConfirmation(
          'Tem certeza que deseja cancelar essa DFDV?',
          'Ao prosseguir, essa DFDV será dada como finalizada e não estará mais em vigor. Esta ação não poderá ser desfeita.',
          'Prosseguir',
        );
        break;
      case ModalActionTypeEnum.Delete:
        this.setModalConfirmation(
          'Tem certeza que deseja deseja excluir essa DFDV?',
          'Ao prosseguir, todos os dados dessa DFDV serão perdidos e não poderão mais ser acessados.',
          'Prosseguir',
          false,
        );
        break;
      case ModalActionTypeEnum.Finalize:
        this.setModalConfirmation(
          'Tem certeza que deseja finalizar essa DFDV?',
          'Ao prosseguir, essa DFDV será dada como finalizada e não estará mais em vigor. Esta ação não poderá ser desfeita.',
          'Prosseguir',
        );
        break;
      default:
        break;
    }
  }

  handleConfirmModal() {
    this.isLoading = true;
    switch (this.modalActionType) {
      case ModalActionTypeEnum.Send_to_BID:
        this.handleSendToBID();
        break;
      case ModalActionTypeEnum.Approve_BID:
        this.handleApproveBID();
        break;
      case ModalActionTypeEnum.Reprove_BID:
        this.handleReproveBID();
        break;
      case ModalActionTypeEnum.Effect_Pending:
        this.handleEffectDFDV();
        break;
      case ModalActionTypeEnum.Effect_New:
        this.handleEffectNewDFDV();
        break;
      case ModalActionTypeEnum.Cancel:
        this.handleCancelDFDV();
        break;
      case ModalActionTypeEnum.Delete:
        this.handleDeleteDFDV();
        break;
      case ModalActionTypeEnum.Finalize:
        this.handleFinalizeDFDV();
        break;
      default:
        break;
    }
  }

  handleCancelModal() {
    this.modalConfirmationModel = new ModalConfirmationModel();
    this.modalConfirmationModel.showModal = false;
    this.modalConfirmationAttachModel = new ModalConfirmationAttachModel();
    this.modalConfirmationAttachModel.showModal = false;
  }

  handleCloseModal() {
    if (
      this.modalConfirmType === ModalConfirmTypeEnum.Success
      && this.modalActionType !== ModalActionTypeEnum.Save
    ) {
      this.router.navigate(['dfdv']);
    }

    this.dfdvErrorObject = [];
    this.modalModel.showModal = false;
  }

  handleSendToBID() {
    this.isSendingToBID = true;
    this.service.sendToBID(this.dfdv.dfdvId).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV enviada para BID com sucesso!',
          'Os valores cotados serão analisados por nossa equipe, e dentro de alguns dias você receberá nosso parecer.',
        );

        this.isSendingToBID = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        const errorObject = JSON.parse(
          JSON.stringify(error?.error?.ErrorObject),
        ) as ListErrorModel[];
        this.dfdvErrorObject.push(...errorObject);

        this.setModalError(
          'Não foi possível enviar a DFDV para BID.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isSendingToBID = false;
        this.isLoading = false;
      },
    });
  }

  handleApproveBID() {
    this.isApprovingBID = true;

    this.service.approveBID([this.dfdv.dfdvId]).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV aprovada com sucesso!',
          'Essa DFDV foi arquivada. Nova DFDV criada para participação no próximo BID.',
        );

        this.isApprovingBID = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível aprovar a DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isApprovingBID = false;
        this.isLoading = false;
      },
    });
  }

  handleEffectDFDV() {
    this.isEffectingPending = true;

    this.service.effectDfdv(this.dfdv.dfdvId).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV efetivada com sucesso!',
          'A versão anterior dessa DFDV foi arquivada. Essa nova DFDV já se encontra em vigor.',
        );

        this.isEffectingPending = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível efetivar essa DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isEffectingPending = false;
        this.isLoading = false;
      },
    });
  }

  handleEffectNewDFDV() {
    this.isEffectingNew = true;

    const files = this.formModalAttach.controls.attachments.value ?? [];

    this.service.effectNewDfdv(this.dfdv.dfdvId, files).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV efetivada com sucesso!',
          'A Empresa referente a essa DFDV foi efetivada, e as demais DFDVs com status Pendente dessa Unidade/Categoria foram reprovadas.',
        );

        this.isEffectingNew = false;
        this.isLoading = false;
        this.updateDfdvStatus();
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível efetivar essa DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isEffectingNew = false;
        this.isLoading = false;
      },
    });
  }

  handleReproveBID() {
    this.isReprovingBID = true;

    this.service.reproveBID([this.dfdv.dfdvId]).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV reprovada com sucesso!',
          'A Empresa referente a essa DFDV não participará mais do BID.',
        );

        this.isReprovingBID = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível reprovar a DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isReprovingBID = false;
        this.isLoading = false;
      },
    });
  }

  handleCancelDFDV() {
    this.isCancelling = true;

    this.service.cancelDfdv(this.dfdv.dfdvId).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV cancelada com sucesso!',
          'Essa DFDV foi finalizada e não está mais em vigor.',
        );

        this.isCancelling = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível cancelar essa DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isCancelling = false;
        this.isLoading = false;
      },
    });
  }

  handleDeleteDFDV() {
    this.isDeleting = true;

    this.service.deleteDfdv(this.dfdv.dfdvId).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV excluída com sucesso!',
          'Todos os dados dessa DFDV foram excluídos e não podem mais ser acessados.',
        );

        this.isDeleting = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível excluir essa DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isDeleting = false;
      },
    });
  }

  handleSave() {
    if (this.currentPageTitle === 'Equipe Apoio') {
      this.save.emit();
      return;
    }

    this.isSaving = true;
    this.modalActionType = ModalActionTypeEnum.Save;

    if (this.errorMessage) {
      this.setModalError(
        this.currentPageTitle,
        this.errorMessage,
      );
      this.isSaving = false;
    }

    const request = this.dfdvUtils.getUpdateRequest();
    this.service.updateDfdv(request).subscribe({
      next: () => {
        this.setModalSuccess(
          this.currentPageTitle,
          'Alterações efetivadas com sucesso.',
        );

        this.isSaving = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          this.currentPageTitle,
          this.setErrorMessage(
            error,
            'Erro ao processar alterações.',
          ),
        );
        this.isSaving = false;
      },
    });
  }

  handleFinalizeDFDV() {
    this.isFinalizing = true;

    this.service.finalizeDfdv(this.dfdv.dfdvId).subscribe({
      next: () => {
        this.setModalSuccess(
          'DFDV finalizada com sucesso!',
          'Essa DFDV foi finalizada e não está mais em vigor.',
        );

        this.isFinalizing = false;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível finalizar essa DFDV.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isFinalizing = false;
        this.isLoading = false;
      },
    });
  }

  setErrorMessage(error: HttpErrorResponse, defaultMessage: string) {
    const message = JSON.parse(JSON.stringify(error?.error));

    return error.status >= 400 && error.status < 500
      ? (message?.Message ?? message)
      : defaultMessage;
  }

  setModalConfirmation(
    title: string,
    description: string,
    button: string,
    isPositive: boolean = true,
  ) {
    this.modalConfirmationModel.title = title;
    this.modalConfirmationModel.description = description;
    this.modalConfirmationModel.confirmLabel = button;
    this.modalConfirmationModel.confirmIcon = 'assets/icons/approve-common-100.svg';
    this.modalConfirmationModel.confirmColor = isPositive
      ? ButtonColorEnum.success
      : ButtonColorEnum.warning;
    this.modalConfirmationModel.showModal = true;
  }

  setModalConfirmationAttach(
    title: string,
    description: string,
    attachLabel: string,
    attachDescription: string,
    attachExtensions: string,
    attachSize: number,
    button: string,
  ) {
    this.modalConfirmationAttachModel.title = title;
    this.modalConfirmationAttachModel.attachLabel = attachLabel;
    this.modalConfirmationAttachModel.attachDescription = attachDescription;
    this.modalConfirmationAttachModel.attachExtensions = attachExtensions;
    this.modalConfirmationAttachModel.attachTotalSize = attachSize;
    this.modalConfirmationAttachModel.description = description;
    this.modalConfirmationAttachModel.confirmLabel = button;
    this.modalConfirmationAttachModel.confirmIcon = '';
    this.modalConfirmationAttachModel.confirmColor = ButtonColorEnum.primary;

    this.modalConfirmationAttachModel.showModal = true;
  }

  setModalSuccess(title: string, description: string) {
    this.dfdvErrorObject = [];
    this.modalConfirmType = ModalConfirmTypeEnum.Success;

    this.modalModel.title = title;
    this.modalModel.description = description;
    this.modalModel.success = true;
    this.modalModel.imagePath = 'assets/icons/success-600.gif';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.showModal = true;
  }

  setModalError(title: string, description: string) {
    this.modalConfirmType = ModalConfirmTypeEnum.Error;

    this.modalModel.title = title;
    this.modalModel.description = description;
    this.modalModel.success = false;
    this.modalModel.imagePath = 'assets/icons/warning-600.gif';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.showModal = true;
  }

  createNewVersion() {
    this.isLoading = true;
    this.isNewVersion = true;

    this.service.createNewVersionDfdv(this.dfdv.dfdvId).subscribe({
      next: (response: GetDfdvModel) => {
        this.isLoading = false;
        this.isNewVersion = false;

        this.router.navigate([`dfdv/${response.dfdvId}`]);
      },
      error: (error: HttpErrorResponse) => {
        this.setModalError(
          'Não foi possível criar uma nova versão.',
          this.setErrorMessage(
            error,
            'Tente novamente mais tarde ou entre em contato com algum administrador.',
          ),
        );

        this.isEffectingNew = false;
        this.isLoading = false;
      },
    });
  }

  showNewVersion() {
    this.router.navigate([`dfdv/${this.dfdv.nextVersionId}`]);
  }

  updateDfdvStatus() {
    this.dfdv.dfdvStatusName = DfdvStatusEnum.Current;
    this.store.dispatch(
      DfdvActions.updateDfdvObject({
        data: this.dfdv,
      }),
    );
  }
}
