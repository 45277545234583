import { environment } from '../../../environments/environment';

/**
 * Simple base class to be extended by other services to consume an API.
 * Endpoint parameter should be added without '/'
 */
export class BaseService {
  public readonly url!: string;
  public readonly publicUrl!: string;
  public readonly urlPims!: string;

  constructor(protected endpoint: string) {
    this.url = `${
      environment.local
        ? environment.LocalUrl
        : `${environment.SensediaBaseUrl}portalopl/v1/`
    }${endpoint}`;
    this.publicUrl = `${
      environment.local
        ? environment.LocalUrl
        : `${environment.SensediaBaseUrl}portalopl-public/v1/`
    }${endpoint}`;
    this.urlPims = `${
      environment.local
        ? environment.LocalUrlPims
        : `${environment.SensediaBaseUrl}portalopl-pims/v1/`
    }${endpoint}`;
  }
}
