import { UpdateSupportTeamDetailModel } from './update-support-team-detail.model';

export class UpdateOperationalSupportModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.generalBuyer = new UpdateSupportTeamDetailModel();
    this.supervisor = new UpdateSupportTeamDetailModel();
    this.instructor = new UpdateSupportTeamDetailModel();
    this.fieldLeader = new UpdateSupportTeamDetailModel();
    this.nightFieldLeader = new UpdateSupportTeamDetailModel();
    this.yardLeader = new UpdateSupportTeamDetailModel();
    this.nightYardLeader = new UpdateSupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  generalBuyer: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  supervisor: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  instructor: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  fieldLeader: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  nightFieldLeader: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  yardLeader: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  nightYardLeader: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
}
