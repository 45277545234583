/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
import { ActivatedRoute } from '@angular/router';
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { DriversFactorModel } from 'src/app/services/dfdv/driversFactor/models/driversFactor.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { ModalModel } from '../../../pre-registration/models/modal.model';

@Component({
  selector: 'app-driver-factor',
  templateUrl: './driver-factor.component.html',
  styleUrls: ['./driver-factor.component.scss'],
})
export class DriverFactorComponent implements OnInit, OnDestroy {
  // Error Messages
  public errorMessage = 'Este campo é obrigatorio';

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;
  public ButtonColorEnum = ButtonColorEnum;

  // Aux
  public isUserRaizen: boolean = false;
  public shouldDisableFields: boolean = false;
  public dfdvLoading: boolean = false;
  public isLoading: boolean = true;
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public dfdvUtils!: DfdvUtils;

  // Models
  public driversFactorModel: DriversFactorModel = new DriversFactorModel();
  public modalModel: ModalModel = new ModalModel();

  // NgRx
  driverStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.driverFactor));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  public destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  // Forms
  public form = new FormGroup({
    workingDays: new FormControl(''),
    totalWorkingDays: new FormControl(''),
    workingHours: new FormControl(''),
    overtimeHours: new FormControl(''),
    standardDriverInIntinereHours: new FormControl(''),
    nightDriverInIntinereHours: new FormControl(''),
    numberOfShifts: new FormControl(''),
    driversPerShift: new FormControl(''),
    standardDriverTeamFactor: new FormControl(''),
    nightDriverTeamFactor: new FormControl(''),
    standardDriverManeuveringTeamFactor: new FormControl(''),
    nightDriverManeuveringTeamFactor: new FormControl(''),
    foodTicketContribution: new FormControl(''),
    collectiveAgreement: new FormControl(''),
    collectiveBargainingAgreement: new FormControl(''),
    driverSalary: new FormControl(''),
    dsr: new FormControl(''),
    toxicologicalExam: new FormControl(''),
    paymentTermAddition: new FormControl(''),
  });

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  shouldDisableField(oplFields = false) {
    const status = this.dfdv?.dfdvStatusName;
    if (!status) {
      return true;
    }
    if (
      this.isUserRaizen
      && (status === DfdvStatusEnum.New || status === DfdvStatusEnum.Draft)
    ) {
      return false;
    }
    if (status === DfdvStatusEnum.Preliminary) {
      return !oplFields !== this.isUserRaizen;
    }
    return true;
  }

  getDFDV() {
    this.unSubscribe = this.driverStore$.subscribe((driverFactor) => {
      if (!driverFactor) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading?.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' }),
                );
              }
            });
          }
        });
      } else {
        this.isLoading = false;
        this.fillForm(driverFactor);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        this.shouldDisableFields = !this.dfdvUtils.isEnableToEdit();
        this.isUserRaizen = this.dfdvUtils.isUserRaizen();
      }
    });
  }

  fillForm(model: DriversFactorModel | null) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.patchValue(model ? model[key] : '');
    });
  }

  processForm(model: DriversFactorModel) {
    Object.keys(this.form.controls).forEach((key) => {
      model[key] = this.form.get(key)?.value ? this.form.get(key)?.value : '0';
    });
  }

  updateDFDVStore() {
    const model = new DriversFactorModel();
    this.processForm(model);
    this.dfdv.driverFactor = model;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'driverFactor',
        data: model,
      }),
    );
  }
}
