import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { CreateDfdvModel } from 'src/app/services/dfdv/models/createDfdv.Model';
import { DfdvModel } from 'src/app/services/dfdv/models/dfdv.model';
import { Subscription, forkJoin } from 'rxjs';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { UnitService } from 'src/app/services/unit/unit.service';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { ModalModel } from '../../pre-registration/models/modal.model';

@Component({
  selector: 'app-dfdv-create-modal',
  templateUrl: './dfdv-create-modal.component.html',
  styleUrls: ['./dfdv-create-modal.component.scss'],
})
export class DfdvCreateModalComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Output() updateDFDVList: EventEmitter<null> = new EventEmitter();

  public categories : string[] = [];
  public units : string[] = [];
  public operators : string[] = [];

  public eInputType = InputTypeEnum;

  public categoryForm = new FormControl('');
  public unitForm = new FormControl('');
  public operatorForm = new FormControl(['']);
  public dfdvIDForm = new FormControl('');

  public selectedCategory: string[] = [];
  public selectedUnits: string[] = [];
  public selectedOperators: string[] = [];

  public sub!: Subscription;

  // aux
  public showAlert: boolean = false;
  public isLoading: boolean = true;
  public btnLoading: boolean = false;
  public modalModel: ModalModel = new ModalModel();

  constructor(
    public formBuilder: FormBuilder,
    public route: Router,
    public service: DfdvService,
    public storage: StorageService,
    public operatorService: OperatorService,
    public categoryService: CategoryService,
    public unitService: UnitService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadData();
  }

  loadData() {
    forkJoin({
      opls: this.operatorService.getActiveOperators(),
      units: this.unitService.getActiveUnits(),
      categories: this.categoryService.getActiveCategories(),
    }).subscribe({
      next: ({ opls, units, categories }) => {
        this.handleGetActiveOperatorsSuccess(opls);
        this.handleGetActiveUnitsSuccess(units);
        this.handleGetActiveCategoriesSuccess(categories);
      },
      error: () => {
        this.operators = [];
        this.selectedOperators = [];
        this.units = [];
        this.selectedUnits = [];
        this.categories = [];
        this.selectedCategory = [];
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleGetActiveOperatorsSuccess(res: GetOperatorModel[]) {
    this.operators = res.map((x) => x.fantasyName);
  }

  handleGetActiveOperatorsError() {
    this.operators = [];
  }

  handleGetActiveUnitsSuccess(res: GetUnitModel[]) {
    this.units = res.map((x) => x.name);
  }

  handleGetActiveUnitsError() {
    this.units = [];
  }

  handleGetActiveCategoriesSuccess(res: GetCategoryModel[]) {
    this.categories = res.map((x) => x.name);
  }

  handleGetActiveCategoriesError() {
    this.categories = [];
  }

  cancelBtn() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.showModal = false;
    this.selectedCategory = [];
    this.selectedOperators = [];
    this.selectedUnits = [];
    this.unitForm.patchValue('');
    this.categoryForm.patchValue('');
    this.operatorForm.patchValue(['']);
    this.dfdvIDForm.patchValue('');
  }

  addBtn() {
    this.btnLoading = true;
    const dfdv: CreateDfdvModel = {
      unitName: this.unitForm.value ?? '',
      categoryName: this.categoryForm.value ?? '',
      fantasyName: this.operatorForm.value ?? [],
      dfdvId: this.dfdvIDForm.value !== '' ? this.dfdvIDForm.value : null ,
    };    
    this.sub = this.service.createDfdv(dfdv).subscribe({
      next: this.createSuccess.bind(this),
      error: this.createError.bind(this),
    });
  }

  createSuccess(dfdvs: GetDfdvModel[]) {
    this.btnLoading = false;

    const hasContent = dfdvs.length > 0;
    const isMulti = dfdvs.length > 1;

    if (hasContent) {
      if (isMulti) {
        dfdvs.forEach((dfdv) => {
          this.storage.setDfdv(dfdv);
        });

        this.modalModel.title = 'DFDVs criadas com sucesso!';
        this.modalModel.buttonLabel = 'ENTENDI';
        this.modalModel.description = 'Essas DFDVs já se encontram disponíveis para exibição na listagem abaixo.';
        this.modalModel.imagePath = '../../../assets/icons/success-600.gif';
        this.modalModel.success = true;
        this.modalModel.showModal = true;
      } else {
        this.storage.setDfdv(dfdvs[0]);
        this.route.navigate([`dfdv/${dfdvs[0].dfdvId}`]);
      }
    }
  }

  createError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.btnLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.buttonLabel = 'ENTENDI';
    this.modalModel.description = message.Message;
    this.modalModel.imagePath = '../../../assets/icons/attention-600.gif';
    this.modalModel.success = false;
    this.modalModel.title = 'Erro';
  }
  clickModal() {
    this.modalModel.showModal = false;
    if (this.modalModel.success) {
      this.updateDFDVList.emit();
      // this.route.navigate(['dfdv']);
    }
  }
}
