<app-fleet-table
  [isLoading]="isLoading"
  [title]="'Lucro'"
  [icon]="'assets/icons/Money-Icon.svg'"
  [columnsHeader]="profitHeader"
  [rowsContent]="profitContent"
  [rowsStatus]="profitRowStatus"
  [totalActiveRow]="profitActiveContent"
  [totalStopedRow]="profitStopedContent"
></app-fleet-table>
