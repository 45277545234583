import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CreateCategoryModel } from './models/create-category-model';
import { GetCategoryModel } from './models/get-category-model';
import { UpdateCategoryModel } from './models/update-category-model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService {
  constructor(private http: HttpClient) {
    super('categories');
  }

  getCategories() {
    return this.http.get<GetCategoryModel[]>(`${this.url}`);
  }

  getCategoryById(id: string) {
    return this.http.get<GetCategoryModel>(`${this.url}/${id}`);
  }

  getActiveCategories() {
    return this.http.get<GetCategoryModel[]>(`${this.url}/actives`);
  }

  createCategory(request: CreateCategoryModel) {
    return this.http.post<GetCategoryModel>(`${this.url}`, request);
  }

  updateCategory(request: UpdateCategoryModel) {
    return this.http.patch<GetCategoryModel>(`${this.url}/${request.categoryId}`, request);
  }
}
