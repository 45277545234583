import { forkJoin } from 'rxjs';
import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { RoleModel } from 'src/app/common/models/role.model';
import { StorageService } from 'src/app/common/services/storage.service';
import { GetSolicitationFilterModel } from 'src/app/services/solicitation/models/get-solicitation-filter-model';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { GetSolicitationStatusModel } from 'src/app/services/solicitation/models/get-solicitation-status-model';
import { CategoryService } from 'src/app/services/category/category.service';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { UnitService } from 'src/app/services/unit/unit.service';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { GetSolicitationTypeModel } from 'src/app/services/solicitation/models/get-SolicitationType-model';
import { LoggedUserModel } from 'src/app/common/models/logged-user.model';
import { SolicitationStatusEnum } from '../enums/solicitation-status.enum';

@Component({
  selector: 'app-solicitation-filter',
  templateUrl: './solicitation-filter.component.html',
  styleUrls: ['./solicitation-filter.component.scss'],
})

export class SolicitationFilterComponent implements OnInit {
  public form = this.formBuilder.group({
    id: [''],
    startOpeningDate: [''],
    endOpeningDate: [''],
    startCompletionDate: [''],
    endCompletionDate: [''],
    opls: [['']],
    units: [['']],
    category: [['']],
    status: [['']],
    types: [['']],
  });

  public opls: string[] = [];
  public units: string[] = [];
  public categories: string[] = [];
  public status: string[] = [];
  public types: string[] = [];
  public oplsSelected: string[] = [];
  public unitsSelected: string[] = [];
  public categoriesSelected: string[] = [];
  public statusSelected: string[] = [];
  public typesSelected: string[] = [];

  public role!: RoleModel;
  public operatorId: string = '';
  public visible: boolean = true;
  public loadedFilterFromStorage: boolean = false;

  public initialStatus: string[] = [];
  @Input() isLoadingFilter!: boolean;
  @Input() loggedUser!: LoggedUserModel;
  @Output() callbackFunc = new EventEmitter<GetSolicitationFilterModel>();
  @Output() callbackFilters = new EventEmitter<GetSolicitationFilterModel>();
  @Output() userRole = new EventEmitter<string>();

  public get ButtonColorEnum() { return ButtonColorEnum; }

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private solicitationService: SolicitationService,
    private operatorService: OperatorService,
    private categoryService: CategoryService,
    private unitService: UnitService,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getUserRole();

    if (this.role.name.includes('Admin') || this.role.name.includes('Analista')) {
      this.loadData();
    } else {
      this.loadDataForOperator();
      this.visible = !this.visible;
    }
    this.loadFormFromStorage();
    this.form.valueChanges.subscribe(() => this.setFormValuesInStorage());
  }

  loadData() {
    forkJoin({
      opls: this.operatorService.getActiveOperators(),
      units: this.unitService.getActiveUnits(),
      categories: this.categoryService.getActiveCategories(),
      status: this.solicitationService.getSolicitationStatus(),
      types: this.solicitationService.getSolicitationTypes(),
    }).subscribe({
      next: ({
        opls, units, categories, status, types,
      }) => {
        this.handleGetActiveOperatorsSuccess(opls);
        this.handleGetActiveUnitsSuccess(units);
        this.handleGetActiveCategoriesSuccess(categories);
        this.handleGetSolicitationStatusSuccess(status);
        this.handleGetSolicitationTypesSuccess(types);
      },
      error: () => {
        this.opls = [];
        this.oplsSelected = [];
        this.units = [];
        this.unitsSelected = [];
        this.categories = [];
        this.categoriesSelected = [];
        this.status = [];
        this.statusSelected = [];
        this.types = [];
        this.typesSelected = [];
        this.isLoadingFilter = false;
      },
      complete: () => {
        this.isLoadingFilter = false;
      },
    });
  }

  loadDataForOperator() {
    forkJoin({
      units: this.unitService.getUnitsByOpl(this.operatorId),
      status: this.solicitationService.getSolicitationStatus(),
      types: this.solicitationService.getSolicitationTypes(),
    }).subscribe({
      next: ({ units, status, types }) => {
        this.handleGetUnitsByOplSuccess(units);
        this.handleGetSolicitationStatusSuccess(status);
        this.handleGetSolicitationTypesSuccess(types);
      },
      error: () => {
        this.units = [];
        this.unitsSelected = [];
        this.categories = [];
        this.categoriesSelected = [];
        this.status = [];
        this.statusSelected = [];
        this.types = [];
        this.isLoadingFilter = false;
      },
      complete: () => {
        this.isLoadingFilter = false;
      },
    });
  }

  getUser() {
    const username = this.storageService.getUser();
    this.operatorId = username;
  }

  getUserRole() {
    this.role = this.storageService.getRole();
    this.userRole.emit(this.role.name);
  }

  getUnitsByOpl(operatorId: string) {
    this.unitService.getUnitsByOpl(operatorId).subscribe({
      next: this.handleGetUnitsByOplSuccess.bind(this),
      error: this.handleGetUnitsByOplError.bind(this),
    });
  }

  handleGetUnitsByOplSuccess(res: GetUnitModel[]) {
    this.units = res.map((x) => x.name);
    this.categories = res.map((x) => x.categories.map((y) => y.categoryName)).flat()
      .filter((value, index, array) => array.indexOf(value) === index);
  }

  handleGetUnitsByOplError() {
    this.units = [];
    this.categories = [];
  }

  getOpls() {
    this.operatorService.getActiveOperators().subscribe({
      next: this.handleGetActiveOperatorsSuccess.bind(this),
      error: this.handleGetActiveOperatorsError.bind(this),
    });
  }

  handleGetActiveOperatorsSuccess(res: GetOperatorModel[]) {
    this.opls = res.map((x) => x.fantasyName);
  }

  handleGetActiveOperatorsError() {
    this.opls = [];
  }

  getUnits() {
    this.unitService.getActiveUnits().subscribe({
      next: this.handleGetActiveUnitsSuccess.bind(this),
      error: this.handleGetActiveUnitsError.bind(this),
    });
  }

  handleGetActiveUnitsSuccess(res: GetUnitModel[]) {
    this.units = res.map((x) => x.name);
  }

  handleGetActiveUnitsError() {
    this.units = [];
  }

  getCategories() {
    this.categoryService.getActiveCategories().subscribe({
      next: this.handleGetActiveCategoriesSuccess.bind(this),
      error: this.handleGetActiveCategoriesError.bind(this),
    });
  }

  handleGetActiveCategoriesSuccess(res: GetCategoryModel[]) {
    this.categories = res.map((x) => x.name);
  }

  handleGetActiveCategoriesError() {
    this.categories = [];
  }

  getStatus() {
    this.solicitationService.getSolicitationStatus().subscribe({
      next: this.handleGetSolicitationStatusSuccess.bind(this),
      error: this.handleGetSolicitationStatusError.bind(this),
    });
  }

  handleGetSolicitationStatusSuccess(res: GetSolicitationStatusModel[]) {
    this.status = res.map((x) => x.name);
  }

  handleGetSolicitationStatusError() {
    this.status = [];
  }

  handleGetSolicitationTypesSuccess(res: GetSolicitationTypeModel[]) {
    this.types = res.map((x) => x.name);
  }

  clearFilterFields() {
    this.form.reset();
    this.form.patchValue({
      id: '',
      startOpeningDate: '',
      endOpeningDate: '',
      startCompletionDate: '',
      endCompletionDate: '',
      opls: [],
      units: [],
      category: [],
      status: [],
      types: [],
    });

    this.oplsSelected = [];
    this.unitsSelected = [];
    this.categoriesSelected = [];
    this.statusSelected = [];
    this.typesSelected = [];
  }

  resetFilter() {
    this.storageService.clearSolicitationFilterForm();
    this.clearFilterFields();
    this.setStatus();
    this.loadedFilterFromStorage = true;
  }

  submit() {
    if (this.callbackFunc) {
      const filters = {
        solicitationId: this.form.controls.id.value ?? '',
        startOpeningDate: this.form.controls.startOpeningDate.value ?? '',
        endOpeningDate: this.form.controls.endOpeningDate.value ?? '',
        startCompletionDate: this.form.controls.startCompletionDate.value ?? '',
        endCompletionDate: this.form.controls.endCompletionDate.value ?? '',
        fantasyName: this.role.name.includes('Operador') ? this.opls : this.form.controls.opls.value || [],
        unit: this.form.controls.units.value ?? [],
        category: this.form.controls.category.value ?? [],
        status: this.form.controls.status.value ?? [],
        types: this.form.controls.types.value ?? [],
        pageNumber: 1,
        pageSize: 10,
      } as GetSolicitationFilterModel;

      this.callbackFunc.emit(filters);
    }
  }

  setStatus() {
    this.setInitialStatusFilter();
    this.statusSelected.push(...this.initialStatus);
    this.form.controls.status.patchValue(this.initialStatus);
    this.form.markAsDirty();
  }

  loadFormFromStorage() {
    const solicitationFilter = this.storageService.getSolicitationFilterForm();
    this.loadedFilterFromStorage = true;
    if (solicitationFilter === undefined) {
      this.setStatus();
      const filter = new GetSolicitationFilterModel();
      filter.status.push(...this.initialStatus);
      this.callbackFilters.emit(filter);
      return;
    }
    this.typesSelected = solicitationFilter.types;
    this.form.controls.types.patchValue(solicitationFilter.types);
    this.oplsSelected = solicitationFilter.fantasyName;
    this.form.controls.opls.patchValue(solicitationFilter.fantasyName);
    this.unitsSelected = solicitationFilter.unit;
    this.form.controls.units.patchValue(solicitationFilter.unit);
    this.categoriesSelected = solicitationFilter.category;
    this.form.controls.category.patchValue(solicitationFilter.category);
    this.statusSelected = solicitationFilter.status;
    this.form.controls.status.patchValue(solicitationFilter.status);
    this.form.controls.id.patchValue(solicitationFilter.solicitationId);
    this.form.controls.startOpeningDate.patchValue(solicitationFilter.startOpeningDate);
    this.form.controls.endOpeningDate.patchValue(solicitationFilter.endOpeningDate);
    this.form.controls.startCompletionDate.patchValue(solicitationFilter.startCompletionDate);
    this.form.controls.endCompletionDate.patchValue(solicitationFilter.endCompletionDate);
    if (this.validForm()) {
      this.form.markAsDirty();
    }
    this.callbackFilters.emit(solicitationFilter);
  }

  setFormValuesInStorage() {
    if (!this.loadedFilterFromStorage) {
      return;
    }

    const solicitationFilter = new GetSolicitationFilterModel();
    solicitationFilter.solicitationId = this.form.controls.id.value ?? '';
    solicitationFilter.startOpeningDate = this.form.controls.startOpeningDate.value ?? '';
    solicitationFilter.endOpeningDate = this.form.controls.endOpeningDate.value ?? '';
    solicitationFilter.startCompletionDate = this.form.controls.startCompletionDate.value ?? '';
    solicitationFilter.endCompletionDate = this.form.controls.endCompletionDate.value ?? '';
    solicitationFilter.fantasyName = this.form.controls.opls.value ?? [];
    solicitationFilter.unit = this.form.controls.units.value ?? [];
    solicitationFilter.category = this.form.controls.category.value ?? [];
    solicitationFilter.status = this.form.controls.status.value ?? [];
    solicitationFilter.types = this.form.controls.types.value ?? [];

    this.storageService.setSolicitationFilterForm(solicitationFilter);
  }

  setInitialStatusFilter() {
    this.initialStatus = [];

    if (this.loggedUser.userRoleName === 'Triagem') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingScreening,
      ];
    }
    if (this.loggedUser.userRoleName === 'Analista Suprimentos') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingScreening,
        SolicitationStatusEnum.AwaitingSupplyValitation,
        SolicitationStatusEnum.AwaitingValueReview,
      ];
    }
    if (this.loggedUser.userRoleName === 'Analista Custos') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingScreening,
        SolicitationStatusEnum.AwaitingFinantialValidation,
        SolicitationStatusEnum.AwaitingValueReview,
        SolicitationStatusEnum.Closing,
      ];
    }
    if (this.loggedUser.userRoleName === 'Supervisor') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingOperationalValidation,
      ];
    }
    if (this.loggedUser.userRoleName === 'Gerente Operação') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingOperationManager,
      ];
    }
    if (this.loggedUser.userRoleName === 'Diretor Operação') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingOperationDirector,
      ];
    }
    if (this.loggedUser.userRoleName === 'Gerente Corporativo') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingCorporateManager,
      ];
    }
    if (this.loggedUser.userRoleName === 'Diretor Corporativo') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingCorporateDirector,
      ];
    }
    if (this.loggedUser.userRoleName === 'Coordenador Suprimentos') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingSupplyCoordinator,
      ];
    }
    if (this.loggedUser.userRoleName === 'Coordenador Corporativo') {
      this.initialStatus = [
        SolicitationStatusEnum.AwaitingCorporateCoordinator,
      ];
    }
    if (this.loggedUser.userRoleName === 'Analista Pagamento') {
      this.initialStatus = [SolicitationStatusEnum.AwaitingPaymentValidation];
    }
  }

  validForm() {
    let isValid = '';
    Object.keys(this.form.controls).forEach((key) => {
      isValid += this.form.get(key)?.value ?? '';
    });
    return !!isValid;
  }
}
