<div class="analyst-container">
  <div class="analyst">
    <app-card
      icon="../../../../assets/icons/categories-primary-600.svg"
      [title]="pageTitle"
      [overflow]="true"
    >
      <div class="container" body *ngIf="!isLoading">
        <div class="toggle">
          <app-toogle
            label="Admin"
            [toggleId]="'toggleAdmin'"
            [checked]="admin"
            (check)="handleAdminCheck()"
          ></app-toogle>
          <app-toogle
            label="Ativo"
            [toggleId]="'toggleActive'"
            [checked]="active"
            (check)="handleActiveCheck()"
          ></app-toogle>
        </div>
        <div class="new-container" *ngIf="!isUpdate">
          <span *ngIf="!isUpdate">
            Favor informar os seguintes dados para que possamos cadastrar o
            usuário.
          </span>
          <div class="form-container">
            <app-search
              title="ID"
              placeholder="ID do usuário"
              [item]="analystSearch.item"
              [loading]="analystSearch.loading"
              [loadingCard]="analystSearch.loadingCard"
              [selected]="analystSearch.selected"
              [control]="form.controls.analyst"
              [showModal]="analystSearch.showModal"
              [searchModel]="analystSearch.searchModel"
              [item]="analystSearch.item"
              [error]="analystSearch.error"
              [errorMessage]="analystSearch.errorMessage"
              (handleClick)="handleSearchClick($event, analystSearch)"
              (handleCardClick)="handleCardClick(analystSearch)"
              (excludeClick)="excludeCard(analystSearch)"
              (handleOutsideClick)="handleCloseModal(analystSearch)"
            ></app-search>
            <app-select
              field="Role"
              [options]="roles"
              [singleControl]="form.controls.role"
              [selected]="selectedRole"
              errorMessage="Este campo é obrigatório"
              property="name"
              [multi]="false"
              [id]="'Analyst Role'"
            ></app-select>
          </div>
        </div>
        <div class="update-container" *ngIf="isUpdate">
          <div class="id-role">
            <app-input
              label="ID"
              [type]="eInputType.text"
              [control]="updateForm.controls.analyst"
              [disabled]="true"
            ></app-input>
            <app-select
              field="Role"
              [options]="roles"
              [singleControl]="updateForm.controls.role"
              [selected]="selectedRole"
              errorMessage="Este campo é obrigatório"
              property="name"
              [multi]="false"
              [id]="'Analyst Role'"
            ></app-select>
          </div>
          <div class="name">
            <app-input
              label="Nome"
              [type]="eInputType.text"
              [control]="updateForm.controls.name"
              [disabled]="true"
            ></app-input>
          </div>
          <div class="info">
            <app-input
              label="E-mail"
              [type]="eInputType.text"
              [control]="updateForm.controls.email"
              [disabled]="true"
            ></app-input>
            <app-input
              label="Cargo"
              [type]="eInputType.text"
              [control]="updateForm.controls.title"
              [disabled]="true"
            ></app-input>
            <app-input
              label="Escritorio"
              [type]="eInputType.text"
              [control]="updateForm.controls.office"
              [disabled]="true"
            ></app-input>
            <app-input
              label="Departamento"
              [type]="eInputType.text"
              [control]="updateForm.controls.department"
              [disabled]="true"
            ></app-input>
          </div>
        </div>
        <div class="button-container">
          <app-buttons
            label="Voltar"
            [color]="eButtonsColor.primary"
            [type]="eButtonsType.secondary"
            [icon]="
              '../../../../../assets/icons/arrow-previous-primary-600.svg'
            "
            [enabled]="true"
            [animation]="false"
            (clickButton)="handleReturnClick()"
          ></app-buttons>
          <app-buttons
            label="{{ buttonLabel }}"
            [color]="eButtonsColor.primary"
            [type]="eButtonsType.primary"
            [loading]="loadingButton"
            [enabled]="form.valid || updateForm.valid"
            (clickButton)="handleButtonClick(isUpdate)"
          ></app-buttons>
        </div>
      </div>
      <div class="skeleton" body>
        <app-skeleton-input [input]="2" *ngIf="isLoading"></app-skeleton-input>
        <app-skeleton-box [box]="2" *ngIf="isLoading"></app-skeleton-box>
      </div>
    </app-card>
  </div>
</div>
<app-modal
  [showModal]="showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
