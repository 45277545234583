import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CreateSubcategoryModel } from './models/create-subcategory-model';
import { GetSubcategoryModel } from './models/get-subcategory-model';
import { UpdateSubcategoryModel } from './models/update-subcategory-model';

@Injectable({
  providedIn: 'root',
})
export class SubcategoryService extends BaseService {
  constructor(private http: HttpClient) {
    super('subcategories')
  }

  getSubcategories() {
    return this.http.get<GetSubcategoryModel[]>(this.url);
  }

  getActiveSubcategories() {
    return this.http.get<GetSubcategoryModel[]>(`${this.url}/actives`);
  }

  getSubcategoryById(subcategoryId: number) {
    return this.http.get<GetSubcategoryModel>(`${this.url}/${subcategoryId}`);
  }

  createSubcategory(request: CreateSubcategoryModel) {
    return this.http.post<GetSubcategoryModel>(this.url, request);
  }

  updateSubcategory(request: UpdateSubcategoryModel) {
    return this.http.patch<GetSubcategoryModel>(`${this.url}/${request.subcategoryId}`, request);
  }
}
