import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AnalystService } from 'src/app/services/analyst/analyst.service';
import { GetAnalystModel } from 'src/app/services/analyst/models/get-analyst-model';

@Component({
  selector: 'app-analyst-list',
  templateUrl: './analyst-list.component.html',
  styleUrls: ['./analyst-list.component.scss'],
})
export class AnalystListComponent implements OnInit {
  @Input() analystsList: GetAnalystModel[] = [];

  constructor() {}

  ngOnInit(): void {}
}
