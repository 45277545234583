export class SupportTeamTotalizerModel {
  [key: string]: any;
  totalSalaryQuantity: number = 0;
  totalSalaryValue: number = 0;
  totalSalaryExpense: number = 0;
  totalSalaryAndChargeValue: number = 0;
  totalSalaryAndChargeExpense: number = 0;
  totalBenefitValue: number = 0;
  totalBenefitExpense: number = 0;
  totalUniformValue: number = 0;
  totalUniformExpense: number = 0;
  totalLightFleetQuantity: number = 0;
  totalLightFleetValue: number = 0;
  totalLightFleetExpense: number = 0;
}
