import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { Utils } from 'src/app/common/utils/utils';
import { GetFixedCostFilterModel } from './models/get-fixedCostFilter.model';
import { PagedFixedCostModel } from './models/PagedFixedCost.model';
import { FixedCostsModel } from './models/fixedCostById/fixedCosts.model';
import { UpdateFixedCostModel } from './models/update/update-fixedCost.model';
import { FixedCostHistoryModel } from './models/fixedCostHistory/FixedCostHistory.model';

@Injectable({
  providedIn: 'root',
})
export class FixedCostService extends BaseService {
  constructor(private http: HttpClient) {
    super('fixed-costs');
  }
  getAllFixedCost(filter: GetFixedCostFilterModel) {
    const queryparams = {
      id: filter.fixedCostId ?? '',
      startDate:
        new Utils().convertFromStringToDate(filter.startDate).toJSON() ?? '',
      endDate:
        new Utils().convertFromStringToDate(filter.endDate).toJSON() ?? '',
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
    };
    const header = new HttpHeaders({ access_token_owner: 'username' });

    return this.http.get<PagedFixedCostModel>(this.url, {
      params: queryparams,
      headers: header,
    });
  }
  getFixedCostById(id: number) {
    return this.http.get<FixedCostsModel>(`${this.url}/${id}`);
  }
  getFixedCostHistory(
    fixedCostId: number,
    categoryPimsId: number,
    instanceId: string
  ) {
    return this.http.get<FixedCostHistoryModel[]>(
      `${this.url}/${fixedCostId}/categories/${categoryPimsId}/units/${instanceId}/history`
    );
  }
  CreateFixedCost() {
    return this.http.post<FixedCostsModel>(this.url, {} as any);
  }
  ConcludeFixedCost(fixedCostId: number) {
    return this.http.put<FixedCostsModel>(
      `${this.url}/${fixedCostId}/conclude`,
      {} as any
    );
  }
  UpdateFixedCost(update: UpdateFixedCostModel) {
    return this.http.patch<FixedCostsModel>(
      `${this.url}/${update.fixedCostId}`,
      update
    );
  }
  getFixedCostsReportDownload(filter: GetFixedCostFilterModel) {
    const queryparams = {
      id: filter.fixedCostId ?? '',
      startDate:
        new Utils().convertFromStringToDate(filter.startDate).toJSON() ?? '',
      endDate:
        new Utils().convertFromStringToDate(filter.endDate).toJSON() ?? '',
      pageNumber: 1,
      pageSize: 12,
    };
    const header = new HttpHeaders({ access_token_owner: 'username' });

    return this.http.get(`${this.url}/download`, {
      params: queryparams,
      headers: header,
      responseType: 'blob',
    });
  }
  getFixedCostsReportByIdDownload(fixedCostId: number) {
    const header = new HttpHeaders({ access_token_owner: 'username' });

    return this.http.get(`${this.url}/${fixedCostId}/download`, {
      headers: header,
      responseType: 'blob',
    });
  }
}
