import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-buttons',
  templateUrl: './round-buttons.component.html',
  styleUrls: ['./round-buttons.component.scss'],
})
export class RoundButtonsComponent implements OnInit {
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() enabled: boolean = false;

  iconPath:string = '';

  ngOnInit(): void {
    switch (this.type) {
      case 'primary':
        if (this.enabled) {
          this.iconPath = '../../assets/Icons/stack-purple200.svg';
        } else {
          this.iconPath = '../../assets/Icons/stack-purple400.svg';
        }
        break;

      case 'secondary':
        if (this.enabled) {
          this.iconPath = '../../assets/Icons/stack-purple800.svg';
        } else {
          this.iconPath = '../../assets/Icons/stack-purple400.svg';
        }
        break;

      case 'delete':
        if (this.enabled) {
          this.iconPath = '../../assets/Icons/trash-slate100.svg';
        } else {
          this.iconPath = '../../assets/Icons/trash-red400.svg';
        }
        break;
      default: break;
    }
  }
}
