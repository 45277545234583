<div class="fleet-container">
  <app-card
    [title]="'Totalizadores'"
    icon="assets/icons/graphic-common.svg"
    [accordion]="true"
  >
    <div *ngIf="isLoading" class="skeleton" body>
      <app-skeleton-input [input]="6"></app-skeleton-input>
    </div>
    <div *ngIf="!isLoading" class="card-body" body>
      <div class="info">
        <label class="info-title">
          <span class="info-text">KM Mensal Estimado<br> Cavalo Mecânico Linha</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.lineTractorEstimatedMonthlyKm | number: '1.2-2' }}
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">KM Mensal Estimado <br>Cavalo Mecânico Manobra</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.maneuverTractorEstimatedMonthlyKm | number: '1.2-2' }}
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Total Km Mensal Estimado <br>Cavalo Mecânico</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.tractorEstimatedMonthlyKm | number: '1.2-2' }}
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Arla</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.arlaCost | currency : 'BRL' }}
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Custo Diesel ANP</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.anpDieselCost | currency : 'BRL' }}
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Custo Diesel ANP c/ Arla</span>
        </label>
        <label class="info-value info-total">
          {{ specifications.anpDieselWithArlaCost | currency : 'BRL' }}
        </label>
      </div>
    </div>
  </app-card>
  <app-fleet-table
    [title]="'Cavalo Mecânico e Conjunto'"
    [icon]="'assets/icons/truck-common.svg'"
    [isLoading]="isLoading"
    [isTotalsBoth]="true"
    [columnsHeader]="tableHeaderColumns"
    [rowsStatus]="tableRowsStatus"
    [rowsContent]="tableRowsContent"
    [totalBothRow]="totalActiveStopColumns"
  ></app-fleet-table>
</div>