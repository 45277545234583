import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { GetValueLimitModel } from 'src/app/services/solicitation/models/get-value-limit-model';
import { UpdateValueLimitModel } from 'src/app/services/solicitation/models/update-value-limit-model';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';

@Component({
  selector: 'app-value-limit',
  templateUrl: './value-limit.component.html',
  styleUrls: ['./value-limit.component.scss'],
})
export class ValueLimitComponent implements OnInit {
  public form = this.formBuilder.group({
    operationManagerLimit: ['', [Validators.required, Validators.min(0.01)]],
    corporateManagerLimit: ['', [Validators.required, Validators.min(0.01)]],
  });

  public loading: boolean = false;
  public sending: boolean = false;
  public modalModel = new ModalModel();
  public get ButtonColorEnum() { return ButtonColorEnum; }
  public get MaskTypeEnum() { return MaskTypeEnum; }
  public isLoading: boolean = true;
  public error: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private solicitationService: SolicitationService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getValueLimits();
  }

  getValueLimits() {
    this.solicitationService.getValueLimits().subscribe({
      next: this.handleGetValueLimitsSuccess.bind(this),
      error: () => {
        this.error = true;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  handleGetValueLimitsSuccess(res: GetValueLimitModel) {
    this.loading = false;
    this.form.controls.operationManagerLimit.patchValue(res.operationManagerLimit.toString());
    this.form.controls.corporateManagerLimit.patchValue(res.corporateManagerLimit.toString());
  }

  updateValueLimits() {
    if (
      !this.form.valid
      || !this.form.controls.operationManagerLimit.value
      || !this.form.controls.corporateManagerLimit.value
    ) return;

    const request = {
      operationManagerLimit: +this.form.controls.operationManagerLimit.value,
      corporateManagerLimit: +this.form.controls.corporateManagerLimit.value,
    } as UpdateValueLimitModel;

    this.sending = true;
    this.solicitationService.updateValueLimits(request).subscribe({
      next: this.handleUpdateValueLimitsSuccess.bind(this),
      error: this.handleUpdateValueLimitsError.bind(this),
    });
  }

  handleUpdateValueLimitsSuccess() {
    this.sending = false;
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Valores atualizados com sucesso!';
    this.modalModel.description = 'A partir de agora os novos valores informados já estão em vigor.';
    this.modalModel.buttonLabel = 'Concluir';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  handleUpdateValueLimitsError(res:HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.sending = false;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível atualizar os valores!';
    this.modalModel.description = message?.Message;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  clickModal() {
    this.modalModel.showModal = false;

    if (this.modalModel.success) {
      this.navigateBack();
    }
  }

  navigateBack() {
    this.router.navigate(['admin']);
  }

  reload() {
    this.isLoading = true;
    this.getValueLimits();
  }
}
