import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressResponse } from '../models/address-response.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  public URI: string = 'https://viacep.com.br/ws';
  public type: string = 'json/';

  constructor(private http: HttpClient) { }

  searchAddress(uf: string, city: string, address: string) {
    return this.http.get<AddressResponse[]>(`${this.URI}/${uf}/${city}/${address}/${this.type}`);
  }

  searchAddressByCep(cep: string) {
    return this.http.get<AddressResponse>(`${this.URI}/${cep}/${this.type}`);
  }
}
