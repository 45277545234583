<div class="container">
  <app-card
    icon="../../../../../assets/icons/subcategories-primary-600.svg"
    title="{{ pageTitle }}"
  >
    <div class="subcategory-container" body *ngIf="!isLoading">
      <app-toogle
        label="Ativo"
        [checked]="checked"
        (check)="handleToggleCheck()"
      ></app-toogle>
      <label>
        Favor informar os seguintes dados, para que possamos cadastrar uma nova
        subcategoria.
      </label>
      <div class="input">
        <app-input
          label="Nome"
          [placeholder]="'Nome da Subcategoria'"
          [type]="eInputType.text"
          errorMessage="Este campo é obrigatório"
          placeholder=""
          [control]="form.controls.name"
          [maxlength]="50"
        ></app-input>
      </div>
      <div class="button">
        <app-secondary-button
          label="Voltar"
          [enabled]="true"
          icon="../../../assets/icons/arrow-previous-primary-600.svg"
          (clickButton)="goBack()"
        ></app-secondary-button>
        <app-buttons
          label="{{ buttonLabel }}"
          [color]="eButtonsColor.primary"
          [type]="eButtonsType.primary"
          [loading]="loadingButton"
          [enabled]="enableButton"
          (clickButton)="
            this.id ? handleUpdateButtonClick() : handleButtonClick()
          "
        ></app-buttons>
      </div>
    </div>
    <div class="skeleton" body>
      <app-skeleton-text [text]="1" *ngIf="isLoading"></app-skeleton-text>
      <app-skeleton-box [box]="1" *ngIf="isLoading"></app-skeleton-box>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
