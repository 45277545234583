import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { GetOperationPimsModel } from './model/get-operation-pims.model';

@Injectable({
  providedIn: 'root',
})
export class OperationPimsService extends BaseService {
  constructor(private http: HttpClient) {
    super('operations');
  }

  getOperations(search: string) {
    return this.http.get<GetOperationPimsModel[]>(`${this.urlPims}`, {
      params: {
        filters: search,
      },
    });
  }
}
