export class OperationalTeamExpenseModel {
  standardDriverPaycheckAndCharge: number = 0;
  nightDriverPaycheckAndCharge: number = 0;
  standardDriverToxicologicalExam: number = 0;
  nightDriverToxicologicalExam: number = 0;
  standardDriverBenefit: number = 0;
  nightDriverBenefit: number = 0;
  standardDriverVariableSalaryWithCharge: number = 0;
  nightDriverVariableSalaryWithCharge: number = 0;
  administrativeUniform: number = 0;
  totalOperationalTeamExpense: number = 0;
}
