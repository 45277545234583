<div class="scope-container">
  <section>
    <header>
      <app-sumarize-header currentPageTitle="Escopo"></app-sumarize-header>
    </header>
    <div class="tab-container">
      <app-tab #tabScope>
        <app-tab-item title="Equipamentos" #tabEquipment>
          <app-scope-equipment
            [form]="equipmentForm"
            [shouldDisableFields]="shouldDisableFields"
            [disableFieldOPL]="disableFieldOPL"
            [disableFieldRaizen]="disableFieldRaizen"
            [isLoading]="isLoading"
            (updateValue)="updateDFDVStore()"
          ></app-scope-equipment>
        </app-tab-item>
        <app-tab-item title="Locação" #tabRental>
          <app-scope-rental
            [form]="rentalForm"
            [shouldDisableFields]="shouldDisableFields"
            [disableFieldOPL]="disableFieldOPL"
            [disableFieldRaizen]="disableFieldRaizen"
            [isLoading]="isLoading"
            (updateValue)="updateDFDVStore()"
          ></app-scope-rental>
        </app-tab-item>
        <app-tab-item title="Consumo" #tabConsumption>
          <app-scope-consumption
            [form]="consumptionForm"
            [shouldDisableFields]="shouldDisableFields"
            [disableFieldOPL]="disableFieldOPL"
            [disableFieldRaizen]="disableFieldRaizen"
            [isLoading]="isLoading"
            (updateValue)="updateDFDVStore()"
          ></app-scope-consumption
        ></app-tab-item>
        <app-tab-item title="Taxas" #tabFee>
          <app-scope-fee
            [form]="feeForm"
            [shouldDisableFields]="shouldDisableFields"
            [disableFieldOPL]="disableFieldOPL"
            [disableFieldRaizen]="disableFieldRaizen"
            [isLoading]="isLoading"
            (updateValue)="updateDFDVStore()"
          ></app-scope-fee>
        </app-tab-item>
      </app-tab>
    </div>
  </section>
</div>
