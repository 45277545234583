export class FleetModalNewConfigModel {
  index!: number;
  active!: boolean;
  tractorQuantity!: string;
  tractorFinancingStatus!: string;
  tractorManufacturer!: string;
  tractorModel!: string;
  tractorYear!: string;
  tractorMonthYearEntry!: string;
  tractorResidual!: string;
  trailerFinancingStatus!: string;
  trailerImplement!: string;
  trailerMaterial!: string;
  trailerType!: string;
  trailerYear!: string;
  trailerMonthYearEntry!: string;
  trailerResidual!: string;
}
