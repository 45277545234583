<app-card
  title="Grupo B - Base Incidência Cumulativa"
  icon="../../../../../assets/icons/Money-Icon.svg"
  [accordion]="true"
>
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-input [input]="6"></app-skeleton-input>
  </div>
  <section *ngIf="!isLoading" actions class="actions">
    Total:
    <div class="total">{{ totalBaseIncidenceCount | number: '1.2-2' }} %</div>
  </section>
  <div *ngIf="!isLoading" class="fee-container" body>
    <div class="input-container">
      <app-input
        label="Férias Proporcionais Indenizadas 1º Ano"
        [placeholder]="'0,00'"
        [disabled]="true"
        [control]="form.controls.proportionalCompensatedVacation"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        (updateValue)="handleUpdateValue()"
      ></app-input>
      <app-input
        label="1/3 Férias"
        [placeholder]="'0,00'"
        [disabled]="true"
        [control]="form.controls.oneThirdVacation"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        (updateValue)="handleUpdateValue()"
      ></app-input>
      <app-input
        label="Aviso Prévio"
        [placeholder]="'0,00'"
        [disabled]="true"
        [control]="form.controls.earlyWarning"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        (updateValue)="handleUpdateValue()"
      ></app-input>
      <app-input
        label="Ausências Legais (Aux. Doença, etc)"
        [placeholder]="'0,00'"
        [disabled]="true"
        [control]="form.controls.legalAbsences"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        (updateValue)="handleUpdateValue()"
      ></app-input>
      <app-input
        label="13º Salário"
        [placeholder]="'0,00'"
        [disabled]="true"
        [control]="form.controls.thirteenthSalary"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        (updateValue)="handleUpdateValue()"
      ></app-input>
    </div>
  </div>
</app-card>
