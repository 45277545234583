import { SupportTeamDetailModel } from "./support-team-detail.model";
import { SupportTeamTotalizerModel } from "./support-team-totalizer.model";


export class OperationalSupportModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.total = new SupportTeamTotalizerModel();
    this.generalBuyer = new SupportTeamDetailModel();
    this.supervisor = new SupportTeamDetailModel();
    this.instructor = new SupportTeamDetailModel();
    this.fieldLeader = new SupportTeamDetailModel();
    this.nightFieldLeader = new SupportTeamDetailModel();
    this.yardLeader = new SupportTeamDetailModel();
    this.nightYardLeader = new SupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  generalBuyer: SupportTeamDetailModel = new SupportTeamDetailModel();
  supervisor: SupportTeamDetailModel = new SupportTeamDetailModel();
  instructor: SupportTeamDetailModel = new SupportTeamDetailModel();
  fieldLeader: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightFieldLeader: SupportTeamDetailModel = new SupportTeamDetailModel();
  yardLeader: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightYardLeader: SupportTeamDetailModel = new SupportTeamDetailModel();
}
