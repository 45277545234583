import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { UnitPimsModel } from './models/unit-pims.model';

@Injectable({
  providedIn: 'root',
})
export class UnitPimsService extends BaseService {
  constructor(private http: HttpClient) {
    super('units');
  }

  getUnitPims(instanceId: string) {
    return this.http.get<UnitPimsModel[]>(`${this.urlPims}/${instanceId}`);
  }
}
