<div class="container" (click)="handleDataClick(analyst.analystId)">
  <div class="analyst-list-container">
    <div class="grid">
      <div class="analystId">{{ analyst.analystId }}</div>
      <div class="name">{{ analyst.name }}</div>
      <div class="role-name">{{ analyst.analystRoleName }}</div>
      <div class="email">{{ analyst.email }}</div>
      <div class="status">
        <app-status
          [color]="analyst.active ? StatusColorEnum.success : StatusColorEnum.warning"
          [image]="
          analyst.active
            ? './assets/icons/active.svg'
            : './assets/icons/Exclude.svg'
        " [status]="analyst.active ? 'Ativo' : 'Inativo'">
        </app-status>
      </div>
    </div>
  </div>
</div>
