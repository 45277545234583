import { FleetCapitalModel } from './fleet-capital.model';
import { FleetProfitModel } from './fleet-profit.model';
import { FleetTaxModel } from './fleet-tax.model';
import { FleetTractorModel } from './fleet-tractor.model';
import { FleetTrailerModel } from './fleet-trailer.model';
import { FleetVariableCostModel } from './fleet-variable-cost.model';

export class FleetConfigurationItemsModel {
  [key: string]: any;

  tractor!: FleetTractorModel;
  trailer!: FleetTrailerModel;
  tax!: FleetTaxModel;
  capitalRemunerationAndDepreciation!: FleetCapitalModel;
  profit!: FleetProfitModel;
  variableCost!: FleetVariableCostModel;
}
