<div class="plant-container">
  <div class="plant-card">
    <app-card
      title="Taxas"
      icon="../../../../../assets/icons/money-primary.svg"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="6"></app-skeleton-input>
      </div>
      <section *ngIf="!isLoading" class="body" body>
        <p>Informar os valores gastos <b>por equipamento</b> anualmente.</p>
        <div class="body-input">
          <app-input
            label="Remuneração Capital"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.capitalRemuneration"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="DPVAT"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.dpvat"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          >
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  <b>DPVAT:</b> Danos Pessoais por Veículos Automotores
                  Terrestres
                </div>
              </div>
            </app-tooltip>
          </app-input>
          <app-input
            label="RCTR-C"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.rctrc"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          >
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  <b>RCTR-C:</b> Responsabilidade Civil do Transportador
                  Rodoviário de Carga
                </div>
              </div>
            </app-tooltip>
          </app-input>
          <app-input
            label="RCFV"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.rcfv"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          >
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  <b>RCF-V:</b> Responsabilidade Civil Facultativa de Veículos
                </div>
              </div>
            </app-tooltip>
          </app-input>
          <app-input
            label="Licenciamento"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.licensing"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="IPVA - Desconto Parcela Única"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.ipvaWithDiscount"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldOPL"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <div [class]="'checkbox-ipva ' + (shouldDisableFields || disableFieldOPL ? 'disabled' : '') ">
            <app-check-box
              (check)="handleCheck(true)"
              (removeCheck)="handleCheck(false)"
              [value]="form.controls.hasIpvaAndLicensingFee.value ?? false"
              [disabled]="shouldDisableFields || disableFieldOPL"
            ></app-check-box>
            <span>IPVA + Taxa Licenciamento?</span>
          </div>
          <app-input
            label="IPVA + Taxa Licenciamento"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.ipvaAndLicensingFee"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldOPL || disableIpva"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="Seguro Obrigatório"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.mandatoryInsurance"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldOPL"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Taxa de Emissão do CRLV"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.crlvEmissionFee"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldOPL"
            (updateValue)="handleUpdateValue()"
          >
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  <b>CRLV:</b> Certificado de Registro e Licenciamento de
                  Veículo
                </div>
              </div>
            </app-tooltip>
          </app-input>
        </div>
      </section>
    </app-card>
  </div>
</div>
