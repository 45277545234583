<app-card
  title="Categorias"
  icon="../../../../assets/icons/categories-primary-600.svg"
  [paddingHorizontal]="false"
>
  <section body *ngIf="!isLoading && categories.length > 0 && !error">
    <app-category-list-item
      *ngFor="let category of categories"
      [category]="category"
    ></app-category-list-item>
  </section>
  <div class="skeleton" body *ngIf="isLoading">
    <app-skeleton-line [line]="5"></app-skeleton-line>
  </div>
  <div
    body
    class="not-found"
    *ngIf="!isLoading && categories.length === 0 && !error"
  >
    <app-not-found></app-not-found>
  </div>
  <div
    body
    class="error"
    *ngIf="!isLoading && categories.length === 0 && error"
  >
    <app-error (reloadCallBack)="reload()"></app-error>
  </div>
</app-card>
