/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, map, takeUntil } from 'rxjs';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DriversFactorModel } from 'src/app/services/dfdv/driversFactor/models/driversFactor.model';
import { FleetModel } from 'src/app/services/dfdv/fleet/models/fleet.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';
import { SummaryModel } from 'src/app/services/dfdv/summary/models/summary.model';
import { TaxModel } from 'src/app/services/dfdv/taxes/models/tax.model';
import { CVMaintenanceModel } from 'src/app/services/dfdv/variable-costs/models/variable-costs.model';
import { ColumnModel } from 'src/app/shared/dynamic-table/model/columnModel';
import { Utils } from 'src/app/common/utils/utils';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { GeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/general-costs.model';
import { BenefitModel } from 'src/app/services/dfdv/benefit/models/benefit.model';
import { OperationalTeamModel } from 'src/app/services/dfdv/operational-team/models/operational-team.model';
import { SupportTeamModel } from 'src/app/services/dfdv/models/support-team/support-team.model';
import { GetGeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/get-general-cost.model';
import { loadGetDfdvModel } from '../../../../common/store/dfdv/dfdv.actions';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  private dfdvStore$: Observable<GetDfdvModel> = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv));
  private loadingStore$: Observable<ILoadingState> = this.store
    .select('loading')
    .pipe((x) => x);

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;
  public dfdvUtils!: DfdvUtils;

  public dfdvLoading: boolean = false;
  public updated: boolean = false;

  public summary: SummaryModel | undefined = new SummaryModel();
  public tax: TaxModel = new TaxModel();
  public scope: ScopeDfdvModel = new ScopeDfdvModel();
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public cvMaintenance: CVMaintenanceModel = new CVMaintenanceModel();
  public driverFactor: DriversFactorModel = new DriversFactorModel();
  public fleet: FleetModel = new FleetModel();
  public generalCosts: GetGeneralCostsModel = new GetGeneralCostsModel();
  public benefit: BenefitModel = new BenefitModel();
  public operationalTeam: OperationalTeamModel = new OperationalTeamModel();
  public supportTeam: SupportTeamModel = new SupportTeamModel();

  public columns: ColumnModel[] = [
    { name: 'description', type: 'text' } as ColumnModel,
    { name: 'value', type: 'text' } as ColumnModel,
  ];

  public fixedRemunerationRows: FormGroup[] = [];
  public operationalTeamRows: FormGroup[] = [];
  public personalExpenseRows: FormGroup[] = [];
  public operationalExpensesRows: FormGroup[] = [];
  public lineVariableCostsRows: FormGroup[] = [];

  constructor(
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.unSubscribe = this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');

      this.loadingStore$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isLoading) => {
          this.dfdvLoading = isLoading.loading;
        });

      if (!this.dfdvLoading) {
        this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((dfdv) => {
          if (dfdv) {
            this.dfdv = dfdv;

            this.dfdvUtils = new DfdvUtils(
              dfdv,
              this.storageService.getRole(),
              this.store
            );
            if (dfdv.tax) this.tax = dfdv.tax;
            if (dfdv.scope) this.scope = dfdv.scope;
            if (dfdv.cvMaintenance) this.cvMaintenance = dfdv.cvMaintenance;
            if (dfdv.driverFactor) this.driverFactor = dfdv.driverFactor;
            if (dfdv.fleet) this.fleet = dfdv.fleet;

            if (this.updated) return;
            if (this.dfdvUtils.isEnableToEdit()) {
              if (dfdv.generalCosts) this.generalCosts = dfdv.generalCosts;
              if (dfdv.benefit) this.benefit = dfdv.benefit;
              if (dfdv.operationalTeam)
                this.operationalTeam = dfdv.operationalTeam;
              if (dfdv.supportTeam) this.supportTeam = dfdv.supportTeam;
              this.updated = true;
              this.summary = this.dfdvUtils.updateSummary();
              this.updated = this.summary !== undefined;
            } else if (dfdv.summary) this.summary = { ...dfdv.summary };

            if (this.summary !== undefined) {
              this.generateFixedRemunerationTableData();
              this.generateOperationalTeamRows();
              this.generatePersonalExpenseRows();
              this.generateOperationalExpensesRows();
              this.generateLineVariableCostsRows();
            }
          }
          if (!dfdv.dfdvId || dfdv.dfdvId !== +(id ?? 0)) {
            this.store.dispatch(loadGetDfdvModel({ id: id ?? '0' }));
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateFixedRemunerationTableData(): void {
    if (this.summary === undefined) return;
    const dataArray: { description: string; value: string | null }[] = [
      {
        description: 'Remuneração Capital do Cavalo Mecânico',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTractorCapitalRemuneration,
          'BRL'
        ),
      },
      {
        description: 'Valor do Cavalo Mecânico',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTractorCost,
          'BRL'
        ),
      },
      {
        description: 'Remuneração Capital',
        value: `${this.decimalPipe.transform(
          this.scope.fee.capitalRemuneration,
          '1.2-2'
        )}%`,
      },
      {
        description: 'Remuneração Capital do Conjunto',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTrailerCapitalRemuneration,
          'BRL'
        ),
      },
      {
        description: 'Valor do Conjunto',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTrailerCost,
          'BRL'
        ),
      },
      {
        description: 'Remuneração Capital',
        value: `${this.decimalPipe.transform(
          this.scope.fee.capitalRemuneration,
          '1.2-2'
        )}%`,
      },
      {
        description: 'Remuneração Capital sobre Impostos',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveCapitalRemunerationOnTaxes,
          'BRL'
        ),
      },
      {
        description: 'Depreciação do Cavalo Mecânico',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTractorDepreciation,
          'BRL'
        ),
      },
      {
        description: 'Valor Líquido do Cavalo Mecânico p/ Depreciação',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTractorNetValueDepreciation,
          'BRL'
        ),
      },
      {
        description: 'Residual do Cavalo Mecânico',
        value: `${this.decimalPipe.transform(
          this.fleet.active.averageActiveTractorResidual,
          '1.2-2'
        )}%`,
      },
      {
        description: 'Período de Depreciação do Cavalo Mecânico (em meses)',
        value: `${this.fleet.active.averageActiveTractorDepreciationPeriod}`,
      },
      {
        description: 'Depreciação do Conjunto',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTrailerDepreciation,
          'BRL'
        ),
      },
      {
        description: 'Valor Líquido do Conjunto p/ Depreciação',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveTrailerNetValueDepreciation,
          'BRL'
        ),
      },
      {
        description: 'Residual do Conjunto',
        value: `${this.decimalPipe.transform(
          this.fleet.active.averageActiveTrailerResidual,
          '1.2-2'
        )}%`,
      },
      {
        description: 'Período de Depreciação do Conjunto (em meses)',
        value: `${this.fleet.active.averageActiveTrailerDepreciationPeriod}`,
      },
      {
        description: 'Plano de Manutenção do Cavalo Mecânico',
        value: this.currencyPipe.transform(
          this.summary.fixedRemunerationExpense.tractorPlanningMaintenance,
          'BRL'
        ),
      },
      {
        description: 'Plano em R$/KM',
        value: this.currencyPipe.transform(
          this.cvMaintenance.tractorFactoryMaintenance,
          'BRL'
        ),
      },
      {
        description: 'KM Plano (12 meses)',
        value: `${this.decimalPipe.transform(
          this.cvMaintenance.kmPlanned,
          '2.'
        )}`,
      },
      {
        description: 'Seguro/Licenciamento',
        value: this.currencyPipe.transform(
          this.summary.fixedRemunerationExpense.insuranceAndLicensing,
          'BRL'
        ),
      },
      {
        description: 'IPVA + Taxa de Licenciamento',
        value: this.currencyPipe.transform(
          this.fleet.active.averageActiveIpvaAndLicensingFee,
          'BRL'
        ),
      },
    ];

    this.fixedRemunerationRows = [];

    dataArray.forEach((obj) => {
      this.fixedRemunerationRows.push(this.formBuilder.group(obj));
    });
  }

  private generateOperationalTeamRows(): void {
    if (this.summary === undefined) return;
    const dataArray: { description: string; value: string | null }[] = [
      {
        description: 'Ordernado + Provisão e Encargos (Motorista Padrão)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.standardDriverPaycheckAndCharge,
          'BRL'
        ),
      },
      {
        description: 'Ordernado + Provisão e Encargos (Motorista Noturno)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.nightDriverPaycheckAndCharge,
          'BRL'
        ),
      },
      {
        description: 'Exame Toxicológico (Motorista Padrão)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.standardDriverToxicologicalExam,
          'BRL'
        ),
      },
      {
        description: 'Exame Toxicológico (Motorista Noturno)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.nightDriverToxicologicalExam,
          'BRL'
        ),
      },
      {
        description: 'Benefícios (Motorista Padrão)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.standardDriverBenefit,
          'BRL'
        ),
      },
      {
        description: 'Benefícios (Motorista Noturno)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.nightDriverBenefit,
          'BRL'
        ),
      },
      {
        description: 'Remuneração Variável (Motorista Padrão)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense
            .standardDriverVariableSalaryWithCharge,
          'BRL'
        ),
      },
      {
        description: 'Remuneração Variável (Motorista Noturno)',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense
            .nightDriverVariableSalaryWithCharge,
          'BRL'
        ),
      },
      {
        description: 'Uniforme Equipe Administrativa/EPIs',
        value: this.currencyPipe.transform(
          this.summary.operationalTeamExpense.administrativeUniform,
          'BRL'
        ),
      },
      {
        description: 'Fator Equipe Motorista Padrão',
        value: `${this.decimalPipe.transform(
          this.driverFactor.standardDriverTeamFactor ?? 0,
          '1.2-2'
        )}`,
      },
      {
        description: 'Fator Equipe Motorista Noturno',
        value: `${this.decimalPipe.transform(
          this.driverFactor.nightDriverTeamFactor ?? 0,
          '1.2-2'
        )}`,
      },
    ];

    this.operationalTeamRows = [];

    dataArray.forEach((obj) => {
      this.operationalTeamRows.push(this.formBuilder.group(obj));
    });
  }

  private generatePersonalExpenseRows(): void {
    if (this.summary === undefined) return;
    const dataArray: { description: string; value: string | null }[] = [
      {
        description: 'Gerência',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.managementExpense,
          'BRL'
        ),
      },
      {
        description: 'Apoio Operacional',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.operationalSupportExpense,
          'BRL'
        ),
      },
      {
        description: 'Manutenção e Almoxarifado',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.maintenanceAndWarehouseExpense,
          'BRL'
        ),
      },
      {
        description: 'RH e SSMA',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.hrAndSsmaExpense,
          'BRL'
        ),
      },
      {
        description: 'Admininistrativo/Financeiro',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.adminAndFinancialExpense,
          'BRL'
        ),
      },
      {
        description: 'Uniformes/EPIs',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.totalUniformExpense,
          'BRL'
        ),
      },
      {
        description: 'Benefícios',
        value: this.currencyPipe.transform(
          this.summary.personalExpense.totalBenefitExpense,
          'BRL'
        ),
      },
    ];

    this.personalExpenseRows = [];

    dataArray.forEach((obj) => {
      this.personalExpenseRows.push(this.formBuilder.group(obj));
    });
  }

  private generateOperationalExpensesRows(): void {
    if (this.summary === undefined) return;
    const dataArray: { description: string; value: string | null }[] = [
      {
        description: 'Aluguel Veículos',
        value: this.currencyPipe.transform(
          this.summary.operationalExpense.lightFleetExpense,
          'BRL'
        ),
      },
      {
        description: 'Comunicação Móvel Administrativo (Celular/Rádio)',
        value: this.currencyPipe.transform(
          this.summary.operationalExpense.mobileCommunicationExpense,
          'BRL'
        ),
      },
      {
        description: 'Material Escritório/Admninistrativo',
        value: this.currencyPipe.transform(
          this.summary.operationalExpense.administrativeExpense,
          'BRL'
        ),
      },
    ];

    this.operationalExpensesRows = [];

    dataArray.forEach((obj) => {
      this.operationalExpensesRows.push(this.formBuilder.group(obj));
    });
  }

  private generateLineVariableCostsRows(): void {
    if (this.summary === undefined) return;
    const dataArray: { description: string; value: string | null }[] = [
      {
        description: 'Custos Variáveis por KM Rodado',
        value: this.currencyPipe.transform(
          this.summary.lineVariableCostExpense.variableCostsPerDrivenKm,
          'BRL'
        ),
      },
      {
        description: 'Manutenção do Conjunto em R$/KM',
        value: this.currencyPipe.transform(
          this.fleet.averageTrailerMaintenance,
          'BRL'
        ),
      },
      {
        description: 'Pneus em R$/KM',
        value: this.currencyPipe.transform(
          this.summary.lineVariableCostExpense.tireExpense,
          'BRL'
        ),
      },
      {
        description: 'Lavagem em R$/KM',
        value: this.currencyPipe.transform(
          this.fleet.averageWashingCost,
          'BRL'
        ),
      },
      {
        description: 'Combustível em R$/KM',
        value: this.currencyPipe.transform(
          this.summary.lineVariableCostExpense.fuelExpense,
          'BRL'
        ),
      },
      {
        description: 'KM Mensal Estimado',
        value: this.decimalPipe.transform(
          this.scope.equipment?.lineTractorEstimatedMonthlyKm ?? 0,
          '1.2-2'
        ),
      },
    ];

    this.lineVariableCostsRows = [];

    dataArray.forEach((obj) => {
      this.lineVariableCostsRows.push(this.formBuilder.group(obj));
    });
  }
}
