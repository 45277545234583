import { Component, OnInit } from '@angular/core';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { Router } from '@angular/router';
import { UnitService } from 'src/app/services/unit/unit.service';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss'],
})
export class UnitComponent implements OnInit {
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public enableButton = true;
  public loadingButton = false;
  public unitList: GetUnitModel[] = [];
  public isLoading: boolean = true;
  public error: boolean = false;

  constructor(
    private router: Router,
    private unitService: UnitService,
  ) { }

  ngOnInit(): void {
    this.getUnits();
  }

  getUnits() {
    this.unitService.getUnits().subscribe({
      next: this.requestSuccess.bind(this),
      error: this.requestError.bind(this),
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  requestSuccess(res: GetUnitModel[]) {
    this.unitList = res;
  }

  requestError() {
    this.error = true;
    this.isLoading = false;
  }

  handleButtonClick() {
    this.router.navigate(['/admin/unit/new']);
  }

  reload() {
    this.isLoading = true;
    this.getUnits();
  }
}
