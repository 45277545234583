export class FleetActiveModel {
  [key: string]: any;

  totalActiveQuantity!: number;
  averageActiveTractorCost!: number;
  averageActiveTractorTireCost!: number;
  averageActiveTractorPisCofinsCost!: number;
  averageActiveTractorIcmsCost!: number;
  averageActiveTractorNetValueDepreciation!: number;
  averageActiveTractorNetValueRemuneration!: number;
  averageActiveTractorDepreciationPeriod!: number;
  averageActiveTractorUsageCycle!: number;
  averageActiveTractorResidual!: number;
  averageActiveTrailerCost!: number;
  averageActiveTrailerTireCost!: number;
  averageActiveTrailerBaseProfit!: number;
  averageActiveTrailerPisCofinsCost!: number;
  averageActiveTrailerIcmsCost!: number;
  averageActiveTrailerNetValueDepreciation!: number;
  averageActiveTrailerNetValueRemuneration!: number;
  averageActiveTrailerDepreciationPeriod!: number;
  averageActiveTrailerUsageCycle!: number;
  averageActiveTrailerResidual!: number;
  averageActiveDpvat!: number;
  averageActiveIpvaAndLicensingFee!: number;
  averageActiveAutoInsurance!: number;
  averageActiveRcfv!: number;
  averageActiveLicensing!: number;
  averageActiveTractorDepreciation!: number;
  averageActiveTrailerDepreciation!: number;
  averageActiveTractorCapitalRemuneration!: number;
  averageActiveTrailerCapitalRemuneration!: number;
  averageActiveTractorCapitalRemunerationOnTaxes!: number;
  averageActiveTrailerCapitalRemunerationOnTaxes!: number;
  averageActiveCapitalRemunerationOnTaxes!: number;
  averageActiveTotalRemuneration!: number;
  averageActiveTotalProfit!: number;
  averageActiveFixedProfit!: number;
  averageActiveVariableProfit!: number;

  constructor() {
    this.totalActiveQuantity = 0;
    this.averageActiveTractorCost = 0;
    this.averageActiveTractorTireCost = 0;
    this.averageActiveTractorPisCofinsCost = 0;
    this.averageActiveTractorIcmsCost = 0;
    this.averageActiveTractorNetValueDepreciation = 0;
    this.averageActiveTractorNetValueRemuneration = 0;
    this.averageActiveTractorDepreciationPeriod = 0;
    this.averageActiveTractorUsageCycle = 0;
    this.averageActiveTractorResidual = 0;
    this.averageActiveTrailerCost = 0;
    this.averageActiveTrailerTireCost = 0;
    this.averageActiveTrailerBaseProfit = 0;
    this.averageActiveTrailerPisCofinsCost = 0;
    this.averageActiveTrailerIcmsCost = 0;
    this.averageActiveTrailerNetValueDepreciation = 0;
    this.averageActiveTrailerNetValueRemuneration = 0;
    this.averageActiveTrailerDepreciationPeriod = 0;
    this.averageActiveTrailerUsageCycle = 0;
    this.averageActiveTrailerResidual = 0;
    this.averageActiveDpvat = 0;
    this.averageActiveIpvaAndLicensingFee = 0;
    this.averageActiveAutoInsurance = 0;
    this.averageActiveRcfv = 0;
    this.averageActiveLicensing = 0;
    this.averageActiveTractorDepreciation = 0;
    this.averageActiveTrailerDepreciation = 0;
    this.averageActiveTractorCapitalRemuneration = 0;
    this.averageActiveTrailerCapitalRemuneration = 0;
    this.averageActiveTractorCapitalRemunerationOnTaxes = 0;
    this.averageActiveTrailerCapitalRemunerationOnTaxes = 0;
    this.averageActiveCapitalRemunerationOnTaxes = 0;
    this.averageActiveTotalRemuneration = 0;
    this.averageActiveTotalProfit = 0;
    this.averageActiveFixedProfit = 0;
    this.averageActiveVariableProfit = 0;
  }
}
