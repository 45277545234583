/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
import { Store } from '@ngrx/store';
import { DriversFactorModel } from 'src/app/services/dfdv/driversFactor/models/driversFactor.model';
/* eslint-disable max-len */
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { FormBuilder } from '@angular/forms';
import { SupportTeamModel } from 'src/app/services/dfdv/models/support-team/support-team.model';

import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ChargeModel } from 'src/app/services/dfdv/charge/models/charges.model';
import { SupportTeamDetailModel } from 'src/app/services/dfdv/models/support-team/support-team-detail.model';
import { SupportTeamTotalizerModel } from 'src/app/services/dfdv/models/support-team/support-team-totalizer.model';
import { ScopeConsumptionModel } from 'src/app/services/dfdv/scope/model/scope-consumption/scope-consumption.model';
import { ScopeEquipmentModel } from 'src/app/services/dfdv/scope/model/scope-equipment/scope-equipment.model';
import { ScopeRentalModel } from 'src/app/services/dfdv/scope/model/scope-rental/scope-rental.model';
import { SupportTeamFleetModel } from 'src/app/services/dfdv/models/support-team/support-team-fleet.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BenefitModel } from 'src/app/services/dfdv/benefit/models/benefit.model';
import { GetGeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/get-general-cost.model';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { ModalModel } from '../../../pre-registration/models/modal.model';
import { SupportTeamTotalComponent } from './support-team-total/support-team-total.component';

@Component({
  selector: 'app-support-team',
  templateUrl: './support-team.component.html',
  styleUrls: ['./support-team.component.scss'],
})
export class SupportTeamComponent implements OnInit, OnDestroy {
  // Select Options
  public units: string[] = [];
  public unitValues: string[] = [];
  public opls: string[] = [];
  public oplValues: string[] = [];

  // Enums
  public ButtonColorEnum = ButtonColorEnum;

  // Aux

  public isLoadingEQ: boolean = false;
  public modalModel: ModalModel = new ModalModel();
  public firstTabError: string = '';
  public isLoading: boolean = false;
  public shouldDisableFields: boolean = false;
  public showSkeleton: boolean = false;
  public dfdvUtils!: DfdvUtils;

  // Model
  public supportTeamModel: SupportTeamModel = new SupportTeamModel();
  public chargesModel: ChargeModel = new ChargeModel();
  public scopeModel: ScopeDfdvModel = {
    consumption: new ScopeConsumptionModel(),
    equipment: new ScopeEquipmentModel(),
    rental: new ScopeRentalModel(),
  } as ScopeDfdvModel;
  public driversFactorModel: DriversFactorModel = new DriversFactorModel();
  public benefitModel: BenefitModel = new BenefitModel();
  public generalCostsModel: GetGeneralCostsModel = new GetGeneralCostsModel();
  public dfdv!: GetDfdvModel;

  // Store
  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  supportTeamStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.supportTeam));

  scopeStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.scope));
  driverStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.driverFactor));
  chargeStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.charge));
  benefitStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.benefit));
  generalCostsStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.generalCosts));

  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  constructor(
    private service: DfdvService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.showSkeleton = true;
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getShouldDisableFields() {
    if (this.dfdvUtils) {
      const enableForOPL =
        !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
      const enableForRaizen =
        this.dfdvUtils.isUserRaizen() &&
        (this.dfdvUtils.isNew() || this.dfdvUtils.isDraft());
      this.shouldDisableFields = !(enableForOPL || enableForRaizen);
    }
  }

  buildModal(
    buttonLabel: string,
    description: string,
    success: boolean,
    title: string,
    icon: string
  ) {
    this.modalModel.buttonLabel = buttonLabel;
    this.modalModel.description = description;
    this.modalModel.success = success;
    this.modalModel.imagePath = icon;
    this.modalModel.title = title;
    this.isLoadingEQ = false;
    this.modalModel.showModal = true;
  }

  clickModal() {
    this.modalModel.showModal = false;
  }

  getDFDV() {
    this.isLoading = true;
    this.unSubscribe = this.supportTeamStore$.subscribe((supportTeam) => {
      if (!supportTeam) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.isLoading = isLoading.loading;
            });

          if (!this.isLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        this.scopeStore$.subscribe((scope) => {
          if (scope) this.scopeModel = scope;
        });
        this.driverStore$.subscribe((driver) => {
          if (driver) this.driversFactorModel = driver;
        });

        this.chargeStore$.subscribe((charge) => {
          if (charge) this.chargesModel = charge;
        });
        this.benefitStore$.subscribe((benefit) => {
          if (benefit) this.benefitModel = benefit;
        });
        this.generalCostsStore$.subscribe((generalCosts) => {
          if (generalCosts) this.generalCostsModel = generalCosts;
        });
        this.PopulateModel(supportTeam);
        this.dfdvStore$.subscribe((dfdv) => {
          this.dfdv = { ...dfdv };

          if (dfdv) {
            this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
            this.getShouldDisableFields();
          }
        });
      }
    });
  }

  PopulateModel(supportTeam: SupportTeamModel) {
    Object.keys(this.supportTeamModel).forEach((group) => {
      Object.keys(this.supportTeamModel[group]).forEach((key) => {
        if (key !== 'transportTypeName') {
          this.supportTeamModel[group][key] = {
            ...supportTeam[group][key],
          };
        } else {
          this.supportTeamModel[group][key] = supportTeam[group][key];
        }
      });
    });

    this.supportTeamModel.total = { ...supportTeam.total };
    this.isLoading = false;
    this.showSkeleton = false;
  }

  UpdateTotal(
    itemUpdate: [
      string,
      SupportTeamTotalizerModel,
      [string, SupportTeamDetailModel][],
      string | null
    ]
  ) {
    const updateType = itemUpdate[0];
    const totalizer = itemUpdate[1];
    const updateModel = itemUpdate[2];
    const transportType = itemUpdate[3];

    let support: SupportTeamModel = new SupportTeamModel();
    support = JSON.parse(
      JSON.stringify(this.supportTeamModel)
    ) as SupportTeamModel;

    updateModel.forEach((model) => {
      const propName = model[0];
      support[updateType].transportTypeName = transportType;
      support[updateType][propName].benefitExpense = model[1].benefitExpense;
      support[updateType][propName].benefitValue = model[1].benefitValue;
      support[updateType][propName].lightFleetExpense =
        model[1].lightFleetExpense;
      support[updateType][propName].lightFleetQuantity =
        model[1].lightFleetQuantity;
      support[updateType][propName].lightFleetValue = model[1].lightFleetValue;
      support[updateType][propName].salaryAndChargeExpense =
        model[1].salaryAndChargeExpense;
      support[updateType][propName].salaryAndChargeValue =
        model[1].salaryAndChargeValue;
      support[updateType][propName].salaryExpense = model[1].salaryExpense;
      support[updateType][propName].salaryQuantity = model[1].salaryQuantity;
      support[updateType][propName].salaryValue = model[1].salaryValue;
      support[updateType][propName].uniformExpense = model[1].uniformExpense;
      support[updateType][propName].uniformValue = model[1].uniformValue;
      support[updateType].total = totalizer;
    });

    support.management.total.totalSalaryValue =
      support.management.total.totalSalaryQuantity === 0
        ? 0
        : support.management.total.totalSalaryExpense /
          support.management.total.totalSalaryQuantity;
    support.management.total.totalSalaryAndChargeValue =
      support.management.total.totalSalaryQuantity === 0
        ? 0
        : support.management.total.totalSalaryAndChargeExpense /
          support.management.total.totalSalaryQuantity;
    support.management.total.totalBenefitValue =
      support.management.total.totalSalaryQuantity === 0
        ? 0
        : support.management.total.totalBenefitExpense /
          support.management.total.totalSalaryQuantity;
    support.management.total.totalUniformValue =
      support.management.total.totalSalaryQuantity === 0
        ? 0
        : support.management.total.totalUniformExpense /
          support.management.total.totalSalaryQuantity;
    support.management.total.totalLightFleetValue =
      support.management.total.totalLightFleetQuantity === 0
        ? 0
        : support.management.total.totalLightFleetExpense /
          support.management.total.totalLightFleetQuantity;
    support.adminAndFinancial.total.totalSalaryValue =
      support.adminAndFinancial.total.totalSalaryQuantity === 0
        ? 0
        : support.adminAndFinancial.total.totalSalaryExpense /
          support.adminAndFinancial.total.totalSalaryQuantity;
    support.adminAndFinancial.total.totalSalaryAndChargeValue =
      support.adminAndFinancial.total.totalSalaryQuantity === 0
        ? 0
        : support.adminAndFinancial.total.totalSalaryAndChargeExpense /
          support.adminAndFinancial.total.totalSalaryQuantity;
    support.adminAndFinancial.total.totalBenefitValue =
      support.adminAndFinancial.total.totalSalaryQuantity === 0
        ? 0
        : support.adminAndFinancial.total.totalBenefitExpense /
          support.adminAndFinancial.total.totalSalaryQuantity;
    support.adminAndFinancial.total.totalUniformValue =
      support.adminAndFinancial.total.totalSalaryQuantity === 0
        ? 0
        : support.adminAndFinancial.total.totalUniformExpense /
          support.adminAndFinancial.total.totalSalaryQuantity;
    support.adminAndFinancial.total.totalLightFleetValue =
      support.adminAndFinancial.total.totalLightFleetQuantity === 0
        ? 0
        : support.adminAndFinancial.total.totalLightFleetExpense /
          support.adminAndFinancial.total.totalLightFleetQuantity;
    support.hrAndSsma.total.totalSalaryValue =
      support.hrAndSsma.total.totalSalaryQuantity === 0
        ? 0
        : support.hrAndSsma.total.totalSalaryExpense /
          support.hrAndSsma.total.totalSalaryQuantity;
    support.hrAndSsma.total.totalSalaryAndChargeValue =
      support.hrAndSsma.total.totalSalaryQuantity === 0
        ? 0
        : support.hrAndSsma.total.totalSalaryAndChargeExpense /
          support.hrAndSsma.total.totalSalaryQuantity;
    support.hrAndSsma.total.totalBenefitValue =
      support.hrAndSsma.total.totalSalaryQuantity === 0
        ? 0
        : support.hrAndSsma.total.totalBenefitExpense /
          support.hrAndSsma.total.totalSalaryQuantity;
    support.hrAndSsma.total.totalUniformValue =
      support.hrAndSsma.total.totalSalaryQuantity === 0
        ? 0
        : support.hrAndSsma.total.totalUniformExpense /
          support.hrAndSsma.total.totalSalaryQuantity;
    support.hrAndSsma.total.totalLightFleetValue =
      support.hrAndSsma.total.totalLightFleetQuantity === 0
        ? 0
        : support.hrAndSsma.total.totalLightFleetExpense /
          support.hrAndSsma.total.totalLightFleetQuantity;
    support.maintenanceAndWarehouse.total.totalSalaryValue =
      support.maintenanceAndWarehouse.total.totalSalaryQuantity === 0
        ? 0
        : support.maintenanceAndWarehouse.total.totalSalaryExpense /
          support.maintenanceAndWarehouse.total.totalSalaryQuantity;
    support.maintenanceAndWarehouse.total.totalSalaryAndChargeValue =
      support.maintenanceAndWarehouse.total.totalSalaryQuantity === 0
        ? 0
        : support.maintenanceAndWarehouse.total.totalSalaryAndChargeExpense /
          support.maintenanceAndWarehouse.total.totalSalaryQuantity;
    support.maintenanceAndWarehouse.total.totalBenefitValue =
      support.maintenanceAndWarehouse.total.totalSalaryQuantity === 0
        ? 0
        : support.maintenanceAndWarehouse.total.totalBenefitExpense /
          support.maintenanceAndWarehouse.total.totalSalaryQuantity;
    support.maintenanceAndWarehouse.total.totalUniformValue =
      support.maintenanceAndWarehouse.total.totalSalaryQuantity === 0
        ? 0
        : support.maintenanceAndWarehouse.total.totalUniformExpense /
          support.maintenanceAndWarehouse.total.totalSalaryQuantity;
    support.maintenanceAndWarehouse.total.totalLightFleetValue =
      support.maintenanceAndWarehouse.total.totalLightFleetQuantity === 0
        ? 0
        : support.maintenanceAndWarehouse.total.totalLightFleetExpense /
          support.maintenanceAndWarehouse.total.totalLightFleetQuantity;
    support.operationalSupport.total.totalSalaryValue =
      support.operationalSupport.total.totalSalaryQuantity === 0
        ? 0
        : support.operationalSupport.total.totalSalaryExpense /
          support.operationalSupport.total.totalSalaryQuantity;
    support.operationalSupport.total.totalSalaryAndChargeValue =
      support.operationalSupport.total.totalSalaryQuantity === 0
        ? 0
        : support.operationalSupport.total.totalSalaryAndChargeExpense /
          support.operationalSupport.total.totalSalaryQuantity;
    support.operationalSupport.total.totalBenefitValue =
      support.operationalSupport.total.totalSalaryQuantity === 0
        ? 0
        : support.operationalSupport.total.totalBenefitExpense /
          support.operationalSupport.total.totalSalaryQuantity;
    support.operationalSupport.total.totalUniformValue =
      support.operationalSupport.total.totalSalaryQuantity === 0
        ? 0
        : support.operationalSupport.total.totalUniformExpense /
          (support.operationalSupport.total.totalSalaryQuantity -
            support.operationalSupport.generalBuyer.salaryQuantity);
    support.operationalSupport.total.totalLightFleetValue =
      support.operationalSupport.total.totalLightFleetQuantity === 0
        ? 0
        : support.operationalSupport.total.totalLightFleetExpense /
          support.operationalSupport.total.totalLightFleetQuantity;

    support.supportTeamFleet.totalLightFleetValue =
      support.supportTeamFleet.totalLightFleetExpense /
      support.supportTeamFleet.totalLightFleetQuantity;

    this.supportTeamModel = { ...support };
    this.UpdateTotalizer();
  }

  UpdateTotalizer() {
    this.supportTeamModel.total.totalBenefitExpense =
      this.supportTeamModel.adminAndFinancial.total.totalBenefitExpense +
      this.supportTeamModel.hrAndSsma.total.totalBenefitExpense +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalBenefitExpense +
      this.supportTeamModel.management.total.totalBenefitExpense +
      this.supportTeamModel.operationalSupport.total.totalBenefitExpense;

    this.supportTeamModel.total.totalBenefitValue =
      this.supportTeamModel.adminAndFinancial.total.totalBenefitValue +
      this.supportTeamModel.hrAndSsma.total.totalBenefitValue +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalBenefitValue +
      this.supportTeamModel.management.total.totalBenefitValue +
      this.supportTeamModel.operationalSupport.total.totalBenefitValue

    this.supportTeamModel.total.totalLightFleetExpense =
      this.supportTeamModel.adminAndFinancial.total.totalLightFleetExpense +
      this.supportTeamModel.hrAndSsma.total.totalLightFleetExpense +
      this.supportTeamModel.maintenanceAndWarehouse.total
        .totalLightFleetExpense +
      this.supportTeamModel.management.total.totalLightFleetExpense +
      this.supportTeamModel.operationalSupport.total.totalLightFleetExpense;
    this.supportTeamModel.total.totalLightFleetQuantity =
      this.supportTeamModel.adminAndFinancial.total.totalLightFleetQuantity +
      this.supportTeamModel.hrAndSsma.total.totalLightFleetQuantity +
      this.supportTeamModel.maintenanceAndWarehouse.total
        .totalLightFleetQuantity +
      this.supportTeamModel.management.total.totalLightFleetQuantity +
      this.supportTeamModel.operationalSupport.total.totalLightFleetQuantity +
      this.supportTeamModel.supportTeamFleet.shiftChange.lightFleetQuantity +
      this.supportTeamModel.supportTeamFleet.workshopTruck.lightFleetQuantity;

    const totalLightFleetQuantityWithoutFleetCard = this.supportTeamModel.adminAndFinancial.total.totalLightFleetQuantity
    + this.supportTeamModel.hrAndSsma.total.totalLightFleetQuantity
    + this.supportTeamModel.maintenanceAndWarehouse.total.totalLightFleetQuantity
    + this.supportTeamModel.management.total.totalLightFleetQuantity
    + this.supportTeamModel.operationalSupport.total.totalLightFleetQuantity;

    this.supportTeamModel.total.totalLightFleetValue = totalLightFleetQuantityWithoutFleetCard === 0 ? 0 : this.supportTeamModel.total.totalLightFleetExpense / totalLightFleetQuantityWithoutFleetCard;
    this.supportTeamModel.total.totalSalaryAndChargeExpense = this.supportTeamModel.adminAndFinancial.total
      .totalSalaryAndChargeExpense
      + this.supportTeamModel.hrAndSsma.total.totalSalaryAndChargeExpense
      + this.supportTeamModel.maintenanceAndWarehouse.total
        .totalSalaryAndChargeExpense
      + this.supportTeamModel.management.total.totalSalaryAndChargeExpense
      + this.supportTeamModel.operationalSupport.total
        .totalSalaryAndChargeExpense;
    this.supportTeamModel.total.totalSalaryAndChargeValue =
      this.supportTeamModel.adminAndFinancial.total.totalSalaryAndChargeValue +
      this.supportTeamModel.hrAndSsma.total.totalSalaryAndChargeValue +
      this.supportTeamModel.maintenanceAndWarehouse.total
        .totalSalaryAndChargeValue +
      this.supportTeamModel.management.total.totalSalaryAndChargeValue +
      this.supportTeamModel.operationalSupport.total.totalSalaryAndChargeValue;
    this.supportTeamModel.total.totalSalaryExpense =
      this.supportTeamModel.adminAndFinancial.total.totalSalaryExpense +
      this.supportTeamModel.hrAndSsma.total.totalSalaryExpense +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalSalaryExpense +
      this.supportTeamModel.management.total.totalSalaryExpense +
      this.supportTeamModel.operationalSupport.total.totalSalaryExpense;
    this.supportTeamModel.total.totalSalaryQuantity =
      this.supportTeamModel.adminAndFinancial.total.totalSalaryQuantity +
      this.supportTeamModel.hrAndSsma.total.totalSalaryQuantity +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalSalaryQuantity +
      this.supportTeamModel.management.total.totalSalaryQuantity +
      this.supportTeamModel.operationalSupport.total.totalSalaryQuantity;
    this.supportTeamModel.total.totalSalaryValue =
      this.supportTeamModel.adminAndFinancial.total.totalSalaryValue +
      this.supportTeamModel.hrAndSsma.total.totalSalaryValue +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalSalaryValue +
      this.supportTeamModel.management.total.totalSalaryValue +
      this.supportTeamModel.operationalSupport.total.totalSalaryValue;
    this.supportTeamModel.total.totalUniformExpense =
      this.supportTeamModel.adminAndFinancial.total.totalUniformExpense +
      this.supportTeamModel.hrAndSsma.total.totalUniformExpense +
      this.supportTeamModel.maintenanceAndWarehouse.total.totalUniformExpense +
      this.supportTeamModel.management.total.totalUniformExpense +
      this.supportTeamModel.operationalSupport.total.totalUniformExpense;

    this.supportTeamModel.total.totalUniformValue =
      this.supportTeamModel.total.totalSalaryQuantity -
        (this.supportTeamModel.management.total.totalSalaryQuantity +
          this.supportTeamModel.operationalSupport.generalBuyer
            .salaryQuantity) <=
      0
        ? 0
        : (this.supportTeamModel.adminAndFinancial.total.totalUniformExpense +
            this.supportTeamModel.hrAndSsma.total.totalUniformExpense +
            this.supportTeamModel.maintenanceAndWarehouse.total
              .totalUniformExpense +
            this.supportTeamModel.management.total.totalUniformExpense +
            this.supportTeamModel.operationalSupport.total
              .totalUniformExpense) /
          (this.supportTeamModel.total.totalSalaryQuantity -
            (this.supportTeamModel.management.total.totalSalaryQuantity +
              this.supportTeamModel.operationalSupport.generalBuyer
                .salaryQuantity));

    this.supportTeamModel.supportTeamFleet.fleetOperationalTeam.lightFleetQuantity =
      totalLightFleetQuantityWithoutFleetCard;

    this.supportTeamModel.supportTeamFleet.totalLightFleetQuantity =
      this.supportTeamModel.total.totalLightFleetQuantity;

    this.supportTeamModel.supportTeamFleet.fleetOperationalTeam.lightFleetExpense =
      this.supportTeamModel.total.totalLightFleetExpense;

    this.supportTeamModel.supportTeamFleet.totalLightFleetExpense = this.supportTeamModel.supportTeamFleet.fleetOperationalTeam.lightFleetExpense
      + this.supportTeamModel.supportTeamFleet.workshopTruck.lightFleetExpense
      + this.supportTeamModel.supportTeamFleet.shiftChange.lightFleetExpense;

    this.supportTeamModel.supportTeamFleet.fleetOperationalTeam.lightFleetValue = this.supportTeamModel.total.totalLightFleetValue;

    this.supportTeamModel.supportTeamFleet.totalLightFleetValue = this.supportTeamModel.supportTeamFleet.shiftChange.lightFleetValue
      + this.supportTeamModel.supportTeamFleet.workshopTruck.lightFleetValue
      + this.supportTeamModel.supportTeamFleet.fleetOperationalTeam.lightFleetValue;

    this.supportTeamModel.total.totalLightFleetValue = this.supportTeamModel.supportTeamFleet.totalLightFleetValue;
    this.supportTeamModel.total.totalLightFleetExpense = this.supportTeamModel.supportTeamFleet.totalLightFleetExpense;
  }

  UpdateTotalSupportFleet(supportFleet: SupportTeamFleetModel) {
    this.supportTeamModel.supportTeamFleet = supportFleet;
    this.UpdateTotalizer();
  }

  UpdateSupporTeamStore() {
    const support: SupportTeamModel = new SupportTeamModel();

    support.total = this.supportTeamModel.total;
    support.management = this.supportTeamModel.management;
    support.operationalSupport = this.supportTeamModel.operationalSupport;
    support.maintenanceAndWarehouse = this.supportTeamModel.maintenanceAndWarehouse;
    support.hrAndSsma = this.supportTeamModel.hrAndSsma;
    support.adminAndFinancial = this.supportTeamModel.adminAndFinancial;
    support.supportTeamFleet = this.supportTeamModel.supportTeamFleet;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'supportTeam',
        data: support,
      })
    );
  }

  submit() {
    this.isLoadingEQ = true;
    this.UpdateSupporTeamStore();
    const request = this.dfdvUtils.getUpdateRequest();
    this.service.updateDfdv(request).subscribe({
      next: () => {
        this.isLoadingEQ = false;
        this.buildModal(
          'Entendi',
          'Alterações efetivadas com sucesso',
          true,
          'Equipe apoio',
          '.../../../../assets/icons/success-600.gif'
        );
      },
      error: (error: HttpErrorResponse) => {
        this.isLoadingEQ = false;
        const message = JSON.parse(JSON.stringify(error?.error));
        this.buildModal(
          'Entendi',
          message?.Message ?? message,
          false,
          'Equipe apoio',
          '../../../../assets/icons/warning-600.gif'
        );
      },
    });
  }
}
