/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { CompaniesService } from 'src/app/services/pims/companies/companies.service';
import { GetCompaniesPimsModel } from 'src/app/services/pims/companies/model/get-companies-pims.model';
import { GetCompanyGroupedPimsModel } from 'src/app/services/operator/models/get-company-grouped-pims.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';

@Component({
  selector: 'app-operator-pims-register',
  templateUrl: './operator-pims-register.component.html',
  styleUrls: ['./operator-pims-register.component.scss'],
})
export class OperatorPimsRegisterComponent implements OnInit {
  public searchControl = this.formBuilder.group({
    search: ['', Validators.minLength(3)],
  });

  public toggleControl = this.formBuilder.group({
    toggle: false,
  });

  public companiesList: GetCompaniesPimsModel[] = [];
  public operatorId: string = '';
  public modalModel = new ModalModel();
  public saveLoading = false;
  public searchLoading = false;

  constructor(
    public companiesService: CompaniesService,
    public operatorService: OperatorService,
    public formBuilder: FormBuilder,
    public route: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.operatorId = params.get('id') ?? '';
    });
  }

  searchCompanies() {
    this.searchLoading = true;
    const value = this.searchControl.controls.search.value ?? '';
    if (value.length < 3) {
      this.searchLoading = false;
      return;
    }

    this.companiesService.getCompaniesListPims(
      this.searchControl.controls.search.value ?? '',
    ).subscribe({
      next: (result) => {
        this.searchLoading = false;
        this.companiesList = result;
        this.companiesList.forEach((x) => x.checked = false);
      },
      error: () => {
        this.searchLoading = false;
        this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
        this.modalModel.title = 'Não foi possível obter os cadastros PIMS';
        this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
        this.modalModel.buttonLabel = 'Entendi';
        this.modalModel.success = false;
        this.modalModel.showModal = true;
      },
    });
  }

  save() {
    this.saveLoading = true;
    let groupedCompanies : GetCompanyGroupedPimsModel = new GetCompanyGroupedPimsModel();
    const selectedCompanies : GetCompaniesPimsModel[] = [];

    selectedCompanies.push(...this.companiesList.filter((x) => x.checked === true));

    if (selectedCompanies.length === 0) {
      this.saveLoading = false;
      return;
    }

    selectedCompanies.forEach((item) => {
      item.transporterCnpj = item.transporterCnpj.replace(/\.|-/gm, '').replace('/', '');
    });

    groupedCompanies = {
      operatorId: this.operatorId,
      operatorName: '...',
      operatorPims: selectedCompanies,
    } as GetCompanyGroupedPimsModel;

    this.operatorService.createOperatorPims(groupedCompanies).subscribe({
      next: this.createOperatorPimsSuccess.bind(this),
      error: this.createOperatorPimsError.bind(this),
    });
  }

  createOperatorPimsSuccess() {
    this.saveLoading = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Cadastros PIMS vinculados com sucesso';
    this.modalModel.imagePath = '../../../../../../assets/icons/success-600.gif';
    this.modalModel.title = 'Cadastro realizado com sucesso';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  createOperatorPimsError(error: HttpErrorResponse) {
    this.saveLoading = false;
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Falha ao realizar o cadastro';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  onCheck(companie: GetCompaniesPimsModel) {
    companie.checked = true;
  }

  onRemoveCheck(companie: GetCompaniesPimsModel) {
    companie.checked = false;
  }

  checkAll() {
    this.companiesList.forEach((x) => x.checked = true);
  }

  removeAllChecks() {
    this.companiesList.forEach((x) => x.checked = false);
  }

  clickModal() {
    this.modalModel.showModal = false;
    if (this.modalModel.success) {
      this.route.navigate([`/admin/operator/${this.operatorId}`]);
    }
  }

  return() {
    this.route.navigate([`/admin/operator/${this.operatorId}`]);
  }
}
