<div class="container">
  <section class="main-dfdv">
    <app-sumarize-info
      [isLoading]="dfdvLoading"
      [dfdv]="dfdv"
    ></app-sumarize-info>
    <header>Configurações</header>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/plant-common.svg"
        title="Usina"
        description="Configurações de Usina"
        [fullDescription]="true"
        (callbackFunc)="navigateToPlant()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Gear-Icon.svg"
        title="Escopo"
        description="Configurações de parâmetros"
        [fullDescription]="true"
        (callbackFunc)="navigateToScope()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/tyre-common.svg"
        title="Fator Motorista"
        description="Dados sobre o fator motorista"
        [fullDescription]="true"
        (callbackFunc)="navigateToDriversFactor()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Money-Icon.svg"
        title="Lucro"
        description="Dados sobre lucro fixo e variável"
        [fullDescription]="true"
        (callbackFunc)="navigateToProfit()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/benefit.svg"
        title="Benefícios"
        description="Ticket, assistência médica, seguro de vida, cesta básica, entre outros"
        [fullDescription]="true"
        (callbackFunc)="navigateToBenefits()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/calc-prinary-600.svg"
        title="Encargos"
        description="INSS, FGTS, Férias, 13º Salário, entre outros"
        [fullDescription]="true"
        (callbackFunc)="navigateToCharges()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Money-Icon.svg"
        title="Impostos"
        description="Dados sobre impostos e taxas"
        [fullDescription]="true"
        (callbackFunc)="navigateToTaxes()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/settings-primary-600.svg"
        title="Parametros RV"
        description="Configurações de parâmetros RV"
        [fullDescription]="true"
        (callbackFunc)="navigateToRvParameter()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Money-Icon.svg"
        title="Depesas Gerais"
        description="Configurações de comunicação movel, telefone, internet, uniforme entre outros"
        [fullDescription]="true"
        (callbackFunc)="navigateToGeneralCost()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Money-Icon.svg"
        title="Equipe Operacional"
        description="Dados de equipe Operacional"
        [fullDescription]="true"
        (callbackFunc)="navigateToOperationalTeam()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/Money-Icon.svg"
        title="Equipe Apoio"
        description="Dados de equipe apoio"
        [fullDescription]="true"
        (callbackFunc)="navigateToSupportTeam()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/tyre-common.svg"
        title="Frota"
        description="Dados das Frotas"
        [fullDescription]="true"
        (callbackFunc)="navigateToFleet()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/gas-bomb-primary.svg"
        title="Custo Variável (CV)"
        description="Dados de Custos Variáveis de Frota, Consumo, Pneus, Manutenção"
        [fullDescription]="true"
        (callbackFunc)="navigateToVariableCost()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/table-primary-600.svg"
        title="Tabela"
        description="Precificação por KM do carregamento realizado dentro (DM) e fora do município (FM)"
        [fullDescription]="true"
        (callbackFunc)="navigateToTable()"
      ></app-card-option>
    </main>
    <main class="settings">
      <app-card-option
        icon="../../../../assets/icons/graphic-common.svg"
        title="Resumo"
        description="Resumo dos Dados de Todas as Abas"
        [fullDescription]="true"
        (callbackFunc)="navigateToSummary()"
      ></app-card-option>
    </main>
  </section>
</div>
