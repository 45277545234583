<div class="container">
  <section>
    <app-card
      icon="../../../../assets/icons/categories-primary-600.svg"
      [title]="'Empresa'"
      [accordion]="true"
    >
      <section actions class="actions" *ngIf="!isLoading">
          <app-toogle
          label="Permitido criar solicitações?"
          [toggleId]="'sol-toggleOpl'"
          [checked]="activeSolicitation"
          [disabled]="isPreregister"
          (check)="handleSolicitationToggleCheck()"
        ></app-toogle>
        <app-toogle
          label="Ativo"
          toggleId="ex-toggleOpl"
          [disabled]="isPreregister"
          [checked]="active"
          (check)="handleToggleCheck()"
        ></app-toogle>
      </section>
      <div class="body" body *ngIf="!isLoading">
        <div class="in-toggle">
          <app-toogle
            label="Ativo"
            [toggleId]="'in-toggleOpl'"
            [checked]="active"
            [disabled]="isPreregister"
            (check)="handleToggleCheck()"
          ></app-toogle>
        </div>
        <div class="input-box">
          <app-input
            label="CNPJ"
            [disabled]="true"
            errorMessage="Informe CNPJ Valido"
            [control]="companyForm.controls.cnpj"
            [type]="eInputType.text"
            [mask]="eInputMask.cnpj"
            [showSideIcon]="true"
            [sideIconLabel]="'../../../assets/icons/user-common-600.svg'"
            [sideIconType]="eSideIconType.image"
          ></app-input>
          <app-input
            label="Razão Social"
            [disabled]="true"
            errorMessage="Este campo é obrigatório"
            [control]="companyForm.controls.corporateName"
            [type]="eInputType.text"
          ></app-input>
        </div>
        <app-input
          label="Nome Fantasia"
          [disabled]="isPreregister"
          errorMessage="Este campo é obrigatório"
          [control]="companyForm.controls.fantasyName"
          [type]="eInputType.text"
          [maxlength]="50"
        ></app-input>
        <div class="input-box">
          <app-input
            label="Número de Inscrição Estadual"
            placeholder="000000000"
            [disabled]="isPreregister"
            [control]="companyForm.controls.numberRegistrationState"
            [description]="'(opcional)'"
            [mask]="eInputMask.number"
            [max]="999999999"
            [min]="0"
            [showNumberActions]="false"
          ></app-input>
          <app-input
            label="Número de Inscrição Municipal"
            placeholder="00000000000"
            [disabled]="isPreregister"
            [control]="companyForm.controls.numberRegistrationMunicipal"
            [description]="'(opcional)'"
            [mask]="eInputMask.number"
            [max]="99999999999"
            [min]="0"
            [showNumberActions]="false"
          ></app-input>
          <app-select
            [field]="'Porte da Empresa'"
            [disabled]="isPreregister"
            errorMessage="Este campo é obrigatório"
            [singleControl]="companyForm.controls.companySize"
            [selected]="selectedCompanySize"
            [options]="porteEmpresa"
            [multi]="false"
          ></app-select>
          <app-select
            [field]="'Número de Funcionários'"
            [disabled]="isPreregister"
            errorMessage="Este campo é obrigatório"
            [singleControl]="companyForm.controls.employeesCount"
            [options]="nFuncionarios"
            [selected]="selectedEmployeesCount"
            [multi]="false"
          ></app-select>
        </div>
      </div>
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="7"></app-skeleton-input>
      </div>
    </app-card>
    <app-card
      icon="../../../../assets/icons/categories-primary-600.svg"
      [title]="'Endereço'"
      [accordion]="true"
    >
      <div class="body" body *ngIf="!isLoading">
        <div class="input-box">
          <app-input
            label="CEP"
            [type]="eInputType.text"
            [errorMessage]="error ? errorMessage : 'Este campo é obrigatório'"
            [control]="addressForm.controls.cep"
            [mask]="eInputMask.cep"
            [search]="true"
            [loading]="loading"
            [searchError]="error"
            [disabled]="isPreregister"
            (searchClick)="searchAddress($event)"
          ></app-input>
          <app-select
            field="Estado"
            errorMessage="Este campo é obrigatório"
            [singleControl]="addressForm.controls.uf"
            [selected]="selectedState"
            [options]="states"
            [disabled]="isPreregister"
          ></app-select>
          <app-input
            label="Cidade"
            errorMessage="Este campo é obrigatório"
            [control]="addressForm.controls.city"
            [type]="eInputType.text"
            [maxlength]="50"
            [disabled]="isPreregister"
          ></app-input>
          <app-input
            label="Bairro"
            errorMessage="Este campo é obrigatório"
            [control]="addressForm.controls.neighborhood"
            [type]="eInputType.text"
            [maxlength]="50"
            [disabled]="isPreregister"
          ></app-input>
        </div>
        <app-input
          label="Endereço"
          errorMessage="Este campo é obrigatório"
          [control]="addressForm.controls.address"
          [type]="eInputType.text"
          [maxlength]="50"
          [disabled]="isPreregister"
        ></app-input>
        <div class="input-box">
          <app-input
            label="Número"
            errorMessage="Este campo é obrigatório"
            [control]="addressForm.controls.number"
            [type]="eInputType.text"
            [maxlength]="10"
            [disabled]="isPreregister"
          ></app-input>
          <app-input
            label="Complemento"
            description="(opcional)"
            [control]="addressForm.controls.complement"
            [type]="eInputType.text"
            [maxlength]="20"
            [disabled]="isPreregister"
          ></app-input>
        </div>
      </div>
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="7"></app-skeleton-input>
      </div>
    </app-card>
    <app-card
      icon="../../../../assets/icons/categories-primary-600.svg"
      [title]="'Contato'"
      [accordion]="true"
    >
      <div class="body" body *ngIf="!isLoading">
        <app-input
          label="Nome do Contato"
          [disabled]="isPreregister"
          [type]="eInputType.text"
          errorMessage="Este campo é obrigatório"
          [control]="contactForm.controls.name"
          [maxlength]="50"
        ></app-input>
        <div class="input-box">
          <app-input
            [disabled]="isPreregister"
            label="E-mail"
            [type]="eInputType.text"
            [flatLoading]="emailLoading"
            errorMessage="{{ emailError }}"
            [control]="contactForm.controls.email"
            [validated]="emailIsValid"
            [showSideIcon]="true"
            sideIconLabel="@"
            [sideIconType]="eSideIconType.text"
          ></app-input>
          <app-input
            label="Telefone/Celular"
            [disabled]="isPreregister"
            [type]="eInputType.text"
            [mask]="eInputMask.phone"
            errorMessage="Este campo é obrigatório"
            [control]="contactForm.controls.phone"
            [showSideIcon]="true"
            [sideIconLabel]="'../../../assets/icons/phone-common-600.svg'"
            [sideIconType]="eSideIconType.image"
          ></app-input>
        </div>
      </div>
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="3"></app-skeleton-input>
      </div>
    </app-card>
    <div class="btn-group" *ngIf="!isLoading">
      <app-secondary-button
        label="Voltar"
        [enabled]="enabledBack"
        icon="../../../assets/icons/arrow-previous-primary-600.svg"
        (clickButton)="goBack()"
      ></app-secondary-button>
      <ng-container *ngIf="isPreregister">
        <app-primary-button
          label="RECUSAR"
          icon="../../../../assets/icons/disapprove-common-100.svg"
          [color]="eButtonsColor.warning"
          [right]="false"
          [loading]="sendingRefuse"
          [enabled]="enabledRefuse"
          (clickButton)="refuse()"
        ></app-primary-button>
        <app-primary-button
          label="ACEITAR"
          icon="../../../../assets/icons/approve-common-100.svg"
          [color]="eButtonsColor.success"
          [right]="false"
          [loading]="sending"
          [enabled]="enabledSending"
          (clickButton)="accept()"
        ></app-primary-button>
      </ng-container>
      <app-buttons
        *ngIf="!isPreregister"
        label="SALVAR"
        [loading]="loadingButton"
        [color]="eButtonsColor.primary"
        [type]="eButtonsType.primary"
        [enabled]="
          !isPreregister &&
          companyForm.valid &&
          addressForm.valid &&
          contactForm.valid
        "
        (clickButton)="submit()"
      ></app-buttons>
    </div>
  </section>
</div>
<app-modal
  [showModal]="showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
