import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent {
  @Input() showModal: boolean = false;
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() confirmLabel: string = '';
  @Input() confirmIcon: string = '../../../assets/icons/approve-success.svg';
  @Input() confirmColor: ButtonColorEnum = ButtonColorEnum.common;

  @Output() cancelFunc = new EventEmitter();
  @Output() confirmFunc = new EventEmitter();

  public close() {
    this.cancelFunc.emit();
    this.showModal = !this.showModal;
  }

  public confirm() {
    this.confirmFunc.emit();
    this.close();
  }
}
