<div class="container">
  <div class="operator-list-container">
    <div
      *ngFor="let operator of operatorList"
      class="grid"
      (click)="handleDataClick(operator.operatorId)"
    >
      <div class="operatorId">
        {{ operator.operatorId | cnpj }}
      </div>
      <div class="fantasyName">{{ operator.fantasyName }}</div>
      <div class="email">
        <img src="../../../../../../assets/icons/arroba.svg" alt="email" />
        <span class="email-text">{{ operator.email }}</span>
      </div>
      <div class="phone">
        <img src="../../../../../../assets/icons/phone.svg" alt="phone" />
        <span class="phone-text">{{ operator.phone | phone }}</span>
      </div>
      <div class="status">
        <app-status
          [color]="
            operator.active ? StatusColorEnum.success : StatusColorEnum.warning
          "
          [image]="
            operator.active
              ? '../../../../../../assets/icons/active.svg'
              : '../../../../../../assets/icons/Exclude.svg'
          "
          [status]="operator.active ? 'Ativo' : 'Inativo'"
        >
        </app-status>
      </div>
    </div>
  </div>
</div>
