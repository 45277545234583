import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GetAnalystModel } from 'src/app/services/analyst/models/get-analyst-model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-analyst-list-card',
  templateUrl: './analyst-list-card.component.html',
  styleUrls: ['./analyst-list-card.component.scss'],
})
export class AnalystListCardComponent {
  constructor(private router: Router) { }
  @Input() analyst!: GetAnalystModel;

  public StatusColorEnum = StatusColorEnum;

  handleDataClick(id: string) {
    this.router.navigate(['/admin/analyst/', id]);
  }
}
