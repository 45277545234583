import { UpdateAdminFinancialModel } from './update-adminFinancial.model';
import { UpdateMaintenenceAndWarehouseModel } from './update-maintenenceAndWarehouse.model';
import { UpdateManagementModel } from './update-management.model';
import { UpdateOperationalSupportModel } from './update-operation-support.model';
import { UpdateRhAndSsmaModel } from './update-rhAndSsma.model';
import { UpdateSupportTeamFleetModel } from './update-support-team-fleet.model';
export class UpdateSupportTeamModel {
  [key: string]: any;

  constructor() {
    this.management = new UpdateManagementModel();
    this.operationalSupport = new UpdateOperationalSupportModel();
    this.maintenanceAndWarehouse = new UpdateMaintenenceAndWarehouseModel();
    this.hrAndSsma = new UpdateRhAndSsmaModel();
    this.adminAndFinancial = new UpdateAdminFinancialModel();
    this.supportTeamFleet = new UpdateSupportTeamFleetModel();
  }

  management: UpdateManagementModel = new UpdateManagementModel();
  operationalSupport: UpdateOperationalSupportModel =
    new UpdateOperationalSupportModel();
  maintenanceAndWarehouse: UpdateMaintenenceAndWarehouseModel =
    new UpdateMaintenenceAndWarehouseModel();
  hrAndSsma: UpdateRhAndSsmaModel = new UpdateRhAndSsmaModel();
  adminAndFinancial: UpdateAdminFinancialModel =
    new UpdateAdminFinancialModel();
  supportTeamFleet: UpdateSupportTeamFleetModel =
    new UpdateSupportTeamFleetModel();
}
