import { SupportTeamDetailModel } from './support-team-detail.model';
import { SupportTeamTotalizerModel } from './support-team-totalizer.model';

export class ManagementModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.total = new SupportTeamTotalizerModel();
    this.transportGeneralManager = new SupportTeamDetailModel();
    this.managementAnalyst = new SupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();

  transportGeneralManager: SupportTeamDetailModel = new SupportTeamDetailModel();
  managementAnalyst: SupportTeamDetailModel = new SupportTeamDetailModel();
}
