import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/common/guards/auth-guard.service';
import { FixedCostsContentComponent } from '../fixed-costs-content/fixed-costs-content.component';
import { FixedCostsHeaderComponent } from './fixed-costs-header.component';
import { FixedCostUnitComponent } from '../fixed-cost-unit/fixed-cost-unit.component';
import { FixedCostsCategoryComponent } from '../fixed-costs-category/fixed-costs-category.component';
import { FixedCostsComponent } from '../fixed-costs.component';

const routes: Routes = [
  {
    path: 'fixed-costs',
    component: FixedCostsHeaderComponent,
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: 'Lançamento Fixo',
      icon: 'fixedcost-icon',
    },
    children: [
      {
        path: '',
        component: FixedCostsHeaderComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: null },
      },
      {
        path: ':id',
        data: { breadcrumb: 'Lançamento #:id' },
        children: [
          {
            path: '',
            component: FixedCostsContentComponent,
            canActivate: [AuthGuardService],
            data: { breadcrumb: null },
          },
          {
            path: 'category',
            data: { breadcrumb: null },
            children: [
              {
                path: ':categoryId',
                component: FixedCostsCategoryComponent,
                canActivate: [AuthGuardService],
                data: { breadcrumb: 'Categoria #:id' },
              },
            ],
          },
          {
            path: 'category/:categoryId/unit',
            data: { breadcrumb: null },
            children: [
              {
                path: ':unitId',
                component: FixedCostUnitComponent,
                canActivate: [AuthGuardService],
                data: {
                  breadcrumb: ':instanceId',
                  parent: {
                    path: 'fixed-costs/:fixedCostId/category/:categoryId',
                    name: 'Categoria #:categoryId',
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FixedCostsRoutingModule {}
