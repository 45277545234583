<div class="container">
  <header class="header">
    <label>ID</label>
    <label>NOME</label>
    <label>ROLE</label>
    <label>EMAIL</label>
    <label>STATUS</label>
  </header>
  <div class="element-container">
    <app-analyst-list-item
      *ngFor="let analyst of analystsList"
      [analyst]="analyst"
      class="detail"
    ></app-analyst-list-item>
    <app-analyst-list-card
      *ngFor="let analyst of analystsList"
      [analyst]="analyst"
      class="card"
    ></app-analyst-list-card>
  </div>
</div>
