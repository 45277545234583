<div class="sing-in-container">
  <span class="description">
    É seu primeiro acesso? Cadastre-se através do botão abaixo.
  </span>
  <div class="form-container">
    <app-input
      [control]="form.controls.user"
      label="Usuário"
      placeholder="Informe seu usuário"
      errorMessage="Este campo é obrigatório"
      [mask]="eMaskType.username"
      [showSideIcon]="true"
      [sideIconLabel]="'../../../assets/icons/user-common-600.svg'"
      [sideIconType]="eSideIconType.image"
    >
      <app-tooltip [top]="true">
        <div class="tooltip-info">
          <div class="text">
            <label class="bold">Usuários Raízen:</label> utilizar o seu CS
          </div>
          <div class="text">
            <label class="bold">Empresas:</label> utilizar o seu CNPJ
          </div>
        </div>
      </app-tooltip>
    </app-input>
    <app-input
      [control]="form.controls.password"
      label="Senha"
      placeholder="Informe sua senha"
      errorMessage="Este campo é obrigatório"
      [type]="eInputType.password"
      [showSideIcon]="true"
      [sideIconLabel]="'../../../assets/icons/password-common-600.svg'"
      [sideIconType]="eSideIconType.image"
    ></app-input>
    <div class="lost-password">
      <app-buttons
        label="Esqueci minha senha"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [animation]="false"
        (clickButton)="lostPassword()"
      ></app-buttons>
    </div>
  </div>
  <div class="buttons-container">
    <app-primary-button
      label="LOGIN"
      icon="../../../assets/icons/arrow-right-common-100.svg"
      [color]="ButtonColorEnum.primary"
      [right]="true"
      [loading]="loading"
      [enabled]="form.valid"
      (click)="onSignIn()"
    ></app-primary-button>
    <app-buttons
      label="Cadastrar"
      [type]="eButtonsType.secondary"
      [enabled]="true"
      [animation]="false"
      (clickButton)="onGoSignUp()"
    ></app-buttons>
  </div>
</div>
