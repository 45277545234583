import { FormGroup } from '@angular/forms';

export class GeneralCostUtils {
  public calcHarvest(element: FormGroup, harvestMonths: number) {
    const cost = element.get('cost')?.value ?? '0';
    const quantity = element.get('quantity')?.value ?? '0';
    const result = +cost * +quantity * harvestMonths;
    return result;
  }

  public calcMonth(element: FormGroup) {
    const cost = element.get('cost')?.value ?? '0';
    const quantity = element.get('quantity')?.value ?? '0';
    const result = +cost * +quantity;
    return result;
  }

  public calcHarvestOtherExpenses(element: FormGroup) {
    const cost = element.get('cost')?.value ?? '0';
    const quantity = element.get('quantity')?.value ?? '0';
    const result = +cost * +quantity;
    return result;
  }

  public calcMonthOtherExpenses(element: FormGroup, harvestMonths: number) {
    const cost = element.get('cost')?.value ?? '0';
    const quantity = element.get('quantity')?.value ?? '0';
    const result = (+cost * +quantity) / harvestMonths;
    return result;
  }

  public isValid(element: FormGroup) {
    let hasErrorQuantity = false;
    let hasErrorCost = false;
    if (
      element.get('quantity')?.errors !== null
      && element.get('quantity')?.touched
    ) {
      element.get('quantity')?.updateValueAndValidity();
      hasErrorQuantity = true;
    }

    if (
      element.get('cost')?.errors !== null
      && element.get('cost')?.touched
    ) {
      element.get('cost')?.updateValueAndValidity();
      hasErrorCost = true;
    }

    return hasErrorQuantity || hasErrorCost ? '#DC2626' : '#475569';
  }
}
