export enum StatusColorEnum {
  teal = 'teal',
  indigo = 'indigo',
  attention = 'attention',
  warning = 'warning',
  common = 'common',
  primary = 'primary',
  secondary = 'secondary',
  rose = 'rose',
  success = 'success',
}
