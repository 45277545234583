import { StorageService } from 'src/app/common/services/storage.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { SolicitationStatus } from 'src/app/shared/enums/SolicitationStatus';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSolicitationFilterModel } from 'src/app/services/solicitation/models/get-solicitation-filter-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { SolicitationService } from '../../../services/solicitation/solicitation.service';
import { SolicitationStatusEnum } from '../enums/solicitation-status.enum';
import { SolicitationJustify } from '../solicitation-approval-modal/model/solicitation-justify.model';
import { SolicitationDetailModel } from '../models/solicitation-detail.model';
import { ModalModel } from '../../pre-registration/models/modal.model';

@Component({
  selector: 'app-solicitation-list',
  templateUrl: './solicitation-list.component.html',
  styleUrls: ['./solicitation-list.component.scss'],
})
export class SolicitationListComponent implements OnInit, OnChanges {
  @Input() required: boolean = true;
  @Input() solicitacoes: SolicitationDetailModel[] = [];
  @Input() filters: GetSolicitationFilterModel | undefined;
  @Input() userRole: string = '';
  @Input() isLoading!: boolean;
  @Input() error!: boolean;
  @Input() page!: number;
  @Input() totalPages!: number;

  @Output() redirect: EventEmitter<boolean> = new EventEmitter();
  @Output() setPageCallback: EventEmitter<number> = new EventEmitter();
  @Output() reloadCallback: EventEmitter<boolean> = new EventEmitter();

  public allSolicitationsChecked: boolean = false;

  public modalTitle: string = '';
  public buttonLabel: string = '';
  public buttonImage: string = '';
  public showModal: boolean = false;
  public eButtonsType = ButtonsType;
  public buttonType!: ButtonsType;
  public ebuttonColor = ButtonColorEnum;
  public buttonColor!: ButtonColorEnum;
  public approval: boolean = true;
  public approvalVisible: boolean = false;
  public selectedSolicitations: SolicitationDetailModel[] = [];
  public username!: string;
  public loading: boolean = false;
  public loadingApprove: boolean = false;
  public loadingDisapprove: boolean = false;
  public enabledReport: boolean = true;
  public enabledApprove: boolean = true;
  public enabledDisapprove: boolean = true;

  public modalModel = new ModalModel();

  public checkBoxLabel: boolean = true;
  public widthBreakpoint: number = 576;

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private solicitationService: SolicitationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.AppearLabelCheckbox();
  }

  ngOnInit(): void {
    if (this.userRole.includes('Admin') || this.userRole.includes('Analista')) {
      this.approvalVisible = this.canApprove();
      this.username = this.storageService.getUser();
      this.disableButtons();
    }
    this.AppearLabelCheckbox();

    this.setInitialStatusFilter();
  }

  ngOnChanges() {
    if (this.solicitacoes) {
      this.approvalVisible = this.canApprove();
    }
  }

  AppearLabelCheckbox() {
    this.checkBoxLabel = window.innerWidth > this.widthBreakpoint;
  }

  disableButtons() {
    this.enabledApprove = this.selectedSolicitations.length > 0;
    this.enabledDisapprove = this.selectedSolicitations.length > 0;
  }

  setPageCallbackFunc(index: number) {
    this.setPageCallback.emit(index);
    this.selectedSolicitations = [];
  }

  checkAll() {
    this.solicitacoes = this.solicitacoes.map((solicitation) => ({
      ...solicitation,
      checked: this.checkSolicitation(solicitation),
    }));

    this.selectedSolicitations = this.solicitacoes.filter((sol) => sol.checked);
    this.disableButtons();
  }

  removeAllChecks() {
    this.solicitacoes = this.solicitacoes.map((solicitation) => ({
      ...solicitation,
      checked: false,
    }));

    this.selectedSolicitations = [];
    this.disableButtons();
  }

  allSolicitationsIsChecked() {
    return this.solicitacoes.some(
      (solicitation) => solicitation?.status !== SolicitationStatus.Concluido
        && solicitation?.status !== SolicitationStatus.ReprovadoCorporativo
        && solicitation.checked,
    );
  }

  checkItem(solicitation: SolicitationDetailModel) {
    const solicitationExists = this.selectedSolicitations.find(
      (sol) => sol.id === solicitation.id,
    );
    if (solicitationExists === undefined) {
      this.selectedSolicitations.push(solicitation);
    }
    this.disableButtons();
  }

  removeCheck(solicitation: SolicitationDetailModel) {
    const solicitationExists = this.selectedSolicitations.find(
      (sol) => sol.id === solicitation.id,
    );
    if (solicitationExists) {
      this.selectedSolicitations = this.selectedSolicitations.filter(
        (el) => el !== solicitation,
      );
    }
    this.disableButtons();
  }

  getLoggedUserData() {
    return this.storageService.getLoggedUserData();
  }

  canApprove() {
    let result = false;
    const role = this.getLoggedUserData();

    if (role.userRoleName === 'Triagem') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation.status === SolicitationStatusEnum.AwaitingScreening,
      );
    }
    if (role.userRoleName === 'Analista Suprimentos') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status === SolicitationStatusEnum.AwaitingScreening
          || solicitation?.status
            === SolicitationStatusEnum.AwaitingSupplyValitation
          || solicitation?.status === SolicitationStatusEnum.AwaitingValueReview,
      );
    }
    if (role.userRoleName === 'Analista Custos') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status === SolicitationStatusEnum.AwaitingScreening
          || solicitation?.status
            === SolicitationStatusEnum.AwaitingFinantialValidation
          || solicitation?.status === SolicitationStatusEnum.AwaitingValueReview
          || solicitation?.status === SolicitationStatusEnum.Closing,
      );
    }
    if (role.userRoleName === 'Supervisor') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingOperationalValidation,
      );
    }
    if (role.userRoleName === 'Gerente Operação') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingOperationManager,
      );
    }
    if (role.userRoleName === 'Diretor Operação') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingOperationDirector,
      );
    }
    if (role.userRoleName === 'Gerente Corporativo') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingCorporateManager,
      );
    }
    if (role.userRoleName === 'Diretor Corporativo') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingCorporateDirector,
      );
    }
    if (role.userRoleName === 'Coordenador Suprimentos') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingSupplyCoordinator,
      );
    }
    if (role.userRoleName === 'Coordenador Corporativo') {
      result = this.solicitacoes.some(
        (solicitation) => solicitation?.status
          === SolicitationStatusEnum.AwaitingCorporateCoordinator,
      );
    }
    if (role.userRoleName === 'Analista Pagamento') {
      result = false;
    }
    return result;
  }

  checkSolicitation(solicitation: SolicitationDetailModel) {
    let result = false;
    const role = this.getLoggedUserData();

    if (role.userRoleName === 'Triagem') {
      result = solicitation?.status === SolicitationStatusEnum.AwaitingScreening;
    }
    if (role.userRoleName === 'Analista Suprimentos') {
      result = solicitation?.status === SolicitationStatusEnum.AwaitingScreening
        || solicitation?.status === SolicitationStatusEnum.AwaitingSupplyValitation
        || solicitation?.status === SolicitationStatusEnum.AwaitingValueReview;
    }
    if (role.userRoleName === 'Analista Custos') {
      result = solicitation?.status === SolicitationStatusEnum.AwaitingScreening
        || solicitation?.status === SolicitationStatusEnum.AwaitingFinantialValidation
        || solicitation?.status === SolicitationStatusEnum.AwaitingValueReview
        || solicitation?.status === SolicitationStatusEnum.Closing;
    }
    if (role.userRoleName === 'Supervisor') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingOperationalValidation;
    }
    if (role.userRoleName === 'Gerente Operação') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingOperationManager;
    }
    if (role.userRoleName === 'Diretor Operação') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingOperationDirector;
    }
    if (role.userRoleName === 'Gerente Corporativo') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingCorporateManager;
    }
    if (role.userRoleName === 'Diretor Corporativo') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingCorporateDirector;
    }
    if (role.userRoleName === 'Coordenador Suprimentos') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingSupplyCoordinator;
    }
    if (role.userRoleName === 'Coordenador Corporativo') {
      result = solicitation?.status
        === SolicitationStatusEnum.AwaitingCorporateCoordinator;
    }
    if (role.userRoleName === 'Analista Pagamento') {
      result = false;
    }
    return result;
  }

  approve() {
    if (this.loadingApprove) return;

    this.setRequiredJustify();
    this.modalTitle = 'Tem certeza que deseja aprovar?';
    this.buttonLabel = 'Aprovar';
    this.buttonImage = '../../../../assets/icons/approve-common-100.svg';
    this.buttonType = this.eButtonsType.primary;
    this.buttonColor = this.ebuttonColor.success;
    this.approval = true;
    this.showModal = true;
  }

  repprove() {
    if (this.loadingDisapprove) return;

    this.required = true;
    this.modalTitle = 'Tem certeza que deseja reprovar?';
    this.buttonLabel = 'reprovar';
    this.buttonImage = '../../../../assets/icons/disapprove-common-100.svg';
    this.buttonType = this.eButtonsType.delete;
    this.buttonColor = this.ebuttonColor.warning;
    this.approval = false;
    this.showModal = true;
  }

  modalClick(event: SolicitationJustify) {
    const { approved, disapproved } = event;
    const solicitationJustify = event;
    solicitationJustify.ids = this.selectedSolicitations.map(
      (solicitation) => solicitation.id,
    );
    this.showModal = false;
    this.loadingApprove = solicitationJustify.approved;
    this.loadingDisapprove = solicitationJustify.disapproved;
    this.enabledApprove = solicitationJustify.approved;
    this.enabledDisapprove = solicitationJustify.disapproved;
    this.enabledReport = false;

    if (
      (approved || disapproved)
      && (!this.required || (this.required && solicitationJustify.justification))
    ) {
      this.solicitationService
        .massiveApprovements(solicitationJustify, this.username)
        .subscribe({
          next: this.handleApprovalSuccess.bind(this),
          error: this.handleApprovalError.bind(this),
        });
    } else {
      this.loadingApprove = false;
      this.loadingDisapprove = false;
      this.enabledApprove = true;
      this.enabledDisapprove = true;
      this.enabledReport = true;
    }
  }

  handleApprovalSuccess() {
    this.selectedSolicitations = [];
    this.modalModel.buttonLabel = 'Concluir';
    this.modalModel.description = 'Acompanhe o andamento através do menu Solicitações.';
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = `${
      this.approval ? 'Aprovações' : 'Reprovações'
    } realizadas com sucesso!`;
    this.modalModel.success = true;
    this.modalModel.showModal = true;
    this.loadingApprove = false;
    this.loadingDisapprove = false;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
    this.enabledReport = true;
  }

  handleApprovalError(res: HttpErrorResponse) {
    this.selectedSolicitations = [];
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível realizar o processo de ${
      this.approval ? 'aprovação!' : 'reprovação!'
    }`;
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.loadingApprove = false;
    this.loadingDisapprove = false;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
    this.enabledReport = true;
  }

  clickModal() {
    this.modalModel.showModal = false;
    if (this.modalModel.success) {
      this.redirect.emit(true);
    }
  }

  getCellMargin() {
    if (!this.approvalVisible) {
      return {
        'margin-right': `${20}px`,
        'margin-left': `${20}px`,
      };
    }
    return {};
  }

  getSolicitationsReportDownload() {
    if (this.loading) return;
    this.loading = true;
    this.enabledApprove = false;
    this.enabledDisapprove = false;
    this.filters = this.storageService.getSolicitationFilterForm();
    this.solicitationService
      .getSolicitationsReportDownload(this.filters)
      .subscribe({
        next: this.handleSolicitationsReportDownloadSuccess.bind(this),
        error: this.handleSolicitationsReportDownloadError.bind(this),
        complete: () => {
          this.loading = false;
          this.enabledApprove = true;
          this.enabledDisapprove = true;
        },
      });
  }

  setInitialStatusFilter() {
    const role = this.storageService.getLoggedUserData();

    if (this.filters === undefined) {
      this.filters = new GetSolicitationFilterModel();
    }

    if (role.userRoleName === 'Triagem') {
      this.filters.status = [SolicitationStatusEnum.AwaitingScreening];
    }
    if (role.userRoleName === 'Analista Suprimentos') {
      this.filters.status = [
        SolicitationStatusEnum.AwaitingScreening,
        SolicitationStatusEnum.AwaitingSupplyValitation,
        SolicitationStatusEnum.AwaitingValueReview,
      ];
    }
    if (role.userRoleName === 'Analista Custos') {
      this.filters.status = [
        SolicitationStatusEnum.AwaitingScreening,
        SolicitationStatusEnum.AwaitingFinantialValidation,
        SolicitationStatusEnum.AwaitingValueReview,
        SolicitationStatusEnum.Closing,
      ];
    }
    if (role.userRoleName === 'Supervisor') {
      this.filters.status = [
        SolicitationStatusEnum.AwaitingOperationalValidation,
      ];
    }
    if (role.userRoleName === 'Gerente Operação') {
      this.filters.status = [SolicitationStatusEnum.AwaitingOperationManager];
    }
    if (role.userRoleName === 'Diretor Operação') {
      this.filters.status = [SolicitationStatusEnum.AwaitingOperationDirector];
    }
    if (role.userRoleName === 'Gerente Corporativo') {
      this.filters.status = [SolicitationStatusEnum.AwaitingCorporateManager];
    }
    if (role.userRoleName === 'Diretor Corporativo') {
      this.filters.status = [SolicitationStatusEnum.AwaitingCorporateDirector];
    }
    if (role.userRoleName === 'Coordenador Suprimentos') {
      this.filters.status = [SolicitationStatusEnum.AwaitingSupplyCoordinator];
    }
    if (role.userRoleName === 'Coordenador Corporativo') {
      this.filters.status = [
        SolicitationStatusEnum.AwaitingCorporateCoordinator,
      ];
    }
    if (role.userRoleName === 'Analista Pagamento') {
      this.filters.status = [SolicitationStatusEnum.AwaitingPaymentValidation];
    }
  }

  /**
   * Method to handle the report as a Blob.
   * Creates an element to attach the report, creates a URL in memory and download the file.
   * And, after that revoke the url and hide the elmement.
   * @param reportFile Blob type {@link https://developer.mozilla.org/pt-BR/docs/Web/API/Blob}
   */
  handleSolicitationsReportDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as Intl.DateTimeFormatOptions;
    fileAnchor.download = `RelatorioSolicitacoes-${new Intl.DateTimeFormat(
      'pt-BR',
      options,
    ).format(new Date())}.xlsx`;
    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
    this.loading = false;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
  }

  handleSolicitationsReportDownloadError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível realizar o download do relatório. ${message?.Message}`;
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.loading = false;
    this.enabledApprove = true;
    this.enabledDisapprove = true;
  }

  reload() {
    this.reloadCallback.emit(true);
  }

  setRequiredJustify() {
    const role = this.getLoggedUserData();

    if (
      role.userRoleName === 'Gerente Operação'
      || role.userRoleName === 'Diretor Operação'
      || role.userRoleName === 'Gerente Corporativo'
      || role.userRoleName === 'Diretor Corporativo'
      || role.userRoleName === 'Coordenador Suprimentos'
      || role.userRoleName === 'Coordenador Corporativo'
    ) {
      this.required = false;
    }
  }
}
