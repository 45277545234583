import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class Utils {
  public calcWidth(width: number) {
    if (width <= 768) {
      return width;
    }
    if (width > 768 && width <= 1376) {
      return width * 0.75;
    }
    if (width > 1376 && width <= 1600) {
      return width * 0.875;
    }
    return width;
  }

  public addLeadingZeros(num: number, totalLength: number) {
    return String(num).padStart(totalLength, '0');
  }

  public formatName(str: string, limit: number) {
    const retorno =
      str?.length > limit ? str.slice(0, limit).concat('...') : str;
    return retorno;
  }

  public convertFromStringToDate(value: string) {
    const year = Number.parseInt(value.substring(6), 10);
    const month = Number.parseInt(value.substring(3, 5), 10);
    const day = Number.parseInt(value.substring(0, 2), 10);
    const date = new Date(year, month - 1, day);
    return date;
  }

  public numberFormatter(value: number, minDigits: number, maxDigits: number) {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    }).format(value);
  }

  public reducedName(value: string) {
    const values = value.split(' ');
    return values.length > 1
      ? `${values[0]} ${values[values.length - 1]}`
      : values[0];
  }

  public formatCnpj(value: string) {
    if (!value) {
      return '';
    }
    const cnpj = value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    return cnpj;
  }

  public generateRandomNumbers(multiplier: number) {
    return Math.floor(Math.random() * multiplier) + 1;
  }

  public toMoney(valor: string) {
    const numeroFormatado = parseFloat(valor).toFixed(2);
    const partes = numeroFormatado.split('.');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return 'R$ ' + partes.join(',');
}

public convertToInvariantDate(dataString: string): string {
  const [mes, ano] = dataString.split('/');
  const data = moment(`${ano}-${this.getMonthNumber(mes.toUpperCase()) + 1}-01`, "YYYY-MM-DD").toDate();
  return moment(data).format("MM/DD/YYYY");
}

  private getMonthNumber(mes: string): number {
    switch (mes) {
      case "JANEIRO":
        return 0;
      case "FEVEREIRO":
        return 1;
      case "MARÇO":
        return 2;
      case "ABRIL":
        return 3;
      case "MAIO":
        return 4;
      case "JUNHO":
        return 5;
      case "JULHO":
        return 6;
      case "AGOSTO":
        return 7;
      case "SETEMBRO":
        return 8;
      case "OUTUBRO":
        return 9;
      case "NOVEMBRO":
        return 10;
      case "DEZEMBRO":
        return 11;
      default:
        throw new Error("Mês inválido");
    }
  }
}
