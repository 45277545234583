import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-line',
  templateUrl: './skeleton-line.component.html',
  styleUrls: ['./skeleton-line.component.scss']
})
export class SkeletonLineComponent implements OnInit {

  @Input() line: number = 0;
  public arr:number[] = [];
  constructor() { }

  ngOnInit(): void {
    for (let index = 0; index < this.line; index++) {
      this.arr.push(0);
    }
  }

}
