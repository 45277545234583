<app-fleet-table
  [isLoading]="isLoading"
  [title]="'Impostos'"
  [icon]="'assets/icons/Money-Icon.svg'"
  [columnsHeader]="taxHeader"
  [rowsContent]="taxContent"
  [rowsStatus]="taxRowStatus"
  [totalActiveRow]="taxActiveContent"
  [totalStopedRow]="taxStopedContent"
></app-fleet-table>
