import { UpdateSupportTeamDetailModel } from './update-support-team-detail.model';

export class UpdateAdminFinancialModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.maintenanceAnalyst = new UpdateSupportTeamDetailModel();
    this.adminAndFinancialAssistant = new UpdateSupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  maintenanceAnalyst: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  adminAndFinancialAssistant: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
}
