import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchModel } from './model/search.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
  @Input() item: string = '';
  @Input() items: string[] = [];
  @Input() selected: boolean = false;
  @Input() control = new FormControl();
  @Input() searchModel!: SearchModel;
  @Input() title!: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() loadingCard: boolean = false;
  @Input() placeholder: string = '';
  @Input() error: boolean = false;
  @Input() errorMessage: string = '';
  @Input() showModal: boolean = false;
  @Output() handleClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() excludeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCardClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleOutsideClick: EventEmitter<any> = new EventEmitter<any>();
  public searchControl = new FormControl();

  ngOnChanges(changes: SimpleChanges): void {
    const { selected } = changes;

    if (
      selected &&
      selected.currentValue === true &&
      this.searchControl.value
    ) {
      this.control.patchValue(this.selected ? this.searchControl.value : '');
      this.control.markAsDirty();
    }
  }

  handleSearchClick() {
    if (this.searchControl.value !== '') {
      this.handleClick.emit(this.searchControl.value ?? '');
    }
  }

  handleCrossClick() {
    this.searchControl.patchValue('');
    this.control.patchValue('');
    this.control.markAsDirty();
    this.item = '';
    this.excludeClick.emit();
  }

  HandleOutsideClick() {
    this.handleOutsideClick.emit();
  }

  CardClick() {
    if (!this.loadingCard) {
      this.handleCardClick.emit();
    }
  }
}
