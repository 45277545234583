import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-text',
  templateUrl: './skeleton-text.component.html',
  styleUrls: ['./skeleton-text.component.scss']
})
export class SkeletonTextComponent implements OnInit {

  @Input() text: number = 0;
  public arr:number[] = [];
  constructor() { }

  ngOnInit(): void {
    for (let index = 0; index < this.text; index++) {
      this.arr.push(0);
    }
  }
}
