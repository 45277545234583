export class FleetTractorUpdateModel {
  quantity: number = 0;
  tractorFinancingStatus: string = '';
  tractorBrand: string = '';
  tractorModel: string = '';
  active: boolean = false;
  tractorYear: number = 0;
  tractorAcquisitionDate: Date = new Date();
  tractorResidual: number = 0;
}

