import { Component, OnInit } from '@angular/core';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { Validators, FormBuilder } from '@angular/forms';
import { OperationService } from 'src/app/services/operation/operation.service';
import { GetOperationPimsModel } from 'src/app/services/operation/models/get-operation-pims.model';
import { SearchControlModel } from '../../unit/unit-new/model/searc-control.model';
import { NewOperationPimsModel } from 'src/app/services/operation/models/new-operation-pims.model';
import { HttpErrorResponse } from '@angular/common/http';
import { OperationPimsService } from 'src/app/services/pims/operation/operation.service';

@Component({
  selector: 'app-operation-new',
  templateUrl: './operation-new.component.html',
  styleUrls: ['./operation-new.component.scss'],
})
export class OperationNewComponent implements OnInit {
  public operationSearch: SearchControlModel = new SearchControlModel();

  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public isLoading: boolean = false;
  public hasSearch: boolean = false;
  public enableButton: boolean = false;
  public loadingButton: boolean = false;
  public hasNotFound: boolean = false;
  public isLoadingOperation: boolean = false;

  public modalModel: ModalModel = new ModalModel();
  public operations: GetOperationPimsModel[] = [];

  public form = this.formBuilder.group({
    operation: ['', Validators.required],
  });

  constructor(
    public operationPimsService: OperationPimsService,
    public operationService: OperationService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  save() {
    this.loadingButton = true;
    const operationList: NewOperationPimsModel[] = [];
    this.operations
      .filter((x) => x.active)
      .forEach((item) => {
        operationList.push({
          name: item.name,
          operationId: item.operationId,
        } as NewOperationPimsModel);
      });
    this.operationService.createOperation(operationList).subscribe({
      next: this.createOperatorPimsSuccess.bind(this),
      error: this.createOperatorPimsError.bind(this),
    });
  }

  createOperatorPimsSuccess() {
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Cadastros PIMS vinculados com sucesso';
    this.modalModel.imagePath = '../../../../../assets/icons/success-600.gif';
    this.modalModel.title = 'Cadastro realizado com sucesso';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
  }

  createOperatorPimsError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Falha ao realizar o cadastro';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  handleSearchClick(value: string, model: SearchControlModel) {
    this.hasNotFound = false;
    const searchValue = value.split(',');
    if (searchValue.some((item) => item.length < 3)) {
      model.error = true;
      model.errorMessage = 'Este filtro precisa conter pelo menos 3 caracteres';
      return;
    }
    model.loading = true;
    this.isLoadingOperation = true;
    this.operationPimsService.getOperations(value).subscribe({
      next: (element) => {
        this.isLoadingOperation = false;
        this.hasSearch = true;
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.items = searchValue;
        this.operations = element;

        this.form.markAsDirty();
        if (element.length === 0) {
          model.errorMessage = 'Não foi possivel localizar';
          this.hasNotFound = true;
          model.error = true;
        }
      },
      error: () => {
        this.isLoadingOperation = false;
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar';
        this.hasNotFound = true;
      },
    });
  }

  excludeCard(model: SearchControlModel) {
    this.hasSearch = false;
    this.operations = [];
    this.hasNotFound = false;
    model.selected = false;
    model.items = [];
    this.form.controls.operation.markAsDirty();
    this.form.controls.operation.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  clickModal() {
    this.loadingButton = false;
    this.modalModel.showModal = false;
  }

  checkAll() {
    this.enableButton = true;
    this.operations.forEach((x) => {
      x.active = true;
    });
  }

  removeAllChecks() {
    this.enableButton = true;
    this.operations.forEach((x) => {
      x.active = false;
    });
  }

  onCheck(operations: GetOperationPimsModel) {
    this.enableButton = true;
    operations.active = true;
  }

  onRemoveCheck(operations: GetOperationPimsModel) {
    this.enableButton = true;
    operations.active = false;
  }
}
