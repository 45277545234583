/* eslint-disable no-param-reassign */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { BenefitValuesModel } from 'src/app/services/dfdv/benefit/models/benefit-values-model';
import { BenefitModel } from 'src/app/services/dfdv/benefit/models/benefit.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ColumnTypeEnum } from 'src/app/shared/dynamic-table/Enum/columnTypeEnum';
import { DynamicTableComponent } from 'src/app/shared/dynamic-table/dynamic-table.component';
import { InputColumnModel } from 'src/app/shared/dynamic-table/model/InputColumnModel';
import { ColumnModel } from 'src/app/shared/dynamic-table/model/columnModel';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent implements OnInit, OnDestroy {
  public benefitForm: FormGroup[] = [];
  public columnModel: ColumnModel[] = [
    {
      name: 'nightDriver',
      type: ColumnTypeEnum.input,
      hasTotal: true,
      totalLeftPadding: 1,
      hasAverage: false,
      inputColumnModel: {
        type: InputTypeEnum.number,
        mask: MaskTypeEnum.money,
        showNumberActions: false,
        placeholder: '0,00',
        sideIcon: true,
        sideIconLabel: 'R$',
        isDisabled: false,
      } as InputColumnModel,
    },
    {
      name: 'defaultDriver',
      type: ColumnTypeEnum.input,
      hasTotal: true,
      totalLeftPadding: 1,
      hasAverage: false,
      inputColumnModel: {
        type: InputTypeEnum.number,
        mask: MaskTypeEnum.money,
        showNumberActions: false,
        placeholder: '0,00',
        sideIcon: true,
        sideIconLabel: 'R$',
        isDisabled: false,
      } as InputColumnModel,
    },
  ] as ColumnModel[];

  // Enums
  public ButtonColorEnum = ButtonColorEnum;

  // Variables
  public shouldDisableFields: boolean = false;
  public canPopulateForm: boolean = true;
  public rowsDescription: string[] = [];
  public dfdv!: GetDfdvModel;
  public dfdvLoading: boolean = false;
  public showTooltip: boolean = false;
  public paragraphText =
    'Informar os valores gastos para cada motorista mensalmente.';
  public dfdvUtils!: DfdvUtils;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  @ViewChild('benefit') benefit!: DynamicTableComponent;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  benefitStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.benefit));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.benefitStore$.subscribe((benefit) => {
      if (!benefit) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        this.populateForm(benefit);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        const enableForOPL =
          !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
        const enableForRaizen =
          this.dfdvUtils.isUserRaizen() &&
          (this.dfdvUtils.isNew() || this.dfdvUtils.isDraft());
        this.shouldDisableFields = !(enableForOPL || enableForRaizen);
        this.setColumnModelInputIsDisabled();
      }
    });
  }

  setColumnModelInputIsDisabled() {
    this.columnModel.forEach(
      (x) => (x.inputColumnModel.isDisabled = this.shouldDisableFields)
    );
  }

  populateForm(model: BenefitModel | null) {
    if (model && this.canPopulateForm) {
      this.getDescriptionRows();
      const benefitModel = this.mapFromBenefitResult(model);

      if (benefitModel === undefined) {
        return;
      }

      Object.keys(benefitModel.keys).forEach((modelKey) => {
        this.benefitForm.push(
          this.formBuilder.group({
            nightDriver: [benefitModel?.keys[modelKey]?.nightDriver],
            defaultDriver: [benefitModel?.keys[modelKey]?.defaultDriver],
          })
        );
      });

      this.showTooltip = true;
      this.benefit.forceChanges();
    }
  }

  getDescriptionRows() {
    this.rowsDescription = [
      'Ticket Alimentação',
      'Ticket Refeição',
      'Cesta Básica',
      'Vale Transporte',
      'Assistência Médica',
      'Seguro de Vida',
      'PCMSO',
      'PPRA',
      'PLR',
    ];
  }

  updateDFDVStore() {
    this.canPopulateForm = false;
    const model = new BenefitModel();
    this.processModel(model);

    this.fillModel(model);

    this.dfdv.benefit = this.mapToBenefitResult(model);

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'benefit',
        data: this.dfdv.benefit,
      })
    );
  }

  processModel(model: BenefitModel) {
    model.keys.foodTicket = new BenefitValuesModel();
    model.keys.mealTicket = new BenefitValuesModel();
    model.keys.basicBasket = new BenefitValuesModel();
    model.keys.transportationVoucher = new BenefitValuesModel();
    model.keys.healthCare = new BenefitValuesModel();
    model.keys.lifeInsurance = new BenefitValuesModel();
    model.keys.pcmso = new BenefitValuesModel();
    model.keys.ppra = new BenefitValuesModel();
    model.keys.plr = new BenefitValuesModel();
  }

  fillModel(model: BenefitModel) {
    let index = 0;
    let totalStandard = 0;
    let totalNight = 0;
    Object.keys(model.keys).forEach((modelKey) => {
      const form = this.benefitForm[index];
      Object.keys(form.controls).forEach((key) => {
        model.keys[modelKey][key] = form.get(key)?.value
          ? form.get(key)?.value
          : '0';

        if (key === 'nightDriver') {
          totalNight += +model.keys[modelKey][key];
        } else {
          totalStandard += +model.keys[modelKey][key];
        }
      });
      index++;
    });

    model.totalNightDriver = totalNight;
    model.totalStandardDriver = totalStandard;
  }

  mapFromBenefitResult(result: any): BenefitModel | undefined {
    if (result === undefined) {
      return undefined;
    }

    const benefit = new BenefitModel();
    benefit.totalStandardDriver = result?.totalStandardDriver;
    benefit.totalNightDriver = result?.totalNightDriver;
    benefit.keys.foodTicket.defaultDriver = result?.standardDriver?.foodTicket;
    benefit.keys.mealTicket.defaultDriver = result?.standardDriver?.mealTicket;
    benefit.keys.basicBasket.defaultDriver =
      result?.standardDriver?.basicBasket;
    benefit.keys.transportationVoucher.defaultDriver =
      result?.standardDriver?.transportationVoucher;
    benefit.keys.healthCare.defaultDriver = result?.standardDriver?.healthCare;
    benefit.keys.lifeInsurance.defaultDriver =
      result?.standardDriver?.lifeInsurance;
    benefit.keys.pcmso.defaultDriver = result?.standardDriver?.pcmso;
    benefit.keys.ppra.defaultDriver = result?.standardDriver?.ppra;
    benefit.keys.plr.defaultDriver = result?.standardDriver?.plr;

    benefit.keys.foodTicket.nightDriver = result?.nightDriver?.foodTicket;
    benefit.keys.mealTicket.nightDriver = result?.nightDriver?.mealTicket;
    benefit.keys.basicBasket.nightDriver = result?.nightDriver?.basicBasket;
    benefit.keys.transportationVoucher.nightDriver =
      result?.nightDriver?.transportationVoucher;
    benefit.keys.healthCare.nightDriver = result?.nightDriver?.healthCare;
    benefit.keys.lifeInsurance.nightDriver = result?.nightDriver?.lifeInsurance;
    benefit.keys.pcmso.nightDriver = result?.nightDriver?.pcmso;
    benefit.keys.ppra.nightDriver = result?.nightDriver?.ppra;
    benefit.keys.plr.nightDriver = result?.nightDriver?.plr;

    return benefit;
  }

  mapToBenefitResult(benefit: BenefitModel): any | undefined {
    if (benefit === undefined) {
      return undefined;
    }

    const result = {
      totalNightDriver: benefit.totalNightDriver,
      totalStandardDriver: benefit.totalStandardDriver,

      standardDriver: {
        foodTicket: benefit.keys.foodTicket.defaultDriver,
        mealTicket: benefit.keys.mealTicket.defaultDriver,
        basicBasket: benefit.keys.basicBasket.defaultDriver,
        transportationVoucher: benefit.keys.transportationVoucher.defaultDriver,
        healthCare: benefit.keys.healthCare.defaultDriver,
        lifeInsurance: benefit.keys.lifeInsurance.defaultDriver,
        pcmso: benefit.keys.pcmso.defaultDriver,
        ppra: benefit.keys.ppra.defaultDriver,
        plr: benefit.keys.plr.defaultDriver,
      },
      nightDriver: {
        foodTicket: benefit.keys.foodTicket.nightDriver,
        mealTicket: benefit.keys.mealTicket.nightDriver,
        basicBasket: benefit.keys.basicBasket.nightDriver,
        transportationVoucher: benefit.keys.transportationVoucher.nightDriver,
        healthCare: benefit.keys.healthCare.nightDriver,
        lifeInsurance: benefit.keys.lifeInsurance.nightDriver,
        pcmso: benefit.keys.pcmso.nightDriver,
        ppra: benefit.keys.ppra.nightDriver,
        plr: benefit.keys.plr.nightDriver,
      },
    };

    return result;
  }
}
