export class FleetStoppedModel {
  [key: string]: any;

  totalStoppedQuantity!: number;
  averageStoppedTractorCost!: number;
  averageStoppedTractorTireCost!: number;
  averageStoppedTractorPisCofinsCost!: number;
  averageStoppedTractorIcmsCost!: number;
  averageStoppedTractorNetValueDepreciation!: number;
  averageStoppedTractorNetValueRemuneration!: number;
  averageStoppedTractorDepreciationPeriod!: number;
  averageStoppedTractorUsageCycle!: number;
  averageStoppedTractorResidual!: number;
  averageStoppedTrailerCost!: number;
  averageStoppedTrailerTireCost!: number;
  averageStoppedTrailerBaseProfit!: number;
  averageStoppedTrailerPisCofinsCost!: number;
  averageStoppedTrailerIcmsCost!: number;
  averageStoppedTrailerNetValueDepreciation!: number;
  averageStoppedTrailerNetValueRemuneration!: number;
  averageStoppedTrailerDepreciationPeriod!: number;
  averageStoppedTrailerUsageCycle!: number;
  averageStoppedTrailerResidual!: number;
  averageStoppedDpvat!: number;
  averageStoppedIpvaAndLicensingFee!: number;
  averageStoppedAutoInsurance!: number;
  averageStoppedRcfv!: number;
  averageStoppedLicensing!: number;
  averageStoppedTractorDepreciation!: number;
  averageStoppedTrailerDepreciation!: number;
  averageStoppedTractorCapitalRemuneration!: number;
  averageStoppedTrailerCapitalRemuneration!: number;
  averageStoppedTractorCapitalRemunerationOnTaxes!: number;
  averageStoppedTrailerCapitalRemunerationOnTaxes!: number;
  averageStoppedCapitalRemunerationOnTaxes!: number;
  averageStoppedTotalRemuneration!: number;
  averageStoppedTotalProfit!: number;
  averageStoppedFixedProfit!: number;
  averageStoppedVariableProfit!: number;
}
