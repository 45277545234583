export enum SolicitationStatusEnum {
  AwaitingScreening = 'Aguardando Triagem',
  DisapprovedScreening = 'Reprovado Triagem',
  AwaitingOperationalValidation = 'Aguardando Validação Operacional',
  DisapprovedOperationalValidation = 'Reprovado Validação Operacional',
  AwaitingSupplyValitation = 'Aguardando Validação Suprimentos',
  DisapprovedSupplyValitation = 'Reprovado Validação Suprimentos',
  AwaitingFinantialValidation = 'Aguardando Validação Financeira',
  DisapprovedFinantialValidation = 'Reprovado Validação Financeira',
  AwaitingValueReview = 'Aguardando Revisão de Valor',
  DisapprovedValueReview = 'Reprovado Revisão de Valor',
  AwaitingSupplyCoordinator = 'Aguardando Coordenador Suprimentos',
  DisapprovedSupplyCoordinator = 'Reprovado Coordenador Suprimentos',
  AwaitingCorporateCoordinator = 'Aguardando Coordenador Corporativo',
  DisapprovedCorporateCoordinator = 'Reprovado Coordenador Corporativo',
  AwaitingOperationManager = 'Aguardando Gerente Operação',
  DisapprovedOperationManager = 'Reprovado Gerente Operação',
  AwaitingOperationDirector = 'Aguardando Diretor Operação',
  DisapprovedOperationDirector = 'Reprovado Diretor Operação',
  AwaitingCorporateManager = 'Aguardando Gerente Corporativo',
  DisapprovedCorporateManager = 'Reprovado Gerente Corporativo',
  AwaitingCorporateDirector = 'Aguardando Diretor Corporativo',
  DisapprovedCorporateDirector = 'Reprovado Diretor Corporativo',
  Closing = 'Em Fechamento',
  DisapprovedClosing = 'Reprovado Fechamento',
  Done = 'Concluido',
  AwaitingPaymentValidation = 'Aguardando Validação de Pagamento',
  DisapprovedPaymentValidation = 'Reprovado Validação de Pagamento',
  Conclusion = 'Em Conclusão',
  DisapprovedConclusion = 'Reprovado Em Conclusão',
}
