export class UpdateSolicitationModel {
  solicitationId: number = 0;
  unitName?: string = undefined;
  categoryName?: string = undefined;
  subcategoryName?: string = undefined;
  paymentTypeName?: string = undefined;
  value?: number = undefined;
  negotiatedValue?: number = undefined;
  title?: string = undefined;
  description?: string = undefined;
  approved?: boolean = undefined;
  justification?: string = undefined;
  valueReview?: boolean = undefined;
  recurrenceSolicitation!: boolean;
  recurrenceMonths!: number | null;
}
