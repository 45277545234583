export class SolicitationDetailModel {
  id!: number;
  openingDate!: Date;
  completionDate!: Date;
  operatorId!: string;
  fantasyName!: string;
  unitName!: string;
  valor!: number;
  categoryName!: string;
  status!: string;
  type!: string;
  checked: boolean = false;
  title!: string;
}
