import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';

export const loadFixedCostsModel = createAction(
  '[FixedCostStateHandler] Load FixedCost',
  props<{ id: number }>()
);

export const loadFixedCostsModelSuccess = createAction(
  '[FixedCostStateHandler] Load FixedCost Success',
  props<{ data: FixedCostsModel }>()
);

export const setLoading = createAction(
  '[FixedCostLoadingHandler] Set loading state',
  props<{ isLoading: boolean }>()
);

export const getError = createAction(
  '[FixedCostErrorHandler] get Error state',
  props<{ errorMessage: any }>()
);
export const setError = createAction(
  '[FixedCostErrorHandler] Set Error state',
  props<{ errorMessage: any }>()
);

export const updateSolicitation = createAction(
  '[FixedCostStateHandler] Update SubObject',
  props<{ data: FixedCostsModel; solicitationId: number; approved: boolean }>()
);

export const createFixedCost = createAction(
  '[FixedCostStateHandler] Create fixedCost'
);

export const clearFixedCost = createAction(
  '[FixedCostStateHandler] Clear fixedCost'
);
