import { Utils } from 'src/app/common/utils/utils';
import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';

@Component({
  selector: 'app-preregister-operator-list',
  templateUrl: './preregister-operator-list.component.html',
  styleUrls: ['./preregister-operator-list.component.scss'],
})
export class PreRegisterOperatorListComponent implements OnInit {
  @Output() operatorRegister = new EventEmitter<GetOperatorModel>();
  @Input() preRegisterList: GetOperatorModel[] = [];

  constructor(private operatorService: OperatorService, public util: Utils) {}

  ngOnInit(): void {}

  requestSuccess(res: GetOperatorModel[]) {
    this.preRegisterList = res;
  }

  requestError() {}

  addNewOperator(newOperator: GetOperatorModel) {
    this.operatorRegister.emit(newOperator);
  }
}
