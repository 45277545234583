import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';
import { CreateSolicitationModel } from 'src/app/services/solicitation/models/create-solicitation-model';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { GetSolicitationFilterModel } from 'src/app/services/solicitation/models/get-solicitation-filter-model';
import { CategoryStorageModel } from '../../services/category/models/category-storage.model';
import { ScopeModel } from '../models/scope.model';
import { LoggedUserModel } from '../models/logged-user.model';
import { RoleModel } from '../models/role.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { GetFixedCostFilterModel } from 'src/app/services/fixedCosts/models/get-fixedCostFilter.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly TOKEN_PASSWORD_KEY = `${environment.AppName}TP`;
  private readonly TOKEN_CREDENTIALS_KEY = `${environment.AppName}TC`;

  private readonly SCOPE_KEY = `${environment.AppName}S`;
  private readonly USER_KEY = `${environment.AppName}U`;
  private readonly USER_LOGGED_KEY = `${environment.AppName}UL`;
  private readonly SOLICITATION_FORM_KEY = `${environment.AppName}SF`;
  private readonly UNIT_FORM_KEY = `${environment.AppName}UF`;
  private readonly CATEGORY_FORM_KEY = `${environment.AppName}CF`;
  private readonly SOLICITATION_FILTER_FORM_KEY = `${environment.AppName}SFF`;
  private readonly DFDV_KEY = `${environment.AppName}DFDV`;
  private readonly FIXEDCOST_FILTER_FORM_KEY = `${environment.AppName}FCFF`;

  clear(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  getTokenPassword() {
    const tokenB64 = window.localStorage.getItem(this.TOKEN_PASSWORD_KEY) ?? '';
    return Buffer.from(tokenB64, 'base64').toString();
  }

  setTokenPassword(token: string) {
    const tokenB64 = Buffer.from(token).toString('base64');
    window.localStorage.removeItem(this.TOKEN_PASSWORD_KEY);
    window.localStorage.setItem(this.TOKEN_PASSWORD_KEY, tokenB64);
  }

  getTokenCredentials() {
    const tokenB64 =
      window.sessionStorage.getItem(this.TOKEN_CREDENTIALS_KEY) ?? '';
    return Buffer.from(tokenB64, 'base64').toString();
  }

  setTokenCredentials(token: string) {
    const tokenB64 = Buffer.from(token).toString('base64');
    window.sessionStorage.removeItem(this.TOKEN_CREDENTIALS_KEY);
    window.sessionStorage.setItem(this.TOKEN_CREDENTIALS_KEY, tokenB64);
  }

  getScope() {
    const scopeB64 = window.localStorage.getItem(this.SCOPE_KEY) ?? '';
    const scopeStr = Buffer.from(scopeB64, 'base64').toString();

    try {
      return JSON.parse(scopeStr) as ScopeModel;
    } catch (error: any) {
      return new ScopeModel();
    }
  }

  setScope(scope: ScopeModel) {
    const scopeStr = JSON.stringify(scope);
    const scopeB64 = Buffer.from(scopeStr).toString('base64');
    window.localStorage.removeItem(this.SCOPE_KEY);
    window.localStorage.setItem(this.SCOPE_KEY, scopeB64);
  }

  getRole() {
    const scopeModel = this.getScope();

    try {
      return scopeModel.roles[0] as RoleModel;
    } catch (error: any) {
      return new RoleModel();
    }
  }

  setUser(user: string) {
    const userB64 = Buffer.from(user).toString('base64');
    window.localStorage.removeItem(this.USER_KEY);
    window.localStorage.setItem(this.USER_KEY, userB64);
  }

  getUser() {
    const userB64 = window.localStorage.getItem(this.USER_KEY) ?? '';
    return Buffer.from(userB64, 'base64').toString();
  }

  setLoggedUserData(loggedUser: LoggedUserModel) {
    const loggeruserStr = JSON.stringify(loggedUser);
    const loggedUserB64 = Buffer.from(loggeruserStr).toString('base64');
    window.localStorage.removeItem(this.USER_LOGGED_KEY);
    window.localStorage.setItem(this.USER_LOGGED_KEY, loggedUserB64);
  }

  getLoggedUserData() {
    const loggedUserB64 =
      window.localStorage.getItem(this.USER_LOGGED_KEY) ?? '';
    const loggedUserStr = Buffer.from(loggedUserB64, 'base64').toString();

    try {
      return JSON.parse(loggedUserStr) as LoggedUserModel;
    } catch (error: any) {
      return new LoggedUserModel();
    }
  }

  getAnalystRole() {
    return 'Fake';
  }

  setCreateSolicitationForm(solicitation: CreateSolicitationModel) {
    const solicitationFormStr = JSON.stringify(solicitation);
    const solicitationFormB64 =
      Buffer.from(solicitationFormStr).toString('base64');
    window.sessionStorage.removeItem(this.SOLICITATION_FORM_KEY);
    window.sessionStorage.setItem(
      this.SOLICITATION_FORM_KEY,
      solicitationFormB64
    );
  }

  getCreateSolicitationForm() {
    const solicitationFormB64 =
      window.sessionStorage.getItem(this.SOLICITATION_FORM_KEY) ?? '';
    const solicitationFormStr = Buffer.from(
      solicitationFormB64,
      'base64'
    ).toString();

    try {
      return JSON.parse(solicitationFormStr) as CreateSolicitationModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearCreateSolicitationForm() {
    window.sessionStorage.removeItem(this.SOLICITATION_FORM_KEY);
  }

  setUnit(units: GetUnitModel) {
    const unitsStr = JSON.stringify(units);
    const unitsB64 = Buffer.from(unitsStr).toString('base64');
    window.sessionStorage.removeItem(this.UNIT_FORM_KEY);
    window.sessionStorage.setItem(this.UNIT_FORM_KEY, unitsB64);
  }

  getUnit() {
    const unitsB64 = window.sessionStorage.getItem(this.UNIT_FORM_KEY) ?? '';
    const unitsStr = Buffer.from(unitsB64, 'base64').toString();

    try {
      return JSON.parse(unitsStr) as GetUnitModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearUnit() {
    window.sessionStorage.removeItem(this.UNIT_FORM_KEY);
  }

  setCreateCategoryForm(category: CategoryStorageModel) {
    const categoryFormStr = JSON.stringify(category);
    const categoryFormB64 = Buffer.from(categoryFormStr).toString('base64');
    window.sessionStorage.removeItem(this.CATEGORY_FORM_KEY);
    window.sessionStorage.setItem(this.CATEGORY_FORM_KEY, categoryFormB64);
  }

  getCreateCategoryForm() {
    const categoryFormB64 =
      window.sessionStorage.getItem(this.CATEGORY_FORM_KEY) ?? '';
    const categoryFormStr = Buffer.from(categoryFormB64, 'base64').toString();

    try {
      return JSON.parse(categoryFormStr) as CategoryStorageModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearCreateCategoryForm() {
    window.sessionStorage.removeItem(this.CATEGORY_FORM_KEY);
  }

  getSolicitationFilterForm() {
    const solicitationFilterFormB64 =
      window.sessionStorage.getItem(this.SOLICITATION_FILTER_FORM_KEY) ?? '';
    const solicitationFilterStr = Buffer.from(
      solicitationFilterFormB64,
      'base64'
    ).toString();

    try {
      return JSON.parse(solicitationFilterStr) as GetSolicitationFilterModel;
    } catch (error: any) {
      return undefined;
    }
  }

  setSolicitationFilterForm(solicitationFilter: GetSolicitationFilterModel) {
    const solicitationFilterFormStr = JSON.stringify(solicitationFilter);
    const solicitationFilterFormB64 = Buffer.from(
      solicitationFilterFormStr
    ).toString('base64');
    window.sessionStorage.removeItem(this.SOLICITATION_FILTER_FORM_KEY);
    window.sessionStorage.setItem(
      this.SOLICITATION_FILTER_FORM_KEY,
      solicitationFilterFormB64
    );
  }

  clearSolicitationFilterForm() {
    window.sessionStorage.removeItem(this.SOLICITATION_FILTER_FORM_KEY);
  }

  setDfdv(dfdv: GetDfdvModel) {
    const dfdvStr = JSON.stringify(dfdv);
    const dfdvB64 = Buffer.from(dfdvStr).toString('base64');
    window.sessionStorage.removeItem(this.DFDV_KEY);
    window.sessionStorage.setItem(this.DFDV_KEY, dfdvB64);
  }

  getDfdv() {
    const dfdvB64 = window.sessionStorage.getItem(this.DFDV_KEY) ?? '';
    const dfdvStr = Buffer.from(dfdvB64, 'base64').toString();

    try {
      return JSON.parse(dfdvStr) as GetDfdvModel;
    } catch (error: any) {
      return undefined;
    }
  }

  clearDfdv() {
    window.sessionStorage.removeItem(this.DFDV_KEY);
  }

  getFixedCostFilterForm() {
    const fixedCostFilterFormB64 =
      window.sessionStorage.getItem(this.FIXEDCOST_FILTER_FORM_KEY) ?? '';
    const fixedCostFilterStr = Buffer.from(
      fixedCostFilterFormB64,
      'base64'
    ).toString();

    try {
      return JSON.parse(fixedCostFilterStr) as GetFixedCostFilterModel;
    } catch (error: any) {
      return undefined;
    }
  }

  setFixedCostFilterForm(fixedCostFilter: GetFixedCostFilterModel) {
    const fixedCostFilterStr = JSON.stringify(fixedCostFilter);
    const fixedCostFilterFormB64 =
      Buffer.from(fixedCostFilterStr).toString('base64');
    window.sessionStorage.removeItem(this.FIXEDCOST_FILTER_FORM_KEY);
    window.sessionStorage.setItem(
      this.FIXEDCOST_FILTER_FORM_KEY,
      fixedCostFilterFormB64
    );
  }

  clearFixedCostFilter() {
    window.sessionStorage.removeItem(this.FIXEDCOST_FILTER_FORM_KEY);
  }
}
