import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-toogle',
  templateUrl: './toogle.component.html',
  styleUrls: ['./toogle.component.scss'],
})
export class ToogleComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
  @Input() label: string = '';
  @Input() toggleId: string = 'check';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Output() check: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.setChecked();
  }

  ngAfterViewInit(): void {
    this.setChecked();
  }

  ngOnChanges() {
    this.setChecked();
  }

  ngAfterViewChecked(): void {
    this.setChecked();
  }

  setChecked() {
    if (this.checked) {
      const checkElement = document.getElementById(this.toggleId);
      checkElement?.setAttribute('checked', 'true');
    } else {
      const checkElement = document.getElementById(this.toggleId);
      checkElement?.removeAttribute('checked');
    }
  }

  onCheck() {
    this.checked = !this.checked;
    this.check.emit(this.checked);
  }
}
