<article
  [ngClass]="{ 'full-description': fullDescription }"
  (click)="emitEvent()"
>
  <aside>
    <img [src]="icon" alt="Card icon" />
  </aside>
  <main>
    <header>{{ title }}</header>
    <span>{{ description }}</span>
  </main>
  <div>
    <img
      src="../../../assets/icons/arrow-right-primary-600.svg"
      alt="Arrow icon"
    />
  </div>
</article>
