import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Subject, Subscription, takeUntil } from 'rxjs';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { BenefitModel } from 'src/app/services/dfdv/benefit/models/benefit.model';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { OperationalTeamCalcModel } from './models/operational-team-calc.model';
import { Utils } from 'src/app/common/utils/utils';
import { StorageService } from 'src/app/common/services/storage.service';

@Component({
  selector: 'app-operational-team',
  templateUrl: './operational-team.component.html',
  styleUrls: ['./operational-team.component.scss'],
})
export class OperationalTeamComponent implements OnInit, OnDestroy {
  // Models
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public operationalTeamModel: OperationalTeamCalcModel =
    new OperationalTeamCalcModel();

  // Aux
  public dfdvLoading: boolean = false;
  public isLoading: boolean = true;

  // NgRx
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  public destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  constructor(
    public utils: Utils,
    public service: DfdvService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  ngOnInit(): void {
    this.getDFDV();
    this.dfdvStore$.subscribe((x) => {
      this.dfdv = x;
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.dfdvStore$.subscribe((dfdv) => {
      if (!dfdv) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading?.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        this.dfdv = { ...dfdv };
        if (this.isLoading && this.dfdv.dfdvId !== undefined) {
          this.buildData();
          this.isLoading = false;
        }
        
      }
    });
  }

  buildData() {
    this.operationalTeamModel.driverSalary =
      this.dfdv.driverFactor.driverSalary;
    this.operationalTeamModel.collectiveAgreement =
      this.dfdv.driverFactor.collectiveBargainingAgreement;
    this.operationalTeamModel.standardDriverTeamFactor =
      this.dfdv.driverFactor.standardDriverTeamFactor;
    this.operationalTeamModel.nightDriverTeamFactor =
      this.dfdv.driverFactor.nightDriverTeamFactor;
    this.operationalTeamModel.workingHours =
      this.dfdv.driverFactor.workingHours;
    this.operationalTeamModel.tractorQuantity =
      this.dfdv.scope.equipment.tractorQuantity;
    const benefitModel = this.mapFromBenefitResult(this.dfdv.benefit);

    // Standard Driver
    this.operationalTeamModel.standardDriver.salaryWithAgreement =
      this.dfdv.driverFactor.driverSalary *
      (1 + this.dfdv.driverFactor.collectiveAgreement / 100);
    this.operationalTeamModel.standardDriver.toxicologicalExam =
      this.dfdv.plant.harvestMonths !== 0
        ? (2 * this.dfdv.driverFactor.toxicologicalExam) /
          this.dfdv.plant.harvestMonths
        : 0;
    this.operationalTeamModel.standardDriver.totalToxicologicalExam =
      this.operationalTeamModel.standardDriver.toxicologicalExam;
    this.operationalTeamModel.standardDriver.driverHoursCost =
      this.dfdv.driverFactor.workingHours !== 0
        ? this.operationalTeamModel.standardDriver.salaryWithAgreement /
          this.dfdv.driverFactor.workingHours
        : 0;
    this.operationalTeamModel.standardDriver.quantitativeHours =
      (this.dfdv.rvParameter.totalMonthlyHoursOfQuantitativeItems *
        this.dfdv.rvParameter.ceilingRangeDistributionWeighting) /
      100;
    this.operationalTeamModel.standardDriver.qualitativeHours =
      (this.dfdv.rvParameter.totalMonthlyHoursOfQualitativeItems *
        this.dfdv.rvParameter.ceilingRangeDistributionWeighting) /
      100;
    this.operationalTeamModel.standardDriver.totalRemunerationWithoutCharges =
      (this.operationalTeamModel.standardDriver.quantitativeHours +
        this.operationalTeamModel.standardDriver.qualitativeHours) *
      this.operationalTeamModel.standardDriver.driverHoursCost;
    this.operationalTeamModel.standardDriver.totalRemunerationWithCharges =
      this.operationalTeamModel.standardDriver.totalRemunerationWithoutCharges *
      (1 + this.dfdv.charge.totalCharge / 100 - 0.04);
    this.operationalTeamModel.standardDriver.inItinereHours =
      this.dfdv.driverFactor.standardDriverInIntinereHours;
    this.operationalTeamModel.standardDriver.inItinereCost =
      this.operationalTeamModel.standardDriver.driverHoursCost *
      1.5 *
      this.dfdv.driverFactor.standardDriverInIntinereHours;
    this.operationalTeamModel.standardDriver.nightAdditional = 0;
    this.operationalTeamModel.standardDriver.dsr =
      ((this.operationalTeamModel.standardDriver.inItinereCost +
        this.operationalTeamModel.standardDriver.nightAdditional) /
        24.5) *
      5.5;
    this.operationalTeamModel.standardDriver.paycheckTotal =
      this.operationalTeamModel.standardDriver.salaryWithAgreement +
      this.operationalTeamModel.standardDriver.inItinereCost +
      this.operationalTeamModel.standardDriver.nightAdditional +
      this.operationalTeamModel.standardDriver.dsr;
    this.operationalTeamModel.standardDriver.totalCharge =
      (this.dfdv.charge.totalCharge / 100) *
      this.operationalTeamModel.standardDriver.paycheckTotal;
    this.operationalTeamModel.standardDriver.paycheckWithCharges =
      this.operationalTeamModel.standardDriver.paycheckTotal +
      this.operationalTeamModel.standardDriver.totalCharge +
      this.operationalTeamModel.standardDriver.toxicologicalExam +
      this.dfdv.benefit.totalStandardDriver;
    this.operationalTeamModel.standardDriver.paycheck =
      this.operationalTeamModel.standardDriver.paycheckTotal +
      this.operationalTeamModel.standardDriver.totalRemunerationWithoutCharges;

    this.operationalTeamModel.standardDriver.provisionsCharge =
      this.dfdv.charge.totalCharge;
    this.operationalTeamModel.standardDriver.foodTicket =
      benefitModel?.keys?.foodTicket.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.mealTicket =
      benefitModel?.keys?.mealTicket.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.basicBasket =
      benefitModel?.keys?.basicBasket.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.transportationVoucher =
      benefitModel?.keys?.transportationVoucher.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.healthCare =
      benefitModel?.keys?.healthCare.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.lifeInsurance =
      benefitModel?.keys?.lifeInsurance.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.pcmso =
      benefitModel?.keys?.pcmso.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.ppra =
      benefitModel?.keys?.ppra.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.plr =
      benefitModel?.keys?.plr.defaultDriver ?? 0;
    this.operationalTeamModel.standardDriver.totalBenefit =
      this.dfdv.benefit.totalStandardDriver;

    // Night Driver
    this.operationalTeamModel.nightDriver.salaryWithAgreement =
      this.dfdv.driverFactor.driverSalary *
      (1 + this.dfdv.driverFactor.collectiveAgreement / 100);
    this.operationalTeamModel.nightDriver.toxicologicalExam =
      this.dfdv.plant.harvestMonths !== 0
        ? (2 * this.dfdv.driverFactor.toxicologicalExam) /
          this.dfdv.plant.harvestMonths
        : 0;
    this.operationalTeamModel.nightDriver.totalToxicologicalExam =
      this.operationalTeamModel.nightDriver.toxicologicalExam;
    this.operationalTeamModel.nightDriver.driverHoursCost =
      this.dfdv.driverFactor.workingHours !== 0
        ? this.operationalTeamModel.nightDriver.salaryWithAgreement /
          this.dfdv.driverFactor.workingHours
        : 0;
    this.operationalTeamModel.nightDriver.quantitativeHours =
      (this.dfdv.rvParameter.totalMonthlyHoursOfQuantitativeItems *
        this.dfdv.rvParameter.ceilingRangeDistributionWeighting) /
      100;
    this.operationalTeamModel.nightDriver.qualitativeHours =
      (this.dfdv.rvParameter.totalMonthlyHoursOfQualitativeItems *
        this.dfdv.rvParameter.ceilingRangeDistributionWeighting) /
      100;
    this.operationalTeamModel.nightDriver.totalRemunerationWithoutCharges =
      (this.operationalTeamModel.nightDriver.quantitativeHours +
        this.operationalTeamModel.nightDriver.qualitativeHours) *
      this.operationalTeamModel.nightDriver.driverHoursCost;
    this.operationalTeamModel.nightDriver.totalRemunerationWithCharges =
      this.operationalTeamModel.nightDriver.totalRemunerationWithoutCharges *
      (1 + this.dfdv.charge.totalCharge / 100 - 0.04);
    this.operationalTeamModel.nightDriver.inItinereHours =
      this.dfdv.driverFactor.nightDriverInIntinereHours;
    this.operationalTeamModel.nightDriver.inItinereCost =
      this.operationalTeamModel.nightDriver.driverHoursCost *
      1.5 *
      this.dfdv.driverFactor.nightDriverInIntinereHours;
    this.operationalTeamModel.nightDriver.nightAdditional =
      (this.dfdv.charge.chargeBase.nightAdditional / 100) *
      8 *
      this.dfdv.driverFactor.workingDays *
      this.operationalTeamModel.nightDriver.driverHoursCost;
    this.operationalTeamModel.nightDriver.dsr =
      ((this.operationalTeamModel.nightDriver.inItinereCost +
        this.operationalTeamModel.nightDriver.nightAdditional) /
        24.5) *
      5.5;
    this.operationalTeamModel.nightDriver.paycheckTotal =
      this.operationalTeamModel.nightDriver.salaryWithAgreement +
      this.operationalTeamModel.nightDriver.inItinereCost +
      this.operationalTeamModel.nightDriver.nightAdditional +
      this.operationalTeamModel.nightDriver.dsr;
    this.operationalTeamModel.nightDriver.totalCharge =
      (this.dfdv.charge.totalCharge / 100) *
      this.operationalTeamModel.nightDriver.paycheckTotal;
    this.operationalTeamModel.nightDriver.paycheckWithCharges =
      this.operationalTeamModel.nightDriver.paycheckTotal +
      this.operationalTeamModel.nightDriver.totalCharge +
      this.operationalTeamModel.nightDriver.toxicologicalExam +
      this.dfdv.benefit.totalNightDriver;
    this.operationalTeamModel.nightDriver.paycheck =
      this.operationalTeamModel.nightDriver.paycheckTotal +
      this.operationalTeamModel.nightDriver.totalRemunerationWithoutCharges;

    this.operationalTeamModel.nightDriver.provisionsCharge =
      this.dfdv.charge.totalCharge;
    this.operationalTeamModel.nightDriver.foodTicket =
      benefitModel?.keys?.foodTicket.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.mealTicket =
      benefitModel?.keys?.mealTicket.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.basicBasket =
      benefitModel?.keys?.basicBasket.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.transportationVoucher =
      benefitModel?.keys?.transportationVoucher.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.healthCare =
      benefitModel?.keys?.healthCare.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.lifeInsurance =
      benefitModel?.keys?.lifeInsurance.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.pcmso =
      benefitModel?.keys?.pcmso.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.ppra =
      benefitModel?.keys?.ppra.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.plr =
      benefitModel?.keys?.plr.nightDriver ?? 0;
    this.operationalTeamModel.nightDriver.totalBenefit =
      this.dfdv.benefit.totalNightDriver;

  }

  mapFromBenefitResult(result: any): BenefitModel | undefined {
    if (result === undefined) {
      return undefined;
    }

    const benefit = new BenefitModel();
    benefit.totalStandardDriver = result?.totalStandardDriver;
    benefit.totalNightDriver = result?.totalNightDriver;
    benefit.keys.foodTicket.defaultDriver = result?.standardDriver?.foodTicket;
    benefit.keys.mealTicket.defaultDriver = result?.standardDriver?.mealTicket;
    benefit.keys.basicBasket.defaultDriver =
      result?.standardDriver?.basicBasket;
    benefit.keys.transportationVoucher.defaultDriver =
      result?.standardDriver?.transportationVoucher;
    benefit.keys.healthCare.defaultDriver = result?.standardDriver?.healthCare;
    benefit.keys.lifeInsurance.defaultDriver =
      result?.standardDriver?.lifeInsurance;
    benefit.keys.pcmso.defaultDriver = result?.standardDriver?.pcmso;
    benefit.keys.ppra.defaultDriver = result?.standardDriver?.ppra;
    benefit.keys.plr.defaultDriver = result?.standardDriver?.plr;

    benefit.keys.foodTicket.nightDriver = result?.nightDriver?.foodTicket;
    benefit.keys.mealTicket.nightDriver = result?.nightDriver?.mealTicket;
    benefit.keys.basicBasket.nightDriver = result?.nightDriver?.basicBasket;
    benefit.keys.transportationVoucher.nightDriver =
      result?.nightDriver?.transportationVoucher;
    benefit.keys.healthCare.nightDriver = result?.nightDriver?.healthCare;
    benefit.keys.lifeInsurance.nightDriver = result?.nightDriver?.lifeInsurance;
    benefit.keys.pcmso.nightDriver = result?.nightDriver?.pcmso;
    benefit.keys.ppra.nightDriver = result?.nightDriver?.ppra;
    benefit.keys.plr.nightDriver = result?.nightDriver?.plr;

    return benefit;
  }
}
