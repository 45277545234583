<app-fleet-table
  [isLoading]="isLoading"
  [title]="'Remuneração Capital + Depreciação'"
  [icon]="'assets/icons/Money-Icon.svg'"
  [columnsHeader]="capitalHeader"
  [rowsContent]="capitalContent"
  [rowsStatus]="capitalRowStatus"
  [totalActiveRow]="capitalActiveContent"
  [totalStopedRow]="capitalStopedContent"
></app-fleet-table>
