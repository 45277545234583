<div class="container">
  <section>
    <header>
      <app-primary-button
        label="NOVA CATEGORIA"
        icon="../../../../assets/icons/add-common-100.svg"
        [color]="ButtonColorEnum.primary"
        [right]="false"
        [loading]="false"
        [enabled]="true"
        (click)="navigateToNewCategory()"
      ></app-primary-button>
    </header>
    <app-category-list></app-category-list>
  </section>
</div>
