import { InvoicingModel } from "../invoicing/invoicing.model";

export class DfdvDetailModel {
  dfdvId!: number;
  unitName: string = '';
  categoryName: string = '';
  fantasyName: string = '';
  operatorId: string = '';
  creationDate!: Date;
  startDate!: Date;
  endDate!: Date;
  version!: number;
  previousVersionId!: number;
  nextVersionId!: number;
  dfdvTypeName: string = '';
  dfdvStatusName: string = '';
  checked: boolean = false;
  invoicing?: InvoicingModel = new InvoicingModel();
}
