import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent implements OnInit {
  public eButtonsType = ButtonsType;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  onBack() {
    this.router.navigate(['solicitation']);
  }
}
