import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-scope-rental',
  templateUrl: './scope-rental.component.html',
  styleUrls: ['./scope-rental.component.scss'],
})
export class ScopeRentalComponent implements OnInit {
  @Input() form = new FormGroup({
    lightFleetRental: new FormControl(''),
    collectiveTransportRental: new FormControl(''),
    workshopTruckRental: new FormControl(''),
    tractorRental: new FormControl(''),
    trailerRental: new FormControl(''),
  });

  @Input() shouldDisableFields: boolean = false;
  @Input() disableFieldOPL: boolean = false;
  @Input() disableFieldRaizen: boolean = false;
  @Input() isLoading: boolean = false;
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  constructor() {}

  ngOnInit(): void {}

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
