<app-card
  [paddingHorizontal]="false"
  [title]="'Uniforme Equipe Operacional'"
  [icon]="'../../../../../assets/icons/Money-Icon.svg'"
  [accordion]="true"
>
  <div class="table-container" body *ngIf="!card">
    <app-skeleton-line
      *ngIf="isLoading"
      [line]="6"
      class="skeleton"
    ></app-skeleton-line>
    <div *ngIf="!isLoading && hasError" class="infobox">
      <app-infobox [type]="eInfoboxType.warning">
        <span content>
          Os seguintes campos em vermelho e marcados com * são de preenchimento
          obrigatório.
        </span>
      </app-infobox>
    </div>
    <header *ngIf="!isLoading">
      <span *ngFor="let x of header">
        {{ x }}
      </span>
    </header>
    <section *ngIf="!isLoading" class="rows-container">
      <div
        class="rows"
        *ngFor="
          let element of uniformOperationalTeam.controls.rows.controls;
          let i = index
        "
      >
        <span [ngStyle]="{ color: utils.isValid(element) }">
          {{ rowList[i] }}
        </span>
        <app-input
          [control]="element.controls.quantity"
          [placeholder]="'0'"
          [mask]="eMaskType.number"
          [max]="9999"
          [min]="0"
          [height]="height"
          [numberActionsWidth]="numberActionsWidth"
          [showTitle]="false"
          [enableMessageError]="false"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          [placeholder]="'0,00'"
          [showSideIcon]="true"
          [enableMessageError]="false"
          [sideIconLabel]="'R$'"
          [mask]="eMaskType.money"
          [control]="element.controls.cost"
          [height]="height"
          [showTitle]="false"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <span class="number harvest">
          {{ utils.calcHarvestOtherExpenses(element) | currency : "BRL" }}
        </span>
        <span class="number month">
          {{
            utils.calcMonthOtherExpenses(element, harvestMonths)
              | currency : "BRL"
          }}
        </span>
      </div>
      <div class="total-result">
        <span>TOTAL</span>
        <span class="number">-</span>
        <span class="number">-</span>
        <span class="number">{{ calcTotal(true) | currency : "BRL" }}</span>
        <span class="number">{{ calcTotal(false) | currency : "BRL" }}</span>
      </div>
    </section>
  </div>
  <div class="card-container" body *ngIf="card">
    <app-skeleton-line
      *ngIf="isLoading"
      [line]="6"
      class="skeleton"
    ></app-skeleton-line>
    <section *ngIf="!isLoading" class="card-item-container">
      <div
        class="item"
        *ngFor="
          let element of uniformOperationalTeam.controls.rows.controls;
          let i = index
        "
      >
        <span class="card-header" [ngStyle]="{ color: utils.isValid(element) }">
          {{ rowList[i] }}
        </span>
        <div class="card-content">
          <span>Quantidade</span>
          <app-input
            [control]="element.controls.quantity"
            [placeholder]="'0'"
            [mask]="eMaskType.number"
            [max]="9999"
            [min]="0"
            [height]="'2.125rem'"
            [numberActionsWidth]="numberActionsWidth"
            [enableMessageError]="false"
            [showTitle]="false"
            [disabled]="shouldDisableFields"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <span>Custo Un. Mensal</span>
          <app-input
            [placeholder]="'0,00'"
            [showSideIcon]="true"
            [enableMessageError]="false"
            [mask]="eMaskType.money"
            [sideIconLabel]="'R$'"
            [showTitle]="false"
            [control]="element.controls.cost"
            [height]="'2.125rem'"
            [disabled]="shouldDisableFields"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <span>Custo Safra</span>
          <span class="number harvest">
            {{ utils.calcHarvestOtherExpenses(element) | currency : "BRL" }}
          </span>
          <span>Custo Mensal</span>
          <span class="number month">
            {{
              utils.calcMonthOtherExpenses(element, harvestMonths)
                | currency : "BRL"
            }}
          </span>
        </div>
      </div>
      <div class="total-result">
        <span>Custo Total Safra</span>
        <span class="number">{{ calcTotal(true) | currency : "BRL" }}</span>
      </div>
      <div class="total-result">
        <span>Custo Mensal Total</span>
        <span class="number">{{ calcTotal(false) | currency : "BRL" }}</span>
      </div>
    </section>
  </div>
</app-card>
