import { UpdateSupportTeamDetailModel } from './update-support-team-detail.model';

export class UpdateManagementModel {
  [key: string]: any;
  transportTypeName!: string | null;

  constructor() {
    this.transportTypeName = null;
    this.transportGeneralManager = new UpdateSupportTeamDetailModel();
    this.managementAnalyst = new UpdateSupportTeamDetailModel();
  }

  transportGeneralManager: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
  managementAnalyst: UpdateSupportTeamDetailModel =
    new UpdateSupportTeamDetailModel();
}
