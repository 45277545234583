import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-card-option',
  templateUrl: './card-option.component.html',
  styleUrls: ['./card-option.component.scss'],
})
export class CardOptionComponent {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() fullDescription: boolean = false;
  @Output() callbackFunc = new EventEmitter<void>();

  emitEvent() {
    if (this.callbackFunc) {
      this.callbackFunc.emit();
    }
  }
}
