import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-solicitation-data',
  templateUrl: './solicitation-data.component.html',
  styleUrls: ['./solicitation-data.component.scss'],
})
export class SolicitationDataComponent implements OnInit {
  public hasId: boolean = false;

  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.get('id')) {
        this.hasId = true;
      }
    });
  }
}
