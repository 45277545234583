<div class="container">
  <section>
    <div class="tab-container">
      <app-tab #tabs>
        <app-tab-item title="Lançamento" #tabRelease>
          <app-card
            [icon]="'../../../../../../assets/icons/units-primary-600.svg'"
            [title]="unit.instanceId ?? 'Unidade'"
            [paddingHorizontal]="false"
            [accordion]="false"
          >
            <section actions class="actions">
              <app-status
                [color]="getStatusColor(unit.fixedCostStatusName)"
                [image]="getStatusIcon(unit.fixedCostStatusName)"
                [status]="unit.fixedCostStatusName"
                *ngIf="!showSkeleton"
              ></app-status>
              <app-icon-button
                (clickButton)="sendBack()"
                icon="../../../../assets/icons/arrow-previous-primary-600.svg"
                text="Voltar"
                [color]="ebuttonColor.primary"
                [enabled]="true"
              ></app-icon-button>
            </section>
            <div body class="content-body" *ngIf="!showSkeleton">
              <div class="content-body-item">
                <span>Valor Total</span>
                <span class="value-gray">{{
                  unit.totalValue | currency : "R$"
                }}</span>
              </div>
              <div class="content-body-item">
                <span>Valor Atual</span>
                <span class="value-gray">{{
                  unit.currentValue | currency : "R$"
                }}</span>
              </div>
              <div class="content-body-item">
                <span>Valor Enviado</span>
                <span class="value-green">{{
                  unit.valueSent | currency : "R$"
                }}</span>
              </div>
              <div class="content-body-item" *ngIf="unit.fixedCostStatusName !== 'Concluido'">
                <span>Valor a ser Pago</span>
                <span class="value-orange">{{
                   getCrurrentValue()  | currency : "R$"
                }}</span>
              </div>
            </div>
            <div class="skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="4"></app-skeleton-box>
            </div>
          </app-card>
          <app-fixed-costs-releases
            [operators]="unit.operators"
            [fixedCostSolicitationPendings]="unit.fixedCostSolicitationPendings"
            [fixedCostSolicitationsSents]="unit.fixedCostSolicitationsSents"
            [isConcluded]="unit.fixedCostStatusName === 'Concluido'"
          ></app-fixed-costs-releases>
        </app-tab-item>
        <app-tab-item title="Histórico" #tabHistory>
            <app-fixed-cost-history></app-fixed-cost-history>
        </app-tab-item>
      </app-tab>
    </div>
  </section>
</div>
