<ng-template #content>
  <article>
    <section class="date">
      <ng-container *ngIf="hasDate">
        <span>{{ dateItem.format('DD/MM/YYYY') }}</span>
        <span>{{ dateItem.format('HH:mm:ss') }}</span>
      </ng-container>
    </section>
    <section class="track">
      <span [ngClass]="{ 'hidden-track': first }" class="before"></span>
      <img
        [src]="
          type === 'approved'
            ? '../../../../assets/icons/track-successs-600.svg'
            : type === 'reproved'
            ? '../../../../assets/icons/track-warning-600.svg'
            : type === 'awaiting'
            ? '../../../../assets/icons/track-attention-600.svg'
            : '../../../../assets/icons/track-primary-600.svg'
        "
        alt="Track icon"
      />
      <span [ngClass]="{ 'hidden-track': last }" class="after"></span>
    </section>
    <section class="content">
      <header>
        <ng-content select="[header]"></ng-content>
        <div *ngIf="accordion" (click)="toggleAccordion()">
          <img
            [ngClass]="{ 'rotate-animation': open }"
            src="../../../../assets/icons/arrow-up-primary-600.svg"
            alt="Arrow icon"
          />
        </div>
      </header>
      <main
        [ngClass]="{ 'hidden-body': !open }"
        [@smoothCollapse]="open ? 'initial' : 'final'"
      >
        <ng-content select="[body]"></ng-content>
      </main>
    </section>
  </article>
</ng-template>
