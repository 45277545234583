<div class="profit-container">
  <section>
    <header>
      <app-sumarize-header currentPageTitle="Lucro"></app-sumarize-header>
    </header>
    <app-card
      [title]="'Lucro'"
      [icon]="'../../../../assets/icons/money-common.svg'"
      [paddingHorizontal]="false"
    >
      <section body>
        <app-skeleton-line
          *ngIf="dfdvLoading"
          class="skeleton"
          [line]="1"
        ></app-skeleton-line>
        <app-skeleton-input
          *ngIf="dfdvLoading"
          class="skeleton"
          [input]="3"
        ></app-skeleton-input>
        <div *ngIf="!dfdvLoading" class="header">
          <div class="header__info">
            <p>
              Montante calculado sobre o valor do investimento e remunerado
              mensalmente, em que:
            </p>
            <p>
              <b>(1) Pagamento Fixo:</b> é remunerado por caminhão na safra +
              entressafra (12 meses);
            </p>
            <p>
              <b>(2) Pagamento Variável:</b> é remunerado por por tabela
              variável na safra (8 meses).
            </p>
          </div>
          <app-input
            label="Lucro"
            errorMessage="Informe um valor valido"
            [control]="profitForm.controls.profitValue"
            [mask]="eInputMask.money"
            [showSideIcon]="true"
            [sideIconLabel]="'%'"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableField(true)"
            (updateValue)="updateDFDVStore()"
          ></app-input>
        </div>
        <div class="table-container" *ngIf="!isMobile && !dfdvLoading">
          <div class="table data-header">
            <span>DESCRIÇÃO</span>
            <span>LUCRO FIXO (1)</span>
            <span>LUCRO VARIÁVEL (2)</span>
          </div>
          <div class="table data">
            <span>Valor</span>
            <app-input
              (focusout)="onFocusOut('fixed')"
              [showTitle]="false"
              [enableMessageError]="false"
              errorMessage="Informe um valor valido"
              [control]="profitForm.controls.fixedProfit"
              [mask]="eInputMask.money"
              [showSideIcon]="true"
              [sideIconLabel]="'%'"
              [sideIconType]="eSideIconType.text"
              [disabled]="shouldDisableField(false)"
              (updateValue)="updateDFDVStore()"
            ></app-input>
            <app-input
              (focusout)="onFocusOut('variable')"
              [showTitle]="false"
              [enableMessageError]="false"
              errorMessage="Informe um valor valido"
              [control]="profitForm.controls.variableProfit"
              [mask]="eInputMask.money"
              [showSideIcon]="true"
              [sideIconLabel]="'%'"
              [sideIconType]="eSideIconType.text"
              [disabled]="shouldDisableField(false)"
              (updateValue)="updateDFDVStore()"
            ></app-input>
          </div>
          <div class="table total">
            <span>Total</span>
            <span>{{ totalFixed === 0 ? "-" : totalFixed }} %</span>
            <span>{{ totalVariable === 0 ? "-" : totalVariable }} %</span>
          </div>
        </div>
      </section>
      <!-- Card -->
      <section body *ngIf="isMobile">
        <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        <app-skeleton-input
          *ngIf="dfdvLoading"
          [input]="3"
        ></app-skeleton-input>
        <div *ngIf="!dfdvLoading" class="card-box">
          <div>
            <div class="card-content">
              <span>LUCRO FIXO</span>
              <app-input
                (focusout)="onFocusOut('fixed')"
                [showTitle]="false"
                [enableMessageError]="false"
                errorMessage="Informe um valor valido"
                [control]="profitForm.controls.fixedProfit"
                [mask]="eInputMask.money"
                [showSideIcon]="true"
                [sideIconLabel]="'%'"
                [sideIconType]="eSideIconType.text"
                [disabled]="shouldDisableField(false)"
                (updateValue)="updateDFDVStore()"
              ></app-input>
              <span class="total">Total</span>
              <span class="total">
                {{ totalFixed === 0 ? "-" : totalFixed }} %
              </span>
            </div>
            <div class="card-content">
              <span>LUCRO VARIAVEL</span>
              <app-input
                (focusout)="onFocusOut('variable')"
                [showTitle]="false"
                [enableMessageError]="false"
                errorMessage="Informe um valor valido"
                [control]="profitForm.controls.variableProfit"
                [mask]="eInputMask.money"
                [showSideIcon]="true"
                [sideIconLabel]="'%'"
                [sideIconType]="eSideIconType.text"
                [disabled]="shouldDisableField(false)"
                (updateValue)="updateDFDVStore()"
              ></app-input>
              <span class="total">Total</span>
              <span class="total">
                {{ totalVariable === 0 ? "-" : totalVariable }} %
              </span>
            </div>
          </div>
        </div>
      </section>
    </app-card>
  </section>
</div>
