import { BaseService } from 'src/app/common/services/base.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthResetPasswordModel } from '../models/auth-resetPassword.model';
import { LoggedUserModel } from '../models/logged-user.model';
import { AuthResponseModel } from '../models/auth-response.model';
import { StorageService } from './storage.service';
import { AuthValidateTokenResponse } from '../models/auth-validateToken-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) {
    super('authorization');
  }

  loginPassword(username: string, password: string) {
    const url = `${environment.SensediaBaseUrl}oauth/access-token`;
    const body = {
      grant_type: 'password',
      scope: environment.AppName,
      username,
      password,
    };

    return this.http.post<AuthResponseModel>(url, body).pipe(
      tap((res) => {
        this.storageService.setTokenPassword(res.access_token);
        this.storageService.setScope(res.scope);
        this.storageService.setUser(username);
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  loginCredentials() {
    const url = `${environment.SensediaBaseUrl}oauth/access-token`;
    const body = {
      grant_type: 'client_credentials',
    };

    return this.http.post<AuthResponseModel>(url, body).pipe(
      tap((res) => {
        this.storageService.setTokenCredentials(res.access_token);
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  logout() {
    this.storageService.clear();
    this.router.navigate(['login']);
  }

  checkIfUserCanLogin(username: string) {
    return this.http.post<LoggedUserModel>(`${this.url}/users/${username}/can-login`, {});
  }

  validateToken(token: string) {
    return this.http.get<AuthValidateTokenResponse>(`${this.publicUrl}/users/${token}/reset-password`, {});
  }

  resetPassword(username:string, request: AuthResetPasswordModel) {
    return this.http.put<AuthValidateTokenResponse>(`${this.publicUrl}/users/${username}/reset-password`, request);
  }

  requestResetPassword(username: string) {
    return this.http.post(`${this.publicUrl}/users/${username}/reset-password`, {} as any);
  }

  redirectToAccessDenied() {
    this.router.navigate(['access-denied']);
  }
}
