import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
})
export class ModalGenericComponent {
  @Input() title: string = '';
  @Input() imagePath: string = '';
  @Input() showModal: boolean = false;
  @Output() HandleOutsideClick: EventEmitter<any> = new EventEmitter<any>();

  public onClick() {
    this.showModal = !this.showModal;
  }

  public outsideClick() {
    this.HandleOutsideClick.emit();
  }
}
