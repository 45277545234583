import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-tires',
  templateUrl: './tires.component.html',
  styleUrls: ['./tires.component.scss'],
})
export class TiresComponent {
  @Input() isLoading: boolean = false;
  @Input() shouldDisableFields: boolean = false;
  @Input() disableFieldQuantity: boolean = false;
  @Input() unitCostTotal: number = 0;
  @Input() subtotalWithFix: number = 0;
  @Input() unitCostOfTiresPerKm: number = 0;
  @Input() tiresForm = new FormGroup({
    tireBrand: new FormControl(''),
    tireTypeName: new FormControl(''),
    tireQuantity: new FormControl(''),
    tireCost: new FormControl(''),
    retreadingQuantity: new FormControl(''),
    retreadingCost: new FormControl(''),
    tireLifespanKm: new FormControl(''),
  });
  @Input() selectedTireTypes: string[] = [];
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enum
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eButtonsType = ButtonsType;
  public eSideIconType = SideIconTypeEnum;

  public optionsTireTypes: string[] = ['1.100', '295'];
  public errorMessage = 'Este campo é obrigatório';

  constructor() {}

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
