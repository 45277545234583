import { StorageService } from 'src/app/common/services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GetSolicitationFilterModel } from 'src/app/services/solicitation/models/get-solicitation-filter-model';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { LoggedUserModel } from 'src/app/common/models/logged-user.model';
import { GetSolicitationPaged } from '../../services/solicitation/models/get-solicitation-paged.model';
import { SolicitationDetailModel } from './models/solicitation-detail.model';
import { SolicitationListComponent } from './solicitation-list/solicitation-list.component';

@Component({
  selector: 'app-solicitation',
  templateUrl: './solicitation.component.html',
  styleUrls: ['./solicitation.component.scss'],
})
export class SolicitationComponent implements OnInit {
  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  public solicitationFilter = new GetSolicitationFilterModel();
  public solicitations: SolicitationDetailModel[] = [];
  public filters: GetSolicitationFilterModel | undefined;
  public userRole: string = '';
  public username!: string;
  public isLoading: boolean = true;
  public isLoadingFilter: boolean = true;
  public error: boolean = false;
  public requiredJustify: boolean = true;
  public page!: number;
  public totalPages!: number;
  public loggedUser!: LoggedUserModel;

  @ViewChild('list') solicitationList!: SolicitationListComponent;


  constructor(
    private router: Router,
    private solicitationService: SolicitationService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.username = this.storageService.getUser();
    this.setRequiredJustify();
  }

  setRequiredJustify() {
    this.loggedUser = this.getLoggedUserData();

    if (
      this.loggedUser.userRoleName === 'Gerente Operação' ||
      this.loggedUser.userRoleName === 'Diretor Operação' ||
      this.loggedUser.userRoleName === 'Gerente Corporativo' ||
      this.loggedUser.userRoleName === 'Diretor Corporativo' ||
      this.loggedUser.userRoleName === 'Coordenador Suprimentos' ||
      this.loggedUser.userRoleName === 'Coordenador Corporativo'
    ) {
      this.requiredJustify = false;
    }
  }

  setUserRole(useRole: string) {
    this.userRole = useRole;
  }

  getSolicitations() {
    this.solicitationService
      .getSolicitations(this.solicitationFilter, this.username)
      .subscribe({
        next: this.handleSolicitationSuccess.bind(this),
        error: this.handleSolicitationError.bind(this),
        complete: () => {
          this.isLoading = false;
          this.error = false;
        },
      });
  }

  getSolicitationsFiltered(filters: GetSolicitationFilterModel) {
    this.isLoading = true;
    this.filters = filters;
    this.solicitationService
      .getSolicitations(filters, this.username)
      .subscribe({
        next: this.handleSolicitationSuccess.bind(this),
        error: this.handleSolicitationError.bind(this),
        complete: () => {
          this.isLoading = false;
          this.error = false;
        },
      });
  }

  navigateToNewSolicitation() {
    this.router.navigate(['solicitation/new']);
  }

  handleSolicitationSuccess(res: GetSolicitationPaged) {
    this.solicitations = [];
    this.solicitationList.selectedSolicitations = [];
    this.solicitations = res.items.map(
      (element) =>
        ({
          id: element.solicitationId,
          openingDate: element.openingDate,
          completionDate: element.completionDate,
          operatorId: element.operatorId,
          fantasyName: element.fantasyName,
          unitName: element.unitName,
          valor: element.negotiatedValue,
          categoryName: element.categoryName,
          status: element.solicitationStatusName,
          type: element.solicitationTypeName,
          checked: false,
          title: element.title,
        } as SolicitationDetailModel)
    );
    this.page = res.pageNumber;
    this.totalPages = res.items ? res.totalPages : 0;
  }

  handleSolicitationError(res: HttpErrorResponse) {
    this.solicitations = [];
    this.error = true;
    this.isLoading = false;
  }

  navigateBack(event: boolean) {
    if (event) {
      this.reload();
    }
  }

  getLoggedUserData() {
    return this.storageService.getLoggedUserData();
  }

  setPage(index: number) {
    this.isLoading = true;
    if (this.filters) {
      this.filters.pageNumber = index;
      this.getSolicitationsFiltered(this.filters);
    } else {
      this.solicitationFilter.pageNumber = index;
      this.getSolicitations();
    }
  }

  reload() {
    this.isLoading = true;
    if (this.filters) {
      this.getSolicitationsFiltered(this.filters);
    } else {
      this.getSolicitations();
    }
  }

  handleSolicitationsCallBack(response: GetSolicitationFilterModel) {
    this.solicitationFilter = response;
    this.getSolicitations();
  }
}
