import { AdminFinancialModel } from './adminFinancial.model';
import { MaintenenceAndWarehouseModel } from './maintenenceAndWarehouse.model';
import { ManagementModel } from './management.model';
import { OperationalSupportModel } from './operation-support.model';
import { RhAndSsmaModel } from './rhAndSsma.model';
import { SupportTeamFleetModel } from './support-team-fleet.model';
import { SupportTeamTotalizerModel } from './support-team-totalizer.model';

export class SupportTeamModel {
  [key: string]: any;

  constructor() {
    this.total = new SupportTeamTotalizerModel();
    this.management = new ManagementModel();
    this.operationalSupport = new OperationalSupportModel();
    this.maintenanceAndWarehouse = new MaintenenceAndWarehouseModel();
    this.hrAndSsma = new RhAndSsmaModel();
    this.adminAndFinancial  = new AdminFinancialModel();
    this.supportTeamFleet = new SupportTeamFleetModel();
  }

  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();

  management: ManagementModel = new ManagementModel();
  operationalSupport: OperationalSupportModel = new OperationalSupportModel();
  maintenanceAndWarehouse: MaintenenceAndWarehouseModel = new MaintenenceAndWarehouseModel();
  hrAndSsma: RhAndSsmaModel = new RhAndSsmaModel();
  adminAndFinancial: AdminFinancialModel = new AdminFinancialModel();
  supportTeamFleet: SupportTeamFleetModel = new SupportTeamFleetModel();
}
