import { UpdateOperationalSupportModel } from './../../services/dfdv/models/support-team/update-operation-support.model';
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import { InvoicingModel } from 'src/app/services/dfdv/invoicing/invoicing.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { SupportTeamModel } from 'src/app/services/dfdv/models/support-team/support-team.model';
import { UpdateSupportTeamModel } from 'src/app/services/dfdv/models/support-team/update-support-team.model';
import { UpdateDfdvModel } from 'src/app/services/dfdv/models/updateDfdv.Model';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { GetGeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/get-general-cost.model';
import { pmt } from 'financial';
import { FleetActiveModel } from 'src/app/services/dfdv/fleet/models/fleet-active.model';
import { FleetStoppedModel } from 'src/app/services/dfdv/fleet/models/fleet-stopped.model';
import { Store } from '@ngrx/store';
import { FleetConfigurationItemsModel } from 'src/app/services/dfdv/fleet/models/fleet-configuration-items.model';
import { SummaryModel } from 'src/app/services/dfdv/summary/models/summary.model';
import { RoleModel } from '../models/role.model';
import { RolesEnum } from '../enums/roles.enum';
import { FleetUpdateModel } from '../../services/dfdv/fleet/models/fleet-update.model';
import * as DfdvActions from '../store/dfdv/dfdv.actions';
import { IDfdvState } from '../store/dfdv/dfdv.state';
import { ILoadingState } from '../store/fixedCost/fixedCost.state';
import { Utils } from './utils';
import { OperationalTeamModel } from 'src/app/services/dfdv/operational-team/models/operational-team.model';
import { OperationalTeamDriver } from 'src/app/services/dfdv/operational-team/models/operational-team-driver.model';

export class DfdvUtils {
  dfdvStatus: string = '';
  public utils = new Utils();
  constructor(
    public dfdv: GetDfdvModel,
    public role: RoleModel,
    public store?: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {
    this.dfdvStatus = this.dfdv.dfdvStatusName;
  }
  isUserRaizen(): boolean {
    return (
      this.role.name.includes(RolesEnum.admin) ||
      this.role.name.includes(RolesEnum.analyst)
    );
  }

  isUserOpl(): boolean {
    return !this.isUserRaizen();
  }

  isEnableToEdit(): boolean {
    return this.isEnableForOPL() || this.isEnableForRaizen();
  }

  isEnableForOPL(): boolean {
    return !this.isUserRaizen() && this.isPreliminary();
  }

  isEnableForRaizen(): boolean {
    return (
      this.isUserRaizen() &&
      (this.isDraft() || this.isNew() || this.isPreliminary())
    );
  }

  isDraft() {
    return this.dfdvStatus === DfdvStatusEnum.Draft;
  }

  isPreliminary() {
    return this.dfdvStatus === DfdvStatusEnum.Preliminary;
  }

  isPending() {
    return this.dfdvStatus === DfdvStatusEnum.Pending;
  }

  isReproved() {
    return this.dfdvStatus === DfdvStatusEnum.Reproved;
  }

  isArchived() {
    return this.dfdvStatus === DfdvStatusEnum.Archived;
  }

  isCurrent() {
    return this.dfdvStatus === DfdvStatusEnum.Current;
  }

  isNew() {
    return this.dfdvStatus === DfdvStatusEnum.New;
  }

  isFinished() {
    return this.dfdvStatus === DfdvStatusEnum.Finished;
  }

  isCanceled() {
    return this.dfdvStatus === DfdvStatusEnum.Canceled;
  }

  getUpdateRequest(): UpdateDfdvModel {
    const request: UpdateDfdvModel = new UpdateDfdvModel();
    request.dfdvId = this.dfdv.dfdvId;
    request.unitName = this.dfdv.unitName;
    request.categoryName = this.dfdv.categoryName;
    request.fantasyName = this.dfdv.fantasyName;
    request.operatorId = this.dfdv.operatorId;
    request.copyId = this.dfdv.copyId;
    request.creationDate = this.dfdv.creationDate;
    request.startDate = this.dfdv.startDate;
    request.endDate = this.dfdv.endDate;
    request.version = this.dfdv.version;
    request.previousVersionId = this.dfdv.previousVersionId;
    request.nextVersionId = this.dfdv.nextVersionId;
    request.dfdvTypeName = this.dfdv.dfdvTypeName;
    request.dfdvStatusName = this.dfdv.dfdvStatusName;
    request.rvParameter = this.dfdv.rvParameter;
    request.driverFactor = this.dfdv.driverFactor;
    request.plant = this.dfdv.plant;
    request.generalCosts = this.dfdv.generalCosts ?? new GetGeneralCostsModel();
    request.profit = this.dfdv.profit;
    request.benefit = this.dfdv.benefit;
    request.tax = this.dfdv.tax;
    request.scope = this.dfdv.scope;
    request.fleet = this.buildFleet();
    request.cvMaintenance = this.dfdv.cvMaintenance;
    request.cvConsumption = {
      tractorConsumption: this.dfdv.cvConsumption[0].tractorConsumption,
    };
    request.cvTire = this.dfdv.cvTire;
    request.charge = this.dfdv.charge;
    request.supportTeam = this.GetSupportTeamUpdate(this.dfdv.supportTeam);
    request.invoicing = this.dfdv.invoicing ?? new InvoicingModel();
    return request;
  }

  buildFleet() {
    const fleetUpdate: FleetUpdateModel[] = [];
    this.dfdv.fleet.fleetConfigurationItems.forEach((x) => {
      const update = new FleetUpdateModel();
      update.tractor.quantity = x.tractor.quantity;
      update.tractor.tractorFinancingStatus = x.tractor.tractorFinancingStatus;
      update.tractor.tractorBrand = x.tractor.tractorBrand;
      update.tractor.tractorModel = x.tractor.tractorModel;
      update.tractor.active = x.tractor.active;
      update.tractor.tractorYear = x.tractor.tractorYear;
      update.tractor.tractorAcquisitionDate = x.tractor.tractorAcquisitionDate;
      update.tractor.tractorResidual = x.tractor.tractorResidual;

      update.trailer.trailerFinancingStatus = x.trailer.trailerFinancingStatus;
      update.trailer.trailerImplement = x.trailer.trailerImplement;
      update.trailer.trailerMaterial = x.trailer.trailerMaterial;
      update.trailer.trailerType = x.trailer.trailerType;
      update.trailer.trailerYear = x.trailer.trailerYear;
      update.trailer.trailerAcquisitionDate = x.trailer.trailerAcquisitionDate;
      update.trailer.trailerResidual = x.trailer.trailerResidual;

      fleetUpdate.push(update);
    });

    return fleetUpdate;
  }

  GetSupportTeamUpdate(supportTeam: SupportTeamModel): UpdateSupportTeamModel {
    const update = new UpdateSupportTeamModel();

    if (supportTeam !== undefined) {
      Object.keys(supportTeam).forEach((team) => {
        const teamGroup = supportTeam[team];
        if (!team || team === 'total') return;
        update[team].transportTypeName = teamGroup.transportTypeName;
        Object.keys(teamGroup).forEach((person) => {
          const personGroup = teamGroup[person];
          if (personGroup) {
            Object.keys(personGroup).forEach((prop) => {
              if (
                prop === 'salaryQuantity' ||
                prop === 'salaryValue' ||
                prop === 'lightFleetQuantity'
              ) {
                update[team][person][prop] = personGroup[prop];
              }
            });
          }
        });
      });
    }
    return update;
  }

  updateFleetDfdv(): boolean {
    if (this.dfdv.dfdvId === undefined) return false;
    const { scope, cvTire, tax, profit, cvConsumption, cvMaintenance } = {
      ...this.dfdv,
    };

    const fleet = { ...this.dfdv.fleet };

    const tractorConsumption =
      cvConsumption === undefined ? 0 : cvConsumption[0].tractorConsumption;
    const fleetConfigurationItems = [...fleet.fleetConfigurationItems];

    const activeFleet = new FleetActiveModel();
    const stoppedFleet = new FleetStoppedModel();

    const {
      tireCost,
      retreadingCost,
      retreadingQuantity,
      tireLifespanKm,
      tireQuantity,
    } = cvTire;

    const unitCostTotal = tireCost + retreadingCost * retreadingQuantity;
    const subtotalWithFix =
      tireLifespanKm === 0 ? 0 : unitCostTotal / tireLifespanKm;
    const unitCostOfTiresPerKm = subtotalWithFix * tireQuantity;

    let sumTractorEstimatedMonthlyKm = 0;

    fleet.anpDieselWithArlaCost =
      scope.consumption.anpDieselCost + scope.consumption.arlaCost * 0.05;
    fleet.tractorEstimatedMonthlyKm =
      scope.equipment.lineTractorEstimatedMonthlyKm +
      scope.equipment.maneuverTractorEstimatedMonthlyKm;

    const updFleetConfiguration: FleetConfigurationItemsModel[] = [];

    fleetConfigurationItems.forEach((item) => {
      const tractor = { ...item.tractor };
      const trailer = { ...item.trailer };
      const variableCost = { ...item.variableCost };
      const fleetTax = { ...item.tax };
      const capital = { ...item.capitalRemunerationAndDepreciation };
      const fleetProfit = { ...item.profit };

      // #region Tractor
      tractor.capitalRemuneration =
        tractor.tractorFinancingStatus === 'Finame'
          ? scope.fee.capitalRemuneration
          : 0;
      tractor.tractorCost = scope.equipment.tractorCost;
      tractor.tractorTireCost =
        cvTire.tireCost * scope.equipment.tractorTireQuantity;
      tractor.tractorPisCofinsCost =
        (tractor.tractorCost * (tax.pis + tax.cofins)) / 100;
      tractor.tractorIcmsCost = tax.isCreditWithoutAssetAllowed
        ? (tractor.tractorCost * tax.icms) / 100
        : 0;
      tractor.tractorNetValueDepreciation =
        tractor.tractorCost === 0
          ? 0
          : tractor.tractorCost -
            tractor.tractorTireCost -
            tractor.tractorPisCofinsCost -
            tractor.tractorIcmsCost;
      if (tractor.tractorCost === 0) tractor.tractorNetValueRemuneration = 0;
      else {
        tractor.tractorNetValueRemuneration = tax.hasPresumedIcmsCredit
          ? tractor.tractorCost - tractor.tractorPisCofinsCost
          : tractor.tractorCost -
            tractor.tractorPisCofinsCost -
            tractor.tractorIcmsCost;
      }
      tractor.tractorUsageCycle = 12 * scope.equipment.tractorLifespan;
      // #endregion

      // #region Trailer
      trailer.trailerCost =
        trailer.trailerFinancingStatus === 'Raízen'
          ? 0
          : this.dfdv.scope.equipment.trailerCost;

      trailer.trailerTireCost =
        cvTire.tireCost * scope.equipment.trailerTireQuantity;
      trailer.trailerBaseProfit =
        trailer.trailerFinancingStatus === 'Quitado' ? 0 : trailer.trailerCost;
      trailer.trailerPisCofinsCost =
        trailer.trailerFinancingStatus === 'Quitado'
          ? 0
          : (trailer.trailerCost * (tax.pis + tax.cofins)) / 100;
      trailer.trailerIcmsCost = this.dfdv.tax.isCreditWithoutAssetAllowed
        ? (trailer.trailerCost * this.dfdv.tax.icmsWithoutComplement) / 100
        : 0;

      if (trailer.trailerCost === 0) {
        trailer.trailerNetValueDepreciation = 0;
        trailer.trailerNetValueRemuneration = 0;
      } else {
        trailer.trailerNetValueDepreciation = this.dfdv.tax
          .hasPresumedIcmsCredit
          ? trailer.trailerCost - trailer.trailerPisCofinsCost
          : trailer.trailerCost -
            trailer.trailerTireCost -
            trailer.trailerPisCofinsCost -
            trailer.trailerIcmsCost;
        trailer.trailerNetValueRemuneration = this.dfdv.tax
          .hasPresumedIcmsCredit
          ? trailer.trailerCost - trailer.trailerPisCofinsCost
          : trailer.trailerCost -
            trailer.trailerPisCofinsCost -
            trailer.trailerIcmsCost;
      }
      trailer.trailerUsageCycle = 12 * scope.equipment.trailerLifespan;
      // #endregion

      // #region Tax
      fleetTax.dpvat = scope.fee.dpvat / 12;
      fleetTax.ipvaAndLicensingFee = scope.fee.hasIpvaAndLicensingFee
        ? (322442 *
            (scope.fee.ipvaAndLicensingFee / 100) *
            (1 - scope.fee.ipvaWithDiscount / 100)) /
          12
        : (322442 * scope.fee.ipvaAndLicensingFee) / 100 / 12;
      fleetTax.autoInsurance =
        (scope.fee.mandatoryInsurance + scope.fee.crlvEmissionFee) / 12;
      fleetTax.rcfv = scope.fee.rcfv / 12;
      fleetTax.licensing = tractor.active
        ? (scope.fee.licensing / 12) * (1 + scope.equipment.jointFactor)
        : 0;
      // #endregion

      // #region Capital
      capital.tractorDepreciation =
        tractor.tractorDepreciationPeriod === 0 ||
        tractor.tractorUsageCycle === 0
          ? 0
          : (tractor.tractorNetValueDepreciation *
              (1 - tractor.tractorResidual / 100)) /
            tractor.tractorUsageCycle;
      capital.trailerDepreciation =
        trailer.trailerDepreciationPeriod === 0 ||
        trailer.trailerFinancingStatus === 'Raízen' ||
        trailer.trailerUsageCycle === 0 ||
        this.dfdv.scope.equipment.jointFactor === 0
          ? 0
          : ((trailer.trailerNetValueDepreciation *
              (1 - trailer.trailerResidual / 100)) /
              trailer.trailerUsageCycle) *
          this.dfdv.scope.equipment.jointFactor;
      
      capital.tractorCapitalRemuneration =
        -pmt(
          Math.pow((1 + tractor.capitalRemuneration / 100) , (1 / 12)) - 1,
          tractor.tractorDepreciationPeriod,
          tractor.tractorNetValueRemuneration - tractor.tractorNetValueDepreciation * tractor.tractorResidual / 100) - capital.tractorDepreciation;

      capital.trailerCapitalRemuneration =
        -pmt(
          Math.pow((1 + tractor.capitalRemuneration / 100) , (1 / 12)) - 1,
          trailer.trailerDepreciationPeriod,
          this.dfdv.scope.equipment.jointFactor *
            (trailer.trailerNetValueRemuneration -
              (trailer.trailerNetValueDepreciation * trailer.trailerResidual) /
                100)
        ) - capital.trailerDepreciation;
      capital.tractorCapitalRemunerationOnTaxes =
        tractor.tractorDepreciationPeriod === 0 ||
        this.dfdv.tax.hasPresumedIcmsCredit
          ? 0
          : ((2 +
              (tractor.tractorDepreciationPeriod / 12 - 1) *
                (1 + tractor.tractorResidual / 100)) /
              ((24 * tractor.tractorDepreciationPeriod) / 12)) *
            (tractor.capitalRemuneration / 100) *
            (tractor.tractorPisCofinsCost + tractor.tractorIcmsCost);
      capital.trailerCapitalRemunerationOnTaxes =
        tractor.tractorDepreciationPeriod === 0 ||
        this.dfdv.tax.hasPresumedIcmsCredit
          ? 0
          : ((1 + trailer.trailerResidual / 100) /
              ((24 * trailer.trailerDepreciationPeriod) / 12)) *
            (tractor.capitalRemuneration / 100) *
            trailer.trailerIcmsCost *
            this.dfdv.scope.equipment.jointFactor;
      capital.capitalRemunerationOnTaxes =
        capital.tractorCapitalRemunerationOnTaxes +
        capital.trailerCapitalRemunerationOnTaxes;
      capital.totalRemuneration =
        capital.tractorDepreciation +
        capital.trailerDepreciation +
        capital.tractorCapitalRemuneration +
        capital.trailerCapitalRemuneration +
        capital.tractorCapitalRemunerationOnTaxes +
        capital.trailerCapitalRemunerationOnTaxes;
      // #endregion

      // #region Profit

      fleetProfit.totalProfit = tractor.active
        ? (profit.profitValue / 100) *
          (tractor.tractorCost +
            trailer.trailerCost * scope.equipment.jointFactor)
        : 0;
      fleetProfit.fixedProfit =
        !tractor.active || tractor.tractorFinancingStatus === 'Quitado'
          ? 0
          : (profit.totalFixedProfit / 100) *
            (tractor.tractorCost +
              trailer.trailerCost * scope.equipment.jointFactor);
      fleetProfit.variableProfit = tractor.active
        ? fleetProfit.totalProfit - fleetProfit.fixedProfit
        : 0;
      // #endregion

      // #region Variable Cost

      variableCost.lineTractorEstimatedMonthlyKm = !tractor.active
        ? 0
        : scope.equipment.lineTractorEstimatedMonthlyKm;
      variableCost.maneuverTractorEstimatedMonthlyKm = !tractor.active
        ? 0
        : scope.equipment.maneuverTractorEstimatedMonthlyKm;
      variableCost.tractorEstimatedMonthlyKm = !tractor.active
        ? 0
        : fleet.tractorEstimatedMonthlyKm;

      variableCost.tractorConsumption =
        tractorConsumption === 0
          ? 0
          : fleet.anpDieselWithArlaCost / tractorConsumption;
      variableCost.tractorMaintenance =
        cvMaintenance.tractorOwnMaintenance === 0 ||
        cvMaintenance.tractorFactoryMaintenance === 0
          ? cvMaintenance.tractorFactoryMaintenance
          : Math.min(
              cvMaintenance.tractorOwnMaintenance,
              cvMaintenance.tractorFactoryMaintenance
            );

      variableCost.trailerMaintenance = cvMaintenance.trailerMaintenance;

      variableCost.tireUnitCostPerKm = unitCostOfTiresPerKm;
      variableCost.washingCost =
        variableCost.lineTractorEstimatedMonthlyKm === 0
          ? 0
          : (scope.consumption.washingCost * 2) /
            variableCost.lineTractorEstimatedMonthlyKm;
      variableCost.totalVariableCost = !tractor.active
        ? 0
        : variableCost.tractorConsumption +
          variableCost.tractorMaintenance +
          variableCost.trailerMaintenance +
          variableCost.tireUnitCostPerKm +
          variableCost.washingCost;

      // #endregion

      fleet.averageTireUnitCostPerKm +=
        variableCost.tireUnitCostPerKm * variableCost.tractorEstimatedMonthlyKm;
      fleet.averageTractorConsumption +=
        variableCost.tractorConsumption *
        variableCost.tractorEstimatedMonthlyKm;
      fleet.averageTractorMaintenance +=
        variableCost.tractorMaintenance *
        variableCost.tractorEstimatedMonthlyKm;
      fleet.averageTrailerMaintenance +=
        variableCost.trailerMaintenance *
        variableCost.tractorEstimatedMonthlyKm;
      fleet.averageWashingCost +=
        variableCost.washingCost * variableCost.tractorEstimatedMonthlyKm;
      sumTractorEstimatedMonthlyKm += variableCost.tractorEstimatedMonthlyKm;

      if (tractor.active) {
        activeFleet.totalActiveQuantity += tractor.quantity;
        activeFleet.averageActiveTractorCost += tractor.tractorCost;
        activeFleet.averageActiveTractorTireCost += tractor.tractorTireCost;
        activeFleet.averageActiveTractorPisCofinsCost +=
          tractor.tractorPisCofinsCost;
        activeFleet.averageActiveTractorIcmsCost += tractor.tractorIcmsCost;
        activeFleet.averageActiveTractorNetValueDepreciation +=
          tractor.tractorNetValueDepreciation;
        activeFleet.averageActiveTractorNetValueRemuneration +=
          tractor.tractorNetValueRemuneration;
        activeFleet.averageActiveTractorDepreciationPeriod +=
          tractor.tractorDepreciationPeriod;
        activeFleet.averageActiveTractorUsageCycle += tractor.tractorUsageCycle;
        activeFleet.averageActiveTractorResidual += tractor.tractorResidual;
        activeFleet.averageActiveTrailerCost += trailer.trailerCost;
        activeFleet.averageActiveTrailerTireCost += trailer.trailerTireCost;
        activeFleet.averageActiveTrailerBaseProfit += trailer.trailerBaseProfit;
        activeFleet.averageActiveTrailerPisCofinsCost +=
          trailer.trailerPisCofinsCost;
        activeFleet.averageActiveTrailerIcmsCost += trailer.trailerIcmsCost;
        activeFleet.averageActiveTrailerNetValueDepreciation +=
          trailer.trailerNetValueDepreciation;
        activeFleet.averageActiveTrailerNetValueRemuneration +=
          trailer.trailerNetValueRemuneration;
        activeFleet.averageActiveTrailerDepreciationPeriod +=
          trailer.trailerDepreciationPeriod;
        activeFleet.averageActiveTrailerUsageCycle += trailer.trailerUsageCycle;
        activeFleet.averageActiveTrailerResidual += trailer.trailerResidual;
        activeFleet.averageActiveDpvat += fleetTax.dpvat;
        activeFleet.averageActiveIpvaAndLicensingFee +=
          fleetTax.ipvaAndLicensingFee;
        activeFleet.averageActiveAutoInsurance += fleetTax.autoInsurance;
        activeFleet.averageActiveRcfv += fleetTax.rcfv;
        activeFleet.averageActiveLicensing += fleetTax.licensing;
        activeFleet.averageActiveTractorDepreciation +=
          capital.tractorDepreciation;
        activeFleet.averageActiveTrailerDepreciation +=
          capital.trailerDepreciation;
        activeFleet.averageActiveTractorCapitalRemuneration +=
          capital.tractorCapitalRemuneration;
        activeFleet.averageActiveTrailerCapitalRemuneration +=
          capital.trailerCapitalRemuneration;
        activeFleet.averageActiveTractorCapitalRemunerationOnTaxes +=
          capital.tractorCapitalRemunerationOnTaxes;
        activeFleet.averageActiveTrailerCapitalRemunerationOnTaxes +=
          capital.trailerCapitalRemunerationOnTaxes;
        activeFleet.averageActiveCapitalRemunerationOnTaxes +=
          capital.capitalRemunerationOnTaxes;
        activeFleet.averageActiveTotalRemuneration += capital.totalRemuneration;
        activeFleet.averageActiveTotalProfit += fleetProfit.totalProfit;
        activeFleet.averageActiveFixedProfit += fleetProfit.fixedProfit;
        activeFleet.averageActiveVariableProfit += fleetProfit.variableProfit;
      } else {
        stoppedFleet.totalStoppedQuantity += tractor.quantity;
        stoppedFleet.averageStoppedTractorCost += tractor.tractorCost;
        stoppedFleet.averageStoppedTractorTireCost += tractor.tractorTireCost;
        stoppedFleet.averageStoppedTractorPisCofinsCost +=
          tractor.tractorPisCofinsCost;
        stoppedFleet.averageStoppedTractorIcmsCost += tractor.tractorIcmsCost;
        stoppedFleet.averageStoppedTractorNetValueDepreciation +=
          tractor.tractorNetValueDepreciation;
        stoppedFleet.averageStoppedTractorNetValueRemuneration +=
          tractor.tractorNetValueRemuneration;
        stoppedFleet.averageStoppedTractorDepreciationPeriod +=
          tractor.tractorDepreciationPeriod;
        stoppedFleet.averageStoppedTractorUsageCycle +=
          tractor.tractorUsageCycle;
        stoppedFleet.averageStoppedTractorResidual += tractor.tractorResidual;
        stoppedFleet.averageStoppedTrailerCost += trailer.trailerCost;
        stoppedFleet.averageStoppedTrailerTireCost += trailer.trailerTireCost;
        stoppedFleet.averageStoppedTrailerBaseProfit +=
          trailer.trailerBaseProfit;
        stoppedFleet.averageStoppedTrailerPisCofinsCost +=
          trailer.trailerPisCofinsCost;
        stoppedFleet.averageStoppedTrailerIcmsCost += trailer.trailerIcmsCost;
        stoppedFleet.averageStoppedTrailerNetValueDepreciation +=
          trailer.trailerNetValueDepreciation;
        stoppedFleet.averageStoppedTrailerNetValueRemuneration +=
          trailer.trailerNetValueRemuneration;
        stoppedFleet.averageStoppedTrailerDepreciationPeriod +=
          trailer.trailerDepreciationPeriod;
        stoppedFleet.averageStoppedTrailerUsageCycle +=
          trailer.trailerUsageCycle;
        stoppedFleet.averageStoppedTrailerResidual += trailer.trailerResidual;
        stoppedFleet.averageStoppedDpvat += fleetTax.dpvat;
        stoppedFleet.averageStoppedIpvaAndLicensingFee +=
          fleetTax.ipvaAndLicensingFee;
        stoppedFleet.averageStoppedAutoInsurance += fleetTax.autoInsurance;
        stoppedFleet.averageStoppedRcfv += fleetTax.rcfv;
        stoppedFleet.averageStoppedLicensing += fleetTax.licensing;
        stoppedFleet.averageStoppedTractorDepreciation +=
          capital.tractorDepreciation;
        stoppedFleet.averageStoppedTrailerDepreciation +=
          capital.trailerDepreciation;
        stoppedFleet.averageStoppedTractorCapitalRemuneration +=
          capital.tractorCapitalRemuneration;
        stoppedFleet.averageStoppedTrailerCapitalRemuneration +=
          capital.trailerCapitalRemuneration;
        stoppedFleet.averageStoppedTractorCapitalRemunerationOnTaxes +=
          capital.tractorCapitalRemunerationOnTaxes;
        stoppedFleet.averageStoppedTrailerCapitalRemunerationOnTaxes +=
          capital.trailerCapitalRemunerationOnTaxes;
        stoppedFleet.averageStoppedCapitalRemunerationOnTaxes +=
          capital.capitalRemunerationOnTaxes;
        stoppedFleet.averageStoppedTotalRemuneration +=
          capital.totalRemuneration;
        stoppedFleet.averageStoppedTotalProfit += fleetProfit.totalProfit;
        stoppedFleet.averageStoppedFixedProfit += fleetProfit.fixedProfit;
        stoppedFleet.averageStoppedVariableProfit += fleetProfit.variableProfit;
      }

      updFleetConfiguration.push({
        capitalRemunerationAndDepreciation: capital,
        profit: fleetProfit,
        tax: fleetTax,
        tractor,
        trailer,
        variableCost,
      } as FleetConfigurationItemsModel);
    });

    fleet.fleetConfigurationItems = updFleetConfiguration;

    fleet.averageTireUnitCostPerKm =
      sumTractorEstimatedMonthlyKm === 0
        ? 0
        : fleet.averageTireUnitCostPerKm / sumTractorEstimatedMonthlyKm;
    fleet.averageTractorConsumption =
      sumTractorEstimatedMonthlyKm === 0
        ? 0
        : fleet.averageTractorConsumption / sumTractorEstimatedMonthlyKm;
    fleet.averageTractorMaintenance =
      sumTractorEstimatedMonthlyKm === 0
        ? 0
        : fleet.averageTractorMaintenance / sumTractorEstimatedMonthlyKm;
    fleet.averageTrailerMaintenance =
      sumTractorEstimatedMonthlyKm === 0
        ? 0
        : fleet.averageTrailerMaintenance / sumTractorEstimatedMonthlyKm;
    fleet.averageWashingCost =
      sumTractorEstimatedMonthlyKm === 0
        ? 0
        : fleet.averageWashingCost / sumTractorEstimatedMonthlyKm;

    Object.keys(activeFleet).forEach((key) => {
      if (key === 'totalActiveQuantity') return;
      if (activeFleet.totalActiveQuantity === 0) activeFleet[key] = 0;
      else {
        activeFleet[key] /= updFleetConfiguration.filter(
          (item) => item.tractor.active
        ).length;
      }
    });

    Object.keys(stoppedFleet).forEach((key) => {
      if (key === 'totalStoppedQuantity') return;
      if (stoppedFleet.totalStoppedQuantity === 0) stoppedFleet[key] = 0;
      else {
        stoppedFleet[key] /= updFleetConfiguration.filter(
          (item) => !item.tractor.active
        ).length;
      }
    });

    fleet.active = activeFleet;
    fleet.stopped = stoppedFleet;

    if (this.store !== undefined) {
      this.store.dispatch(
        DfdvActions.updateSubobject({
          subObjectName: 'fleet',
          data: fleet,
        })
      );
      return true;
    }
    return false;
  }

  UpdateOperationalTeam(): boolean {
    if (this.dfdv.dfdvId === undefined) return false;
    const { driverFactor, benefit, plant, rvParameter, charge } = {
      ...this.dfdv,
    };

    const updOperationalTeam = {
      standardDriver: new OperationalTeamDriver(),
      nightDriver: new OperationalTeamDriver(),
    } as OperationalTeamModel;
    // #region Standard Driver

    updOperationalTeam.standardDriver.salaryWithAgreement =
      driverFactor.driverSalary * (1 + driverFactor.collectiveAgreement / 100);

    updOperationalTeam.standardDriver.nightAdditional = 0;

    updOperationalTeam.standardDriver.toxicologicalExam =
      plant.harvestMonths !== 0
        ? (2 * driverFactor.toxicologicalExam) / plant.harvestMonths
        : 0;

    updOperationalTeam.standardDriver.hourCost =
      driverFactor.workingHours !== 0
        ? updOperationalTeam.standardDriver.salaryWithAgreement /
          driverFactor.workingHours
        : 0;

    updOperationalTeam.standardDriver.hoursOfQuantitativeItemsWithWeighting =
      (rvParameter.totalMonthlyHoursOfQuantitativeItems *
        rvParameter.ceilingRangeDistributionWeighting) /
      100;

    updOperationalTeam.standardDriver.hoursOfQualitativeItemsWithWeighting =
      (rvParameter.totalMonthlyHoursOfQualitativeItems *
        rvParameter.ceilingRangeDistributionWeighting) /
      100;

    updOperationalTeam.standardDriver.inIntinereHoursCost =
      updOperationalTeam.standardDriver.hourCost *
      1.5 *
      driverFactor.standardDriverInIntinereHours;
    updOperationalTeam.standardDriver.dsr =
      ((updOperationalTeam.standardDriver.inIntinereHoursCost +
        updOperationalTeam.standardDriver.nightAdditional) /
        24.5) *
      5.5;

    updOperationalTeam.standardDriver.paycheck =
      updOperationalTeam.standardDriver.salaryWithAgreement +
      updOperationalTeam.standardDriver.inIntinereHoursCost +
      updOperationalTeam.standardDriver.nightAdditional +
      updOperationalTeam.standardDriver.dsr;

    updOperationalTeam.standardDriver.charge =
      updOperationalTeam.standardDriver.paycheck * charge.totalCharge/100;

    updOperationalTeam.standardDriver.variableSalary =
      (updOperationalTeam.standardDriver.hoursOfQuantitativeItemsWithWeighting +
        updOperationalTeam.standardDriver
          .hoursOfQualitativeItemsWithWeighting) *
      updOperationalTeam.standardDriver.hourCost;
    updOperationalTeam.standardDriver.variableSalaryWithCharge =
      updOperationalTeam.standardDriver.variableSalary *
      (1 + charge.totalCharge / 100 - 0.04);

    updOperationalTeam.standardDriver.totalPayslip =
      updOperationalTeam.standardDriver.paycheck +
      updOperationalTeam.standardDriver.variableSalary;

    updOperationalTeam.standardDriver.totalRemuneration =
      updOperationalTeam.standardDriver.paycheck +
      updOperationalTeam.standardDriver.charge +
      updOperationalTeam.standardDriver.toxicologicalExam +
      benefit.totalStandardDriver;
    // #endregion
    // #region Night Driver

    updOperationalTeam.nightDriver.salaryWithAgreement =
      driverFactor.driverSalary * (1 + driverFactor.collectiveAgreement / 100);

    updOperationalTeam.nightDriver.toxicologicalExam =
      plant.harvestMonths !== 0
        ? (2 * driverFactor.toxicologicalExam) / plant.harvestMonths
        : 0;

    updOperationalTeam.nightDriver.hourCost =
      driverFactor.workingHours !== 0
        ? updOperationalTeam.nightDriver.salaryWithAgreement /
          driverFactor.workingHours
        : 0;

    updOperationalTeam.nightDriver.nightAdditional =
      (charge.chargeBase.nightAdditional / 100) *
      8 *
      driverFactor.workingDays *
      updOperationalTeam.nightDriver.hourCost;

    updOperationalTeam.nightDriver.hoursOfQuantitativeItemsWithWeighting =
      (rvParameter.totalMonthlyHoursOfQuantitativeItems *
        rvParameter.ceilingRangeDistributionWeighting) /
      100;

    updOperationalTeam.nightDriver.hoursOfQualitativeItemsWithWeighting =
      (rvParameter.totalMonthlyHoursOfQualitativeItems *
        rvParameter.ceilingRangeDistributionWeighting) /
      100;

    updOperationalTeam.nightDriver.inIntinereHoursCost =
      updOperationalTeam.nightDriver.hourCost *
      1.5 *
      driverFactor.nightDriverInIntinereHours;
    updOperationalTeam.nightDriver.dsr =
      ((updOperationalTeam.nightDriver.inIntinereHoursCost +
        updOperationalTeam.nightDriver.nightAdditional) /
        24.5) *
      5.5;

    updOperationalTeam.nightDriver.paycheck =
      updOperationalTeam.nightDriver.salaryWithAgreement +
      updOperationalTeam.nightDriver.inIntinereHoursCost +
      updOperationalTeam.nightDriver.nightAdditional +
      updOperationalTeam.nightDriver.dsr;

    updOperationalTeam.nightDriver.charge =
      updOperationalTeam.nightDriver.paycheck * charge.totalCharge/100;

    updOperationalTeam.nightDriver.variableSalary =
      (updOperationalTeam.nightDriver.hoursOfQuantitativeItemsWithWeighting +
        updOperationalTeam.nightDriver
          .hoursOfQualitativeItemsWithWeighting) *
      updOperationalTeam.nightDriver.hourCost;
    updOperationalTeam.nightDriver.variableSalaryWithCharge =
      updOperationalTeam.nightDriver.variableSalary *
      (1 + charge.totalCharge / 100 - 0.04);

    updOperationalTeam.nightDriver.totalPayslip =
      updOperationalTeam.nightDriver.paycheck +
      updOperationalTeam.nightDriver.variableSalary;

    updOperationalTeam.nightDriver.totalRemuneration =
      updOperationalTeam.nightDriver.paycheck +
      updOperationalTeam.nightDriver.charge +
      updOperationalTeam.nightDriver.toxicologicalExam +
      benefit.totalNightDriver;
    // #endregion

    if (this.store !== undefined) {
      this.store.dispatch(
        DfdvActions.updateSubobject({
          subObjectName: 'operationalTeam',
          data: updOperationalTeam,
        })
      );
      return true;
    }
    return false;
  }

  updateSummary() {
    if (
      this.dfdv.dfdvId === undefined ||
      !this.updateFleetDfdv() ||
      !this.UpdateOperationalTeam()
    )
      return undefined;
    const {
      scope,
      tax,
      cvMaintenance,
      driverFactor,
      benefit,
      supportTeam,
      generalCosts,
      operationalTeam,
      fleet,
    } = {
      ...this.dfdv,
    };

    if (generalCosts === undefined) return undefined;

    const updSummary = new SummaryModel();

    const {
      fixedRemunerationExpense,
      operationalTeamExpense,
      personalExpense,
      operationalExpense,
      lineVariableCostExpense,
    } = updSummary;

    fixedRemunerationExpense.insuranceAndLicensing =
      fleet.active.averageActiveLicensing +
      fleet.active.averageActiveAutoInsurance +
      fleet.active.averageActiveRcfv +
      fleet.active.averageActiveDpvat;
    fixedRemunerationExpense.tractorPlanningMaintenance =
      cvMaintenance.tractorFactoryMaintenance * cvMaintenance.kmPlanned;
    fixedRemunerationExpense.totalFixedRemunerationExpense =
      fleet.active.averageActiveTractorCapitalRemuneration +
      fleet.active.averageActiveTrailerCapitalRemuneration +
      fleet.active.averageActiveCapitalRemunerationOnTaxes +
      fleet.active.averageActiveTractorDepreciation +
      fleet.active.averageActiveTrailerDepreciation +
      fixedRemunerationExpense.tractorPlanningMaintenance +
      fixedRemunerationExpense.insuranceAndLicensing +
      fleet.active.averageActiveIpvaAndLicensingFee;

    operationalTeamExpense.administrativeUniform =
      scope.equipment.tractorQuantity === 0
        ? 0
        : ((driverFactor.standardDriverTeamFactor +
            driverFactor.nightDriverTeamFactor) *
            generalCosts.totalMonthlyCostOfOperationalUniform) /
          scope.equipment.tractorQuantity;
    operationalTeamExpense.standardDriverBenefit =
      benefit.totalStandardDriver * driverFactor.standardDriverTeamFactor;
    operationalTeamExpense.nightDriverBenefit =
      benefit.totalNightDriver * driverFactor.nightDriverTeamFactor;
    operationalTeamExpense.standardDriverPaycheckAndCharge =
      (operationalTeam.standardDriver.paycheck +
        operationalTeam.standardDriver.charge) *
      driverFactor.standardDriverTeamFactor;
    operationalTeamExpense.nightDriverPaycheckAndCharge =
      (operationalTeam.nightDriver.paycheck +
        operationalTeam.nightDriver.charge) *
      driverFactor.nightDriverTeamFactor;
    operationalTeamExpense.standardDriverToxicologicalExam =
      operationalTeam.standardDriver.toxicologicalExam *
      driverFactor.standardDriverTeamFactor;
    operationalTeamExpense.nightDriverToxicologicalExam =
      operationalTeam.nightDriver.toxicologicalExam *
      driverFactor.nightDriverTeamFactor;
    operationalTeamExpense.standardDriverVariableSalaryWithCharge =
      operationalTeam.standardDriver.variableSalaryWithCharge *
      driverFactor.standardDriverTeamFactor;
    operationalTeamExpense.nightDriverVariableSalaryWithCharge =
      operationalTeam.nightDriver.variableSalaryWithCharge *
      driverFactor.nightDriverTeamFactor;
    operationalTeamExpense.totalOperationalTeamExpense =
      operationalTeamExpense.standardDriverPaycheckAndCharge +
      operationalTeamExpense.nightDriverPaycheckAndCharge +
      operationalTeamExpense.standardDriverToxicologicalExam +
      operationalTeamExpense.nightDriverToxicologicalExam +
      operationalTeamExpense.standardDriverBenefit +
      operationalTeamExpense.nightDriverBenefit +
      operationalTeamExpense.standardDriverVariableSalaryWithCharge +
      operationalTeamExpense.nightDriverVariableSalaryWithCharge +
      operationalTeamExpense.administrativeUniform;

    personalExpense.adminAndFinancialExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.adminAndFinancial.total.totalSalaryAndChargeExpense /
          scope.equipment.tractorQuantity;
    personalExpense.hrAndSsmaExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.hrAndSsma.total.totalSalaryAndChargeExpense /
          scope.equipment.tractorQuantity;
    personalExpense.maintenanceAndWarehouseExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.maintenanceAndWarehouse.total
            .totalSalaryAndChargeExpense / scope.equipment.tractorQuantity;
    personalExpense.managementExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.management.total.totalSalaryAndChargeExpense /
          scope.equipment.tractorQuantity;
    personalExpense.operationalSupportExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.operationalSupport.total.totalSalaryAndChargeExpense /
          scope.equipment.tractorQuantity;
    personalExpense.totalBenefitExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.total.totalBenefitExpense /
          scope.equipment.tractorQuantity;
    personalExpense.totalUniformExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.total.totalUniformExpense /
          scope.equipment.tractorQuantity;
    personalExpense.totalPersonalExpense =
      personalExpense.managementExpense +
      personalExpense.operationalSupportExpense +
      personalExpense.maintenanceAndWarehouseExpense +
      personalExpense.hrAndSsmaExpense +
      personalExpense.adminAndFinancialExpense +
      personalExpense.totalUniformExpense +
      personalExpense.totalBenefitExpense;

    operationalExpense.administrativeExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : (generalCosts.administrativeCosts.officeSupplyMonthlyCost +
            generalCosts.administrativeCosts.telephoneMonthlyCost +
            generalCosts.administrativeCosts.systemMaintenanceMonthlyCost +
            generalCosts.administrativeCosts.internetProviderMonthlyCost) /
          scope.equipment.tractorQuantity;
    operationalExpense.lightFleetExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : supportTeam.total.totalLightFleetExpense /
          scope.equipment.tractorQuantity;
    operationalExpense.mobileCommunicationExpense =
      scope.equipment.tractorQuantity === 0
        ? 0
        : generalCosts.administrativeCosts.mobileCommunicationMonthlyCost /
          scope.equipment.tractorQuantity;
    operationalExpense.totalOperationalExpense =
      operationalExpense.lightFleetExpense +
      operationalExpense.mobileCommunicationExpense +
      operationalExpense.administrativeExpense;

    lineVariableCostExpense.fuelExpense =
      fleet.averageTractorConsumption *
      (1 - (tax.pis + tax.cofins + tax.iss + tax.payrollEncumbrance) / 100);
    lineVariableCostExpense.tireExpense =
      fleet.averageTireUnitCostPerKm * (1 - (tax.pis + tax.cofins) / 100);
    lineVariableCostExpense.variableCostsPerDrivenKm = parseFloat(
      this.utils
        .numberFormatter(
          fleet.averageTrailerMaintenance +
            lineVariableCostExpense.tireExpense +
            fleet.averageWashingCost +
            lineVariableCostExpense.fuelExpense,
          2,
          2
        )
        .replace(',', '.')
    );
    lineVariableCostExpense.totalLineVariableCostExpense =
      lineVariableCostExpense.variableCostsPerDrivenKm *
      scope.equipment.lineTractorEstimatedMonthlyKm;

    updSummary.totalFixedCost =
      fixedRemunerationExpense.totalFixedRemunerationExpense +
      operationalTeamExpense.totalOperationalTeamExpense +
      personalExpense.totalPersonalExpense +
      operationalExpense.totalOperationalExpense +
      fleet.active.averageActiveFixedProfit;
    updSummary.totalFixedCostWithTax =
      updSummary.totalFixedCost /
      (1 - (tax.pis + tax.cofins + tax.iss + tax.payrollEncumbrance) / 100);
    updSummary.totalVariableCost =
      lineVariableCostExpense.totalLineVariableCostExpense +
      fleet.active.averageActiveVariableProfit;
    updSummary.totalVariableCostWithTax =
      updSummary.totalVariableCost /
      (1 - (tax.pis + tax.cofins + tax.iss + tax.payrollEncumbrance) / 100);

    if (this.store !== undefined) {
      this.store.dispatch(
        DfdvActions.updateSubobject({
          subObjectName: 'summary',
          data: updSummary,
        })
      );
      return updSummary;
    }
    return undefined;
  }

  updateInvoicing() {
    if (this.updateSummary() === undefined) return false;

    if (this.isUserOpl()) {      
      if (this.store !== undefined) {
        this.store.dispatch(
          DfdvActions.updateSubobject({
            subObjectName: 'invoicing',
            data: new InvoicingModel(),
          })
        );
        return true;
      }
      return false
    }
    
    const { summary, scope, plant, fleet, invoicing } = this.dfdv;
    const updInvoicing = new InvoicingModel();

    updInvoicing.preHarvestInvoicing =
      (summary.operationalTeamExpense.totalOperationalTeamExpense *
        scope.equipment.tractorQuantity *
        plant.preHarvestDays) /
      30;
    updInvoicing.betweenHarvestInvoicing =
      12 - plant.harvestMonths === 0
        ? 0
        : (summary.fixedRemunerationExpense.totalFixedRemunerationExpense +
            fleet.active.averageActiveFixedProfit +
            ((summary.personalExpense.totalPersonalExpense +
              summary.operationalExpense.totalOperationalExpense) *
              plant.betweenHarvestNegotiation) /
              100) *
          scope.equipment.tractorQuantity *
          (12 - plant.harvestMonths);
    updInvoicing.fixedCostInvoicing =
      summary.totalFixedCost *
      scope.equipment.tractorQuantity *
      plant.harvestMonths;
    updInvoicing.variableCostInvoicing =
      plant.density === 0 || scope.equipment.lineTractorEstimatedMonthlyKm === 0
        ? 0
        : (this.dfdv.summary.totalVariableCost /
            scope.equipment.lineTractorEstimatedMonthlyKm /
            plant.density) *
          plant.estimatedAvgRadius *
          plant.volumetry *
          2;

    updInvoicing.otherCostEstimatedInvoicing =
      invoicing?.otherCostEstimatedInvoicing ?? 0;
    updInvoicing.totalInvoicing =
      updInvoicing.betweenHarvestInvoicing +
      updInvoicing.fixedCostInvoicing +
      updInvoicing.otherCostEstimatedInvoicing +
      updInvoicing.preHarvestInvoicing +
      updInvoicing.variableCostInvoicing;

    if (this.store !== undefined) {
      this.store.dispatch(
        DfdvActions.updateSubobject({
          subObjectName: 'invoicing',
          data: updInvoicing,
        })
      );
      return true;
    }
    return false;
  }
}
