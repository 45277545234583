<div class="benefit-container">
  <section>
    <app-sumarize-header currentPageTitle="Benefícios"></app-sumarize-header>
    <div class="dinamyc-table-container">
      <app-dynamic-table
        #benefit
        [headers]="['MOTORISTA NOTURNO', 'MOTORISTA PADRÃO']"
        [rows]="benefitForm"
        [columns]="columnModel"
        [rowDescriptions]="rowsDescription"
        [descriptionLabel]="'DESCRIÇÃO'"
        [showTotal]="true"
        [showAverage]="false"
        [icon]="'../../../../../assets/icons/add-primary.svg'"
        [title]="'Benefícios'"
        [paragraph]="paragraphText"
        [showInfoBox]="true"
        [accordion]="true"
        [flexStart]="true"
        [loading]="dfdvLoading"
        [skeletonSize]="6"
        (updateValue)="updateDFDVStore()"
      >
      </app-dynamic-table>
    </div>
    <app-tooltip [top]="true" class="tooltip-pcmso" *ngIf="showTooltip">
      <div class="tooltip-info">
        <div class="text">
          <label class="bold">PCMSO:</label> Programa de Controle Médico de
          Saúde Ocupacional
        </div>
      </div>
    </app-tooltip>
    <app-tooltip [top]="true" class="tooltip-ppra" *ngIf="showTooltip">
      <div class="tooltip-info">
        <div class="text">
          <label class="bold">PPRA:</label> Programa de Prevenção de Riscos
          Ambientais
        </div>
      </div>
    </app-tooltip>
    <app-tooltip [top]="true" class="tooltip-plr" *ngIf="showTooltip">
      <div class="tooltip-info">
        <div class="text">
          <label class="bold">PLR:</label> Programa de Participação nos Lucros e
          Resultados
        </div>
      </div>
    </app-tooltip>
  </section>
</div>
