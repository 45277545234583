<div class="container">
  <span class="attach-title" [ngClass]="{ disabled: disabled }">
    {{ title }}
    <small>{{ description }}</small>
  </span>
  <div class="attach" [ngClass]="{ disabled: disabled }">
    <div class="items">
      <li *ngFor="let file of selectedFiles">
        <div class="items-content">
          <span>{{ file.fileName }}</span>
          <div class="search-loading" *ngIf="file.loading"></div>
          <img
            *ngIf="!file.loading"
            src="{{
              disabled || file.uploaded
                ? '../../../assets/icons/Download-Icon.svg'
                : '../../../assets/icons/exclude-primary-600.svg'
            }}"
            (click)="
              disabled || file.uploaded
                ? downloadFile(file)
                : removeFile(file.file)
            "
          />
        </div>
      </li>
    </div>
    <div class="attach-img" [ngClass]="{ disabled: disabled }">
      <label for="attach-input">
        <img src="../../../assets/icons/attachments-common-100.svg" />
      </label>
      <input
        #fileInput
        id="attach-input"
        type="file"
        accept="{{ extensions }}"
        multiple
        (change)="onFileSelected($event)"
      />
    </div>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="closeModal()"
></app-modal>
