<app-card
  title="Especificações"
  icon="./assets/icons/graphic-common.svg"
  [accordion]="true"
>
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-line [line]="5"></app-skeleton-line>
  </div>
  <div *ngIf="!isLoading" class="card-body" body>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Quantidade Total Cavalo Mecânico</span>
      </label>
      <label class="info-value info-total">
        {{ data.totalQuantityTractor }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Quantidade Total Conjunto</span>
      </label>
      <label class="info-value info-total">
        {{ data.totalQuantityTrailer }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Fator Conjunto</span>
      </label>
      <label class="info-value info-total">
        {{ data.factorTrailer | number : "1.2-2" }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Valor Cavalo Mecânico</span>
      </label>
      <label class="info-value info-total">
        {{ data.costTractor | currency : "BRL" }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Valor Conjunto</span>
      </label>
      <label class="info-value info-total">
        {{ data.costTrailer | currency : "BRL" }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Taxa Remuneração Capital</span>
      </label>
      <label class="info-value info-total">
        {{ data.capitalRemunerationRate | number : "1.2-2" }} %
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Quantidade Pneus por Cav. Mecânico</span>
      </label>
      <label class="info-value info-total">
        {{ data.quantityTiresPerTractor }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Quantidade Pneus por Conjunto</span>
      </label>
      <label class="info-value info-total">
        {{ data.quantityTiresPerTrailer }}
      </label>
    </div>
    <div>&nbsp;</div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Vida Útil Cavalo Mecânico (em anos)</span>
      </label>
      <label class="info-value info-total">
        {{ data.lifeCycleTractor }}
      </label>
    </div>
    <div class="info">
      <label class="info-title">
        <span class="info-text">Vida Útil Conjunto (em anos)</span>
      </label>
      <label class="info-value info-total">
        {{ data.lifeCycleTrailer }}
      </label>
    </div>
  </div>
</app-card>
