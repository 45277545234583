export class CeillingRangeDistributionModel {
  [key: string] : number,
  ZeroPercent: number = 0;
  TenPercent: number = 0;
  TwentyPercent: number = 0;
  ThirtyPercent: number = 0;
  FortyPercent: number = 0;
  FiftyPercent: number = 0;
  SixtyPercent: number = 0;
  SeventyPercent: number = 0;
  EightyPercent: number = 0;
  NinetyPercent: number = 0;
  HundredPercent: number = 0;
}
