<div class="container">
  <section>
    <app-card
      [icon]="'../../../../../../assets/icons/truck-common.svg'"
      [title]="'Empresa Pims'"
      [paddingHorizontal]="false"
      [accordion]="true"
    >
      <div body class="card-container">
        <header class="header operator">
          <div class="h-cdtransp">CD TRANSP</div>
          <div class="h-description">DESCRIÇÃO</div>
          <div class="h-participation">PARTICIPAÇÃO</div>
          <div class="h-valuetobepaided">VALOR A SER PAGO</div>
        </header>
        <div *ngIf="!showSkeleton">
          <div class="table operator" *ngFor="let operator of operators">
            <span class="cdtransp">{{ operator.transporterId }}</span>
            <span class="description">{{ operator.operatorPimsName }}</span>
            <span class="participation">{{ operator.participation }}%</span>
            <div [class]="'valuetobepaided ' + (getCurrentValueTransporter(operator.participation) < 0 ? 'negative':'')">
              {{ getCurrentValueTransporter(operator.participation) | currency : "R$" }}
            </div>
          </div>
        </div>
        <div class="skeleton" body *ngIf="showSkeleton">
          <app-skeleton-line [line]="5"></app-skeleton-line>
        </div>
      </div>

    </app-card>
    <app-card
      [icon]="'../../../../../../assets/icons/solicitation-primary-600.svg'"
      [title]="'Solicitações a Enviar'"
      [paddingHorizontal]="false"
      [accordion]="true"
    >
      <div body class="card-container" *ngIf="!showSkeleton">
        <div *ngIf="fixedCostSolicitationPendings && fixedCostSolicitationPendings.length > 0">
          <header class="header solicitation">
            <app-check-box
              [bold]="true"
              (check)="checkAllPending()"
              (removeCheck)="removeAllChecksPending()"
              [value]="checkPendings"
              *ngIf="!isConcluded"
            ></app-check-box>
            <div>ID</div>
            <div>TÍTULO</div>
            <div class="valuetobepaided">VALOR A SER PAGO</div>
          </header>
          <div *ngFor="let solicitation of fixedCostSolicitationPendings">
            <div class="table solicitation">
              <app-check-box
                [bold]="true"
                (check)="onCheck(solicitation.solicitationId)"
                (removeCheck)="onRemoveCheck(solicitation.solicitationId)"
                [value]="getValue(solicitation.solicitationId)"
                *ngIf="!isConcluded"
              ></app-check-box>
              <span>{{
                util.addLeadingZeros(solicitation.solicitationId, 5)
              }}</span>
              <span class="title">{{ solicitation.title }}</span>
              <span
                [ngClass]="getColorClass(solicitation.solicitationTypeName)"
                >{{ solicitation.negotiatedValue | currency : "R$" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="skeleton" body *ngIf="showSkeleton">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
    </app-card>
    <app-card
      [icon]="'../../../../../../assets/icons/solicitation-primary-600.svg'"
      [title]="'Solicitações a Reenviar'"
      [paddingHorizontal]="false"
      [accordion]="true"
      *ngIf="fixedCostSolicitationsSents && fixedCostSolicitationsSents.length > 0"
    >
      <div body class="card-container" *ngIf="!showSkeleton">
        <header [class]="'header solicitation ' + (isConcluded ? 'concluded': '')">
          <app-check-box
            [bold]="true"
            (check)="checkAllSent()"
            (removeCheck)="removeAllChecksSent()"
            [value]="checkSents"
            *ngIf="!isConcluded"
          ></app-check-box>
          <div>ID</div>
          <div>TÍTULO</div>
          <div class="valuetobepaided">VALOR A SER PAGO</div>
        </header>
        <div *ngFor="let solicitation of fixedCostSolicitationsSents">
          <div [class]="'table solicitation ' + (isConcluded ? 'concluded': '')">
            <app-check-box
              [bold]="true"
              (check)="onCheck(solicitation.solicitationId)"
              (removeCheck)="onRemoveCheck(solicitation.solicitationId)"
              [value]="getValue(solicitation.solicitationId)"
              *ngIf="!isConcluded"
            ></app-check-box>
            <span>{{
              util.addLeadingZeros(solicitation.solicitationId, 5)
            }}</span>
            <span class="title">{{ solicitation.title }}</span>
            <span [class]="getColorClass(solicitation.solicitationTypeName)">{{
              solicitation.negotiatedValue | currency : "R$"
            }}</span>
          </div>
        </div>
      </div>
      <div class="skeleton" body *ngIf="showSkeleton">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
    </app-card>
  </section>
</div>
