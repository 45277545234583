/* eslint-disable max-len */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { FleetTaxModel } from 'src/app/services/dfdv/fleet/models/fleet-tax.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table-tax',
  templateUrl: './fleet-table-tax.component.html',
  styleUrls: ['./fleet-table-tax.component.scss'],
})
export class FleetTableTaxComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() taxList: FleetTaxModel[] = [];
  @Input() rowStatus: FleetTableRowStatusEnum[] = [];

  public taxHeader: string[] = [
    'ATIVO/PARADO',
    'DPVAT',
    'IPVA + TAXAS LICENCIAMENTO',
    'AUTO SEGURO',
    'SEGURO CONTRA TERCEIROS',
    'LICENCIAMENTO',
  ];

  public taxContent: string[][] = [];

  public taxActiveContent: string[] = [];

  public taxStopedContent: string[] = [];

  public taxRowStatus: FleetTableRowStatusEnum[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['taxList']) {
      this.buildTable();
    }
  }

  constructor(public utils: Utils) {
  }

  buildTable() {
    const columnTotalsActive = Array(this.taxHeader.length - 1).fill(0);
    const columnTotalsStopped = Array(this.taxHeader.length - 1).fill(0);

    if (!this.taxList?.length) {
      const initialValues = Array(this.taxHeader.length - 1).fill('-');
      this.taxActiveContent = initialValues;
      this.taxStopedContent = initialValues;
      return;
    }

    this.taxContent = [];
    this.taxActiveContent = [];
    this.taxStopedContent = [];
    this.taxRowStatus = [];

    this.taxRowStatus = this.rowStatus;

    this.taxList.forEach((x, index) => {
      const keys = Object.keys(x).filter((z) => z !== 'active');

      if (this.taxRowStatus[index] === FleetTableRowStatusEnum.ACTIVE) {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsActive[i] += x[key];
          }
        });
      } else {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsStopped[i] += x[key];
          }
        });
      }

      this.taxContent.push([
        (index + 1).toString(),
        this.utils.toMoney(x.dpvat.toString()),
        this.utils.toMoney(x.ipvaAndLicensingFee.toString()),
        this.utils.toMoney(x.autoInsurance.toString()),
        this.utils.toMoney(x.rcfv.toString()),
        this.utils.toMoney(x.licensing.toString()),
      ]);
    });

    const activeItemCount = this.taxRowStatus.filter((x) => x === FleetTableRowStatusEnum.ACTIVE).length;
    const columnActiveAverages = columnTotalsActive.map((total) => (activeItemCount > 0 ? total / activeItemCount : 0));
    this.taxActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.taxActiveContent.length; i++) {
      if(this.taxActiveContent[i] !== '-') {
        this.taxActiveContent[i] = this.utils.toMoney(this.taxActiveContent[i]);
      }
    }

    const stoppedItemCount = this.taxRowStatus.filter((x) => x === FleetTableRowStatusEnum.STOPED).length;
    const columnStoppedAverages = columnTotalsStopped.map((total) => (stoppedItemCount > 0 ? total / stoppedItemCount : 0));
    this.taxStopedContent = columnStoppedAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.taxStopedContent.length; i++) {
      if(this.taxStopedContent[i] !== '-') {
        this.taxStopedContent[i] = this.utils.toMoney(this.taxStopedContent[i]);
      }
    }
  }
}
