<div class="support-fleet-total-container">
  <section>
    <app-card
      title="Frota Leve"
      [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
      [accordion]="true"
    >
      <div class="body" body>
        <div class="text-description">
          <label
            ><b>Frota Equipe Operacional (1)</b> corresponde ao compilado dos
            valores de Frota Leve informados acima. Ainda, é levando em
            consideração as despesas com <b>Troca de Turno (2)</b> e
            <b>Caminhão Oficina (3)</b>.
          </label>
          <label
            >O <b>TOTAL</b> a seguir apresenta um compilado final dessas
            despesas (corresponde aos mesmos valores de Frota Leve apresentados
            nos Totalizadores).</label
          >
        </div>
        <div class="ligth-fleet tranporte-type">
          <div></div>
          <label>FROTA LEVE</label>
          <div class="select-transport-type">
            <label>Tipo de Transporte</label>

            <div class="skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="1"></app-skeleton-box>
            </div>
            <app-select
              [errorMessage]="errorMessage"
              [singleControl]="form.controls.fleetType"
              [selected]="selectedFleetTypes"
              [options]="optionFleetTypes"
              placeholder="Tipo de Transporte"
              [multi]="false"
              [disabled]="shouldDisableFields"
              *ngIf="!showSkeleton"
              (statusChanged)="UpdateStore()"
            ></app-select>
          </div>
        </div>

        <div class="table">
          <div class="header">
            <div class="description-title">
              <label class="title">DESCRIÇÃO</label>
            </div>
            <label>QUANTIDADE</label>
            <label>VALOR</label>
            <label>DESPESA</label>
          </div>
        </div>
        <div class="skeleton" body *ngIf="showSkeleton">
          <app-skeleton-line [line]="3"></app-skeleton-line>
        </div>
        <div class="table" *ngIf="!showSkeleton">
          <div class="table-body">
            <div class="description-title">
              <label class="title">Frota Equipe Operacional (1)</label>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.fleetOperationalTeam.lightFleetQuantity
              }}</label>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.fleetOperationalTeam.lightFleetValue| currency : "BRL"
              }}</label>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.fleetOperationalTeam.lightFleetExpense| currency : "BRL"
              }}</label>
            </div>
          </div>
          <div class="table-body">
            <div class="description-title">
              <label class="title">Troca de Turno (2)</label>
            </div>
            <div class="cell">
              <app-input
                placeholder="0"
                [control]="form.controls.shiftChangeQuantity"
                [mask]="eMaskType.number"
                [max]="100000"
                [min]="0"
                [disabled]="shouldDisableFields "
                (updateValue)="UpdateStore()"
              ></app-input>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.shiftChange.lightFleetValue| currency : "BRL"
              }}</label>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.shiftChange.lightFleetExpense| currency : "BRL"
              }}</label>
            </div>
          </div>
          <div class="table-body">
            <div class="description-title">
              <label class="title">Caminhão Oficina (3)</label>
            </div>
            <div class="cell">
              <app-input
                placeholder="0"
                [control]="form.controls.workshopTruckQuantity"
                [mask]="eMaskType.number"
                [max]="100000"
                [min]="0"
                [disabled]="shouldDisableFields"
                (updateValue)="UpdateStore()"
              ></app-input>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.workshopTruck.lightFleetValue| currency : "BRL"
              }}</label>
            </div>
            <div class="cell">
              <label>{{
                supportTeamFleetModel.workshopTruck.lightFleetExpense| currency : "BRL"
              }}</label>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="description-footer description-title cell"><label class="title">TOTAL</label></div>
          <div class="cell">
            <label>
              {{ supportTeamFleetModel.totalLightFleetQuantity  }}
            </label>
          </div>
          <div class="cell">
            <label>
              {{ supportTeamFleetModel.totalLightFleetValue | currency : "BRL" }}
            </label>
          </div>
          <div class="cell">
            <label>
              {{ supportTeamFleetModel.totalLightFleetExpense| currency : "BRL"  }}
            </label>
          </div>
        </div>
      </div>
    </app-card>
  </section>
</div>
