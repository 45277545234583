import { SubcategoryService } from 'src/app/services/subcategory/subcategory.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { GetSubcategoryModel } from '../../../services/subcategory/models/get-subcategory-model';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss'],
})
export class SubcategoryComponent implements OnInit {
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public enableButton = true;
  public loadingButton = false;
  public subcategories: GetSubcategoryModel[] = [];
  public isLoading: boolean = true;
  public error: boolean = false;

  constructor(
    private router: Router,
    private subcategoryService: SubcategoryService,
  ) { }

  ngOnInit(): void {
    this.getSubcategories();
  }

  getSubcategories() {
    this.subcategoryService.getSubcategories().subscribe({
      next: this.getSubcategoriesSuccess.bind(this),
      error: this.getSubcategoriesError.bind(this),
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  getSubcategoriesSuccess(res: GetSubcategoryModel[]) {
    this.subcategories = res;
  }

  getSubcategoriesError() {
    this.error = true;
    this.isLoading = false;
  }

  handleButtonClick() {
    this.router.navigate(['/admin/subcategory/new']);
  }

  reload() {
    this.isLoading = true;
    this.getSubcategories();
  }
}
