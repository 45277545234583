<div class="roundButton-container">
  <button
    class="btnRound btnItem1"
    *ngIf="type === 'primary'"
    [ngClass]="{ 'btnRound-primary': enabled, 'btnRound-primary-d': !enabled }"
  >
    <img src="{{ icon }}" />
  </button>

  <button
    class="btnRound btnItem2"
    *ngIf="type === 'secondary'"
    [ngClass]="{
      'btnRound-secondary': enabled,
      'btnRound-secondary-d': !enabled
    }"
  >
    <img src="{{ icon }}" />
  </button>

  <button
    class="btnRound btnItem3"
    *ngIf="type === 'delete'"
    [ngClass]="{ 'btnRound-delete': enabled, 'btnRound-delete-d': !enabled }"
  >
    <img src="{{ icon }}" />
  </button>
</div>
