<div class="card-container">
  <div
    *ngFor="let item of unitCategories"
    class="card-content"
    (click)="navigate(item.categoryId)"
  >
    <div class="id">
      <label>{{ item.categoryId }}</label>
    </div>
    <div class="items">
      <label class="name">{{ item.categoryName }}</label>
      <label class="opl">{{
        item.operatorName ? item.operatorName.toUpperCase() : ""
      }}</label>
      <div id="status">
        <app-status
          [color]="
            item.active ? StatusColorEnum.success : StatusColorEnum.warning
          "
          [image]="
            item.active
              ? '../../../../../../assets/icons/active.svg'
              : '../../../../../../assets/icons/Exclude.svg'
          "
          [status]="item.active ? 'Ativo' : 'Inativo'"
        >
        </app-status>
      </div>
    </div>
  </div>
</div>
