<div class="sing-in-container">
  <header>
    <app-buttons
      label="Voltar"
      icon="../../../../assets/icons/arrow-slate100.svg"
      [type]="eButtonsType.navigate"
      [enabled]="true"
      [right]="false"
      (clickButton)="onBack()"
    ></app-buttons>
  </header>
  <div class="g-container">
    <span class="g-title">
      É necessário também se cadastrar no G-Certifica.
    </span>
    <app-buttons
      label="Acessar o G-Certifica"
      icon="../../../../assets/icons/arrow-slate100.svg"
      [type]="eButtonsType.navigate"
      [enabled]="true"
      [right]="true"
      (clickButton)="onGCertifica()"
    ></app-buttons>
    <hr />
  </div>
  <div class="form-container">
    <app-input
      [control]="form.controls.cnpj"
      label="CNPJ"
      placeholder="00.000.000/0000-00"
      errorMessage="{{ cnpjErrorMessage }}"
      [type]="eInputType.text"
      [mask]="eMaskTypeEnum.cnpj"
      [flatLoading]="loadingCnpj"
      [validated]="cnpjIsValid"
      [showSideIcon]="true"
      [sideIconLabel]="'../../../assets/icons/user-common-600.svg'"
      [sideIconType]="eSideIconType.image"
    ></app-input>
    <app-input
      [control]="form.controls.password"
      label="Senha"
      placeholder="Informe sua senha"
      errorMessage="Este campo é obrigatório"
      [type]="eInputType.password"
      [passwordValidation]="validator"
      [maxlength]="18"
      (passValid)="pass($event)"
      [showSideIcon]="true"
      [sideIconLabel]="'../../../assets/icons/password-common-600.svg'"
      [sideIconType]="eSideIconType.image"
    ></app-input>
    <div [formGroup]="form" class="captcha-container">
      <re-captcha formControlName="recaptcha"></re-captcha>
    </div>
  </div>
  <div class="buttons-container">
    <app-primary-button
      label="AVANÇAR"
      icon="../../../assets/icons/arrow-right-common-100.svg"
      [color]="ButtonColorEnum.primary"
      [right]="true"
      [loading]="loading"
      [enabled]="form.valid && !disableButton"
      (click)="onSignUp()"
    ></app-primary-button>
  </div>
</div>
