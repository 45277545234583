<section>
  <header #header [ngClass]="{ grabbing: grabbing }">
    <ng-container *ngIf="tabs && tabs.length > 1">
      <h2
        *ngFor="let tab of tabs"
        [ngClass]="{ 'active-tab': tab === activeTab }"
        (click)="selectTab(tab)"
      >
        {{ tab.title }}
      </h2>
    </ng-container>
  </header>
  <main *ngIf="activeTab">
    <ng-container *ngTemplateOutlet="activeTab.content"></ng-container>
  </main>
</section>
