import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { AdminService } from 'src/app/services/admin/admin.service';
import { GetCoverImageModel } from 'src/app/services/admin/models/get-cover-image.model';
import { UpdateCoverImageModel } from 'src/app/services/admin/models/update-cover-image.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';

@Component({
  selector: 'app-cover-image',
  templateUrl: './cover-image.component.html',
  styleUrls: ['./cover-image.component.scss'],
})
export class CoverImageComponent implements OnInit {
  public form = this.formBuilder.group(
    {
      file: [[] as File[]],
    },
  );

  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public modalModel = new ModalModel();
  public image = '';
  public size: number = 2097152;
  public coverImage = new GetCoverImageModel();
  public request = new UpdateCoverImageModel();
  public isLoading: boolean = true;
  public error: boolean = false;
  public enableButton = false;
  public loadingButton = false;

  constructor(
    public formBuilder: FormBuilder,
    private adminService: AdminService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getCoverImage();
  }

  getCoverImage() {
    this.adminService.getCoverImages().subscribe({
      next: (res) => {
        this.coverImage.name = res.name;
        this.coverImage.content = res.content;
        this.image = res.content;
        this.isLoading = false;
        this.error = false;
        this.enableButton = true;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.isLoading = false;
          this.coverImage.name = '';
          this.coverImage.content = '';
          this.image = '';
          this.enableButton = true;
        } else {
          this.image = '';
          this.isLoading = false;
          this.error = true;
          this.enableButton = false;
        }
      },
    });
  }

  clickModal() {
    this.modalModel.showModal = false;

    if (this.modalModel.success) {
      this.navigateBack();
    }
  }

  navigateBack() {
    this.router.navigate(['admin/']);
  }

  showModalError() {
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Formulário inválido!';
    this.modalModel.description = 'Carrege um arquivo de imagem para prosseguir com a ação.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.loadingButton = false;
    this.enableButton = true;
  }

  save() {
    if (this.form.controls.file.value?.length === 0) {
      this.showModalError();
    } else {
      this.loadingButton = true;
      this.enableButton = false;
      this.form.controls.file.value?.forEach((el) => {
        this.request = {
          content: el,
        } as UpdateCoverImageModel;
      });
      this.adminService.updateCoverImages(this.request).subscribe({
        next: this.handleSaveSuccess.bind(this),
        error: this.handleSaveError.bind(this),
      });
    }
  }

  handleSaveSuccess(res: GetCoverImageModel) {
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Imagem de capa atualizada com sucesso!';
    this.modalModel.description = 'A partir de agora a imagem escolhida já está em vigor.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
    this.loadingButton = false;
    this.enableButton = true;
  }

  handleSaveError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível atualizar a imagem de capa!';
    this.modalModel.description = message?.Message;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.modalModel.showModal = true;
    this.loadingButton = false;
    this.enableButton = true;
  }

  reload() {
    this.isLoading = true;
    this.error = false;
    this.getCoverImage();
  }

  showFile(base64File: any) {
    this.coverImage.content = base64File;
    this.image = base64File;
  }
}
