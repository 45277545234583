<div class="operator-container">
  <section>
    <div class="preregister-list">
      <app-card
        title="Notificações de pré-cadastro"
        icon="../../../../assets/icons/preregister.svg"
        [paddingHorizontal]="false"
        [accordion]="true"
      >
        <app-preregister-operator-list
          body
          (operatorRegister)="addOperator($event)"
          [preRegisterList]="preRegisters"
          *ngIf="
            !isLoadingPreRegister &&
            preRegisters.length > 0 &&
            !errorPreRegister
          "
        ></app-preregister-operator-list>
        <div class="skeleton" body *ngIf="isLoadingPreRegister">
          <app-skeleton-line [line]="3"></app-skeleton-line>
        </div>
        <div
          body
          class="not-found"
          *ngIf="
            !isLoadingPreRegister &&
            preRegisters.length === 0 &&
            !errorPreRegister
          "
        >
          <app-not-found></app-not-found>
        </div>
        <div
          body
          class="error"
          *ngIf="
            !isLoadingPreRegister &&
            preRegisters.length === 0 &&
            errorPreRegister
          "
        >
          <app-error (reloadCallBack)="reloadPreRegisters()"></app-error>
        </div>
      </app-card>
    </div>

    <div class="register-list">
      <app-card
        title="Empresas"
        icon="../../../../assets/icons/operator-primary-600.svg"
        [paddingHorizontal]="false"
        [accordion]="true"
        body
      >
        <app-operator-list
          body
          [newOperator]="newOperator"
          [operatorList]="operators"
          *ngIf="!isLoading && operators.length > 0 && !error"
        ></app-operator-list>
        <div class="skeleton" body *ngIf="isLoading">
          <app-skeleton-line [line]="5"></app-skeleton-line>
        </div>
        <div
          body
          class="not-found"
          *ngIf="!isLoading && operators.length === 0 && !error"
        >
          <app-not-found></app-not-found>
        </div>
        <div
          body
          class="error"
          *ngIf="!isLoading && preRegisters.length === 0 && error"
        >
          <app-error (reloadCallBack)="reload()"></app-error>
        </div>
      </app-card>
    </div>
  </section>
</div>
