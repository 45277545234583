<app-modal-generic
  [imagePath]="'assets/icons/question-purple.svg'"
  [title]="title"
  [showModal]="showModal"
  (HandleOutsideClick)="close()"
>
  <div class="content">
    <span>{{ description }}</span>
    <div class="buttons">
      <app-secondary-button
        label="Cancelar"
        [enabled]="true"
        (click)="close()"
      ></app-secondary-button>
      <app-primary-button
        [label]="confirmLabel"
        [icon]="confirmIcon"
        [color]="confirmColor"
        [right]="false"
        [enabled]="true"
        (clickButton)="confirm()"
      ></app-primary-button>
    </div>
  </div>
</app-modal-generic>

