import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FleetTableRowStatusEnum } from './enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table',
  templateUrl: './fleet-table.component.html',
  styleUrls: ['./fleet-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetTableComponent implements OnInit, OnChanges {

  @Input() isLoading: boolean = false;
  @Input() hasAction: boolean = false;
  @Input() isTotalsBoth: boolean = false;
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() buttonLabel: string = '';
  @Input() buttonIcon: string = '';
  @Input() columnsHeader: string[] = [];
  @Input() rowsStatus: FleetTableRowStatusEnum[] = [];
  @Input() rowsContent: string[][] = [];
  @Input() totalActiveRow: string[] = [];
  @Input() totalStopedRow: string[] = [];
  @Input() totalBothRow: string[] = [];

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<number> = new EventEmitter();

  public fixedColumnsList: string[] = [];
  public headerList: string[] = [];
  public rowsList: string[][] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.rowsContent.length) {
      this.setFixedColumns();
      this.setScrollColumns();
    }

    this.setScrollHeader();
  }

  ngOnChanges(): void {
    if (this.rowsContent.length) {
      this.setFixedColumns();
      this.setScrollColumns();
    }

    this.setScrollHeader();
  }

  setScrollHeader() {
    this.headerList = [];
    this.headerList = this.columnsHeader.slice(1);
  }

  setFixedColumns() {
    this.fixedColumnsList = [];
    this.rowsContent.forEach((row, rowIndex) => row.slice(0,1).forEach((x) => {
      const isActive = this.rowsStatus[rowIndex] === FleetTableRowStatusEnum.ACTIVE;
      const label = `#${x} - ${isActive ? 'Ativo': 'Parado' }`;
      this.fixedColumnsList.push(label);
    }));
  }

  setScrollColumns() {
    this.rowsList = [];
    this.rowsList = this.rowsContent.map((row) => row.slice(1));
  }

  handleClick() {
    this.clickEvent.emit();
  }

  rowClick(index: number) {
    this.rowClickEvent.emit(index);
  }
}
