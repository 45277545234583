import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-unit-list-detail',
  templateUrl: './unit-list-detail.component.html',
  styleUrls: ['./unit-list-detail.component.scss'],
})
export class UnitListDetailComponent {
  @Input() unit!: GetUnitModel;

  public StatusColorEnum = StatusColorEnum;

  constructor(private router: Router) {}

  handleDataClick(id: number) {
    this.router.navigate(['/admin/unit/', id]);
  }
}
