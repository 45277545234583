<div class="general-container">
  <section>
    <header>
      <app-sumarize-header
        currentPageTitle="Despesas Gerais"
      ></app-sumarize-header>
      <div class="general-costs-card">
        <app-card
          [accordion]="true"
          [title]="'Especificações'"
          [icon]="'../../../../../assets/icons/graphic-common.svg'"
        >
          <app-skeleton-input
            *ngIf="dfdvLoading"
            class="skeleton"
            [input]="1"
            body
          ></app-skeleton-input>
          <div *ngIf="!dfdvLoading" class="card-body" body>
            <div class="info harvest-months">
              <label class="info-title"> Meses de Duração da Safra </label>
              <label class="info-value"> {{ harvestMonths }} </label>
            </div>
          </div>
        </app-card>
      </div>
    </header>
    <div class="tab-container">
      <app-tab #tabs>
        <app-tab-item title="Escritorio" #tabOffice>
          <app-office-expenses
            #office
            [isLoading]="dfdvLoading"
            [hasError]="officeError"
            [harvestMonths]="harvestMonths"
            [shouldDisableFields]="shouldDisableFields"
            (updateValue)="updateDFDVStore()"
          ></app-office-expenses>
        </app-tab-item>
        <app-tab-item title="Equipe Administrativa" #tabManagement>
          <div class="content-container">
            <app-administrative-team-uniform
              #management
              [isLoading]="dfdvLoading"
              [hasError]="managementError"
              [harvestMonths]="harvestMonths"
              [shouldDisableFields]="shouldDisableFields"
              (updateValue)="updateDFDVStore()"
            ></app-administrative-team-uniform>
          </div>
        </app-tab-item>
        <app-tab-item title="Equipe Operacional" #tabOperational>
          <div class="content-container">
            <app-operational-team-uniform
              #operational
              [isLoading]="dfdvLoading"
              [hasError]="operationalError"
              [harvestMonths]="harvestMonths"
              [shouldDisableFields]="shouldDisableFields"
              (updateValue)="updateDFDVStore()"
            ></app-operational-team-uniform>
          </div>
        </app-tab-item>
        <app-tab-item title="Equipe Manutenção e Almoxarifado" #tabMaintence>
          <app-maintence-and-warehouse-team-uniform
            #maintence
            [isLoading]="dfdvLoading"
            [hasError]="maintenceError"
            [harvestMonths]="harvestMonths"
            [shouldDisableFields]="shouldDisableFields"
            (updateValue)="updateDFDVStore()"
          ></app-maintence-and-warehouse-team-uniform>
        </app-tab-item>
      </app-tab>
    </div>
  </section>
</div>
