export class RolePermissionModel {
  date:boolean = false;
  status:boolean = false;
  opl:boolean = false;
  unity:boolean = true;
  category:boolean = true;
  subcategory:boolean = true;
  value:boolean = true;
  negotiatedValue:boolean = false;
  paymentType:boolean = true;
  title:boolean = true;
  description:boolean = true;
  attachemnt:boolean = true;
  quantity:boolean = true;
  unitaryValue:boolean = true;
  recurrencySolicitation: boolean = true;
  recurrencyMonths: boolean = true;
}
