import { OperationalTeamCalcDriverModel } from './operational-team-calc-driver.model';

export class OperationalTeamCalcModel {
  driverSalary: number = 0;
  collectiveAgreement: number = 0;
  workingHours: number = 0;
  standardDriverTeamFactor: number = 0;
  nightDriverTeamFactor: number = 0;
  tractorQuantity: number = 0;

  standardDriver: OperationalTeamCalcDriverModel = new OperationalTeamCalcDriverModel();
  nightDriver: OperationalTeamCalcDriverModel = new OperationalTeamCalcDriverModel();
}
