<app-card
  title="Grupo A - Encargos"
  icon="./assets/icons/coin-primary-600.svg"
  [accordion]="true"
>
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-input [input]="6"></app-skeleton-input>
  </div>
  <section *ngIf="!isLoading" actions class="actions">
    Total:
    <div class="total">{{ totalChargesCount | number: '1.2-2' }} %</div>
  </section>
  <div  *ngIf="!isLoading" class="fee-container" body>
    <div class="input-container">
      <app-input
        label="INSS"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.INSS"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>INSS:</b> Instituto Nacional do Seguro Social</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="FGTS"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.FGTS"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>FGTS:</b> Fundo de Garantia do Tempo de Serviço</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="SEST"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.SEST"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
        >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>SEST</b> Serviço Social do Transporte</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="SENAT"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.SENAT"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>SENAT:</b> Serviço Nacional de Aprendizagem do Transporte</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="INCRA"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.INCRA"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>INCRA:</b> Instituto Nacional de Colonização e Reforma Agrária</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="SEBRAE"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.SEBRAE"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>SEBRAE:</b> Serviço Brasileiro de Apoio às Micro e Pequenas Empresas</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="Salário Educação"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.educationSalary"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      ></app-input>
      <app-input
        label="FAP"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.FAP"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>FAP:</b> Fator Acidentário de Prevenção</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="RAT"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.RAT"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>RAT:</b> Risco de Acidente do Trabalho</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="NRAT"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="form.controls.NRAT"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="true"
        (updateValue)="handleUpdateValue()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>NRAT:</b> Novo Risco de Acidente do Trabalho</div>
          </div>
        </app-tooltip>
      </app-input>
    </div>
  </div>
</app-card>
