<div class="container">
  <div class="actions">
    <app-primary-button
      [enabled]="btnEnabled"
      [label]="'SALVAR'"
      [loading]="btnLoading"
      (clickButton)="onSave()"
    ></app-primary-button>
  </div>
  <app-card
    icon="../../../../assets/icons/categories-primary-600.svg"
    title="Categoria Vinculada"
    [accordion]="false"
  >
    <ng-container body *ngIf="isLoading">
      <app-skeleton-input [input]="4"></app-skeleton-input>
    </ng-container>
    <div class="form-container" body *ngIf="!isLoading">
        <app-toogle *ngIf="showToggle"
        label="Ativo"
        toggleId="toggleAction"
        [checked]="checked"
        (check)="handleToggleCheck()"
      ></app-toogle>
      <app-select
        field="Categoria"
        property="name"
        errorMessage="Este campo é obrigatório"
        [options]="categoriesList"
        [singleControl]="form.controls.category"
        [selected]="selectedCategory"
        [disabled]="blockCategory"
        [multi]="false"
        (statusChanged)="changeSelectedCategory($event)"
        (selectedItemsEmpty)="changeSelectedCategory($event)"
      ></app-select>
      <div class="input-box" *ngIf="form.controls.category.value">
        <app-select
          field="Empresa"
          [options]="operatorsList"
          [singleControl]="form.controls.operator"
          [selected]="selectedOperator"
          [disabled]="blockOplFields"
          property="name"
          [multi]="false"
          [id]="'Operador'"
          description="(Opcional)"
          (statusChanged)="changeSelectedOperator($event)"
          (selectedItemsEmpty)="clearTransporter()"
        ></app-select>
        <app-select
          *ngIf="isSupplyCategory"
          field="Subcategoria"
          property="name"
          errorMessage="Este campo é obrigatório"
          [options]="subcategoriesList"
          [multiControl]="form.controls.subcategory"
          [selected]="selectedSubcategories"
          [multi]="true"
          (statusChanged)="changeSelectedSubcategory($event)"
          (selectedItemsEmpty)="changeSelectedSubcategory($event)"
        ></app-select>
        <app-search
          *ngIf="isTransportCategory"
          title="Supervisor"
          placeholder="ID do supervisor"
          [item]="supervisorSearch.item"
          [loading]="supervisorSearch.loading"
          [loadingCard]="supervisorSearch.loadingCard"
          [selected]="supervisorSearch.selected"
          [control]="form.controls.supervisor"
          [showModal]="supervisorSearch.showModal"
          [searchModel]="supervisorSearch.searchModel"
          [item]="supervisorSearch.item"
          [error]="supervisorSearch.error"
          [errorMessage]="supervisorSearch.errorMessage"
          (handleClick)="handleSearchClick($event, supervisorSearch)"
          (handleCardClick)="handleCardClick(supervisorSearch)"
          (excludeClick)="excludeCard(supervisorSearch)"
          (handleOutsideClick)="handleCloseModal(supervisorSearch)"
        ></app-search>
        <app-search
          *ngIf="isSupplyCategory || isTransportCategory"
          title="Gerente de Operação"
          placeholder="ID do Gerente de Operação"
          [item]="managerSearch.item"
          [loading]="managerSearch.loading"
          [loadingCard]="managerSearch.loadingCard"
          [selected]="managerSearch.selected"
          [control]="form.controls.manager"
          [showModal]="managerSearch.showModal"
          [searchModel]="managerSearch.searchModel"
          [item]="managerSearch.item"
          [error]="managerSearch.error"
          [errorMessage]="managerSearch.errorMessage"
          (handleClick)="handleSearchClick($event, managerSearch)"
          (handleCardClick)="handleCardClick(managerSearch)"
          (excludeClick)="excludeCard(managerSearch)"
          (handleOutsideClick)="handleCloseModal(managerSearch)"
        ></app-search>
        <app-search
          *ngIf="isSupplyCategory || isTransportCategory"
          title="Diretor de Operação"
          placeholder="ID do Diretor de Operação"
          [item]="directorSearch.item"
          [loading]="directorSearch.loading"
          [loadingCard]="directorSearch.loadingCard"
          [selected]="directorSearch.selected"
          [control]="form.controls.director"
          [showModal]="directorSearch.showModal"
          [searchModel]="directorSearch.searchModel"
          [item]="directorSearch.item"
          [error]="directorSearch.error"
          [errorMessage]="directorSearch.errorMessage"
          (handleClick)="handleSearchClick($event, directorSearch)"
          (handleCardClick)="handleCardClick(directorSearch)"
          (excludeClick)="excludeCard(directorSearch)"
          (handleOutsideClick)="handleCloseModal(directorSearch)"
        ></app-search>
      </div>
    </div>
  </app-card>
</div>
<div class="operation-container">
  <app-card
    icon="../../../../assets/icons/categories-primary-600.svg"
    title="Operação Realizada"
    [accordion]="false"
    [paddingHorizontal]="false"
  >
    <div body class="operation">
      <header>
        <span>CD_TRANSP</span>
        <span>DESCRIÇÃO</span>
        <span>OPERAÇÕES</span>
      </header>
      <div
        class="notfound"
        *ngIf="operatorPims.operatorPims.length === 0 && !skeleton"
      >
        <app-not-found></app-not-found>
      </div>
      <div class="skeleton" body *ngIf="skeleton">
        <app-skeleton-box [box]="3"></app-skeleton-box>
        <app-skeleton-box [box]="3"></app-skeleton-box>
        <app-skeleton-box [box]="3"></app-skeleton-box>
      </div>
      <ng-container *ngIf="!skeleton">
        <section *ngFor="let item of operatorPims.operatorPims; let i= index">
          <span>{{item.transporterId}}</span>
          <span>{{item.transporterName}}</span>
          <app-select
            property="name"
            [options]="operationsList"
            [multiControl]="this.operationsForm[i]"
            [selected]="selectedOperations[i]"
            [disabled]="blockOplFields"
            [multi]="true"
            [showErrorMessage]="false"
            [height]="2.5"
          ></app-select>
        </section>
      </ng-container>
    </div>
    <!-- card -->
    <div body>
      <div class="card-container">
        <div
          class="notfound"
          *ngIf="operatorPims.operatorPims.length === 0 && !skeleton"
        >
          <app-not-found></app-not-found>
        </div>
        <div class="skeleton" body *ngIf="skeleton">
          <app-skeleton-box [box]="3"></app-skeleton-box>
          <app-skeleton-box [box]="3"></app-skeleton-box>
          <app-skeleton-box [box]="3"></app-skeleton-box>
        </div>
        <ng-container *ngIf="!skeleton">
          <div
            *ngFor="let item of operatorPims.operatorPims; let i= index"
            class="card-content"
          >
            <div class="id">
              <label>{{ item.transporterId }}</label>
            </div>
            <div class="items">
              <label class="name">{{ item.transporterName }}</label>
              <app-select
                property="name"
                [options]="operationsList"
                [multiControl]="this.operationsForm[i]"
                [selected]="selectedOperations[i]"
                [disabled]="blockOplFields"
                [multi]="true"
                [showErrorMessage]="false"
                [height]="2.5"
              ></app-select>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
