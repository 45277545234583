import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';
import { ListErrorModel } from 'src/app/shared/modal/models/list-error.model';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';
import { ButtonsType } from '../enums/Buttonstypes';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() buttonLabel: string = '';
  @Input() imagePath: string = '';
  @Input() showModal: boolean = false;
  @Input() listOfErrors: ListErrorModel[] = [];

  @Output() click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() clickButton : EventEmitter<boolean> = new EventEmitter();

  public eButtonsType = ButtonsType;

  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  public show() {
    this.showModal = !this.showModal;
    this.clickButton.emit();
  }

  public onClick() {
    this.click.emit();
    this.show();
  }
}
