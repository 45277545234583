import {
  Component, EventEmitter, HostListener, Input, Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { SignInModel } from '../models/signin.model';
import { InputTypeEnum } from '../../../shared/input/enums/input-type.enum';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  public form = this.formBuilder.group({
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  public eButtonsType = ButtonsType;
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  @Input() loading: boolean = false;
  @Output() signIn:EventEmitter<SignInModel> = new EventEmitter();
  @Output() goSignUp:EventEmitter<any> = new EventEmitter();
  @Output() onlostPassword:EventEmitter<any> = new EventEmitter();

  @HostListener('document:keydown', ['$event'])
  documentKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSignIn();
    }
  }

  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  constructor(public formBuilder: FormBuilder) { }

  onSignIn() {
    const result = new SignInModel();
    result.user = this.form.controls.user.value;
    result.password = this.form.controls.password.value;
    this.signIn.emit(result);
  }

  onGoSignUp() {
    this.goSignUp.emit(true);
  }

  lostPassword() {
    this.onlostPassword.emit();
  }
}
