<div class="grid-items">
  <div *ngFor="let item of operations" class="grid-content" (click)="navigate(item.operationId)">
    <label id="id">{{ util.addLeadingZeros(item.operationId, 5) }}</label>
    <label id="name">{{ item.name.toUpperCase() }}</label>
    <div id="status">
      <app-status
        [color]="item.active ?  StatusColorEnum.success : StatusColorEnum.warning"
        [image]="
          item.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="item.active ? 'Ativo' : 'Inativo'"
      >
      </app-status>
    </div>
  </div>
</div>
