import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CumulativeIncidenceModel } from 'src/app/services/dfdv/charge/models/cumalativeIncidence/cumulativeIncidence.model';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';

@Component({
  selector: 'app-cumulative-incidence',
  templateUrl: './cumulative-incidence.component.html',
  styleUrls: ['./cumulative-incidence.component.scss'],
})
export class CumulativeIncidenceComponent implements OnInit, AfterViewInit {
  @Input() isLoading: boolean = true;
  @Input() totalBaseIncidenceBaseCount: number = 0;
  @Input() totalBaseIncidenceInssCount: number = 0;
  @Input() totalBaseIncidenceFgtsCount: number = 0;
  @Input() shouldDisableFields: boolean = false;
  @Output() totalBaseIncidenceBase: EventEmitter<{ base: number, inss: number, fgts: number }> = new EventEmitter();
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Form
  public form = this.formBuilder.group({
    rows: this.formBuilder.array([this.buildRow()]),
  });

  // Mobile Control
  public isMobile: boolean = false;
  public widthBreakpoint: number = 768;

  public height: string = '2.25rem';
  public eMaskType = MaskTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;

  public header: string[] = [
    'DESCRIÇÃO',
    'ENCARGOS',
    'INSS RPS (A)',
    'FGTS RPS+IN25 (B)',
  ];
  public rowList: string[] = [
    'Férias Proporcionais Indenizadas 1º Ano',
    '1/3 Férias',
    'Aviso Prévio',
    'Ausências Legais (Aux. Doença, etc)',
    '13º Salário',
  ];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  constructor(public formBuilder: FormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.buildTable();
  }

  ngAfterViewInit() {
    this.checkDevice();
  }

  checkDevice() {
    this.isMobile = this.getWindowInnerWidth() <= this.widthBreakpoint;
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }

  buildTable() {
    Object.keys(new CumulativeIncidenceModel()).forEach(() => {
      this.form.controls.rows.push(this.buildRow());
    });
    this.form.controls.rows.removeAt(0);
  }

  buildRow() {
    return this.formBuilder.group({
      charges: [''],
      inssRps: [''],
      inssRpsIn25: [''],
    });
  }

  isValid(element: FormGroup) {
    let hasErrorCharges = false;
    let hasErrorInssRps = false;
    let hasErrorInssRpsIn25 = false;

    if (
      element.get('charges')?.errors !== null
      && element.get('charges')?.touched
    ) {
      element.get('charges')?.updateValueAndValidity();
      hasErrorCharges = true;
    }

    if (
      element.get('inssRps')?.errors !== null
      && element.get('inssRps')?.touched
    ) {
      element.get('inssRps')?.updateValueAndValidity();
      hasErrorInssRps = true;
    }

    if (
      element.get('inssRpsIn25')?.errors !== null
      && element.get('inssRpsIn25')?.touched
    ) {
      element.get('inssRpsIn25')?.updateValueAndValidity();
      hasErrorInssRpsIn25 = true;
    }

    return hasErrorCharges || hasErrorInssRps || hasErrorInssRpsIn25 ? '#DC2626' : '#475569';
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
