<div
  class="input-group"
  [ngClass]="{ error: error, disabled: disabled || loading }"
>
  <div class="label-group">
    <label class="title" *ngIf="showTitle">
      {{ label }}
      <small *ngIf="description">{{ description }}</small>
      <ng-content></ng-content>
    </label>
    <span *ngIf="!disabled && maxlength > 0 && showMaxLength">
      {{ control.value?.length + "/" + maxlength }}
    </span>
  </div>
  <div
    class="input-box"
    [ngClass]="{ search: search }"
    (keyup.enter)="handleSearchClick()"
  >
    <div class="input-wrapper">
      <div
        class="sideicon"
        *ngIf="showSideIcon"
        [ngClass]="{ disabled: disabled, focused: inputFocused }"
        (click)="handleSearchClick()"
      >
        <span
          class="sideIcon-label"
          *ngIf="sideIconType === eSideIconTypeEnum.text"
        >
          {{ sideIconLabel }}
        </span>
        <img
          *ngIf="sideIconType === eSideIconTypeEnum.image"
          src="{{ sideIconLabel }}"
        />
      </div>

      <input
        [ngStyle]="{ height: height }"
        *ngIf="mask === eMaskTypeEnum.username"
        [ngStyle]="{ height: height }"
        (focus)="focusInput()"
        (blur)="focusInput()"
        [formControl]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [type]="type"
        [mask]="selectMask()"
        [specialCharacters]="[]"
        [ngClass]="{ sideIcon: showSideIcon }"
      />
      <input
        [ngStyle]="{ height: height }"
        *ngIf="
          mask !== eMaskTypeEnum.username &&
          mask !== eMaskTypeEnum.money &&
          mask !== eMaskTypeEnum.decimal &&
          mask !== eMaskTypeEnum.number
        "
        [ngStyle]="{ height: height }"
        (focus)="focusInput()"
        (blur)="focusInput()"
        [formControl]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [type]="type"
        [mask]="selectMask()"
        [ngClass]="{ valid: validated, sideIcon: showSideIcon }"
        (ngModelChange)="onChange()"
      />
      <input
        [ngStyle]="{ height: height }"
        *ngIf="mask === eMaskTypeEnum.decimal"
        [ngStyle]="{ height: height }"
        (focus)="focusInput()"
        (blur)="focusInput()"
        [formControl]="control"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [type]="type"
        [mask]="selectMask()"
        thousandSeparator="."
        decimalMarker=","
        separatorLimit="1000000"
        [ngClass]="{ valid: validated, sideIcon: showSideIcon }"
      />
      <input
        [ngStyle]="{ height: height }"
        *ngIf="mask === eMaskTypeEnum.money"
        [ngStyle]="{ height: height }"
        (focus)="focusInput()"
        (blur)="focusInput()"
        [formControl]="control"
        [placeholder]="placeholder"
        [options]="moneyMaskConfig"
        currencyMask
        [ngClass]="{ sideIcon: showSideIcon }"
      />
      <input
        [ngStyle]="{ height: height }"
        *ngIf="mask === eMaskTypeEnum.number"
        [ngStyle]="{ height: height }"
        (focus)="focusInput()"
        (blur)="focusInput()"
        [formControl]="control"
        [placeholder]="placeholder"
        [patterns]="numberPatterns"
        [mask]="selectMask()"
        [allowNegativeNumbers]="true"
        [ngClass]="{ valid: validated, sideIcon: showSideIcon }"
      />

      <div
        class="number-actions"
        *ngIf="mask === eMaskTypeEnum.number && showNumberActions"
        [ngClass]="{ disabled: disabled }"
        [ngStyle]="{ height: height }"
      >
        <button
          class="decrement-button"
          [ngStyle]="{ width: numberActionsWidth }"
          (click)="decrement()"
        >
          -
        </button>
        <button
          class="increment-button"
          [ngStyle]="{ width: numberActionsWidth }"
          (click)="increment()"
        >
          +
        </button>
      </div>

      <div
        class="search-img"
        *ngIf="search"
        [ngClass]="{ disabled: disabled }"
        (click)="handleSearchClick()"
      >
        <div class="search-loading" *ngIf="loading"></div>
        <img
          id="search"
          *ngIf="!loading"
          src="../../../assets/icons/search.svg"
        />
      </div>

      <div class="flat-container" *ngIf="!passwordValidation">
        <div class="flat-loading" *ngIf="flatLoading"></div>
        <img
          *ngIf="!flatLoading && validated"
          class="flat-icon"
          src="../../../assets/icons/Success-green.svg"
        />
      </div>
    </div>
    <app-password-validation
      *ngIf="passwordValidation"
      [validator]="PasswordValidModel"
    ></app-password-validation>
    <div
      *ngIf="enableMessageError"
      class="error-info"
      [ngStyle]="{ visibility: error ? 'visible' : 'hidden' }"
    >
      <img src="../../../assets/icons/error-warning-600.svg" alt="Error icon" />
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</div>
