import { SearchModel } from 'src/app/shared/search/model/search.model';

export class SearchControlModel {
  public selected: boolean = false;
  public loading: boolean = false;
  public loadingCard: boolean = false;
  public item: string = '';
  public items: string[] = [];
  public showModal: boolean = false;
  public error: boolean = false;
  public errorMessage!: string;
  public searchModel: SearchModel = new SearchModel();
}
