<div class="container">
  <app-card
  [accordion]="true"
  [icon]="'../../../../assets/icons/filter-primary-600.svg'"
  [title]="'Filtros'"
  >
    <div body class="clean-button">
      <app-icon-button
            (clickButton)="clearFilterFields()"
            icon="../../../../assets/icons/clean-red.svg"
            text="Limpar"
            [enabled]="true"
            [color]="buttonColorEnum.warning"
          ></app-icon-button>
    </div>
    <div class="filter-container" body>
      <app-input
      [control]="formFilter.controls.id"
      [label]="'ID'"
      [type]="inputTypeEnum.number"></app-input>

      <app-datepicker
        [interval]="true"
        [controlStartDate]="formFilter.controls.startDate"
        [controlEndDate]="formFilter.controls.endDate"
        [monthCalendar]="true"
        [label]="'Período'"
      ></app-datepicker>

    </div>
    <div body class="button-container">
      <app-primary-button
      [label]="'FILTRAR'"
      [loading]="loading"
      [enabled]="true"
      (clickButton)="handleFilter()"
      ></app-primary-button>
    </div>
  </app-card>
</div>
