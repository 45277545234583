import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GetAttachmentModel } from 'src/app/services/solicitation/models/get-attachment-model';

@Component({
  selector: 'app-modal-confirmation-attach',
  templateUrl: './modal-confirmation-attach.component.html',
  styleUrls: ['./modal-confirmation-attach.component.scss']
})
export class ModalConfirmationAttachComponent {

  @Input() isAnalyst: boolean = false;
  @Input() attachLabel: string = '';
  @Input() attachDescription: string = '';
  @Input() attachExtensions: string = '';
  @Input() attachTotalSize: number = 0;
  @Input() form = new FormGroup({
    attachments: new FormControl([] as File[], Validators.required),
  });
  @Input() showModal: boolean = false;
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() confirmLabel: string = '';
  @Input() confirmIcon: string = '../../../assets/icons/approve-success.svg';
  @Input() confirmColor: ButtonColorEnum = ButtonColorEnum.common;

  public loadingAttach: boolean = false;
  public selectedFiles: GetAttachmentModel[] = [];

  @Output() cancelFunc = new EventEmitter();
  @Output() confirmFunc = new EventEmitter();

  public close() {
    this.cancelFunc.emit();
    this.showModal = !this.showModal;
  }

  public confirm() {
    this.confirmFunc.emit();
    this.close();
  }

}
