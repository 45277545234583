import { Component, Input } from '@angular/core';
import { FleetSpecificationModel } from './models/fleet-specifications.model';

@Component({
  selector: 'app-fleet-specifications',
  templateUrl: './fleet-specifications.component.html',
  styleUrls: ['./fleet-specifications.component.scss'],
})
export class FleetSpecificationsComponent {
  @Input() isLoading: boolean = false;
  @Input() data!: FleetSpecificationModel;

  constructor() {}


}
