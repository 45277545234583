<div class="card-container">
  <app-card
    title="Procurar Operações PIMS"
    icon="../../../assets/icons/solicitation-primary-600.svg"
  >
    <div class="container" body>
      <div class="header">
        <div class="input-tooltip-container">
          <app-search
            title="Operação PIMS"
            placeholder="Cód. Operação ou descrição"
            [items]="operationSearch.items"
            [loading]="operationSearch.loading"
            [loadingCard]="operationSearch.loadingCard"
            [selected]="operationSearch.selected"
            [showModal]="operationSearch.showModal"
            [searchModel]="operationSearch.searchModel"
            [error]="operationSearch.error"
            [errorMessage]="operationSearch.errorMessage"
            (handleClick)="handleSearchClick($event, operationSearch)"
            (handleCardClick)="excludeCard(operationSearch)"
            (excludeClick)="excludeCard(operationSearch)"
          >
          </app-search>
          <app-tooltip [bottom]="true">
            <div class="tooltip-info">
              <div class="text">
                Este filtro precisa conter pelo menos 3 caracteres, e no caso do
                (s) Código(s) da Operação, preencher com zeros(0) à esquerda, se
                necessário.É possível informar mais de um Código da Operação ao
                mesmo tempo, separados por vírgula
              </div>
            </div>
          </app-tooltip>
        </div>
        <app-buttons
          label="SALVAR"
          [color]="eButtonsColor.primary"
          [type]="eButtonsType.primary"
          [loading]="loadingButton"
          [enabled]="enableButton"
          (clickButton)="save()"
        ></app-buttons>
      </div>
      <div class="skeleton" body *ngIf="isLoadingOperation">
        <app-skeleton-line [line]="4"></app-skeleton-line>
      </div>
      <div *ngIf="operations.length > 0">
        <div class="table">
          <div class="header-table">
            <app-check-box
              (check)="checkAll()"
              (removeCheck)="removeAllChecks()"
            ></app-check-box>
            <label>CD OPERAÇÃO</label>
            <label>DESCRIÇÃO</label>
          </div>
          <div class="body-table">
            <div class="info-table" *ngFor="let item of operations">
              <app-check-box
                [value]="item.active"
                (check)="onCheck(item)"
                (removeCheck)="onRemoveCheck(item)"
              ></app-check-box>
              <label class="id">{{ item.operationId }}</label>
              <label class="name">{{ item.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="hasNotFound">
        <app-not-found></app-not-found>
      </div>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
