<div class="login-container">
  <div class="app-container">
    <app-card
      [title]="
        lostPassword ? titleLostPass : !toggleScreen ? titleSignIn : titleSignUp
      "
      icon="../../../assets/icons/cash-primary-600.svg"
      [image]="image"
      [accordion]="false"
      [isLoading]="isLoading"
      [emphasis]="true"
    >
      <section body class="body">
        <div [@active]="!toggleScreen ? 'final' : 'initial'">
          <app-sign-in
            *ngIf="!toggleScreen && !lostPassword"
            [loading]="sending"
            (signIn)="onSignIn($event)"
            (goSignUp)="onGoSignUp()"
            (onlostPassword)="onLostPassword()"
          ></app-sign-in>
        </div>
        <div [@active]="toggleScreen ? 'final' : 'initial'">
          <app-sign-up
            *ngIf="toggleScreen && !lostPassword"
            [loading]="sending"
            (back)="onBack()"
            (gCertifica)="onGCertifica()"
            (signUp)="onSignUp($event)"
          ></app-sign-up>
        </div>
        <app-lost-password *ngIf="lostPassword"> </app-lost-password>
      </section>
    </app-card>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="closeModal()"
></app-modal>
