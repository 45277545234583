<div class="dfdv-actions">
  <div class="skeleton" *ngIf="isLoading">
    <app-skeleton-box [box]="6"></app-skeleton-box>
  </div>
  <div *ngIf="!isLoading" class="dfdv-actions__buttons">
    <app-primary-button
      *ngIf="enableSendToBID"
      label="Enviar para BID"
      icon="assets/icons/arrow-next-common100.svg"
      [right]="true"
      [color]="ButtonColorEnum.success"
      [loading]="isSendingToBID"
      [enabled]="(!isLoading || isSendingToBID) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Send_to_BID)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableApproveBID"
      label="Aprovar BID"
      icon="assets/icons/approve-common-100.svg"
      [color]="ButtonColorEnum.success"
      [loading]="isApprovingBID"
      [enabled]="(!isLoading || isApprovingBID) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Approve_BID)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableReproveBID"
      label="Reprovar BID"
      icon="assets/icons/reprove-common-100.svg"
      [color]="ButtonColorEnum.warning"
      [loading]="isReprovingBID"
      [enabled]="(!isLoading || isReprovingBID) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Reprove_BID)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableEffectPending"
      label="Efetivar"
      icon="assets/icons/effect-common-100.svg"
      [color]="ButtonColorEnum.info"
      [loading]="isEffectingPending"
      [enabled]="(!isLoading || isEffectingPending) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Effect_Pending)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableEffectNew"
      label="Efetivar"
      icon="assets/icons/effect-common-100.svg"
      [color]="ButtonColorEnum.info"
      [loading]="isEffectingNew"
      [enabled]="(!isLoading || isEffectingNew) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Effect_New)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableDelete"
      label="Deletar"
      icon="assets/icons/trash-slate100.svg"
      [color]="ButtonColorEnum.warning"
      [right]="false"
      [loading]="isDeleting"
      [enabled]="(!isLoading || isDeleting) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Delete)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableCancel"
      label="Cancelar"
      icon="assets/icons/cancel-common-100.svg"
      [color]="ButtonColorEnum.warning"
      [right]="true"
      [loading]="isCancelling"
      [enabled]="(!isLoading || isCancelling) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Cancel)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableNewVersion"
      label="Nova Versão"
      icon="assets/icons/add-common-100.svg"
      [color]="ButtonColorEnum.primary"
      [right]="false"
      [loading]="isNewVersion"
      [enabled]="(!isLoading || isNewVersion) && !errorMessage"
      (click)="createNewVersion()"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableShowNewVersion"
      label="Exibir Nova Versão"
      [color]="ButtonColorEnum.primary"
      [right]="true"
      [loading]="isShowNewVersion"
      [enabled]="(!isLoading || isShowNewVersion) && !errorMessage"
      (click)="showNewVersion()"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableFinalize"
      label="Finalizar"
      icon="assets/icons/check-circle-primary-600.svg"
      [color]="ButtonColorEnum.success"
      [right]="true"
      [loading]="isFinalizing"
      [enabled]="(!isLoading || isFinalizing) && !errorMessage"
      (click)="handleShowConfirmation(ModalActionTypeEnum.Finalize)"
    ></app-primary-button>
    <app-primary-button
      *ngIf="enableSave"
      label="Salvar"
      [color]="ButtonColorEnum.primary"
      [loading]="isSaving || isLoadingEQ"
      [enabled]="(!isLoading || isSaving || !isLoadingEQ) && !errorMessage"
      (click)="handleSave()"
    ></app-primary-button>
  </div>
</div>

<app-modal-confirmation
  [showModal]="modalConfirmationModel.showModal"
  [title]="modalConfirmationModel.title"
  [description]="modalConfirmationModel.description"
  [confirmLabel]="modalConfirmationModel.confirmLabel"
  [confirmIcon]="modalConfirmationModel.confirmIcon"
  [confirmColor]="modalConfirmationModel.confirmColor"
  (confirmFunc)="handleConfirmModal()"
  (cancelFunc)="handleCancelModal()"
></app-modal-confirmation>

<app-modal-confirmation-attach
  [showModal]="modalConfirmationAttachModel.showModal"
  [form]="formModalAttach"
  [isAnalyst]="isUserRaizen"
  [title]="modalConfirmationAttachModel.title"
  [attachLabel]="modalConfirmationAttachModel.attachLabel"
  [attachDescription]="modalConfirmationAttachModel.attachDescription"
  [attachExtensions]="modalConfirmationAttachModel.attachExtensions"
  [attachTotalSize]="modalConfirmationAttachModel.attachTotalSize"
  [description]="modalConfirmationAttachModel.description"
  [confirmLabel]="modalConfirmationAttachModel.confirmLabel"
  [confirmIcon]="modalConfirmationAttachModel.confirmIcon"
  [confirmColor]="modalConfirmationAttachModel.confirmColor"
  (confirmFunc)="handleConfirmModal()"
  (cancelFunc)="handleCancelModal()"
></app-modal-confirmation-attach>

<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  [listOfErrors]="dfdvErrorObject"
  (clickButton)="handleCloseModal()"
></app-modal>
