<button
  [ngClass]="{
    disabled: !enabled,
    right: right,
    common: color === 'common',
    primary: color === 'primary',
    success: color === 'success',
    attention: color === 'attention',
    warning: color === 'warning',
    info: color === 'info'
  }"
  [disabled]="!enabled || loading"
  (click)="handleClick($event)"
>
<div *ngIf="loading" class="btn-loading"></div>
<img *ngIf="!loading && !progress &&icon " [src]="icon" />
<span *ngIf="!loading && !progress">{{ label }}</span>
<span *ngIf="progress && showProgressValue">{{ progressValue }} %</span>
<div  *ngIf="progress" class="progressBar" [style.width.%]="progressValue"></div>
</button>




