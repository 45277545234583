/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/prefer-selector-in-select */
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FixedCostService } from 'src/app/services/fixedCosts/fixed-costs.service';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';
import { GetFixedCostFilterModel } from 'src/app/services/fixedCosts/models/get-fixedCostFilter.model';
import { FixedCostModel } from 'src/app/services/fixedCosts/models/get-fixedCosts.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PagedFixedCostModel } from 'src/app/services/fixedCosts/models/PagedFixedCost.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { Router } from '@angular/router';
import {
  IErrorState,
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FixedCostsListComponent } from './fixed-costs-list/fixed-costs-list.component';
import { FixedCostsFilterComponent } from './fixed-costs-filter/fixed-costs-filter.component';
import { ModalModel } from '../pre-registration/models/modal.model';
import * as FixcedCostActions from '../../common/store/fixedCost/fixedCost.actions';

@Component({
  selector: 'app-fixed-costs',
  templateUrl: './fixed-costs.component.html',
  styleUrls: ['./fixed-costs.component.scss'],
})
export class FixedCostsComponent implements AfterViewInit, OnDestroy {
  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }
  public fixedCosts!: FixedCostModel[];
  public modalModel = new ModalModel();
  private destroy$ = new Subject<void>();
  public fixedCostLoading: boolean = false;
  public error!: any | null;

  @ViewChild('filter') filterComponent!: FixedCostsFilterComponent;
  @ViewChild('list') listComponent!: FixedCostsListComponent;

  constructor(
    public service: FixedCostService,
    private router: Router,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
      errorFixedCost: IErrorState;
    }>
  ) {}

  fixedCostStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost));
  loadingStore$ = this.store.select('loadingFixedCost').pipe((x) => x);
  errorStore$ = this.store.select('errorFixedCost').pipe((x) => x);

  ngAfterViewInit(): void {
    this.errorStore$.pipe(takeUntil(this.destroy$)).subscribe((error) => {
      if (error.errorMessage !== null) {
        this.error = error.errorMessage;
        this.ShowErrorModal(this.error);
      }
    });
    this.getFixedCosts();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFixedCosts(filter?: GetFixedCostFilterModel) {
    this.listComponent.showSkeleton = true;
    this.service
      .getAllFixedCost(filter ?? new GetFixedCostFilterModel())
      .subscribe({
        next: this.getFixedCostsSuccess.bind(this),
        error: this.getFixedCostsError.bind(this),
      });
  }

  getFixedCostsSuccess(pagedFixedCost: PagedFixedCostModel) {
    this.listComponent.showSkeleton = false;
    this.filterComponent.loading = false;
    this.listComponent.showNotFound = false;
    this.fixedCosts = pagedFixedCost.items;

    if (this.fixedCosts.length === 0) {
      this.listComponent.showNotFound = true;
    }
  }

  getFixedCostsError(res: HttpErrorResponse) {
    this.filterComponent.loading = false;
    this.listComponent.showSkeleton = false;
    this.listComponent.showNotFound = true;
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível realizar a atualização!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  clickModal() {
    this.modalModel.showModal = false;
  }
  CreateFixedCost() {
    this.fixedCostLoading = true;
    this.loadingStore$.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.fixedCostLoading = isLoading.loading;
    });
    this.service.CreateFixedCost().subscribe({
      next: this.handleSuccessCreateSuccess.bind(this),
      error: this.handleSuccessCreateError.bind(this),
    });
  }

  handleSuccessCreateError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível criar novo lançamento!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  handleSuccessCreateSuccess(fixedCost: FixedCostsModel) {
    this.store.dispatch(FixcedCostActions.clearFixedCost());
    const id = fixedCost.fixedCostId;
    if (!this.fixedCostLoading) {
      this.fixedCostStore$
        .pipe(takeUntil(this.destroy$))
        .subscribe((fixedCostContent) => {
          if (!fixedCostContent) {
            this.store.dispatch(
              FixcedCostActions.loadFixedCostsModel({
                id,
              })
            );
          } else {
            this.fixedCostLoading = false;
            this.router.navigate([`/fixed-costs/${fixedCost.fixedCostId}`]);
          }
        });
    }
  }

  ShowErrorModal(res: any) {
    this.filterComponent.loading = false;
    this.listComponent.showSkeleton = false;
    this.listComponent.showNotFound = true;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = res?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível realizar a atualização!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.store.dispatch(FixcedCostActions.setError({ errorMessage: null }));
  }
}
