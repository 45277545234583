import {
  ChangeDetectorRef,
  Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { Subscription, debounceTime, tap } from 'rxjs';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { cnpjValidation } from './sign-up-utils/cnpjValidator';
import { SignUpModel } from '../models/signup.model';
import { MaskTypeEnum } from '../../../shared/input/enums/mask-type.enum';
import { InputTypeEnum } from '../../../shared/input/enums/input-type.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public form = this.formBuilder.group({
    cnpj: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(10)]],
    recaptcha: ['', Validators.required],
  });

  // Enum
  public eButtonsType = ButtonsType;
  public eInputType = InputTypeEnum;
  public eMaskTypeEnum = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Aux
  public passIsValid : boolean = false;
  public validator : boolean = false;
  public captchaSuccess: boolean = false;
  public canCheckCnpj:boolean = true;
  public loadingCnpj:boolean = false;
  public disableButton:boolean = true;
  public cnpjIsValid: boolean = false;
  public sub!: Subscription;

  // Errors
  public cnpjErrorMessage: string = 'Este campo é obrigatório';

  @Input() loading: boolean = false;
  @Output() back:EventEmitter<any> = new EventEmitter();
  @Output() gCertifica:EventEmitter<any> = new EventEmitter();
  @Output() signUp:EventEmitter<SignUpModel> = new EventEmitter();

  @HostListener('document:keydown', ['$event'])
  documentKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSignUp();
    }
  }

  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  constructor(
    public formBuilder: FormBuilder,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    public service: OperatorService,
  ) { }

  ngOnInit() {
    this.form.controls.password.valueChanges.subscribe((value) => {
      if (!this.form.controls.password.dirty) {
        this.form.controls.password.setErrors(null);
      } else {
        this.validator = (value !== null);
      }
    });

    this.form.controls.cnpj.valueChanges.pipe(
      tap(() => {
        this.cnpjIsValid = false;
        this.disableButton = true;
        if (this.sub) {
          this.sub.unsubscribe();
        }
      }),
      tap((value: any) => {
        if (value?.length === 14) {
          if (!cnpjValidation(value)) {
            this.form.controls.cnpj.setErrors({ cnpjValid: false });
            this.cnpjErrorMessage = 'O CNPJ digitado é invalido';
          }
        } else this.cnpjErrorMessage = 'Este campo é obrigatorio';
      }),
      debounceTime(1500),
    )
      .subscribe((value) => {
        if (this.form.controls.cnpj.valid) {
          this.loadingCnpj = true;
          this.disableButton = true;
          this.validateCnpj(value);
        }
      });
  }

  validateCnpj(value: string) {
    this.sub = this.service.validateCnpjsPublic(value).subscribe({
      next: (result) => {
        this.loadingCnpj = false;
        if (result) {
          this.disableButton = false;
          this.cnpjIsValid = true;
          this.form.controls.cnpj.setErrors(null);
        }
      },
      error: (result) => {
        this.disableButton = true;
        this.loadingCnpj = false;
        this.cnpjIsValid = false;
        this.form.controls.cnpj.setErrors({ cnpjValid: false });
        this.cnpjErrorMessage = result.error.Message;
      },
    });
  }

  onBack() {
    this.back.emit(true);
  }

  onGCertifica() {
    this.gCertifica.emit({});
  }

  pass(event:boolean) {
    this.passIsValid = event;
  }

  onSignUp() {
    const result = new SignUpModel();
    result.cnpj = this.form.controls.cnpj.value;
    result.password = this.form.controls.password.value;
    result.passIsValid = this.passIsValid;
    result.recaptcha = this.form.controls.recaptcha.value;
    this.signUp.emit(result);
  }
}
