import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { LoggedUserModel } from 'src/app/common/models/logged-user.model';
import { RoleModel } from 'src/app/common/models/role.model';
import { StorageService } from 'src/app/common/services/storage.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { GetDfdvFilterModel } from 'src/app/services/dfdv/models/get-dfdv-filter.model';
import { GetDfdvStatusModel } from 'src/app/services/dfdv/models/get-dfdv-status.model';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { UnitService } from 'src/app/services/unit/unit.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';

@Component({
  selector: 'app-dfdv-filter',
  templateUrl: './dfdv-filter.component.html',
  styleUrls: ['./dfdv-filter.component.scss'],
})
export class DfdvFilterComponent implements OnInit {
  public form = this.formBuilder.group({
    id: [''],
    fromStartDate: [''],
    toStartDate: [''],
    fromEndDate: [''],
    toEndDate: [''],
    opls: [['']],
    units: [['']],
    category: [['']],
    status: [['']],
  });

  public opls: string[] = [];
  public units: string[] = [];
  public categories: string[] = [];
  public status: string[] = [];
  public oplsSelected: string[] = [];
  public unitsSelected: string[] = [];
  public categoriesSelected: string[] = [];
  public statusSelected: string[] = [];

  public role!: RoleModel;
  public operatorId: string = '';
  public visible: boolean = true;
  public loadedFilterFromStorage: boolean = false;

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }
  @Input() isLoadingFilter!: boolean;
  @Input() loggedUser!: LoggedUserModel;
  @Output() callbackFunc = new EventEmitter<GetDfdvFilterModel>();
  @Output() callbackFilters = new EventEmitter<GetDfdvFilterModel>();
  @Output() userRole = new EventEmitter<string>();

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private dfdvService: DfdvService,
    private operatorService: OperatorService,
    private categoryService: CategoryService,
    private unitService: UnitService,
  ) {
    this.isLoadingFilter = false;
  }

  ngOnInit(): void {
    this.getUser();
    this.getUserRole();

    if (this.role.name.includes('Admin') || this.role.name.includes('Analista')) {
      this.loadData();
    } else {
      this.loadDataForOperator();
      this.visible = !this.visible;
    }

    this.callbackFilters.emit(new GetDfdvFilterModel());
  }

  clearFilterFields() {
    this.form.reset();
    this.form.patchValue({
      id: '',
      fromStartDate: '',
      toStartDate: '',
      fromEndDate: '',
      toEndDate: '',
      opls: [],
      units: [],
      category: [],
      status: [],
    });

    this.oplsSelected = [];
    this.unitsSelected = [];
    this.categoriesSelected = [];
    this.statusSelected = [];
  }

  submit() {
    if (this.callbackFunc) {
      const filters = {
        dfdvId: this.form.controls.id.value ?? '',
        fromStartDate: this.form.controls.fromStartDate.value ?? '',
        toStartDate: this.form.controls.toStartDate.value ?? '',
        fromEndDate: this.form.controls.fromEndDate.value ?? '',
        toEndDate: this.form.controls.toEndDate.value ?? '',
        category: this.form.controls.category.value ?? [],
        operator: this.form.controls.opls.value || [],
        status: this.form.controls.status.value ?? [],
        unit: this.form.controls.units.value ?? [],
        pageNumber: 1,
        pageSize: 10,
      } as GetDfdvFilterModel;

      this.callbackFunc.emit(filters);
    }
  }

  getUser() {
    const username = this.storageService.getUser();
    this.operatorId = username;
  }

  getUserRole() {
    this.role = this.storageService.getRole();
    this.userRole.emit(this.role.name);
  }

  loadData() {
    forkJoin({
      opls: this.operatorService.getActiveOperators(),
      units: this.unitService.getActiveUnits(),
      categories: this.categoryService.getActiveCategories(),
      status: this.dfdvService.getDfdvStatus(),
    }).subscribe({
      next: ({
        opls, units, categories, status,
      }) => {
        this.handleGetActiveOperatorsSuccess(opls);
        this.handleGetActiveUnitsSuccess(units);
        this.handleGetActiveCategoriesSuccess(categories);
        this.handleGetActiveStatusSucess(status);
      },
      error: () => {
        this.opls = [];
        this.oplsSelected = [];
        this.units = [];
        this.unitsSelected = [];
        this.categories = [];
        this.categoriesSelected = [];
        this.status = [];
        this.statusSelected = [];
        this.isLoadingFilter = false;
      },
      complete: () => {
        this.isLoadingFilter = false;
      },
    });
  }

  loadDataForOperator() {
    forkJoin({
      units: this.unitService.getUnitsByOpl(this.operatorId),
      status: this.dfdvService.getDfdvStatus(),
    }).subscribe({
      next: ({ units, status }) => {
        this.handleGetUnitsByOplSuccess(units);
        this.handleGetActiveStatusSucess(status);
      },
      error: () => {
        this.units = [];
        this.unitsSelected = [];
        this.categories = [];
        this.categoriesSelected = [];
        this.status = [];
        this.statusSelected = [];
        this.isLoadingFilter = false;
      },
      complete: () => {
        this.isLoadingFilter = false;
      },
    });
  }

  handleGetActiveOperatorsSuccess(res: GetOperatorModel[]) {
    this.opls = res.map((x) => x.fantasyName);
  }

  handleGetUnitsByOplSuccess(res: GetUnitModel[]) {
    this.units = res.map((x) => x.name);
    this.categories = res
      .map((x) => x.categories.map((y) => y.categoryName))
      .flat()
      .filter((value, index, array) => array.indexOf(value) === index);
  }

  handleGetActiveUnitsSuccess(res: GetUnitModel[]) {
    this.units = res.map((x) => x.name);
  }

  handleGetActiveCategoriesSuccess(res: GetCategoryModel[]) {
    this.categories = res.map((x) => x.name);
  }

  handleGetActiveStatusSucess(status: GetDfdvStatusModel[]) {
    this.status = status.map((x) => x.name);
  }
}
