import { GetAttachmentModel } from './get-attachment-model';

export class GetSolicitationModel {
  solicitationId!: number;
  operatorId!: string;
  fantasyName!: string;
  unitName!: string;
  solicitationStatusName!: string;
  categoryName!: string;
  categoryCostCenter!: string;
  subcategoryName!: string;
  paymentTypeName!: string;
  openingDate!: Date;
  completionDate!: Date;
  value!: number;
  negotiatedValue!: number;
  title!: string;
  description!: string;
  attachments!: GetAttachmentModel[];
  solicitationTypeName: string = '';
  equipmentQuantity: number = 0;
  equipmentUnitaryValue: number = 0;
  createByAnalyst?:string;
  createByOperator?:string;
  recurrenceSolicitation!: boolean;
  recurrenceMonths!: number;
}
