<div class="container">
  <section>
    <app-sumarize-header currentPageTitle="Tabela"></app-sumarize-header>
    <app-card
      title="Especificações"
      icon="assets/icons/graphic-common.svg"
      [accordion]="true"
    >
      <div *ngIf="isLoading" body>
        <app-skeleton-input [input]="2"></app-skeleton-input>
      </div>
      <div *ngIf="!isLoading" class="specification" body>
        <label>
          <strong>KM Mensal Estimado</strong>
          <span>{{ lineTractorEstimatedMonthlyKm | number }}</span>
        </label>
        <label>
          <strong>Densidade</strong>
          <span>{{ density | number : "1.2-2" }}</span>
        </label>
      </div>
    </app-card>
    <app-card
      title="Precificação por KM"
      icon="assets/icons/money-common.svg"
      [accordion]="true"
      [paddingHorizontal]="false"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <div *ngIf="!isLoading" class="pricing" body>
        <div class="column" *ngFor="let item of [1, 2]; let i = index">
          <header>
            <strong>DISTÂNCIA (KM)</strong>
            <strong>DM (R$/TON)</strong>
            <strong>FM (R$/TON)</strong>
          </header>
          <div *ngFor="let item of getColumnTable(i)">
            <strong>{{ item?.kmDistance }}</strong>
            <span>{{ item?.dmShippingCost | currency : "BRL" }}</span>
            <span>{{ item?.fmShippingCost | currency : "BRL" }}</span>
          </div>
        </div>
      </div>
    </app-card>
  </section>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="closeModal()"
></app-modal>
