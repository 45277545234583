import { Component, Input } from '@angular/core';
import { GetSubcategoryModel } from 'src/app/services/subcategory/models/get-subcategory-model';

@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.scss'],
})
export class SubcategoryListComponent {
  @Input() subcategories: GetSubcategoryModel[] = [];
}
