<div class="operator-pims-container">
  <div class="card-container">
    <app-card
      title="Cadastros no PIMS"
      icon="../../../../assets/icons/solicitation-primary-600.svg"
      [accordion]="true"
      [paddingHorizontal]="false"
    >
      <section actions class="actions">
        <div class="buttons">
          <app-icon-button
            icon="../../../../assets/icons/sync-primary.svg"
            text="Sincronizar"
            [enabled]="true"
            [color]="ButtonColorEnum.primary"
          ></app-icon-button>
          <app-icon-button
            (clickButton)="addOperatorPims()"
            icon="../../../../assets/icons/add-primary.svg"
            text="Adicionar"
            [enabled]="true"
            [color]="ButtonColorEnum.primary"
          ></app-icon-button>
        </div>
      </section>
      <section body class="body">
        <div class="list-container">
          <header
            class="header-content"
            *ngIf="this.companiesPims.operatorPims.length > 0"
          >
            <label id="hcdtransp">CD TRANSP</label>
            <label id="hcnpj">CNPJ</label>
            <label id="hdescription">DESCRIÇÃO</label>
            <label id="hstatus">STATUS</label>
          </header>
          <div class="list-content">
            <app-operator-pims-detail
              [companiesPims]="companiesPims"
              class="detail"
            ></app-operator-pims-detail>
            <app-operator-pims-card
              [companiesPims]="companiesPims"
              class="card"
            ></app-operator-pims-card>
          </div>
        </div>
      </section>
    </app-card>
  </div>
</div>
