<article [ngClass]="{ emphasis: emphasis, overflow: overflow }">
  <aside
    *ngIf="!accordion && (image || isLoading)"
    [ngStyle]="{ 'background-image': !isLoading ? 'url(' + image + ')' : '' }"
  >
    <div *ngIf="isLoading" class="skeleton"></div>
    <img src="../../../assets/images/raizen-common-100.svg" alt="Raizen" />
  </aside>
  <main>
    <header>
      <section class="title">
        <div>
          <img [src]="icon" alt="Card icon" />
        </div>
        <h2>{{ title }}</h2>
      </section>
      <section class="actions">
        <ng-content select="[actions]"></ng-content>
        <div *ngIf="accordion" (click)="toggleAccordion()">
          <img
            [ngClass]="{ 'rotate-animation': open }"
            src="../../../assets/icons/arrow-up-primary-600.svg"
            alt="Arrow icon"
          />
        </div>
      </section>
    </header>
    <section
      class="body"
      [ngClass]="{
        'hidden-body': !open,
        'without-horizontal-padding': !paddingHorizontal
      }"
      [@smoothCollapse]="open ? 'initial' : 'final'"
    >
      <ng-content select="[body]"></ng-content>
    </section>
  </main>
</article>
