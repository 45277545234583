import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StateUtils } from 'src/app/common/utils/state-utils';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { environment } from 'src/environments/environment';
import { ExternalService } from '../../../common/services/external.service';

@Component({
  selector: 'app-address-step',
  templateUrl: './address-step.component.html',
  styleUrls: ['./address-step.component.scss'],
})
export class AddressStepComponent implements OnInit {
  @Input() form = new FormGroup({
    cep: new FormControl(''),
    uf: new FormControl(''),
    city: new FormControl(''),
    neighborhood: new FormControl(''),
    address: new FormControl(''),
    number: new FormControl(''),
    complement: new FormControl(''),
  });

  public stateSelected: string[] = [];
  public states: string[] = [];
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eButtonsType = ButtonsType;

  public disabled: boolean = false;
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = 'Este campo é obrigatório';

  public get InfoboxTypeEnum() { return InfoboxTypeEnum; }

  constructor(
    private externalService: ExternalService,
    private stateUtils: StateUtils,
  ) { }

  ngOnInit(): void {
    this.states = this.stateUtils.getAllStatesDescriptions();
  }

  onGCertifica() {
    window.open(environment.GCertificaUrl, '_blank');
  }

  searchAddress(search: boolean) {
    const cep = this.form.controls.cep.value ?? '';

    if (!search || !cep || cep.length !== 8) return;

    this.loading = true;
    this.error = false;
    this.externalService.searchAddressByCep(cep).subscribe({
      next: (res) => {
        this.loading = false;
        if (res.erro) {
          this.errorMessage = 'CEP não encontrado';
          this.error = true;
        } else {
          this.errorMessage = '';
          this.error = false;

          this.form.controls.city.patchValue(res.localidade);
          this.form.controls.neighborhood.patchValue(res.bairro);
          this.form.controls.address.patchValue(res.logradouro);
          this.form.controls.number.patchValue('');
          this.form.controls.complement.patchValue('');

          const state = this.stateUtils.getStateDescriptionByUF(res.uf);
          if (state) {
            this.stateSelected = [state];
            this.form.controls.uf.patchValue(state);
          }
        }
      },
      error: () => {
        this.loading = false;
        this.error = true;
        this.errorMessage = 'Erro ao obter o CEP';
      },
    });
  }
}
