export class GetSolicitationFilterModel {
  solicitationId: string = '';
  startOpeningDate: string = '';
  endOpeningDate: string = '';
  startCompletionDate: string = '';
  endCompletionDate: string = '';
  fantasyName: string[] = [];
  unit: string[] = [];
  category: string[] = [];
  status: string[] = [];
  types: string[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
}
