<div
  class="Dateinput-group"
  [ngClass]="{ disabled: disabled }"
  (clickOutside)="blurInput()"
>
  <div class="Datelabel-group">
    <label>{{ label }}</label>
  </div>
  <div
    class="date-input"
    [ngClass]="{ disabled: disabled }"
    [formControl]="controlEndDate"
    [formControl]="controlStartDate"
    ngDefaultControl
    (click)="onFocus ? blurInput() : focusInput()"
  >
    <div class="date-content" *ngIf="controlStartDate.value !== '' && controlStartDate.value !== null">
      <div class="items">
        <span>{{ rangeDate }}</span>
        <img
          src="../../../assets/icons/exclude-primary-600.svg"
          (click)="resetDate()"
        />
      </div>
    </div>
    <div class="calendar-img">
      <img src="../../../assets/icons/calendar-slate300.svg" />
    </div>
  </div>
  <div
    *ngIf="onFocus"
    class="calendar-box"
    [ngStyle]="{ right: '0.25' + 'rem' }"
  >
    <div>
      <app-calendar-datepicker
        [interval]="interval"
        [monthCalendar]="monthCalendar"
        (dateInterval)="onDateInterval($event)"
      ></app-calendar-datepicker>
    </div>
  </div>
  <div *ngIf="error" class="error-info">
    <img src="../../../assets/icons/error-warning-600.svg" alt="Error icon" />
    <span>{{ errorMessage }}</span>
  </div>
</div>
