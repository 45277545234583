<app-modal-generic
  [title]="'Nova Configuração'"
  [imagePath]="'assets/icons/Gear-Icon.svg'"
  [showModal]="showModal"
  class="modal-new-config"
>
  <div class="modal-new-config__container">
    <div class="skeleton" *ngIf="isLoading">
      <app-skeleton-input [input]="6"></app-skeleton-input>
    </div>
    <div class="modal-new-config__form">
      <div class="modal-new-config__group">
        <div class="modal-new-config__group-header">
          <h4 class="modal-new-config__group-title">Cavalo Mecânico</h4>
          <app-toogle
            [label]="'Ativo'"
            [checked]="active"
            (check)="toogleCheck()"
          ></app-toogle>
        </div>
        <div class="modal-new-config__group-fields">
          <app-input
            label="Quantidade"
            placeholder="00"
            [type]="eInputType.number"
            [mask]="eInputMask.number"
            [control]="form.controls.tractorQuantity"
            [showNumberActions]="true"
            [max]="999999999999"
            [min]="0"
            [disabled]="shouldDisableFields"
          ></app-input>
          <app-select
            [field]="'Status Financiamento'"
            [singleControl]="form.controls.tractorFinancingStatus"
            [selected]="tractorFinancingStatusSelected"
            [options]="tractorFinancingStatusOptions"
            placeholder="Status Financiamento"
            [multi]="false"
            [disabled]="shouldDisableFields"
          ></app-select>
          <app-select
            [field]="'Fabricante'"
            [singleControl]="form.controls.tractorManufacturer"
            [selected]="manufacturerSelected"
            [options]="manufacturerOptions"
            placeholder="Fabricante"
            [multi]="false"
            [disabled]="shouldDisableFields"
            (statusChanged)="setModel()"
          ></app-select>
          <app-select
            [field]="'Modelo'"
            [singleControl]="form.controls.tractorModel"
            [selected]="modelSelected"
            [options]="modelOptions"
            placeholder="Modelo"
            [multi]="false"
            [disabled]="true"
          ></app-select>
          <app-input
            label="Ano Cavalo Mecânico"
            placeholder="0000"
            [type]="eInputType.number"
            [mask]="eInputMask.number"
            [control]="form.controls.tractorYear"
            [showNumberActions]="true"
            [maxlength]="4"
            [max]="9999"
            [min]="0"
            [disabled]="shouldDisableFields"
          ></app-input>
          <app-datepicker
            [interval]="false"
            [controlStartDate]="form.controls.tractorStartDate"
            [monthCalendar]="true"
            [label]="'*Mês/Ano Entrada'"
            [disabled]="shouldDisableFields"
            [errorMessage]="'Selecione uma data'"
            [error]="!form.controls.tractorStartDate.value"
          ></app-datepicker>
          <app-input
            label="Residual Cavalo Mecânico"
            placeholder="0,00"
            [type]="eInputType.number"
            [mask]="eInputMask.money"
            [control]="form.controls.tractorResidual"
            [min]="0"
            [showSideIcon]="true"
            [sideIconLabel]="'%'"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields"
          ></app-input>
        </div>
      </div>
      <div class="modal-new-config__group">
        <h4 class="modal-new-config__group-title">Conjunto</h4>
        <div class="modal-new-config__group-fields">
          <app-select
            [field]="'Status Financiamento'"
            [singleControl]="form.controls.trailerFinancingStatus"
            [selected]="trailerFinancingStatusSelected"
            [options]="trailerFinancingStatusOptions"
            placeholder="Status Financiamento"
            [multi]="false"
            [disabled]="shouldDisableFields"
          ></app-select>
          <app-select
            [field]="'Implemento'"
            [singleControl]="form.controls.trailerImplement"
            [selected]="implementSelected"
            [options]="implementOptions"
            placeholder="Implemento"
            [multi]="false"
            [disabled]="shouldDisableFields"
          ></app-select>
          <app-select
            [field]="'Material'"
            [singleControl]="form.controls.trailerMaterial"
            [selected]="materialSelected"
            [options]="materialOptions"
            placeholder="Material"
            [multi]="false"
            [disabled]="shouldDisableFields"
          ></app-select>
          <app-select
            [field]="'Tipo'"
            [singleControl]="form.controls.trailerType"
            [selected]="typeSelected"
            [options]="typeOptions"
            placeholder="Tipo"
            [multi]="false"
            [disabled]="shouldDisableFields"
          ></app-select>
          <app-input
            label="Ano Conjunto"
            placeholder="0000"
            [type]="eInputType.number"
            [mask]="eInputMask.number"
            [control]="form.controls.trailerYear"
            [showNumberActions]="true"
            [maxlength]="4"
            [max]="9999"
            [min]="0"
            [disabled]="shouldDisableFields"
          ></app-input>
          <app-datepicker
            [interval]="false"
            [controlStartDate]="form.controls.trailerStartDate"
            [monthCalendar]="true"
            [label]="'*Mês/Ano Entrada'"
            [disabled]="shouldDisableFields"
            [errorMessage]="'Selecione uma data'"
            [error]="!form.controls.trailerStartDate.value"
          ></app-datepicker>
          <app-input
            label="Residual Conjunto"
            placeholder="0,00"
            [type]="eInputType.number"
            [mask]="eInputMask.money"
            [control]="form.controls.trailerResidual"
            [min]="0"
            [showSideIcon]="true"
            [sideIconLabel]="'%'"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields"
          ></app-input>
        </div>
      </div>
      <div class="modal-new-config__actions">
        <app-secondary-button
          [label]="'Cancelar'"
          [enabled]="true"
          (click)="handleCancel()"
        ></app-secondary-button>
        <app-primary-button
          *ngIf="isEditing"
          label="Deletar"
          icon="assets/icons/trash-slate100.svg"
          [color]="ButtonColorEnum.warning"
          [right]="false"
          [loading]="isDeleting"
          [enabled]="!isSaving"
          (click)="handleDelete()"
        ></app-primary-button>
        <app-primary-button
          [label]="isEditing ? 'Alterar' : 'Adicionar'"
          [icon]="isEditing ? '' : 'assets/icons/add-common-100.svg'"
          [color]="ButtonColorEnum.primary"
          [right]="false"
          [loading]="isDeleting"
          [enabled]="enableSave"
          (click)="handleAddOrUpdate()"
        ></app-primary-button>
      </div>
    </div>
  </div>
</app-modal-generic>
