import { createAction, props } from '@ngrx/store';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';

export const loadGetDfdvModel = createAction(
  '[DfdvStateHandler] Load Dfdv',
  props<{ id: string }>(),
);

export const loadGetDfdvModelSuccess = createAction(
  '[DfdvStateHandler] Load Dfdv Success',
  props<{ data: GetDfdvModel }>(),
);

export const setLoading = createAction(
  '[DfdvLoadingHandler] Set loading state',
  props<{ isLoading: boolean }>(),
);

export const updateSubobject = createAction(
  '[DfdvStateHandler] Update SubObject',
  props<{ subObjectName: string, data: any }>(),
);

export const updateDfdvObject = createAction(
  '[DfdvStateHandler] Update Dfdv Object',
  props<{ data: any }>(),
);
export const clearDfdvObject = createAction(
  '[DfdvStateHandler] Clear Dfdv Object'
);
