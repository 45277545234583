import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger('smoothCollapseMenu', [
      state('initial', style({})),
      state(
        'final',
        style({
          height: '3rem',
        })
      ),
      transition('initial <=> final', animate('200ms')),
    ]),
  ],
})
export class MenuItemComponent implements OnInit {
  @Input() title!: string;
  @Input() icon!: string;
  @Input() hasChild: boolean = false;
  @Input() selected: boolean = false;
  @Input() showMenuItem: boolean = true;
  @Input() open: boolean = false;
  @Input() submenu: boolean = false;

  @Output() clickCallBack: EventEmitter<MenuItemComponent> =
    new EventEmitter<MenuItemComponent>();

  constructor() {}

  ngOnInit(): void {}

  toggleAccordion() {
    this.open = !this.open;
    this.handleClick();
  }

  handleClick() {
    this.clickCallBack.emit(this);
  }
}
