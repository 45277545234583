import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { BaseService } from 'src/app/common/services/base.service';
import { GetDfdvFilterModel } from './models/get-dfdv-filter.model';
import { GetDfdvPaged } from './models/get-dfdv-paged.model';
import { GetDfdvModel } from './models/getDfdv.model';
import { CreateDfdvModel } from './models/createDfdv.Model';
import { GetDfdvStatusModel } from './models/get-dfdv-status.model';
import { UpdateDfdvModel } from './models/updateDfdv.Model';

@Injectable({
  providedIn: 'root',
})
export class DfdvService extends BaseService {
  constructor(private http: HttpClient) {
    super('dfdv');
  }

  getDfdvById(dfdvId:string) {
    return this.http.get<GetDfdvModel>(`${this.url}/${dfdvId}`);
  }

  createDfdv(dfdv:CreateDfdvModel) {
    return this.http.post<GetDfdvModel[]>(`${this.url}/`, dfdv);
  }

  updateDfdv(dfdv:UpdateDfdvModel) {
    return this.http.patch<UpdateDfdvModel>(`${this.url}/${dfdv.dfdvId}`, dfdv);
  }

  getDfdvFiltered(filter: GetDfdvFilterModel) {
    const queryparams = {
      DfdvId: filter.dfdvId,
      Unit: filter.unit,
      Category: filter.category,
      Operator: filter.operator,
      Status: filter.status,
      fromStartDate:
        new Utils()
          .convertFromStringToDate(filter.fromStartDate)
          .toJSON() ?? '',
      toStartDate:
        new Utils()
          .convertFromStringToDate(filter.toStartDate)
          .toJSON() ?? '',
      fromEndDate:
          new Utils()
            .convertFromStringToDate(filter.fromEndDate)
            .toJSON() ?? '',
      toEndDate:
          new Utils()
            .convertFromStringToDate(filter.toEndDate)
            .toJSON() ?? '',
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
    };

    return this.http.get<GetDfdvPaged>(this.url, {
      params: queryparams,
    });
  }

  getDfdvStatus() {
    return this.http.get<GetDfdvStatusModel[]>(`${this.url}/status`);
  }

  sendToBID(dfdvId: number) {
    return this.http.patch<HttpResponseBase>(`${this.url}/${dfdvId}/send-to-bid`, '');
  }

  cancelDfdv(dfdvId: number) {
    return this.http.patch<HttpResponseBase>(`${this.url}/${dfdvId}/cancel`, { dfdvId });
  }

  finalizeDfdv(dfdvId: number) {
    return this.http.put<HttpResponseBase>(`${this.url}/${dfdvId}/finalize`, { dfdvId });
  }

  deleteDfdv(dfdvId: number) {
    return this.http.delete<HttpResponseBase>(`${this.url}/${dfdvId}`);
  }

  approveBID(dfdvIds: number[]) {
    return this.http.patch<HttpResponseBase>(`${this.url}/approve`, { dfdvIds });
  }

  reproveBID(dfdvIds: number[]) {
    return this.http.patch<HttpResponseBase>(`${this.url}/reprove`, { dfdvIds });
  }

  effectDfdv(dfdvId: number) {
    return this.http.patch<{ dfdvIds: number[] }>(`${this.url}/${dfdvId}/execute`, { dfdvId });
  }

  createNewVersionDfdv(dfdvId: number) {
    return this.http.post<GetDfdvModel>(`${this.url}/${dfdvId}/new-version`, { dfdvId });
  }

  effectNewDfdv(dfdvId: number, files: File[]) {
    const formData: FormData = new FormData();
    files.forEach((x) => formData.append('files', x));

    return this.http.patch<{ dfdvIds: number[] }>(`${this.url}/${dfdvId}/execute-new-version`, formData);
  }

  downloadFile(dfdvId: number, fileName: string) {
    return this.http.get(`${this.url}/${dfdvId}/attachments/${fileName}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });
  }

  downloadDfdvReport(dfdvId: number) {
    return this.http.get(`${this.url}/${dfdvId}/download`, { responseType: 'blob' });
  }
}
