<div class="card-content" [ngClass]="{ checked: dfdv.checked }">
  <div class="check-box">
    <div
      class="id"
      [ngClass]="{ 'center-id': !(!isHeader && approvalVisible) }"
    >
      {{ util.addLeadingZeros(dfdv.dfdvId, 5) }}
    </div>
    <div
      class="check"
      *ngIf="
        !isHeader &&
        approvalVisible &&
        dfdv.dfdvStatusName === DfdvStatusEnum.Pending
      "
    >
      <app-check-box
        (check)="onCheck()"
        (removeCheck)="onRemoveCheck()"
        [disabled]="isDisable()"
        [value]="dfdv.checked"
      ></app-check-box>
    </div>
  </div>
  <div class="info" (click)="viewDfdv(dfdv.dfdvId)">
    <div class="title">
      <div class="name">{{ dfdv.fantasyName || "DFDV de Rascunho" }}</div>
      <div class="status">
        <app-status
          [color]="StatusColorEnum.common"
          [status]="
            'v' +
            dfdv.version +
            ' - ' +
            (dfdv.creationDate | date : 'dd/MM/yyyy')
          "
        ></app-status>
        <app-status
          [color]="getDfdvTypeColor(dfdv.dfdvTypeName)"
          [status]="dfdv.dfdvTypeName"
        ></app-status>
        <app-status
          [color]="getDfdvStatusColor(dfdv.dfdvStatusName)"
          [status]="dfdv.dfdvStatusName"
        ></app-status>
      </div>
    </div>
    <div class="data">
      <div class="date">
        <img src="../../../../../assets/icons/calendar-primary-600.svg" />
        <div class="date-info">
          {{ dfdv.startDate | date : "dd/MM/yyyy" }}
          <div class="date-info-completion">
            {{ dfdv.endDate | date : "dd/MM/yyyy" }}
          </div>
        </div>
      </div>
      <div class="unit-name">
        <img src="../../../../../assets/icons/units-primary-600.svg" />
        <div class="name">{{ dfdv.unitName }}</div>
      </div>
      <div class="category-name">
        <img src="../../../../../assets/icons/categories-primary-600.svg" />
        <div class="name">{{ dfdv.categoryName }}</div>
      </div>
    </div>
    <div *ngIf="isUserRaizen && dfdv.invoicing" class="dfdv-detail__invoicing">
      <div class="info">
        <label class="info-title"> Faturamento Fixo </label>
        <label class="info-value"> {{ dfdv.invoicing.fixedCostInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Variável </label>
        <label class="info-value"> {{ dfdv.invoicing.variableCostInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Estimado Outros Custos </label>
        <label class="info-value"> {{ dfdv.invoicing.otherCostEstimatedInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Pré-Safra </label>
        <label class="info-value"> {{ dfdv.invoicing.preHarvestInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Entre-Safra </label>
        <label class="info-value"> {{ dfdv.invoicing.betweenHarvestInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Total </label>
        <label class="info-value info-total"> {{ dfdv.invoicing.totalInvoicing ?? 0 | currency : 'BRL' }} </label>
      </div>
    </div>
  </div>
</div>
