import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { GetCompaniesPimsModel } from './model/get-companies-pims.model';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  constructor(private http: HttpClient) {
    super('companies');
  }

  getCompaniesListPims(search: string) {
    return this.http.get<GetCompaniesPimsModel[]>(`${this.urlPims}`, {
      params: {
        parameters: search,
      },
    });
  }
}
