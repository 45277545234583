<div class="card-container">
  <div
    *ngFor="let subcategory of subcategoriesList"
    class="card-content"
    (click)="handleDataClick(subcategory.subcategoryId)"
  >
    <div class="id">
      <label>{{ util.addLeadingZeros(subcategory.subcategoryId, 5) }}</label>
    </div>
    <div class="items">
      <label class="name">{{ subcategory.name }}</label>
      <div id="status">
        <app-status
        [color]="
          subcategory.active ? StatusColorEnum.success : StatusColorEnum.warning
        "
          [image]="
            subcategory.active
              ? '../../../../../../assets/icons/active.svg'
              : '../../../../../../assets/icons/Exclude.svg'
          "
          [status]="subcategory.active ? 'Ativo' : 'Inativo'"
        >
        </app-status>
      </div>
    </div>
  </div>
</div>
