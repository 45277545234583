<div class="container">
  <section>
    <header>
      <img src="./././assets/images/access-denied.svg" alt="Acesso Negado" />
    </header>
    <main>
      <label class="title">Opssssssss!</label>
      <label class="description"
        >A página que você procura não foi encontrada.</label
      >
      <app-buttons
        label="Voltar para as solicitações"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="false"
        (clickButton)="onBack()"
      ></app-buttons>
    </main>
  </section>
</div>
