import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { ButtonColorEnum } from './enums/button-color.enum';
import { ButtonsType } from '../enums/Buttonstypes';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {
  @Input() label: string = '';
  @Input() type!: ButtonsType;
  @Input() icon: string = '';
  @Input() animation:boolean = true;
  @Input() enabled: boolean = false;
  @Input() right: boolean = false;
  @Input() loading: boolean = false;
  @Input() color!: ButtonColorEnum;
  @Input() progress: boolean = false;
  @Input() progressValue: string = '';

  @Output() clickButton:EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;

  onClick(event: MouseEvent) {
    this.clickButton.emit(event);
  }
}
