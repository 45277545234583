import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Utils } from 'src/app/common/utils/utils';
import { CVConsumptionModel } from 'src/app/services/dfdv/variable-costs/models/variable-costs.model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss'],
})
export class ConsumptionComponent implements OnChanges, AfterViewInit {
  // Inputs
  @Input() isLoading: boolean = false;
  @Input() shouldDisableFields: boolean = false;
  @Input() consumptionList: CVConsumptionModel[] = [];
  @Input() consumptionForm = new FormGroup({
    tractorConsumption: new FormControl(''),
  });
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enums
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public consumptionListNext: CVConsumptionModel[] = [];
  public errorMessage = 'Este campo é obrigatório';

  // Mobile Control
  public breakpointBase: number = 768;
  public widthBreakpoint: number = 0;
  public isMobile: boolean = false;

  constructor(private util: Utils) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngOnChanges(): void {
    if (this.consumptionList) {
      this.consumptionListNext = this.consumptionList.slice(1);
    }
  }
  ngAfterViewInit(): void {
    this.checkDevice();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  checkDevice() {
    this.isMobile = this.getWindowInnerWidth() <= this.widthBreakpoint;
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
