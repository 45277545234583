import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { ButtonColorEnum } from '../enums/button-color.enum';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() loading: boolean = false;
  @Input() enabled: boolean = false;
  @Input() icon: string = '';
  @Input() color: ButtonColorEnum = ButtonColorEnum.primary;

  @Output() clickButton: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(event: MouseEvent) {
    if (!this.loading && this.enabled) {
      this.clickButton.emit(event);
    }
  }
}
