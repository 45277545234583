<app-card
  [paddingHorizontal]="false"
  [title]="title"
  [icon]="icon"
  [accordion]="accordion"
>
  <div *ngIf="loading" class="skeleton" body>
    <app-skeleton-line [line]="skeletonSize"></app-skeleton-line>
  </div>

  <section actions class="actions" *ngIf="actions && !isMobile && !loading">
    <app-icon-button
      (clickButton)="addLine()"
      icon="../../../../assets/icons/add-primary.svg"
      text="Novo item"
      [enabled]="true"
      [color]="eButtonColor.primary"
    ></app-icon-button>
  </section>

  <ng-container *ngIf="!loading" body>
    <div body class="table" *ngIf="!isMobile">
      <div class="paragraph" *ngIf="paragraph.length > 0">
        <p content>{{ paragraph }}</p>
      </div>
      <div class="infobox" *ngIf="showInfoBox">
        <app-infobox
          *ngIf="!formIsValid() || hasError"
          [type]="eInfoboxType.warning"
        >
          <span content>
            {{ errorMessage }}
          </span>
        </app-infobox>
      </div>
      <div class="header table-template" [ngStyle]="getColumnTemplate()">
        <div *ngIf="rowDescriptions.length > 0">{{ descriptionLabel }}</div>
        <div *ngFor="let header of headers">{{ header }}</div>
        <div class="removeAction" *ngIf="actions">AÇÃO</div>
      </div>
      <div class="body">
        <ng-container *ngFor="let row of rows; let i = index">
          <div class="row table-template" [ngStyle]="getColumnTemplate()">
            <div class="description" *ngIf="rowDescriptions.length > 0">
              {{ rowDescriptions[i] }}
            </div>
            <div class="column" *ngFor="let column of columns; let j = index">
              <ng-container [ngSwitch]="column.type">
                <app-input
                  *ngSwitchCase="'input'"
                  [type]="column.inputColumnModel.type"
                  [mask]="column.inputColumnModel.mask"
                  [control]="getFormControl(row, column.name)"
                  [placeholder]="column.inputColumnModel.placeholder"
                  [height]="height"
                  [sideIconLabel]="column.inputColumnModel.sideIconLabel"
                  [showSideIcon]="column.inputColumnModel.sideIcon"
                  [showNumberActions]="
                    column.inputColumnModel.showNumberActions
                  "
                  [min]="column.inputColumnModel.min"
                  [max]="column.inputColumnModel.max"
                  [enableMessageError]="false"
                  [disabled]="column.inputColumnModel.isDisabled"
                  [showTitle]="false"
                  (updateValue)="handleUpdateValue()"
                ></app-input>
                <span *ngSwitchCase="'text'">
                  {{ row.get(column.name)?.value }}
                </span>
                <span *ngSwitchCase="'money'">
                  {{ row.get(column.name)?.value | currency : "BRL" }}
                </span>
                <span *ngSwitchCase="'calc'" class="data">
                  {{ calcColumn(row, column.name) }}
                </span>
                <span *ngSwitchCase="'number'" class="data">
                  {{ row.get(column.name)?.value }}
                </span>
                <app-select
                  *ngSwitchCase="'select'"
                  [singleControl]="getFormControl(row, column.name)"
                  [showErrorMessage]="false"
                  [height]="2.5"
                  [options]="column.options"
                  [selected]="getSelectedOption(column.name, i)"
                  property="Type"
                  (statusChanged)="
                    changeControl($event, column.name, i); handleUpdateValue()
                  "
                ></app-select>
                <app-toogle
                  *ngSwitchCase="'toogle'"
                  [checked]="row.get(column.name)?.value"
                  [label]="'Ativo'"
                  [toggleId]="headers[j] + '_' + i"
                  (check)="
                    handleCheck(row, column.name, i); handleUpdateValue()
                  "
                ></app-toogle>
                <span class="suffix" *ngIf="column.suffix">
                  {{ column.suffix }}
                </span>
              </ng-container>
            </div>
            <div class="removeAction-button" *ngIf="actions">
              <app-icon-button
                icon="../../../../assets/icons/delete-warning-600.svg"
                [enabled]="true"
                (clickButton)="removeLine(i)"
              ></app-icon-button>
            </div>
          </div>
        </ng-container>
        <div
          class="total-container table-template"
          *ngIf="showTotal"
          [ngStyle]="getColumnTemplate()"
        >
          <div class="description">
            <span>{{ labelTotal }}</span>
          </div>

          <ng-container
            *ngIf="
              customTotalValue !== null;
              then showCustomValue;
              else calcTotalTemplate
            "
          ></ng-container>

          <ng-template #showCustomValue>
            <span class="data">
              {{ customTotalValue }}
            </span>
          </ng-template>

          <ng-template #calcTotalTemplate>
            <div class="total" *ngFor="let column of columns; let j = index">
              <span class="data" *ngIf="column.type === 'money'">
                {{ calcTotal(column, false) | currency : "BRL" }}
              </span>
              <span class="data" *ngIf="column.type === 'text'"> - </span>
              <span class="data" *ngIf="column.type === 'number'">
                {{ calcTotal(column, false) }}
              </span>
              <span
                class="data"
                [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
                *ngIf="
                  column.type === 'input' &&
                  column.inputColumnModel.mask === eMaskType.money
                "
              >
                {{ calcTotal(column, false) | currency : "BRL" }}
              </span>
              <span
                class="data"
                [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
                *ngIf="
                  column.type === 'input' &&
                  column.inputColumnModel.mask !== eMaskType.money
                "
              >
                {{ calcTotal(column, false) }}
              </span>
              <span
                class="data"
                [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
                *ngIf="
                  column.type === 'calc' &&
                  column.inputColumnModel.mask === eMaskType.money
                "
              >
                {{ calcTotal(column, false) | currency : "BRL" }}
              </span>
              <span
                class="data"
                [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
                *ngIf="
                  column.type === 'calc' &&
                  column.inputColumnModel.mask !== eMaskType.money
                "
              >
                {{ calcTotal(column, false) }}
              </span>
              <span
                class="suffix"
                *ngIf="column.suffix !== '' || column.totalSuffix !== ''"
              >
                {{ column.suffix || column.totalSuffix }}
              </span>
            </div>
          </ng-template>
        </div>
        <div
          class="total-container table-template"
          *ngIf="showCustomTotal"
          [ngStyle]="getColumnTemplate()"
        >
          <div class="description">
            <span>{{ customLabel }}</span>
          </div>

          <span
            class="data"
            [ngStyle]="{ 'padding-left': totalLeftPadding + 'rem' }"
          >
            {{ customValue }}
          </span>
        </div>
        <div
          class="total-container table-template"
          *ngIf="showAverage"
          [ngStyle]="getColumnTemplate()"
        >
          <div class="description">
            <span>{{ labelMedia }}</span>
          </div>
          <div class="total" *ngFor="let column of columns; let j = index">
            <span class="data" *ngIf="column.type === 'money'">
              {{ calcTotal(column, true) | currency : "BRL" }}
            </span>
            <span class="data" *ngIf="column.type === 'text'"> - </span>
            <span class="data" *ngIf="column.type === 'number'">
              {{ calcTotal(column, true) }}
            </span>
            <span
              class="data"
              [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
              *ngIf="
                column.type === 'input' &&
                column.inputColumnModel.mask === eMaskType.money
              "
            >
              {{ calcTotal(column, true) | currency : "BRL" }}
            </span>
            <span
              class="data"
              [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
              *ngIf="
                column.type === 'input' &&
                column.inputColumnModel.mask !== eMaskType.money
              "
            >
              {{ calcTotal(column, true) }}
            </span>
            <span
              class="data"
              [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
              *ngIf="
                column.type === 'calc' &&
                column.inputColumnModel.mask === eMaskType.money
              "
            >
              {{ calcTotal(column, true) | currency : "BRL" }}
            </span>
            <span
              class="data"
              [ngStyle]="{ 'padding-left': column.totalLeftPadding + 'rem' }"
              *ngIf="
                column.type === 'calc' &&
                column.inputColumnModel.mask !== eMaskType.money
              "
            >
              {{ calcTotal(column, true) }}
            </span>
            <span
              class="suffix"
              *ngIf="column.suffix !== '' || column.totalSuffix !== ''"
            >
              {{ column.suffix || column.totalSuffix }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Card -->
    <div body class="card-container" *ngIf="isMobile">
      <div class="card">
        <div class="paragraph" *ngIf="paragraph.length > 0">
          <p content>{{ paragraph }}</p>
        </div>
        <div class="infobox" *ngIf="showInfoBox">
          <app-infobox
            *ngIf="!formIsValid() || hasError"
            [type]="eInfoboxType.warning"
          >
            <span content>
              {{ errorMessage }}
            </span>
          </app-infobox>
        </div>
        <ng-container *ngFor="let row of rows; let i = index">
          <div class="row">
            <div
              class="description"
              *ngIf="rowDescriptions.length > 0"
              [ngClass]="{ 'flex-start': flexStart }"
            >
              {{ rowDescriptions[i] }}
            </div>
            <div class="card-body">
              <div *ngFor="let column of columns; let j = index">
                <span>{{ headers[j] }}</span>
                <div>
                  <ng-container [ngSwitch]="column.type">
                    <app-input
                      *ngSwitchCase="'input'"
                      [type]="column.inputColumnModel.type"
                      [mask]="column.inputColumnModel.mask"
                      [control]="getFormControl(row, column.name)"
                      [placeholder]="column.inputColumnModel.placeholder"
                      [height]="'2.3rem'"
                      [sideIconLabel]="column.inputColumnModel.sideIconLabel"
                      [showSideIcon]="column.inputColumnModel.sideIcon"
                      [showNumberActions]="
                        column.inputColumnModel.showNumberActions
                      "
                      [min]="column.inputColumnModel.min"
                      [max]="column.inputColumnModel.max"
                      [enableMessageError]="false"
                      [showTitle]="false"
                      [disabled]="column.inputColumnModel.isDisabled"
                      (updateValue)="handleUpdateValue()"
                    ></app-input>
                    <span *ngSwitchCase="'text'" class="data">
                      {{ row.get(column.name)?.value }}
                    </span>
                    <span *ngSwitchCase="'money'" class="data">
                      {{ row.get(column.name)?.value | currency : "BRL" }}
                    </span>
                    <span *ngSwitchCase="'calc'" class="data">
                      {{ calcColumn(row, column.name) }}
                    </span>
                    <span *ngSwitchCase="'number'" class="data">
                      {{ row.get(column.name)?.value }}
                    </span>
                    <app-select
                      *ngSwitchCase="'select'"
                      [singleControl]="getFormControl(row, column.name)"
                      [showErrorMessage]="false"
                      [height]="2.5"
                      [options]="column.options"
                      [selected]="getSelectedOption(column.name, i)"
                      property="Type"
                      (statusChanged)="
                        changeControl($event, column.name, i);
                        handleUpdateValue()
                      "
                    ></app-select>
                    <app-toogle
                      *ngSwitchCase="'toogle'"
                      [checked]="row.get(column.name)?.value"
                      [label]="'Ativo'"
                      [toggleId]="headers[j]"
                      (check)="
                        handleCheck(row, column.name, i); handleUpdateValue()
                      "
                    ></app-toogle>
                  </ng-container>
                  <span class="suffix data" *ngIf="column.suffix !== ''">
                    {{ column.suffix }}
                  </span>
                </div>
              </div>
              <div class="removeAction" *ngIf="actions">
                <span>AÇÃO</span>
                <app-icon-button
                  class="remove-button"
                  icon="../../../../assets/icons/delete-warning-600.svg"
                  [enabled]="true"
                  (clickButton)="removeLine(i)"
                ></app-icon-button>
              </div>
            </div>
          </div>
        </ng-container>
        <div
          class="total-card"
          *ngIf="showTotal"
          [ngStyle]="getColumnTemplate()"
        >
          <div *ngFor="let column of columns; let j = index">
            <div
              class="total"
              *ngIf="
                column.type !== 'text' &&
                column.inputColumnModel.type !== 'text'
              "
            >
              <span>{{ labelTotal }}: {{ headers[j] }}</span>
              <div>
                <span class="data" *ngIf="column.type === 'money'">
                  {{ calcTotal(column, false) | currency : "BRL" }}
                </span>
                <span class="data" *ngIf="column.type === 'number'">
                  {{ calcTotal(column, false) }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'input' &&
                    column.inputColumnModel.mask === eMaskType.money
                  "
                >
                  {{ calcTotal(column, false) | currency : "BRL" }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'input' &&
                    column.inputColumnModel.mask !== eMaskType.money
                  "
                >
                  {{ calcTotal(column, false) }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'calc' &&
                    column.inputColumnModel.mask === eMaskType.money
                  "
                >
                  {{ calcTotal(column, false) | currency : "BRL" }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'calc' &&
                    column.inputColumnModel.mask !== eMaskType.money
                  "
                >
                  {{ calcTotal(column, false) }}
                </span>
                <span
                  class="suffix data"
                  *ngIf="column.suffix !== '' || column.totalSuffix !== ''"
                >
                  {{ column.suffix || column.totalSuffix }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="total-card"
          *ngIf="showCustomTotal"
          [ngStyle]="getColumnTemplate()"
        >
          <div class="total">
            <span>{{ customLabel }}</span>
            <div>
              <span
                class="custom"
                [ngStyle]="{ 'padding-left': totalLeftPadding + 'rem' }"
              >
                {{ customValue }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="total-card"
          *ngIf="showAverage"
          [ngStyle]="getColumnTemplate()"
        >
          <div *ngFor="let column of columns; let j = index">
            <div
              class="total"
              *ngIf="
                column.type !== 'text' &&
                column.inputColumnModel.type !== 'text'
              "
            >
              <span>{{ labelMedia }}: {{ headers[j] }}</span>
              <div>
                <span class="data" *ngIf="column.type === 'money'">
                  {{ calcTotal(column, true) | currency : "BRL" }}
                </span>
                <span class="data" *ngIf="column.type === 'number'">
                  {{ calcTotal(column, true) }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'input' &&
                    column.inputColumnModel.mask === eMaskType.money
                  "
                >
                  {{ calcTotal(column, true) | currency : "BRL" }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'input' &&
                    column.inputColumnModel.mask !== eMaskType.money
                  "
                >
                  {{ calcTotal(column, true) }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'calc' &&
                    column.inputColumnModel.mask === eMaskType.money
                  "
                >
                  {{ calcTotal(column, true) | currency : "BRL" }}
                </span>
                <span
                  class="data"
                  [ngStyle]="{
                    'padding-left': column.totalLeftPadding + 'rem'
                  }"
                  *ngIf="
                    column.type === 'calc' &&
                    column.inputColumnModel.mask !== eMaskType.money
                  "
                >
                  {{ calcTotal(column, true) }}
                </span>
                <span
                  class="suffix data"
                  *ngIf="column.suffix !== '' || column.totalSuffix !== ''"
                >
                  {{ column.suffix || column.totalSuffix }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <section actions class="actions" *ngIf="actions && isMobile">
          <app-icon-button
            (clickButton)="addLine()"
            icon="../../../../assets/icons/add-primary.svg"
            text="Novo Item"
            [enabled]="true"
            [color]="eButtonColor.primary"
          ></app-icon-button>
        </section>
      </div>
    </div>
  </ng-container>
</app-card>
