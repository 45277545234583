import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent implements OnInit {
  @Input() enabled:boolean = false;
  @Input() label:string = '';
  @Input() icon:string = '';
  @Input() right:boolean = false;
  @Input() loading:boolean = false;
  @Input() animation!:boolean;

  @Output() clickButton:EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  handleClick(event: MouseEvent) {
    if (this.animation) {
      this.loading = !this.loading;
    }
    this.clickButton.emit(event);
  }

  constructor() { }

  ngOnInit(): void {
  }
}
