<div class="consumption-container">
  <div class="consumption-card">
    <app-card
      title="Consumo"
      icon="../../../../../assets/icons/velocimeter-primary.svg"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="6"></app-skeleton-input>
      </div>
      <section *ngIf="!isLoading" class="body" body>
        <p>Informar os valores <b>unitários</b> gastos.</p>
        <div class="body-input">
          <app-input
            label="Custo Combustível Frota Leve (R$) "
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.lightFleetFuelCost"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Consumo KM/L Frota Leve "
            placeholder="0"
            [errorMessage]="errorMessage"
            [control]="form.controls.lightFleetConsumption"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel=",00"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Custo Combustível Transporte Coletivo (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.collectiveTransportFuelCost"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Consumo KM/L Transporte Coletivo "
            placeholder="0"
            [errorMessage]="errorMessage"
            [control]="form.controls.collectiveTransportConsumption"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel=",00"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Custo Arla"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.arlaCost"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Custo Lavagem"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.washingCost"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>

          <app-input
            label="Custo Diesel ANP"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.anpDieselCost"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldOPL"
            (updateValue)="handleUpdateValue()"
          ></app-input>
        </div>
      </section>
    </app-card>
  </div>
</div>
