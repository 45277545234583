import { CategoryFixedCostsModel } from './CategoryFixedCosts.model';

export class FixedCostsModel {
  fixedCostId!: number;
  date!: string;
  fixedCostStatusName!: string;
  totalValue!: number;
  currentValue!: number;
  valueSent!: number;
  valueToBePaid!: number;
  totalSolicitationSent!: number;
  totalSolicitationNotSent!: number;
  totalSolicitationPending!: number;
  categories!: CategoryFixedCostsModel[];
}
