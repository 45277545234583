import { Component } from '@angular/core';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lgpd-info',
  templateUrl: './lgpd-info.component.html',
  styleUrls: ['./lgpd-info.component.scss'],
})
export class LgpdInfoComponent {
  public eButtonsType = ButtonsType;
  public get InfoboxTypeEnum() { return InfoboxTypeEnum; }

  onLGPD() {
    window.open(environment.LGPDTerm, '_blank');
  }
}
