<ng-template #stepBox>
  <div class="container" [ngClass]="type">
    <div class="circle" [class.green]="type === 'previous'">
      <div class="inside">
        <img *ngIf="type === 'previous'"
        src="../../../assets/icons/check-active.svg" alt="current">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #title>
  <div class="container" [ngClass]="type">
    <label>{{ label }}</label>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
