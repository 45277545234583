import { ScopeRentalModel } from './scope-rental/scope-rental.model';
import { ScopeConsumptionModel } from './scope-consumption/scope-consumption.model';
import { ScopeEquipmentModel } from './scope-equipment/scope-equipment.model';
import { ScopeFeeModel } from './scope-fee/scope-fee.model';

export class ScopeDfdvModel {
  public equipment: ScopeEquipmentModel = new ScopeEquipmentModel();
  public rental!: ScopeRentalModel;
  public consumption!: ScopeConsumptionModel;
  public fee: ScopeFeeModel = new ScopeFeeModel();
}
