import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetOperationPimsModel } from 'src/app/services/operation/models/get-operation-pims.model';
import { OperationService } from 'src/app/services/operation/operation.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
})
export class OperationComponent implements OnInit {
  public operations: GetOperationPimsModel[] = [];
  public enableButton: boolean = true;
  public loadingButton: boolean = false;
  public isLoading: boolean = true;
  public error: boolean = false;
  public get ButtonColorEnum() { return ButtonColorEnum; }

  constructor(
    private service: OperationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getOperations();
  }

  getOperations() {
    this.service.getOperations().subscribe({
      next: (result) => {
        this.operations = result;
        this.isLoading = false;
        this.error = false;
      },
      error: () => {
        this.operations = [];
        this.isLoading = false;
        this.error = true;
      },
    });
  }

  goToNewOperation() {
    this.router.navigate(['admin/operation/new']);
  }

  reload() {
    this.getOperations();
  }
}
