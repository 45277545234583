<div class="fleet-value-container">
  <div class="fleet-value-card">
    <app-card
      title="Equipamentos"
      icon="../../../../../assets/icons/minus-primary.svg"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="6"></app-skeleton-input>
      </div>
      <section *ngIf="!isLoading" class="body" body>
        <app-input
          label="Valor Cavalo Mecânico (R$)"
          placeholder="0,00"
          [errorMessage]="errorMessage"
          [control]="form.controls.tractorCost"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields || disableFieldRaizen"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Valor Reboque e Semi Reboque (R$)"
          placeholder="0,00"
          [errorMessage]="errorMessage"
          [control]="form.controls.trailerCost"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields || disableFieldRaizen"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Total Frota Leve"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.lightFleetQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Total Cavalo Mecânico Linha"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.lineTractorQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Total Cavalo Mecânico Manobra"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.maneuverTractorQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Total Cavalo Mecânico"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.tractorQuantity"
          [type]="eInputType.number"
          [disabled]="true"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Total Conjunto"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.trailerQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Fator Conjunto"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.jointFactor"
          [mask]="eMaskType.money"
          [disabled]="true"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Pneus Por Cavalo Mecânico"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.tractorTireQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Quantidade Pneus Por Conjunto"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.trailerTireQuantity"
          [mask]="eMaskType.number"
          [max]="1000"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="KM Mensal Estimado Cavalo Mecânico Linha"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.lineTractorEstimatedMonthlyKm"
          [mask]="eMaskType.number"
          [max]="9999999999"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="KM Mensal Estimado Cavalo Mecânico Manobra"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.maneuverTractorEstimatedMonthlyKm"
          [mask]="eMaskType.number"
          [max]="9999999999"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="KM Rodado por Dia de Troca"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.operationDayDrivenKm"
          [mask]="eMaskType.number"
          [max]="9999999999"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="KM Rodado por Dia de Operação"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="form.controls.exchangeDayDrivenKm"
          [mask]="eMaskType.number"
          [max]="9999999999"
          [min]="0"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Vida Útil Cavalo Mecânico (em anos)"
          [placeholder]="'0,00'"
          errorMessage="{{ errorMessage }}"
          [control]="form.controls.tractorLifespan"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel=",00"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>

        <app-input
          label="Vida Útil Conjunto (em anos)"
          [placeholder]="'0,00'"
          errorMessage="{{ errorMessage }}"
          [control]="form.controls.trailerLifespan"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel=",00"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields || disableFieldOPL"
          (updateValue)="handleUpdateValue()"
        ></app-input>
      </section>
    </app-card>
  </div>
</div>
