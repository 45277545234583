import { QuantitiesCostAmountValues } from '../quantitiesCostAmountValues.model';

export class AdministrativeTeamUniformModel {
  [key: string]: QuantitiesCostAmountValues;

  pants: QuantitiesCostAmountValues;
  shirt: QuantitiesCostAmountValues;
  boots: QuantitiesCostAmountValues;
  glasses: QuantitiesCostAmountValues;
  hearingProtection: QuantitiesCostAmountValues;

  constructor() {
    this.pants = new QuantitiesCostAmountValues();
    this.shirt = new QuantitiesCostAmountValues();
    this.boots = new QuantitiesCostAmountValues();
    this.glasses = new QuantitiesCostAmountValues();
    this.hearingProtection = new QuantitiesCostAmountValues();
  }
}
