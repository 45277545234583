import {
  AfterViewChecked, Component, Input, OnInit, ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { FixedCostService } from 'src/app/services/fixedCosts/fixed-costs.service';
import { FixedCostHistoryModel } from 'src/app/services/fixedCosts/models/fixedCostHistory/FixedCostHistory.model';
import { HistoryItemTypeEnum } from 'src/app/shared/history/enums/history-item-type.enum';
import { HistoryItemComponent } from 'src/app/shared/history/history-item/history-item.component';

@Component({
  selector: 'app-fixed-cost-history',
  templateUrl: './fixed-cost-history.component.html',
  styleUrls: ['./fixed-cost-history.component.scss'],
})
export class FixedCostHistoryComponent implements OnInit {
  public fixedCostHistory: FixedCostHistoryModel[] = [];
  public isLoaded: boolean = false;

  public historyItemTypeEnum = HistoryItemTypeEnum;

  @ViewChild('HistoryItem') historyItem!: HistoryItemComponent;

  constructor(
    public utils: Utils,
    public service: FixedCostService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const fixedCostId = +(params.get('id') ?? '0');
      const categoryPimsId = +(params.get('categoryId') ?? '0');
      const instanceId = params.get('unitId') ?? '';

      this.service.getFixedCostHistory(fixedCostId, categoryPimsId, instanceId).subscribe((x) => {
        this.fixedCostHistory.push(...x);
        this.isLoaded = true;
      });
    });
  }

  getColorValue(type: string) {
    switch (type) {
      case 'Outros Custos':
        return 'other-costs';
      case 'Custo Fixo':
        return 'fixed-cost';
      case 'Desconto':
        return 'discount';
      default:
        return 'other-costs';
    }
  }

}
