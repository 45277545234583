import { BenefitKeysModel } from './benefit-keys.model';

export class BenefitModel {
  [key: string] : any;

  totalStandardDriver!: number;
  totalNightDriver!: number;
  keys!: BenefitKeysModel;

  constructor() {
    this.keys = new BenefitKeysModel();
  }
}
