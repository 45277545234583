import {
  Component, ElementRef, Input, TemplateRef, ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent {
  @Input() title: string = '';
  @ViewChild('content') content!: TemplateRef<ElementRef>;
}
