import { SolicitationJustify } from 'src/app/pages/solicitation/solicitation-approval-modal/model/solicitation-justify.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { catchError, throwError } from 'rxjs';
import { GetSolicitationPaged } from './models/get-solicitation-paged.model';
import { GetApprovementFlowModel } from './models/get-approvementFlow.model';
import { GetApprovementModel } from './models/get-approvement.model';
import { CreateSolicitationModel } from './models/create-solicitation-model';
import { GetPaymentTypeModel } from './models/get-payment-type-model';
import { GetSolicitationFilterModel } from './models/get-solicitation-filter-model';
import { GetSolicitationModel } from './models/get-solicitation-model';
import { GetSolicitationStatusModel } from './models/get-solicitation-status-model';
import { GetValueLimitModel } from './models/get-value-limit-model';
import { UpdateSolicitationModel } from './models/update-solicitation-model';
import { UpdateValueLimitModel } from './models/update-value-limit-model';
import { GetSolicitationHistoryModel } from './models/get-solicitation-history.model';
import { BaseService } from '../../common/services/base.service';
import { GetSolicitationTypeModel } from './models/get-SolicitationType-model';

@Injectable({
  providedIn: 'root',
})
export class SolicitationService extends BaseService {
  constructor(private http: HttpClient) {
    super('solicitations');
  }

  getSolicitations(filter: GetSolicitationFilterModel, username: string) {
    const queryparams = {
      solicitationId: filter.solicitationId,
      fantasyName: filter.fantasyName,
      unit: filter.unit,
      category: filter.category,
      status: filter.status,
      types: filter.types,
      startOpeningDate: new Utils().convertFromStringToDate(filter.startOpeningDate).toJSON() ?? '',
      endOpeningDate: new Utils().convertFromStringToDate(filter.endOpeningDate).toJSON() ?? '',
      startCompletionDate: new Utils().convertFromStringToDate(filter.startCompletionDate).toJSON() ?? '',
      endCompletionDate: new Utils().convertFromStringToDate(filter.endCompletionDate).toJSON() ?? '',
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
    };

    const header = new HttpHeaders({ access_token_owner: username });

    return this.http.get<GetSolicitationPaged>(
      this.url,
      {
        params: queryparams,
        headers: header,
      },
    );
  }

  getSolicitationById(id: number) {
    return this.http.get<GetSolicitationModel>(`${this.url}/${id}`);
  }

  createSolicitation(request: CreateSolicitationModel) {
    return this.http.post<GetSolicitationModel>(this.url, request);
  }

  updateSolicitation(request: UpdateSolicitationModel) {
    return this.http.patch<GetSolicitationModel>(`${this.url}/${request.solicitationId}`, request);
  }

  getSolicitationStatus() {
    return this.http.get<GetSolicitationStatusModel[]>(`${this.url}/status`);
  }

  getPaymentTypes() {
    return this.http.get<GetPaymentTypeModel[]>(`${this.url}/payment-types`);
  }

  getValueLimits() {
    return this.http.get<GetValueLimitModel>(`${this.url}/value-limits`);
  }

  updateValueLimits(request: UpdateValueLimitModel) {
    return this.http.patch<GetValueLimitModel>(`${this.url}/value-limits`, request);
  }

  massiveApprovements(request: SolicitationJustify, username: string) {
    const header = new HttpHeaders({ access_token_owner: username });

    return this.http.put<GetApprovementModel[]>(`${this.url}/massive-approvement`, request, { headers: header });
  }

  getSolicitationHistoriesById(id: string, username: string) {
    const header = new HttpHeaders({ access_token_owner: username });
    return this.http.get<GetSolicitationHistoryModel[]>(`${this.url}/${id}/history`, { headers: header });
  }

  getApprovementFlow(solicitationId : string, username: string) {
    const header = new HttpHeaders({ access_token_owner: username });

    return this.http.get<GetApprovementFlowModel[]>(`${this.url}/${solicitationId}/approvement`, { headers: header });
  }

  getSolicitationTypes() {
    return this.http.get<GetSolicitationTypeModel[]>(`${this.url}/types`);
  }

  getSolicitationsReportDownload(filter?: GetSolicitationFilterModel) {
    let params = new HttpParams();

    if (filter !== undefined) {
      if (filter.solicitationId) { params = params.append('solicitationId', filter.solicitationId); }

      if (filter.startOpeningDate) { params = params.append('startOpeningDate', new Utils().convertFromStringToDate(filter.startOpeningDate).toJSON()); }

      if (filter.endOpeningDate) { params = params.append('endOpeningDate', new Utils().convertFromStringToDate(filter.endOpeningDate).toJSON()); }

      if (filter.startCompletionDate) { params = params.append('startCompletionDate', new Utils().convertFromStringToDate(filter.startCompletionDate).toJSON()); }

      if (filter.endCompletionDate) { params = params.append('endCompletionDate', new Utils().convertFromStringToDate(filter.endCompletionDate).toJSON()); }

      filter.fantasyName.forEach((x) => {
        params = params.append('fantasyName', x);
      });

      filter.unit.forEach((x) => {
        params = params.append('unit', x);
      });

      filter.category.forEach((x) => {
        params = params.append('category', x);
      });

      filter.status.forEach((x) => {
        params = params.append('status', x);
      });

      filter.types.forEach((x) => {
        params = params.append('types', x);
      });
    }
    return this.http.get(`${this.url}/download`, { params, responseType: 'blob' });
  }

  uploadFile(files:File[], solicitationId: string) {
    const formData: FormData = new FormData();
    files.forEach((x) => formData.append('files', x));

    return this.http.post<GetSolicitationModel>(
      `${this.url}/${solicitationId}/attachments`,
      formData,
      { reportProgress: true, observe: 'events' },
    ).pipe(
      catchError(this.errorMgmt),
    );
  }

  downloadFile(solicitationId: string, fileName: string) {
    return this.http.get(`${this.url}/${solicitationId}/attachments/${fileName}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
