<app-card
  title="Filtros"
  icon="../../../../assets/icons/filter-primary-600.svg"
  [accordion]="true"
>
  <section actions class="actions" *ngIf="!isLoadingFilter">
    <div class="buttons">
      <app-icon-button
        (clickButton)="resetFilter()"
        icon="../../../../assets/icons/reset-primary.svg"
        text="Resetar"
        [enabled]="true"
        [color]="ButtonColorEnum.primary"
      ></app-icon-button>
      <app-icon-button
        *ngIf="form.dirty"
        (clickButton)="clearFilterFields()"
        icon="../../../../assets/icons/delete-warning-600.svg"
        text="Limpar"
        [enabled]="true"
        [color]="ButtonColorEnum.warning"
      ></app-icon-button>
    </div>
  </section>
  <section body class="body" *ngIf="!isLoadingFilter">
    <div class="filters">
      <app-input
        label="ID"
        placeholder="Informe o ID da solicitação"
        [control]="form.controls.id"
      ></app-input>
      <app-select
        field="Tipo de Solicitação"
        property="nome"
        id="Types"
        [options]="types"
        [selected]="typesSelected"
        [multiControl]="form.controls.types"
        [multi]="true"
      ></app-select>
      <app-datepicker
        label="Período de Abertura"
        placeholder="Selecione o intervalo de data"
        [controlStartDate]="form.controls.startOpeningDate"
        [controlEndDate]="form.controls.endOpeningDate"
        [interval]="true"
        [disabled]="false"
      ></app-datepicker>
      <app-datepicker
        label="Período de Conclusão"
        placeholder="Selecione o intervalo de data"
        [controlStartDate]="form.controls.startCompletionDate"
        [controlEndDate]="form.controls.endCompletionDate"
        [interval]="true"
        [disabled]="false"
      ></app-datepicker>
      <app-select
        *ngIf="visible"
        field="Empresa"
        property="nome"
        id="OPL"
        [options]="opls"
        [selected]="oplsSelected"
        [multiControl]="form.controls.opls"
        [multi]="true"
      ></app-select>
      <app-select
        field="Unidade"
        property="nome"
        id="Unidade"
        [options]="units"
        [selected]="unitsSelected"
        [multiControl]="form.controls.units"
        [multi]="true"
      ></app-select>
      <app-select
        field="Categoria"
        property="nome"
        id="Categoria"
        [options]="categories"
        [selected]="categoriesSelected"
        [multiControl]="form.controls.category"
        [multi]="true"
      ></app-select>
      <app-select
        field="Status"
        property="nome"
        id="Status"
        [options]="status"
        [selected]="statusSelected"
        [multiControl]="form.controls.status"
        [multi]="true"
      ></app-select>
    </div>
    <div class="filter-button">
      <app-primary-button
        label="FILTRAR"
        [color]="ButtonColorEnum.primary"
        [loading]="false"
        [enabled]="true"
        icon="../../../assets/icons/search.svg"
        (click)="submit()"
      ></app-primary-button>
    </div>
  </section>
  <div class="skeleton" body>
    <app-skeleton-input
      [input]="6"
      *ngIf="isLoadingFilter"
    ></app-skeleton-input>
  </div>
</app-card>
