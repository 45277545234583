/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import {
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { Utils } from 'src/app/common/utils/utils';
import { UnitFixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/unitFixedCosts.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import { UpdateFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-fixedCost.model';
import { UpdateSolicitationsFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-solictiationsFixedCost.model';
import * as FixcedCostActions from '../../../common/store/fixedCost/fixedCost.actions';

@Component({
  selector: 'app-fixed-cost-unit',
  templateUrl: './fixed-cost-unit.component.html',
  styleUrls: ['./fixed-cost-unit.component.scss'],
})
export class FixedCostUnitComponent implements OnInit, OnDestroy {
  public statusColorEnum = StatusColorEnum;
  public ebuttonColor = ButtonColorEnum;
  public unit: UnitFixedCostsModel = new UnitFixedCostsModel();
  private destroy$ = new Subject<void>();
  public fixedCostLoading: boolean = false;
  public showSkeleton = false;
  public showNotFound = false;
  public solicitations: UpdateSolicitationsFixedCostModel[] = [];
  public unSubscribe!: Subscription;

  constructor(
    public util: Utils,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
    }>
  ) {}

  categoriesStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost.categories));
  fixedCostStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost));
  solicitationStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.updateSolicitation));

  loadingStore$ = this.store.select('loadingFixedCost').pipe((x) => x);

  ngOnInit(): void {
    this.showSkeleton = true;
    this.route.paramMap.subscribe((params) => {
      const categoryId = params.get('categoryId');
      this.unSubscribe = this.categoriesStore$.subscribe((categories) => {
        if (categories) {
          const category = categories.find(
            (categori) =>
              categori.categoryPimsId === parseInt(categoryId ?? '0')
          );
          const unitCategory = category ? category.units : [];
          const unitId = params.get('unitId');
          this.unit =
            unitCategory.find((unit) => unit.instanceId === unitId) ??
            new UnitFixedCostsModel();

          this.solicitationStore$.subscribe((solicitations) => {
            if (solicitations) {
              this.solicitations = solicitations.solicitations;
            }
          });
          this.showSkeleton = false;
        } else {
          const fixedCostId = params.get('id');
          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.fixedCostLoading = isLoading.loading;
            });
          if (!this.fixedCostLoading) {
            this.fixedCostStore$
              .pipe(takeUntil(this.destroy$))
              .subscribe((x) => {
                if (
                  (!x.fixedCostId || x.fixedCostId !== +(fixedCostId ?? 0)) &&
                  fixedCostId !== null
                ) {
                  this.store.dispatch(
                    FixcedCostActions.loadFixedCostsModel({
                      id: parseInt(fixedCostId ?? '0'),
                    })
                  );
                }
              });
          }
        }
      });
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendBack() {
    const url = `${this.router.url}`;
    this.router.navigate([url.slice(0, url.lastIndexOf('unit/'))]);
  }
  getStatusColor(status: string) {
    switch (status) {
      case 'Concluido':
        return this.statusColorEnum.success;
      default:
        return this.statusColorEnum.attention;
    }
  }

  getStatusIcon(status: string) {
    switch (status) {
      case 'Concluido':
        return '../../../../assets/icons/Success-green.svg';
      default:
        return '../../../../assets/icons/Timer Yellow Outline.svg';
    }
  }
  getCrurrentValue() {
    let currentValue = 0;
    this.solicitations.forEach((sol) => {
      currentValue +=
        this.unit.fixedCostSolicitationPendings.find(
          (item) => item.solicitationId === sol.solicitationId && sol.approved
        )?.negotiatedValue ?? 0;
      currentValue +=
        this.unit.fixedCostSolicitationsSents.find(
          (item) => item.solicitationId === sol.solicitationId && sol.approved
        )?.negotiatedValue ?? 0;
    });
    return currentValue;
  }
}
