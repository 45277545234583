import { StatusColorEnum } from './../../../../../shared/enums/StatusColor';
/* eslint-disable linebreak-style */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';

@Component({
  selector: 'app-operator-list-item',
  templateUrl: './operator-list-item.component.html',
  styleUrls: ['./operator-list-item.component.scss'],
})
export class OperatorListItemComponent implements OnInit {
  @Input() operatorList!: GetOperatorModel[];

  public StatusColorEnum = StatusColorEnum;
  constructor(public util: Utils, private router: Router) {}

  ngOnInit(): void {}

  handleDataClick(id: string) {
    this.router.navigate(['/admin/operator/', id]);
  }
}
