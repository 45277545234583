<div class="search-container" [ngClass]="{ error: error }">
  <div class="label-group">
    <label class="input" [ngClass]="{ loading: loading }">
      {{ title }}
    </label>
  </div>
  <div
    class="input-box"
    [ngClass]="{ disabled: disabled }"
    (keyup.enter)="handleSearchClick()"
  >
    <div
      class="element-container"
      [ngClass]="{
        loading: loading,
        'apply-border': selected,
        disabled: disabled
      }"
    >
      <div
        *ngIf="selected"
        [ngClass]="{ 'card-loading': loadingCard }"
        class="card-selected"
        (click)="CardClick()"
      >
        <div id="card" class="card-content" *ngIf="items.length === 0">
          <span>{{ item }}</span>
          <div class="cross-img" id="cross">
            <div *ngIf="loadingCard" class="btn-loading"></div>
            <img
              *ngIf="!loadingCard"
              (click)="handleCrossClick()"
              src="../../../assets/icons/exclude-primary-600.svg"
            />
          </div>
        </div>
        <div
          id="card"
          class="card-content"
          *ngFor="let valueItem of items; let i = index"
        >
          <div ngClass="card-item" [id]="'card-item-' + i">
            <span>{{ valueItem }}</span>
            <div class="cross-img" [id]="'cross-' + i">
              <div *ngIf="loadingCard" class="btn-loading"></div>
              <img
                *ngIf="!loadingCard"
                (click)="handleCrossClick()"
                src="../../../assets/icons/exclude-primary-600.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <input
        *ngIf="!selected"
        type="text"
        placeholder="{{ placeholder }}"
        [formControl]="searchControl"
        maxlength="10"
      />
      <div
        *ngIf="!selected"
        class="search-img"
        [ngClass]="{ selected: selected }"
        (click)="handleSearchClick()"
      >
        <div class="search-loading" *ngIf="!selected && loading"></div>
        <img
          id="search"
          *ngIf="!selected && !loading"
          src="../../../assets/icons/search.svg"
        />
      </div>
    </div>
  </div>
  <div
    class="error-info"
    [ngStyle]="{ visibility: error ? 'visible' : 'hidden' }"
  >
    <img src="../../../assets/icons/error-warning-600.svg" alt="Error icon" />
    <span>{{ errorMessage }}</span>
  </div>
</div>
<app-search-modal
  [showModal]="showModal"
  [searchModel]="searchModel"
  (handleCardClick)="CardClick()"
  (outsideClick)="HandleOutsideClick()"
></app-search-modal>
