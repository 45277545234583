<div [class]="(disabled ? 'disabled ' : '') + 'toogle-container'">
  <label class="toogle">
    <input
      id="{{ toggleId }}"
      type="checkbox"
      (change)="onCheck()"
      [disabled]="disabled"
    />
    <span class="checkbox"></span>
    {{ label }}
  </label>
</div>
