<div class="textarea-group" [ngClass]="{ error: error, disabled: disabled }">
  <div class="label-group">
    <label>{{ label }}</label>
    <small>{{ description }}</small>
    <span *ngIf="!disabled && maxlength > 0">
      {{ control.value?.length + "/" + maxlength }}
    </span>
  </div>
  <div class="text-area-content">
    <img [src]="
        error
          ? '../../../assets/icons/resize-warning-600.svg'
          : disabled
          ? '../../../assets/icons/resize-common-400.svg'
          : '../../../assets/icons/resize-common-600.svg'
      " alt="Resize icon" />
    <textarea [formControl]="control" [placeholder]="placeholder" [maxlength]="maxlength"></textarea>
  </div>
  <div class="error-info" [ngStyle]="{'visibility': error ? 'visible': 'hidden'}">
    <img src="../../../assets/icons/error-warning-600.svg" alt="Error icon" />
    <span>{{ errorMessage }}</span>
  </div>
</div>
