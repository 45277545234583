<div class="container">
<app-tab>
  <app-tab-item title="Solicitação">
    <app-solicitation-new></app-solicitation-new>
  </app-tab-item>
  <app-tab-item title="Historico" *ngIf="hasId">
    <app-solicitation-history></app-solicitation-history>
  </app-tab-item>
  <app-tab-item title="Aprovações" *ngIf="hasId">
    <app-solicitation-approvement></app-solicitation-approvement>
  </app-tab-item>
</app-tab>
</div>
