<div class="grid-items">
  <div
    *ngFor="let item of unitCategories"
    class="grid-content"
    (click)="navigate(item.categoryId)"
  >
    <label id="id">{{ item.categoryId }}</label>
    <label id="name">{{ item.categoryName }}</label>
    <label id="opl">{{
      item.operatorName ? item.operatorName.toUpperCase() : ""
    }}</label>
    <div id="status">
      <app-status
        [color]="item.active ? StatusColorEnum.success : StatusColorEnum.warning"
        [image]="
          item.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="item.active ? 'Ativo' : 'Inativo'"
      >
      </app-status>
    </div>
  </div>
</div>
