<div class="base">
  <div class="container">
    <app-card
      title="Operação #{{operationId}}"
      icon="../../../../assets/icons/solicitation-primary-600.svg"
      [accordion]="false"
      [paddingHorizontal]="true"
    >
      <div class="container" body>
        <div class="control-container">
          <app-toogle
            [checked]="formOperation.controls.active.value ?? false"
            [label]="'Ativo'"
            (check)="onCheck()"
          ></app-toogle>
        </div>
        <div class="input-container">
          <app-input
            [label]="'CD OPERAÇÃO'"
            [control]="formOperation.controls.cd_operation"
            [disabled]="true"
          ></app-input>
          <app-input
            [label]="'Descrição'"
            [control]="formOperation.controls.name"
            [disabled]="true"
          ></app-input>
        </div>
        <div class="buttons-container">
          <app-secondary-button
            [label]="'VOLTAR'"
            [enabled]="true"
            [icon]="'../../../../../../../assets/icons/arrow-previous-primary-600.svg'"
            (clickButton)="return()"
          ></app-secondary-button>
          <app-primary-button
            [label]="'SALVAR'"
            [loading]="updateLoading"
            [enabled]="!updateLoading"
            (clickButton)="onSave()"
          ></app-primary-button>
        </div>
      </div>
    </app-card>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
