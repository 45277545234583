import { SupportTeamDetailModel } from "./support-team-detail.model";
import { SupportTeamTotalizerModel } from "./support-team-totalizer.model";

export class AdminFinancialModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.total = new SupportTeamTotalizerModel();
    this.maintenanceAnalyst = new SupportTeamDetailModel();
    this.adminAndFinancialAssistant = new SupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  maintenanceAnalyst: SupportTeamDetailModel = new SupportTeamDetailModel();
  adminAndFinancialAssistant: SupportTeamDetailModel = new SupportTeamDetailModel();
}
