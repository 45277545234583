import { ButtonColorEnum } from "src/app/shared/buttons/enums/button-color.enum";

export class ModalConfirmationModel {
  title: string = '';
  description: string = '';
  confirmLabel : string = '';
  confirmIcon: string = '';
  confirmColor: ButtonColorEnum = ButtonColorEnum.common;
  showModal: boolean = false;
}

export class ModalConfirmationAttachModel extends ModalConfirmationModel {
  attachLabel: string = '';
  attachDescription: string = '';
  attachExtensions: string = '';
  attachTotalSize: number = 0;
}
