import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputTypeEnum } from './enums/input-type.enum';
import { MaskTypeEnum } from './enums/mask-type.enum';
import { SideIconTypeEnum } from './enums/side-icon-type.enum';
import { PasswordValidator } from './helper/password.validator';
import { PasswordValidationModel } from './model/passwordValidation.model';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges {
  @Input() control = new FormControl('');

  // Title Label
  @Input() showTitle: boolean = true;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() description: string = '';
  @Input() showMaxLength: boolean = true;
  @Input() maxlength!: number;

  // error
  @Input() enableMessageError: boolean = true;
  @Input() errorMessage: string = '';

  // Mask
  @Input() isNegative: boolean = false;
  @Input() mask!: MaskTypeEnum;
  @Input() max: number = 0;
  @Input() min: number = 0;
  @Input() showNumberActions: boolean = true;
  @Input() numberActionsWidth: string = '2.5rem';

  // Aux
  @Input() pattern!: string;
  @Input() type: InputTypeEnum = InputTypeEnum.text;
  @Input() disabled: boolean = false;
  @Input() passwordValidation: boolean = false;
  @Input() search: boolean = false;
  @Input() loading: boolean = false;
  @Input() flatLoading!: boolean;
  @Input() searchError: boolean = false;
  @Input() height: string = '3rem';
  @Input() validated!: boolean;

  // Side Icon
  @Input() sideIconLabel: string = '';
  @Input() sideIconType: SideIconTypeEnum = SideIconTypeEnum.text;
  @Input() showSideIcon: boolean = false;

  @Output() passValid: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter();
  @Output() searchClick: EventEmitter<boolean> = new EventEmitter();
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  public inputFocused: boolean = false;

  public error: boolean = false;
  public eSideIconTypeEnum = SideIconTypeEnum;
  public eInputTypeEnum = InputTypeEnum;
  public PasswordValidModel = new PasswordValidationModel();
  public eMaskTypeEnum = MaskTypeEnum;
  public numberPatterns = {
    9: { pattern: /-/ },
    0: { pattern: /[0-9]/ },
  };
  public moneyMaskConfig = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    align: 'left',
    nullable: true,
    allowNegative: this.isNegative,
    max: 9999999999.99,
    min: -9999999999.99,
  };

  focusInput() {
    this.inputFocused = !this.inputFocused;

    if (!this.inputFocused) this.updateValue.emit();
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.control.disable();
    } else if (!this.disabled && !this.control.enabled) {
      this.control.enable();
    }
    if (this.searchError) {
      this.error = this.searchError;
    }
    this.moneyMaskConfig.allowNegative = this.isNegative;
  }

  ngOnInit(): void {
    this.control.statusChanges.subscribe(this.controlStatusChanges.bind(this));

    if (this.type === InputTypeEnum.password) {
      this.control.valueChanges.subscribe(
        this.passwordControlValueChanges.bind(this),
      );
    }
    if (this.mask === MaskTypeEnum.number) {
      this.control.valueChanges.subscribe(
        this.numberControlValueChanges.bind(this),
      );
    }
  }

  controlStatusChanges() {
    if (this.type === InputTypeEnum.password) {
      if (this.control.value !== '') {
        this.error = false;
        return;
      }
    }
    this.error = this.control.status === 'INVALID';
  }

  passwordControlValueChanges() {
    this.PasswordValidModel = PasswordValidator.strong(this.control);
    this.passValid.emit(this.PasswordValidModel.valid);
  }

  numberControlValueChanges() {
    const value = +(this.control.value ?? 0);

    if (value > this.max) {
      this.control.patchValue(`${this.max}`);
    } else if (value < this.min) {
      this.control.patchValue(`${this.min}`);
    }
  }

  selectMask() {
    switch (this.mask) {
      case MaskTypeEnum.cnpj:
        return '00.000.000/0000-00';
      case MaskTypeEnum.cpf:
        return '000.000.000-00';
      case MaskTypeEnum.cep:
        return '00000-000';
      case MaskTypeEnum.phone:
        return '(00) 00000-0000';
      case MaskTypeEnum.number:
        return this.min < 0 ? '9?00*' : '0*';
      case MaskTypeEnum.username:
        return 'AA999999999999';
      case MaskTypeEnum.decimal:
        return 'separator.2';
      case MaskTypeEnum.monthYear:
        return '00/0000';
      default:
        return '';
    }
  }

  handleSearchClick() {
    this.searchClick.emit(!this.disabled);
  }

  increment() {
    if (this.disabled) return;

    const value = +(this.control.value ?? 0);
    this.control.patchValue(`${value >= this.max ? this.max : value + 1}`);
    this.updateValue.emit();
  }

  decrement() {
    if (this.disabled) return;

    const value = +(this.control.value ?? 0);
    this.control.patchValue(`${value <= this.min ? this.min : value - 1}`);
    this.updateValue.emit();
  }

  onChange() {
    this.valueChange.emit(this.control.value);
  }
}
