import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { GetSubcategoryModel } from 'src/app/services/subcategory/models/get-subcategory-model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-subcategory-list-card',
  templateUrl: './subcategory-list-card.component.html',
  styleUrls: ['./subcategory-list-card.component.scss'],
})
export class SubcategoryListCardComponent {
  @Input() subcategoriesList: GetSubcategoryModel[] = [];

  public StatusColorEnum = StatusColorEnum;

  constructor(
    public util: Utils,
    private router: Router,
  ) { }

  handleDataClick(id:number) {
    this.router.navigate(['/admin/subcategory/', id]);
  }
}
