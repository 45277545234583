import { Utils } from 'src/app/common/utils/utils';
/* eslint-disable no-param-reassign */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { FleetModel } from 'src/app/services/dfdv/fleet/models/fleet.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';
import { ConsumptionFleetModel } from 'src/app/services/dfdv/variable-costs/models/fleet/consumption-fleet.model';
import { MaintenanceFleetModel } from 'src/app/services/dfdv/variable-costs/models/fleet/maintenance-fleet.model';
import { TruckModel } from 'src/app/services/dfdv/variable-costs/models/fleet/truck.models';
import { TiresVariableCostsModel } from 'src/app/services/dfdv/variable-costs/models/tires/tiresVariableCosts.model';
import {
  CVMaintenanceModel,
  CVTireModel,
} from 'src/app/services/dfdv/variable-costs/models/variable-costs.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { TabItemComponent } from 'src/app/shared/tab/tab-item/tab-item.component';
import { TabComponent } from 'src/app/shared/tab/tab.component';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { CVConsumptionModel } from '../../../../services/dfdv/variable-costs/models/variable-costs.model';

@Component({
  selector: 'app-variable-costs',
  templateUrl: './variable-costs.component.html',
  styleUrls: ['./variable-costs.component.scss'],
})
export class VariableCostsComponent implements OnInit, OnDestroy {
  @ViewChild('tabVariableCost') tabVariableCost!: TabComponent;
  @ViewChild('tabFleet') tabFleet!: TabItemComponent;
  @ViewChild('tabMaintenance') tabMaintenance!: TabItemComponent;
  @ViewChild('tabConsumption') tabConsumption!: TabItemComponent;
  @ViewChild('tabTyres') tabTyres!: TabItemComponent;

  // Forms
  public maintenanceForm = this.formBuilder.group({
    tractorOwnMaintenance: [''],
    tractorFactoryMaintenance: [''],
    trailerMaintenance: [''],
    kmPlanned: [''],
  });

  public consumptionForm = this.formBuilder.group({
    tractorConsumption: [''],
  });

  public tiresForm = this.formBuilder.group({
    tireBrand: [''],
    tireTypeName: [''],
    tireQuantity: [''],
    tireCost: [''],
    retreadingQuantity: [''],
    retreadingCost: [''],
    tireLifespanKm: [''],
  });

  // Models
  public fleetStore: FleetModel = new FleetModel();
  public scopeStore: ScopeDfdvModel = new ScopeDfdvModel();
  public consumptionFleet: ConsumptionFleetModel[] = [];
  public maintenanceFleet: MaintenanceFleetModel[] = [];
  public truck: TruckModel[] = [];
  public tiresVariableCostsModel: TiresVariableCostsModel =
    new TiresVariableCostsModel();

  // Enums
  public ButtonColorEnum = ButtonColorEnum;

  // Variables
  public isLoading: boolean = false;
  public disableFieldsMaintenance: boolean = false;
  public disableFieldKMMaintenance: boolean = false;
  public disableFieldConsumption: boolean = false;
  public disableFieldsTires: boolean = false;
  public disableFieldQuantityTires: boolean = false;
  public shouldDisableFields: boolean = false;
  public disableByStatus: boolean = false;
  public disableFieldOPL: boolean = false;
  public disableFieldRaizen: boolean = false;
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public tabError: string = '';
  public dfdvUtils!: DfdvUtils;
  public tireTypeSelected: string[] = [];
  public unSubscribe!: Subscription;

  public Utils: Utils = new Utils();

  // Totals
  public unitCostTotal: number = 0;
  public subtotalWithFix: number = 0;
  public unitCostOfTiresPerKm: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    public utils: Utils,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) { }

  cvMaintenanceStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv?.cvMaintenance));
  cvConsumptionStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv?.cvConsumption));
  cvTireStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv?.cvTire));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.handleChangesDFDVStore();
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.isLoading = true;

    this.unSubscribe = this.route.paramMap.subscribe((params) => {
      const id = params.get('id');

      this.loadingStore$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isLoading) => {
          this.isLoading = isLoading.loading;
        });

      if (!this.isLoading) {
        this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
          if (!x?.dfdvId || x?.dfdvId !== +(id ?? 0)) {
            this.store.dispatch(
              DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
            );
          }
        });
      }
    });
  }

  handleChangesDFDVStore() {
    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv?.dfdvId) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole(),this.store);
        const isNewOrDraft = this.dfdvUtils.isDraft() || this.dfdvUtils.isNew();
        this.disableByStatus =
          !this.dfdvUtils.isPreliminary() &&
          !this.dfdvUtils.isDraft() &&
          !this.dfdvUtils.isNew();

        this.disableFieldsMaintenance = this.disableByStatus
          || this.dfdvUtils.isPreliminary() && this.dfdvUtils.isUserRaizen()
          || isNewOrDraft && !this.dfdvUtils.isUserRaizen();

        this.disableFieldKMMaintenance = this.disableByStatus
          || (this.dfdvUtils.isPreliminary() || isNewOrDraft) && !this.dfdvUtils.isUserRaizen();

        this.disableFieldConsumption = this.disableByStatus || (
          this.dfdvUtils.isUserRaizen()
            ? this.dfdvUtils.isPreliminary()
            : this.dfdvUtils.isDraft() || this.dfdvUtils.isNew()
        );

        this.disableFieldsTires = this.disableByStatus
          || (isNewOrDraft && !this.dfdvUtils.isUserRaizen())
          || (this.dfdvUtils.isPreliminary() && this.dfdvUtils.isUserRaizen());

        this.disableFieldQuantityTires = this.disableByStatus
          || this.dfdvUtils.isPreliminary() && !this.dfdvUtils.isUserRaizen()
          || isNewOrDraft && !this.dfdvUtils.isUserRaizen();


        this.setCVMaintenanceForm(dfdv.cvMaintenance);
        this.setCVConsumptionForm(dfdv.cvConsumption);
        this.setCVTireForm(dfdv.cvTire);
        this.calculateCVTiresTotals();
      }
    });
  }

  calculateCVTiresTotals() {
    const { tireCost, retreadingCost, retreadingQuantity, tireLifespanKm, tireQuantity } = this.dfdv.cvTire;

    this.unitCostTotal = tireCost + retreadingCost * retreadingQuantity;
    this.subtotalWithFix = tireLifespanKm === 0 ? 0 : this.unitCostTotal / tireLifespanKm;
    this.unitCostOfTiresPerKm = this.subtotalWithFix * tireQuantity;
  }

  setCVMaintenanceForm(maintenance: CVMaintenanceModel) {
    const maintenanceControls = this.maintenanceForm.controls;
    maintenanceControls.tractorOwnMaintenance.patchValue(
      this.getValueString(maintenance.tractorOwnMaintenance, '0')
    );
    maintenanceControls.trailerMaintenance.patchValue(
      this.getValueString(maintenance.trailerMaintenance, '0')
    );
    maintenanceControls.tractorFactoryMaintenance.patchValue(
      this.getValueString(maintenance.tractorFactoryMaintenance, '0')
    );
    maintenanceControls.kmPlanned.patchValue(
      this.getValueString(maintenance.kmPlanned, '0')
    );
  }

  setCVConsumptionForm(consumption: CVConsumptionModel[]) {
    const firstYear = consumption.find((x) => x.contractYear === 1);
    const initialTractorConsumption = this.getValueString(
      firstYear?.tractorConsumption,
      '0'
    );
    this.consumptionForm.controls.tractorConsumption.patchValue(
      initialTractorConsumption
    );

    this.updateDfdvCVConsumption();
  }

  setCVTireForm(tire: CVTireModel) {
    const tiresControls = this.tiresForm.controls;

    tiresControls.tireBrand.patchValue(tire.tireBrand);
    if (tiresControls.tireTypeName.value !== tire.tireTypeName) {
      tiresControls.tireTypeName.patchValue(tire.tireTypeName);
    }
    tiresControls.tireQuantity.patchValue(
      this.getValueString(tire.tireQuantity, '0')
    );
    tiresControls.tireCost.patchValue(this.getValueString(tire.tireCost, '0'));
    tiresControls.retreadingQuantity.patchValue(
      this.getValueString(tire.retreadingQuantity, '0')
    );
    tiresControls.retreadingCost.patchValue(
      this.getValueString(tire.retreadingCost, '0')
    );
    tiresControls.tireLifespanKm.patchValue(
      this.getValueString(tire.tireLifespanKm, '0')
    );

    if (tire.tireTypeName) {
      this.tireTypeSelected = [];
      this.tireTypeSelected.push(tire.tireTypeName);
    }
  }

  getValueString(value?: any, defaultValue: string = ''): string {
    return value?.toString() ?? defaultValue;
  }

  getValueNumber(value?: any): number {
    return Number(value) ?? 0;
  }

  formatHarvestYear(year: number) {
    const currentYear = year.toString();
    const nextYear = (year + 1).toString();
    return `${currentYear.substring(
      2,
      currentYear.length
    )}'${nextYear.substring(2, nextYear.length)}`;
  }

  updateDfdvCVMaintenance() {
    const maintenanceControls = this.maintenanceForm.controls;
    this.dfdv.cvMaintenance = {
      tractorOwnMaintenance: this.getValueNumber(
        maintenanceControls.tractorOwnMaintenance.value
      ),
      trailerMaintenance: this.getValueNumber(
        maintenanceControls.trailerMaintenance.value
      ),
      tractorFactoryMaintenance: this.getValueNumber(
        maintenanceControls.tractorFactoryMaintenance.value
      ),
      kmPlanned: this.getValueNumber(maintenanceControls.kmPlanned.value),
    };
  }

  updateDfdvCVConsumption() {
    const hasConsumption = this.dfdv.cvConsumption?.length;
    const consumptionControls = this.consumptionForm.controls;
    const startYear = this.dfdv?.startDate ? new Date(this.dfdv?.startDate).getFullYear() : 0;
    const endYear = this.dfdv?.endDate ? new Date(this.dfdv?.endDate).getFullYear() : 0;
    const numberOfYears = endYear - startYear;

    const copyFirstYear: CVConsumptionModel = {
      contractYear: 1,
      harvestYear: hasConsumption
        ? this.dfdv.cvConsumption.find((x) => x.contractYear === 1)!.harvestYear
        : '',
      tractorConsumption: this.getValueNumber(
        consumptionControls.tractorConsumption.value
      ),
    };

    const nextYears = hasConsumption ? [] as CVConsumptionModel[] : [];
    const newConsumption = nextYears;

    newConsumption.unshift(copyFirstYear);
    const cvConsumption = JSON.parse(JSON.stringify(newConsumption)) as CVConsumptionModel[];

    let { tractorConsumption } = newConsumption[0];
    for (let index = 1; index < numberOfYears; index++) {
      tractorConsumption *= index % 2 === 0 ? 0.98 : 1;
      cvConsumption.push({
        contractYear: (index + 1),
        harvestYear: this.formatHarvestYear((startYear + index)),
        tractorConsumption: +(tractorConsumption.toFixed(2)),
      } as CVConsumptionModel);
    }

    this.dfdv.cvConsumption = cvConsumption;
  }

  updateDfdvCVTire() {
    const tireControls = this.tiresForm.controls;
    this.dfdv.cvTire = {
      tireBrand: this.getValueString(tireControls.tireBrand.value),
      tireTypeName: this.getValueString(tireControls.tireTypeName.value),
      tireQuantity: this.getValueNumber(tireControls.tireQuantity.value),
      tireCost: this.getValueNumber(tireControls.tireCost.value),
      retreadingQuantity: this.getValueNumber(
        tireControls.retreadingQuantity.value
      ),
      retreadingCost: this.getValueNumber(tireControls.retreadingCost.value),
      tireLifespanKm: this.getValueNumber(tireControls.tireLifespanKm.value),
    };
  }

  updateDFDVStore() {
    this.updateDfdvCVConsumption();
    this.updateDfdvCVMaintenance();
    this.updateDfdvCVTire();

    this.store.dispatch(
      DfdvActions.updateDfdvObject({
        data: this.dfdv,
      })
    );
    this.dfdvUtils.updateFleetDfdv();
  }

  handleToogleValue(toogle: any) {
    this.truck[toogle?.index].active = toogle?.value;
  }
}
