<div class="container">
  <section>
    <app-card
        [accordion]="true"
        [icon]="'../../../../assets/icons/Curve-Arrow-Purple.svg'"
        [title]="'Lançamento Custo Fixo'"
        [paddingHorizontal]="false"
        [accordion]="false"
      >
      <section actions class="actions" >
        <app-icon-button
          (clickButton)="getFixedCostReportDownload()"
          icon="../../../../assets/icons/spreadsheet-download.svg"
          text="Baixar"
          [enabled]="enabledReport"
          [loading]="loadingReport"
          [color]="ebuttonColor.primary"
        ></app-icon-button>
        <app-icon-button
          (clickButton)="concludeFixedCosts()"
          icon="../../../../assets/icons/Success-green.svg"
          text="Concluir"
          [enabled]="enabledConclude"
          [loading]="loadingConclude"
          [color]="ebuttonColor.success"
        ></app-icon-button>
      </section>
      <div
        body
        class="body">
        <div class="actions-list">
          <app-icon-button
            (clickButton)="getFixedCostReportDownload()"
            icon="../../../../assets/icons/spreadsheet-download.svg"
            text="Baixar"
            [enabled]="enabledReport"
            [loading]="loadingReport"
            [color]="ebuttonColor.primary"
          ></app-icon-button>
          <app-icon-button
            (clickButton)="concludeFixedCosts()"
            icon="../../../../assets/icons/Success-green.svg"
            text="Concluir"
            [enabled]="enabledConclude"
            [loading]="loadingConclude"
            [color]="ebuttonColor.success"
          ></app-icon-button>
        </div>
        <div class="fixedCost">
          <app-input
            class="periodToSend"
            [label]="'Período'"
            [disabled]="true"
            [control]="form.controls.date"
            [type]="inputTypeEnum.text">
          </app-input>
          <app-primary-button
            class="buttonSend"
            [label]="'ENVIAR'"
            [loading]="loading"
            [enabled]="enabledSend"
            (clickButton)="handleSend()"
          ></app-primary-button>
        </div>
      </div>
    </app-card>
    <router-outlet></router-outlet>
  </section>
</div>
<app-modal-confirmation
  [showModal]="modalConfirmationModel.showModal"
  [title]="modalConfirmationModel.title"
  [description]="modalConfirmationModel.description"
  (confirmFunc)="clickModal(true)"
  (cancelFunc)="clickModal(false)"
  confirmLabel="Confirmar"
  confirmIcon="assets/icons/approve-common-100.svg"
  [confirmColor]="eButtonsColor.success"
></app-modal-confirmation>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal(false)"
></app-modal>
