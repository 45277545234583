import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { Utils } from 'src/app/common/utils/utils';
import { DfdvDetailModel } from 'src/app/services/dfdv/models/dfdv-detail.model';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-dfdv-detail',
  templateUrl: './dfdv-detail.component.html',
  styleUrls: ['./dfdv-detail.component.scss'],
})
export class DfdvDetailComponent implements OnInit {
  @Input() isHeader: boolean = false;
  @Input() dfdv = new DfdvDetailModel();
  @Input() isPairRow: boolean = false;
  @Input() isUserRaizen: boolean = false;
  @Input() approvalVisible: boolean = false;
  @Output() check: EventEmitter<any> = new EventEmitter();
  @Output() removeCheck: EventEmitter<any> = new EventEmitter();

  public breakpointBase: number = 1025;
  public widthBreakpoint: number = 0;
  public isNegative: boolean = false;
  public isVarible: boolean = false;

  public get StatusColorEnum() {
    return StatusColorEnum;
  }

  public DfdvStatusEnum = DfdvStatusEnum;

  constructor(
    public util: Utils,
    private router: Router,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngOnInit(): void {}

  viewDfdv(dfdvId: number) {
    this.store.dispatch(DfdvActions.clearDfdvObject());
    this.router.navigate([`/dfdv/${dfdvId}`]);
  }
  onCheck() {
    this.dfdv.checked = true;
    this.check.emit(this.dfdv);
  }

  onRemoveCheck() {
    this.dfdv.checked = false;
    this.removeCheck.emit(this.dfdv);
  }

  isDisable() {
    return this.dfdv?.dfdvStatusName === DfdvStatusEnum.Canceled;
  }

  getDfdvStatusColor(status: string) {
    switch (status) {
      case DfdvStatusEnum.Draft:
        return StatusColorEnum.teal;
      case DfdvStatusEnum.Preliminary:
        return StatusColorEnum.indigo;
      case DfdvStatusEnum.Pending:
        return StatusColorEnum.attention;
      case DfdvStatusEnum.Reproved:
        return StatusColorEnum.warning;
      case DfdvStatusEnum.Archived:
        return StatusColorEnum.common;
      case DfdvStatusEnum.Current:
        return StatusColorEnum.primary;
      case DfdvStatusEnum.New:
        return StatusColorEnum.secondary;
      case DfdvStatusEnum.Canceled:
        return StatusColorEnum.rose;
      default:
        return StatusColorEnum.success;
    }
  }

  getDfdvTypeColor(status: string) {
    switch (status) {
      case 'Cotação':
        return StatusColorEnum.attention;
      case 'Efetivo':
        return StatusColorEnum.success;
      default:
        return StatusColorEnum.primary;
    }
  }
}
