import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { OfficeExpensesModel } from 'src/app/services/dfdv/general-costs/models/office-expenses/office-expenses.model';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { GeneralCostUtils } from '../general-cost.utils';

@Component({
  selector: 'app-office-expenses',
  templateUrl: './office-expenses.component.html',
  styleUrls: ['./office-expenses.component.scss'],
})
export class OfficeExpensesComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() hasError:boolean = false;
  @Input() harvestMonths: number = 0;
  @Input() shouldDisableFields: boolean = false;
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Mobile Control
  public card: boolean = false;
  public widthBreakpoint: number = 768;
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  public officeExpensesModel = this.formBuilder.group({
    rows: this.formBuilder.array([this.buildRow()]),
  });

  public height: string = '2.5rem';
  public numberActionsWidth: string = '2rem';
  public eMaskType = MaskTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;

  public utils: GeneralCostUtils = new GeneralCostUtils();

  public rowList: string[] = [
    'Comunicação Móvel Adminitrativo',
    'Material Escritório',
    'Telefone (aparelhos)',
    'Manutenção Sistemas',
    'Internet/Provedor',
  ];
  public header: string[] = [
    'DESCRIÇÃO',
    'QUANTIDADE',
    'CUSTO UN. MENSAL',
    'CUSTO MENSAL',
    'CUSTO SAFRA',
  ];

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.card = window.innerWidth < this.widthBreakpoint;
    });
    this.buildTable();
  }

  buildTable() {
    Object.keys(new OfficeExpensesModel()).forEach(() => {
      this.officeExpensesModel.controls.rows.push(this.buildRow());
    });
    this.officeExpensesModel.controls.rows.removeAt(0);
  }

  buildRow() {
    return this.formBuilder.group({
      quantity: [''],
      cost: [''],
    });
  }

  calcTotal(harvest: boolean) {
    let total = 0;
    this.officeExpensesModel.controls.rows.controls.forEach((element) => {
      const cost = element.get('cost')?.value ?? '0';
      const quantity = element.get('quantity')?.value ?? '0';
      const result = +cost * +quantity;
      total += result;
    });

    if (harvest) {
      return total * this.harvestMonths;
    }
    return total;
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
