export class FleetTrailerModel {
  [key: string]: any;

  trailerFinancingStatus!: string;
  trailerImplement!: string;
  trailerMaterial!: string;
  trailerType!: string;
  trailerCost!: number;
  trailerTireCost!: number;
  trailerBaseProfit!: number;
  trailerPisCofinsCost!: number;
  trailerIcmsCost!: number;
  trailerNetValueDepreciation!: number;
  trailerNetValueRemuneration!: number;
  trailerYear!: number;
  trailerAcquisitionDate!: Date;
  trailerDepreciationPeriod!: number;
  trailerUsageCycle!: number;
  trailerResidual!: number;
}
