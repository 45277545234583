import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { GetFixedCostFilterModel } from 'src/app/services/fixedCosts/models/get-fixedCostFilter.model';
import { StorageService } from 'src/app/common/services/storage.service';

@Component({
  selector: 'app-fixed-costs-filter',
  templateUrl: './fixed-costs-filter.component.html',
  styleUrls: ['./fixed-costs-filter.component.scss'],
})
export class FixedCostsFilterComponent implements OnInit {
  @Output() filterEvent = new EventEmitter<GetFixedCostFilterModel>();

  // Enum
  public inputTypeEnum = InputTypeEnum;
  public buttonColorEnum = ButtonColorEnum;
  public loading = false;
  public loadedFilterFromStorage: boolean = false;

  public formFilter = this.formBuilder.group({
    id: [''],
    startDate: [''],
    endDate: [''],
  });

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.loadFormFromStorage();
    this.formFilter.valueChanges.subscribe(() => this.setFormValuesInStorage());
  }

  handleFilter() {
    let id;

    if (this.formFilter.controls.id.value) {
      id = +this.formFilter.controls.id.value;
    }

    const filter = {
      fixedCostId: id,
      startDate: this.formFilter.controls.startDate.value ?? '',
      endDate: this.formFilter.controls.endDate.value ?? '',
      pageNumber: 1,
      pageSize: 10,
    } as GetFixedCostFilterModel;

    this.loading = true;
    this.filterEvent.emit(filter);
  }

  clearFilterFields() {
    this.formFilter.patchValue({
      id: '',
      endDate: '',
      startDate: '',
    });
  }

  loadFormFromStorage() {
    const fixedCostFilter = this.storageService.getFixedCostFilterForm();

    if (fixedCostFilter === undefined) {
      return;
    }

    this.formFilter.controls.id.patchValue(
      fixedCostFilter.fixedCostId?.toString() ?? null
    );
    this.formFilter.controls.startDate.patchValue(fixedCostFilter.startDate);
    this.formFilter.controls.endDate.patchValue(fixedCostFilter.endDate);
    this.loadedFilterFromStorage = true;
  }

  setFormValuesInStorage() {
    const fixedCostFilter = new GetFixedCostFilterModel();

    if (
      this.formFilter.controls.id.value !== undefined &&
      this.formFilter.controls.id.value !== null
    ) {
      fixedCostFilter.fixedCostId =
        parseInt(this.formFilter.controls.id.value, 2) ?? undefined;
    }

    fixedCostFilter.startDate = this.formFilter.controls.startDate.value ?? '';
    fixedCostFilter.endDate = this.formFilter.controls.endDate.value ?? '';
    this.storageService.setFixedCostFilterForm(fixedCostFilter);
  }
}
