import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { SupportTeamDetailModel } from 'src/app/services/dfdv/models/support-team/support-team-detail.model';
import { SupportTeamTotalizerModel } from 'src/app/services/dfdv/models/support-team/support-team-totalizer.model';

@Component({
  selector: 'app-support-team-total',
  templateUrl: './support-team-total.component.html',
  styleUrls: ['./support-team-total.component.scss'],
})
export class SupportTeamTotalComponent implements OnInit {
  @Input() totalizer: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  @Input() transportType!: string | null;
  @Input() columns!: [string, SupportTeamDetailModel];
  @Input() showSkeleton: boolean = false;

  public skeleton: boolean = false;

  constructor(public utils: Utils) {}

  ngOnInit(): void {
    this.skeleton = this.showSkeleton;
  }
}
