<div class="container">
  <div class="card-container">
    <app-card
      [title]="'Especificações'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/chart.svg'"
    >
      <section class="specification-card" body>
        <div class="spe_info">
          <span class="spe_title">Sálario Motorista Padrão</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">{{
            operationalTeamModel.driverSalary | currency : "BRL"
          }}</span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Sálario Motorista Noturno</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">{{
            operationalTeamModel.driverSalary | currency : "BRL"
          }}</span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Acordo Coletivo</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">
            {{ utils.numberFormatter(operationalTeamModel.collectiveAgreement, 2, 2) }} %
          </span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Fator Equipe Motorista Padrão</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">
            {{ utils.numberFormatter(operationalTeamModel.standardDriverTeamFactor, 2, 2) }}
          </span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Fator Equipe Motorista Noturno</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">
            {{ utils.numberFormatter(operationalTeamModel.nightDriverTeamFactor, 2, 2) }}
          </span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Horas de Trabalho (ao mês)</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">{{
            operationalTeamModel.workingHours
          }}</span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
        <div class="spe_info">
          <span class="spe_title">Quantidade Total Cavalo Mecânico</span>
          <span class="spe_desc" *ngIf="!dfdvLoading">{{
            operationalTeamModel.tractorQuantity
          }}</span>
          <app-skeleton-line *ngIf="dfdvLoading" [line]="1"></app-skeleton-line>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Totalizadores'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/chart.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="2"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="total-row">
          <div class="total-paycheck">
            <span class="title">
              Contra Cheque + Encargos + Exames + Benefícios
            </span>
            <span class="value end total">
              {{ this.operationalTeamModel.standardDriver.paycheckWithCharges | currency : "BRL" }}
            </span>
            <span class="value end total">
              {{ this.operationalTeamModel.nightDriver.paycheckWithCharges | currency : "BRL" }}
            </span>
          </div>
          <div class="total-paycheck">
            <span class="title">Total Holerite Motorista (S/ Encargos)</span>
            <span class="value end total">
              {{ this.operationalTeamModel.standardDriver.paycheck | currency : "BRL" }}
            </span>
            <span class="value end total">
              {{ this.operationalTeamModel.nightDriver.paycheck | currency : "BRL" }}
            </span>
          </div>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Contra-Cheque'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/hand-money.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="6"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="table">
          <div class="table-row">
            <span class="title">Piso Salarial</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.salaryWithAgreement
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.salaryWithAgreement
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Quantidade Horas "In Intinere"</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.inItinereHours
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.inItinereHours
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Valor Horas "In Intinere"</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.inItinereCost
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.inItinereCost
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Adicional Noturno</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.nightAdditional
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.nightAdditional
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">DSR</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.dsr | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.dsr | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Remuneração Contra-Cheque</span>
            <span class="value end total">{{
              this.operationalTeamModel.standardDriver.paycheckTotal
                | currency : "BRL"
            }}</span>
            <span class="value end total">{{
              this.operationalTeamModel.nightDriver.paycheckTotal
                | currency : "BRL"
            }}</span>
          </div>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Encargos'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/cash-primary-600.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="2"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="table">
          <div class="table-row">
            <span class="title">Provisões e Encargos</span>
            <span class="value end">
              {{ utils.numberFormatter(this.operationalTeamModel.standardDriver.provisionsCharge, 2, 2) }} %
            </span>
            <span class="value end">
              {{ utils.numberFormatter(this.operationalTeamModel.nightDriver.provisionsCharge, 2, 2) }} %
            </span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Encargos</span>
            <span class="value end total">
              {{ this.operationalTeamModel.standardDriver.totalCharge | currency : "BRL" }}
            </span>
            <span class="value end total">
              {{ this.operationalTeamModel.nightDriver.totalCharge | currency : "BRL" }}
            </span>
          </div>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Exames'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/papper.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="2"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="table">
          <div class="table-row">
            <span class="title">Exame Toxicológico</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.toxicologicalExam
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.toxicologicalExam
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Exames</span>
            <span class="value end total">{{
              this.operationalTeamModel.standardDriver.totalToxicologicalExam
                | currency : "BRL"
            }}</span>
            <span class="value end total">{{
              this.operationalTeamModel.nightDriver.totalToxicologicalExam
                | currency : "BRL"
            }}</span>
          </div>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Benefícios'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/add-primary.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="10"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="table">
          <div class="table-row">
            <span class="title">Ticket Alimentação</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.foodTicket
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.foodTicket
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Ticket Refeição</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.mealTicket
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.mealTicket
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Cesta Básica</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.basicBasket
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.basicBasket
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Vale Transporte</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.transportationVoucher
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.transportationVoucher
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Assistência Médica</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.healthCare
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.healthCare
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Seguro de Vida</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.lifeInsurance
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.lifeInsurance
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">PCMSO</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.pcmso | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.pcmso | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">PPRA</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.ppra | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.ppra | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">PLR</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.plr | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.plr | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Benefícios</span>
            <span class="value end total">{{
              this.operationalTeamModel.standardDriver.totalBenefit
                | currency : "BRL"
            }}</span>
            <span class="value end total">{{
              this.operationalTeamModel.nightDriver.totalBenefit
                | currency : "BRL"
            }}</span>
          </div>
        </div>
      </section>
    </app-card>
  </div>

  <div class="card-container">
    <app-card
      [title]="'Remuneração Variável Equipe Linha'"
      [paddingHorizontal]="false"
      [icon]="'../../../../../assets/icons/hand-money.svg'"
    >
      <section class="header-container" body>
        <div class="table-header">
          <span>DESCRIÇÃO</span>
          <span class="end">MOTORISTA PADRÃO</span>
          <span class="end">MOTORISTA NOTURNO</span>
        </div>
      </section>
      <div body class="skeleton-lines" *ngIf="dfdvLoading">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <section body *ngIf="!dfdvLoading">
        <div class="table">
          <div class="table-row">
            <span class="title">Custo/Hora Motorista</span>
            <span class="value end">{{
              this.operationalTeamModel.standardDriver.driverHoursCost
                | currency : "BRL"
            }}</span>
            <span class="value end">{{
              this.operationalTeamModel.nightDriver.driverHoursCost
                | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row">
            <span class="title">Horas (Itens Quantitativos)</span>
            <span class="value end">
              {{ utils.numberFormatter(operationalTeamModel.standardDriver.quantitativeHours, 2, 2) }}
            </span>
            <span class="value end">
              {{ utils.numberFormatter(operationalTeamModel.nightDriver.quantitativeHours, 2, 2) }}
            </span>
          </div>
          <div class="table-row">
            <span class="title">Horas (Itens Qualitativos)</span>
            <span class="value end">
              {{ utils.numberFormatter(operationalTeamModel.standardDriver.qualitativeHours, 2, 2) }}
            </span>
            <span class="value end">
              {{ utils.numberFormatter(operationalTeamModel.nightDriver.qualitativeHours, 2, 2) }}
            </span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Remuneração Variável (S/ Encargos)</span>
            <span class="value end total">{{
              this.operationalTeamModel.standardDriver
                .totalRemunerationWithoutCharges | currency : "BRL"
            }}</span>
            <span class="value end total">{{
              this.operationalTeamModel.nightDriver
                .totalRemunerationWithoutCharges | currency : "BRL"
            }}</span>
          </div>
          <div class="table-row footer">
            <span class="title">Total Remuneração Variável (C/ Encargos)</span>
            <span class="value end total">{{
              this.operationalTeamModel.standardDriver
                .totalRemunerationWithCharges | currency : "BRL"
            }}</span>
            <span class="value end total">{{
              this.operationalTeamModel.nightDriver.totalRemunerationWithCharges
                | currency : "BRL"
            }}</span>
          </div>
        </div>
      </section>
    </app-card>
  </div>
</div>
