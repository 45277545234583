<div class="maintenance-container">
  <div class="maintenance-card">
    <app-card
      title="Custo Variável com Manutenção"
      icon="../../../../../assets/icons/hammer-primary.svg"
      [accordion]="true"
    >
      <app-skeleton-input
        *ngIf="isLoading"
        [input]="4"
        body
      ></app-skeleton-input>

      <section *ngIf="!isLoading" class="body" body>
        <p class="label-expenses">Informar os valores gastos em R$/KM.</p>
      </section>

      <section *ngIf="!isLoading" class="body" body>
        <app-input
          label="Manutenção Própria do Cavalo Mecânico por KM"
          placeholder="R$ 0,00"
          [errorMessage]="errorMessage"
          [control]="maintenanceForm.controls.tractorOwnMaintenance"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Manutenção Fábrica do Cavalo Mecânico por KM"
          placeholder="R$ 0,00"
          [errorMessage]="errorMessage"
          [control]="maintenanceForm.controls.tractorFactoryMaintenance"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Manutenção do Conjunto por KM"
          placeholder="R$ 0,00"
          [errorMessage]="errorMessage"
          [control]="maintenanceForm.controls.trailerMaintenance"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="KM Plano (12 meses)"
          placeholder="0"
          [errorMessage]="errorMessage"
          [control]="maintenanceForm.controls.kmPlanned"
          [mask]="eMaskType.number"
          [disabled]="disableFieldKM"
          [max]="maxKmPlanned"
          (updateValue)="handleUpdateValue()"
        ></app-input>
      </section>
    </app-card>
  </div>
</div>
