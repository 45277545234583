<article (click)="handleDataClick(category.categoryId)">
  <aside>
    <span>{{ util.addLeadingZeros(category.categoryId, 5) }}</span>
  </aside>
  <main>
    <header>
      <strong>
        {{ category.name }}
        <span>{{ "- c.c. " + category.costCenter }}</span>
      </strong>
      <app-status
        [color]="category.active ? StatusColorEnum.success : StatusColorEnum.warning"
        [image]="
          category.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="category.active ? 'Ativo' : 'Inativo'"
      ></app-status>
    </header>
    <section>
      <div>
        <header>Coordenador de Suprimentos</header>
        <strong>{{ category.supplyCoordinatorId }}</strong>
        <p>{{ category.supplyCoordinatorName }}</p>
      </div>
      <div>
        <header>Coordenador Corporativo</header>
        <strong>{{ category.corporateCoordinatorId }}</strong>
        <p>{{ category.corporateCoordinatorName }}</p>
      </div>
      <div>
        <header>Gerente Corporativo</header>
        <strong>{{ category.corporateManagerId }}</strong>
        <p>{{ category.corporateManagerName }}</p>
      </div>
      <div>
        <header>Diretor Corporativo</header>
        <strong>{{ category.corporateDirectorId }}</strong>
        <p>{{ category.corporateDirectorName }}</p>
      </div>
    </section>
  </main>
</article>
