import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public displaySidenav: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  notifyDisplay(display: boolean) {
    this.displaySidenav = display;
  }
}
