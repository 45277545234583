import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CreateAnalystModel } from './models/create-analyst-model';
import { GetAnalystModel } from './models/get-analyst-model';
import { GetAnalystUserSystemModel } from './models/get-analyst-usersystem-model';
import { UpdateAnalystModel } from './models/update-analyst-model';

@Injectable({
  providedIn: 'root',
})
export class AnalystService extends BaseService {
  constructor(private http: HttpClient) {
    super('analysts')
  }

  getAnalysts() {
    return this.http.get<GetAnalystModel[]>(this.url);
  }

  getAnalystById(analystId: string) {
    return this.http.get<GetAnalystModel>(`${this.url}/${analystId}`);
  }

  getAnalystByIdUserSystem(analystId: string) {
    return this.http.get<GetAnalystUserSystemModel>(`${this.url}/usersystem/${analystId}`);
  }

  createAnalyst(request: CreateAnalystModel) {
    return this.http.post<GetAnalystModel>(this.url, request);
  }

  updateAnalyst(request: UpdateAnalystModel) {
    return this.http.patch<GetAnalystModel>(`${this.url}/${request.analystId}`, request);
  }
}
