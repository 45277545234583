export enum DfdvStatusEnum {
  Draft = 'Rascunho',
  Preliminary = 'Preliminar',
  Pending = 'Pendente',
  Reproved = 'Reprovado',
  Archived = 'Arquivado',
  Current = 'Vigente',
  New = 'Novo',
  Finished = 'Finalizado',
  Canceled = 'Cancelado',
}
