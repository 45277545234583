<div class="parameters-container">
  <section>
    <header>
      <app-sumarize-header
        currentPageTitle="Equipe Apoio"
        (save)="submit()"
        [isLoadingEQ]="isLoadingEQ"
      ></app-sumarize-header>
    </header>
    <div class="card-container">
      <app-card
        title="Especificações"
        icon="../../../../../assets/icons/graphic-common.svg"
        [accordion]="true"
      >
        <div class="skeleton" body *ngIf="showSkeleton">
          <app-skeleton-input [input]="8"></app-skeleton-input>
        </div>
        <div class="parameters-especifications" body *ngIf="!showSkeleton">
          <div class="info light-fleet-rental">
            <label class="info-title">Aluguel Frota Leve</label>
            <label class="info-value">
              {{ scopeModel.rental?.lightFleetRental ?? 0 | currency : "BRL" }}
            </label>
          </div>
          <div class="info collective-transport-rental">
            <label class="info-title"> Aluguel Transporte Coletivo </label>
            <label class="info-value">
              {{
                scopeModel.rental?.collectiveTransportRental ?? 0| currency : "BRL"
              }}
            </label>
          </div>
          <div class="info workshop-truck-rental">
            <label class="info-title"> Aluguel Caminhão Oficina </label>
            <label class="info-value">
              {{ scopeModel.rental?.workshopTruckRental ?? 0 | currency : "BRL" }}
            </label>
          </div>
          <div class="info light-fleet-fuel-cost">
            <label class="info-title"> Custo Combustível Frota Leve </label>
            <label class="info-value">
              {{ scopeModel.consumption?.lightFleetFuelCost ?? 0 | currency : "BRL" }}
            </label>
          </div>
          <div class="info collective-transport-fuel-cost">
            <label class="info-title">
              Custo Combustível Transporte Coletivo
            </label>
            <label class="info-value">
              {{
                scopeModel.consumption?.collectiveTransportFuelCost ?? 0
                  | currency : "BRL"
              }}
            </label>
          </div>
          <div class="info light-fleet-consumption">
            <label class="info-title"> Consumo KM/L Frota Leve </label>
            <label class="info-value">
              {{ scopeModel.consumption?.lightFleetConsumption ?? 0}}
            </label>
          </div>
          <div class="info collective-transport-consumption">
            <label class="info-title"> Consumo KM/L Transporte Coletivo </label>
            <label class="info-value">
              {{ scopeModel.consumption?.collectiveTransportConsumption ?? 0}}
            </label>
          </div>
          <div class="info exchange-day-driven-km">
            <label class="info-title"> KM Rodado por Dia de Troca </label>
            <label class="info-value">
              {{ scopeModel.equipment?.exchangeDayDrivenKm ?? 0}}
            </label>
          </div>
          <div class="info operation-day-driven-km">
            <label class="info-title"> KM Rodado por Dia de Operação </label>
            <label class="info-value">
              {{ scopeModel.equipment?.operationDayDrivenKm ?? 0}}
            </label>
          </div>
          <div class="info working-days">
            <label class="info-title"> Dias Úteis de Trabalho (ao mês) </label>
            <label class="info-value">
              {{ driversFactorModel?.workingDays ?? 0}}
            </label>
          </div>
        </div>
      </app-card>
      <app-support-team-total
        [totalizer]="supportTeamModel.total"
        [showSkeleton]="showSkeleton"
        #total
      ></app-support-team-total>
      <app-card
        *ngIf="showSkeleton"
        title="Gerência"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
        <app-skeleton-line body [line]="4"></app-skeleton-line>
      </app-card>
      <app-support-team-card
        *ngIf="!showSkeleton"
        [title]="'Gerência'"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [supportTeamType]="'management'"
        [totalizer]="supportTeamModel.management.total"
        [transportType]="supportTeamModel.management.transportTypeName"
        [columns]="[
          [
            'transportGeneralManager',
            supportTeamModel.management.transportGeneralManager
          ],
          ['managementAnalyst', supportTeamModel.management.managementAnalyst]
        ]"
        [modelAux]="[chargesModel, scopeModel, driversFactorModel,benefitModel,generalCostsModel]"
        [description]="['Gerente Geral Transportadora', 'Analista de Gestão']"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotal($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-team-card>
      <app-card
        *ngIf="showSkeleton"
        title="Apoio Operacional"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
      <app-skeleton-line body [line]="9"></app-skeleton-line>
      </app-card>
      <app-support-team-card
        *ngIf="!showSkeleton"
        [title]="'Apoio Operacional'"
        [supportTeamType]="'operationalSupport'"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [totalizer]="supportTeamModel.operationalSupport.total"
        [transportType]="supportTeamModel.operationalSupport.transportTypeName"
        [columns]="[
          ['generalBuyer', supportTeamModel.operationalSupport.generalBuyer],
          ['supervisor', supportTeamModel.operationalSupport.supervisor],
          ['instructor', supportTeamModel.operationalSupport.instructor],
          ['fieldLeader', supportTeamModel.operationalSupport.fieldLeader],
          [
            'nightFieldLeader',
            supportTeamModel.operationalSupport.nightFieldLeader
          ],
          ['yardLeader', supportTeamModel.operationalSupport.yardLeader],
          [
            'nightYardLeader',
            supportTeamModel.operationalSupport.nightYardLeader
          ]
        ]"
        [modelAux]="[chargesModel, scopeModel, driversFactorModel,benefitModel,generalCostsModel]"
        [description]="[
          'Comprador Geral',
          'Supervisor',
          'Instrutor',
          'Líder de Campo',
          'Líder de Campo Noturno',
          'Líder de Pátio',
          'Líder de Pátio Noturno'
        ]"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotal($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-team-card>
      <app-card
        *ngIf="showSkeleton"
        title="Manutenção e Almoxarifado"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
      <app-skeleton-line body [line]="15"></app-skeleton-line>
      </app-card>
      <app-support-team-card
        *ngIf="!showSkeleton"
        [title]="'Manutenção e Almoxarifado'"
        [supportTeamType]="'maintenanceAndWarehouse'"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [totalizer]="supportTeamModel.maintenanceAndWarehouse.total"
        [transportType]="
          supportTeamModel.maintenanceAndWarehouse.transportTypeName
        "
        [columns]="[
          [
            'maintenanceSupervisor',
            supportTeamModel.maintenanceAndWarehouse.maintenanceSupervisor
          ],
          [
            'warehouseAssistant',
            supportTeamModel.maintenanceAndWarehouse.warehouseAssistant
          ],
          [
            'storekeeperAssistant',
            supportTeamModel.maintenanceAndWarehouse.storekeeperAssistant
          ],
          [
            'nightStorekeeperAssistant',
            supportTeamModel.maintenanceAndWarehouse.nightStorekeeperAssistant
          ],
          ['electrician', supportTeamModel.maintenanceAndWarehouse.electrician],
          [
            'nightElectrician',
            supportTeamModel.maintenanceAndWarehouse.nightElectrician
          ],
          ['mechanic', supportTeamModel.maintenanceAndWarehouse.mechanic],
          [
            'nightMechanic',
            supportTeamModel.maintenanceAndWarehouse.nightMechanic
          ],
          [
            'tireRepairman',
            supportTeamModel.maintenanceAndWarehouse.tireRepairman
          ],
          [
            'nightTireRepairman',
            supportTeamModel.maintenanceAndWarehouse.nightTireRepairman
          ],
          ['welder', supportTeamModel.maintenanceAndWarehouse.welder],
          [
            'mechanicalAssistant',
            supportTeamModel.maintenanceAndWarehouse.mechanicalAssistant
          ]
        ]"
        [modelAux]="[chargesModel, scopeModel, driversFactorModel,benefitModel,generalCostsModel]"
        [description]="[
          'Supervisor de Manutenção',
          'Assistente de Almoxarifado',
          'Assistente Almoxarife',
          'Assistente Almoxarife Noturno',
          'Eletricista',
          'Eletricista Noturno',
          'Mecânico',
          'Mecânico Noturno',
          'Borracheiro',
          'Borracheiro Noturno',
          'Soldador',
          'Ajudante Mecânico'
        ]"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotal($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-team-card>
      <app-card
        *ngIf="showSkeleton"
        title="RH e SSMA"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
      <app-skeleton-line body [line]="5"></app-skeleton-line>
      </app-card>
      <app-support-team-card
        *ngIf="!showSkeleton"
        [title]="'RH e SSMA'"
        [supportTeamType]="'hrAndSsma'"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [totalizer]="supportTeamModel.hrAndSsma.total"
        [transportType]="supportTeamModel.hrAndSsma.transportTypeName"
        [columns]="[
          ['operationsAnalyst', supportTeamModel.hrAndSsma.operationsAnalyst],
          ['hrTechnician', supportTeamModel.hrAndSsma.hrTechnician],
          ['securityTechnician', supportTeamModel.hrAndSsma.securityTechnician]
        ]"
        [modelAux]="[chargesModel, scopeModel, driversFactorModel,benefitModel,generalCostsModel]"
        [description]="[
          'Analista de Operações',
          'Técnico RH',
          'Técnico de Segurança'
        ]"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotal($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-team-card>
      <app-card
        *ngIf="showSkeleton"
        title="Adm/Financeiro"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
      <app-skeleton-line body [line]="5"></app-skeleton-line>
      </app-card>
      <app-support-team-card
        *ngIf="!showSkeleton"
        [title]="'Adm/Financeiro'"
        [supportTeamType]="'adminAndFinancial'"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [totalizer]="supportTeamModel.adminAndFinancial.total"
        [transportType]="supportTeamModel.adminAndFinancial.transportTypeName"
        [columns]="[
          [
            'maintenanceAnalyst',
            supportTeamModel.adminAndFinancial.maintenanceAnalyst
          ],
          [
            'adminAndFinancialAssistant',
            supportTeamModel.adminAndFinancial.adminAndFinancialAssistant
          ]
        ]"
        [modelAux]="[chargesModel, scopeModel, driversFactorModel,benefitModel,generalCostsModel]"
        [description]="['Analista de Manutenção', 'Assistente Adm/Fin']"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotal($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-team-card>
      <app-card
        *ngIf="showSkeleton"
        title="Frota Leve"
        [icon]="'../../../../../../assets/icons/Money-Icon.svg'"
        [accordion]="true"
      >
        <app-skeleton-text body [text]="1"></app-skeleton-text>
        <app-skeleton-line body [line]="4"></app-skeleton-line>
      </app-card>
      <app-support-fleet
        *ngIf="!showSkeleton"
        [supportTeamFleetModel]="supportTeamModel.supportTeamFleet"
        [modelAux]="[scopeModel, driversFactorModel]"
        [shouldDisableFields]="shouldDisableFields"
        [showSkeleton]="showSkeleton"
        (UpdateTotal)="UpdateTotalSupportFleet($event)"
        (UpdateStoreSupportTeam)="UpdateSupporTeamStore()"
      ></app-support-fleet>
    </div>
  </section>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
