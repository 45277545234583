import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { BenefitModel } from 'src/app/services/dfdv/benefit/models/benefit.model';
import { ChargeModel } from 'src/app/services/dfdv/charge/models/charges.model';
import { DriversFactorModel } from 'src/app/services/dfdv/driversFactor/models/driversFactor.model';
import { SupportTeamDetailModel } from 'src/app/services/dfdv/models/support-team/support-team-detail.model';
import { SupportTeamTotalizerModel } from 'src/app/services/dfdv/models/support-team/support-team-totalizer.model';
import { SupportTeamModel } from 'src/app/services/dfdv/models/support-team/support-team.model';
import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { GetGeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/get-general-cost.model';
import * as DfdvActions from '../../../../../common/store/dfdv/dfdv.actions';
import { Utils } from 'src/app/common/utils/utils';

@Component({
  selector: 'app-support-team-card',
  templateUrl: './support-team-card.component.html',
  styleUrls: ['./support-team-card.component.scss'],
})
export class SupportTeamCardComponent implements OnInit, OnChanges {
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input()
  totalizer: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  @Input() transportType!: string | null;
  @Input()
  columns!: [string, SupportTeamDetailModel][];
  @Input() modelAux!: [
    ChargeModel,
    ScopeDfdvModel,
    DriversFactorModel,
    BenefitModel,
    GetGeneralCostsModel
  ];
  @Input() description: string[] = [];
  @Input() supportTeamType: string = '';

  @Input() shouldDisableFields: boolean = false;
  @Input() showSkeleton: boolean = false;

  @Output() UpdateTotal: EventEmitter<
    [
      string,
      SupportTeamTotalizerModel,
      [string, SupportTeamDetailModel][],
      string | null
    ]
  > = new EventEmitter();
  @Output() UpdateStoreSupportTeam: EventEmitter<null> = new EventEmitter();

  public form = this.formBuilder.group({});

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';
  public isLoading: boolean = true;

  // Models
  public chargesModel: ChargeModel = new ChargeModel();
  public scopeModel: ScopeDfdvModel = new ScopeDfdvModel();
  public driversFactorModel: DriversFactorModel = new DriversFactorModel();
  public benefitModel: BenefitModel = new BenefitModel();
  public generalCostsModel: GetGeneralCostsModel = new GetGeneralCostsModel();
  public model: [string, SupportTeamDetailModel][] = [];
  public supportTeam: SupportTeamModel = new SupportTeamModel();

  public selectedFleetTypes: string[] = [];
  public optionFleetTypes: string[] = ['Frota Leve', 'Transporte Coletivo'];

  supportTeamStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.supportTeam));

  constructor(
    private formBuilder: FormBuilder,
    public utils: Utils,
    private store: Store<{ dfdv: IDfdvState }>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.transportType === '' || this.transportType === null) {
      this.selectedFleetTypes = [];
    }
  }

  ngOnInit(): void {
    this.model = [...this.columns];
    [
      this.chargesModel,
      this.scopeModel,
      this.driversFactorModel,
      this.benefitModel,
      this.generalCostsModel,
    ] = this.modelAux;

    this.form.addControl(
      'transportertypeName',
      new FormControl(this.transportType ?? '')
    );

    this.form
      .get('transportertypeName')
      ?.valueChanges.subscribe((change) =>
        this.UpdateTransporterTypeName(change)
      );

    this.model.forEach((tuple) => {
      const salaryQuantity = `${tuple[0]}SalaryQuantity`;
      const salaryValue = `${tuple[0]}SalaryValue`;
      const lightFleetQuantity = `${tuple[0]}LightFleetQuantity`;

      this.form.addControl(
        salaryQuantity,
        new FormControl(tuple[1].salaryQuantity)
      );
      this.form.addControl(salaryValue, new FormControl(tuple[1].salaryValue));
      this.form.addControl(
        lightFleetQuantity,
        new FormControl(tuple[1].lightFleetQuantity)
      );

      this.form
        .get(salaryQuantity)
        ?.valueChanges.subscribe(() => this.UpdateSalaryQuantityValues(tuple));
      this.form
        .get(salaryValue)
        ?.valueChanges.subscribe(() => this.UpdateSalaryValuesValues(tuple));
      this.form
        .get(lightFleetQuantity)
        ?.valueChanges.subscribe(() =>
          this.UpdateLightFleetQuantityValues(tuple)
        );
    });
    this.supportTeamStore$.subscribe((supportTeam) => {
      if (supportTeam) {
        this.supportTeam = { ...supportTeam };
        this.model.forEach((tuple) => {
          const salaryQuantity = `${tuple[0]}SalaryQuantity`;
          const salaryValue = `${tuple[0]}SalaryValue`;
          const lightFleetQuantity = `${tuple[0]}LightFleetQuantity`;

          tuple[1].salaryQuantity =
            supportTeam[this.supportTeamType][tuple[0]].salaryQuantity;
          tuple[1].salaryValue =
            supportTeam[this.supportTeamType][tuple[0]].salaryValue;
          tuple[1].lightFleetQuantity =
            supportTeam[this.supportTeamType][tuple[0]].lightFleetQuantity;

          this.form.get(salaryQuantity)?.patchValue(tuple[1].salaryQuantity);
          this.form.get(salaryValue)?.patchValue(tuple[1].salaryValue);
          this.form
            .get(lightFleetQuantity)
            ?.patchValue(tuple[1].lightFleetQuantity);
          this.UpdateSalaryQuantityValues(tuple);
          this.UpdateSalaryValuesValues(tuple);
          this.UpdateLightFleetQuantityValues(tuple);
        });

        this.getFleetType();
        this.isLoading = false;
      }
    });
  }

  getFormControl(row: FormGroup, columnType: string) {
    const control: AbstractControl | null = row.get(columnType);
    return control instanceof FormControl ? control : new FormControl(null);
  }

  getFleetType() {
    if (this.form.get('transportertypeName')?.value !== '') {
      this.selectedFleetTypes = [
        this.form.get('transportertypeName')?.value ?? '',
      ];
    }
  }

  UpdateSalaryQuantityValues(item: [string, SupportTeamDetailModel]) {
    const props = this.model.find((x) => x[0] === item[0]);
    const salaryQuantity = `${item[0]}SalaryQuantity`;
    if (!props) return;

    const prop = props[1];
    const valueForm = this.form.get(salaryQuantity);
    prop.salaryQuantity = valueForm !== null ? +valueForm.value : 0;
    prop.salaryExpense = prop.salaryQuantity * prop.salaryValue;

    prop.salaryAndChargeExpense =
      prop.salaryQuantity * prop.salaryAndChargeValue;

    if (prop.salaryQuantity === 0) {
      prop.benefitValue = 0;
    } else {
      prop.benefitValue =
        (item[0].includes('night')
          ? this.benefitModel.totalNightDriver
          : this.benefitModel.totalStandardDriver) ?? 0;
    }

    prop.uniformValue = this.getUniformValue(item[0]);
    prop.benefitExpense = prop.salaryQuantity * prop.benefitValue;
    prop.uniformExpense = prop.salaryQuantity * prop.uniformValue;

    this.UpdateTotalizer();
  }
  getUniformValue(subGroupName: string): number {
    switch (this.supportTeamType) {
      case 'management': {
        return 0;
      }
      case 'operationalSupport': {
        if (subGroupName === 'generalBuyer') return 0;
        if (subGroupName === 'supervisor' || subGroupName === 'instructor')
          return this.generalCostsModel.totalMonthlyCostOfAdministrativeUniform;
        return this.generalCostsModel
          .totalMonthlyCostOfMaintenanceAndWarehouseUniform;
      }
      case 'maintenanceAndWarehouse': {
        if (
          subGroupName === 'warehouseAssistant' ||
          subGroupName === 'storekeeperAssistant' ||
          subGroupName === 'nightStorekeeperAssistant'
        )
          return this.generalCostsModel
            .totalMonthlyCostOfMaintenanceAndWarehouseUniform;
        return this.generalCostsModel.totalMonthlyCostOfOperationalUniform;
      }
      default: {
        return this.generalCostsModel.totalMonthlyCostOfAdministrativeUniform;
      }
    }
  }

  UpdateSalaryValuesValues(item: [string, SupportTeamDetailModel]) {
    const props = this.model.find((x) => x[0] === item[0]);

    if (!props) return;

    const prop = props[1];
    const nigthAdd = item[0].includes('night');

    const salaryValue = `${item[0]}SalaryValue`;
    prop.salaryValue = this.form.get(salaryValue)?.value;

    prop.salaryExpense = prop.salaryQuantity * prop.salaryValue;

    if (nigthAdd) {
      prop.salaryAndChargeValue =
        prop.salaryValue *
        (1 +
          (this.chargesModel.totalCharge +
            this.chargesModel.chargeBase.nightAdditional) /
            100);
    } else
      prop.salaryAndChargeValue =
        prop.salaryValue * (1 + this.chargesModel.totalCharge / 100);
    prop.salaryAndChargeExpense =
      prop.salaryQuantity * prop.salaryAndChargeValue;

    this.UpdateTotalizer();
  }
  UpdateLightFleetQuantityValues(item: [string, SupportTeamDetailModel]) {
    const props = this.model.find((x) => x[0] === item[0]);

    if (!props) return;

    const prop = props[1];
    const lightFleetQuantity = `${item[0]}LightFleetQuantity`;
    const valueForm = this.form.get(lightFleetQuantity);
    prop.lightFleetQuantity = valueForm !== null ? +valueForm.value : 0;

    if (prop.lightFleetQuantity == 0) {
      prop.lightFleetValue = 0;
    } else {
      this.UpdateTransporterTypeName(this.transportType ?? '');
    }
    prop.lightFleetExpense = prop.lightFleetValue * prop.lightFleetQuantity;

    this.UpdateTotalizer();
  }

  UpdateTransporterTypeName(change: any) {
    if (change === '') {
      this.transportType = null;
      this.selectedFleetTypes = [];

      this.model.forEach((item) => {
        const prop = item[1];
        prop.lightFleetValue = 0;
        prop.lightFleetExpense = 0;
      });

      this.UpdateTotalizer();
      return;
    }

    let lightFleetValue = 0;
    const consumption =
      change === 'Frota Leve'
        ? this.scopeModel.consumption.lightFleetConsumption
        : this.scopeModel.consumption.collectiveTransportConsumption;
    const cost =
      change === 'Frota Leve'
        ? this.scopeModel.consumption.lightFleetFuelCost
        : this.scopeModel.consumption.collectiveTransportFuelCost;

    const rental =
      change === 'Frota Leve'
        ? this.scopeModel.rental.lightFleetRental
        : this.scopeModel.rental.collectiveTransportRental;

    if (
      consumption !== 0 &&
      rental !== undefined &&
      this.scopeModel.equipment !== undefined &&
      this.driversFactorModel !== undefined
    ) {
      lightFleetValue =
        rental +
        ((this.scopeModel.equipment.operationDayDrivenKm *
          this.driversFactorModel.workingDays) /
          consumption) *
          cost;
    }
    this.model.forEach((item) => {
      const prop = item[1];
      if (prop.lightFleetQuantity > 0) {
        prop.lightFleetValue = lightFleetValue;
        prop.lightFleetExpense = prop.lightFleetValue * prop.lightFleetQuantity;
      } else {
        prop.lightFleetValue = 0;
        prop.lightFleetExpense = 0;
      }
    });
    this.transportType = change;
    this.UpdateTotalizer();
  }

  UpdateTotalizer() {
    const totalizer = new SupportTeamTotalizerModel();
    this.model.forEach((item) => {
      totalizer.totalBenefitExpense += item[1].benefitExpense;
      totalizer.totalBenefitValue += item[1].benefitValue;
      totalizer.totalLightFleetExpense += item[1].lightFleetExpense;
      totalizer.totalLightFleetQuantity += item[1].lightFleetQuantity;
      totalizer.totalLightFleetValue += item[1].lightFleetValue;
      totalizer.totalSalaryAndChargeExpense += item[1].salaryAndChargeExpense;
      totalizer.totalSalaryAndChargeValue += item[1].salaryAndChargeValue;
      totalizer.totalSalaryExpense += item[1].salaryExpense;
      totalizer.totalSalaryQuantity += item[1].salaryQuantity;
      totalizer.totalSalaryValue += item[1].salaryValue;
      totalizer.totalUniformExpense += item[1].uniformExpense;
      totalizer.totalUniformValue +=
        item[1].salaryQuantity === 0 ? 0 : item[1].uniformValue;
    });
    this.totalizer = totalizer;
    this.UpdateTotal.emit([
      this.supportTeamType,
      this.totalizer,
      this.model,
      this.transportType,
    ]);
  }

  UpdateStore() {
    this.UpdateStoreSupportTeam.emit();
  }
}
