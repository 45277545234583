import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return null;
    }
    let phone = value.replace(/[^0-9]/g, '');
    if (phone.length === 10) {
      phone= phone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1)$2-$3');
    }
    if (phone.length === 11) {
      phone= phone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1)$2-$3');
    }
    return phone;
  }
}
