import { AuthService } from 'src/app/common/services/auth.service';
/* eslint-disable no-param-reassign */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateAnalystModel } from 'src/app/services/analyst/models/update-analyst-model';
import { AnalystService } from 'src/app/services/analyst/analyst.service';
import { CreateAnalystModel } from 'src/app/services/analyst/models/create-analyst-model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { SearchControlModel } from '../../unit/unit-new/model/searc-control.model';

@Component({
  selector: 'app-analyst-new',
  templateUrl: './analyst-new.component.html',
  styleUrls: ['./analyst-new.component.scss'],
})
export class AnalystNewComponent implements OnInit {
  public isUpdate: boolean = false;
  public buttonLabel: string = '';
  public pageTitle: string = '';
  public loadingButton = false;
  public admin: boolean = false;
  public active: boolean = true;
  public roles: string[] = [
    'Triagem',
    'Analista Custos',
    'Analista Suprimentos',
    'Analista Pagamento'
  ];
  public selectedRole: string[] = [];
  public modalModel: ModalModel = new ModalModel();
  public showModal: boolean = false;

  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public eInputType = InputTypeEnum;

  public analystId!: string | null;
  public analystSearch: SearchControlModel = new SearchControlModel();
  public isLoading: boolean = true;

  public form = this.formBuilder.group({
    analyst: ['', Validators.required],
    role: ['', Validators.required],
  });

  public updateForm = this.formBuilder.group({
    analyst: [''],
    role: ['', Validators.required],
    name: [''],
    email: [''],
    title: [''],
    office: [''],
    department: [''],
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public analystService: AnalystService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.analystId = params.get('id');
      if (params.get('id') !== null) {
        this.fillForm(params.get('id') ?? '');
        this.pageTitle = 'Editar Usuário';
        this.buttonLabel = 'ENVIAR';
        this.isUpdate = true;
      } else {
        // this.getStorage();
        this.pageTitle = 'Cadastro de novo Usuário';
        this.buttonLabel = 'CADASTRAR';
        this.isLoading = false;
      }
    });
  }

  handleSearchClick(value: string, model: SearchControlModel) {
    if (value === '') return;

    model.loading = true;

    this.analystService.getAnalystByIdUserSystem(value).subscribe({
      next: (element) => {
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.item = element.name;
        model.searchModel = element;
        this.form.markAsDirty();
      },
      error: () => {
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar o ID';
      },
    });
  }

  handleCardClick(model: SearchControlModel) {
    if (model.searchModel.department) {
      model.showModal = true;
      return;
    }

    model.loadingCard = true;
    this.analystService.getAnalystByIdUserSystem(model.searchModel.analystId)
      .subscribe({
        next: (element) => {
          model.loadingCard = false;
          model.searchModel = element;
          model.showModal = true;
        },
        error: () => {
          model.loadingCard = false;
          this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
          this.modalModel.title = 'Não foi possível obter os dados do Usuário';
          this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.success = false;
          this.showModal = true;
        },
      });
  }

  handleCloseModal(model: SearchControlModel) {
    model.showModal = false;
  }

  excludeCard(model: SearchControlModel) {
    model.selected = false;
    model.item = '';
    this.form.controls.analyst.markAsDirty();
    this.form.controls.analyst.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  handleButtonClick(update: boolean) {
    this.loadingButton = true;
    if (!update) {
      const request = new CreateAnalystModel();
      request.active = this.active;
      request.admin = this.admin;
      request.analystId = this.form.controls.analyst.value!;
      request.analystRole = this.form.controls.role.value!;
      this.analystService.createAnalyst(request).subscribe({
        next: this.requestSuccess.bind(this),
        error: this.requestError.bind(this),
      });
    } else {
      const request = new UpdateAnalystModel();
      request.active = this.active;
      request.admin = this.admin;
      request.analystId = this.updateForm.controls.analyst.value!;
      request.analystRole = this.updateForm.controls.role.value!;
      this.analystService.updateAnalyst(request).subscribe({
        next: this.requestSuccess.bind(this),
        error: this.requestError.bind(this),
      });
    }
  }

  requestSuccess() {
    this.loadingButton = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = `Atenção: O Usuário encontra-se ${
      this.active ? 'ATIVO' : 'INATIVO'
    }.`;
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    if (!this.isUpdate) {
      this.modalModel.title = 'Usuário cadastrado com sucesso!';
    } else {
      this.modalModel.title = 'Usuário atualizado com sucesso!';
    }
    this.modalModel.success = true;
    this.showModal = true;
  }

  requestError(result: HttpErrorResponse) {
    // let message:string = '';
    // try {
    //   message = result.error.Message;
    // } catch (error) {
    //   message = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
    // }
    const message = JSON.parse(JSON.stringify(result.error));
    this.loadingButton = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    if (!this.isUpdate) {
      this.modalModel.title = 'Não foi possível cadastrar o Usuário';
    } else {
      this.modalModel.title = 'Não foi possível atualizar o Usuário';
    }
    this.modalModel.success = false;
    this.showModal = true;
  }

  handleAdminCheck() {
    this.admin = !this.admin;
  }

  handleReturnClick() {
    this.router.navigate(['/admin/analyst']);
  }

  handleActiveCheck() {
    this.active = !this.active;
  }

  fillForm(id: string) {
    this.analystService.getAnalystById(id).subscribe({
      next: (element) => {
        this.updateForm.controls.analyst.patchValue(element.analystId);
        this.updateForm.controls.name.patchValue(element.name);
        this.updateForm.controls.email.patchValue(element.email);
        this.updateForm.controls.office.patchValue(element.office);
        this.updateForm.controls.department.patchValue(element.department);
        this.updateForm.controls.title.patchValue(element.title);
        this.updateForm.controls.role.patchValue(element.analystRoleName);
        this.roles.push(element.analystRoleName);
        this.roles = this.roles.filter((value, index, array) => array.indexOf(value) === index);
        this.selectedRole = [element.analystRoleName];
        this.active = element.active;
        this.admin = element.analystProfileName === 'Admin';
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.authService.redirectToAccessDenied();
        } else {
          const message = JSON.parse(JSON.stringify(error?.error));
          this.loadingButton = false;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.description = message?.Message ?? message;
          this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
          this.modalModel.title = 'Erro ao buscar o Usuário';
          this.modalModel.success = true;
          this.showModal = true;
        }
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  clickModal() {
    if (this.modalModel.success) {
      this.showModal = false;
      this.router.navigate(['/admin/analyst']);
    } else {
      this.showModal = false;
    }
  }
}
