import {
  Component, Input, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit, OnChanges {
  public error: boolean = false;

  @Input() control = new FormControl('');
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Input() maxlength: number = 0;
  @Input() disabled: boolean = false;

  ngOnChanges(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnInit(): void {
    this.control.statusChanges.subscribe(this.controlStatusChanges.bind(this));
  }

  controlStatusChanges() {
    this.error = this.control.status === 'INVALID';
  }
}
