<div class="container">
  <app-tab>
    <app-tab-item title="Informações Gerais">
      <app-operator-new></app-operator-new>
    </app-tab-item>
    <app-tab-item title="Informações PIMS">
      <app-operator-pims></app-operator-pims>
    </app-tab-item>
  </app-tab>
</div>
