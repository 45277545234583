<div class="container">
  <section>
    <header>
      <img src="./././assets/icons/connection-error.svg" alt="Erro" />
    </header>
    <main>
      <label class="title">Opssssssss!</label>
      <label class="description"
        >Ocorreu um erro ao obter as informações.</label
      >
      <app-buttons
        label="Tentar novamente"
        icon="../../../../assets/icons/reload.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="true"
        (clickButton)="reload()"
      ></app-buttons>
    </main>
  </section>
</div>
