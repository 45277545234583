import { GetUniformMaintenceWarehouseTeamModel } from './maintence-warehouse/get-uniform-maintenceWarehouse.model';
import { GetAdministrativeTeamUniformModel } from './management-team/get-administrative-team-uniform.model';
import { GetOfficeExpensesModel } from './office-expenses/get-office-expenses.model';
import { GetUniformOperationalTeamModel } from './operational-team/get-uniform-operationalTeam.model';

export class GetGeneralCostsModel {
  [key: string]: any;

  public totalMonthlyCostOfAdministrativeUniform!: number;
  public totalMonthlyCostOfOperationalUniform!: number;
  public totalMonthlyCostOfMaintenanceAndWarehouseUniform!: number;
  public totalMonthlyCostOfAdministrativeCosts!: number;
  public totalHarvestCostOfAdministrativeCosts!: number;
  public totalHarvestCostOfAdministrativeUniform!: number;
  public totalHarvestCostOfOperationalUniform!: number;
  public totalHarvestCostOfMaintenanceAndWarehouseUniform!: number;

  public administrativeCosts: GetOfficeExpensesModel =
    new GetOfficeExpensesModel();
  public administrativeUniform: GetAdministrativeTeamUniformModel =
    new GetAdministrativeTeamUniformModel();
  public operationalUniform: GetUniformOperationalTeamModel =
    new GetUniformOperationalTeamModel();
  public maintenanceAndWarehouseUniform: GetUniformMaintenceWarehouseTeamModel =
    new GetUniformMaintenceWarehouseTeamModel();
}
