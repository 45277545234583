import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any>;

    if (req.url.endsWith('/oauth/access-token')) {
      let clientId = '';
      let clientSecret = '';

      if (req.body.grant_type === 'client_credentials') {
        clientId = environment.ClientIdPublicFront;
        clientSecret = environment.ClientSecretPublicFront;
      } else if (req.body.grant_type === 'password') {
        clientId = environment.ClientIdFront;
        clientSecret = environment.ClientSecretFront;
      }

      const auth = `${clientId}:${clientSecret}`;
      const authB64 = Buffer.from(auth).toString('base64');

      request = req.clone({
        setHeaders: {
          Authorization: `Basic ${authB64}`,
        },
      });
    } else if (req.url.includes('/portalopl-public/')) {
      const clientId = environment.ClientIdPublicFront;
      const token = this.storageService.getTokenCredentials();

      request = req.clone({
        setHeaders: {
          client_id: clientId,
          access_token: token,
        },
      });
    } else if (req.url.includes('/portalopl/') || req.url.includes('/portalopl-pims/')) {
      const clientId = environment.ClientIdFront;
      const token = this.storageService.getTokenPassword();

      request = req.clone({
        setHeaders: {
          client_id: clientId,
          access_token: token,
        },
      });
    } else {
      request = req;
    }

    return next.handle(request).pipe(catchError((error) => {
      if (error.status === 401) {
        this.authService.logout();
      } else if (error.status === 403) {
        this.router.navigate(['access-denied']);
      }

      return throwError(() => error);
    }));
  }
}
