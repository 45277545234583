<app-card
  [accordion]="true"
  [title]="dfdv.fantasyName || 'DFDV de Rascunho'"
  icon="assets/icons/settings-primary-600.svg"
  class="sumarize-resume"
>
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-text [text]="1"></app-skeleton-text>
  </div>
  <div *ngIf="!isLoading" actions class="sumarize-resume__status">
    <app-icon-button
      text="Baixar"
      icon="assets/icons/spreadsheet-download.svg"
      [enabled]="true"
      [loading]="loadingDownload"
      [color]="ebuttonColor.primary"
      (clickButton)="downloadDfdvReport()"
    ></app-icon-button>
    <app-status
      [color]="StatusColorEnum.common"
      [status]="
        'v' + dfdv.version + ' - ' + (dfdv.creationDate | date : 'dd/MM/yyyy')
      "
    ></app-status>
    <app-status
      [color]="getDfdvTypeColor(dfdv.dfdvTypeName)"
      [status]="dfdv.dfdvTypeName"
    ></app-status>
    <app-status
      [color]="getDfdvStatusColor(dfdv.dfdvStatusName)"
      [status]="dfdv.dfdvStatusName"
    ></app-status>
  </div>
  <div *ngIf="!isLoading" class="sumarize-resume__body" body>
    <div class="sumarize-resume__categories">
      <div class="sumarize-resume__date">
        <img src="assets/icons/calendar-primary-600.svg" />
        <div class="sumarize-resume__date-info">
          {{ dfdv.startDate | date : "dd/MM/yyyy" }}
        </div>
        <div class="sumarize-resume__date-info__separator">
          {{ dfdv.endDate | date : "dd/MM/yyyy" }}
        </div>
      </div>
      <div class="sumarize-resume__unit-name">
        <img src="assets/icons/units-primary-600.svg" />
        <div class="sumarize-resume__name">{{ dfdv.unitName }}</div>
      </div>
      <div class="sumarize-resume__category-name">
        <img src="assets/icons/categories-primary-600.svg" />
        <div class="sumarize-resume__name">{{ dfdv.categoryName }}</div>
      </div>
    </div>

    <app-attach
      #attach
      *ngIf="dfdv.attachments.length"
      class="sumarize-resume__attachments"
      [loadingAttach]="loadingAttach"
      [control]="form.controls.attachments"
      [selectedFiles]="selectedFiles"
      [disabled]="!rolePermission.attachemnt"
      [totalSize]="totalSize"
      [isAnalyst]="isAnalyst"
      [disabled]="true"
      title="Evidências dessa versão"
      (download)="downloadAttatchment($event)"
    ></app-attach>

    <div *ngIf="isUserRaizen" class="sumarize-resume__invoicing">
      <div class="info">
        <label class="info-title"> Faturamento Fixo </label>
        <label class="info-value">
          {{ fixedCostInvoicing | currency : "BRL" }}
        </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Variável </label>
        <label class="info-value">
          {{ variableCostInvoicing | currency : "BRL" }}
        </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Estimado Outros Custos </label>
        <label class="info-value">
          {{ otherCostEstimatedInvoicing | currency : "BRL" }}
        </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Pré-Safra </label>
        <label class="info-value">
          {{ preHarvestInvoicing | currency : "BRL" }}
        </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Entre-Safra </label>
        <label class="info-value">
          {{ betweenHarvestInvoicing | currency : "BRL" }}
        </label>
      </div>
      <div class="info">
        <label class="info-title"> Faturamento Total </label>
        <label class="info-value info-total">
          {{ totalInvoicing | currency : "BRL" }}
        </label>
      </div>
    </div>
  </div>
</app-card>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="handleCloseModal()"
></app-modal>
