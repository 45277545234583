import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToNewCategory() {
    this.router.navigate(['/admin/category/new']);
  }
}
