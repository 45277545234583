import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-box',
  templateUrl: './skeleton-box.component.html',
  styleUrls: ['./skeleton-box.component.scss']
})
export class SkeletonBoxComponent implements OnInit {

  @Input() box: number = 0;
  public arr:number[] = [];
  constructor() { }

  ngOnInit(): void {
    for (let index = 0; index < this.box; index++) {
      this.arr.push(0);
    }
  }

}
