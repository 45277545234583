<div class="card-container">
  <header>
    <app-sumarize-header
      currentPageTitle="Encargos"
      [errorMessage]="hasErrorMessage"
    ></app-sumarize-header>
  </header>
  <app-card title="Totalizadores" icon="./assets/icons/graphic-common.svg">
    <div *ngIf="isLoading" class="skeleton" body>
      <app-skeleton-line [line]="5"></app-skeleton-line>
    </div>
    <div *ngIf="!isLoading" class="card-body" body>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Incidência Cumulativa</span>
          <app-tooltip [top]="true">
            <div class="tooltip-info">
              <div class="text">Gupo A * Grupo B</div>
            </div>
          </app-tooltip>
        </label>
        <label class="info-value info-total">
          {{ totalizerCumulativeCount | number: '1.2-2' }} %
        </label>
      </div>
      <div class="info">
        <label class="info-title">
          <span class="info-text">Total</span>
          <app-tooltip [top]="true">
            <div class="tooltip-info">
              <div class="text">Grupo A + Grupo B + Grupo C + Incidência Cumulativa</div>
            </div>
          </app-tooltip>
        </label>
        <label class="info-value info-total">
          {{ totalizerTotalCount | number: '1.2-2' }} %
        </label>
      </div>
    </div>
  </app-card>
  <app-charge
    #charge
    [isLoading]="isLoading"
    [shouldDisableFields]="shouldDisableFields"
    [totalChargesCount]="totalChargesCount"
    (updateValue)="updateDFDVStore()"
  ></app-charge>
  <app-cumulative-incidence
    #cumulativeIncidence
    *ngIf="isUserRaizen"
    [isLoading]="isLoading"
    [totalBaseIncidenceBaseCount]="totalBaseIncidenceBaseCount"
    [totalBaseIncidenceInssCount]="totalBaseIncidenceInssCount"
    [totalBaseIncidenceFgtsCount]="totalBaseIncidenceFgtsCount"
    [shouldDisableFields]="shouldDisableFields"
    (updateValue)="updateDFDVStore()"
  ></app-cumulative-incidence>
  <app-cumulative-incidence-opl
    #cumulativeIncidenceOPL
    *ngIf="!isUserRaizen"
    [isLoading]="isLoading"
    [totalBaseIncidenceCount]="totalBaseIncidenceBaseCount"
    (updateValue)="updateDFDVStore()"
  ></app-cumulative-incidence-opl>
  <app-card
    title="Grupo C - Provisão"
    icon="./assets/icons/coin-primary-600.svg"
    [accordion]="true"
  >
    <div *ngIf="isLoading" class="skeleton" body>
      <app-skeleton-input [input]="3"></app-skeleton-input>
    </div>
    <section *ngIf="!isLoading" actions class="actions">
      Total:
      <div class="total">{{ totalProvisionCount | number: '1.2-2' }} %</div>
    </section>
    <div *ngIf="!isLoading" class="body grid" body>
      <app-input
        label="40% de Multa do FGTS"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="otherChargesform.controls.fgtsPenalty"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="40% de Multa do FGTS Safrista"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="otherChargesform.controls.fgtsPenaltySeasonalWorker"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="updateDFDVStore()"
      ></app-input>
    </div>
  </app-card>
  <app-card
    title="Grupo D - Adicional Noturno"
    icon="./assets/icons/coin-primary-600.svg"
    [accordion]="true"
  >
    <div *ngIf="isLoading" class="skeleton" body>
      <app-skeleton-input [input]="1"></app-skeleton-input>
    </div>
    <div *ngIf="!isLoading" class="body" body>
      <app-input
        label="Adicional Noturno"
        [placeholder]="'0,00'"
        errorMessage="{{ errorMessage }}"
        [control]="otherChargesform.controls.nightAdditional"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="updateDFDVStore()"
      ></app-input>
    </div>
  </app-card>
</div>
