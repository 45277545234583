export class SupportTeamDetailModel {
  [key: string]: any;
  salaryQuantity: number = 0;
  salaryValue: number = 0;
  salaryExpense: number = 0;
  salaryAndChargeValue: number = 0;
  salaryAndChargeExpense: number = 0;
  benefitValue: number = 0;
  benefitExpense: number = 0;
  uniformValue: number = 0;
  uniformExpense: number = 0;
  lightFleetQuantity: number = 0;
  lightFleetValue: number = 0;
  lightFleetExpense: number = 0;
}
