import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { PatchOperationPimsModel } from 'src/app/services/operation/models/patch-operation-pims.model';
import { OperationService } from 'src/app/services/operation/operation.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';

@Component({
  selector: 'app-operation-update',
  templateUrl: './operation-update.component.html',
  styleUrls: ['./operation-update.component.scss'],
})
export class OperationUpdateComponent implements OnInit {
  // Forms
  public formOperation = this.formBuilder.group({
    cd_operation: '',
    name: '',
    active: false,
  });

  // Controls
  public operationId : string = '';
  public operationModel : PatchOperationPimsModel = new PatchOperationPimsModel();

  // Auxs
  public eButtonsColor = ButtonColorEnum;
  public showModal: boolean = false;
  public modalModel: ModalModel = new ModalModel();

  // Buttons Handler
  public updateLoading = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public service: OperationService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.operationId = params.get('id') ?? '';
    });

    this.getOperation();
  }

  getOperation() {
    this.service.getOperation(this.operationId).subscribe(((result) => {
      this.formOperation.controls.active.patchValue(result.active);
      this.formOperation.controls.cd_operation
        .patchValue(result.operationId.toString());
      this.formOperation.controls.name.patchValue(result.name);

      this.operationModel = result;
    }));
  }

  onSave() {
    this.updateLoading = true;
    const operationPims = {
      operationId: this.operationModel.operationId,
      name: this.operationModel.name,
      active: this.formOperation.controls.active.value,
    } as PatchOperationPimsModel;

    this.service.updateOperation(operationPims).subscribe({
      next: this.updateSuccess.bind(this),
      error: this.updateError.bind(this),
    });
  }

  onCheck() {
    this.formOperation.controls.active.patchValue(!this.formOperation.controls.active.value);
  }

  return() {
    this.router.navigate(['/admin/operation']);
  }

  clickModal() {
    if (this.modalModel.success) {
      this.return();
    }
    this.modalModel.showModal = false;
  }

  updateSuccess() {
    this.updateLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/success-600.gif';
    this.modalModel.description = `A operação ${this.operationId} foi atualizada com sucesso`;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.modalModel.title = 'Atualização';
  }

  updateError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.updateLoading = false;
    this.modalModel.showModal = true;
    this.modalModel.imagePath = '../../../../../../../assets/icons/warning-600.gif';
    this.modalModel.description = message?.Message;
    this.modalModel.success = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.title = `Não foi possivel atualizar a operação ${this.operationId}`;
  }
}
