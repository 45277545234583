import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalystService } from 'src/app/services/analyst/analyst.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { GetAnalystModel } from '../../../services/analyst/models/get-analyst-model';

@Component({
  selector: 'app-analyst',
  templateUrl: './analyst.component.html',
  styleUrls: ['./analyst.component.scss'],
})
export class AnalystComponent implements OnInit {
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;

  public isLoading: boolean = true;
  public error: boolean = false;
  public analysts: GetAnalystModel[] = [];

  constructor(
    private router: Router,
    private analystService: AnalystService,
  ) {}

  ngOnInit(): void {
    this.getAnalysts();
  }

  handleButtonClick() {
    this.router.navigate(['/admin/analyst/new']);
  }

  getAnalysts() {
    this.analystService.getAnalysts().subscribe({
      next: (res) => {
        this.analysts.push(...res);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  reload() {
    this.isLoading = true;
    this.getAnalysts();
  }
}
