export class FleetSpecificationModel {
  totalQuantityTractor: number = 0;
  totalQuantityTrailer: number = 0;
  factorTrailer: number = 0;
  costTractor: number = 0;
  costTrailer: number = 0;
  capitalRemunerationRate: number = 0;
  quantityTiresPerTractor: number = 0;
  quantityTiresPerTrailer: number = 0;
  lifeCycleTractor: number = 0;
  lifeCycleTrailer: number = 0;
}
