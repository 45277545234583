import { SupportTeamLightFleetModel } from './support-team-lightFleet.model';
import { UpdateSupportTeamLightFleetModel } from './update-support-team-lightFleet.model';

export class UpdateSupportTeamFleetModel {
  [key: string]: any;

  constructor() {
    this.fleetOperationalTeam = new SupportTeamLightFleetModel();
    this.shiftChange = new UpdateSupportTeamLightFleetModel();
    this.workshopTruck = new UpdateSupportTeamLightFleetModel();
  }
  
  fleetOperationalTeam: SupportTeamLightFleetModel = new SupportTeamLightFleetModel();
  shiftChange: UpdateSupportTeamLightFleetModel = new UpdateSupportTeamLightFleetModel();
  workshopTruck: UpdateSupportTeamLightFleetModel = new UpdateSupportTeamLightFleetModel();
}
