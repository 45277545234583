<div class="fleet-container">
  <header>
    <app-sumarize-header currentPageTitle="Frota"></app-sumarize-header>
  </header>

  <section>
    <app-fleet-specifications
      [isLoading]="isLoading"
      [data]="specificationData"
    ></app-fleet-specifications>

    <app-fleet-table-tractor
      [isLoading]="isLoading"
      [activeActions]="activeActions"
      (newConfig)="handleOpenConfigModal($event)"
      [tractorList]="tractorList"
    >
    </app-fleet-table-tractor>

    <app-fleet-table-trailer
      [isLoading]="isLoading"
      [trailerList]="trailerList"
      [rowStatus]="rowStatus"
    ></app-fleet-table-trailer>
    <app-fleet-table-tax
      [isLoading]="isLoading"
      [taxList]="taxList"
      [rowStatus]="rowStatus"
    ></app-fleet-table-tax>
    <app-fleet-table-capital
      [isLoading]="isLoading"
      [capitalList]="capitalList"
      [rowStatus]="rowStatus"
    ></app-fleet-table-capital>
    <app-fleet-table-profit
      [isLoading]="isLoading"
      [profitList]="profitList"
      [rowStatus]="rowStatus"
    ></app-fleet-table-profit>
  </section>
</div>
<app-fleet-modal-new-config
  [shouldDisableFields]="shouldDisableFields"
  [showModal]="showNewConfigModal"
  [newConfigModel]="newConfigModel"
  (addOrUpdateConfig)="handleAddOrUpdateConfig($event)"
  (deleteConfig)="handleDeleteConfig($event)"
  (closeModal)="handleCloseConfigModal()"
></app-fleet-modal-new-config>
