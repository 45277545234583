import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject, map, takeUntil } from 'rxjs';
import {
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { FixedCostService } from 'src/app/services/fixedCosts/fixed-costs.service';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';
import { UpdateFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-fixedCost.model';
import { UpdateSolicitationsFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-solictiationsFixedCost.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalModel } from '../../pre-registration/models/modal.model';
import * as FixcedCostActions from '../../../common/store/fixedCost/fixedCost.actions';
import { updateSolicitation } from '../../../common/store/fixedCost/fixedCost.actions';

@Component({
  selector: 'app-fixed-costs-header',
  templateUrl: './fixed-costs-header.component.html',
  styleUrls: ['./fixed-costs-header.component.scss'],
})
export class FixedCostsHeaderComponent implements OnInit, OnDestroy {
  public form = this.formBuilder.group({
    date: [''],
  });

  public ebuttonColor = ButtonColorEnum;
  public inputTypeEnum = InputTypeEnum;
  public enabledReport: boolean = false;
  public enabledConclude: boolean = false;
  public enabledSend: boolean = false;
  public loadingReport: boolean = false;
  public loadingConclude: boolean = false;
  public loading: boolean = false;
  public fixedCost: FixedCostsModel = new FixedCostsModel();
  public modalModel = new ModalModel();
  public modalConfirmationModel = new ModalModel();
  private destroy$ = new Subject<void>();
  public fixedCostLoading: boolean = false;
  public eButtonsColor = ButtonColorEnum;
  public solicitations = new UpdateFixedCostModel();
  public sendBack: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public service: FixedCostService,
    private router: Router,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
    }>
  ) {}

  categoriesStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost.categories));
  fixedCostStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost));
  solicitationStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.updateSolicitation));
  loadingStore$ = this.store.select('loadingFixedCost').pipe((x) => x);

  ngOnInit(): void {
    this.fixedCostStore$.subscribe((fixedCostContent) => {
      if (fixedCostContent) {
        this.fixedCost = { ...fixedCostContent };
        this.form.controls.date.patchValue(
          this.formatDate(this.fixedCost.date)
        );
      }
    });
    this.solicitationStore$.subscribe((solicitations) => {
      if (solicitations) {
        this.solicitations = solicitations;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatDate(date: string) {
    if (date === undefined) return null;
    this.enabledReport = true;
    this.enabledConclude = true;
    this.enabledSend = true;
    return moment(date).locale('pt-br').clone().format('MM/YYYY');
  }

  getFixedCostReportDownload() {
    if (this.loadingReport) return;
    this.loadingReport = true;
    this.service
      .getFixedCostsReportByIdDownload(this.fixedCost.fixedCostId)
      .subscribe({
        next: this.handleFixedCostsReportDownloadSuccess.bind(this),
        error: this.handleFixedCostsReportDownloadError.bind(this),
        complete: () => {
          this.loadingReport = false;
        },
      });
  }

  handleFixedCostsReportDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as Intl.DateTimeFormatOptions;
    fileAnchor.download = `RelatorioSolicitacoes-${new Intl.DateTimeFormat(
      'pt-BR',
      options
    ).format(new Date())}.xlsx`;
    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
    this.loadingReport = false;
  }

  handleFixedCostsReportDownloadError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description =
      'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível realizar o download do relatório. ${message?.Message}`;
    this.modalModel.success = false;
    this.modalModel.showModal = true;
    this.loadingReport = false;
  }

  concludeFixedCosts() {
    this.enabledReport = false;
    this.enabledConclude = false;
    this.enabledSend = false;
    this.loadingConclude = true;

    this.modalConfirmationModel.buttonLabel = 'Entendi';
    this.modalConfirmationModel.description =
      'Após ser concluído, esse lançamento não poderá mais ser modificado.';
    this.modalConfirmationModel.imagePath =
      '../../../assets/icons/warning-600.gif';
    this.modalConfirmationModel.title = 'Tem certeza que deseja concluir?';
    this.modalConfirmationModel.success = false;
    this.modalConfirmationModel.showModal = true;
  }
  clickModal(confirmed: boolean) {
    if (confirmed) {
      this.service.ConcludeFixedCost(this.fixedCost.fixedCostId).subscribe({
        next: this.handleSuccessConcludeSuccess.bind(this),
        error: this.handleSuccessConcludeError.bind(this),
      });
    }

    this.enabledReport = true;
    if (this.sendBack) {
      this.router.navigate(['/fixed-costs']);
      this.store.dispatch(FixcedCostActions.clearFixedCost());
    }

    this.enabledReport = false;
    this.enabledConclude = true;
    this.enabledSend = true;
    this.loadingConclude = false;
    this.loadingReport = false;
    this.loading = false;
    this.modalModel.showModal = false;
    this.modalConfirmationModel.showModal = false;
  }

  handleSend() {
    this.enabledReport = false;
    this.enabledConclude = false;
    this.enabledSend = false;
    this.loading = true;

    this.service.UpdateFixedCost(this.solicitations).subscribe({
      next: this.handleSuccessUpdateSuccess.bind(this),
      error: this.handleSuccessUpdateError.bind(this),
    });
  }
  handleSuccessUpdateError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title =
      'Não foi possível atualizar as solicitações do lançamento!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  handleSuccessUpdateSuccess() {
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Atualização realizada com sucesso';
    this.modalModel.imagePath = '../../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Lançamento atualizado!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;

    this.sendBack = true;
  }
  handleSuccessConcludeError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Não foi possível concluir o lançamento!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;
  }

  handleSuccessConcludeSuccess() {
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = 'Atualização realizada com sucesso';
    this.modalModel.imagePath = '../../../../assets/icons/approve-modal.svg';
    this.modalModel.title = 'Lançamento concluido!';
    this.modalModel.success = false;
    this.modalModel.showModal = true;

    this.sendBack = true;
  }
}
