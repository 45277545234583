import { ConsumptionModel } from './consumption/consumption.model';
import { InitialConsumptionModel } from './consumption/initial-consumption.model';
import { FleetModel } from './fleet/fleet.models';
import { GrowingContractMaintenanceRealPerKmModel } from './maintenance/growing-contract-maintenance.model';
import { MaintenanceRealPerKmModel } from './maintenance/maintenance.model';
import { TiresVariableCostsModel } from './tires/tiresVariableCosts.model';

export class VariableCostsModel {
  public fleetModel!: FleetModel;
  public tiresVariableCostsModel!: TiresVariableCostsModel;
  public maintenanceRealPerKmModel!: MaintenanceRealPerKmModel;
  public growingContractMaintenanceRealPerKmModel: GrowingContractMaintenanceRealPerKmModel[] =
    [];
  public initialConsumptionModel!: InitialConsumptionModel;
  public consumptionModel: ConsumptionModel[] = [];
}

export class CVMaintenanceModel {
  tractorOwnMaintenance!: number;
  tractorFactoryMaintenance!: number;
  trailerMaintenance!: number;
  kmPlanned: number = 0;
}

export class CVTireModel {
  tireBrand!: string;
  tireTypeName!: string;
  tireQuantity!: number;
  tireCost!: number;
  retreadingQuantity!: number;
  retreadingCost!: number;
  tireLifespanKm!: number;
}

export class CVConsumptionUpdateModel {
  tractorConsumption!: number;
}

export class CVConsumptionModel {
  contractYear!: number;
  harvestYear!: string;
  tractorConsumption!: number;
}
