import { FleetActiveModel } from './fleet-active.model';
import { FleetConfigurationItemsModel } from './fleet-configuration-items.model';
import { FleetStoppedModel } from './fleet-stopped.model';

export class FleetModel {
  [key: string]: any;

  constructor() {
    this.active = new FleetActiveModel();
    this.stopped = new FleetStoppedModel();
    this.fleetConfigurationItems = [];
  }

  tractorEstimatedMonthlyKm!: number;
  anpDieselWithArlaCost!: number;
  averageTractorConsumption!: number;
  averageTractorMaintenance!: number;
  averageTrailerMaintenance!: number;
  averageTireUnitCostPerKm!: number;
  averageWashingCost!: number;
  active!: FleetActiveModel;
  stopped!: FleetStoppedModel;
  fleetConfigurationItems!: FleetConfigurationItemsModel[];
}
