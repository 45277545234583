import { UniformMaintenceWarehouseTeamModel } from './maintence-warehouse/uniform-maintenceWarehouse.model';
import { AdministrativeTeamUniformModel } from './management-team/administrative-team-uniform.model';
import { OfficeExpensesModel } from './office-expenses/office-expenses.model';
import { UniformOperationalTeamModel } from './operational-team/uniform-operationalTeam.model';

export class GeneralCostsModel {
  [key: string]: any;

  public totalMonthlyCostOfAdministrativeUniform!: number;
  public totalMonthlyCostOfOperationalUniform!: number;
  public totalMonthlyCostOfMaintenanceAndWarehouseUniform!: number;
  public totalMonthlyCostOfAdministrativeCosts!: number;
  public totalHarvestCostOfAdministrativeCosts!: number;
  public totalHarvestCostOfAdministrativeUniform!: number;
  public totalHarvestCostOfOperationalUniform!: number;
  public totalHarvestCostOfMaintenanceAndWarehouseUniform!: number;

  public administrativeCosts: OfficeExpensesModel;
  public administrativeUniform: AdministrativeTeamUniformModel;
  public operationalUniform: UniformOperationalTeamModel;
  public maintenanceAndWarehouseUniform: UniformMaintenceWarehouseTeamModel;

  constructor() {
    this.administrativeCosts = new OfficeExpensesModel();
    this.administrativeUniform = new AdministrativeTeamUniformModel();
    this.operationalUniform = new UniformOperationalTeamModel();
    this.maintenanceAndWarehouseUniform = new UniformMaintenceWarehouseTeamModel();
  }
}
