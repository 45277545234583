<app-card
  title="Grupo B - Base Incidência Cumulativa"
  icon="../../../../../assets/icons/Money-Icon.svg"
  [paddingHorizontal]="isLoading"
  [accordion]="true"
>
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-line [line]="6"></app-skeleton-line>
  </div>
  <div body *ngIf="!isMobile && !isLoading" class="table-container">
    <div class="infobox" *ngIf="!form.valid && !shouldDisableFields">
      <app-infobox [type]="eInfoboxType.warning">
        <span content>
          Os seguintes campos em vermelho e marcados com * são de preenchimento
          obrigatório.
        </span>
      </app-infobox>
    </div>
    <header>
      <span>DESCRIÇÃO</span>
      <span>ENCARGOS</span>
      <span>
        INSS RPS (A)
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>RPS:</b> Regulamento da Previdência Social</div>
          </div>
        </app-tooltip>
      </span>
      <span>
        FGTS RPS+IN25 (B)
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>IN25:</b> Instrução Normativa nº 25 (fiscalização do FGTS)</div>
          </div>
        </app-tooltip>
      </span>
    </header>
    <section class="rows-container">
      <div
        class="rows"
        *ngFor="
          let element of form.controls.rows.controls;
          let i = index
        "
      >
        <span [ngStyle]="{ color: isValid(element) }">{{ rowList[i] }}</span>
        <app-input
          [control]="element.controls.charges"
          placeholder="0,00"
          [showTitle]="false"
          [enableMessageError]="false"
          [height]="height"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="%"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          [control]="element.controls.inssRps"
          placeholder="0,00"
          [showTitle]="false"
          [enableMessageError]="false"
          [height]="height"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="%"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          [control]="element.controls.inssRpsIn25"
          placeholder="0,00"
          [showTitle]="false"
          [enableMessageError]="false"
          [height]="height"
          [mask]="eMaskType.money"
          [showSideIcon]="true"
          sideIconLabel="%"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
      </div>
      <div class="rows total">
        <span>Total</span>
        <label class="info-value">
          {{ totalBaseIncidenceBaseCount | number: '1.2-2' }} %
        </label>
        <label class="info-value">
          {{ totalBaseIncidenceInssCount | number: '1.2-2' }} %
        </label>
        <label class="info-value">
          {{ totalBaseIncidenceFgtsCount | number: '1.2-2' }} %
        </label>
      </div>
    </section>
  </div>
  <div body *ngIf="isMobile && !isLoading" class="card-container">
    <section class="card-item-container">
      <div
        class="item"
        *ngFor="
          let element of form.controls.rows.controls;
          let i = index
        "
      >
        <span class="card-header" [ngStyle]="{ color: isValid(element) }">
          {{ rowList[i] }}
        </span>
        <div class="card-content">
          <span>ENCARGOS</span>
          <app-input
            [control]="element.controls.charges"
            placeholder="0,00"
            [showTitle]="false"
            [enableMessageError]="false"
            [height]="height"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [disabled]="shouldDisableFields"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <span>
            INSS RPS (A)
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text"><b>RPS:</b> Regulamento da Previdência Social</div>
              </div>
            </app-tooltip>
          </span>
          <app-input
            [control]="element.controls.inssRps"
            placeholder="0,00"
            [showTitle]="false"
            [enableMessageError]="false"
            [height]="height"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [disabled]="shouldDisableFields"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <span>
            FGTS RPS+IN25 (B)
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text"><b>IN25:</b> Instrução Normativa nº 25 (fiscalização do FGTS)</div>
              </div>
            </app-tooltip>
          </span>
          <app-input
            [control]="element.controls.inssRpsIn25"
            placeholder="0,00"
            [showTitle]="false"
            [enableMessageError]="false"
            [height]="height"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [disabled]="shouldDisableFields"
            (updateValue)="handleUpdateValue()"
          ></app-input>
        </div>
      </div>
      <div class="item">
        <span class="card-header"> Total </span>
        <div class="card-content">
          <span>ENCARGOS</span>
          <label class="info-value">
            {{ totalBaseIncidenceBaseCount | number: '1.2-2' }} %
          </label>
          <span>
            INSS RPS (A)
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text"><b>RPS:</b> Regulamento da Previdência Social</div>
              </div>
            </app-tooltip>
          </span>
          <label class="info-value">
            {{ totalBaseIncidenceInssCount | number: '1.2-2' }} %
          </label>
          <span>
            FGTS RPS+IN25 (B)
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text"><b>IN25:</b> Instrução Normativa nº 25 (fiscalização do FGTS)</div>
              </div>
            </app-tooltip>
          </span>
          <label class="info-value">
            {{ totalBaseIncidenceFgtsCount | number: '1.2-2' }} %
          </label>
        </div>
      </div>
    </section>
  </div>
</app-card>
