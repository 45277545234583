import {
  Component, HostListener, Input, OnInit,
} from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss'],
})
export class UnitListComponent implements OnInit {
  @Input() unitList! : GetUnitModel[];

  public isMobile: boolean = false;
  public breakpointBase: number = 767;
  public widthBreakpoint: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  constructor(private util : Utils) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngOnInit(): void {
    this.checkDevice();
  }

  checkDevice() {
    this.isMobile = this.getWindowInnerWidth() <= this.widthBreakpoint;
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }
}
