export class TiresVariableCostsModel {
  [key: string]: any;

  public tireType!: string;
  public tireQuantity!: number;
  public realPerTire!: number;
  public retreadingQuantity!: number;
  public retreading!: number;
  public total!: number;
  public lifeKm!: number;
  public subtotalWithRepair!: number;
  public unitCostOfTiresPerKm!: number;
  public tireBrand!: string;
}
