import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import { Utils } from '../../../../../common/utils/utils';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.scss'],
})
export class CategoryListItemComponent {
  @Input() category!: GetCategoryModel;

  public StatusColorEnum = StatusColorEnum;

  constructor(
    private router: Router,
    public util: Utils,
  ) { }

  handleDataClick(id: number) {
    this.router.navigate(['/admin/category/', id]);
  }
}
