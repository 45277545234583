<div class="reset-container" *ngIf="!resetPass">
  <div class="form-container">
    <header>
      <app-buttons
        label="Voltar"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="false"
        (clickButton)="onBack()"
      ></app-buttons>
    </header>
    <div *ngIf="!tokenIsValid">
      <app-infobox [type]="eInfoboxType.warning">
        <div content>
          <span
            >Parece que você clicou em um link inválido ou expirado de reset de
            senha. Por favor, solicite novamente o reset de senha.</span
          >
        </div>
      </app-infobox>
    </div>
    <span class="description">
      Favor informar o seu usuário, para que possamos iniciar o processo de
      reset de senha.
    </span>
    <div class="input-tooltip-container">
      <div class="input">
        <app-input
          [control]="form.controls.user"
          label="Usuário"
          placeholder="Informe seu usuário"
          errorMessage="Este campo é obrigatório"
          [mask]="eMaskType.username"
          [showSideIcon]="true"
          [sideIconLabel]="'../../../assets/icons/user-common-600.svg'"
          [sideIconType]="eSideIconType.image"
        >
          <app-tooltip [top]="true">
            <div class="tooltip-info">
              <div class="text">
                <label class="bold">Usuários Raízen:</label> utilizar o seu CS
              </div>
              <div class="text">
                <label class="bold">Empresas:</label> utilizar o seu CNPJ
              </div>
            </div>
          </app-tooltip>
        </app-input>
      </div>
    </div>
    <div [formGroup]="form" class="captcha-container">
      <re-captcha formControlName="recaptcha"></re-captcha>
    </div>
    <app-buttons
      label="SOLICITAR"
      [type]="eButtonsType.primary"
      [color]="eButtonsColor.primary"
      [enabled]="enableButtonRequest"
      [animation]="true"
      [loading]="loadingButton"
      (clickButton)="clickButton()"
    ></app-buttons>
  </div>
</div>
<div class="alter-container" *ngIf="resetPass">
  <div class="form-container">
    <span class="description">
      Olá, {{ name }}, favor informar sua nova senha:
    </span>
    <div class="password">
      <app-input
        [control]="formPass.controls.password"
        label="Nova Senha"
        [type]="eInputType.password"
        placeholder="Informe sua nova senha"
        errorMessage="Este campo é obrigatório"
        [passwordValidation]="true"
        (passValid)="passIsValidOutput($event)"
      ></app-input>
      <app-input
        [control]="formPass.controls.validatePassword"
        label="Confirmar Nova Senha"
        [type]="eInputType.password"
        placeholder="Informe sua nova senha"
        errorMessage="{{ passErrorMessage }}"
      ></app-input>
    </div>
    <app-buttons
      label="SALVAR"
      [type]="eButtonsType.primary"
      [color]="eButtonsColor.primary"
      [enabled]="enableButton"
      [animation]="true"
      [loading]="loadingButton"
      (clickButton)="clickButton()"
    ></app-buttons>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="closeModal()"
></app-modal>
