import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin/admin.service';
import { GetContextModel } from '../../services/admin/models/get-context-model';
import { AdminCardModel } from './models/admin-card.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public cards : AdminCardModel[] = [];
  public names : string[] = ['Usuários', 'Empresas', 'Unidades', 'Categorias', 'Subcategorias', 'Operações'];
  public contexts: GetContextModel = new GetContextModel();
  public isLoading: boolean = true;

  constructor(
    public route: Router,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.getContextsCount();
  }

  generateModel() {
    this.names.forEach((element) => {
      this.cards.push({
        name: element,
        value: this.getAmount(element),
        icon: this.getRegisterIcon(element),
      });
    });
  }

  getAmount(value: string) {
    switch (value) {
      case 'Usuários':
        return (this.contexts.analystsActivesCount
          + this.contexts.analystsInactivesCount).toString();
      case 'Empresas':
        return (this.contexts.operatorsActivesCount
          + this.contexts.operatorsInactivesCount).toString();
      case 'Unidades':
        return (this.contexts.unitsActivesCount
          + this.contexts.unitsInactivesCount).toString();
      case 'Categorias':
        return (this.contexts.categoriesActivesCount
          + this.contexts.categoriesInactivesCount).toString();
      case 'Subcategorias':
        return (this.contexts.subcategoriesActivesCount
          + this.contexts.subcategoriesInactivesCount).toString();
      case 'Operações':
        return (this.contexts.operationsActivesCount
          + this.contexts.operationsInactivesCount).toString();
      default:
        return '';
    }
  }

  getRegisterIcon(value:string) {
    switch (value) {
      case 'Usuários':
        return '../../../assets/icons/person.svg';
      case 'Empresas':
        return '../../../assets/icons/operator-primary-600.svg';
      case 'Unidades':
        return '../../../assets/icons/units-primary-600.svg';
      case 'Categorias':
        return '../../../assets/icons/categories-primary-600.svg';
      case 'Subcategorias':
        return '../../../assets/icons/subcategories-primary-600.svg';
      case 'Operações':
        return '../../../assets/icons/hammer-primary.svg';
      default:
        return '';
    }
  }

  navigateToPage(page: string) {
    switch (page) {
      case 'Usuários':
        return this.route.navigate(['/admin/analyst']);
      case 'Empresas':
        return this.route.navigate(['/admin/operator']);
      case 'Unidades':
        return this.route.navigate(['/admin/unit']);
      case 'Categorias':
        return this.route.navigate(['/admin/category']);
      case 'Subcategorias':
        return this.route.navigate(['/admin/subcategory']);
      case 'Operações':
        return this.route.navigate(['/admin/operation']);
      default:
        return '';
    }
  }

  navigateToValueLimit() {
    this.route.navigate(['/admin/config/value-limit']);
  }

  navigateCoverImage() {
    this.route.navigate(['/admin/config/cover-image']);
  }

  getContextsCount() {
    this.adminService.getContextsCount().subscribe({
      next: (result) => {
        this.contexts = result;
        this.generateModel();
        this.isLoading = false;
      },
      error: () => {
        this.generateModel();
        this.isLoading = false;
      },
    });
  }
}
