import { FixedRemunerationExpenseModel } from './summary/fixed-remuneration-expense.model';
import { LineVariableCostExpenseModel } from './summary/line-variable-cost-expense.model';
import { OperationalExpenseModel } from './summary/operational-expense.model';
import { OperationalTeamExpenseModel } from './summary/operational-team-expense.model';
import { PersonalExpenseModel } from './summary/personal-expense.model';

export class SummaryModel {
  totalFixedCost: number = 0;
  totalFixedCostWithTax: number = 0;
  totalVariableCost: number = 0;
  totalVariableCostWithTax: number = 0;

  fixedRemunerationExpense: FixedRemunerationExpenseModel = new FixedRemunerationExpenseModel();
  operationalTeamExpense: OperationalTeamExpenseModel = new OperationalTeamExpenseModel();
  personalExpense: PersonalExpenseModel = new PersonalExpenseModel();
  operationalExpense: OperationalExpenseModel = new OperationalExpenseModel();
  lineVariableCostExpense: LineVariableCostExpenseModel = new LineVariableCostExpenseModel();
}
