import {
  Component, EventEmitter, Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from 'src/app/common/utils/utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { FleetModalNewConfigModel } from './models/fleet-new-config.model';

@Component({
  selector: 'app-fleet-modal-new-config',
  templateUrl: './fleet-modal-new-config.component.html',
  styleUrls: ['./fleet-modal-new-config.component.scss'],
})
export class FleetModalNewConfigComponent implements OnInit, OnChanges {
  @Input() shouldDisableFields: boolean = false;
  @Input() showModal: boolean = false;
  @Input() newConfigModel!: FleetModalNewConfigModel;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() addOrUpdateConfig: EventEmitter<FleetModalNewConfigModel> = new EventEmitter();
  @Output() deleteConfig: EventEmitter<any> = new EventEmitter();

  // Control
  public isLoading: boolean = false;
  public isEditing: boolean = false;
  public isSaving: boolean = false;
  public isDeleting: boolean = false;
  public enableDelete: boolean = false;
  public enableSave: boolean = false;
  public active: boolean = true;
  public modalModel: ModalModel = new ModalModel();

  // Form
  public form = new FormGroup({
    tractorQuantity: new FormControl('', Validators.required),
    tractorFinancingStatus: new FormControl('', Validators.required),
    tractorManufacturer: new FormControl('', Validators.required),
    tractorModel: new FormControl('', Validators.required),
    tractorYear: new FormControl('', [Validators.required, Validators.minLength(4)]),
    tractorResidual: new FormControl('', Validators.required),
    tractorStartDate: new FormControl('', Validators.required),

    trailerFinancingStatus: new FormControl('', Validators.required),
    trailerImplement: new FormControl('', Validators.required),
    trailerMaterial: new FormControl('', Validators.required),
    trailerType: new FormControl('', Validators.required),
    trailerYear: new FormControl('', [Validators.required, Validators.minLength(4)]),
    trailerResidual: new FormControl('', Validators.required),
    trailerStartDate: new FormControl('', Validators.required),

  });

  public tractorFinancingStatusSelected: string[] = [];
  public tractorFinancingStatusOptions: string[] = ['Finame', 'Leasing', 'Quitado', 'Raízen'];
  public manufacturerSelected: string[] = [];
  public manufacturerOptions: string[] = ['Scania', 'Volvo', 'Mercedes'];
  public modelSelected: string[] = [];
  public modelOptions: string[] = ['Scania 124 420', 'Volvo FH 540', 'MB 3340'];
  public tractorMonthYearEntrySelected: string[] = [];
  public tractorMonthYearEntryOptions: string[] = [];
  public trailerFinancingStatusSelected: string[] = [];
  public trailerFinancingStatusOptions: string[] = ['Finame', 'Leasing', 'Quitado', 'Raízen'];
  public implementSelected: string[] = [];
  public implementOptions: string[] = ['Randon', 'Facchini', 'Usicamp', 'Outro'];
  public materialSelected: string[] = [];
  public materialOptions: string[] = ['Alumínio', 'Aço', 'Híbrido'];
  public typeSelected: string[] = [];
  public typeOptions: string[] = ['Rodotrem'];
  public trailerMonthYearEntrySelected: string[] = [];
  public trailerMonthYearEntryOptions: string[] = [];

  // Enum
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eButtonsType = ButtonsType;
  public eSideIconType = SideIconTypeEnum;
  public ButtonColorEnum = ButtonColorEnum;

  constructor(public utils: Utils) { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.validateAllFormFields();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newConfigModel']) {
      if (this.newConfigModel?.index !== undefined && this.newConfigModel?.index >= 0) {
        this.setFormData();
        this.setModel();
      } else {
        this.isEditing = false;
      }
    }
  }

  setFormData() {
    this.form.controls.tractorQuantity.patchValue(this.newConfigModel.tractorQuantity);
    this.form.controls.tractorFinancingStatus.patchValue(this.newConfigModel.tractorFinancingStatus);
    this.form.controls.tractorManufacturer.patchValue(this.newConfigModel.tractorManufacturer);
    this.form.controls.tractorModel.patchValue(this.newConfigModel.tractorModel);
    this.form.controls.tractorYear.patchValue(this.newConfigModel.tractorYear);
    this.form.controls.tractorResidual.patchValue(this.newConfigModel.tractorResidual);
    this.form.controls.tractorStartDate.patchValue(this.utils.convertToInvariantDate(this.newConfigModel.tractorMonthYearEntry));
    this.form.controls.trailerFinancingStatus.patchValue(this.newConfigModel.trailerFinancingStatus);
    this.form.controls.trailerImplement.patchValue(this.newConfigModel.trailerImplement);
    this.form.controls.trailerMaterial.patchValue(this.newConfigModel.trailerMaterial);
    this.form.controls.trailerType.patchValue(this.newConfigModel.trailerType);
    this.form.controls.trailerYear.patchValue(this.newConfigModel.trailerYear);
    this.form.controls.trailerResidual.patchValue(this.newConfigModel.trailerResidual);
    this.form.controls.trailerStartDate.patchValue(this.utils.convertToInvariantDate(this.newConfigModel.trailerMonthYearEntry));

    this.trailerFinancingStatusSelected = [this.newConfigModel.trailerFinancingStatus];
    this.tractorFinancingStatusSelected = [this.newConfigModel.tractorFinancingStatus];
    this.implementSelected = [this.newConfigModel.trailerImplement];
    this.materialSelected = [this.newConfigModel.trailerMaterial];
    this.typeSelected = [this.newConfigModel.trailerType];
    this.manufacturerSelected = [this.newConfigModel.tractorManufacturer];

    this.isEditing = true;
  }

  handleCancel() {
    this.clearForm();
    this.closeModal.emit();
  }

  toogleCheck() {
    this.active = !this.active;
  }

  handleAddOrUpdate() {
    const newConfig = {
      active: this.active,
      tractorQuantity: this.form.controls.tractorQuantity.value ?? '',
      tractorFinancingStatus: this.form.controls.tractorFinancingStatus.value ?? '',
      tractorManufacturer: this.form.controls.tractorManufacturer.value ?? '',
      tractorModel: this.form.controls.tractorModel.value ?? '',
      tractorYear: this.form.controls.tractorYear.value ?? '',
      tractorMonthYearEntry: this.form.controls.tractorStartDate.value ?? '',
      tractorResidual: this.form.controls.tractorResidual.value ?? '',
      trailerFinancingStatus: this.form.controls.trailerFinancingStatus.value ?? '',
      trailerImplement: this.form.controls.trailerImplement.value ?? '',
      trailerMaterial: this.form.controls.trailerMaterial.value ?? '',
      trailerType: this.form.controls.trailerType.value ?? '',
      trailerYear: this.form.controls.trailerYear.value ?? '',
      trailerMonthYearEntry: this.form.controls.trailerStartDate.value ?? '',
      trailerResidual: this.form.controls.trailerResidual.value ?? '',
      index: this.newConfigModel.index,
    } as FleetModalNewConfigModel;

    this.addOrUpdateConfig.emit(newConfig);

    this.clearForm();
  }

  handleDelete() {
    this.deleteConfig.emit(this.newConfigModel.index);
    this.clearForm();
  }

  clearForm() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.reset();
      this.form.get(key)?.setErrors(null);
    });

    this.form.controls.trailerStartDate.patchValue(null);
    this.form.controls.trailerStartDate.reset();
    this.form.controls.trailerStartDate.setErrors(null);
    this.form.controls.tractorStartDate.patchValue(null);
    this.form.controls.tractorStartDate.reset();
    this.form.controls.tractorStartDate.setErrors(null);

    this.trailerFinancingStatusSelected = [];
    this.tractorFinancingStatusSelected = [];
    this.implementSelected = [];
    this.materialSelected = [];
    this.typeSelected = [];
    this.manufacturerSelected = [];
    this.modelSelected = [];
  }

  setModel() {
    this.modelSelected = [];

    switch (this.form.controls.tractorManufacturer.value) {
      case 'Scania':
        this.modelSelected.push('Scania 124 420');
        this.form.controls.tractorModel.patchValue('Scania 124 420');
        break;
      case 'Volvo':
        this.modelSelected.push('Volvo FH 540');
        this.form.controls.tractorModel.patchValue('Volvo FH 540');
        break;
      case 'Mercedes':
        this.modelSelected.push('MB 3340');
        this.form.controls.tractorModel.patchValue('MB 3340');
        break;
    }
  }

  validateAllFormFields() {       
    this.enableSave = true;
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if(!control.value) this.enableSave = false;
      }
    });
  }
}
