<article>
  <header>
    <div class="steps">
      <div
        class="step"
        *ngFor="let step of steps; let i = index"
        [ngClass]="{ last: i === steps.length - 1 }"
      >
        <div class="up">
          <ng-container *ngTemplateOutlet="step!.stepBox!"></ng-container>
          <div
            *ngIf="i < steps.length - 1"
            class="progress-bar"
            [ngClass]="{ previous: i < currentStepIndex }"
          >
            <div class="progress"></div>
          </div>
        </div>
        <div class="down">
          <ng-container *ngTemplateOutlet="step!.title!"></ng-container>
        </div>
      </div>
    </div>
  </header>
  <div class="content">
    <div class="forms" *ngFor="let step of steps; let i = index">
      <div
        class="form"
        [ngClass]="{
          current: i === currentStepIndex,
          next: isNextStep,
          previous: isPreviousStep
        }"
      >
        <ng-container *ngTemplateOutlet="step!.content!"> </ng-container>
      </div>
    </div>
  </div>
  <section [ngClass]="{ 'first-step': isFirstStep() }">
    <app-secondary-button
      *ngIf="!isFirstStep()"
      [label]="'Voltar'"
      (click)="backPreviousStep()"
      [right]="false"
      [icon]="'../../../assets/icons/arrow-previous-primary-600.svg'"
      [enabled]="true"
    ></app-secondary-button>
    <app-primary-button
      [loading]="loading"
      [animation]="true"
      [label]="isLastStep() ? 'Enviar' : 'Avançar'"
      [enabled]="steps[currentStepIndex] && steps[currentStepIndex].valid"
      (click)="onClickHandler()"
      [right]="true"
      [icon]="'../../../assets/icons/arrow-right-common-100.svg'"
    ></app-primary-button>
  </section>
</article>
