import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, Input, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HistoryItemTypeEnum } from '../enums/history-item-type.enum';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({})),
      state('final', style({
        height: '0',
        paddingBottom: '0',
        opacity: '0',
      })),
      transition('initial <=> final', animate('300ms')),
    ]),
  ],
})
export class HistoryItemComponent implements OnInit {

  @Input() type = HistoryItemTypeEnum.normal;
  @Input() date: Date = new Date();
  @Input() accordion: boolean = false;
  @Input() hasDate: boolean = true;
  @ViewChild('content') content!: TemplateRef<ElementRef>;

  public open: boolean = true;
  public first: boolean = false;
  public last: boolean = false;
  public dateItem = moment();

  constructor() {}

  toggleAccordion() {
    this.open = !this.open;
  }

  ngOnInit(): void {
    this.dateItem = moment(this.date).clone();
  }
}
