export class ScopeConsumptionModel {
  [key : string] : number;

  public lightFleetFuelCost: number = 0;
  public lightFleetConsumption: number = 0;
  public collectiveTransportFuelCost: number = 0;
  public collectiveTransportConsumption: number = 0;
  public arlaCost: number = 0;
  public washingCost: number = 0;
  public anpDieselCost: number = 0;
}
