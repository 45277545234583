<div class="container">
  <app-card
    [accordion]="true"
    [icon]="'../../../../assets/icons/Curve-Arrow-Purple.svg'"
    [title]="'Lançamentos Realizados'"
    [paddingHorizontal]="false"
    [accordion]="false"
  >
    <div class="tooltip-container" body>
      <app-tooltip [right]="true">
        <div class="tooltip-info">
          <span class="tooltip-title">Valor Total:</span>
          <span class="tooltip-desc">
            Valor das solicitações Enviadas + Pendentes + Excluídas</span
          ><br />
          <span class="tooltip-title">Valor Atual:</span>
          <span class="tooltip-desc">
            Valor das solicitações Enviadas + Pendentes</span
          ><br />
          <span class="tooltip-title">Valor Enviado:</span>
          <span class="tooltip-desc"> Valor das solicitações Enviadas</span
          ><br />
          <span class="tooltip-title">Valor a ser Pago:</span>
          <span class="tooltip-desc"> Valor das solicitações Selecionadas</span
          ><br />
        </div>
      </app-tooltip>
    </div>
    <div class="button-container" body>
      <app-icon-button
        icon="../../../../assets/icons/spreadsheet-download.svg"
        text="Baixar"
        [color]="ebuttonColor.primary"
        [enabled]="fixedCosts &&  fixedCosts.length >0"
        [loading]="loadingReport"
        (clickButton)="getFixedCostReportDownload()"
      ></app-icon-button>
    </div>
    <div body class="card-container" *ngIf="!showSkeleton">
      <div *ngFor="let fc of fixedCosts ">
        <div class="card-item" (click) ="viewFixedCost(fc)">
          <div class="id">
            <span>{{util.addLeadingZeros(fc.fixedCostId, 5)}}</span>
          </div>
          <div class="content">
            <div class="content-header">
              <div class="period">
                <span>Período {{formatDate(fc.date)}}</span>
              </div>
              <div class="status-container">
                <app-status
                  [color]="statusColorEnum.attention"
                  [image]="'../../../../assets/icons/Timer Yellow Outline.svg'"
                  [status]="fc.totalSolicitationsPending.toString()"
                ></app-status>
                <app-status
                  [color]="statusColorEnum.success"
                  [image]="'../../../../assets/icons/Success-green.svg'"
                  [status]="fc.totalSolicitationsSent.toString()"
                ></app-status>
                <app-status
                  [color]="statusColorEnum.warning"
                  [image]="'../../../../assets/icons/Cross Red.svg'"
                  [status]="fc.totalSolicitationsNotSent.toString()"
                ></app-status>
                <app-status
                  [color]="getStatusColor(fc.fixedCostStatusName)"
                  [image]="getStatusIcon(fc.fixedCostStatusName)"
                  [status]="fc.fixedCostStatusName"
                ></app-status>
              </div>
            </div>
            <div class="content-body">
              <div class="content-body-item">
                <span>Valor Total</span>
                <span class="value-gray">{{fc.totalValue | currency:'R$'}}</span>
              </div>
              <div class="content-body-item">
                <span>Valor Atual</span>
                <span
                  class="value-gray"
                  >{{fc.currentValue | currency:'R$'}}</span
                >
              </div>
              <div class="content-body-item">
                <span>Valor Enviado</span>
                <span class="value-green">{{fc.valueSent | currency:'R$'}}</span>
              </div>
              <div class="content-body-item" *ngIf="fc.fixedCostStatusName !== 'Concluido' ">
                <span>Valor a ser Pago</span>
                <span
                  class="value-orange"
                  >{{fc.valueToBePaid | currency:'R$'}}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skeleton" body *ngIf="showSkeleton">
      <app-skeleton-line [line]="5"></app-skeleton-line>
    </div>
    <app-not-found body *ngIf="showNotFound && !showSkeleton"></app-not-found>
  </app-card>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
