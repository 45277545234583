import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { CategoryPimsModel } from '../../category/models/category-pims.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryPimsService extends BaseService {
  constructor(private http: HttpClient) {
    super('categories');
  }

  getCategoryPimsById(categoryPimsId: number) {
    return this.http.get<CategoryPimsModel>(`${this.urlPims}/${categoryPimsId}`);
  }
}
