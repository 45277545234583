/* eslint-disable max-len */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Utils } from 'src/app/common/utils/utils';
import { FleetTrailerModel } from 'src/app/services/dfdv/fleet/models/fleet-trailer.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table-trailer',
  templateUrl: './fleet-table-trailer.component.html',
  styleUrls: ['./fleet-table-trailer.component.scss'],
})
export class FleetTableTrailerComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() trailerList: FleetTrailerModel[] = [];
  @Input() rowStatus: FleetTableRowStatusEnum[] = [];

  public trailerHeader: string[] = [
    'ATIVO/PARADO',
    'STATUS FINANCIAMENTO',
    'IMPLEMENTO',
    'MATERIAL',
    'TIPO',
    'VALOR CONJUNTO',
    'VALOR DO PNEU',
    'VALOR BASE LUCRO',
    'VALOR IMPOSTO (PIS/COFINS)',
    'VALOR IMPOSTO (ICMS)',
    'VALOR LÍQUIDO P/ DEPRECIAÇÃO',
    'VALOR LÍQUIDO P/ REMUNERAÇÃO CAPITAL',
    'ANO CONJUNTO',
    'MÊS/ANO ENTRADA',
    'PERÍODO DEPRECIAÇÃO (EM MESES)',
    'CICLO DE UTILIZAÇÃO',
    'RESIDUAL CONJUNTO',
  ];

  public trailerContent: string[][] = [];

  public trailerActiveContent: string[] = [];

  public trailerStopedContent: string[] = [];

  public trailerRowStatus: FleetTableRowStatusEnum[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trailerList']) {
      this.buildTable();
    }
  }

  constructor(public utils: Utils) {
  }

  buildTable() {
    const columnTotalsActive = Array(this.trailerHeader.length - 1).fill(0);
    const columnTotalsStopped = Array(this.trailerHeader.length - 1).fill(0);

    if (!this.trailerList?.length) {
      const initialValues = Array(this.trailerHeader.length - 1).fill('-');
      this.trailerActiveContent = initialValues;
      this.trailerStopedContent = initialValues;
      return;
    }

    this.trailerContent = [];
    this.trailerActiveContent = [];
    this.trailerStopedContent = [];
    this.trailerRowStatus = [];
    this.trailerRowStatus = this.rowStatus;

    this.trailerList.forEach((x, index) => {
      const keys = Object.keys(x).filter((z) => z !== 'active');

      if (this.trailerRowStatus[index] === FleetTableRowStatusEnum.ACTIVE) {
        keys.forEach((key, i) => {
          if (key === 'trailerYear') {
            columnTotalsActive[i] = 0;
          } else if (typeof x[key] === 'number') {
            columnTotalsActive[i] += x[key];
          }
        });
      } else {
        keys.forEach((key, i) => {
          if (key === 'trailerYear') {
            columnTotalsStopped[i] = 0;
          } else if (typeof x[key] === 'number') {
            columnTotalsStopped[i] += x[key];
          }
        });
      }

      this.trailerContent.push([
        (index + 1).toString(),
        x.trailerFinancingStatus,
        x.trailerImplement,
        x.trailerMaterial,
        x.trailerType,
        this.utils.toMoney(x.trailerCost.toString()),
        this.utils.toMoney(x.trailerTireCost.toString()),
        this.utils.toMoney(x.trailerBaseProfit.toString()),
        this.utils.toMoney(x.trailerPisCofinsCost.toString()),
        this.utils.toMoney(x.trailerIcmsCost.toString()),
        this.utils.toMoney(x.trailerNetValueDepreciation.toString()),
        this.utils.toMoney(x.trailerNetValueRemuneration.toString()),
        x.trailerYear.toString(),
        moment(x.trailerAcquisitionDate).locale('pt-br').format("MMMM/YYYY").toUpperCase(),
        x.trailerDepreciationPeriod.toString(),
        this.utils.numberFormatter(x.trailerUsageCycle, 2, 2),
        this.utils.numberFormatter(x.trailerResidual, 2, 2) + ' %',
      ]);
    });

    const activeItemCount = this.trailerRowStatus.filter((x) => x === FleetTableRowStatusEnum.ACTIVE).length;
    const columnActiveAverages = columnTotalsActive.map((total) => (activeItemCount > 0 ? total / activeItemCount : 0));
    this.trailerActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    this.trailerActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.trailerActiveContent.length; i++) {
      if ((i === this.trailerActiveContent.length - 2 || i === this.trailerActiveContent.length - 3) && this.trailerActiveContent[i] !== '-') {
        this.trailerActiveContent[i] = this.trailerActiveContent[i].replace('.', ',');
      } else if (i === this.trailerActiveContent.length - 1 && this.trailerActiveContent[i] !== '-') {
        this.trailerActiveContent[i] = this.trailerActiveContent[i] + ' %'
      } else if(this.trailerActiveContent[i] !== '-') {
        this.trailerActiveContent[i] = this.utils.toMoney(this.trailerActiveContent[i]);
      }
    }


    const stoppedItemCount = this.trailerRowStatus.filter((x) => x === FleetTableRowStatusEnum.STOPED).length;
    const columnStoppedAverages = columnTotalsStopped.map((total) => (stoppedItemCount > 0 ? total / stoppedItemCount : 0));
    this.trailerStopedContent = columnStoppedAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));
    for (let i = 0; i < this.trailerStopedContent.length; i++) {
      if ((i === this.trailerStopedContent.length - 2 || i === this.trailerStopedContent.length - 3) && this.trailerStopedContent[i] !== '-') {
        this.trailerStopedContent[i] = this.trailerStopedContent[i].replace('.', ',');
      } else if (i === this.trailerStopedContent.length - 1 && this.trailerStopedContent[i] !== '-') {
        this.trailerStopedContent[i] = this.trailerStopedContent[i] + ' %'
      } else if(this.trailerStopedContent[i] !== '-') {
        this.trailerStopedContent[i] = this.utils.toMoney(this.trailerStopedContent[i]);
      }
    }
  }
}
