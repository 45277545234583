<div class="variable-costs-container">
  <section>
    <header>
      <app-sumarize-header currentPageTitle="Custos Variáveis"></app-sumarize-header>
    </header>
    <div class="tab-container">
      <app-tab #tabVariableCost>
        <app-tab-item title="CV Frota" #tabFleet>
          <app-variable-costs-fleet
            [isLoading]="isLoading"
            [dfdv]="dfdv"
          ></app-variable-costs-fleet>
        </app-tab-item>
        <app-tab-item title="CV Manutenção" #tabMaintenance>
          <app-maintenance
            [isLoading]="isLoading"
            [maintenanceForm]="maintenanceForm"
            [shouldDisableFields]="disableFieldsMaintenance"
            [disableFieldKM]="disableFieldKMMaintenance"
            (updateValue)="updateDFDVStore()"
          ></app-maintenance>
        </app-tab-item>
        <app-tab-item title="CV Consumo" #tabConsumption>
          <app-consumption
            [isLoading]="isLoading"
            [consumptionList]="dfdv.cvConsumption"
            [consumptionForm]="consumptionForm"
            [shouldDisableFields]="disableFieldConsumption"
            (updateValue)="updateDFDVStore()"
          ></app-consumption>
        </app-tab-item>
        <app-tab-item title="CV Pneus" #tabTyres>
          <app-tires
            [isLoading]="isLoading"
            [unitCostTotal]="unitCostTotal"
            [subtotalWithFix]="subtotalWithFix"
            [unitCostOfTiresPerKm]="unitCostOfTiresPerKm"
            [tiresForm]="tiresForm"
            [shouldDisableFields]="disableFieldsTires"
            [disableFieldQuantity]="disableFieldQuantityTires"
            [selectedTireTypes]="tireTypeSelected"
            (updateValue)="updateDFDVStore()"
          ></app-tires>
        </app-tab-item>
      </app-tab>
    </div>
  </section>
</div>
