/* eslint-disable no-param-reassign */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import {
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { Utils } from 'src/app/common/utils/utils';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ScopeConsumptionModel } from 'src/app/services/dfdv/scope/model/scope-consumption/scope-consumption.model';
import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';
import { ScopeEquipmentModel } from 'src/app/services/dfdv/scope/model/scope-equipment/scope-equipment.model';
import { ScopeFeeModel } from 'src/app/services/dfdv/scope/model/scope-fee/scope-fee.model';
import { ScopeRentalModel } from 'src/app/services/dfdv/scope/model/scope-rental/scope-rental.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { TabItemComponent } from 'src/app/shared/tab/tab-item/tab-item.component';
import { TabComponent } from 'src/app/shared/tab/tab.component';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss'],
})
export class ScopeComponent implements OnInit, OnDestroy {
  @ViewChild('tabScope') tabScope!: TabComponent;
  @ViewChild('tabEquipment') tabEquipment!: TabItemComponent;
  @ViewChild('tabRental') tabRental!: TabItemComponent;
  @ViewChild('tabConsumption') tabConsumption!: TabItemComponent;
  @ViewChild('tabFee') tabFee!: TabItemComponent;

  public Utils: Utils = new Utils();

  public ButtonColorEnum = ButtonColorEnum;
  public tabError: string = '';
  public dfdv!: GetDfdvModel;
  public isLoading: boolean = false;
  public shouldDisableFields: boolean = false;
  public disableFieldOPL: boolean = false;
  public disableFieldRaizen: boolean = false;
  public dfdvUtils!: DfdvUtils;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  public equipmentForm = this.formBuilder.group({
    tractorCost: [''],
    trailerCost: [''],
    lightFleetQuantity: [''],
    lineTractorQuantity: [''],
    maneuverTractorQuantity: [''],
    tractorQuantity: [''],
    trailerQuantity: [''],
    jointFactor: [''],
    tractorTireQuantity: [''],
    trailerTireQuantity: [''],
    lineTractorEstimatedMonthlyKm: [''],
    maneuverTractorEstimatedMonthlyKm: [''],
    tractorLifespan: [''],
    trailerLifespan: [''],
    operationDayDrivenKm: [''],
    exchangeDayDrivenKm: [''],
  });

  public rentalForm = this.formBuilder.group({
    lightFleetRental: [''],
    collectiveTransportRental: [''],
    workshopTruckRental: [''],
    tractorRental: [''],
    trailerRental: [''],
  });

  public consumptionForm = this.formBuilder.group({
    lightFleetFuelCost: [''],
    lightFleetConsumption: [''],
    collectiveTransportFuelCost: [''],
    collectiveTransportConsumption: [''],
    arlaCost: [''],
    washingCost: [''],
    anpDieselCost: [''],
  });

  public feeForm = this.formBuilder.group({
    dpvat: [''],
    licensing: [''],
    rctrc: [''],
    rcfv: [''],
    ipvaAndLicensingFee: [''],
    ipvaWithDiscount: [''],
    mandatoryInsurance: [''],
    crlvEmissionFee: [''],
    capitalRemuneration: [''],
    hasIpvaAndLicensingFee: [] as boolean[],
  });

  public equipmentModel: ScopeEquipmentModel = new ScopeEquipmentModel();
  public rentalModel: ScopeRentalModel = new ScopeRentalModel();
  public consumptionModel: ScopeConsumptionModel = new ScopeConsumptionModel();
  public feeModel: ScopeFeeModel = new ScopeFeeModel();

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  scopeStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.scope));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngOnInit(): void {
    this.getDFDV();

    this.equipmentForm.controls.lineTractorQuantity.valueChanges.subscribe(() =>
      this.calcTractorQuantity()
    );
    this.equipmentForm.controls.maneuverTractorQuantity.valueChanges.subscribe(
      () => this.calcTractorQuantity()
    );
    this.equipmentForm.controls.trailerQuantity.valueChanges.subscribe(() =>
      this.calcJointFactor()
    );
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.isLoading = true;
    this.unSubscribe = this.scopeStore$.subscribe((scope) => {
      if (!scope) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.isLoading = isLoading.loading;
            });

          if (!this.isLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        this.isLoading = false;
        this.populateForms(scope);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        this.shouldDisableFields = !this.dfdvUtils.isEnableToEdit();
        this.disableFieldOPL =
          !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
        this.disableFieldRaizen =
          this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
      }
    });
  }

  updateDFDVStore() {
    const model = new ScopeDfdvModel();
    this.processModels(model);

    const formModels = [
      {
        form: this.equipmentForm,
        model: model.equipment,
        name: 'equipment',
      },
      {
        form: this.rentalForm,
        model: model.rental,
        name: 'rental',
      },
      {
        form: this.consumptionForm,
        model: model.consumption,
        name: 'consumption',
      },
      {
        form: this.feeForm,
        model: model.fee,
        name: 'fee',
      },
    ];

    formModels.forEach((formModel) => {
      this.processForm(formModel.form, formModel.model);
    });

    this.dfdv.scope = model;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'scope',
        data: this.dfdv.scope,
      })
    );
  }

  populateForms(model: ScopeDfdvModel) {
    const formModels = [
      {
        form: this.equipmentForm,
        model: model.equipment,
      },
      {
        form: this.rentalForm,
        model: model.rental,
      },
      {
        form: this.consumptionForm,
        model: model.consumption,
      },
      {
        form: this.feeForm,
        model: model.fee,
      },
    ];

    formModels.forEach((formModel) => {
      this.populateForm(formModel.form, formModel.model);
    });
  }

  populateForm(form: FormGroup, model: any) {
    Object.keys(form.controls).forEach((key) => {
      form.get(key)?.patchValue(model[key] ?? '');
    });
  }

  resetForms() {
    const forms = [
      this.equipmentForm,
      this.rentalForm,
      this.consumptionForm,
      this.feeForm,
    ];

    forms.forEach((form) => {
      this.resetForm(form);
    });
  }

  resetForm(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      form.get(key)?.reset();
      form.get(key)?.setErrors(null);
    });
  }

  isFormValid(form: FormGroup) {
    let isValid = true;
    form.updateValueAndValidity();
    Object.keys(form.controls).forEach((key) => {
      form.get(key)?.updateValueAndValidity();
    });
    isValid = isValid && form.valid;
    return isValid;
  }

  processForm(form: FormGroup, model: any) {
    Object.keys(form.controls).forEach((key) => {
      if (key !== 'hasIpvaAndLicensingFee')
        model[key] = form.get(key)?.value ? +form.get(key)?.value : 0;
      else model[key] = form.get(key)?.value ? form.get(key)?.value : false;
    });
  }

  processModels(model: ScopeDfdvModel) {
    model.equipment = new ScopeEquipmentModel();
    model.rental = new ScopeRentalModel();
    model.consumption = new ScopeConsumptionModel();
    model.fee = new ScopeFeeModel();
  }

  calcTractorQuantity() {
    const lineTractorQuantity = +(
      this.equipmentForm.controls.lineTractorQuantity.value ?? '0'
    );
    const maneuverTractorQuantity = +(
      this.equipmentForm.controls.maneuverTractorQuantity.value ?? '0'
    );
    const result = lineTractorQuantity + maneuverTractorQuantity;
    this.equipmentForm.controls.tractorQuantity.patchValue(result.toString());
    this.calcJointFactor();
  }

  calcJointFactor() {
    const trailerQuantity = +(
      this.equipmentForm.controls.trailerQuantity.value ?? '0'
    );
    const tractorQuantity = +(
      this.equipmentForm.controls.tractorQuantity.value ?? '0'
    );
    const result =
      tractorQuantity === 0 ? 0 : trailerQuantity / tractorQuantity;
    this.equipmentForm.controls.jointFactor.patchValue(
      result.toFixed(2).toString()
    );
  }
}
