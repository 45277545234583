/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import {
  AfterViewChecked, Component, OnInit, ViewChild,
} from '@angular/core';
import {
  Subscription, forkJoin, isEmpty, tap,
} from 'rxjs';
import { CategoryService } from 'src/app/services/category/category.service';
import { UnitService } from 'src/app/services/unit/unit.service';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GetSubcategoryModel } from 'src/app/services/subcategory/models/get-subcategory-model';
import { GetUnitCategoryModel } from 'src/app/services/unit/models/get-unit-category-model';
import { GetCompaniesPimsModel } from 'src/app/services/pims/companies/model/get-companies-pims.model';
import { GetCompanyGroupedPimsModel } from 'src/app/services/operator/models/get-company-grouped-pims.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { SearchModel } from 'src/app/shared/search/model/search.model';
import { AnalystService } from 'src/app/services/analyst/analyst.service';
import { OperationService } from 'src/app/services/operation/operation.service';
import { GetOperationPimsModel } from 'src/app/services/operation/models/get-operation-pims.model';
import { GetTransporterOperationModel } from 'src/app/services/operator/models/get-transporter-operation-mode';
import { CreateUnitCategoryModel } from 'src/app/services/unit/models/create-unit-category-model';
import { CreateTransporterOperationModel } from 'src/app/services/operator/models/create-transporter-operation';
import { NewOperationPimsModel } from 'src/app/services/operation/models/new-operation-pims.model';
import { StorageService } from 'src/app/common/services/storage.service';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { OperatorService } from '../../../../../services/operator/operator.service';
import { SubcategoryService } from '../../../../../services/subcategory/subcategory.service';
import { SearchControlModel } from '../model/searc-control.model';

@Component({
  selector: 'app-unit-category-new',
  templateUrl: './unit-category-new.component.html',
  styleUrls: ['./unit-category-new.component.scss'],
})
export class UnitCategoryNewComponent implements OnInit {
  public form = this.formBuilder.group({
    category: [''],
    operator: [''],
    subcategory: [['']],
    supervisor: [''],
    manager: [''],
    director: [''],
  });

  public operationsForm: FormControl<string[] | null>[] = [];

  // Service Objects
  public categories: GetCategoryModel[] = [];
  public operators: GetOperatorModel[] = [];
  public subcategories: GetSubcategoryModel[] = [];
  public unitCategory: GetUnitCategoryModel = new GetUnitCategoryModel();
  public operatorPims: GetCompanyGroupedPimsModel = new GetCompanyGroupedPimsModel();
  public operations!: GetOperationPimsModel[];

  // Select Controls
  public categoriesList: string[] = [];
  public operatorsList: string[] = [];
  public subcategoriesList: string[] = [];
  public operationsList: string[] = [];

  // Selected Controls
  public selectedOperator: string[] = [];
  public selectedCategory: string[] = [];
  public selectedSubcategories: string[] = [];
  public selectedOperations: [string[]] = [[]];

  // Search Controls
  public supervisorSearch = new SearchControlModel();
  public managerSearch = new SearchControlModel();
  public directorSearch = new SearchControlModel();

  // Aux
  public modalModel = new ModalModel();
  public unitId!: string;
  public categoryId!: string;
  public sub!: Subscription;
  public subHandler: boolean = false;
  public btnLoading: boolean = false;
  public btnEnabled: boolean = true;
  public isLoading: boolean = true;
  public skeleton: boolean = false;
  public blockCategory: boolean = false;
  public isSupplyCategory: boolean = false;
  public isCorporateCategory: boolean = false;
  public isTransportCategory: boolean = false;
  public toogleCheck: boolean = true;
  public checked: boolean = true;
  public showToggle: boolean = false;
  public unit: GetUnitModel = new GetUnitModel();
  public blockOplFields: boolean = false;

  constructor(
    public categoryService: CategoryService,
    public operatorService: OperatorService,
    public operationService: OperationService,
    public subcategoryService: SubcategoryService,
    public unitService: UnitService,
    private router: Router,
    public analystService: AnalystService,
    public storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    forkJoin({
      categories: this.categoryService.getActiveCategories(),
      operators: this.operatorService.getActiveOperators(),
      subcategories: this.subcategoryService.getActiveSubcategories(),
      operations: this.operationService.getActiveOperations(),
    }).subscribe({
      next: ({
        categories, operators, subcategories, operations,
      }) => {
        this.handleGetCategoriesSuccess(categories);
        this.handleGetOperatorsSuccess(operators);
        this.handleGetSubcategoriesSuccess(subcategories);
        this.handleGetOperationSuccess(operations);

        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
          const unitId = params.get('id') ?? '';
          this.unitId = unitId;
          const categoryId = params.get('categoryId') ?? '';
          if (categoryId && unitId) {
            this.showToggle = true;
            this.categoryId = categoryId;
            this.unitService.getUnitCategory(unitId, categoryId).subscribe((result) => {
              this.unitCategory = result;
              this.blockCategory = true;
              this.updateFormData();
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }

          this.unit = this.storageService.getUnit() ?? new GetUnitModel();
          if (this.unit.unitId === 0) {
            this.unitService.getUnitById(+this.unitId).subscribe((x) => {
              this.unit = x;
              this.storageService.setUnit(this.unit);
            });
          }
        });
      },
      error: () => {
        this.showModal('Erro', 'Erro ao resgatar infomações', false);
      },
    });
  }

  getOperatorPims() {
    const operatorId = this.operators.find((x) => x.fantasyName === this.form.controls.operator.value)?.operatorId ?? '';
    if (operatorId === '') {
      this.skeleton = false;
      return;
    }
    this.operatorService.getActiveCompaniesPims(operatorId).subscribe(
      {
        next: (result) => {
          if (result && operatorId && this.unitId && this.categoryId) {
            this.skeleton = true;
            this.operatorPims = result;
            this.operatorService.getTransporterOperations(operatorId, this.unitId, this.categoryId).subscribe(
              {
                next: this.buildOperationData.bind(this),
                error: () => {
                  this.skeleton = false;
                },
              },
            );
          } else {
            this.skeleton = false;
          }
        },
        error: () => {
          this.skeleton = false;
        },
      },
    );
  }

  buildOperationData(transp: GetTransporterOperationModel[]) {
    this.operationsForm = [];
    this.selectedOperations = [[]];
    this.selectedOperations.pop();
    transp.forEach((x) => {
      const exist = this.operatorPims.operatorPims.find((y) => y.transporterId === x.transporterId);
      if (exist) {
        const operationList = x.operations.map((o) => o.name);
        this.operationsForm.push(this.formBuilder.group({ operations: [operationList] }).controls.operations);
        this.selectedOperations.push(operationList ?? []);
      }
    });
    this.skeleton = false;
  }

  updateFormData() {
    this.form.controls.category.patchValue(this.unitCategory.categoryName);
    this.selectedCategory = [this.unitCategory.categoryName];
    this.form.controls.operator.patchValue(this.unitCategory.operatorName);
    if (this.unitCategory.operatorName) this.selectedOperator = [this.unitCategory.operatorName];
    this.form.controls.subcategory.patchValue(this.unitCategory.subcategories);
    this.selectedSubcategories = this.unitCategory.subcategories;
    this.toogleCheck = this.unitCategory.active;
    this.checked = this.unitCategory.active;

    if (this.unitCategory.supervisorId) {
      this.supervisorSearch.selected = true;
      this.supervisorSearch.item = this.unitCategory.supervisorName;
      this.supervisorSearch.searchModel.analystId = this.unitCategory.supervisorId;
      this.form.controls.supervisor.patchValue(this.unitCategory.supervisorId);
    }

    if (this.unitCategory.operationManagerId) {
      this.managerSearch.selected = true;
      this.managerSearch.item = this.unitCategory.operationManagerName;
      this.managerSearch.searchModel.analystId = this.unitCategory.operationManagerId;
      this.form.controls.manager.patchValue(this.unitCategory.operationManagerId);
    }

    if (this.unitCategory.operationDirectorId) {
      this.directorSearch.selected = true;
      this.directorSearch.item = this.unitCategory.operationDirectorName;
      this.directorSearch.searchModel.analystId = this.unitCategory.operationDirectorId;
      this.form.controls.director.patchValue(this.unitCategory.operationDirectorId);
    }

    this.updateCategoryType(this.selectedCategory[0]);
    this.getOperatorPims();
  }

  handleGetCategoriesSuccess(categories: GetCategoryModel[]) {
    this.categoriesList = categories.map((x) => x.name);
    this.categories = categories;
  }

  handleGetOperatorsSuccess(operators: GetOperatorModel[]) {
    this.operatorsList = operators.map((x) => x.fantasyName);
    this.operators = operators;
  }

  handleGetSubcategoriesSuccess(subcategories: GetSubcategoryModel[]) {
    this.subcategoriesList = subcategories.map((x) => x.name);
    this.subcategories = subcategories;
  }

  handleGetOperationSuccess(operations: GetOperationPimsModel[]) {
    this.operations = operations,
    this.operationsList = operations.map((x) => x.name);
  }

  operatorHandle(cnpj: string) {
    this.sub = this.operatorService.getCompaniesPims(cnpj).pipe(
      tap(() => {
        if (this.subHandler) {
          this.sub.unsubscribe();
          this.subHandler = false;
        }
      }),
    ).subscribe({
      next: this.getCompaniesPimsSuccess.bind(this),
      error: this.getCompaniesPimsError.bind(this),
    });
  }

  getCompaniesPimsSuccess(result: GetCompanyGroupedPimsModel) {
    this.operatorPims = result;
  }

  getCompaniesPimsError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error?.error));
    this.showModal('Erro', message, false);
  }

  changeSelectedCategory(selected: string[]) {
    if (!Array.isArray(selected)) return;
    this.blockOplFields = false;
    const category = selected[0] ?? '';
    this.selectedCategory = category ? [category] : [];
    const id = this.categories.find((x) => x.name === selected[0] && x.active)?.categoryId;
    if (id) {
      this.categoryId = id.toString();
    } else if (selected.length === 0) {
      this.categoryId = '';
    }
    const linkedCategory = this.categories.find((x) => x.name === selected[0]);
    const pimsId = linkedCategory?.categoryPims ? linkedCategory.categoryPims.categoryPimsId : null;
    const unit = pimsId ? this.unit.categories.find((x) => x.active && x.categoryPimsId === pimsId) : null;

    if (unit) {
      this.blockOplFields = true;
      const operator = this.operators.find((x) => x.operatorId === unit.operatorId)?.fantasyName;
      this.selectedOperator = operator !== undefined ? [operator] : [];
      this.form.controls.operator.patchValue(operator ?? null);
      this.categoryId = unit.categoryId.toString();
    } else {
      this.selectedOperator = [];
      this.selectedOperations = [[]];
      this.form.controls.operator.patchValue(null);
      this.operatorPims.operatorPims = [];
      this.operationsForm = [];
    }

    if (this.categoryId !== '') {
      this.getOperatorPims();
    }

    this.updateCategoryType(category);

    this.selectedSubcategories = [];
    this.form.controls.subcategory.patchValue([]);

    this.supervisorSearch.selected = false;
    this.supervisorSearch.item = '';
    this.supervisorSearch.searchModel = new SearchModel();
    this.form.controls.supervisor.patchValue('');

    this.managerSearch.selected = false;
    this.managerSearch.item = '';
    this.managerSearch.searchModel = new SearchModel();
    this.form.controls.manager.patchValue('');

    this.directorSearch.selected = false;
    this.directorSearch.item = '';
    this.directorSearch.searchModel = new SearchModel();
    this.form.controls.director.patchValue('');
  }

  changeSelectedSubcategory(selected: string[]) {
    if (!Array.isArray(selected)) return;

    this.selectedSubcategories = selected;
  }

  updateCategoryType(category: string) {
    this.isSupplyCategory = category.toLowerCase().startsWith('suprimento');
    this.isCorporateCategory = category.toLowerCase().startsWith('corporativo');
    this.isTransportCategory = !this.isSupplyCategory
      && !this.isCorporateCategory
      && category.length > 0;

    this.updateFormValidators();
  }

  updateFormValidators() {
    if (this.isSupplyCategory) {
      this.form.controls.subcategory.addValidators(Validators.required);
      this.form.controls.supervisor.removeValidators(Validators.required);
      this.form.controls.manager.addValidators(Validators.required);
      this.form.controls.director.addValidators(Validators.required);
    } else if (this.isTransportCategory) {
      this.form.controls.subcategory.removeValidators(Validators.required);
      this.form.controls.supervisor.addValidators(Validators.required);
      this.form.controls.manager.addValidators(Validators.required);
      this.form.controls.director.addValidators(Validators.required);
    } else {
      this.form.controls.subcategory.removeValidators(Validators.required);
      this.form.controls.supervisor.removeValidators(Validators.required);
      this.form.controls.manager.removeValidators(Validators.required);
      this.form.controls.director.removeValidators(Validators.required);
    }
  }

  handleSearchClick(value: string, model: SearchControlModel) {
    if (value === '') return;

    model.loading = true;
    this.analystService.getAnalystByIdUserSystem(value).subscribe({
      next: (element) => {
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.item = element.name;
        model.searchModel = element;
        this.setFormAsDirtyAndValidity();
      },
      error: () => {
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar o ID';
      },
    });
  }

  handleCardClick(model: SearchControlModel) {
    if (model.searchModel.department) {
      model.showModal = true;
      return;
    }

    model.loadingCard = true;
    this.analystService.getAnalystByIdUserSystem(model.searchModel.analystId)
      .subscribe({
        next: (element) => {
          model.loadingCard = false;
          model.searchModel = element;
          model.showModal = true;
        },
        error: () => {
          model.loadingCard = false;
          this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
          this.modalModel.title = 'Não foi possível obter os dados do Usuário';
          this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.success = false;
          this.modalModel.showModal = true;
        },
      });
  }

  excludeCard(model: SearchControlModel) {
    model.selected = false;
    model.item = '';
    model.searchModel = new SearchModel();

    this.verifySearchControls();
    this.setFormAsDirtyAndValidity();
  }

  handleCloseModal(model: SearchControlModel) {
    model.showModal = false;
  }

  setFormAsDirtyAndValidity() {
    this.form.controls.supervisor.markAsDirty();
    this.form.controls.manager.markAsDirty();
    this.form.controls.director.markAsDirty();
    this.form.controls.supervisor.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.form.controls.manager.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.form.controls.director.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  verifySearchControls() {
    if (!this.supervisorSearch.selected) {
      this.unitCategory.supervisorId = this.supervisorSearch.searchModel.analystId;
      this.unitCategory.supervisorName = this.supervisorSearch.searchModel.name;
      this.form.controls.supervisor.patchValue('');
    }

    if (!this.managerSearch.selected) {
      this.unitCategory.operationManagerId = this.managerSearch.searchModel.analystId;
      this.unitCategory.operationManagerName = this.managerSearch.searchModel.name;
      this.form.controls.manager.patchValue('');
    }

    if (!this.directorSearch.selected) {
      this.unitCategory.operationDirectorId = this.directorSearch.searchModel.analystId;
      this.unitCategory.operationDirectorName = this.directorSearch.searchModel.name;
      this.form.controls.director.patchValue('');
    }
  }

  onSave() {
    this.btnEnabled = false;
    this.btnLoading = true;
    if (this.selectedCategory.length === 1 && this.form.valid) {
      const transpOperations: CreateTransporterOperationModel[] = [];
      if (this.operatorPims.operatorPims.length > 0) {
        for (let i = 0; i < this.operatorPims.operatorPims.length; i++) {
          const operations: NewOperationPimsModel[] = [];
          this.operationsForm[i].value?.forEach((x) => {
            operations.push({
              name: x,
              operationId: this.operations.find((op) => op.name === x)?.operationId,
            } as NewOperationPimsModel);
          });
          transpOperations.push({
            transporterId: this.operatorPims.operatorPims[i].transporterId,
            operations,
          } as CreateTransporterOperationModel);
        }
      }
      const request = {
        unitId: +this.unitId,
        categoryName: this.form.controls.category.value,
        operatorId: this.operators.find((x) => x.fantasyName === this.form.controls.operator.value)?.operatorId ?? null,
        supervisorId: this.form.controls.supervisor.value === '' ? null : this.form.controls.supervisor.value,
        operationManagerId: this.form.controls.manager.value === '' ? null : this.form.controls.manager.value,
        operationDirectorId: this.form.controls.director.value === '' ? null : this.form.controls.director.value,
        subcategories: this.form.controls.subcategory.value,
        transporters: transpOperations,
        active: this.checked,
      } as CreateUnitCategoryModel;

      if (this.blockCategory) {
        this.unitService.updateUnitCategory(request).subscribe({
          next: this.onSaveSuccess.bind(this, true),
          error: this.onSaveError.bind(this),
        });
        return;
      }

      this.unitService.createUnitCategory(request).subscribe({
        next: this.onSaveSuccess.bind(this, false),
        error: this.onSaveError.bind(this),
      });
      return;
    }

    this.btnEnabled = true;
    this.btnLoading = false;

    this.showModal(
      'Erro',
      'Preencha todos os dados obrigatorios antes de continuar com a operação',
      false,
    );
  }

  onSaveSuccess(update: boolean) {
    this.btnEnabled = true;
    this.btnLoading = false;
    this.showModal(
      'Sucesso',
      update ? 'Vinculo entre unidade e categoria atualizado com sucesso!' : 'Vinculo entre unidade e categoria criado com sucesso!',
      true,
    );
  }

  onSaveError(error: HttpErrorResponse) {
    this.btnEnabled = true;
    this.btnLoading = false;
    const message = JSON.parse(JSON.stringify(error?.error));
    this.showModal(
      'Erro',
      `Erro ao realizar a operação: ${message?.Message}`,
      false,
    );
  }

  changeSelectedOperator(operators: string[]) {
    this.skeleton = true;
    this.getOperatorPims();
  }

  clickModal() {
    if (this.modalModel.success) {
      this.modalModel.showModal = false;
      this.goBack();
    } else {
      this.modalModel.showModal = false;
    }
  }

  goBack() {
    this.router.navigate([`/admin/unit/${this.unitId}`]);
  }

  clearTransporter() {
    this.skeleton = false;
    this.operatorPims = new GetCompanyGroupedPimsModel();
  }

  handleToggleCheck() {
    this.checked = !this.checked;
    this.toogleCheck = this.checked;
    this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  showModal(title: string, message: string, success: boolean) {
    this.modalModel.showModal = true;
    this.modalModel.title = title;
    this.modalModel.description = message;
    this.modalModel.success = success;
    this.modalModel.buttonLabel = 'ENTENDI';
    this.modalModel.imagePath = success ? '../../../../../../assets/icons/success-600.gif' : '../../../../../../assets/icons/warning-600.gif';
  }
}
