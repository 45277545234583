<div class="container">
  <section>
    <header *ngIf="!isLoading">
      <app-primary-button
        label="{{ buttonLabel }}"
        [color]="eButtonsColor.primary"
        [loading]="loadingButton"
        [enabled]="form.valid"
        (clickButton)="id ? updateUnit() : createUnit()"
      ></app-primary-button>
    </header>
    <app-card
      icon="../../../../assets/icons/units-primary-600.svg"
      title="{{ pageTitle }}"
    >
      <section actions class="actions" *ngIf="!isLoading">
        <app-toogle
          label="Ativo"
          toggleId="toggleAction"
          [checked]="checked"
          (check)="handleToggleCheck()"
        ></app-toogle>
      </section>
      <div class="form-container" body *ngIf="!isLoading">
        <label for="">{{ description }}</label>
        <app-toogle
          label="Ativo"
          toggleId="toggleForm"
          [checked]="checked"
          (check)="handleToggleCheck()"
        ></app-toogle>
        <div class="input-box">
          <app-input
            label="Nome"
            [type]="eInputType.text"
            errorMessage="Este campo é obrigatório"
            [control]="form.controls.name"
            [maxlength]="50"
          ></app-input>
          <app-search
            title="Unidade PIMS"
            placeholder="Nome da instância do PIMS"
            [loading]="unitSearch.loading"
            [loadingCard]="unitSearch.loadingCard"
            [selected]="unitSearch.selected"
            [control]="form.controls.instanceId"
            [showModal]="unitSearch.showModal"
            [searchModel]="unitSearch.searchModel"
            [item]="unitSearch.item"
            [error]="unitSearch.error"
            [errorMessage]="unitSearch.errorMessage"
            (handleClick)="handleSearchClick($event, unitSearch)"
            (excludeClick)="excludeCard(unitSearch)"
            (handleOutsideClick)="handleCloseModal(unitSearch)"
          ></app-search>
        </div>
      </div>
      <div class="skeleton" body>
        <app-skeleton-input [input]="3" *ngIf="isLoading"></app-skeleton-input>
        <app-skeleton-box [box]="2" *ngIf="isLoading"></app-skeleton-box>
      </div>
    </app-card>
    <section>
      <app-card
        title="Categorias Vinculadas"
        icon="../../../../assets/icons/categories-primary-600.svg"
        [paddingHorizontal]="false"
      >
        <section actions class="actions">
          <div class="buttons">
            <app-icon-button
              icon="../../../../assets/icons/add-primary.svg"
              text="Adicionar"
              [enabled]="!!id && checked"
              [color]="eButtonsColor.primary"
              (clickButton)="addCategoryToUnit()"
            ></app-icon-button>
          </div>
        </section>
        <section body class="body">
          <div class="list-container">
            <header class="header-content" *ngIf="unitCategories.length > 0">
              <label id="hid">ID</label>
              <label id="hdescription">CATEGORIA</label>
              <label id="hopl">OPL</label>
              <label id="hstatus">STATUS</label>
            </header>
            <div class="list-content" *ngIf="unitCategories.length > 0">
              <app-unit-category-detail
                [unitCategories]="unitCategories"
                [unitId]="id"
                class="detail"
              ></app-unit-category-detail>
              <app-unit-category-card
                [unitCategories]="unitCategories"
                [unitId]="id"
                class="card"
              ></app-unit-category-card>
            </div>
          </div>
        </section>
        <div body class="skeleton" *ngIf="isLoading">
          <app-skeleton-line [line]="5"></app-skeleton-line>
        </div>
        <div
          body
          class="not-found"
          *ngIf="!isLoading && unitCategories.length === 0">
          <app-not-found></app-not-found>
        </div>
      </app-card>
    </section>
  </section>
</div>
<app-modal
  [showModal]="showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
