import { QuantitiesCostAmountValues } from '../quantitiesCostAmountValues.model';

export class UniformMaintenceWarehouseTeamModel {
  [key: string]: QuantitiesCostAmountValues;

  public pants: QuantitiesCostAmountValues;
  public shirt: QuantitiesCostAmountValues;
  public boots: QuantitiesCostAmountValues;
  public gloves: QuantitiesCostAmountValues;
  public glasses: QuantitiesCostAmountValues;
  public sweatshirt: QuantitiesCostAmountValues;

  constructor() {
    this.pants = new QuantitiesCostAmountValues();
    this.shirt = new QuantitiesCostAmountValues();
    this.boots = new QuantitiesCostAmountValues();
    this.gloves = new QuantitiesCostAmountValues();
    this.glasses = new QuantitiesCostAmountValues();
    this.sweatshirt = new QuantitiesCostAmountValues();
  }
}
