<div class="container">
  <section>
    <header>
      <app-primary-button
        label="NOVA UNIDADE"
        icon="../../../../assets/icons/add-common-100.svg"
        [color]="eButtonsColor.primary"
        [loading]="loadingButton"
        [enabled]="enableButton"
        (clickButton)="handleButtonClick()"
      ></app-primary-button>
    </header>
    <app-card
      title="Unidades"
      icon="../../../../assets/icons/units-primary-600.svg"
      [paddingHorizontal]="false"
    >
      <app-unit-list
        body
        [unitList]="unitList"
        *ngIf="!isLoading && unitList.length > 0 && !error"
      ></app-unit-list>
      <div class="skeleton" body *ngIf="isLoading">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <div
        body
        class="not-found"
        *ngIf="!isLoading && unitList.length === 0 && !error"
      >
        <app-not-found></app-not-found>
      </div>
      <div
        body
        class="error"
        *ngIf="!isLoading && unitList.length === 0 && error"
      >
        <app-error (reloadCallBack)="reload()"></app-error>
      </div>
    </app-card>
  </section>
</div>
