import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorService } from 'src/app/services/operator/operator.service';

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss'],
})
export class OperatorComponent implements OnInit {
  public newOperator: GetOperatorModel = new GetOperatorModel();
  public modalModel : ModalModel = new ModalModel();
  public preRegisters: GetOperatorModel[] = [];
  public operators: GetOperatorModel[] = [];
  public isLoading: boolean = true;
  public isLoadingPreRegister: boolean = true;
  public error: boolean = false;
  public errorPreRegister: boolean = false;

  constructor(
    private router: Router,
    private operatorService: OperatorService,
  ) {}

  ngOnInit(): void {
    this.getPreRegisters();
    this.getOperators();
  }

  addOperator(operator: GetOperatorModel) {
    this.newOperator = operator;
  }

  getPreRegisters() {
    this.operatorService.getOperatorsPreRegisters().subscribe({
      next: (res) => {
        this.preRegisters.push(...res);
      },
      error: () => {
        this.errorPreRegister = true;
        this.isLoadingPreRegister = false;
      },
      complete: () => {
        this.isLoadingPreRegister = false;
        this.errorPreRegister = false;
      },
    });
  }

  getOperators() {
    this.operatorService.getOperators().subscribe({
      next: (res) => {
        this.operators.push(...res);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  reload() {
    this.isLoading = true;
    this.getOperators();
  }

  reloadPreRegisters() {
    this.isLoadingPreRegister = true;
    this.getPreRegisters();
  }
}
