import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-scope-equipment',
  templateUrl: './scope-equipment.component.html',
  styleUrls: ['./scope-equipment.component.scss'],
})
export class ScopeEquipmentComponent implements OnInit {
  @Input() form = new FormGroup({
    tractorCost: new FormControl(''),
    trailerCost: new FormControl(''),
    lightFleetQuantity: new FormControl(''),
    lineTractorQuantity: new FormControl(''),
    maneuverTractorQuantity: new FormControl(''),
    tractorQuantity: new FormControl(''),
    trailerQuantity: new FormControl(''),
    jointFactor: new FormControl(''),
    tractorTireQuantity: new FormControl(''),
    trailerTireQuantity: new FormControl(''),
    lineTractorEstimatedMonthlyKm: new FormControl(''),
    maneuverTractorEstimatedMonthlyKm: new FormControl(''),
    tractorLifespan: new FormControl(''),
    trailerLifespan: new FormControl(''),
    operationDayDrivenKm: new FormControl(''),
    exchangeDayDrivenKm: new FormControl(''),
  });

  @Input() shouldDisableFields: boolean = false;
  @Input() disableFieldOPL: boolean = false;
  @Input() disableFieldRaizen: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  constructor() {}

  ngOnInit(): void {}
  
  handleUpdateValue() {
    this.updateValue.emit();
  }
}
