import { Utils } from 'src/app/common/utils/utils';
import {
  Component, Input, OnInit, SimpleChange,
} from '@angular/core';
import { Router } from '@angular/router';
import { GetOperatorModel } from 'src/app/services/operator/models/get-operator-model';
import { OperatorService } from 'src/app/services/operator/operator.service';

@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.scss'],
})
export class OperatorListComponent implements OnInit {
  @Input() newOperator: GetOperatorModel;
  @Input() operatorList: GetOperatorModel[] = [];

  public isMobile: boolean = false;

  public breakpointBase: number = 768;
  public widthBreakpoint: number = 0;

  constructor(private operatorService: OperatorService, public util: Utils) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
    this.newOperator = new GetOperatorModel();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.widthBreakpoint) {
      this.isMobile = true;
    }
  }

  requestSuccess(res: GetOperatorModel[]) {
    this.operatorList = res;
  }

  requestError() {}

  ngOnChanges() {
    if (this.newOperator.operatorId !== '') {
      this.operatorList.push(this.newOperator);
    }
  }
}
