export class GetDfdvFilterModel {
  dfdvId: string = '';
  unit: string[] = [];
  category: string[] = [];
  operator: string[] = [];
  status: string[] = [];
  fromStartDate: string = '';
  toStartDate: string = '';
  fromEndDate: string = '';
  toEndDate: string = '';
  pageNumber: number = 1;
  pageSize: number = 10;
}
