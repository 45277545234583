<div class="unit-container" body>
  <div *ngIf="!isMobile" class="table-content">
    <header>
      <span id="h-id">ID</span>
      <span id="h-name">NOME</span>
      <span id="h-status">STATUS</span>
    </header>
    <app-unit-list-detail
      *ngFor="let unit of unitList"
      [unit]="unit"
    ></app-unit-list-detail>
  </div>
  <div *ngIf="isMobile" class="card-content">
    <app-unit-list-card
      *ngFor="let unit of unitList"
      [unit]="unit"
    ></app-unit-list-card>
  </div>
</div>
