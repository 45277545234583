export class ScopeEquipmentModel {
  [key: string]: number;

  public tractorCost: number = 0;
  public trailerCost: number = 0;
  public lightFleetQuantity: number = 0;
  public lineTractorQuantity: number = 0;
  public maneuverTractorQuantity: number = 0;
  public tractorQuantity: number = 0;
  public trailerQuantity: number = 0;
  public jointFactor: number = 0;
  public tractorTireQuantity: number = 0;
  public trailerTireQuantity: number = 0;
  public lineTractorEstimatedMonthlyKm: number = 0;
  public maneuverTractorEstimatedMonthlyKm: number = 0;
  public tractorLifespan: number = 0;
  public trailerLifespan: number = 0;
  public finameLeasingRemuneration: number = 0;
  public paidVehicleRemuneration: number = 0;
  public operationDayDrivenKm: number = 0;
  public exchangeDayDrivenKm: number = 0;
}
