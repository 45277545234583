<app-fleet-table
  [isLoading]="isLoading"
  [title]="'Conjunto'"
  [icon]="'assets/icons/truck-side-common.svg'"
  [columnsHeader]="trailerHeader"
  [rowsContent]="trailerContent"
  [rowsStatus]="trailerRowStatus"
  [totalActiveRow]="trailerActiveContent"
  [totalStopedRow]="trailerStopedContent"
></app-fleet-table>
