export class OperationalTeamDriver {
  totalRemuneration!: number;
  totalPayslip!: number;
  salaryWithAgreement!: number;
  inIntinereHoursCost!: number;
  nightAdditional!: number;
  dsr!: number;
  paycheck!: number;
  charge!: number;
  toxicologicalExam!: number;
  hourCost!: number;
  hoursOfQuantitativeItemsWithWeighting!: number;
  hoursOfQualitativeItemsWithWeighting!: number;
  variableSalary!: number;
  variableSalaryWithCharge!: number;
}
