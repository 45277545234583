<div class="grid-items">
  <div
    *ngFor="let item of companiesPims.operatorPims"
    class="grid-content"
    (click)="navigate(companiesPims.operatorId, item.transporterId)"
  >
    <label id="id">{{ util.addLeadingZeros(item.transporterId, 5) }}</label>
    <label id="cnpj">{{ item.transporterCnpj | cnpj }}</label>
    <label id="name">{{ item.transporterName.toUpperCase() }}</label>
    <div id="status">
      <app-status
        [color]="item.active ?  StatusColorEnum.success : StatusColorEnum.warning"
        [image]="
          item.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="item.active ? 'Ativo' : 'Inativo'"
      >
      </app-status>
    </div>
  </div>
</div>
