import { Component, Input } from '@angular/core';
import { PasswordValidationModel } from '../model/passwordValidation.model';
import { InputTypeEnum } from '../enums/input-type.enum';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent {
  @Input() validator: PasswordValidationModel = new PasswordValidationModel();

  public eInputTypeEnum = InputTypeEnum;
}
