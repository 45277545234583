<div class="subcategory-list-container">
  <header class="header-content">
    <label id="hid">ID</label>
    <label id="hname">NOME</label>
    <label id="hstatus">STATUS</label>
  </header>
  <div class="subcategory-list-content">
    <app-subcategory-list-card
      [subcategoriesList]="subcategories"
      class="card"
    ></app-subcategory-list-card>
    <app-subcategory-list-detail
      [subcategoriesList]="subcategories"
      class="detail"
    ></app-subcategory-list-detail>
  </div>
</div>
