<section
  [ngClass]="{
    success: type === 'success',
    attention: type === 'attention',
    warning: type === 'warning'
  }"
>
  <img
    [src]="
      type === 'success'
        ? '../../../assets/icons/check-success-600.svg'
        : type === 'attention'
        ? '../../../assets/icons/alert-attention-600.svg'
        : '../../../assets/icons/error-warning-600.svg'
    "
    alt="Info icon"
  />
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
</section>
