import {
  Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { ButtonsType } from '../enums/Buttonstypes';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public eButtonsType = ButtonsType;
  @Output() reloadCallBack: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  reload() {
    this.reloadCallBack.emit(true);
  }
}
