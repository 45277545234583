import {
  Subject, Subscription, debounceTime, forkJoin, tap,
} from 'rxjs';
import {
  AfterViewInit, Component, Input, OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { environment } from 'src/environments/environment';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { GetEmployeeNumberModel } from 'src/app/services/operator/models/get-employee-number-model';
import { GetCompanySizeModel } from 'src/app/services/operator/models/get-company-size-model';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-company-step',
  templateUrl: './company-step.component.html',
  styleUrls: ['./company-step.component.scss'],
})
export class CompanyStepComponent implements OnInit, AfterViewInit {
  @Input() form = new FormGroup({
    cnpj: new FormControl(''),
    corporateName: new FormControl(''),
    tradingName: new FormControl(''),
    numberRegistrationState: new FormControl(''),
    numberRegistrationMunicipal: new FormControl(''),
    companySize: new FormControl(''),
    employeesCount: new FormControl(''),
    validatedFantasy: new FormControl(''),
    validatedCompany: new FormControl(''),
  });

  // Enum
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eButtonsType = ButtonsType;
  public eSideIconType = SideIconTypeEnum;

  // Prop
  public nFuncionarios: string[] = [];
  public porteEmpresa: string[] = [];

  // Aux
  public isLoading: boolean = true;
  public subCorporate!: Subscription;
  public subFantasy!: Subscription;
  // // Corporate Aux
  public corporateLoading: boolean = false;
  public corporateNameIsValid: boolean = false;
  public corporateNameError:string = 'Este campo é obrigatório';
  // // Fantasy Aux
  public fantasyLoading: boolean = false;
  public fantasyNameIsValid: boolean = false;
  public fantasyNameError:string = 'Este campo é obrigatório';

  // Requ
  private timer!: ReturnType<typeof setTimeout>;
  private destroy$: Subject<void> = new Subject();

  public get InfoboxTypeEnum() { return InfoboxTypeEnum; }

  constructor(public operatorService: OperatorService) {}

  ngAfterViewInit(): void {
    this.form.controls.corporateName.valueChanges.pipe(
      tap(() => {
        this.corporateLoading = false;
        this.corporateNameIsValid = false;
        this.form.controls.validatedCompany.patchValue('');
        if (this.subCorporate) {
          this.form.setErrors({ invalid: true });
          this.subCorporate.unsubscribe();
        }
      }),
      debounceTime(1500),
      tap((value: any) => {
        if (value && value !== '' && !this.form.controls.corporateName.valid) {
          this.form.controls.corporateName.markAsPending();
        }
      }),
    ).subscribe((value) => {
      if (!value || value === '') return;
      this.corporateLoading = true;
      this.validateCompanyName(value);
    });

    this.form.controls.tradingName.valueChanges.pipe(
      tap(() => {
        this.fantasyLoading = false;
        this.fantasyNameIsValid = false;
        this.form.controls.validatedFantasy.patchValue('');
        if (this.subFantasy) {
          this.form.setErrors({ invalid: true });
          this.subFantasy.unsubscribe();
        }
      }),
      debounceTime(1500),
      tap((value: any) => {
        if (value && value !== '' && !this.form.controls.validatedFantasy.valid) {
          this.form.controls.validatedFantasy.markAsPending();
        }
      }),
    ).subscribe((value) => {
      if (!value || value === '') return;
      this.fantasyLoading = true;
      this.validateFantasyName(value);
    });
  }

  validateCompanyName(value:string) {
    this.subCorporate = this.operatorService.validateCompanyNamesPublic(value ?? '')
      .subscribe({
        next: (result) => {
          this.corporateLoading = false;
          if (result) {
            this.corporateNameIsValid = true;
            this.form.controls.corporateName.setErrors(null);
            this.form.controls.validatedCompany.patchValue('validated');
          }
        },
        error: (result) => {
          this.corporateLoading = false;
          this.corporateNameIsValid = false;
          this.corporateNameError = result.error.Message;
          this.form.setErrors({ invalid: true });
          this.form.controls.corporateName.setErrors({ corporateNameIsValid: false });
          this.form.controls.validatedCompany.patchValue('');
        },
      });
  }

  validateFantasyName(value:string) {
    this.subFantasy = this.operatorService.validateFantasyNamesPublic(value ?? '')
      .subscribe({
        next: (result) => {
          this.fantasyLoading = false;
          if (result) {
            this.fantasyNameIsValid = true;
            this.form.controls.tradingName.setErrors(null);
            this.form.controls.validatedFantasy.patchValue('validated');
          }
        },
        error: (result) => {
          this.fantasyLoading = false;
          this.fantasyNameIsValid = false;
          this.fantasyNameError = result.error.Message;
          this.form.controls.tradingName.setErrors({ fantasyNameIsValid: false });
          this.form.controls.validatedFantasy.patchValue('');
        },
      });
  }

  ngOnInit(): void {
    this.loadData();
  }

  onGCertifica() {
    window.open(environment.GCertificaUrl, '_blank');
  }

  loadData() {
    forkJoin({
      employeeNumber: this.operatorService.getEmployeeNumbersPublic(),
      companySize: this.operatorService.getCompanySizesPublic(),
    }).subscribe({
      next: ({ employeeNumber, companySize }) => {
        this.handleGetEmployeeNumberSuccess(employeeNumber);
        this.handleGetCompanySizeSuccess(companySize);
      },
      error: () => {
        this.nFuncionarios = [];
        this.porteEmpresa = [];
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleGetEmployeeNumberSuccess(res: GetEmployeeNumberModel[]) {
    res.forEach((element) => {
      this.nFuncionarios.push(element.name);
    });
  }

  handleGetCompanySizeSuccess(res: GetCompanySizeModel[]) {
    res.forEach((element) => {
      this.porteEmpresa.push(element.name);
    });
  }
}
