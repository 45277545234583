export class GetContextModel {
  public analystsActivesCount: number = 0;
  public analystsInactivesCount: number = 0;
  public categoriesActivesCount: number = 0;
  public categoriesInactivesCount: number = 0;
  public operatorsActivesCount: number = 0;
  public operatorsInactivesCount: number = 0;
  public subcategoriesActivesCount: number = 0;
  public subcategoriesInactivesCount: number = 0;
  public unitsActivesCount: number = 0;
  public unitsInactivesCount: number = 0;
  public operationsActivesCount: number = 0;
  public operationsInactivesCount: number = 0;
}
