<app-card [title]="'Histórico'" icon="../../../../assets/icons/categories-primary-600.svg">
    <div body *ngIf="isLoaded">
        <app-history>
            <app-history-item *ngFor="let solicitationHistory of solicitationHistories"
                [date]="solicitationHistory.date" [accordion]="true" [type]="HistoryItemTypeEnum.normal"
                [hasDate]="solicitationHistory.date !== null">
                <div header class="header">
                    <div class="analyst">
                        <span class="analystId">{{solicitationHistory.analystId}}</span>
                        <span class="analystName">
                            {{utils.formatName(solicitationHistory.analystName, 20)}}</span>
                    </div>
                </div>
                <div body class="body">
                    <div class="history-changes-container">
                        <div class="change" *ngFor="let change of getAllChangesFromHistory(solicitationHistory)">
                            <div class="detail">
                                <strong>Campo:</strong>
                                <span>{{ change.field }}</span>
                            </div>
                            <div class="detail">
                                <strong>De:</strong>
                                <span>{{ change.from }}</span>
                            </div>
                            <div class="detail">
                                <strong>Para:</strong>
                                <span>{{ change.to }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-history-item>
        </app-history>
        <div class="buttons-container">
            <app-secondary-button label="VOLTAR" icon="./assets/icons/arrow-previous-primary-600.svg" [right]="false"
                [loading]="false" [enabled]="true" (click)="handleReturnClick()"></app-secondary-button>
        </div>
    </div>
    <div body *ngIf="!isLoaded">
      <app-skeleton-text [text]="3"></app-skeleton-text>
    </div>
</app-card>
