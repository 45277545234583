<app-modal-generic
  [imagePath]="'../../../../assets/icons/question-purple.svg'"
  [title]="title"
  [showModal]="showModal"
>
  <div class="content">
    <app-text-area
      label="Justificativa"
      [description]="description"
      [control]="form.controls.justify"
      placeholder="Descrição"
      errorMessage="Este campo é obrigatório"
      [maxlength]="100"
    ></app-text-area>
    <div class="buttons">
      <app-buttons
        class="cancel"
        [label]="'Cancelar'"
        [type]="eButtonsType.secondary"
        [enabled]="true"
        (clickButton)="onClick(true)"
        [animation]="false"
      ></app-buttons>
      <app-primary-button
        [label]="buttonLabel"
        [icon]="buttonImage"
        [color]="buttonColor"
        [right]="false"
        [loading]="sending"
        [enabled]="enableButton"
        (clickButton)="onClick(false)"
      ></app-primary-button>
    </div>
  </div>
</app-modal-generic>
