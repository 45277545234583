import { CumulativeValuesModel } from '../cumalative-values/cumulative-values.model';

export class CumulativeIncidenceModel {
  [key: string]: CumulativeValuesModel;

  public chargeIndemnifiedProportionalVacation: CumulativeValuesModel;
  public chargeOneThirdVacation: CumulativeValuesModel;
  public chargePreviousNotice: CumulativeValuesModel;
  public chargeLegalAbsences: CumulativeValuesModel;
  public chargeThirteenthSalary: CumulativeValuesModel;

  constructor() {
    this.chargeIndemnifiedProportionalVacation = new CumulativeValuesModel();
    this.chargeOneThirdVacation = new CumulativeValuesModel();
    this.chargePreviousNotice = new CumulativeValuesModel();
    this.chargeLegalAbsences = new CumulativeValuesModel();
    this.chargeThirteenthSalary = new CumulativeValuesModel();
  }
}
