import {
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { ChargeModel } from 'src/app/services/dfdv/charge/models/charges.model';
import { CumulativeIncidenceModel } from 'src/app/services/dfdv/charge/models/cumalativeIncidence/cumulativeIncidence.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { ChargeComponent } from './charge/charge.component';
import { CumulativeIncidenceOplComponent } from './cumulative-incidence-opl/cumulative-incidence-opl.component';
import { CumulativeIncidenceComponent } from './cumulative-incidence/cumulative-incidence.component';

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss'],
})
export class ChargesComponent implements OnInit, OnDestroy {
  @ViewChild('charge')
    chargeComponent!: ChargeComponent;

  @ViewChild('cumulativeIncidence')
    cumulativeIncidenceComponent!: CumulativeIncidenceComponent;

  @ViewChild('cumulativeIncidenceOPL')
    cumulativeIncidenceComponentOPL!: CumulativeIncidenceOplComponent;

  // Control
  public isLoading: boolean = false;
  public isUserRaizen: boolean = false;
  public shouldDisableFields: boolean = false;
  public cumulativeIncidenceError: boolean = false;
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public chargesModel: ChargeModel = new ChargeModel();
  public dfdvUtils!: DfdvUtils;

  // Error Messages
  public errorMessage = 'Este campo é obrigatorio';
  public hasErrorMessage: string = '';

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;
  public ButtonColorEnum = ButtonColorEnum;

  // Forms
  public otherChargesform = this.formBuilder.group({
    nightAdditional: [''],
    fgtsPenalty: [''],
    fgtsPenaltySeasonalWorker: [''],
  });

  // Totals
  public totalizerCumulativeCount: number = 0;
  public totalizerTotalCount: number = 0;
  public totalChargesCount: number = 0;
  public totalBaseIncidenceBaseCount: number = 0;
  public totalBaseIncidenceInssCount: number = 0;
  public totalBaseIncidenceFgtsCount: number = 0;
  public totalProvisionCount: number = 0;

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  chargeStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.charge));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.isLoading = true;
    this.unSubscribe = this.chargeStore$.subscribe((charge) => {
      if (!charge) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.isLoading = isLoading.loading;
            });

          if (!this.isLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' }),
                );
              }
            });
          }
        });
      } else {
        setTimeout(() => {
          this.isLoading = false;
          this.setCharges(charge);
          this.setCalculations();
        }, 0);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = JSON.parse(JSON.stringify(dfdv));

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(this.dfdv, this.storageService.getRole());
        this.isUserRaizen = this.dfdvUtils.isUserRaizen();
        this.shouldDisableFields = this.isUserRaizen ? !this.dfdvUtils.isEnableForRaizen() : true;

        this.setCalculations();
      }
    });
  }

  setCalculations() {
    this.setSumOfTaxesCharges();
    this.setSumOfTaxesCumulativeIncidence();
    this.setTotalsCount();

    if (this.chargeComponent?.form) this.setCharges(this.dfdv.charge);
  }

  setSumOfTaxesCharges() {
    if (!this.dfdv?.charge?.chargeBase) return;

    const {
      inss, fgts, sest, senat, incra, sebrae, nrat, educationSalary,
    } = this.dfdv.charge.chargeBase;

    const calc = sest + nrat + educationSalary + sebrae + inss + fgts + senat + incra;
    const total = +(calc.toFixed(2));

    this.totalChargesCount = total;
  }

  setSumOfTaxesCumulativeIncidence() {
    if (!this.dfdv.charge) return;

    const { cumulativeIncidenceBase, cumulativeIncidenceInss, cumulativeIncidenceFgts } = this.dfdv.charge;

    this.totalBaseIncidenceBaseCount = (cumulativeIncidenceBase.proportionalCompensatedVacation
      + cumulativeIncidenceBase.oneThirdVacation
      + cumulativeIncidenceBase.earlyWarning
      + cumulativeIncidenceBase.legalAbsences
      + cumulativeIncidenceBase.thirteenthSalary);

    this.totalBaseIncidenceInssCount = (cumulativeIncidenceInss.proportionalCompensatedVacation
      + cumulativeIncidenceInss.oneThirdVacation
      + cumulativeIncidenceInss.earlyWarning
      + cumulativeIncidenceInss.legalAbsences
      + cumulativeIncidenceInss.thirteenthSalary);

    this.totalBaseIncidenceFgtsCount = (cumulativeIncidenceFgts.proportionalCompensatedVacation
      + cumulativeIncidenceFgts.oneThirdVacation
      + cumulativeIncidenceFgts.earlyWarning
      + cumulativeIncidenceFgts.legalAbsences
      + cumulativeIncidenceFgts.thirteenthSalary);
  }

  getTotalizerTotal() {
    const total = this.totalChargesCount
      + this.totalBaseIncidenceBaseCount
      + this.totalProvisionCount
      + this.totalizerCumulativeCount;

    return +(total.toFixed(2));
  }

  getTotalizerCumulative() {
    const total = (this.totalChargesCount * this.totalBaseIncidenceBaseCount) / 100;

    return +(total.toFixed(2));
  }

  getTotalProvision() {
    const otherFormControls = this.otherChargesform.controls;
    const fgts = +(otherFormControls.fgtsPenalty.value ?? '0');
    const fgtsWorker = +(otherFormControls.fgtsPenaltySeasonalWorker.value ?? '0');
    const total = fgts + fgtsWorker;

    return +(total.toFixed(2));
  }

  setTotalsCount() {
    this.totalProvisionCount = this.getTotalProvision();
    this.totalizerTotalCount = this.getTotalizerTotal();
    this.totalizerCumulativeCount = this.getTotalizerCumulative();
  }

  setCharges(charge?: ChargeModel) {
    if (charge) {
      this.setFormValues(charge);
    } else {
      this.resetForm();
    }
  }

  allFormsAreValid() {
    this.updateErrors();

    if (!this.chargeComponent.form.valid || this.cumulativeIncidenceError) {
      return false;
    }
    return true;
  }

  buildRequest() {
    const chargeRequestModel = new ChargeModel();
    const chargeFormControls = this.chargeComponent.form.controls;
    const otherFormControls = this.otherChargesform.controls;

    // Charges
    chargeRequestModel.chargeBase.sest = +(chargeFormControls.SEST.value ?? '0');
    chargeRequestModel.chargeBase.fap = +(chargeFormControls.FAP.value ?? '0');
    chargeRequestModel.chargeBase.rat = +(chargeFormControls.RAT.value ?? '0');
    chargeRequestModel.chargeBase.nrat = +(chargeFormControls.NRAT.value ?? '0');
    chargeRequestModel.chargeBase.educationSalary = +(chargeFormControls.educationSalary.value ?? '0');
    chargeRequestModel.chargeBase.sebrae = +(chargeFormControls.SEBRAE.value ?? '0');
    chargeRequestModel.chargeBase.inss = +(chargeFormControls.INSS.value ?? '0');
    chargeRequestModel.chargeBase.fgts = +(chargeFormControls.FGTS.value ?? '0');
    chargeRequestModel.chargeBase.senat = +(chargeFormControls.SENAT.value ?? '0');
    chargeRequestModel.chargeBase.incra = +(chargeFormControls.INCRA.value ?? '0');

    // Cumulative Incidence
    const cumulativeIncidenceModel = new CumulativeIncidenceModel();
    if (this.isUserRaizen) {
      this.cumulativeIncidenceComponent.form.controls.rows.controls.forEach(
        (control, i) => {
          const rowLabel = Object.keys(cumulativeIncidenceModel)[i];
          const rowControl = control.controls;

          if (rowLabel === 'chargeIndemnifiedProportionalVacation') {
            chargeRequestModel.cumulativeIncidenceBase.proportionalCompensatedVacation = +(rowControl.charges.value ?? 0);
            chargeRequestModel.cumulativeIncidenceInss.proportionalCompensatedVacation = +(rowControl.inssRps.value ?? 0);
            chargeRequestModel.cumulativeIncidenceFgts.proportionalCompensatedVacation = +(rowControl.inssRpsIn25.value ?? 0);
          } else if (rowLabel === 'chargeOneThirdVacation') {
            chargeRequestModel.cumulativeIncidenceBase.oneThirdVacation = +(rowControl.charges.value ?? 0);
            chargeRequestModel.cumulativeIncidenceInss.oneThirdVacation = +(rowControl.inssRps.value ?? 0);
            chargeRequestModel.cumulativeIncidenceFgts.oneThirdVacation = +(rowControl.inssRpsIn25.value ?? 0);
          } else if (rowLabel === 'chargePreviousNotice') {
            chargeRequestModel.cumulativeIncidenceBase.earlyWarning = +(rowControl.charges.value ?? 0);
            chargeRequestModel.cumulativeIncidenceInss.earlyWarning = +(rowControl.inssRps.value ?? 0);
            chargeRequestModel.cumulativeIncidenceFgts.earlyWarning = +(rowControl.inssRpsIn25.value ?? 0);
          } else if (rowLabel === 'chargeLegalAbsences') {
            chargeRequestModel.cumulativeIncidenceBase.legalAbsences = +(rowControl.charges.value ?? 0);
            chargeRequestModel.cumulativeIncidenceInss.legalAbsences = +(rowControl.inssRps.value ?? 0);
            chargeRequestModel.cumulativeIncidenceFgts.legalAbsences = +(rowControl.inssRpsIn25.value ?? 0);
          } else {
            chargeRequestModel.cumulativeIncidenceBase.thirteenthSalary = +(rowControl.charges.value ?? 0);
            chargeRequestModel.cumulativeIncidenceInss.thirteenthSalary = +(rowControl.inssRps.value ?? 0);
            chargeRequestModel.cumulativeIncidenceFgts.thirteenthSalary = +(rowControl.inssRpsIn25.value ?? 0);
          }
        },
      );
    } else {
      const cumulativeOPLFormControls = this.cumulativeIncidenceComponentOPL.form.controls;
      chargeRequestModel.cumulativeIncidenceBase.proportionalCompensatedVacation = +(
        cumulativeOPLFormControls.proportionalCompensatedVacation.value ?? 0);
      chargeRequestModel.cumulativeIncidenceBase.oneThirdVacation = +(
        cumulativeOPLFormControls.oneThirdVacation.value ?? 0
      );
      chargeRequestModel.cumulativeIncidenceBase.earlyWarning = +(
        cumulativeOPLFormControls.earlyWarning.value ?? 0
      );
      chargeRequestModel.cumulativeIncidenceBase.legalAbsences = +(
        cumulativeOPLFormControls.legalAbsences.value ?? 0
      );
      chargeRequestModel.cumulativeIncidenceBase.thirteenthSalary = +(
        cumulativeOPLFormControls.thirteenthSalary.value ?? 0
      );

      chargeRequestModel.cumulativeIncidenceInss = this.dfdv.charge.cumulativeIncidenceInss;
      chargeRequestModel.cumulativeIncidenceFgts = this.dfdv.charge.cumulativeIncidenceFgts;
    }

    // Provision & Night Additional
    chargeRequestModel.chargeBase.fgtsPenalty = +(otherFormControls.fgtsPenalty.value ?? '0');
    chargeRequestModel.chargeBase.fgtsPenaltySeasonalWorker = +(otherFormControls.fgtsPenaltySeasonalWorker.value ?? '0');
    chargeRequestModel.chargeBase.nightAdditional = +(otherFormControls.nightAdditional.value ?? '0');

    // Totals
    chargeRequestModel.totalChargeBase = this.totalBaseIncidenceBaseCount;
    chargeRequestModel.totalCumulativeIncidenceBase = this.totalBaseIncidenceBaseCount;
    chargeRequestModel.totalCumulativeIncidenceInss = this.totalBaseIncidenceInssCount;
    chargeRequestModel.totalCumulativeIncidenceFgts = this.totalBaseIncidenceFgtsCount;
    chargeRequestModel.totalProvision = this.totalProvisionCount;
    chargeRequestModel.totalCharge = this.totalizerTotalCount;
    chargeRequestModel.totalCumulativeIncidence = this.totalizerCumulativeCount;

    return chargeRequestModel;
  }

  resetForm() {
    Object.keys(this.chargeComponent.form.controls).forEach((key) => {
      if (!this.chargeComponent.form.get(key)?.value) {
        this.chargeComponent.form.get(key)?.reset();
        this.chargeComponent.form.get(key)?.setErrors(null);
      }
    });
    Object.keys(this.otherChargesform.controls).forEach((key) => {
      if (!this.otherChargesform.get(key)?.value) {
        const field = this.otherChargesform.get(key);

        field?.setValue(null);
        field?.reset();
        field?.setErrors(null);
      }
    });
    this.cumulativeIncidenceComponent?.form.controls.rows.controls.forEach(
      (control) => {
        const rowControl = control.controls;
        rowControl.charges.setValue(null);
        rowControl.charges.reset();
        rowControl.charges.setErrors(null);
        rowControl.charges.markAsTouched();

        rowControl.inssRps.setValue(null);
        rowControl.inssRps.reset();
        rowControl.inssRps.setErrors(null);
        rowControl.inssRps.markAsTouched();

        rowControl.inssRpsIn25.setValue(null);
        rowControl.inssRpsIn25.reset();
        rowControl.inssRpsIn25.setErrors(null);
        rowControl.inssRpsIn25.markAsTouched();
      },
    );
  }

  updateErrors() {
    Object.keys(this.otherChargesform.controls).forEach((key) => {
      if (!this.otherChargesform.get(key)?.value) {
        this.otherChargesform.get(key)?.updateValueAndValidity();
      }
    });
    Object.keys(this.chargeComponent.form.controls).forEach((key) => {
      if (!this.chargeComponent.form.get(key)?.value) {
        this.chargeComponent.form.get(key)?.updateValueAndValidity();
      }
    });

    this.cumulativeIncidenceError = false;

    if (this.isUserRaizen) {
      this.cumulativeIncidenceComponent.form.controls.rows.controls.forEach(
        (control) => {
          const rowControl = control.controls;
          rowControl.charges.updateValueAndValidity();
          rowControl.inssRps.updateValueAndValidity();
          rowControl.inssRpsIn25.updateValueAndValidity();

          this.cumulativeIncidenceError = this.cumulativeIncidenceError
            || rowControl.charges.invalid
            || rowControl.inssRps.invalid
            || rowControl.inssRpsIn25.invalid;
        },
      );
    } else {
      const cumulativeOPLForm = this.cumulativeIncidenceComponentOPL.form.controls;
      cumulativeOPLForm.proportionalCompensatedVacation.updateValueAndValidity();
      cumulativeOPLForm.oneThirdVacation.updateValueAndValidity();
      cumulativeOPLForm.earlyWarning.updateValueAndValidity();
      cumulativeOPLForm.legalAbsences.updateValueAndValidity();
      cumulativeOPLForm.thirteenthSalary.updateValueAndValidity();

      this.cumulativeIncidenceError = cumulativeOPLForm.proportionalCompensatedVacation.invalid
        || cumulativeOPLForm.oneThirdVacation.invalid
        || cumulativeOPLForm.earlyWarning.invalid
        || cumulativeOPLForm.legalAbsences.invalid
        || cumulativeOPLForm.thirteenthSalary.invalid;
    }
  }

  getValueString(value?: number): string {
    return value?.toString() ?? '';
  }

  setFormValues(value: ChargeModel | undefined) {
    const chargeBase = value?.chargeBase;
    const cumulativeBase = value?.cumulativeIncidenceBase;
    const cumulativeInss = value?.cumulativeIncidenceInss;
    const cumulativeFgts = value?.cumulativeIncidenceFgts;
    const chargeBaseForm = this.chargeComponent.form.controls;
    const otherChargeForm = this.otherChargesform.controls;

    // Charge
    chargeBaseForm.INSS.patchValue(this.getValueString(chargeBase?.inss), {
      emitEvent: false,
    });
    chargeBaseForm.FGTS.patchValue(this.getValueString(chargeBase?.fgts), {
      emitEvent: false,
    });
    chargeBaseForm.SEST.patchValue(this.getValueString(chargeBase?.sest), {
      emitEvent: false,
    });
    chargeBaseForm.SENAT.patchValue(this.getValueString(chargeBase?.senat), {
      emitEvent: false,
    });
    chargeBaseForm.INCRA.patchValue(this.getValueString(chargeBase?.incra), {
      emitEvent: false,
    });
    chargeBaseForm.SEBRAE.patchValue(this.getValueString(chargeBase?.sebrae), {
      emitEvent: false,
    });
    chargeBaseForm.educationSalary.patchValue(
      this.getValueString(chargeBase?.educationSalary),
      { emitEvent: false },
    );
    chargeBaseForm.FAP.patchValue(this.getValueString(chargeBase?.fap), {
      emitEvent: false,
    });
    chargeBaseForm.RAT.patchValue(this.getValueString(chargeBase?.rat), {
      emitEvent: false,
    });

    // Cumulative Incidence
    if (this.isUserRaizen) {
      const cumulativeForm = this.cumulativeIncidenceComponent.form.controls;

      const rowsValues = [
        {
          charges: cumulativeBase?.proportionalCompensatedVacation ?? 0,
          inssRps: cumulativeInss?.proportionalCompensatedVacation ?? 0,
          inssRpsIn25: cumulativeFgts?.proportionalCompensatedVacation ?? 0,
        },
        {
          charges: cumulativeBase?.oneThirdVacation ?? 0,
          inssRps: cumulativeInss?.oneThirdVacation ?? 0,
          inssRpsIn25: cumulativeFgts?.oneThirdVacation ?? 0,
        },
        {
          charges: cumulativeBase?.earlyWarning ?? 0,
          inssRps: cumulativeInss?.earlyWarning ?? 0,
          inssRpsIn25: cumulativeFgts?.earlyWarning ?? 0,
        },
        {
          charges: cumulativeBase?.legalAbsences ?? 0,
          inssRps: cumulativeInss?.legalAbsences ?? 0,
          inssRpsIn25: cumulativeFgts?.legalAbsences ?? 0,
        },
        {
          charges: cumulativeBase?.thirteenthSalary ?? 0,
          inssRps: cumulativeInss?.thirteenthSalary ?? 0,
          inssRpsIn25: cumulativeFgts?.thirteenthSalary ?? 0,
        },
      ];

      cumulativeForm.rows.controls.forEach((control, i) => {
        const rowControl = control.controls;
        rowControl.charges.setValue(
          this.getValueString(rowsValues[i]?.charges),
        );
        rowControl.inssRps.setValue(
          this.getValueString(rowsValues[i]?.inssRps),
        );
        rowControl.inssRpsIn25.setValue(
          this.getValueString(rowsValues[i]?.inssRpsIn25),
        );
      });
    } else {
      const cumulativeOPLForm = this.cumulativeIncidenceComponentOPL.form.controls;
      cumulativeOPLForm.proportionalCompensatedVacation.patchValue(
        this.getValueString(cumulativeBase?.proportionalCompensatedVacation),
      );
      cumulativeOPLForm.oneThirdVacation.patchValue(
        this.getValueString(cumulativeBase?.oneThirdVacation),
      );
      cumulativeOPLForm.earlyWarning.patchValue(
        this.getValueString(cumulativeBase?.earlyWarning),
      );
      cumulativeOPLForm.legalAbsences.patchValue(
        this.getValueString(cumulativeBase?.legalAbsences),
      );
      cumulativeOPLForm.thirteenthSalary.patchValue(
        this.getValueString(cumulativeBase?.thirteenthSalary),
      );
    }

    // Provision & Night Additional
    otherChargeForm.fgtsPenalty.patchValue(
      this.getValueString(chargeBase?.fgtsPenalty),
      { emitEvent: false },
    );
    otherChargeForm.fgtsPenaltySeasonalWorker.patchValue(
      this.getValueString(chargeBase?.fgtsPenaltySeasonalWorker),
    );
    otherChargeForm.nightAdditional.patchValue(
      this.getValueString(chargeBase?.nightAdditional),
    );
  }

  updateDFDVStore() {
    if (this.allFormsAreValid()) {
      this.chargesModel = this.buildRequest();
      this.dfdv.charge = this.chargesModel;
      this.store.dispatch(
        DfdvActions.updateSubobject({
          subObjectName: 'charge',
          data: this.chargesModel,
        }),
      );
    } else {
      this.hasErrorMessage = 'Os seguintes campos em vermelho e marcados com * são de preenchimento obrigatório.';
      this.showValidatorErrors();
    }
  }

  showValidatorErrors() {
    Object.keys(this.chargeComponent.form.controls).forEach((key) => {
      if (
        !this.chargeComponent.form.get(key)?.value
        && this.chargeComponent.form.get(key)?.value !== 0
      ) {
        this.chargeComponent.form.get(key)?.reset();
      }
    });
    Object.keys(this.otherChargesform.controls).forEach((key) => {
      if (
        !this.otherChargesform.get(key)?.value
        && this.otherChargesform.get(key)?.value !== 0
      ) {
        this.otherChargesform.get(key)?.reset();
      }
    });
  }
}
