import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  Event, NavigationEnd,
  Router,
} from '@angular/router';
import { RolesEnum } from 'src/app/common/enums/roles.enum';
import { LoggedUserModel } from 'src/app/common/models/logged-user.model';
import { AuthService } from 'src/app/common/services/auth.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { Utils } from 'src/app/common/utils/utils';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('smoothCollapseHorizontal', [
      state('initial', style({})),
      state(
        'final',
        style({
          width: '4.5rem',
        }),
      ),
      transition('initial <=> final', animate('200ms')),
    ]),
    trigger('smoothCollapseVertical', [
      state('initial', style({})),
      state(
        'final',
        style({
          height: '5rem',
        }),
      ),
      transition('initial <=> final', animate('200ms')),
    ]),
  ],
})
export class SidenavComponent implements AfterViewInit {
  public displaySidenav: boolean = false;
  public openMenu: boolean = true;
  public showMenu: boolean = false;
  public selectedMenu: number = 0;
  public menuPreRegister: boolean = false;
  public menuHome: boolean = false;
  public menuSolicitations: boolean = false;
  public menuDfdv: boolean = false;
  public menuAdmin: boolean = false;
  public menuLogout: boolean = false;
  public menuFixedCost: boolean = false;
  public loggedUser = new LoggedUserModel();
  public id: string | null = null;

  public breakpointBase: number = 1344;
  public widthBreakpoint: number = 0;
  public isMobile: boolean = false;

  @Output() notifyDisplayFunc = new EventEmitter<boolean>();

  @ViewChild('menu') menu!: MenuComponent;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storageService: StorageService,
    private util: Utils,
  ) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngAfterViewInit() {
    this.checkDevice();

    this.router.events.subscribe(this.handleRouterEvents.bind(this));
  }

  checkDevice() {
    if (this.getWindowInnerWidth() <= this.widthBreakpoint) {
      this.openMenu = true;
      this.showMenu = false;
      this.isMobile = true;
    } else {
      this.showMenu = true;
      this.isMobile = false;
    }
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }

  handleRouterEvents(event: Event) {
    if (event instanceof NavigationEnd) {
      if (event.url.includes('dfdv/')) {
        [, , this.id] = event.url.split('/');
      } else {
        this.id = null;
      }
      this.displayAppropriateMenus(event.url);
      this.selectAppropriateMenu(event.url);
    }
  }

  displayAppropriateMenus(url: string) {
    if (url.includes('pre-register')) {
      this.menuPreRegister = true;
      this.menuSolicitations = false;
      this.menuDfdv = false;
      this.menuAdmin = false;
      this.menuLogout = false;
    } else {
      const scope = this.storageService.getScope();
      this.getLoggedUser();
      this.menuPreRegister = false;
      this.menuSolicitations = true;
      this.menuDfdv = true;
      this.menuAdmin = scope.roles.some(
        (role) => role.name === RolesEnum.admin,
      );
      this.menuFixedCost = this.menuAdmin;
      this.menuLogout = true;
    }
  }

  selectAppropriateMenu(url: string) {
    this.displaySidenav = !url.includes('login');
    this.notifyDisplay();

    if (url.includes('pre-register')) {
      this.selectedMenu = 0;
    } else if (url.includes('home')) {
      this.selectedMenu = 1;
    } else if (url.includes('solicitation')) {
      this.selectedMenu = 2;
    } else if (url.includes('admin')) {
      this.selectedMenu = 3;
    } else if (url.includes('dfdv')) {
      if (url.includes('driver-factor')) {
        this.selectedMenu = 40;
      } else if (url.includes('general-cost')) {
        this.selectedMenu = 43;
      } else if (url.includes('support-team')) {
        this.selectedMenu = 44;
      } else if (url.includes('operational-team')) {
        this.selectedMenu = 54;
      } else if (url.includes('fleet')) {
        this.selectedMenu = 45;
      } else if (url.includes('plant')) {
        this.selectedMenu = 47;
      } else if (url.includes('tax')) {
        this.selectedMenu = 48;
      } else if (url.includes('charge')) {
        this.selectedMenu = 49;
      } else if (url.includes('variable-cost')) {
        this.selectedMenu = 50;
      } else if (url.includes('benefit')) {
        this.selectedMenu = 51;
      } else if (url.includes('profit')) {
        this.selectedMenu = 52;
      } else if (url.includes('rv-parameter')) {
        this.selectedMenu = 53;
      } else if (url.includes('scope')) {
        this.selectedMenu = 55;
      } else if (url.includes('summary')) {
        this.selectedMenu = 56;
      } else if (url.includes('table')) {
        this.selectedMenu = 57;
      } else {
        this.selectedMenu = 5;
      }
    } else if (url.includes('fixed-costs')) {
      this.selectedMenu = 4;
    } else {
      this.selectedMenu = -1;
    }
  }

  notifyDisplay() {
    if (this.notifyDisplayFunc) {
      this.notifyDisplayFunc.emit(this.displaySidenav);
    }
  }

  toggleOpenMenu() {
    this.openMenu = !this.openMenu;
  }

  toggleShowMenu() {
    this.showMenu = !this.showMenu;
  }

  logout() {
    this.authService.logout();
  }

  navigateToHome() {
    this.selectedMenu = 1;
    this.router.navigate(['home']);
    this.hideMenu();
  }

  navigateToSolicitation() {
    this.selectedMenu = 2;
    this.router.navigate(['solicitation']);
    this.hideMenu();
  }

  navigateToAdmin() {
    this.selectedMenu = 3;
    this.router.navigate(['admin']);
    this.hideMenu();
  }

  navigateToLogin() {
    this.selectedMenu = 0;
    this.router.navigate(['login']);
    this.hideMenu();
  }

  navigateToDfdv() {
    this.selectedMenu = 5;
    this.router.navigate(['dfdv']);
    this.hideMenu();
  }

  navigateToFixedCost() {
    this.selectedMenu = 4;
    this.router.navigate(['fixed-costs']);
    this.hideMenu();
  }

  navigateToGeneralCost() {
    this.selectedMenu = 43;
    this.router.navigate([`/dfdv/${this.id}/general-cost`]);
  }

  navigateToVariableCost() {
    this.selectedMenu = 50;
    this.router.navigate([`/dfdv/${this.id}/variable-cost`]);
  }

  navigateToOperationalTeam() {
    this.selectedMenu = 54;
    this.router.navigate([`/dfdv/${this.id}/operational-team`]);
  }

  navigateToSupportTeam() {
    this.selectedMenu = 44;
    this.router.navigate([`/dfdv/${this.id}/support-team`]);
  }

  navigateToFleet() {
    this.selectedMenu = 45;
    this.router.navigate([`/dfdv/${this.id}/fleet`]);
  }

  navigateToDriversFactor() {
    this.selectedMenu = 40;
    this.router.navigate([`/dfdv/${this.id}/driver-factor`]);
  }

  navigateToCharges() {
    this.selectedMenu = 49;
    this.router.navigate([`/dfdv/${this.id}/charge`]);
  }

  navigateToTaxes() {
    this.selectedMenu = 48;
    this.router.navigate([`/dfdv/${this.id}/tax`]);
  }

  navigateToBenefits() {
    this.selectedMenu = 51;
    this.router.navigate([`/dfdv/${this.id}/benefit`]);
  }

  navigateToProfit() {
    this.selectedMenu = 52;
    this.router.navigate([`/dfdv/${this.id}/profit`]);
  }

  navigateToPlant() {
    this.selectedMenu = 47;
    this.router.navigate([`/dfdv/${this.id}/plant`]);
  }

  navigateToRvParameter() {
    this.selectedMenu = 53;
    this.router.navigate([`/dfdv/${this.id}/rv-parameter`]);
  }

  navigateToScope() {
    this.selectedMenu = 55;
    this.router.navigate([`/dfdv/${this.id}/scope`]);
  }

  navigateToSummary() {
    this.selectedMenu = 56;
    this.router.navigate([`/dfdv/${this.id}/summary`]);
  }

  navigateToTable() {
    this.selectedMenu = 57;
    this.router.navigate([`/dfdv/${this.id}/table`]);
  }

  hideMenu() {
    if (this.isMobile) {
      this.showMenu = false;
    }
  }

  getLoggedUser() {
    const user = this.storageService.getLoggedUserData();
    if (user.userId !== undefined) {
      this.loggedUser.userId = user?.userId;
      this.loggedUser.userName = this.util.reducedName(user?.userName);
      this.loggedUser.userRoleName = this.util.formatName(
        user?.userRoleName,
        23,
      );
    } else {
      this.loggedUser.userId = '';
      this.loggedUser.userName = '';
      this.loggedUser.userRoleName = '';
    }
  }

  handleMenuItem(item: MenuItemComponent) {
    const itemsWithChilds = this.menu?.items
      .toArray()
      .filter((x) => x.hasChild);
    itemsWithChilds.forEach((i) => {
      if (i !== item) {
        i.open = i.open ? false : i.open;
      }
    });
  }
}
