/* eslint-disable no-param-reassign */
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { SubcategoryService } from 'src/app/services/subcategory/subcategory.service';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { UnitService } from 'src/app/services/unit/unit.service';
import { CreateUnitModel } from 'src/app/services/unit/models/create-unit-model';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { UpdateUnitModel } from 'src/app/services/unit/models/update-unit-model';
import { CategoryService } from 'src/app/services/category/category.service';
import { GetUnitCategoryModel } from 'src/app/services/unit/models/get-unit-category-model';
import { AuthService } from 'src/app/common/services/auth.service';
import { UnitPimsService } from 'src/app/services/pims/unit/unit.service';
import { UnitPimsModel } from 'src/app/services/pims/unit/models/unit-pims.model';
import { SearchControlModel } from './model/searc-control.model';

@Component({
  selector: 'app-unit-new',
  templateUrl: './unit-new.component.html',
  styleUrls: ['./unit-new.component.scss'],
})
export class UnitNewComponent implements OnInit {
  public form = this.formBuilder.group({
    name: ['', Validators.required],
    instanceId: [''],
  });

  public id: string = '';
  public unitCategories: GetUnitCategoryModel[] = [];
  public unitPims: UnitPimsModel[] = [];
  public unitSearch = new SearchControlModel();
  public modalModel = new ModalModel();
  public showModal: boolean = false;
  public loadingButton: boolean = false;
  public enableButton: boolean = false;
  public toogleCheck: boolean = true;
  public checked: boolean = true;
  public isLoading = true;

  public pageTitle: string = '';
  public description: string = '';
  public buttonLabel: string = '';
  public eInputType = InputTypeEnum;
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    public unitService: UnitService,
    public operatorService: OperatorService,
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService,
    public unitPimsService: UnitPimsService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id') ?? '';

      if (this.id) {
        this.pageTitle = `Unidade #${this.id.padStart(5, '0')}`;
        this.description = 'Favor realizar os ajustes necessários, e em seguida confirmar através do botão abaixo, para que possamos atualizar a unidade.';
        this.buttonLabel = 'SALVAR';
        this.loadUnitWithId();
      } else {
        this.pageTitle = 'Cadastro de nova unidade';
        this.description = 'Favor informar os seguintes dados, para que possamos cadastrar a unidade.';
        this.buttonLabel = 'CADASTRAR';
        this.loadUnitByStorage();
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.setStorage();
    });
  }

  loadUnitWithId() {
    forkJoin({ unit: this.unitService.getUnitById(+this.id) }).subscribe({
      next: ({ unit }) => {
        this.fillForm(unit);
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.resetUnitPims();
        this.isLoading = false;
        if (error.status === 404) {
          this.authService.redirectToAccessDenied();
        } else {
          const message = JSON.parse(JSON.stringify(error?.error));
          this.loadingButton = false;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.description = message?.Message ?? message;
          this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
          this.modalModel.title = 'Erro ao obter os dados';
          this.modalModel.success = true;
          this.showModal = true;
        }
      },
    });
  }

  loadUnitByStorage() {
    this.getStorage();
    this.isLoading = false;
  }

  handleSearchClick(value: string, model: SearchControlModel) {
    if (value === '') return;

    model.loading = true;
    this.unitPimsService.getUnitPims(value).subscribe({
      next: (element) => {
        this.unitPims = element;
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.item = element[0].instanceId;
      },
      error: () => {
        this.resetUnitPims();
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar a instância da unidade';
      },
    });
  }

  excludeCard(model: SearchControlModel) {
    model.selected = false;
    model.item = '';
    this.resetUnitPims();
  }

  handleCloseModal(model: SearchControlModel) {
    model.showModal = false;
  }

  handleToggleCheck() {
    this.checked = !this.checked;
    this.toogleCheck = this.checked;
    this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  clickModal() {
    if (this.modalModel.success) {
      this.goBack();
    }

    this.showModal = false;
  }

  goBack() {
    this.router.navigate([`/admin/unit/${this.id}`]);
  }

  checkFormIsValid() {
    if (!this.form.valid) {
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Dados obrigatorios não preenchidos';
      this.modalModel.description = 'Preencha todos os dados necessarios para continuar com o cadastro de uma nova unidade';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.showModal = true;
      return false;
    }
    return true;
  }

  createUnit() {
    if (!this.checkFormIsValid()) return;

    const request = {
      name: this.form.controls.name.value ?? '',
      unitPims: this.unitPims,
      active: this.checked,
    } as CreateUnitModel;

    this.loadingButton = true;
    this.unitService.createUnit(request).subscribe({
      next: this.handleCreateOrUpdateUnitSuccess.bind(this),
      error: this.handleCreateOrUpdateUnitError.bind(this),
    });
  }

  updateUnit() {
    if (!this.checkFormIsValid()) return;

    const request = {
      unitId: +this.id,
      name: this.form.controls.name.value ?? '',
      unitPims: this.unitPims,
      active: this.checked,
    } as UpdateUnitModel;

    this.loadingButton = true;
    this.unitService.updateUnit(request).subscribe({
      next: this.handleCreateOrUpdateUnitSuccess.bind(this),
      error: this.handleCreateOrUpdateUnitError.bind(this),
    });
  }

  handleCreateOrUpdateUnitSuccess(response: GetUnitModel) {
    this.id = this.id ? this.id : response?.unitId.toString();
    this.loadingButton = false;
    this.modalModel.imagePath = '../../../assets/icons/success-600.gif';
    this.modalModel.title = this.id
      ? 'Unidade atualizada com sucesso!'
      : 'Unidade cadastrada com sucesso!';
    this.modalModel.description = `Atenção: a unidade encontra-se ${
      this.toogleCheck ? 'ATIVA' : 'INATIVA'
    }.`;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.showModal = true;
    this.storageService.clearUnit();
  }

  handleCreateOrUpdateUnitError(res: HttpErrorResponse) {
    this.loadingButton = false;
    const message = JSON.parse(JSON.stringify(res.error));
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível ${
      this.id ? 'atualizar' : 'cadastrar'
    } a unidade`;
    this.modalModel.description = message?.Message;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.showModal = true;
  }

  fillForm(unit: GetUnitModel) {
    this.unitCategories = unit.categories;
    this.unitPims = unit.unitPims;
    this.form.controls.instanceId.patchValue(unit.unitPims[0]?.instanceId ?? '');
    this.unitSearch.selected = !!unit.unitPims[0]?.instanceId;
    this.unitSearch.item = unit.unitPims[0]?.instanceId ?? '';
    this.form.controls.name.patchValue(unit.name);
    this.checked = unit.active;
    this.toogleCheck = unit.active;
  }

  setStorage() {
    this.enableButton = this.form.valid;


    const unitModel = {
      name: this.form.controls.name.value!,
      active: this.toogleCheck,
      unitPims: this.form.controls.instanceId.value ? this.unitPims : [],
      categories: this.unitCategories,
    } as GetUnitModel;

    this.storageService.setUnit(unitModel);
  }

  getStorage() {
    const unit = this.storageService.getUnit();
    if (unit !== undefined) {
      this.fillForm(unit);
      this.enableButton = this.form.valid;
    }
  }

  addCategoryToUnit() {
    this.setStorage();
    this.router.navigate([`admin/unit/${this.id}/category/new`]);
  }

  resetUnitPims() {
    this.unitPims = [];
  }
}
