<app-card
  title="Solicitações"
  icon="../../../../assets/icons/solicitation-primary-600.svg"
  [paddingHorizontal]="false"
>
  <section actions class="actions" *ngIf="!isLoading && !error">
    <app-check-box
      [label]="'Selecionar todos'"
      [labelColor]="'#781f78'"
      [bold]="true"
      (check)="checkAll()"
      (removeCheck)="removeAllChecks()"
      *ngIf="approvalVisible"
    ></app-check-box>
    <div *ngIf="solicitacoes.length > 0">
      <app-icon-button
        (clickButton)="getSolicitationsReportDownload()"
        icon="../../../../assets/icons/spreadsheet-download.svg"
        text="Baixar"
        [enabled]="enabledReport"
        [loading]="loading"
        [color]="ebuttonColor.primary"
      ></app-icon-button>
    </div>
    <div *ngIf="approvalVisible">
      <app-icon-button
        (clickButton)="repprove()"
        icon="../../../../assets/icons/reprove.svg"
        text="Reprovar"
        [enabled]="selectedSolicitations.length > 0"
        [loading]="loadingDisapprove"
        [color]="ebuttonColor.warning"
      ></app-icon-button>
    </div>
    <div *ngIf="approvalVisible">
      <app-icon-button
        (clickButton)="approve()"
        icon="../../../../assets/icons/approve-success.svg"
        text="Aprovar"
        [enabled]="selectedSolicitations.length > 0"
        [loading]="loadingApprove"
        [color]="ebuttonColor.success"
      ></app-icon-button>
    </div>
  </section>
  <div
    body
    class="body"
    *ngIf="!isLoading && solicitacoes.length > 0 && !error"
  >
    <div class="actions-list">
      <app-check-box
        [label]="checkBoxLabel ? 'Selecionar todos' : ''"
        [labelColor]="'#781f78'"
        [bold]="true"
        (check)="checkAll()"
        (removeCheck)="removeAllChecks()"
        *ngIf="approvalVisible"
      ></app-check-box>
      <div *ngIf="solicitacoes.length > 0">
        <app-icon-button
          (clickButton)="getSolicitationsReportDownload()"
          icon="../../../../assets/icons/spreadsheet-download.svg"
          text="Baixar"
          [enabled]="enabledReport"
          [loading]="loading"
          [color]="ebuttonColor.primary"
        ></app-icon-button>
      </div>
      <div *ngIf="approvalVisible">
        <app-icon-button
          (clickButton)="repprove()"
          icon="../../../../assets/icons/reprove.svg"
          text="Reprovar"
          [enabled]="enabledDisapprove"
          [loading]="loadingDisapprove"
          [color]="ebuttonColor.warning"
        ></app-icon-button>
      </div>
      <div *ngIf="approvalVisible">
        <app-icon-button
          (clickButton)="approve()"
          icon="../../../../assets/icons/approve-success.svg"
          text="Aprovar"
          [enabled]="enabledApprove"
          [loading]="loadingApprove"
          [color]="ebuttonColor.success"
        ></app-icon-button>
      </div>
    </div>
    <div class="solicitations">
      <div
        *ngFor="let solicitation of solicitacoes; let indexOfelement = index"
        class="rows"
      >
        <app-solicitation-detail
          [solicitation]="solicitation"
          [isPairRow]="indexOfelement % 2 === 0"
          [approvalVisible]="approvalVisible"
          (check)="checkItem($event)"
          (removeCheck)="removeCheck($event)"
        ></app-solicitation-detail>
      </div>
    </div>
    <br />
    <app-paginator
      [page]="page"
      [totalPages]="totalPages"
      (callbackFunc)="setPageCallbackFunc($event)"
    ></app-paginator>
  </div>
  <div body class="skeleton" *ngIf="isLoading">
    <app-skeleton-line [line]="5"></app-skeleton-line>
  </div>
  <div
    body
    class="not-found"
    *ngIf="!isLoading && solicitacoes.length === 0 && !error"
  >
    <app-not-found></app-not-found>
  </div>
  <div
    body
    class="error"
    *ngIf="!isLoading && solicitacoes.length === 0 && error"
  >
    <app-error (reloadCallBack)="reload()"></app-error>
  </div>
</app-card>
<div>
  <app-solicitation-approval-modal
    [showModal]="showModal"
    [approval]="approval"
    [required]="required"
    [title]="modalTitle"
    [buttonLabel]="buttonLabel"
    [buttonImage]="buttonImage"
    [buttonType]="buttonType"
    [buttonColor]="buttonColor"
    (clickButton)="modalClick($event)"
  ></app-solicitation-approval-modal>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
