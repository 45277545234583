/* eslint-disable operator-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ParametersModel } from 'src/app/services/dfdv/parameters/models/parameters.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { TabItemComponent } from 'src/app/shared/tab/tab-item/tab-item.component';
import { TabComponent } from 'src/app/shared/tab/tab.component';
import { GetGeneralCostsModel } from 'src/app/services/dfdv/general-costs/models/get-general-cost.model';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { GeneralCostsModel } from '../../../../services/dfdv/general-costs/models/general-costs.model';
import { AdministrativeTeamUniformComponent } from './administrative-team/administrative-team-uniform.component';
import { MaintenanceAndWarehouseTeamUniformComponent } from './maintence-warehouse/maintence-and-warehouse-team-uniform.component';
import { OfficeExpensesComponent } from './office-expenses/office-expenses.component';
import { OperationalTeamUniformComponent } from './operational-team/operational-team-uniform.component';

@Component({
  selector: 'app-general-costs',
  templateUrl: './general-costs.component.html',
  styleUrls: ['./general-costs.component.scss'],
})
export class GeneralCostsComponent implements OnInit, OnDestroy {
  // Enums
  public ButtonColorEnum = ButtonColorEnum;

  // Aux
  public shouldDisableFields: boolean = false;
  public parameterModels: ParametersModel[] = [];
  public harvestMonths: number = 0;
  public dfdv!: GetDfdvModel;
  public dfdvLoading: boolean = false;
  public dfdvUtils!: DfdvUtils;

  // Errors
  public officeError: boolean = false;
  public managementError: boolean = false;
  public operationalError: boolean = false;
  public maintenceError: boolean = false;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  @ViewChild('tabs') tabs!: TabComponent;
  @ViewChild('tabOffice') tabOffice!: TabItemComponent;
  @ViewChild('tabManagement') tabManegement!: TabItemComponent;
  @ViewChild('tabOperational') tabOperational!: TabItemComponent;
  @ViewChild('tabMaintence') tabMaintence!: TabItemComponent;

  @ViewChild('office') officeComponent!: OfficeExpensesComponent;
  @ViewChild('management')
  managementComponent!: AdministrativeTeamUniformComponent;
  @ViewChild('operational')
  operationalComponent!: OperationalTeamUniformComponent;
  @ViewChild('maintence')
  maintenceComponent!: MaintenanceAndWarehouseTeamUniformComponent;

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  rvGeneralCostsStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.generalCosts));
  plantStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.plant));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.rvGeneralCostsStore$.subscribe((generalCosts) => {
      if (!generalCosts) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        setTimeout(() => {
          this.populateForms(generalCosts);
        }, 0);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };
      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        const enableForOPL =
          !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
        const enableForRaizen =
          this.dfdvUtils.isUserRaizen() &&
          (this.dfdvUtils.isNew() || this.dfdvUtils.isDraft());
        this.shouldDisableFields = !(enableForOPL || enableForRaizen);
      }
    });

    this.plantStore$.subscribe((plant) => {
      this.harvestMonths = plant?.harvestMonths;
    });
  }

  populateForms(result: GetGeneralCostsModel) {
    const generalCostModel = this.mapFromGeneralCostResult(result);

    if (generalCostModel === undefined) {
      this.resetControls();
      return;
    }

    const controlMappings = [
      {
        controls:
          this.officeComponent.officeExpensesModel.controls.rows.controls,
        source: generalCostModel.administrativeCosts,
      },
      {
        controls:
          this.managementComponent.AdministrativeTeamUniformModel.controls.rows
            .controls,
        source: generalCostModel.administrativeUniform,
      },
      {
        controls:
          this.operationalComponent.uniformOperationalTeam.controls.rows
            .controls,
        source: generalCostModel.operationalUniform,
      },
      {
        controls:
          this.maintenceComponent.uniformMaintenceWarehouseTeamModel.controls
            .rows.controls,
        source: generalCostModel.maintenanceAndWarehouseUniform,
      },
    ];

    controlMappings.forEach(({ controls, source }) => {
      controls.forEach((control, i) => {
        const element = Object.keys(source)[i];
        const rowControl = control.controls;

        rowControl.quantity.setValue(
          source[element]?.quantity.toString() || '0'
        );
        rowControl.cost.setValue(source[element]?.unitCost.toString() || '0');
      });
    });
  }

  updateDFDVStore() {
    const generalCostModel = new GeneralCostsModel();
    const controlMappings = [
      {
        controls:
          this.officeComponent.officeExpensesModel.controls.rows.controls,
        target: generalCostModel.administrativeCosts,
      },
      {
        controls:
          this.managementComponent.AdministrativeTeamUniformModel.controls.rows
            .controls,
        target: generalCostModel.administrativeUniform,
      },
      {
        controls:
          this.operationalComponent.uniformOperationalTeam.controls.rows
            .controls,
        target: generalCostModel.operationalUniform,
      },
      {
        controls:
          this.maintenceComponent.uniformMaintenceWarehouseTeamModel.controls
            .rows.controls,
        target: generalCostModel.maintenanceAndWarehouseUniform,
      },
    ];

    controlMappings.forEach(({ controls, target }) => {
      controls.forEach((control, i) => {
        const element = Object.keys(target)[i];
        const rowControl = control.controls;

        target[element] = {
          quantity: +(rowControl.quantity.value ?? 0),
          unitCost: +(rowControl.cost.value ?? 0),
        };
      });
    });

    this.dfdv.generalCosts = this.mapToGeneralCostResult(generalCostModel);

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'generalCosts',
        data: this.dfdv.generalCosts,
      })
    );
  }

  resetControls() {
    const controlMappings = [
      {
        controls:
          this.officeComponent.officeExpensesModel.controls.rows.controls,
      },
      {
        controls:
          this.managementComponent.AdministrativeTeamUniformModel.controls.rows
            .controls,
      },
      {
        controls:
          this.operationalComponent.uniformOperationalTeam.controls.rows
            .controls,
      },
      {
        controls:
          this.maintenceComponent.uniformMaintenceWarehouseTeamModel.controls
            .rows.controls,
      },
    ];

    controlMappings.forEach(({ controls }) => {
      controls.forEach((control) => {
        const rowControl = control.controls;
        rowControl.quantity.setValue(null);
        rowControl.quantity.reset();
        rowControl.quantity.setErrors(null);
        rowControl.quantity.markAsTouched();
        rowControl.cost.setValue(null);
        rowControl.cost.reset();
        rowControl.cost.setErrors(null);
        rowControl.cost.markAsTouched();
      });
    });

    this.officeError = false;
    this.managementError = false;
    this.operationalError = false;
    this.maintenceError = false;
  }

  mapFromGeneralCostResult(
    result: GetGeneralCostsModel
  ): GeneralCostsModel | undefined {
    if (result === undefined) {
      return undefined;
    }

    const generalCosts = new GeneralCostsModel();
    generalCosts.totalMonthlyCostOfAdministrativeUniform =
      result?.totalMonthlyCostOfAdministrativeUniform;
    generalCosts.totalMonthlyCostOfOperationalUniform =
      result?.totalMonthlyCostOfOperationalUniform;
    generalCosts.totalMonthlyCostOfMaintenanceAndWarehouseUniform =
      result?.totalMonthlyCostOfMaintenanceAndWarehouseUniform;
    generalCosts.totalMonthlyCostOfAdministrativeCosts =
      result?.totalMonthlyCostOfAdministrativeCosts;
    generalCosts.totalHarvestCostOfAdministrativeCosts =
      result?.totalHarvestCostOfAdministrativeCosts;
    generalCosts.totalHarvestCostOfAdministrativeUniform =
      result?.totalHarvestCostOfAdministrativeUniform;
    generalCosts.totalHarvestCostOfOperationalUniform =
      result?.totalHarvestCostOfOperationalUniform;
    generalCosts.totalHarvestCostOfMaintenanceAndWarehouseUniform =
      result?.totalHarvestCostOfMaintenanceAndWarehouseUniform;

    generalCosts.administrativeCosts.mobileCommunication.quantity =
      result?.administrativeCosts.mobileCommunicationQuantity;
    generalCosts.administrativeCosts.mobileCommunication.unitCost =
      result?.administrativeCosts.mobileCommunicationUnitCost;
    generalCosts.administrativeCosts.officeSupply.quantity =
      result?.administrativeCosts.officeSupplyQuantity;
    generalCosts.administrativeCosts.officeSupply.unitCost =
      result?.administrativeCosts.officeSupplyUnitCost;
    generalCosts.administrativeCosts.telephone.quantity =
      result?.administrativeCosts.telephoneQuantity;
    generalCosts.administrativeCosts.telephone.unitCost =
      result?.administrativeCosts.telephoneUnitCost;
    generalCosts.administrativeCosts.systemMaintenance.quantity =
      result?.administrativeCosts.systemMaintenanceQuantity;
    generalCosts.administrativeCosts.systemMaintenance.unitCost =
      result?.administrativeCosts.systemMaintenanceUnitCost;
    generalCosts.administrativeCosts.internetProvider.quantity =
      result?.administrativeCosts.internetProviderQuantity;
    generalCosts.administrativeCosts.internetProvider.unitCost =
      result?.administrativeCosts.internetProviderUnitCost;

    generalCosts.administrativeUniform.pants.quantity =
      result?.administrativeUniform.pantsQuantity;
    generalCosts.administrativeUniform.pants.unitCost =
      result?.administrativeUniform.pantsUnitCost;
    generalCosts.administrativeUniform.shirt.quantity =
      result?.administrativeUniform.shirtQuantity;
    generalCosts.administrativeUniform.shirt.unitCost =
      result?.administrativeUniform.shirtUnitCost;
    generalCosts.administrativeUniform.boots.quantity =
      result?.administrativeUniform.bootsQuantity;
    generalCosts.administrativeUniform.boots.unitCost =
      result?.administrativeUniform.bootsUnitCost;
    generalCosts.administrativeUniform.glasses.quantity =
      result?.administrativeUniform.glassesQuantity;
    generalCosts.administrativeUniform.glasses.unitCost =
      result?.administrativeUniform.glassesUnitCost;
    generalCosts.administrativeUniform.hearingProtection.quantity =
      result?.administrativeUniform.hearingProtectionQuantity;
    generalCosts.administrativeUniform.hearingProtection.unitCost =
      result?.administrativeUniform.hearingProtectionUnitCost;

    generalCosts.operationalUniform.pants.quantity =
      result?.operationalUniform.pantsQuantity;
    generalCosts.operationalUniform.pants.unitCost =
      result?.operationalUniform.pantsUnitCost;
    generalCosts.operationalUniform.shirt.quantity =
      result?.operationalUniform.shirtQuantity;
    generalCosts.operationalUniform.shirt.unitCost =
      result?.operationalUniform.shirtUnitCost;
    generalCosts.operationalUniform.boots.quantity =
      result?.operationalUniform.bootsQuantity;
    generalCosts.operationalUniform.boots.unitCost =
      result?.operationalUniform.bootsUnitCost;
    generalCosts.operationalUniform.gloves.quantity =
      result?.operationalUniform.glovesQuantity;
    generalCosts.operationalUniform.gloves.unitCost =
      result?.operationalUniform.glovesUnitCost;
    generalCosts.operationalUniform.glasses.quantity =
      result?.operationalUniform.glassesQuantity;
    generalCosts.operationalUniform.glasses.unitCost =
      result?.operationalUniform.glassesUnitCost;
    generalCosts.operationalUniform.helmet.quantity =
      result?.operationalUniform.helmetQuantity;
    generalCosts.operationalUniform.helmet.unitCost =
      result?.operationalUniform.helmetUnitCost;
    generalCosts.operationalUniform.hygieneKit.quantity =
      result?.operationalUniform.hygieneKitQuantity;
    generalCosts.operationalUniform.hygieneKit.unitCost =
      result?.operationalUniform.hygieneKitUnitCost;
    generalCosts.operationalUniform.hearingProtection.quantity =
      result?.operationalUniform.hearingProtectionQuantity;
    generalCosts.operationalUniform.hearingProtection.unitCost =
      result?.operationalUniform.hearingProtectionUnitCost;
    generalCosts.operationalUniform.leggings.quantity =
      result?.operationalUniform.leggingsQuantity;
    generalCosts.operationalUniform.leggings.unitCost =
      result?.operationalUniform.leggingsUnitCost;
    generalCosts.operationalUniform.sweatshirt.quantity =
      result?.operationalUniform.sweatshirtQuantity;
    generalCosts.operationalUniform.sweatshirt.unitCost =
      result?.operationalUniform.sweatshirtUnitCost;
    generalCosts.operationalUniform.raincoat.quantity =
      result?.operationalUniform.raincoatQuantity;
    generalCosts.operationalUniform.raincoat.unitCost =
      result?.operationalUniform.raincoatUnitCost;

    generalCosts.maintenanceAndWarehouseUniform.pants.quantity =
      result?.maintenanceAndWarehouseUniform.pantsQuantity;
    generalCosts.maintenanceAndWarehouseUniform.pants.unitCost =
      result?.maintenanceAndWarehouseUniform.pantsUnitCost;
    generalCosts.maintenanceAndWarehouseUniform.shirt.quantity =
      result?.maintenanceAndWarehouseUniform.shirtQuantity;
    generalCosts.maintenanceAndWarehouseUniform.shirt.unitCost =
      result?.maintenanceAndWarehouseUniform.shirtUnitCost;
    generalCosts.maintenanceAndWarehouseUniform.boots.quantity =
      result?.maintenanceAndWarehouseUniform.bootsQuantity;
    generalCosts.maintenanceAndWarehouseUniform.boots.unitCost =
      result?.maintenanceAndWarehouseUniform.bootsUnitCost;
    generalCosts.maintenanceAndWarehouseUniform.gloves.quantity =
      result?.maintenanceAndWarehouseUniform.glovesQuantity;
    generalCosts.maintenanceAndWarehouseUniform.gloves.unitCost =
      result?.maintenanceAndWarehouseUniform.glovesUnitCost;
    generalCosts.maintenanceAndWarehouseUniform.glasses.quantity =
      result?.maintenanceAndWarehouseUniform.glassesQuantity;
    generalCosts.maintenanceAndWarehouseUniform.glasses.unitCost =
      result?.maintenanceAndWarehouseUniform.glassesUnitCost;
    generalCosts.maintenanceAndWarehouseUniform.sweatshirt.quantity =
      result?.maintenanceAndWarehouseUniform.sweatshirtQuantity;
    generalCosts.maintenanceAndWarehouseUniform.sweatshirt.unitCost =
      result?.maintenanceAndWarehouseUniform.sweatshirtUnitCost;

    return generalCosts;
  }

  mapToGeneralCostResult(
    generalCosts: GeneralCostsModel
  ): GetGeneralCostsModel | undefined {
    if (generalCosts === undefined) {
      return undefined;
    }

    const result = {
      administrativeCosts: {
        mobileCommunicationQuantity:
          generalCosts.administrativeCosts.mobileCommunication.quantity,
        mobileCommunicationUnitCost:
          generalCosts.administrativeCosts.mobileCommunication.unitCost,
        mobileCommunicationMonthlyCost:
          generalCosts.administrativeCosts.mobileCommunication.quantity *
          generalCosts.administrativeCosts.mobileCommunication.unitCost,
        mobileCommunicationHarvestCost:
          generalCosts.administrativeCosts.mobileCommunication.quantity *
          generalCosts.administrativeCosts.mobileCommunication.unitCost *
          this.harvestMonths,

        officeSupplyQuantity:
          generalCosts.administrativeCosts.officeSupply.quantity,
        officeSupplyUnitCost:
          generalCosts.administrativeCosts.officeSupply.unitCost,
        officeSupplyMonthlyCost:
          generalCosts.administrativeCosts.officeSupply.quantity *
          generalCosts.administrativeCosts.officeSupply.unitCost,
        officeSupplyHarvestCost:
          generalCosts.administrativeCosts.officeSupply.quantity *
          generalCosts.administrativeCosts.officeSupply.unitCost *
          this.harvestMonths,

        telephoneQuantity: generalCosts.administrativeCosts.telephone.quantity,
        telephoneUnitCost: generalCosts.administrativeCosts.telephone.unitCost,
        telephoneMonthlyCost:
          generalCosts.administrativeCosts.telephone.quantity *
          generalCosts.administrativeCosts.telephone.unitCost,
        telephoneHarvestCost:
          generalCosts.administrativeCosts.telephone.quantity *
          generalCosts.administrativeCosts.telephone.unitCost *
          this.harvestMonths,

        systemMaintenanceQuantity:
          generalCosts.administrativeCosts.systemMaintenance.quantity,
        systemMaintenanceUnitCost:
          generalCosts.administrativeCosts.systemMaintenance.unitCost,
        systemMaintenanceMonthlyCost:
          generalCosts.administrativeCosts.systemMaintenance.quantity *
          generalCosts.administrativeCosts.systemMaintenance.unitCost,
        systemMaintenanceHarvestCost:
          generalCosts.administrativeCosts.systemMaintenance.quantity *
          generalCosts.administrativeCosts.systemMaintenance.unitCost *
          this.harvestMonths,

        internetProviderQuantity:
          generalCosts.administrativeCosts.internetProvider.quantity,
        internetProviderUnitCost:
          generalCosts.administrativeCosts.internetProvider.unitCost,
        internetProviderMonthlyCost:
          generalCosts.administrativeCosts.internetProvider.quantity *
          generalCosts.administrativeCosts.internetProvider.unitCost,
        internetProviderHarvestCost:
          generalCosts.administrativeCosts.internetProvider.quantity *
          generalCosts.administrativeCosts.internetProvider.unitCost *
          this.harvestMonths,
      },
      administrativeUniform: {
        pantsQuantity: generalCosts.administrativeUniform.pants.quantity,
        pantsUnitCost: generalCosts.administrativeUniform.pants.unitCost,
        pantsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.administrativeUniform.pants.quantity *
                generalCosts.administrativeUniform.pants.unitCost) /
              this.harvestMonths,
        pantsHarvestCost:
          generalCosts.administrativeUniform.pants.quantity *
          generalCosts.administrativeUniform.pants.unitCost,
        shirtQuantity: generalCosts.administrativeUniform.shirt.quantity,
        shirtUnitCost: generalCosts.administrativeUniform.shirt.unitCost,
        shirtMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.administrativeUniform.shirt.quantity *
                generalCosts.administrativeUniform.shirt.unitCost) /
              this.harvestMonths,
        shirtHarvestCost:
          generalCosts.administrativeUniform.shirt.quantity *
          generalCosts.administrativeUniform.shirt.unitCost,

        bootsQuantity: generalCosts.administrativeUniform.boots.quantity,
        bootsUnitCost: generalCosts.administrativeUniform.boots.unitCost,
        bootsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.administrativeUniform.boots.quantity *
                generalCosts.administrativeUniform.boots.unitCost) /
              this.harvestMonths,
        bootsHarvestCost:
          generalCosts.administrativeUniform.boots.quantity *
          generalCosts.administrativeUniform.boots.unitCost,

        glassesQuantity: generalCosts.administrativeUniform.glasses.quantity,
        glassesUnitCost: generalCosts.administrativeUniform.glasses.unitCost,
        glassesMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.administrativeUniform.glasses.quantity *
                generalCosts.administrativeUniform.glasses.unitCost) /
              this.harvestMonths,
        glassesHarvestCost:
          generalCosts.administrativeUniform.glasses.quantity *
          generalCosts.administrativeUniform.glasses.unitCost,

        hearingProtectionQuantity:
          generalCosts.administrativeUniform.hearingProtection.quantity,
        hearingProtectionUnitCost:
          generalCosts.administrativeUniform.hearingProtection.unitCost,
        hearingProtectionMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.administrativeUniform.hearingProtection.quantity *
                generalCosts.administrativeUniform.hearingProtection.unitCost) /
              this.harvestMonths,
        hearingProtectionHarvestCost:
          generalCosts.administrativeUniform.hearingProtection.quantity *
          generalCosts.administrativeUniform.hearingProtection.unitCost,
      },
      operationalUniform: {
        pantsQuantity: generalCosts.operationalUniform.pants.quantity,
        pantsUnitCost: generalCosts.operationalUniform.pants.unitCost,
        pantsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.pants.quantity *
                generalCosts.operationalUniform.pants.unitCost) /
              this.harvestMonths,
        pantsHarvestCost:
          generalCosts.operationalUniform.pants.quantity *
          generalCosts.operationalUniform.pants.unitCost,

        shirtQuantity: generalCosts.operationalUniform.shirt.quantity,
        shirtUnitCost: generalCosts.operationalUniform.shirt.unitCost,
        shirtMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.shirt.quantity *
                generalCosts.operationalUniform.shirt.unitCost) /
              this.harvestMonths,
        shirtHarvestCost:
          generalCosts.operationalUniform.shirt.quantity *
          generalCosts.operationalUniform.shirt.unitCost,

        bootsQuantity: generalCosts.operationalUniform.boots.quantity,
        bootsUnitCost: generalCosts.operationalUniform.boots.unitCost,
        bootsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.boots.quantity *
                generalCosts.operationalUniform.boots.unitCost) /
              this.harvestMonths,
        bootsHarvestCost:
          generalCosts.operationalUniform.boots.quantity *
          generalCosts.operationalUniform.boots.unitCost,

        glovesQuantity: generalCosts.operationalUniform.gloves.quantity,
        glovesUnitCost: generalCosts.operationalUniform.gloves.unitCost,
        glovesMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.gloves.quantity *
                generalCosts.operationalUniform.gloves.unitCost) /
              this.harvestMonths,
        glovesHarvestCost:
          generalCosts.operationalUniform.gloves.quantity *
          generalCosts.operationalUniform.gloves.unitCost,

        glassesQuantity: generalCosts.operationalUniform.glasses.quantity,
        glassesUnitCost: generalCosts.operationalUniform.glasses.unitCost,
        glassesMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.glasses.quantity *
                generalCosts.operationalUniform.glasses.unitCost) /
              this.harvestMonths,
        glassesHarvestCost:
          generalCosts.operationalUniform.glasses.quantity *
          generalCosts.operationalUniform.glasses.unitCost,

        helmetQuantity: generalCosts.operationalUniform.helmet.quantity,
        helmetUnitCost: generalCosts.operationalUniform.helmet.unitCost,
        helmetMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.helmet.quantity *
                generalCosts.operationalUniform.helmet.unitCost) /
              this.harvestMonths,
        helmetHarvestCost:
          generalCosts.operationalUniform.helmet.quantity *
          generalCosts.operationalUniform.helmet.unitCost,

        hygieneKitQuantity: generalCosts.operationalUniform.hygieneKit.quantity,
        hygieneKitUnitCost: generalCosts.operationalUniform.hygieneKit.unitCost,
        hygieneKitMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.hygieneKit.quantity *
                generalCosts.operationalUniform.hygieneKit.unitCost) /
              this.harvestMonths,
        hygieneKitHarvestCost:
          generalCosts.operationalUniform.hygieneKit.quantity *
          generalCosts.operationalUniform.hygieneKit.unitCost,

        hearingProtectionQuantity:
          generalCosts.operationalUniform.hearingProtection.quantity,
        hearingProtectionUnitCost:
          generalCosts.operationalUniform.hearingProtection.unitCost,
        hearingProtectionMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.hearingProtection.quantity *
                generalCosts.operationalUniform.hearingProtection.unitCost) /
              this.harvestMonths,
        hearingProtectionHarvestCost:
          generalCosts.operationalUniform.hearingProtection.quantity *
          generalCosts.operationalUniform.hearingProtection.unitCost,

        leggingsQuantity: generalCosts.operationalUniform.leggings.quantity,
        leggingsUnitCost: generalCosts.operationalUniform.leggings.unitCost,
        leggingsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.leggings.quantity *
                generalCosts.operationalUniform.leggings.unitCost) /
              this.harvestMonths,
        leggingsHarvestCost:
          generalCosts.operationalUniform.leggings.quantity *
          generalCosts.operationalUniform.leggings.unitCost,

        sweatshirtQuantity: generalCosts.operationalUniform.sweatshirt.quantity,
        sweatshirtUnitCost: generalCosts.operationalUniform.sweatshirt.unitCost,
        sweatshirtMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.sweatshirt.quantity *
                generalCosts.operationalUniform.sweatshirt.unitCost) /
              this.harvestMonths,
        sweatshirtHarvestCost:
          generalCosts.operationalUniform.sweatshirt.quantity *
          generalCosts.operationalUniform.sweatshirt.unitCost,

        raincoatQuantity: generalCosts.operationalUniform.raincoat.quantity,
        raincoatUnitCost: generalCosts.operationalUniform.raincoat.unitCost,
        raincoatMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.operationalUniform.raincoat.quantity *
                generalCosts.operationalUniform.raincoat.unitCost) /
              this.harvestMonths,
        raincoatHarvestCost:
          generalCosts.operationalUniform.raincoat.quantity *
          generalCosts.operationalUniform.raincoat.unitCost,
      },
      maintenanceAndWarehouseUniform: {
        pantsQuantity:
          generalCosts.maintenanceAndWarehouseUniform.pants.quantity,
        pantsUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.pants.unitCost,
        pantsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.pants.quantity *
                generalCosts.maintenanceAndWarehouseUniform.pants.unitCost) /
              this.harvestMonths,
        pantsHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.pants.quantity *
          generalCosts.maintenanceAndWarehouseUniform.pants.unitCost,

        shirtQuantity:
          generalCosts.maintenanceAndWarehouseUniform.shirt.quantity,
        shirtUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.shirt.unitCost,
        shirtMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.shirt.quantity *
                generalCosts.maintenanceAndWarehouseUniform.shirt.unitCost) /
              this.harvestMonths,
        shirtHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.shirt.quantity *
          generalCosts.maintenanceAndWarehouseUniform.shirt.unitCost,

        bootsQuantity:
          generalCosts.maintenanceAndWarehouseUniform.boots.quantity,
        bootsUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.boots.unitCost,
        bootsMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.boots.quantity *
                generalCosts.maintenanceAndWarehouseUniform.boots.unitCost) /
              this.harvestMonths,
        bootsHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.boots.quantity *
          generalCosts.maintenanceAndWarehouseUniform.boots.unitCost,

        glovesQuantity:
          generalCosts.maintenanceAndWarehouseUniform.gloves.quantity,
        glovesUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.gloves.unitCost,
        glovesMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.gloves.quantity *
                generalCosts.maintenanceAndWarehouseUniform.gloves.unitCost) /
              this.harvestMonths,
        glovesHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.gloves.quantity *
          generalCosts.maintenanceAndWarehouseUniform.gloves.unitCost,

        glassesQuantity:
          generalCosts.maintenanceAndWarehouseUniform.glasses.quantity,
        glassesUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.glasses.unitCost,
        glassesMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.glasses.quantity *
                generalCosts.maintenanceAndWarehouseUniform.glasses.unitCost) /
              this.harvestMonths,
        glassesHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.glasses.quantity *
          generalCosts.maintenanceAndWarehouseUniform.glasses.unitCost,

        sweatshirtQuantity:
          generalCosts.maintenanceAndWarehouseUniform.sweatshirt.quantity,
        sweatshirtUnitCost:
          generalCosts.maintenanceAndWarehouseUniform.sweatshirt.unitCost,
        sweatshirtMonthlyCost:
          this.harvestMonths === 0
            ? 0
            : (generalCosts.maintenanceAndWarehouseUniform.sweatshirt.quantity *
                generalCosts.maintenanceAndWarehouseUniform.sweatshirt
                  .unitCost) /
              this.harvestMonths,
        sweatshirtHarvestCost:
          generalCosts.maintenanceAndWarehouseUniform.sweatshirt.quantity *
          generalCosts.maintenanceAndWarehouseUniform.sweatshirt.unitCost,
      },
    } as GetGeneralCostsModel;

    result.totalMonthlyCostOfAdministrativeCosts =
      result.administrativeCosts.internetProviderMonthlyCost +
      result.administrativeCosts.mobileCommunicationMonthlyCost +
      result.administrativeCosts.officeSupplyMonthlyCost +
      result.administrativeCosts.systemMaintenanceMonthlyCost +
      result.administrativeCosts.telephoneMonthlyCost;
    result.totalHarvestCostOfAdministrativeCosts =
      result.administrativeCosts.internetProviderHarvestCost +
      result.administrativeCosts.mobileCommunicationHarvestCost +
      result.administrativeCosts.officeSupplyHarvestCost +
      result.administrativeCosts.systemMaintenanceHarvestCost +
      result.administrativeCosts.telephoneHarvestCost;

    result.totalMonthlyCostOfAdministrativeUniform =
      result.administrativeUniform.bootsMonthlyCost +
      result.administrativeUniform.glassesMonthlyCost +
      result.administrativeUniform.hearingProtectionMonthlyCost +
      result.administrativeUniform.pantsMonthlyCost +
      result.administrativeUniform.shirtMonthlyCost;
    result.totalHarvestCostOfAdministrativeUniform =
      result.administrativeUniform.bootsHarvestCost +
      result.administrativeUniform.glassesHarvestCost +
      result.administrativeUniform.hearingProtectionHarvestCost +
      result.administrativeUniform.pantsHarvestCost +
      result.administrativeUniform.shirtHarvestCost;

    result.totalMonthlyCostOfMaintenanceAndWarehouseUniform =
      result.maintenanceAndWarehouseUniform.bootsMonthlyCost +
      result.maintenanceAndWarehouseUniform.glassesMonthlyCost +
      result.maintenanceAndWarehouseUniform.glovesMonthlyCost +
      result.maintenanceAndWarehouseUniform.pantsMonthlyCost +
      result.maintenanceAndWarehouseUniform.shirtMonthlyCost +
      result.maintenanceAndWarehouseUniform.sweatshirtMonthlyCost;
    result.totalHarvestCostOfMaintenanceAndWarehouseUniform =
      result.maintenanceAndWarehouseUniform.bootsHarvestCost +
      result.maintenanceAndWarehouseUniform.glassesHarvestCost +
      result.maintenanceAndWarehouseUniform.glovesHarvestCost +
      result.maintenanceAndWarehouseUniform.pantsHarvestCost +
      result.maintenanceAndWarehouseUniform.shirtHarvestCost +
      result.maintenanceAndWarehouseUniform.sweatshirtHarvestCost;

    result.totalMonthlyCostOfOperationalUniform =
      result.operationalUniform.bootsMonthlyCost +
      result.operationalUniform.glassesMonthlyCost +
      result.operationalUniform.glovesMonthlyCost +
      result.operationalUniform.hearingProtectionMonthlyCost +
      result.operationalUniform.helmetMonthlyCost +
      result.operationalUniform.hygieneKitMonthlyCost +
      result.operationalUniform.leggingsMonthlyCost +
      result.operationalUniform.pantsMonthlyCost +
      result.operationalUniform.raincoatMonthlyCost +
      result.operationalUniform.shirtMonthlyCost +
      result.operationalUniform.sweatshirtMonthlyCost;
    result.totalHarvestCostOfOperationalUniform =
      result.operationalUniform.bootsHarvestCost +
      result.operationalUniform.glassesHarvestCost +
      result.operationalUniform.glovesHarvestCost +
      result.operationalUniform.hearingProtectionHarvestCost +
      result.operationalUniform.helmetHarvestCost +
      result.operationalUniform.hygieneKitHarvestCost +
      result.operationalUniform.leggingsHarvestCost +
      result.operationalUniform.pantsHarvestCost +
      result.operationalUniform.raincoatHarvestCost +
      result.operationalUniform.shirtHarvestCost +
      result.operationalUniform.sweatshirtHarvestCost;

    return result;
  }
}
