<div class="container">
  <section>
<header>
  <app-primary-button *ngIf="enableNewDFDV" label="NOVA DFDV" icon="../../../../assets/icons/add-common-100.svg"
    [color]="ButtonColorEnum.primary" [right]="false" [loading]="false" [enabled]="true"
    (click)="openCreateNewDfdvModal()"></app-primary-button>
</header>
    <app-dfdv-filter
      (callbackFunc)="getDfdvFiltered($event)"
      (callbackFilters)="handleDfdvCallBack($event)"
      (userRole)="setUserRole($event)"
      [isLoadingFilter]="isLoadingFilter"
      [loggedUser]="loggedUser"
      >
    </app-dfdv-filter>
    <app-dfdv-list
      [filters]="filters"
      [dfdvsDetail]="dfdvList"
      [userRole]="userRole"
      (redirect)="navigateBack($event)"
      (setPageCallback)="setPage($event)"
      (reloadCallback)="reload()"
      [isLoading]="isLoading"
      [error]="error"
      [page]="page"
      [totalPages]="totalPages"
    ></app-dfdv-list>
  </section>
</div>
<app-dfdv-create-modal
  *ngIf="enableNewDFDV"
  #createModal
  [showModal]="showModal"
  (updateDFDVList)="reload()"
></app-dfdv-create-modal>
