<div
  class="calendar-container"
  [ngClass]="{ normal: (!interval || monthCalendar), interval: interval && !monthCalendar}"
>
  <div class="calendar-container-column">
    <div class="header-container">
      <label *ngIf="interval && !monthCalendar">De:</label>
      <img
        src="../../../assets/icons/arrow-minimal-purple600.svg"
        class="arrow-left arrow"
        (click)="previousMonth_Year(false)"
      />
      <div
        class="month-name"
        (click)="monthHeaderClick(false)"
        [ngClass]="{ 'month-open': monthtable }"
      >
        {{ date.locale("pt-br").format("MMMM") }}
      </div>
      <div
        class="year-header"
        (click)="yearHeaderClick(false)"
        [ngClass]="{ 'year-header-open': yeartable }"
      >
        {{ date.format("Y") }}
      </div>
      <img
        src="../../../assets/icons/arrow-minimal-purple600.svg"
        class="arrow-right arrow"
        (click)="nextMonth_Year(false)"
      />
    </div>
    <div class="week-container" *ngIf="daystable">
      <div class="calendar-week-days">D</div>
      <div class="calendar-week-days">S</div>
      <div class="calendar-week-days">T</div>
      <div class="calendar-week-days">Q</div>
      <div class="calendar-week-days">Q</div>
      <div class="calendar-week-days">S</div>
      <div class="calendar-week-days">S</div>
    </div>
    <div class="days-container" *ngIf="daystable">
      <div *ngFor="let day of daysArr">
        <div
          class="days"
          (click)="setDate(day)"
          [ngClass]="{
            inative: day?.month() !== date.month(),
            today: todayCheck(day),
            'selected-day': isSelected(day.clone())
          }"
        >
          {{ day?.date() }}
        </div>
      </div>
    </div>
    <div class="month-container" [ngClass]="{ hidden: !monthtable }">
      <div *ngFor="let month of monthArr; let i = index">
        <div
          class="month"
          (click)="setMonth(i, false)"
          [ngClass]="{ currentMonth: monthCheck(month) }"
        >
          {{ month }}
        </div>
      </div>
    </div>
    <div class="year-container" [ngClass]="{ hidden: !yeartable }">
      <div *ngFor="let year of yearArr">
        <div class="year" (click)="setYear(year, false)">
          {{ year.year() }}
        </div>
      </div>
    </div>
  </div>
  <!--INTERVAL DATEPICKER-->
  <div class="calendar-container-column calendar-interval" *ngIf="interval && !monthCalendar">
    <div class="header-container">
      <label *ngIf="interval">Até:</label>
      <img
        src="../../../assets/icons/arrow-minimal-purple600.svg"
        class="arrow-left arrow"
        (click)="previousMonth_Year(true)"
      />
      <div
        class="month-name"
        (click)="monthHeaderClick(true)"
        [ngClass]="{ 'month-open': monthtable }"
      >
        {{ dateNext.locale("pt-br").format("MMMM") }}
      </div>
      <div
        class="year-header"
        (click)="yearHeaderClick(true)"
        [ngClass]="{ 'year-header-open': yeartable }"
      >
        {{ dateNext.format("Y") }}
      </div>
      <img
        src="../../../assets/icons/arrow-minimal-purple600.svg"
        class="arrow-right arrow"
        (click)="nextMonth_Year(true)"
      />
    </div>
    <div class="week-container" *ngIf="daystableNext">
      <div class="calendar-week-days">D</div>
      <div class="calendar-week-days">S</div>
      <div class="calendar-week-days">T</div>
      <div class="calendar-week-days">Q</div>
      <div class="calendar-week-days">Q</div>
      <div class="calendar-week-days">S</div>
      <div class="calendar-week-days">S</div>
    </div>
    <div class="days-container" *ngIf="daystableNext">
      <div *ngFor="let day of daysArrNext">
        <div
          class="days"
          (click)="setDate(day)"
          [ngClass]="{
            inative: day?.month() !== dateNext.month(),
            today: todayCheck(day),
            'selected-day': isSelected(day)
          }"
        >
          {{ day?.date() }}
        </div>
      </div>
    </div>
    <div class="month-container" [ngClass]="{ hidden: !monthtableNext }">
      <div *ngFor="let month of monthArr; let i = index">
        <div
          class="month"
          (click)="setMonth(i, true)"
          [ngClass]="{ currentMonth: monthCheck(month) }"
        >
          {{ month }}
        </div>
      </div>
    </div>
    <div class="year-container" [ngClass]="{ hidden: !yeartableNext }">
      <div *ngFor="let year of yearArr">
        <div class="year" (click)="setYear(year, true)">
          {{ year.year() }}
        </div>
      </div>
    </div>
  </div>
</div>
