<div class="support-team-container">
  <section>
    <app-card [title]="title" [icon]="icon" [accordion]="true">
      <section class="table" body>
        <div class="column-header">
          <div class="empty"></div>
          <div class="description-title cell"><label>DESCRIÇÃO</label></div>
          <div *ngFor="let item of description">
            <div class="description-title cell">
              <label>{{ item }}</label>
            </div>
          </div>
          <div class="footer-title cell"><label>TOTAL</label></div>
        </div>
        <div class="body">
          <section class="header">
            <div class="quantity cell"></div>
            <div class="salary cell"><label>ORDENADOS</label></div>
            <div class="salary-and-charges cell">
              <label>ORDENADOS + ENCARGOS</label>
            </div>
            <div class="benefits cell"><label>BENEFÍCIOS</label></div>
            <div class="uniform cell"><label>UNIFORMES</label></div>
            <div class="ligth-fleet cell">
              <label>FROTA LEVE</label>
              <div class="select-transport-type">
                <label>Tipo de Transporte</label>

                <div class="skeleton" body *ngIf="showSkeleton">
                  <app-skeleton-box [box]="1"></app-skeleton-box>
                </div>
                <app-select
                  [errorMessage]="errorMessage"
                  [singleControl]="getFormControl(form, 'transportertypeName')"
                  [selected]="selectedFleetTypes"
                  [options]="optionFleetTypes"
                  placeholder="Tipo de Transporte"
                  [disabled]="shouldDisableFields"
                  [multi]="false"
                  *ngIf="!showSkeleton"
                  (statusChanged)="UpdateStore()"
                ></app-select>
              </div>
            </div>
          </section>
          <section class="description">
            <div class="quantity cell"><label>QUANTIDADE</label></div>
            <div class="salary cell">
              <label>SALÁRIO</label>
              <label>DESPESA</label>
            </div>
            <div class="salary-and-charges cell">
              <label>SALÁRIO</label>
              <label>DESPESA</label>
            </div>
            <div class="benefits cell">
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
            <div class="uniform cell">
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
            <div class="ligth-fleet cell">
              <label>QUANTIDADE</label>
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
          </section>
          <section class="description" *ngFor="let item of model">
            <div class="skeleton" body *ngIf="showSkeleton">
              <app-skeleton-line [line]="1"></app-skeleton-line>
            </div>
            <div class="quantity cell" *ngIf="!showSkeleton">
              <app-input
                placeholder="0,00"
                [control]="getFormControl(form, item[0] + 'SalaryQuantity')"
                [mask]="eMaskType.money"
                [showSideIcon]="true"
                [sideIconLabel]="',00'"
                [sideIconType]="eSideIconType.text"
                [disabled]="shouldDisableFields"
                (updateValue)="UpdateStore()"
              ></app-input>
            </div>
            <div class="salary cell" *ngIf="!showSkeleton">
              <app-input
                placeholder="0,00"
                [errorMessage]="errorMessage"
                [control]="getFormControl(form, item[0] + 'SalaryValue')"
                [mask]="eMaskType.money"
                [showSideIcon]="true"
                [sideIconLabel]="'R$'"
                [max]="100000"
                [min]="0"
                [disabled]="shouldDisableFields"
                (updateValue)="UpdateStore()"
              ></app-input>
              <label>{{ item[1].salaryExpense | currency : "BRL" }}</label>
            </div>
            <div class="salary-and-charges cell" *ngIf="!showSkeleton">
              <label>{{
                item[1].salaryAndChargeValue | currency : "BRL"
              }}</label>
              <label>{{
                item[1].salaryAndChargeExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="benefits cell" *ngIf="!showSkeleton">
              <label>{{ item[1].benefitValue | currency : "BRL" }}</label>
              <label>{{ item[1].benefitExpense | currency : "BRL" }}</label>
            </div>
            <div
              class="uniform cell"
              *ngIf="
                supportTeamType !== 'management' &&
                item[0] !== 'generalBuyer' &&
                !showSkeleton
              "
            >
              <label>{{
                (form.get(item[0] + "SalaryQuantity")?.value === 0
                  ? 0
                  : item[1].uniformValue
                ) | currency : "BRL"
              }}</label>
              <label>{{ item[1].uniformExpense | currency : "BRL" }}</label>
            </div>
            <div
              class="uniform cell"
              *ngIf="
                (supportTeamType === 'management' ||
                  item[0] === 'generalBuyer') &&
                !showSkeleton
              "
            >
              <label>-</label>
              <label>-</label>
            </div>
            <div class="ligth-fleet cell" *ngIf="!showSkeleton">
              <app-input
                placeholder="0"
                [errorMessage]="errorMessage"
                [control]="getFormControl(form, item[0] + 'LightFleetQuantity')"
                [mask]="eMaskType.number"
                [max]="100000"
                [min]="0"
                [disabled]="shouldDisableFields"
                (updateValue)="UpdateStore()"
              ></app-input>
              <label>{{ item[1].lightFleetValue | currency : "BRL" }}</label>
              <label>{{ item[1].lightFleetExpense | currency : "BRL" }}</label>
            </div>
          </section>
          <section class="footer">
            <div class="quantity cell">
              <label>{{
                utils.numberFormatter(totalizer.totalSalaryQuantity, 2, 2)
              }}</label>
            </div>
            <div class="salary cell">
              <label>{{ totalizer.totalSalaryValue | currency : "BRL" }}</label>
              <label>{{
                totalizer.totalSalaryExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="salary-and-charges cell">
              <label>
                {{ totalizer.totalSalaryAndChargeValue | currency : "BRL" }}
              </label>
              <label>
                {{ totalizer.totalSalaryAndChargeExpense | currency : "BRL" }}
              </label>
            </div>
            <div class="benefits cell">
              <label>{{
                totalizer.totalBenefitValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalBenefitExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="uniform cell">
              <label>{{
                totalizer.totalUniformValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalUniformExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="ligth-fleet cell">
              <label>{{ totalizer.totalLightFleetQuantity }}</label>
              <label>{{
                totalizer.totalLightFleetValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalLightFleetExpense | currency : "BRL"
              }}</label>
            </div>
          </section>
        </div>
      </section>
    </app-card>
  </section>
</div>
