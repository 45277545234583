import { QuantitiesCostAmountValues } from '../quantitiesCostAmountValues.model';

export class OfficeExpensesModel {
  [key: string]: QuantitiesCostAmountValues;

  mobileCommunication: QuantitiesCostAmountValues;
  officeSupply: QuantitiesCostAmountValues;
  telephone: QuantitiesCostAmountValues;
  systemMaintenance: QuantitiesCostAmountValues;
  internetProvider: QuantitiesCostAmountValues;

  constructor() {
    this.mobileCommunication = new QuantitiesCostAmountValues();
    this.officeSupply = new QuantitiesCostAmountValues();
    this.telephone = new QuantitiesCostAmountValues();
    this.systemMaintenance = new QuantitiesCostAmountValues();
    this.internetProvider = new QuantitiesCostAmountValues();
  }
}
