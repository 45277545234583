import { OperatorFixedCostsModel } from './operatorFixedCosts.model';
import { SolicitationPendingFixedCostsModel } from './solicitationPendingFixedCosts.model';
import { SolicitationSentFixedCostsModel } from './solicitationSentFixedCosts.model';

export class UnitFixedCostsModel {
  instanceId!: string;
  unitPimsName!: string;
  fixedCostStatusName!: string;
  totalValue!: number;
  currentValue!: number;
  valueSent!: number;
  valueToBePaid!: number;
  totalSolicitationsSent!: number;
  totalSolicitationsNotSent!: number;
  totalSolicitationsPending!: number;
  operators!: OperatorFixedCostsModel[];
  fixedCostSolicitationPendings!: SolicitationPendingFixedCostsModel[];
  fixedCostSolicitationsSents!: SolicitationSentFixedCostsModel[];
}
