
export class GetOfficeExpensesModel {
  [key: string]: number;

  mobileCommunicationQuantity: number = 0;
  mobileCommunicationUnitCost: number = 0;
  mobileCommunicationMonthlyCost: number = 0;
  mobileCommunicationHarvestCost: number = 0;

  officeSupplyQuantity: number = 0;
  officeSupplyUnitCost: number = 0;
  officeSupplyMonthlyCost: number = 0;
  officeSupplyHarvestCost: number = 0;

  telephoneQuantity: number = 0;
  telephoneUnitCost: number = 0;
  telephoneMonthlyCost: number = 0;
  telephoneHarvestCost: number = 0;

  systemMaintenanceQuantity: number = 0;
  systemMaintenanceUnitCost: number = 0;
  systemMaintenanceMonthlyCost: number = 0;
  systemMaintenanceHarvestCost: number = 0;

  internetProviderQuantity: number = 0;
  internetProviderUnitCost: number = 0;
  internetProviderMonthlyCost: number = 0;
  internetProviderHarvestCost: number = 0;
}
