<div class="parameters-total-container">
  <section>
    <app-card
      title="Totalizadores"
      icon="../../../../../../assets/icons/graphic-common.svg"
      [accordion]="true"
    >
      <section class="table" body>
        <div class="column-header">
          <div class="empty"></div>
          <div class="description-title cell"><label>DESCRIÇÃO</label></div>
          <div class="footer-title cell"><label>TOTAL</label></div>
        </div>
        <div class="body">
          <section class="header">
            <div class="quantity cell"></div>
            <div class="salary cell"><label>ORDENADOS</label></div>
            <div class="salary-and-charges cell">
              <label>ORDENADOS + ENCARGOS</label>
            </div>
            <div class="benefits cell"><label>BENEFÍCIOS</label></div>
            <div class="uniform cell"><label>UNIFORMES</label></div>
            <div class="ligth-fleet cell"><label>FROTA LEVE</label></div>
          </section>
          <section class="description">
            <div class="quantity cell"><label>QUANTIDADE</label></div>
            <div class="salary cell">
              <label>SALÁRIO</label>
              <label>DESPESA</label>
            </div>
            <div class="salary-and-charges cell">
              <label>SALÁRIO</label>
              <label>DESPESA</label>
            </div>
            <div class="benefits cell">
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
            <div class="uniform cell">
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
            <div class="ligth-fleet cell">
              <label>QUANTIDADE</label>
              <label>VALOR</label>
              <label>DESPESA</label>
            </div>
          </section>
          <section class="footer">
            <div class="quantity cell skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="1"></app-skeleton-box>
            </div>
            <div class="quantity cell" *ngIf="!showSkeleton">
              <label>{{
                utils.numberFormatter(totalizer.totalSalaryQuantity, 2, 2)
              }}</label>
            </div>
            <div class="salary cell skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="2"></app-skeleton-box>
            </div>
            <div class="salary cell" *ngIf="!showSkeleton">
              <label>{{ totalizer.totalSalaryValue | currency : "BRL" }}</label>
              <label>{{
                totalizer.totalSalaryExpense | currency : "BRL"
              }}</label>
            </div>
            <div
              class="salary-and-charges cell skeleton"
              body
              *ngIf="showSkeleton"
            >
              <app-skeleton-box [box]="2"></app-skeleton-box>
            </div>
            <div class="salary-and-charges cell" *ngIf="!showSkeleton">
              <label>
                {{ totalizer.totalSalaryAndChargeValue | currency : "BRL" }}
              </label>
              <label>
                {{ totalizer.totalSalaryAndChargeExpense | currency : "BRL" }}
              </label>
            </div>
            <div class="benefits cell skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="2"></app-skeleton-box>
            </div>
            <div class="benefits cell" *ngIf="!showSkeleton">
              <label>{{
                totalizer.totalBenefitValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalBenefitExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="uniform cell skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="2"></app-skeleton-box>
            </div>
            <div class="uniform cell" *ngIf="!showSkeleton">
              <label>{{
                totalizer.totalUniformValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalUniformExpense | currency : "BRL"
              }}</label>
            </div>
            <div class="ligth-fleet cell skeleton" body *ngIf="showSkeleton">
              <app-skeleton-box [box]="3"></app-skeleton-box>
            </div>
            <div class="ligth-fleet cell" *ngIf="!showSkeleton">
              <label>{{ totalizer.totalLightFleetQuantity }}</label>
              <label>{{
                totalizer.totalLightFleetValue | currency : "BRL"
              }}</label>
              <label>{{
                totalizer.totalLightFleetExpense | currency : "BRL"
              }}</label>
            </div>
          </section>
        </div>
      </section>
    </app-card>
  </section>
</div>
