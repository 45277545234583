import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { GetOperationPimsModel } from 'src/app/services/operation/models/get-operation-pims.model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-operation-card',
  templateUrl: './operation-card.component.html',
  styleUrls: ['./operation-card.component.scss'],
})
export class OperationCardComponent {
  @Input() operations: GetOperationPimsModel[] = [];

  public StatusColorEnum = StatusColorEnum;

  constructor(public util: Utils, public router: Router) { }

  navigate(operationId:number) {
    this.router.navigate([`admin/operation/${operationId}`]);
  }
}
