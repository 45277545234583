import {
  AfterViewInit, Component, ContentChildren, QueryList,
} from '@angular/core';
import { HistoryItemComponent } from './history-item/history-item.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements AfterViewInit {
  @ContentChildren(HistoryItemComponent) items!: QueryList<HistoryItemComponent>;
  public historyItems: HistoryItemComponent[] = [];

  ngAfterViewInit() {
    if (!this.items || this.items.length === 0) return;

    this.items.first.first = true;
    this.items.last.last = true;

    if (this.historyItems.length === 0) {
      setTimeout(() => {
        this.historyItems = this.items.toArray();
      }, 0);
    }
  }
}
