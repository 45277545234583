<div class="card-container">
  <div
    *ngFor="let item of companiesPims.operatorPims"
    class="card-content"
    (click)="navigate(companiesPims.operatorId, item.transporterId)"
  >
    <div class="id">
      <label>{{ util.addLeadingZeros(item.transporterId, 5) }}</label>
    </div>
    <div class="items">
      <label class="cnpj">{{ item.transporterCnpj | cnpj }}</label>
      <label class="name">{{ item.transporterName.toUpperCase() }}</label>
      <div id="status">
        <app-status
          [color]="
            item.active ? StatusColorEnum.success : StatusColorEnum.warning
          "
          [image]="
            item.active
              ? '../../../../../../assets/icons/active.svg'
              : '../../../../../../assets/icons/Exclude.svg'
          "
          [status]="item.active ? 'Ativo' : 'Inativo'"
        >
        </app-status>
      </div>
    </div>
  </div>
</div>
