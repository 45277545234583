import {
  Component, Input, OnInit, Output, EventEmitter,
} from '@angular/core';
import { ButtonColorEnum } from '../enums/button-color.enum';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() label: string = '';
  @Input() progressValue: string = '';
  @Input() showProgressValue: boolean = false;
  @Input() icon: string = '';
  @Input() right: boolean = false;
  @Input() progress: boolean = false;
  @Input() loading: boolean = false;
  @Input() enabled: boolean = false;
  @Input() animation!: boolean;
  @Input() color: ButtonColorEnum = ButtonColorEnum.primary;

  @Output() clickButton: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  handleClick(event: MouseEvent) {
    // if (this.animation) {
    // this.loading = !this.loading;
    // }
    if (!this.loading && this.enabled) {
      this.clickButton.emit(event);
    }
  }
}
