/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, map, takeUntil } from 'rxjs';
import {
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { Utils } from 'src/app/common/utils/utils';
import { OperatorFixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/operatorFixedCosts.model';
import { SolicitationPendingFixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/solicitationPendingFixedCosts.model';
import { SolicitationSentFixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/solicitationSentFixedCosts.model';
import { UpdateSolicitationsFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-solictiationsFixedCost.model';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';
import * as FixcedCostActions from '../../../../common/store/fixedCost/fixedCost.actions';

@Component({
  selector: 'app-fixed-costs-releases',
  templateUrl: './fixed-costs-releases.component.html',
  styleUrls: ['./fixed-costs-releases.component.scss'],
})
export class FixedCostsReleasesComponent implements OnInit {
  @Input()
    operators: OperatorFixedCostsModel[] = [];
  @Input()
    fixedCostSolicitationPendings: SolicitationPendingFixedCostsModel[] = [];
  @Input()
    fixedCostSolicitationsSents: SolicitationSentFixedCostsModel[] = [];
  @Input() isConcluded: boolean = false;

  public showSkeleton = true;
  public solicitations: UpdateSolicitationsFixedCostModel[] = [];
  public fixedCost: FixedCostsModel = new FixedCostsModel();
  private destroy$ = new Subject<void>();
  public checkPendings: boolean = true;
  public checkSents: boolean = true;

  constructor(
    public util: Utils,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
    }>,
  ) {}

  solicitationStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.updateSolicitation));

  fixedCostStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost));

  ngOnInit(): void {
    this.fixedCostStore$.subscribe((fixedCost) => {
      this.fixedCost = fixedCost;
    });
    this.solicitationStore$.pipe(takeUntil(this.destroy$)).subscribe((sols) => {
      if (sols) {
        this.solicitations = [...sols.solicitations];
        this.showSkeleton = false;

        this.checkPendings = true;
        this.checkSents = true;
        this.fixedCostSolicitationPendings.forEach((sol) => {
          this.checkPendings = this.checkPendings
            && (this.solicitations.find(
              (solis) => sol.solicitationId == solis.solicitationId,
            )?.approved
              ?? true);
        });
        this.fixedCostSolicitationsSents.forEach((sol) => {
          this.checkSents = this.checkSents
            && (this.solicitations.find(
              (solis) => sol.solicitationId == solis.solicitationId,
            )?.approved
              ?? true);
        });
      }
    });
  }

  getValue(id: number) {
    const solicitation = this.solicitations.find(
      (sol) => sol.solicitationId === id,
    );
    if (solicitation) return solicitation.approved;
    return true;
  }

  checkAllPending() {
    this.fixedCostSolicitationPendings.forEach((sol) => {
      const solicitation = this.solicitations.find(
        (soli) => soli.solicitationId === sol.solicitationId,
      );
      if (solicitation !== undefined) {
        this.updateSolicitation(solicitation.solicitationId, true);
      }
    });
  }
  removeAllChecksPending() {
    this.fixedCostSolicitationPendings.forEach((sol) => {
      const solicitation = this.solicitations.find(
        (soli) => soli.solicitationId === sol.solicitationId,
      );
      if (solicitation !== undefined) {
        this.updateSolicitation(solicitation.solicitationId, false);
      }
    });
  }
  checkAllSent() {
    this.fixedCostSolicitationsSents.forEach((sol) => {
      const solicitation = this.solicitations.find(
        (soli) => soli.solicitationId === sol.solicitationId,
      );
      if (solicitation !== undefined) {
        this.updateSolicitation(solicitation.solicitationId, true);
      }
    });
  }
  removeAllChecksSent() {
    this.fixedCostSolicitationsSents.forEach((sol) => {
      const solicitation = this.solicitations.find(
        (soli) => soli.solicitationId === sol.solicitationId,
      );
      if (solicitation !== undefined) {
        this.updateSolicitation(solicitation.solicitationId, false);
      }
    });
  }

  onCheck(id: number) {
    const solicitation = this.solicitations.find(
      (soli) => soli.solicitationId === id,
    );
    if (solicitation !== undefined) {
      this.updateSolicitation(solicitation.solicitationId, true);
    }
  }

  onRemoveCheck(id: number) {
    const solicitation = this.solicitations.find(
      (soli) => soli.solicitationId === id,
    );
    if (solicitation !== undefined) {
      this.updateSolicitation(solicitation.solicitationId, false);
    }
  }
  getColorClass(solicitationTypeName: string) {
    switch (solicitationTypeName) {
      case 'Desconto':
        return 'value negative';
      case 'Outros Custos':
        return 'value variable';
      default:
        return 'value';
    }
  }
  updateSolicitation(solicitationId: number, approved: boolean) {
    this.store.dispatch(
      FixcedCostActions.updateSolicitation({
        data: this.fixedCost,
        solicitationId,
        approved,
      }),
    );
  }

  getCurrentValueTransporter(participation: number): number {
    let currentValue = 0;
    currentValue += (this.getCurrentValue() * participation) / 100;
    return currentValue;
  }

  getCurrentValue() {
    let currentValue = 0;
    this.solicitations.forEach((sol) => {
      currentValue
        += this.fixedCostSolicitationPendings.find(
          (item) => item.solicitationId === sol.solicitationId && sol.approved,
        )?.negotiatedValue ?? 0;
      currentValue
        += this.fixedCostSolicitationsSents.find(
          (item) => item.solicitationId === sol.solicitationId && sol.approved,
        )?.negotiatedValue ?? 0;
    });
    return currentValue;
  }
}
