/* eslint-disable max-len */
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import {
  IFixedCostState,
  ILoadingState,
} from 'src/app/common/store/fixedCost/fixedCost.state';
import { Utils } from 'src/app/common/utils/utils';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import { CategoryFixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/CategoryFixedCosts.model';
import { UpdateSolicitationsFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-solictiationsFixedCost.model';
import * as FixcedCostActions from '../../../common/store/fixedCost/fixedCost.actions';

@Component({
  selector: 'app-fixed-costs-content',
  templateUrl: './fixed-costs-content.component.html',
  styleUrls: ['./fixed-costs-content.component.scss'],
})
export class FixedCostsContentComponent implements OnInit, OnDestroy {
  public statusColorEnum = StatusColorEnum;
  public showSkeleton = false;
  public showNotFound = false;
  public ebuttonColor = ButtonColorEnum;
  public fixedCosts: FixedCostsModel = new FixedCostsModel();
  public categories: CategoryFixedCostsModel[] = [];
  private destroy$ = new Subject<void>();
  public fixedCostLoading: boolean = false;
  public solicitations: UpdateSolicitationsFixedCostModel[] = [];
  public unSubscribe!: Subscription;
  constructor(
    public util: Utils,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{
      fixedCost: IFixedCostState;
      loadingFixedCost: ILoadingState;
    }>
  ) {}

  categoriesStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost.categories));
  fixedCostStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.fixedCost));
  solicitationStore$ = this.store
    .select('fixedCost')
    .pipe(map((x) => x.updateSolicitation));
  loadingStore$ = this.store.select('loadingFixedCost').pipe((x) => x);

  ngOnInit(): void {
    this.showSkeleton = true;
    this.unSubscribe = this.categoriesStore$.subscribe((fixedcost) => {
      if (!fixedcost) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');
          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.fixedCostLoading = isLoading.loading;
            });
          if (!this.fixedCostLoading) {
            this.fixedCostStore$
              .pipe(takeUntil(this.destroy$))
              .subscribe((x) => {
                if (
                  (!x.fixedCostId || x.fixedCostId !== +(id ?? 0)) &&
                  id !== null
                ) {
                  this.store.dispatch(
                    FixcedCostActions.loadFixedCostsModel({
                      id: parseInt(id ?? '0'),
                    })
                  );
                }
              });
          }
        });
      } else {
        this.categories = fixedcost;
        this.solicitationStore$.subscribe((solicitations) => {
          if (solicitations) {
            this.solicitations = solicitations.solicitations;
          }
        });
        this.showSkeleton = false;
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendBack() {
    this.router.navigate(['/fixed-costs']);
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'Concluido':
        return this.statusColorEnum.success;
      default:
        return this.statusColorEnum.attention;
    }
  }

  getStatusIcon(status: string) {
    switch (status) {
      case 'Concluido':
        return '../../../../assets/icons/Success-green.svg';
      default:
        return '../../../../assets/icons/Timer Yellow Outline.svg';
    }
  }

  viewFixedCostCategory(categoryId: number) {
    this.router.navigate([`${this.router.url}/category/${categoryId}`]);
  }

  getCrurrentValue(category: CategoryFixedCostsModel) {
    let currentValue = 0;
    category.units.forEach((unit) => {
      this.solicitations.forEach((sol) => {
        currentValue +=
          unit.fixedCostSolicitationPendings.find(
            (item) => item.solicitationId === sol.solicitationId && sol.approved
          )?.negotiatedValue ?? 0;
        currentValue +=
          unit.fixedCostSolicitationsSents.find(
            (item) => item.solicitationId === sol.solicitationId && sol.approved
          )?.negotiatedValue ?? 0;
      });
    });
    return currentValue;
  }
}
