<app-modal-generic
  [title]="'Nova DFDV'"
  [imagePath]="'../../../assets/icons/calc-prinary-600.svg'"
  [showModal]="this.showModal"
>
  <div class="container">
    <div class="skeleton" *ngIf="isLoading">
      <app-skeleton-input [input]="3"></app-skeleton-input>
    </div>
    <div class="forms" *ngIf="!isLoading">
      <app-select
        field="Unidade"
        property="nome"
        id="Unidade"
        [options]="units"
        [selected]="selectedUnits"
        [singleControl]="unitForm"
      ></app-select>
      <app-select
        field="Categoria"
        property="nome"
        id="Categoria"
        [options]="categories"
        [selected]="selectedCategory"
        [singleControl]="categoryForm"
      ></app-select>
      <app-select
        field="Empresa"
        description="(opcional)"
        property="nome"
        id="OPL"
        [multi]="true"
        [options]="operators"
        [selected]="selectedOperators"
        [multiControl]="operatorForm"
      ></app-select>
      <app-input
        label="ID da DFDV a ser replicada"
        placeholder="12345"
        description="(opcional)"
        [type]="eInputType.text"
        [control]="dfdvIDForm"
      ></app-input>
    </div>
    <div class="buttons">
      <app-secondary-button
        [label]="'CANCELAR'"
        [enabled]="true"
        (clickButton)="cancelBtn()"
      ></app-secondary-button>
      <app-primary-button
        [label]="'CRIAR'"
        icon="../../../../assets/icons/add-common-100.svg"
        [enabled]="!isLoading"
        [loading]="btnLoading"
        (clickButton)="addBtn()"
      ></app-primary-button>
    </div>
  </div>
</app-modal-generic>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
