/* eslint-disable max-len */
import { SupportTeamFleetModel } from 'src/app/services/dfdv/models/support-team/support-team-fleet.model';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { DriversFactorModel } from 'src/app/services/dfdv/driversFactor/models/driversFactor.model';
import { ScopeDfdvModel } from 'src/app/services/dfdv/scope/model/scope-dfdv.model';

@Component({
  selector: 'app-support-fleet',
  templateUrl: './support-fleet.component.html',
  styleUrls: ['./support-fleet.component.scss'],
})
export class SupportFleetComponent implements OnInit, OnChanges {
  @Input() shouldDisableFields: boolean = false;
  @Input() modelAux!: [ScopeDfdvModel, DriversFactorModel];
  @Input() supportTeamFleetModel = new SupportTeamFleetModel();
  @Input() showSkeleton: boolean = false;

  @Output() UpdateTotal: EventEmitter<SupportTeamFleetModel> = new EventEmitter();
  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  public selectedFleetTypes: string[] = [];
  public optionFleetTypes: string[] = ['Frota Leve', 'Transporte Coletivo'];

  // Model
  public scopeModel: ScopeDfdvModel = new ScopeDfdvModel();
  public driversModel: DriversFactorModel = new DriversFactorModel();

  @Output() UpdateStoreSupportTeam: EventEmitter<null> = new EventEmitter();

  // Form
  public form = this.formBuilder.group({
    fleetType: [''],
    shiftChangeQuantity: [''],
    workshopTruckQuantity: [''],
  });
  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supportTeamFleetModel']) {
      this.form.controls.shiftChangeQuantity.patchValue(this.supportTeamFleetModel.shiftChange.lightFleetQuantity.toString());
      this.form.controls.workshopTruckQuantity.patchValue(this.supportTeamFleetModel.workshopTruck.lightFleetQuantity.toString());
    }
  }

  ngOnInit(): void {
    if (this.supportTeamFleetModel.transportTypeName && this.supportTeamFleetModel.transportTypeName !== null && this.supportTeamFleetModel.transportTypeName !== '') {
      this.selectedFleetTypes = [this.supportTeamFleetModel.transportTypeName];
    }
    this.form.controls.fleetType.valueChanges.subscribe(() => {
      this.CalcFleetTypeUpdate();
    });
    this.form.controls.shiftChangeQuantity.valueChanges.subscribe(() => {
      this.CalcShiftUpdate();
    });
    this.form.controls.workshopTruckQuantity.valueChanges.subscribe(() => {
      this.CalcWorkshopTruckUpdate();
    });
  }

  CalcWorkshopTruckUpdate() {
    const prop = this.supportTeamFleetModel;
    const scope = this.modelAux[0];
    const driver = this.modelAux[1];
    prop.workshopTruck.lightFleetQuantity = +(
      this.form.controls.workshopTruckQuantity.value ?? 0
    );
    if (
      prop.workshopTruck.lightFleetQuantity !== 0
      && scope.consumption != null
      && scope.consumption.lightFleetConsumption !== 0
    ) {
      prop.workshopTruck.lightFleetValue = scope.rental.workshopTruckRental
        + ((scope.equipment.operationDayDrivenKm
          * driver.workingDays)
          / scope.consumption.lightFleetConsumption)
          * scope.consumption.lightFleetFuelCost;
    } else prop.workshopTruck.lightFleetValue = 0;

    prop.workshopTruck.lightFleetExpense = prop.workshopTruck.lightFleetQuantity
      * prop.workshopTruck.lightFleetValue;
    this.UpdateTotalizer();
  }
  CalcShiftUpdate() {
    const scope = this.modelAux[0];
    const driver = this.modelAux[1];
    const prop = this.supportTeamFleetModel;
    prop.shiftChange.lightFleetQuantity = +(
      this.form.controls.shiftChangeQuantity.value ?? 0
    );

    if (prop.transportTypeName === '' || prop.transportTypeName === null) {
      prop.shiftChange.lightFleetValue = 0;
      prop.shiftChange.lightFleetExpense = 0;
      this.UpdateTotalizer();
      return;
    }

    const consumption = prop.transportTypeName === 'Frota Leve'
      ? scope.consumption.lightFleetConsumption
      : scope.consumption.collectiveTransportConsumption;
    const cost = prop.transportTypeName === 'Frota Leve'
      ? scope.consumption.lightFleetFuelCost
      : scope.consumption.collectiveTransportFuelCost;

    const rental = prop.transportTypeName === 'Frota Leve'
      ? scope.rental.lightFleetRental
      : scope.rental.collectiveTransportRental;

    if (
      prop.shiftChange.lightFleetQuantity !== 0
      && consumption !== 0
      && rental !== undefined
      && scope.equipment !== undefined
      && driver !== undefined
    ) {
      prop.shiftChange.lightFleetValue = rental
        + ((scope.equipment.operationDayDrivenKm
          * driver.workingDays)
          / consumption)
          * cost;
    } else {
      prop.shiftChange.lightFleetValue = 0;
    }

    prop.shiftChange.lightFleetExpense = prop.shiftChange.lightFleetQuantity * prop.shiftChange.lightFleetValue;
    this.UpdateTotalizer();
  }

  CalcFleetTypeUpdate() {
    const prop = this.supportTeamFleetModel;
    const driver = this.modelAux[1];
    const scope = this.modelAux[0];
    prop.transportTypeName = this.form.controls.fleetType.value;

    if (prop.transportTypeName === '' || prop.transportTypeName === null) {
      prop.shiftChange.lightFleetValue = 0;
      prop.shiftChange.lightFleetExpense = 0;
      this.UpdateTotalizer();
      return;
    }

    const consumption = prop.transportTypeName === 'Frota Leve'
      ? scope.consumption.lightFleetConsumption
      : scope.consumption.collectiveTransportConsumption;
    const cost = prop.transportTypeName === 'Frota Leve'
      ? scope.consumption.lightFleetFuelCost
      : scope.consumption.collectiveTransportFuelCost;

    const rental = prop.transportTypeName === 'Frota Leve'
      ? scope.rental.lightFleetRental
      : scope.rental.collectiveTransportRental;

    if (
      prop.shiftChange.lightFleetQuantity !== 0
      && consumption !== 0
      && rental !== undefined
      && scope.equipment !== undefined
      && driver !== undefined
    ) {
      prop.shiftChange.lightFleetValue = rental
        + ((scope.equipment.operationDayDrivenKm
          * driver.workingDays)
          / consumption)
          * cost;
    } else {
      prop.shiftChange.lightFleetValue = 0;
    }
    prop.shiftChange.lightFleetExpense = prop.shiftChange.lightFleetQuantity * prop.shiftChange.lightFleetValue;
    this.CalcWorkshopTruckUpdate();
    this.UpdateTotalizer();
  }

  UpdateTotalizer() {
    const totalizer = this.supportTeamFleetModel;
    totalizer.totalLightFleetExpense = totalizer.fleetOperationalTeam.lightFleetExpense
      + totalizer.shiftChange.lightFleetExpense
      + totalizer.workshopTruck.lightFleetExpense;
    totalizer.totalLightFleetQuantity = totalizer.fleetOperationalTeam.lightFleetQuantity
      + totalizer.shiftChange.lightFleetQuantity
      + totalizer.workshopTruck.lightFleetQuantity;
    totalizer.totalLightFleetValue = totalizer.totalLightFleetQuantity === 0 ? 0 : totalizer.totalLightFleetExpense / totalizer.totalLightFleetQuantity;

    this.UpdateTotal.emit(totalizer);
  }

  UpdateStore() {
    this.UpdateStoreSupportTeam.emit();
  }
}
