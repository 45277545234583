import { Injectable } from '@angular/core';
import {
  Actions, createEffect, ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map, mergeMap, catchError, tap,
} from 'rxjs/operators';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as DfdvActions from './dfdv.actions';
import { IDfdvState } from './dfdv.state';

@Injectable()
export class DfdvEffects {
  loadGetDfdvModel$ = createEffect(() => this.actions$.pipe(
    ofType(DfdvActions.loadGetDfdvModel),
    tap(() => this.store.dispatch(DfdvActions.setLoading({ isLoading: true }))),
    mergeMap((action: ReturnType<typeof DfdvActions.loadGetDfdvModel>) => this.service.getDfdvById(action.id).pipe(
      map((data) => {
        this.store.dispatch(DfdvActions.setLoading({ isLoading: false }));
        return DfdvActions.loadGetDfdvModelSuccess({ data });
      }),
      catchError(() => {
        this.store.dispatch(DfdvActions.setLoading({ isLoading: false }));
        this.route.navigate(['/access-denied']);
        return of({ type: '[DfdvStateHandler] Load Dfdv Failed' });
      }),
    )),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<{ dfdv: IDfdvState }>,
    private service: DfdvService,
    private route: Router,
  ) {}
}
