<div *ngIf="!isMobile" class="container">
  <header>
    <label class="title-3-bold" id="hoperatorId">CNPJ</label>
    <label class="title-3-bold" id="hfantasyName">NOME FANTASIA</label>
    <label class="title-3-bold" id="hemail">EMAIL</label>
    <label class="title-3-bold" id="hphone">CONTATO</label>
    <label class="title-3-bold" id="hstatus">STATUS</label>
  </header>
</div>
<app-operator-list-item
  body
  [operatorList]="operatorList"
></app-operator-list-item>
