export class GetAdministrativeTeamUniformModel {
  [key: string]: number;

  pantsQuantity: number = 0;
  pantsUnitCost: number = 0;
  pantsMonthlyCost: number = 0;
  pantsHarvestCost: number = 0;

  shirtQuantity: number = 0;
  shirtUnitCost: number = 0;
  shirtMonthlyCost: number = 0;
  shirtHarvestCost: number = 0;

  bootsQuantity: number = 0;
  bootsUnitCost: number = 0;
  bootsMonthlyCost: number = 0;
  bootsHarvestCost: number = 0;

  glassesQuantity: number = 0;
  glassesUnitCost: number = 0;
  glassesMonthlyCost: number = 0;
  glassesHarvestCost: number = 0;

  hearingProtectionQuantity: number = 0;
  hearingProtectionUnitCost: number = 0;
  hearingProtectionMonthlyCost: number = 0;
  hearingProtectionHarvestCost: number = 0;
}
