<div class="container">
  <div class="preregister-operator-list-container">
    <div
      *ngFor="let operator of preRegisterList; let i = index"
      [class]="loadingReject[i] || loadingAccept[i] ? 'grid disable' : 'grid'"
      (click)="
        loadingReject[i] || loadingAccept[i]
          ? $event.stopPropagation()
          : handleDataClick(operator.operatorId)
      "
    >
      <div class="operatorId">
        {{ operator.operatorId | cnpj }}
      </div>
      <div class="info-operator">
        <div class="fantasyName">
          <span>{{ operator.fantasyName }}</span>
        </div>
        <div class="email-phone">
          <div class="email">
            <img src="../../../../../../assets/icons/arroba.svg" alt="email" />
            <span>{{ operator.email }}</span>
          </div>
          <div class="phone">
            <img src="../../../../../../assets/icons/phone.svg" alt="phone" />
            <span>{{ operator.phone | phone }}</span>
          </div>
        </div>
      </div>
      <div class="status">
        <div
          [class]="!loadingReject[i] ? 'approve' : 'approve disable'"
          (click)="
            !loadingReject[i] ? approve($event, i, operator.operatorId) : null
          "
          [id]=""
        >
          <img
            [class]="loadingAccept[i] ? 'loading' : 'null'"
            [src]="
              !loadingAccept[i]
                ? '../../../../../../assets/icons/check-accept-purple-600.svg'
                : '../../../../../../assets/icons/loading-accept.svg'
            "
            alt="approve signal"
            [id]="operator.operatorId"
            *ngIf="!loadingReject[i]"
          />
          <img
            src="../../../../../../assets/icons/check-accept-purple-400.svg"
            alt="approve signal"
            [id]="operator.operatorId"
            *ngIf="loadingReject[i]"
          />
          <label *ngIf="!loadingAccept[i]">Aceitar</label>
        </div>
        <div
          [class]="!loadingAccept[i] ? 'reject' : 'reject disable'"
          (click)="
            !loadingAccept[i]
              ? disapprove($event, i, operator.operatorId)
              : null
          "
        >
          <img
            [class]="loadingReject[i] ? 'loading' : 'null'"
            [src]="
              !loadingReject[i]
                ? '../../../../../../assets/icons/reject-red-600.svg'
                : '../../../../../../assets/icons/loading-reject.svg'
            "
            alt="reject signal"
            [id]="operator.operatorId"
            *ngIf="!loadingAccept[i]"
          />
          <img
            src="../../../../../../assets/icons/reject-red-400.svg"
            alt="reject signal"
            [id]="operator.operatorId"
            *ngIf="loadingAccept[i]"
          />
          <label *ngIf="!loadingReject[i]">Recusar</label>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="closeModal()"
></app-modal>
