export enum ModalActionTypeEnum {
  Save,
  Send_to_BID,
  Approve_BID,
  Reprove_BID,
  Effect_Pending,
  Effect_New,
  Cancel,
  Delete,
  Finalize,
}

export enum ModalConfirmTypeEnum {
  Error,
  Success,
}
