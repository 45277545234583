<div *ngIf="showModal">
  <div class="modal-container">
    <img [src]="imagePath" />
    <div class="text-container">
      <h2>{{ title }}</h2>
      <span>{{ description }}</span>
    </div>

    <div class="error-object-container">
      <div class="error-object-item" *ngFor="let item of listOfErrors">
        <h4>{{ item.TabName }}</h4>
        <span *ngFor="let error of item.Errors"> {{ error }} </span>
      </div>
    </div>

    <div class="button-container">
      <app-primary-button
        [label]="buttonLabel"
        [color]="ButtonColorEnum.primary"
        [enabled]="true"
        (click)="onClick()"
      ></app-primary-button>
    </div>
  </div>
  <div class="overlay" (click)="onClick()"></div>
</div>
