<div class="container">
  <div class="card-container">
    <app-sumarize-header
      currentPageTitle="Fator Motorista"
    ></app-sumarize-header>
    <app-card
      icon="../../../../assets/icons/Money-Icon.svg"
      title="Fator Motorista"
      [accordion]="true"
    >
      <div body *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="12"></app-skeleton-input>
      </div>
      <div class="driver-container" *ngIf="!isLoading" body>
        <div class="input-container">
          <app-input
            label="Dias Úteis de Trabalho (ao mês)"
            [placeholder]="'0'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.workingDays"
            [mask]="eMaskType.number"
            [disabled]="shouldDisableField()"
            [max]="31"
            [min]="0"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Dias de Trabalho (ao mês) "
            [placeholder]="'0'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.totalWorkingDays"
            [disabled]="shouldDisableField()"
            [mask]="eMaskType.number"
            [max]="31"
            [min]="0"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Horas de trabalho (ao mês)"
            [placeholder]="'0'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.workingHours"
            [disabled]="shouldDisableField()"
            [mask]="eMaskType.number"
            [max]="744"
            [min]="0"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Horas Extras"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.overtimeHours"
            [disabled]="shouldDisableField()"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="%"
            [sideIconType]="eSideIconType.text"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label='Quantidade Horas "In Intinere" Motorista Padrão'
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.standardDriverInIntinereHours"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            [showSideIcon]="true"
            sideIconLabel=",00"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label='Quantidade Horas "In Intinere" Motorista Noturno'
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.nightDriverInIntinereHours"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            [showSideIcon]="true"
            sideIconLabel=",00"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Número de Turnos"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.numberOfShifts"
            [mask]="eMaskType.number"
            [disabled]="shouldDisableField()"
            [max]="744"
            [min]="0"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Motoristas Por Turno"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.driversPerShift"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            [showSideIcon]="true"
            sideIconLabel=",00"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Fator Equipe Motorista Padrão"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.standardDriverTeamFactor"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            [showSideIcon]="true"
            sideIconLabel=",00"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Fator Equipe Motorista Noturno"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.nightDriverTeamFactor"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel=",00"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Fator Equipe Motorista de Manobra Padrão"
            [placeholder]="'0'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.standardDriverManeuveringTeamFactor"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel=",00"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Fator Equipe Motorista de Manobra Padrão"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.nightDriverManeuveringTeamFactor"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel=",00"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Acordo Coletivo"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.collectiveAgreement"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="%"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Dissídio Acordo Coletivo"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.collectiveBargainingAgreement"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="%"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Salário Motorista"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.driverSalary"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="R$"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Contribuição da Empresa Sobre Vale Alimentação"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.foodTicketContribution"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField()"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="%"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="DSR"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.dsr"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField(true)"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="%"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Exame Toxicológico"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.toxicologicalExam"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField(true)"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="R$"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
          <app-input
            label="Acréscimo com Prazo de Pagamento de 90 Dias"
            [placeholder]="'0,00'"
            errorMessage="{{ errorMessage }}"
            [control]="form.controls.paymentTermAddition"
            [mask]="eMaskType.money"
            [disabled]="shouldDisableField(true)"
            [sideIconType]="eSideIconType.text"
            sideIconLabel="%"
            [showSideIcon]="true"
            (updateValue)="updateDFDVStore()"
          ></app-input>
        </div>
      </div>
    </app-card>
  </div>
</div>
