import { Utils } from 'src/app/common/utils/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/common/services/storage.service';
import { HistoryItemTypeEnum } from 'src/app/shared/history/enums/history-item-type.enum';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth.service';
import { GetApprovementFlowModel } from '../../../../services/solicitation/models/get-approvementFlow.model';
import { StatusColorEnum } from '../../../../shared/enums/StatusColor';

@Component({
  selector: 'app-solicitation-approvement',
  templateUrl: './solicitation-approvement.component.html',
  styleUrls: ['./solicitation-approvement.component.scss'],
})
export class SolicitationApprovementComponent implements OnInit {
  public solicitationId!: string;
  public approvements: GetApprovementFlowModel[] = [];
  public eHistoryItemType = HistoryItemTypeEnum;
  public username: string = '';
  public date: Date = new Date();
  public closed: boolean = false;
  isLoaded: boolean = false;

  public StatusColorEnum = StatusColorEnum;

  constructor(
    private storageService: StorageService,
    private service: SolicitationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.solicitationId = params.get('id') ?? '';
    });
    this.username = this.storageService.getUser();
    this.service
      .getApprovementFlow(this.solicitationId, this.username)
      .subscribe({
        next: this.handleSuccess.bind(this),
        error: this.handleError.bind(this),
      });
  }

  handleSuccess(item: GetApprovementFlowModel[]) {
    this.closed = !(item[item.length - 1].status === 'Em Fechamento');

    item.forEach((x) => {
      this.approvements.push(x);
    });
    this.isLoaded = true;
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      this.authService.redirectToAccessDenied();
    }
  }
}
