import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { IDfdvState, ILoadingState } from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { TableModel } from 'src/app/services/dfdv/models/table/table.model';
import { updateSubobject } from 'src/app/common/store/dfdv/dfdv.actions';
import { StorageService } from 'src/app/common/services/storage.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnDestroy {
  public modalModel: ModalModel = new ModalModel();
  public dfdvUtils!: DfdvUtils;
  public loadingButton: boolean = false;
  public isLoading: boolean = false;
  public updated: boolean = false;
  public table: TableModel[] = new Array<TableModel>(200);

  // props used in formulas
  public lineTractorEstimatedMonthlyKm: number = 0;
  public density: number = 0;
  public iss: number = 0;
  public totalVariableCostWithTax: number = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private service: DfdvService,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {
    this.store.select('loading').pipe(map((x) => x.loading)).subscribe((isLoading) => setTimeout(() => {
      this.isLoading = isLoading;
    }, 0));

    this.store.select('dfdv').pipe(takeUntil(this.destroy$), map((x) => x.dfdv)).subscribe((dfdv) => {
      if (this.updated || !dfdv.plant || !dfdv.tax || !dfdv.scope || !dfdv.summary) return;

      this.dfdvUtils = new DfdvUtils(
        dfdv,
        this.storageService.getRole(),
        this.store
      );
      if (this.dfdvUtils.isEnableToEdit()) {
        this.dfdvUtils.updateSummary();
        const shouldUpdateSubobject = this.lineTractorEstimatedMonthlyKm
              !== dfdv.scope.equipment.lineTractorEstimatedMonthlyKm
          || this.density !== dfdv.plant.density
          || this.iss !== dfdv.tax.iss
          || this.totalVariableCostWithTax !== dfdv.summary.totalVariableCostWithTax;

        this.lineTractorEstimatedMonthlyKm = dfdv.scope.equipment.lineTractorEstimatedMonthlyKm;
        this.density = dfdv.plant.density;
        this.iss = dfdv.tax.iss;
        this.totalVariableCostWithTax = dfdv.summary.totalVariableCostWithTax;

        this.applyFormulas();

        if (shouldUpdateSubobject) {
          this.updateSubobject();
        }
      } else {
        this.lineTractorEstimatedMonthlyKm = dfdv.scope.equipment.lineTractorEstimatedMonthlyKm;
        this.density = dfdv.plant.density;
        this.table = [...dfdv.table];
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFormulas() {
    for (let i = 0; i < 200; i++) {
      const kmDistance = i + 1;
      const dmShippingCost = this.lineTractorEstimatedMonthlyKm !== 0 && this.density !== 0
        ? (this.totalVariableCostWithTax / this.lineTractorEstimatedMonthlyKm / this.density)
           * kmDistance * 2
        : 0;
      const fmShippingCost = (dmShippingCost / 0.88) * (1 - this.iss / 100);

      this.table[i] = {
        kmDistance,
        dmShippingCost,
        fmShippingCost,
      };
    }
  }

  updateSubobject() {
    this.updated = true;
    this.store.dispatch(
      updateSubobject({
        subObjectName: 'table',
        data: this.table,
      })
    );
  }

  getColumnTable(index: number) {
    return this.table.slice(index * 100, (index + 1) * 100);
  }

  submit() {
    if (this.isLoading || this.loadingButton) return;

    this.loadingButton = true;

    const request = this.dfdvUtils.getUpdateRequest();

    this.service.updateDfdv(request).subscribe({
      next: () => {
        this.modalModel.title = 'Alterações realizadas com sucesso!';
        this.modalModel.description = 'Sua DFDV foi recalculada e já se econtra com os valores atualizados.';
        this.modalModel.buttonLabel = 'Entendi';
        this.modalModel.imagePath = '../../../../assets/icons/success-600.gif';
        this.modalModel.success = true;
        this.modalModel.showModal = true;
        this.loadingButton = false;
      },
      error: (error: HttpErrorResponse) => {
        const message = JSON.parse(JSON.stringify(error?.error));
        this.modalModel.title = 'Não foi possível realizar as alterações';
        this.modalModel.description = error.status >= 400 && error.status < 500
          ? message?.Message ?? message
          : 'Tente novamente mais tarde ou entre em contato com algum administrador.';
        this.modalModel.buttonLabel = 'Entendi';
        this.modalModel.imagePath = '../../../../assets/icons/warning-600.gif';
        this.modalModel.success = false;
        this.modalModel.showModal = true;
        this.loadingButton = false;
      },
    });
  }

  closeModal() {
    this.modalModel.showModal = false;
    this.loadingButton = false;
  }
}
