<app-card
  title="Despesas Fixas e Variáveis"
  icon="../../../../../assets/icons/solicitation-primary-600.svg"
  [paddingHorizontal]="false"
>
  <section actions class="actions" *ngIf="!isLoading && !error && approvalVisible">
    <app-check-box
      [label]="'Selecionar todos'"
      [labelColor]="'#781f78'"
      [bold]="true"
      (check)="checkAll()"
      (removeCheck)="removeAllChecks()"
    ></app-check-box>
    <app-icon-button
      (clickButton)="handleReproveBID()"
      icon="assets/icons/reprove.svg"
      text="Reprovar BID"
      [enabled]="enableApproveAndReproveBID"
      [loading]="loadingReproveBID"
      [color]="ebuttonColor.warning"
    ></app-icon-button>
    <app-icon-button
      (clickButton)="handleApproveBID()"
      icon="assets/icons/approve-success.svg"
      text="Aprovar BID"
      [enabled]="enableApproveAndReproveBID"
      [loading]="loadingApproveBID"
      [color]="ebuttonColor.success"
    ></app-icon-button>
  </section>
  <div
    body
    class="body"
    *ngIf="!isLoading && dfdvsDetail.length > 0 && !error"
  >
    <div *ngIf="approvalVisible" class="actions-list">
      <app-check-box
        [label]="checkBoxLabel ? 'Selecionar todos' : ''"
        [labelColor]="'#781f78'"
        [bold]="true"
        (check)="checkAll()"
        (removeCheck)="removeAllChecks()"
      ></app-check-box>
      <app-icon-button
        (clickButton)="handleReproveBID()"
        icon="assets/icons/reprove.svg"
        text="Reprovar BID"
        [enabled]="enableApproveAndReproveBID"
        [loading]="loadingReproveBID"
        [color]="ebuttonColor.warning"
      ></app-icon-button>
      <app-icon-button
        (clickButton)="handleApproveBID()"
        icon="assets/icons/approve-success.svg"
        text="Aprovar BID"
        [enabled]="enableApproveAndReproveBID"
        [loading]="loadingApproveBID"
        [color]="ebuttonColor.success"
      ></app-icon-button>
    </div>
    <div class="solicitations">
      <div
        *ngFor="let dfdv of dfdvsDetail; let indexOfelement = index"
        class="rows"
      >
        <app-dfdv-detail
          [dfdv]="dfdv"
          [isPairRow]="indexOfelement % 2 === 0"
          [approvalVisible]="approvalVisible"
          [isUserRaizen]="isUserRaizen"
          (check)="checkItem($event)"
          (removeCheck)="removeCheck($event)"
        ></app-dfdv-detail>
      </div>
    </div>
    <br />
    <app-paginator
      [page]="page"
      [totalPages]="totalPages"
      (callbackFunc)="setPageCallbackFunc($event)"
    ></app-paginator>
  </div>
  <div body class="skeleton" *ngIf="isLoading">
    <app-skeleton-line [line]="5"></app-skeleton-line>
  </div>
  <div
    body
    class="not-found"
    *ngIf="!isLoading && dfdvsDetail.length === 0 && !error"
  >
    <app-not-found></app-not-found>
  </div>
  <div
    body
    class="error"
    *ngIf="!isLoading && dfdvsDetail.length === 0 && error"
  >
    <app-error (reloadCallBack)="reload()"></app-error>
  </div>
</app-card>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
<app-modal-confirmation
  [showModal]="modalConfirmationModel.showModal"
  [title]="modalConfirmationModel.title"
  [description]="modalConfirmationModel.description"
  [confirmLabel]="modalConfirmationModel.confirmLabel"
  [confirmIcon]="modalConfirmationModel.confirmIcon"
  [confirmColor]="modalConfirmationModel.confirmColor"
  (confirmFunc)="handleConfirm()"
  (cancelFunc)="handleCancel()"
></app-modal-confirmation>
