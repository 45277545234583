/* eslint-disable no-param-reassign */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage.service';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { CategoryService } from 'src/app/services/category/category.service';
import { CreateCategoryModel } from 'src/app/services/category/models/create-category-model';
import { UpdateCategoryModel } from 'src/app/services/category/models/update-category-model';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';
import { AnalystService } from 'src/app/services/analyst/analyst.service';
import { SearchModel } from 'src/app/shared/search/model/search.model';
import { AuthService } from 'src/app/common/services/auth.service';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { CategoryPimsService } from 'src/app/services/pims/category/category.service';
import { CategoryPimsModel } from 'src/app/services/category/models/category-pims.model';
import { CategoryStorageModel } from '../../../../services/category/models/category-storage.model';
import { SearchControlModel } from '../../unit/unit-new/model/searc-control.model';

@Component({
  selector: 'app-category-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.scss'],
})
export class CategoryNewComponent implements OnInit {
  public title = 'Cadastro de nova categoria';
  public id: string | null = null;
  public checked: boolean = true;

  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;

  public showModal: boolean = false;
  public modalModel: ModalModel = new ModalModel();
  public enableButton = false;
  public loadingButton = false;
  public description: string = 'Favor informar os seguintes dados, para que possamos cadastrar a categoria.';
  public buttonLabel: string = 'CADASTRAR';
  public toogleCheck: boolean = false;
  public isLoading: boolean = true;

  public categoryPims!: CategoryPimsModel | null;
  public categorySearch = new SearchControlModel();
  public coordSuprimentosSearch: SearchControlModel = new SearchControlModel();
  public coordCorporativoSearch: SearchControlModel = new SearchControlModel();
  public corporateDirectorSearch: SearchControlModel = new SearchControlModel();
  public corporateManagerSearch: SearchControlModel = new SearchControlModel();

  public form = this.formBuilder.group({
    name: ['', Validators.required],
    costCenter: ['', Validators.required],
    supCoord: ['', Validators.required],
    corpCoord: ['', Validators.required],
    corpDirector: ['', Validators.required],
    corpManager: ['', Validators.required],
    categoryPimsId: [''],
    categoryPimsName: [''],
  });

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private categoryService: CategoryService,
    private analystService: AnalystService,
    private authService: AuthService,
    private categoryPimsService: CategoryPimsService,
  ) {}

  ngOnInit(): void {
    this.getStorage();

    this.form.statusChanges.subscribe(() => {
      this.setStorage();
    });

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.fillForm(params.get('id'));
    });
  }

  fillForm(value: string | null) {
    if (value == null) {
      this.isLoading = false;
      return;
    }

    this.categoryService.getCategoryById(value).subscribe({
      next: (el) => {
        if (el) {
          this.title = `Categoria #${el.categoryId
            ?.toString()
            .padStart(5, '0')}`;
          this.description = 'Favor realizar os ajustes necessários, e em seguida confirmar através do botão abaixo, para que possamos atualizar a categoria.';
          this.buttonLabel = 'SALVAR';
          this.enableButton = true;
          this.checked = el.active;

          this.form.controls.name.patchValue(el.name);
          this.form.controls.costCenter.patchValue(
            el.costCenter?.toString() ?? '',
          );

          this.categoryPims = el.categoryPims;
          this.form.controls.categoryPimsId
            .patchValue(el?.categoryPims?.categoryPimsId?.toString());
          this.form.controls.categoryPimsName.patchValue(el?.categoryPims?.name);

          this.categorySearch.selected = !!el?.categoryPims?.categoryPimsId;
          this.categorySearch.item = el?.categoryPims?.name ?? '';

          this.form.controls.supCoord.patchValue(el.supplyCoordinatorId);
          this.form.controls.corpCoord.patchValue(el.corporateCoordinatorId);
          this.form.controls.corpManager.patchValue(el.corporateManagerId);
          this.form.controls.corpDirector.patchValue(el.corporateDirectorId);

          this.coordSuprimentosSearch.selected = true;
          this.coordSuprimentosSearch.item = el.supplyCoordinatorName;
          this.coordSuprimentosSearch.searchModel.analystId = el.supplyCoordinatorId;

          this.coordCorporativoSearch.selected = true;
          this.coordCorporativoSearch.item = el.corporateCoordinatorName;
          this.coordCorporativoSearch.searchModel.analystId = el.corporateCoordinatorId;

          this.corporateManagerSearch.selected = true;
          this.corporateManagerSearch.item = el.corporateManagerName;
          this.corporateManagerSearch.searchModel.analystId = el.corporateManagerId;

          this.corporateDirectorSearch.selected = true;
          this.corporateDirectorSearch.item = el.corporateDirectorName;
          this.corporateDirectorSearch.searchModel.analystId = el.corporateDirectorId;
        } else {
          this.goBack();
        }
      },
      error: this.handleGetCategoryError.bind(this),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleToggleCheck() {
    this.checked = !this.checked;
    this.setStorage();
  }

  handleSearchClick(value: string, model: SearchControlModel) {
    if (value === '') return;

    model.loading = true;

    this.analystService.getAnalystByIdUserSystem(value).subscribe({
      next: (element: SearchModel) => {
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.item = element.name;
        model.searchModel = element;
        this.form.enable();
      },
      error: () => {
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar o ID';
      },
    });
  }

  handleCategorySearchClick(value: string, model: SearchControlModel) {
    if (value === '') return;

    model.loading = true;
    this.categoryPimsService.getCategoryPimsById(+value).subscribe({
      next: (element) => {
        this.categoryPims = element;
        this.form.controls.categoryPimsName.patchValue(element?.name);
        model.loading = false;
        model.error = false;
        model.selected = true;
        model.errorMessage = '';
        model.item = element.name;
      },
      error: () => {
        model.loading = false;
        model.error = true;
        model.errorMessage = 'Não foi possivel localizar a categoria';
      },
    });
  }

  handleCardClick(model: SearchControlModel) {
    if (model.searchModel.department) {
      model.showModal = true;
      return;
    }

    model.loadingCard = true;
    this.analystService
      .getAnalystByIdUserSystem(model.searchModel.analystId)
      .subscribe({
        next: (element) => {
          model.loadingCard = false;
          model.searchModel = element;
          model.showModal = true;
        },
        error: () => {
          model.loadingCard = false;
          this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
          this.modalModel.title = 'Não foi possível obter os dados do Usuário';
          this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com algum administrador.';
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.success = false;
          this.showModal = true;
        },
      });
  }

  excludeCard(model: SearchControlModel) {
    model.selected = false;
    model.item = '';
    this.categoryPims = null;
    this.form.enable();
  }

  handleCloseModal(model: SearchControlModel) {
    model.showModal = false;
  }

  handleButtonClick() {
    if (this.form.invalid) {
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Dados obrigatórios não preenchidos';
      this.modalModel.description = 'Preencha todos os dados necessarios para continuar com o cadastro de uma nova categoria';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.showModal = true;
      return;
    }

    const request = {
      name: this.form.controls.name.value ?? '',
      costCenter: +(this.form.controls.costCenter.value ?? ''),
      supplyCoordinatorId: this.form.controls.supCoord.value ?? '',
      corporateCoordinatorId: this.form.controls.corpCoord.value ?? '',
      corporateManagerId: this.form.controls.corpManager.value ?? '',
      corporateDirectorId: this.form.controls.corpDirector.value ?? '',
      active: this.checked,
      categoryPims: this.categoryPims,
    } as CreateCategoryModel;

    this.loadingButton = true;
    this.categoryService.createCategory(request).subscribe({
      next: this.handleCreateCategorySuccess.bind(this),
      error: this.handleCreateCategoryError.bind(this),
    });
  }

  updateHandleButtonClick() {
    if (this.form.invalid) {
      this.loadingButton = false;
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Dados obrigatórios não preenchidos';
      this.modalModel.description = 'Preencha todos os dados necessarios para continuar com a atualização da categoria';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.showModal = true;
      return;
    }

    const request = {
      categoryId: Number.parseInt(this.id ?? '0', 10),
      name: this.form.controls.name.value ?? '',
      costCenter: +(this.form.controls.costCenter.value ?? ''),
      supplyCoordinatorId: this.form.controls.supCoord.value ?? '',
      corporateCoordinatorId: this.form.controls.corpCoord.value ?? '',
      corporateManagerId: this.form.controls.corpManager.value ?? '',
      corporateDirectorId: this.form.controls.corpDirector.value ?? '',
      active: this.checked,
      categoryPims: this.categoryPims,
    } as UpdateCategoryModel;

    this.loadingButton = true;
    this.categoryService.updateCategory(request).subscribe({
      next: this.handleCreateCategorySuccess.bind(this),
      error: this.handleCreateCategoryError.bind(this),
    });
  }

  handleCreateCategorySuccess() {
    this.loadingButton = false;
    this.modalModel.imagePath = '../../../assets/icons/success-600.gif';
    this.modalModel.title = `Categoria ${
      this.id ? 'atualizada' : 'cadastrada'
    } com sucesso!`;
    this.modalModel.description = `Atenção: a categoria encontra-se ${
      this.checked ? 'ATIVA' : 'INATIVA'
    }.`;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = true;
    this.showModal = true;
    this.storageService.clearCreateCategoryForm();
  }

  handleCreateCategoryError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.loadingButton = false;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível ${
      this.id ? 'atualizar' : 'cadastrar'
    } a categoria`;
    this.modalModel.description = message?.Message;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.success = false;
    this.showModal = true;
  }

  handleGetCategorySuccess(res: GetCategoryModel) {
    this.form.controls.name.patchValue(res.name);
    this.form.controls.costCenter.patchValue(res.costCenter?.toString() ?? '');
    this.form.controls.supCoord.patchValue(res.supplyCoordinatorId);
    this.form.controls.corpCoord.patchValue(res.corporateCoordinatorId);
    this.form.controls.corpDirector.patchValue(res.corporateDirectorId);
    this.form.controls.corpManager.patchValue(res.corporateManagerId);
    this.checked = res.active;
    this.description = 'Favor realizar os ajustes necessários, e em seguida confirmar através do botão abaixo, para que possamos atualizar esta categoria.';
    this.buttonLabel = 'SALVAR';

    this.coordSuprimentosSearch.item = this.form.controls.supCoord.value!;
    this.coordSuprimentosSearch.selected = true;

    this.coordCorporativoSearch.item = this.form.controls.corpCoord.value!;
    this.coordCorporativoSearch.selected = true;

    this.corporateManagerSearch.item = this.form.controls.corpManager.value!;
    this.corporateManagerSearch.selected = true;

    this.corporateDirectorSearch.item = this.form.controls.corpDirector.value!;
    this.corporateDirectorSearch.selected = true;
  }

  handleGetCategoryError(res: HttpErrorResponse) {
    if (res.status === 404) {
      this.authService.redirectToAccessDenied();
    } else {
      const message = JSON.parse(JSON.stringify(res.error));
      this.loadingButton = false;
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Erro ao buscar categoria';
      this.modalModel.description = message?.Message;
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = true;
      this.showModal = true;
      this.isLoading = false;
    }
  }

  clickModal() {
    this.showModal = false;

    if (this.modalModel.success) {
      this.goBack();
    }
  }

  setStorage() {
    this.enableButton = this.form.valid;

    if (this.id) return;

    const model = {
      active: this.checked,
      name: this.form.controls.name.value!,
      costCenter: +this.form.controls.costCenter.value!,
      supplyCoordinatorId: this.form.controls.supCoord.value!,
      supplyCoordinatorName: this.coordSuprimentosSearch.item,
      corporateDirectorId: this.form.controls.corpDirector.value!,
      corporateDirectorName: this.corporateDirectorSearch.item,
      corporateManagerId: this.form.controls.corpManager.value!,
      corporateManagerName: this.corporateManagerSearch.item,
      corporateCoordinatorId: this.form.controls.corpCoord.value!,
      corporateCoordinatorName: this.coordCorporativoSearch.item,
      categoryPims: this.form.controls.categoryPimsId.value
        ? this.categoryPims : new CategoryPimsModel(),
    } as CategoryStorageModel;

    this.storageService.setCreateCategoryForm(model);
  }

  getStorage() {
    const category = this.storageService.getCreateCategoryForm();

    if (category === undefined) return;

    this.form.controls.name.patchValue(category.name);
    this.form.controls.costCenter.patchValue(
      category.costCenter?.toString() ?? '',
    );
    this.form.controls.supCoord.patchValue(category.supplyCoordinatorId);
    this.form.controls.corpDirector.patchValue(category.corporateDirectorId);
    this.form.controls.corpManager.patchValue(category.corporateManagerId);
    this.form.controls.corpCoord.patchValue(category.corporateCoordinatorId);
    this.checked = category.active;

    this.coordSuprimentosSearch.selected = !!category.supplyCoordinatorId;
    this.coordSuprimentosSearch.item = category.supplyCoordinatorName;
    this.coordSuprimentosSearch.searchModel.analystId = category.supplyCoordinatorId;

    this.coordCorporativoSearch.selected = !!category.corporateCoordinatorId;
    this.coordCorporativoSearch.item = category.corporateCoordinatorName;
    this.coordCorporativoSearch.searchModel.analystId = category.corporateCoordinatorId;

    this.corporateManagerSearch.selected = !!category.corporateManagerId;
    this.corporateManagerSearch.item = category.corporateManagerName;
    this.corporateManagerSearch.searchModel.analystId = category.corporateManagerId;

    this.corporateDirectorSearch.selected = !!category.corporateDirectorId;
    this.corporateDirectorSearch.item = category.corporateDirectorName;
    this.corporateDirectorSearch.searchModel.analystId = category.corporateDirectorId;

    this.categoryPims = category.categoryPims;
    this.form.controls.categoryPimsId
      .patchValue(category?.categoryPims?.categoryPimsId?.toString());
    this.form.controls.categoryPimsName.patchValue(category?.categoryPims?.name);

    this.categorySearch.selected = !!category?.categoryPims?.categoryPimsId;
    this.categorySearch.item = category?.categoryPims?.name ?? '';

    this.enableButton = this.form.valid;
  }

  goBack() {
    this.router.navigate(['/admin/category']);
  }
}
