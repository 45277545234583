<div class="container">
  <section>
    <header>
      <app-buttons
        class="button"
        label="NOVO USUÁRIO"
        [color]="eButtonsColor.primary"
        [type]="eButtonsType.primary"
        [loading]="false"
        [enabled]="true"
        icon="./assets/icons/add-common-100.svg"
        (clickButton)="handleButtonClick()"
      ></app-buttons>
    </header>
    <app-card
      title="Usuários"
      icon="./assets/icons/person.svg"
      [paddingHorizontal]="false"
    >
      <app-analyst-list
        body
        *ngIf="!isLoading && analysts.length > 0 && !error"
        [analystsList]="analysts"
      ></app-analyst-list>
      <div class="skeleton" body *ngIf="isLoading">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <div
        body
        class="not-found"
        *ngIf="!isLoading && analysts.length === 0 && !error"
      >
        <app-not-found></app-not-found>
      </div>
      <div
        body
        class="error"
        *ngIf="!isLoading && analysts.length === 0 && error"
      >
        <app-error (reloadCallBack)="reload()"></app-error>
      </div>
    </app-card>
  </section>
</div>
