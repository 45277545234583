export class ScopeFeeModel {
  [key: string]: any;

  public dpvat: number = 0;
  public licensing: number = 0;
  public rctrc: number = 0;
  public rcfv: number = 0;
  public ipvaAndLicensingFee: number = 0;
  public ipvaWithDiscount: number = 0;
  public mandatoryInsurance: number = 0;
  public crlvEmissionFee: number = 0;
  public capitalRemuneration: number = 0;
  public hasIpvaAndLicensingFee: boolean = true;
}
