import { AuthService } from 'src/app/common/services/auth.service';
import { UpdateSubcategoryModel } from 'src/app/services/subcategory/models/update-subcategory-model';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { SubcategoryService } from 'src/app/services/subcategory/subcategory.service';
import { CreateSubcategoryModel } from 'src/app/services/subcategory/models/create-subcategory-model';

@Component({
  selector: 'app-subcategory-new',
  templateUrl: './subcategory-new.component.html',
  styleUrls: ['./subcategory-new.component.scss'],
})
export class SubcategoryNewComponent implements OnInit {
  public pageTitle! : string;

  public eInputType = InputTypeEnum;

  public buttonLabel! : string;
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public enableButton = true;
  public loadingButton = false;

  public toogleCheck : boolean = true;
  public checked: boolean = true;

  public id! : string;
  public modalModel : ModalModel = new ModalModel();
  public showModal : boolean = false;
  public isLoading: boolean = true;

  public form = this.formBuilder.group({
    name: ['', Validators.required],
  });

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private subcategoryService: SubcategoryService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id')!;

      if (this.id === null) {
        this.buttonLabel = 'CADASTRAR';
        this.pageTitle = 'Cadastro de nova Subcategoria';
        this.isLoading = false;
      } else {
        this.buttonLabel = 'SALVAR';
        this.subcategoryService.getSubcategoryById(+this.id).subscribe({
          next: (result) => {
            this.pageTitle = `Subcategoria #${this.id.toString().padStart(5, '0')}`;
            this.form.controls.name.patchValue(result.name);
            this.checked = result.active;
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.authService.redirectToAccessDenied();
            } else {
              const message = JSON.parse(JSON.stringify(error?.error));
              this.loadingButton = false;
              this.modalModel.buttonLabel = 'Entendi';
              this.modalModel.description = message?.Message ?? message;
              this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
              this.modalModel.title = 'Erro ao buscar subcategoria';
              this.modalModel.success = true;
              this.showModal = true;
            }
          },
          complete: () => {
            this.isLoading = false;
          },
        });
      }
    });
  }

  handleButtonClick() {
    if (!this.validateForm()) { return; }

    const request = {
      name: this.form.controls.name.value,
      active: this.checked,
    } as CreateSubcategoryModel;

    this.subcategoryService.createSubcategory(request).subscribe({
      next: this.createSubcategorySuccess.bind(this),
      error: this.createSubcategoryError.bind(this),
    });
  }

  handleUpdateButtonClick() {
    if (!this.validateForm()) { return; }

    const request = {
      subcategoryId: +(this.id ?? 0),
      name: this.form.controls.name.value,
      active: this.checked,
    } as UpdateSubcategoryModel;

    this.subcategoryService.updateSubcategory(request).subscribe({
      next: this.createSubcategorySuccess.bind(this),
      error: this.createSubcategoryError.bind(this),
    });
  }

  validateForm() {
    this.loadingButton = true;
    if (this.form.controls.name.value === null || this.form.controls.name.value === '') {
      this.loadingButton = false;
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.description = 'Preencha todos os dados necessarios para continuar com o cadastro de uma nova subcategoria';
      this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
      this.modalModel.title = 'Dados obrigatorios não preenchidos';
      this.modalModel.success = false;
      this.showModal = true;
      return false;
    }
    return true;
  }

  handleToggleCheck() {
    this.checked = !this.checked;
    this.toogleCheck = this.checked;
  }

  clickModal() {
    if (this.modalModel.success) {
      this.showModal = false;
      this.goBack();
    } else {
      this.showModal = false;
    }
  }

  goBack() {
    this.router.navigate(['/admin/subcategory']);
  }

  createSubcategorySuccess() {
    this.loadingButton = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = `Atenção: a subcategoria encontra-se ${this.checked ? 'ATIVA' : 'INATIVA'}.`;
    this.modalModel.imagePath = '../../../assets/icons/approve-modal.svg';
    this.modalModel.title = `Subcategoria ${this.id ? 'atualizada' : 'cadastrada'} com sucesso!`;
    this.modalModel.success = true;
    this.showModal = true;
  }

  createSubcategoryError(res: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(res.error));
    this.loadingButton = false;
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.description = message?.Message;
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.title = `Não foi possível ${this.id ? 'atualizar' : 'cadastrar'} a subcategoria`;
    this.modalModel.success = false;
    this.showModal = true;
  }
}
