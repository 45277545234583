import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() top: boolean = false;
  @Input() bottom: boolean = false;
  @Input() left: boolean = false;
  @Input() right: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
