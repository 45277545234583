<div class="card-container">
  <header>
    <app-sumarize-header
      [currentPageTitle]="dfdv.unitName || 'Usina'"
      [errorMessage]="hasErrorMessage"
    ></app-sumarize-header>
  </header>
  <app-card
    [title]="dfdv.unitName || 'Usina'"
    icon="../../../../../assets/icons/plant-common.svg"
    [accordion]="true"
  >
    <div *ngIf="!isLoading" class="card-body" body>
      <app-datepicker
        label="Duração do Contrato"
        placeholder="Selecione o intervalo de data"
        [controlStartDate]="form.controls.startDate"
        [controlEndDate]="form.controls.endDate"
        [interval]="true"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      ></app-datepicker>
      <app-input
        label="Meses de Duração da Safra"
        placeholder="0"
        [errorMessage]="errorMessage"
        [control]="form.controls.harvestMonths"
        [mask]="eMaskType.number"
        [max]="12"
        [min]="0"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="Raio Médio Estimado (KM)"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.estimatedAvgRadius"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel=",00"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="TCD"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.TCD"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel=",00"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      >
        <app-tooltip [top]="true">
          <div class="tooltip-info">
            <div class="text"><b>TCD:</b> Tonelada de Cana por Dia</div>
          </div>
        </app-tooltip>
      </app-input>
      <app-input
        label="Densidade (TON)"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.density"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel=",00"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="Volumetria"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.volumetry"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel=",00"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields || disableFieldOPL"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="Dias de Pré-Safra"
        placeholder="0"
        [errorMessage]="errorMessage"
        [control]="form.controls.preHarvestDays"
        [mask]="eMaskType.number"
        [max]="9999999999"
        [min]="0"
        [disabled]="shouldDisableFields || disableFieldRaizen"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        label="Negociação Entre-Safra"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.betweenHarvestNegotiation"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="%"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields || disableFieldRaizen"
        (updateValue)="updateDFDVStore()"
      ></app-input>
      <app-input
        *ngIf="isUserRaizen"
        label="Faturamento Estimado Outros Custos"
        placeholder="0,00"
        [errorMessage]="errorMessage"
        [control]="form.controls.otherCostEstimatedInvoicing"
        [mask]="eMaskType.money"
        [showSideIcon]="true"
        sideIconLabel="R$"
        [sideIconType]="eSideIconType.text"
        [disabled]="shouldDisableFields"
        (updateValue)="updateDFDVStore()"
      ></app-input>
    </div>
    <div *ngIf="isLoading" class="skeleton" body>
      <app-skeleton-input [input]="4"></app-skeleton-input>
    </div>
  </app-card>
</div>
