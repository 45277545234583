import { SupportTeamDetailModel } from "./support-team-detail.model";
import { SupportTeamTotalizerModel } from "./support-team-totalizer.model";

export class MaintenenceAndWarehouseModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.total = new SupportTeamTotalizerModel();
    this.maintenanceSupervisor = new SupportTeamDetailModel();
    this.warehouseAssistant = new SupportTeamDetailModel();
    this.storekeeperAssistant = new SupportTeamDetailModel();
    this.nightStorekeeperAssistant = new SupportTeamDetailModel();
    this.electrician = new SupportTeamDetailModel();
    this.nightElectrician = new SupportTeamDetailModel();
    this.mechanic = new SupportTeamDetailModel();
    this.nightMechanic = new SupportTeamDetailModel();
    this.tireRepairman = new SupportTeamDetailModel();
    this.nightTireRepairman = new SupportTeamDetailModel();
    this.welder = new SupportTeamDetailModel();
    this.mechanicalAssistant = new SupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  maintenanceSupervisor: SupportTeamDetailModel = new SupportTeamDetailModel();
  warehouseAssistant: SupportTeamDetailModel = new SupportTeamDetailModel();
  storekeeperAssistant: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightStorekeeperAssistant: SupportTeamDetailModel =
    new SupportTeamDetailModel();
  electrician: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightElectrician: SupportTeamDetailModel = new SupportTeamDetailModel();
  mechanic: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightMechanic: SupportTeamDetailModel = new SupportTeamDetailModel();
  tireRepairman: SupportTeamDetailModel = new SupportTeamDetailModel();
  nightTireRepairman: SupportTeamDetailModel = new SupportTeamDetailModel();
  welder: SupportTeamDetailModel = new SupportTeamDetailModel();
  mechanicalAssistant: SupportTeamDetailModel = new SupportTeamDetailModel();
}
