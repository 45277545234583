import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Utils } from 'src/app/common/utils/utils';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';
import { InfoboxTypeEnum } from '../infobox/enums/infobox-type.enum';
import { InputTypeEnum } from '../input/enums/input-type.enum';
import { MaskTypeEnum } from '../input/enums/mask-type.enum';
import { ColumnTypeEnum } from './Enum/columnTypeEnum';
import { ColumnModel } from './model/columnModel';
import { SelectOptionsModel } from './model/select-options.model';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent implements OnInit, AfterViewInit {
  // Inputs
  @Input() headers: string[] = [];
  @Input() columns: ColumnModel[] = [];
  @Input() rows: FormGroup[] = [];
  @Input() rowDescriptions: string[] = [];
  @Input() descriptionLabel: string = 'DESCRIÇÃO';
  @Input() showTotal: boolean = false;
  @Input() showAverage: boolean = false;
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() showInfoBox: boolean = false;
  @Input() errorMessage: string = ' Os seguintes campos em vermelho e marcados com * são de preenchimento obrigatório.';
  @Input() calcString: string = '';
  @Input() accordion: boolean = false;
  @Input() options: string[] = [];
  @Input() customTableSize: string = '';
  @Input() selectedOption: SelectOptionsModel[] = [];
  @Input() actions: boolean = false;
  @Input() defaultForm!: FormGroup;
  @Input() labelTotal: string = 'Total';
  @Input() labelMedia: string = 'Média';
  @Input() hasError: boolean = false;
  @Input() paragraph: string = '';
  @Input() flexStart: boolean = false;
  @Input() customTotalValue: string | null = null;
  @Input() loading: boolean = false;
  @Input() skeletonSize: number = 3;
  @Input() showCustomTotal: boolean = false;
  @Input() customLabel: string = '';
  @Input() customValue: string = '0';
  @Input() totalLeftPadding: number = 0;

  // Auxs
  public height: string = '2.5rem';
  public isValid: { valid: boolean; column: string }[] = [];
  public totalColumns: { column: string; value: number }[] = [];
  // Enums
  public eColumnType = ColumnTypeEnum;
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;
  public eButtonColor = ButtonColorEnum;

  // Mobile Control
  public breakpointBase: number = 640;
  public widthBreakpoint: number = 640;
  public isMobile: boolean = false;

  @Output() totalOut: EventEmitter<any> = new EventEmitter();
  @Output() toogleOut: EventEmitter<any> = new EventEmitter();
  @Output() total: EventEmitter<number> = new EventEmitter();
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  constructor(
    private formBuilder: FormBuilder,
    private util: Utils,
    private cd: ChangeDetectorRef,
  ) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  ngAfterViewInit(): void {
    this.checkDevice();
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    for (let i = 0; i < this.columns.length; i++) {
      this.isValid.push({
        valid: true,
        column: this.columns[i].name,
      });
    }

    if (this.selectedOption.length === 0) {
      this.columns.forEach((element) => {
        if (element.type === 'select') {
          for (let i = 0; i < this.rows.length; i++) {
            const aux = [] as string[];
            this.selectedOption.push({
              column: element.name,
              index: i,
              option: aux,
            });
          }
        }
      });
    }
  }

  checkDevice() {
    this.isMobile = this.getWindowInnerWidth() <= this.widthBreakpoint;
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }

  getFormControl(row: FormGroup, columnType: string) {
    const control: AbstractControl | null = row.get(columnType);
    return control instanceof FormControl ? control : new FormControl(null);
  }

  getColumnTemplate() {
    const sum = this.rowDescriptions.length > 0 ? 1 : 0;
    if (this.customTableSize !== '') {
      return {
        'grid-template-columns': `${this.customTableSize} ${this.actions ? '1rem' : ''
          }`,
      };
    }
    return {
      'grid-template-columns': `repeat(${this.headers.length + sum}, 1fr) ${this.actions ? '1rem' : ''
        }`,
    };
  }

  renderRowDescription() {
    if (this.rowDescriptions.length > 0) {
      return {
        opacity: '1',
      };
    }
    return {
      opacity: '0',
    };
  }

  calcTotal(column: ColumnModel, average: boolean) {
    if (!column.hasTotal && !column.hasAverage) {
      return '-';
    }

    let total = 0;

    for (let i = 0; i < this.rows.length; i++) {
      const value = this.rows[i].get(column.name)?.value ?? 0;
      total += +value;
    }

    const totalColumn = this.totalColumns.find((x) => x.column === column.name);

    if (totalColumn) {
      this.totalColumns[this.totalColumns.indexOf(totalColumn)].value = total;
    } else {
      this.totalColumns.push({ column: column.name, value: total });
    }
    if (this.totalColumns !== undefined && this.totalColumns !== null) {
      this.totalOut.emit(this.totalColumns);
    }

    if (average) {
      let number = total / this.rows.length;
      number = Math.round(+number * 100) / 100;
      return number.toString();
    }

    this.total.emit(total);
    return total.toString();
  }

  formatCurrency(number: string) {
    if (Number.isNaN(+number)) {
      return '-';
    }

    const roundedNumber = Math.round(+number * 100) / 100;

    const formattedNumber = roundedNumber.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formattedNumber;
  }

  formIsValid() {
    const validArr: boolean[] = [];
    this.isValid.forEach((x) => {
      this.rows.forEach((y) => {
        if (y.get(x.column)?.untouched || y.get(x.column)?.valid) {
          validArr.push(true);
        } else {
          validArr.push(false);
        }
      });
    });
    if (validArr.includes(false)) {
      return false;
    }
    return true;
  }

  calcColumn(row: FormGroup, columnName: string) {
    const result = eval(this.calcString);
    row.get(columnName)?.patchValue(result.toString());
    return this.formatCurrency(result);
  }

  forceChanges() {
    this.cd.detectChanges();
  }

  handleCheck(row: FormGroup, columnName: string, index: number) {
    row.get(columnName)?.setValue(!row.get(columnName)?.value);
    this.toogleOut.emit({
      index,
      columnName,
      value: row.get(columnName)?.value,
    });
  }

  changeControl(event: string[], columnName: string, index: number) {
    const control = this.selectedOption.find(
      (name) => name.column === columnName && name.index === index,
    );
    if (control) {
      control.option = event;
      this.rows[index].get(columnName)?.patchValue(event);
    }
  }

  getSelectedOption(columnName: string, row: number) {
    const aux: string[] = [];
    return (
      this.selectedOption.find(
        (x) => x.column === columnName && x.index === row,
      )?.option ?? aux
    );
  }

  removeLine(index: number) {
    this.rows.splice(index, 1);
    this.rowDescriptions.pop();

    this.handleUpdateValue();
  }

  addLine() {
    const form = this.formBuilder.group({});
    Object.keys(this.defaultForm?.controls).forEach((key) => {
      form.addControl(
        key,
        new FormControl(
          this.defaultForm?.controls[key].value,
          this.defaultForm?.controls[key].validator,
        ),
      );
    });
    form.updateValueAndValidity();
    this.rows.push(form);
    this.rowDescriptions.push(`Item ${this.rows.length}`);
    this.forceChanges();
    this.handleUpdateValue();
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
