<div class="grid-items">
  <div
    *ngFor="let subcategory of subcategoriesList"
    class="grid-content"
    (click)="handleDataClick(subcategory.subcategoryId)"
  >
    <label id="id">{{
      util.addLeadingZeros(subcategory.subcategoryId, 5)
    }}</label>
    <label id="name">{{ util.formatName(subcategory.name, 30) }}</label>
    <div id="status">
      <app-status
        [color]="
          subcategory.active ? StatusColorEnum.success : StatusColorEnum.warning
        "
        [image]="
          subcategory.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="subcategory.active ? 'Ativo' : 'Inativo'"
      >
      </app-status>
    </div>
  </div>
</div>
