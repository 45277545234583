<div *ngIf="showModal">
  <div class="modal-container">
    <header >
      <div class="circle">
        <img src="{{ imagePath }}" />
      </div>
      <div class="text-container">
        <h2>{{ title }}</h2>
      </div>
    </header>
    <div class="content">
    <ng-content></ng-content>
    </div>
  </div>
  <div class="overlay" (click)="outsideClick()"></div>
</div>
