<app-infobox [type]="InfoboxTypeEnum.success">
  <div content>
    <span
      >A Raízen está comprometida em resguardar a sua privacidade e proteger os
      seus dados pessoais. Se você quiser saber mais sobre como usamos e
      protegemos os seus dados, quais são os seus direitos e como você pode
      exercê-los, por favor consulte a nossa
      <app-buttons
        label="Política de Privacidade"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="true"
        (clickButton)="onLGPD()"
      ></app-buttons
    ></span>
  </div>
</app-infobox>
