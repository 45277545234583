import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { UniformMaintenceWarehouseTeamModel } from 'src/app/services/dfdv/general-costs/models/maintence-warehouse/uniform-maintenceWarehouse.model';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { GeneralCostUtils } from '../general-cost.utils';

@Component({
  selector: 'app-maintence-and-warehouse-team-uniform',
  templateUrl: './maintence-and-warehouse-team-uniform.component.html',
  styleUrls: ['./maintence-and-warehouse-team-uniform.component.scss'],
})
export class MaintenanceAndWarehouseTeamUniformComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() hasError: boolean = false;
  @Input() harvestMonths: number = 0;
  @Input() shouldDisableFields: boolean = false;
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  public uniformMaintenceWarehouseTeamModel = this.formBuilder.group({
    rows: this.formBuilder.array([this.buildRow()]),
  });

  // Mobile Control
  public card: boolean = false;
  public widthBreakpoint: number = 768;
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  public height: string = '2.5rem';
  public numberActionsWidth: string = '2rem';
  public eMaskType = MaskTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;

  public rowList: string[] = [
    'Calça (com faixa)',
    'Camisa (com faixa)',
    'Botina',
    'Luva de Raspa / Grafatex',
    'Óculos de Segurança',
    'Moletom de frio',
  ];
  public utils: GeneralCostUtils = new GeneralCostUtils();
  public totalMonth = 0;
  public header: string[] = [
    'DESCRIÇÃO',
    'QUANTIDADE',
    'CUSTO UNITÁRIO',
    'CUSTO ANUAL',
    'CUSTO MENSAL',
  ];
  public totalHarvest = 0;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.card = window.innerWidth < this.widthBreakpoint;
    });
    this.buildTable();
  }

  buildTable() {
    Object.keys(new UniformMaintenceWarehouseTeamModel()).forEach(() => {
      this.uniformMaintenceWarehouseTeamModel.controls.rows.push(
        this.buildRow(),
      );
    });
    this.uniformMaintenceWarehouseTeamModel.controls.rows.removeAt(0);
  }

  calcTotal(harvest: boolean) {
    let total = 0;
    this.uniformMaintenceWarehouseTeamModel.controls.rows.controls.forEach(
      (element) => {
        const cost = element.get('cost')?.value ?? '0';
        const quantity = element.get('quantity')?.value ?? '0';
        let result = 0;
        if (harvest) {
          result = +cost * +quantity;
        } else {
          result = (+cost * +quantity) / this.harvestMonths;
        }
        total += result;
      },
    );

    return total;
  }

  buildRow() {
    return this.formBuilder.group({
      quantity: [''],
      cost: [''],
    });
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
