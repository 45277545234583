<div class="card-container">
  <div *ngFor="let item of operations" class="card-content" (click)="navigate(item.operationId)">
    <div class="id">
      <label>{{ util.addLeadingZeros(item.operationId, 5) }}</label>
    </div>
    <div class="items">
      <label class="name">{{ item.name.toUpperCase() }}</label>
      <div id="status">
        <app-status
          [color]="item.active ?  StatusColorEnum.success : StatusColorEnum.warning"
          [image]="
            item.active
              ? '../../../../../../assets/icons/active.svg'
              : '../../../../../../assets/icons/Exclude.svg'
          "
          [status]="item.active ? 'Ativo' : 'Inativo'"
        >
        </app-status>
      </div>
    </div>
  </div>
</div>
