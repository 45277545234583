import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HistoryFieldChange } from 'src/app/common/models/history-field-change-model';
import { AuthService } from 'src/app/common/services/auth.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { Utils } from 'src/app/common/utils/utils';
import { GetSolicitationHistoryModel } from 'src/app/services/solicitation/models/get-solicitation-history.model';
import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { HistoryItemTypeEnum } from 'src/app/shared/history/enums/history-item-type.enum';

@Component({
  selector: 'app-solicitation-history',
  templateUrl: './solicitation-history.component.html',
  styleUrls: ['./solicitation-history.component.scss'],
})
export class SolicitationHistoryComponent implements OnInit {
  public date: Date = new Date();
  public id!: string | null;
  public username!: string;
  public solicitationHistories: GetSolicitationHistoryModel[] = [];
  public isLoaded: boolean = false;
  public get HistoryItemTypeEnum() {
    return HistoryItemTypeEnum;
  }
  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }
  public eButtonsColor = ButtonColorEnum;
  public eButtonsType = ButtonsType;

  constructor(
    private solicitationService: SolicitationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    public utils: Utils,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.username = this.storageService.getUser();
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (params.get('id') !== null) {
        this.getSolicitationHistories(this.id!);
      }
    });
  }

  getSolicitationHistories(id: string) {
    this.solicitationService
      .getSolicitationHistoriesById(id, this.username)
      .subscribe({
        next: this.handleGetSolicitationHistoriesSuccess.bind(this),
        error: this.GetSolicitationHistoriesError.bind(this),
      });
  }

  handleGetSolicitationHistoriesSuccess(res: GetSolicitationHistoryModel[]) {
    this.solicitationHistories.push(...res);
    this.isLoaded = true;
  }

  GetSolicitationHistoriesError(res: HttpErrorResponse) {
    if (res.status === 404) { this.authService.redirectToAccessDenied(); }
  }

  getAllChangesFromHistory(
    history: GetSolicitationHistoryModel,
  ): HistoryFieldChange[] {
    const changes = new Array<HistoryFieldChange>();
    if (history.negociatedValueOld != null && history.negociatedValueOld) {
      changes.push({
        field: 'Valor negociado',
        from: `R$${this.utils.numberFormatter(history.negociatedValueOld, 2, 2)}`,
        to: `R$${this.utils.numberFormatter(history.negociatedValueNew, 2, 2)}`,
      });
    }
    if (history.titleOld != null && history.titleNew) {
      changes.push({
        field: 'Título',
        from: history.titleOld,
        to: history.titleNew,
      });
    }
    if (history.descriptionOld != null && history.descriptionNew) {
      changes.push({
        field: 'Descrição',
        from: history.descriptionOld,
        to: history.descriptionNew,
      });
    }
    if (history.categoryNameOld != null && history.categoryNameNew) {
      changes.push({
        field: 'Categoria',
        from: history.categoryNameOld,
        to: history.categoryNameNew,
      });
    }
    if (history.subcategoryNameOld != null && history.subcategoryNameNew) {
      changes.push({
        field: 'Subcategoria',
        from: history.subcategoryNameOld,
        to: history.subcategoryNameNew,
      });
    }
    if (history.paymentTypeNameOld != null && history.paymentTypeNameNew) {
      changes.push({
        field: 'Tipo de pagamento',
        from: history.paymentTypeNameOld,
        to: history.paymentTypeNameNew,
      });
    }
    if (
      history.EquipmentUnitaryValueOld != null
      && history.EquipmentUnitaryValueOld
    ) {
      changes.push({
        field: 'Valor Unitário',
        from: `R$${this.utils.numberFormatter(history.EquipmentUnitaryValueOld, 2, 2)}`,
        to: `R$${this.utils.numberFormatter(history.EquipmentUnitaryValueNew, 2, 2)}`,
      });
    }
    if (history.EquipmentQuantityOld != null && history.EquipmentQuantityOld) {
      changes.push({
        field: 'Quantidade de Equipamentos',
        from: `R$${this.utils.numberFormatter(history.EquipmentQuantityOld, 2, 2)}`,
        to: `R$${this.utils.numberFormatter(history.EquipmentQuantityNew, 2, 2)}`,
      });
    }
    if (history.recurrenceSolicitationOld != null) {
      changes.push({
        field: 'Solicitação Recorrente',
        from: history.recurrenceSolicitationOld ? 'SIM' : 'NÃO',
        to: history.recurrenceSolicitationNew ? 'SIM' : 'NÃO',
      });
    }
    if (history.recurrenceMonthsNew > 0) {
      changes.push({
        field: 'Quant. Meses Recorrência',
        from: history.recurrenceMonthsOld ? history.recurrenceMonthsOld.toString() : '0',
        to: history.recurrenceMonthsNew.toString(),
      });
    }
    return changes;
  }

  handleReturnClick() {
    this.router.navigate(['/solicitation']);
  }
}
