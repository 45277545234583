/* eslint-disable max-len */
import {
  Component, Input, OnChanges,
  SimpleChanges
} from '@angular/core';
import { Utils } from 'src/app/common/utils/utils';
import { FleetProfitModel } from 'src/app/services/dfdv/fleet/models/fleet-profit.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';

@Component({
  selector: 'app-fleet-table-profit',
  templateUrl: './fleet-table-profit.component.html',
  styleUrls: ['./fleet-table-profit.component.scss'],
})
export class FleetTableProfitComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() profitList: FleetProfitModel[] = [];
  @Input() rowStatus: FleetTableRowStatusEnum[] = [];

  public profitHeader: string[] = [
    'ATIVO/PARADO',
    'LUCRO VARIÁVEL',
    'LUCRO FIXO',
    'LUCRO TOTAL',
  ];

  public profitContent: string[][] = [];

  public profitActiveContent: string[] = [];

  public profitStopedContent: string[] = [];

  public profitRowStatus: FleetTableRowStatusEnum[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['profitList']) {
      this.buildTable();
    }
  }

  constructor(public utils: Utils) {
  }


  buildTable() {
    const columnTotalsActive = Array(this.profitHeader.length - 1).fill(0);
    const columnTotalsStopped = Array(this.profitHeader.length - 1).fill(0);

    if (!this.profitList?.length) {
      const initialValues = Array(this.profitHeader.length - 1).fill('-');
      this.profitActiveContent = initialValues;
      this.profitStopedContent = initialValues;
      return;
    }

    this.profitContent = [];
    this.profitActiveContent = [];
    this.profitStopedContent = [];
    this.profitRowStatus = [];
    this.profitRowStatus = this.rowStatus;

    const keys = ['variableProfit', 'fixedProfit', 'totalProfit'];

    this.profitList.forEach((x, index) => {
      if (this.profitRowStatus[index] === FleetTableRowStatusEnum.ACTIVE) {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsActive[i] += x[key];
          }
        });
      } else {
        keys.forEach((key, i) => {
          if (typeof x[key] === 'number') {
            columnTotalsStopped[i] += x[key];
          }
        });
      }

      this.profitContent.push([
        (index + 1).toString(),
        this.utils.toMoney(x.variableProfit.toString()),
        this.utils.toMoney(x.fixedProfit.toString()),
        this.utils.toMoney(x.totalProfit.toString()),
      ]);
    });

    const activeItemCount = this.profitRowStatus.filter((x) => x === FleetTableRowStatusEnum.ACTIVE).length;
    const columnActiveAverages = columnTotalsActive.map((total) => (activeItemCount > 0 ? total / activeItemCount : 0));
    this.profitActiveContent = columnActiveAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));

    for (let i = 0; i < this.profitActiveContent.length; i++) {
      if(this.profitActiveContent[i] !== '-') {
        this.profitActiveContent[i] = this.utils.toMoney(this.profitActiveContent[i]);
      }
    }

    const stoppedItemCount = this.profitRowStatus.filter((x) => x === FleetTableRowStatusEnum.STOPED).length;
    const columnStoppedAverages = columnTotalsStopped.map((total) => (stoppedItemCount > 0 ? total / stoppedItemCount : 0));
    this.profitStopedContent = columnStoppedAverages.map((avg) => (avg === 0 ? '-' : avg.toFixed(2)));

    for (let i = 0; i < this.profitStopedContent.length; i++) {
      if(this.profitStopedContent[i] !== '-') {
        this.profitStopedContent[i] = this.utils.toMoney(this.profitStopedContent[i]);
      }
    }
  }
}
