<section title="{{ userName }} | {{ userId }} | {{ userRole }}">
  <header *ngIf="text">
    <label class="text">{{ text }}</label>
  </header>
  <main>
    <div class="icon">
      <img src="../../../assets/icons/logged-user.svg" alt="Usuário Logado" />
    </div>
    <div class="user-info">
      <label class="user-name">{{ userName }}</label>
      <div class="user-id-role">{{ userId }} {{ pipeText }} {{ userRole }}</div>
    </div>
  </main>
</section>
