import {
  AfterViewChecked, Component, OnDestroy, OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import * as DfdvActions from '../../../common/store/dfdv/dfdv.actions';
import { DfdvCardModel } from './models/dfdv-card.model';

@Component({
  selector: 'app-dfdv-summarize',
  templateUrl: './dfdv-summarize.component.html',
  styleUrls: ['./dfdv-summarize.component.scss'],
})
export class DfdvSummarizeComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  public cards: DfdvCardModel[] = [];
  public names: string[] = ['Configurações'];
  public sidenav: boolean = false;
  public id: string | null = null;
  public dfdv!: GetDfdvModel;
  public selectedMenu: number = 0;
  public displaySidenav: boolean = false;
  public dfdvLoading: boolean = false;
  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) { }

  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngAfterViewChecked(): void {
    this.sidenav = this.route.firstChild?.snapshot !== undefined;
  }

  ngOnInit(): void {
    this.generateModel();
    this.unSubscribe = this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      this.loadingStore$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isLoading) => {
          this.dfdvLoading = isLoading.loading;
        });
      if (!this.dfdvLoading) {
        this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
          if (!x.dfdvId || x.dfdvId !== +(this.id ?? 0)) {
            this.store.dispatch(
              DfdvActions.loadGetDfdvModel({ id: this.id ?? '0' }),
            );
          }
        });
      }
    });
    this.sidenav = this.route.firstChild?.snapshot !== undefined;

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };
    });
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateModel() {
    this.names.forEach((element) => {
      this.cards.push({
        name: element,
        icon: this.getRegisterIcon(element),
      });
    });
  }

  getRegisterIcon(value: string) {
    switch (value) {
      case 'Configurações':
        return '../../../assets/icons/settings-primary-600.svg';
      default:
        return '';
    }
  }

  navigateToDataBase() {
    this.router.navigate([`/dfdv/${this.id}/database`]);
  }

  navigateToGeneralCost() {
    this.router.navigate([`/dfdv/${this.id}/general-cost`]);
  }

  navigateToVariableCost() {
    this.router.navigate([`/dfdv/${this.id}/variable-cost`]);
  }

  navigateToOperationalTeam() {
    this.router.navigate([`/dfdv/${this.id}/operational-team`]);
  }

  navigateToSupportTeam() {
    this.router.navigate([`/dfdv/${this.id}/support-team`]);
  }

  navigateToFleet() {
    this.router.navigate([`/dfdv/${this.id}/fleet`]);
  }

  navigateToDriversFactor() {
    this.router.navigate([`/dfdv/${this.id}/driver-factor`]);
  }

  navigateToCharges() {
    this.router.navigate([`/dfdv/${this.id}/charge`]);
  }

  navigateToTaxes() {
    this.router.navigate([`/dfdv/${this.id}/tax`]);
  }

  navigateToBenefits() {
    this.router.navigate([`/dfdv/${this.id}/benefit`]);
  }

  navigateToProfit() {
    this.router.navigate([`/dfdv/${this.id}/profit`]);
  }

  navigateToScope() {
    this.router.navigate([`/dfdv/${this.id}/scope`]);
  }

  navigateToRvParameter() {
    this.router.navigate([`/dfdv/${this.id}/rv-parameter`]);
  }

  navigateToSummary() {
    this.router.navigate([`/dfdv/${this.id}/summary`]);
  }

  navigateToPlant() {
    this.router.navigate([`/dfdv/${this.id}/plant`]);
  }

  navigateToResume() {
    this.router.navigate([`/dfdv/${this.id}/resume`]);
  }

  navigateToTable() {
    this.router.navigate([`/dfdv/${this.id}/table`]);
  }

  notifyDisplay(display: boolean) {
    this.displaySidenav = display;
  }
}
