<div class="container">
  <section>
    <header>
      <img src="./././assets/images/empty-truck.svg" alt="Lista Vazia" />
    </header>
    <main>
      <label class="title">Opssssssss!</label>
      <label class="description">Nenhum resultado foi encontrado.</label>
    </main>
  </section>
</div>
