/* eslint-disable no-param-reassign */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { Utils } from 'src/app/common/utils/utils';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { ProfitModel } from 'src/app/services/profit/models/profit.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss'],
})
export class ProfitComponent implements OnInit, OnDestroy {
  // Enums
  public eButtonsType = ButtonsType;
  public eButtonsColor = ButtonColorEnum;
  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Form
  public profitForm = this.formBuilder.group({
    profitValue: '',
    fixedProfit: '',
    variableProfit: '',
  });

  // Aux
  public isUserRaizen: boolean = false;
  public totalFixed: number = 0;
  public totalVariable: number = 0;
  public dfdv!: GetDfdvModel;
  public dfdvLoading: boolean = false;
  public dfdvUtils!: DfdvUtils;

  public isMobile: boolean = false;
  public breakpointBase: number = 767;
  public widthBreakpoint: number = 0;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDevice();
  }

  constructor(
    public formBuilder: FormBuilder,
    private util: Utils,
    private storageService: StorageService,
    private route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {
    this.widthBreakpoint = this.util.calcWidth(this.breakpointBase);
  }

  profitStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.profit));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  checkDevice() {
    this.isMobile = this.getWindowInnerWidth() <= this.widthBreakpoint;
  }

  getWindowInnerWidth() {
    return window.innerWidth;
  }

  ngOnInit(): void {
    this.checkDevice();
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.profitStore$.subscribe((profit) => {
      if (!profit) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' })
                );
              }
            });
          }
        });
      } else {
        this.populateForm(profit);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        this.isUserRaizen = this.dfdvUtils.isUserRaizen();
      }
    });

    this.profitForm.controls.profitValue.valueChanges.subscribe((value) => {
      this.checkAndCorrectValue(
        this.profitForm.controls.profitValue,
        value ?? '0'
      );
      this.calculateTotals();
    });

    this.profitForm.controls.fixedProfit.valueChanges.subscribe((value) => {
      this.checkAndCorrectValue(
        this.profitForm.controls.fixedProfit,
        value ?? '0'
      );
      this.calculateTotals();
    });

    this.profitForm.controls.variableProfit.valueChanges.subscribe((value) => {
      this.checkAndCorrectValue(
        this.profitForm.controls.variableProfit,
        value ?? '0'
      );
      this.calculateTotals();
    });
  }

  populateForm(profit: ProfitModel) {
    Object.keys(this.profitForm.controls).forEach((key) => {
      this.profitForm.get(key)?.patchValue(profit[key]?.toString());
    });

    this.calculateTotals();
  }

  populateModel(profit: ProfitModel) {
    Object.keys(this.profitForm.controls).forEach((key) => {
      profit[key] = +this.profitForm.get(key)?.value ?? 0;
    });

    profit.totalFixedProfit = this.totalFixed;
    profit.totalVariableProfit = this.totalVariable;
  }

  checkAndCorrectValue(control: AbstractControl, value: string): void {
    if (Number(value) > 100) {
      control.patchValue('100');
    }
  }

  calculateTotals(): void {
    this.totalFixed =
      Math.round(
        Number(this.profitForm.controls.fixedProfit.value) *
          Number(this.profitForm.controls.profitValue.value)
      ) / 100;
    this.totalVariable =
      Math.round(
        Number(this.profitForm.controls.variableProfit.value) *
          Number(this.profitForm.controls.profitValue.value)
      ) / 100;
  }

  shouldDisableField(isOplField: boolean) {
    const status = this.dfdv?.dfdvStatusName;
    if (!status) {
      return true;
    }
    if (
      this.isUserRaizen
      && (status === DfdvStatusEnum.New || status === DfdvStatusEnum.Draft)
    ) {
      return false;
    }
    if (status === DfdvStatusEnum.Preliminary) {
      return !isOplField !== this.isUserRaizen;
    }
    return true;
  }

  onFocusOut(type: string) {
    let primaryControl: AbstractControl;
    let secondaryControl: AbstractControl;

    if (type === 'fixed') {
      primaryControl = this.profitForm.controls.fixedProfit;
      secondaryControl = this.profitForm.controls.variableProfit;
    } else if (type === 'variable') {
      primaryControl = this.profitForm.controls.variableProfit;
      secondaryControl = this.profitForm.controls.fixedProfit;
    } else {
      return;
    }

    const primaryValue = Number(primaryControl.value);
    if (primaryValue > 100) {
      primaryControl.patchValue('100');
      secondaryControl.patchValue('0');
      return;
    }
    primaryControl.patchValue(
      (Math.round(primaryValue * 100) / 100).toString()
    );
    secondaryControl.patchValue(
      (Math.round((100 - primaryValue) * 100) / 100).toString()
    );

    this.updateDFDVStore();
  }

  updateDFDVStore() {
    const profit = new ProfitModel();
    this.populateModel(profit);



    this.dfdv.profit = profit;

    this.store.dispatch(
      DfdvActions.updateSubobject({
        subObjectName: 'profit',
        data: this.dfdv.profit,
      })
    );
  }
}
