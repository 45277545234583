export class DriversFactorModel {
  [key: string]: any;

  isUpdated: boolean = false;
  workingDays!: number;
  totalWorkingDays!: number;
  workingHours!: number;
  overtimeHours!: number;
  standardDriverInIntinereHours!: number;
  nightDriverInIntinereHours!: number;
  numberOfShifts!: number;
  driversPerShift!: number;
  standardDriverTeamFactor!: number;
  nightDriverTeamFactor!: number;
  standardDriverManeuveringTeamFactor!: number;
  nightDriverManeuveringTeamFactor!: number;
  foodTicketContribution!: number;
  collectiveBargainingAgreement!: number;
  collectiveAgreement!: number;
  driverSalary!: number;
  dsr!: number;
  toxicologicalExam!: number;
  paymentTermAddition!: number;
}
