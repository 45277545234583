<div class="card-content" (click)="handleDataClick(analyst.analystId)">
  <div class="id">
    <label>{{ analyst.analystId }}</label>
  </div>
  <div class="items">
    <div class="name-email">
      <div class="name">
        <p>{{ analyst.name }}</p>
      </div>
      <div class="email">
        <img src="../../../../../../assets/icons/arroba.svg" alt="email" />
        {{ analyst.email }}
      </div>
    </div>
    <div class="role">
      <img src="../../../../../../assets/icons/suitcase.svg" alt="role" />
      {{ analyst.analystRoleName }}
    </div>
    <div id="status">
      <app-status
        [color]="analyst.active ? StatusColorEnum.success : StatusColorEnum.warning"
        [image]="
          analyst.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
        [status]="analyst.active ? 'Ativo' : 'Inativo'"
      >
      </app-status>
    </div>
  </div>
</div>
