<section [ngClass]="{ top: top, bottom: bottom, left: left, right: right }">
  <div class="icon">
    <img src="../../../assets/icons/question-purple.svg" alt="tooltip" />
  </div>
  <div class="tooltip-container">
    <div class="tooltip">
      <ng-content></ng-content>
    </div>
    <div class="square"></div>
  </div>
</section>
