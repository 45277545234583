import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { DfdvDetailModel } from 'src/app/services/dfdv/models/dfdv-detail.model';
import { GetDfdvFilterModel } from 'src/app/services/dfdv/models/get-dfdv-filter.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { ModalModel } from '../../pre-registration/models/modal.model';
import { ModalConfirmationModel } from '../dfdv-sumarize/sumarize-header/models/modal-confirmation.model';

@Component({
  selector: 'app-dfdv-list',
  templateUrl: './dfdv-list.component.html',
  styleUrls: ['./dfdv-list.component.scss'],
})
export class DfdvListComponent implements OnInit, OnChanges {
  @Input() required: boolean = true;
  @Input() dfdvsDetail: DfdvDetailModel[] = [];
  @Input() filters: GetDfdvFilterModel | undefined;
  @Input() userRole: string = '';
  @Input() isLoading!: boolean;
  @Input() error!: boolean;
  @Input() page!: number;
  @Input() totalPages!: number;

  @Output() redirect: EventEmitter<boolean> = new EventEmitter();
  @Output() setPageCallback: EventEmitter<number> = new EventEmitter();
  @Output() reloadCallback: EventEmitter<boolean> = new EventEmitter();

  public allDfdvsChecked: boolean = false;

  public isUserRaizen: boolean = false;
  public modalTitle: string = '';
  public buttonLabel: string = '';
  public buttonImage: string = '';
  public showModal: boolean = false;
  public eButtonsType = ButtonsType;
  public buttonType!: ButtonsType;
  public ebuttonColor = ButtonColorEnum;
  public buttonColor!: ButtonColorEnum;
  public approval: boolean = true;
  public approvalVisible: boolean = false;
  public selectedDfdv: DfdvDetailModel[] = [];
  public username!: string;
  public loading: boolean = false;
  public loadingApproveBID: boolean = false;
  public loadingReproveBID: boolean = false;
  public enabledReport: boolean = true;
  public showConfirmation: boolean = true;
  public modalConfirmationType: string = '';
  public modalConfirmationTitle: string = '';
  public modalConfirmationDescription: string = '';

  public modalModel = new ModalModel();
  public modalConfirmationModel = new ModalConfirmationModel();

  public checkBoxLabel: boolean = true;
  public widthBreakpoint: number = 576;

  get enableApproveAndReproveBID() { return this.selectedDfdv.length > 0}

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private dfdvService: DfdvService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.AppearLabelCheckbox();
  }

  AppearLabelCheckbox() {
    this.checkBoxLabel = window.innerWidth > this.widthBreakpoint;
  }

  ngOnInit(): void {
    this.checkDfdvStatus();
    this.AppearLabelCheckbox();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      this.removeAllChecks();
    }

    this.checkDfdvStatus();
  }

  checkDfdvStatus() {
    if (this.userRole.includes('Admin') || this.userRole.includes('Analista')) {
      this.isUserRaizen = true;
      this.approvalVisible = this.hasDFDVstatusPending();
      this.username = this.storageService.getUser();
    }
  }

  checkAll() {
    this.dfdvsDetail = this.dfdvsDetail.map((dfdv) => ({
      ...dfdv,
      checked: dfdv.dfdvStatusName === DfdvStatusEnum.Pending,
    }));

    this.selectedDfdv = this.dfdvsDetail.filter((dfdv) => dfdv.checked);
  }

  removeAllChecks() {
    this.dfdvsDetail = this.dfdvsDetail.map((dfdv) => ({
      ...dfdv,
      checked: false,
    }));

    this.selectedDfdv = [];
  }

  allDfdvIsChecked() {
    return this.dfdvsDetail.some(
      (dfdv) => dfdv?.dfdvStatusName !== DfdvStatusEnum.Canceled && dfdv.checked
    );
  }

  checkItem(dfdvDetail: DfdvDetailModel) {
    const dfdvExists = this.selectedDfdv.find(
      (dfdv) => dfdv.dfdvId === dfdvDetail.dfdvId
    );
    if (dfdvExists === undefined) {
      this.selectedDfdv.push(dfdvDetail);
    }
  }

  removeCheck(dfdvDetail: DfdvDetailModel) {
    const dfdvExists = this.selectedDfdv.find(
      (dfdv) => dfdv.dfdvId === dfdvDetail.dfdvId
    );
    if (dfdvExists) {
      this.selectedDfdv = this.selectedDfdv.filter((el) => el !== dfdvDetail);
    }
  }

  setPageCallbackFunc(index: number) {
    this.setPageCallback.emit(index);
    this.removeAllChecks();
  }

  reload() {
    this.reloadCallback.emit(true);
  }

  clickModal() {
    this.modalModel.showModal = false;
    if (this.modalModel.success) {
      this.redirect.emit(true);
    }
  }

  hasDFDVstatusPending() {
    return this.dfdvsDetail
      .filter((dfdv) => dfdv.dfdvStatusName === DfdvStatusEnum.Pending).length > 0;
  }

  handleApproveBID() {
    const isMultiIds = this.selectedDfdv.length > 1;
    this.modalConfirmationType = 'approve';
    this.modalConfirmationModel.showModal = true;
    this.modalConfirmationModel.title = isMultiIds
      ? 'Tem certeza que deseja aprovar essas DFDVs?'
      : 'Tem certeza que deseja aprovar essa DFDV?';
    this.modalConfirmationModel.description = isMultiIds
      ? 'Ao prosseguir, essas DFDVs serão arquivadas e novas DFDVs serão criadas para participação no próximo BID.'
      : 'Ao prosseguir, essa DFDV será arquivada e uma nova DFDV será criada para participação no próximo BID.';
    this.modalConfirmationModel.confirmLabel = 'Confirmar';
    this.modalConfirmationModel.confirmIcon = 'assets/icons/approve-common-100.svg';
    this.modalConfirmationModel.confirmColor = ButtonColorEnum.success;
  }

  handleReproveBID() {
    const isMultiIds = this.selectedDfdv.length > 1;
    this.modalConfirmationType = 'reprove';
    this.modalConfirmationModel.showModal = true;
    this.modalConfirmationModel.title = isMultiIds
      ? 'Tem certeza que deseja reprovar essas DFDVs?'
      : 'Tem certeza que deseja reprovar essa DFDV?';
    this.modalConfirmationModel.description = isMultiIds
      ? 'Ao prosseguir, as Empresas referente a essas DFDVs não participarão mais do BID.'
      : 'Ao prosseguir, a Empresa referente a essa DFDV não participará mais do BID.';
    this.modalConfirmationModel.confirmLabel = 'Prosseguir';
    this.modalConfirmationModel.confirmIcon = 'assets/icons/reprove-common-100.svg';
    this.modalConfirmationModel.confirmColor = ButtonColorEnum.warning;
  }

  handleConfirm() {
    if (this.modalConfirmationType === 'approve')
      this.handleApproveBIDDFDVs();
    if (this.modalConfirmationType === 'reprove')
      this.handleReproveBIDDFDVs();
  }

  handleCancel() {
    this.modalConfirmationType = '';
    this.modalConfirmationModel = new ModalConfirmationModel();
  }

  handleApproveBIDDFDVs() {
    this.loadingApproveBID = true;
    const dfdvIDs = this.selectedDfdv.map((dfdv) => dfdv.dfdvId);
    const isMultiIds = dfdvIDs.length > 1;

    this.dfdvService.approveBID(dfdvIDs)
      .pipe(finalize(() => {
        this.loadingApproveBID = false;
      }))
      .subscribe({
        next: () => {
          this.modalModel.success = true;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.title = isMultiIds
            ? 'DFDVs aprovadas com sucesso!'
            : 'DFDV aprovada com sucesso!';
          this.modalModel.description = isMultiIds
            ? 'Essas DFDVs foram arquivadas. Novas DFDVs criadas para participação no próximo BID.'
            : 'Essa DFDV foi arquivada. Nova DFDV criada para participação no próximo BID.';
          this.modalModel.imagePath = 'assets/icons/success-600.gif';
          this.modalModel.showModal = true;
        },
        error: (e) => {
          this.modalModel.success = false;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.title = isMultiIds ? 'Não foi possível aprovar as DFDVs.' : 'Não foi possível aprovar a DFDV.';
          this.modalModel.description = e.status >= 500
            ? 'Tente novamente mais tarde ou entre em contato com algum administrador.'
            : e.message;
          this.modalModel.imagePath = 'assets/icons/warning-600.gif';
          this.modalModel.showModal = true;
        },
      });
  }

  handleReproveBIDDFDVs() {
    this.loadingApproveBID = true;
    const dfdvIDs = this.selectedDfdv.map((dfdv) => dfdv.dfdvId);
    const isMultiIds = dfdvIDs.length > 1;

    this.dfdvService.reproveBID(dfdvIDs)
      .pipe(finalize(() => {
        this.loadingApproveBID = false;
      }))
      .subscribe({
        next: () => {
          this.modalModel.success = true;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.title = isMultiIds
            ? 'DFDVs reprovadas com sucesso!'
            : 'DFDV reprovada com sucesso!';
          this.modalModel.description = isMultiIds
            ? 'As Empresas referente a essas DFDVs não participarão mais do BID.'
            : 'A Empresa referente a essa DFDV não participará mais do BID.';
          this.modalModel.imagePath = 'assets/icons/success-600.gif';
          this.modalModel.showModal = true;
        },
        error: (e) => {
          this.modalModel.success = false;
          this.modalModel.buttonLabel = 'Entendi';
          this.modalModel.title = isMultiIds
            ? 'Não foi possível reprovar as DFDVs.'
            : 'Não foi possível reprovar a DFDV.';
          this.modalModel.description = e.status >= 500
            ? 'Tente novamente mais tarde ou entre em contato com algum administrador.'
            : e.message;
          this.modalModel.imagePath = 'assets/icons/warning-600.gif';
          this.modalModel.showModal = true;
        },
      });
  }

}
