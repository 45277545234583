<div class="container">
  <section>
    <header>
      <app-primary-button
        label="NOVA SOLICITAÇÃO"
        icon="../../../../assets/icons/add-common-100.svg"
        [color]="ButtonColorEnum.primary"
        [right]="false"
        [loading]="false"
        [enabled]="true"
        (click)="navigateToNewSolicitation()"
      ></app-primary-button>
    </header>
    <app-solicitation-filter
      (callbackFunc)="getSolicitationsFiltered($event)"
      (callbackFilters)="handleSolicitationsCallBack($event)"
      (userRole)="setUserRole($event)"
      [isLoadingFilter]="isLoadingFilter"
      [loggedUser]="loggedUser"
    ></app-solicitation-filter>
    <app-solicitation-list
      #list
      [filters]="filters"
      [solicitacoes]="solicitations"
      [userRole]="userRole"
      [required]="requiredJustify"
      (redirect)="navigateBack($event)"
      (setPageCallback)="setPage($event)"
      (reloadCallback)="reload()"
      [isLoading]="isLoading"
      [error]="error"
      [page]="page"
      [totalPages]="totalPages"
    ></app-solicitation-list>
  </section>
</div>
