import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { CategoryService } from 'src/app/services/category/category.service';
import { GetCategoryModel } from 'src/app/services/category/models/get-category-model';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  public categories: GetCategoryModel[] = [];
  public isLoading: boolean = true;
  public error: boolean = false;

  constructor(private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.categoryService.getCategories().subscribe({
      next: this.handleGetCategoriesSuccess.bind(this),
      error: this.handleGetCategoriesError.bind(this),
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  handleGetCategoriesSuccess(res: GetCategoryModel[]) {
    this.categories.push(...res);
  }

  handleGetCategoriesError(res: HttpErrorResponse) {
    this.error = true;
    this.isLoading = false;
  }

  reload() {
    this.isLoading = true;
    this.getCategories();
  }
}
