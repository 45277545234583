/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import {
  IDfdvState,
  ILoadingState,
} from 'src/app/common/store/dfdv/dfdv.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { Utils } from 'src/app/common/utils/utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { CeillingRangeDistributionModel } from 'src/app/services/dfdv/rv-parameter/model/ceilingRangeDistribution.model';
import { RvParameterModel } from 'src/app/services/dfdv/rv-parameter/model/rv-parameter.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ColumnTypeEnum } from 'src/app/shared/dynamic-table/Enum/columnTypeEnum';
import { DynamicTableComponent } from 'src/app/shared/dynamic-table/dynamic-table.component';
import { InputColumnModel } from 'src/app/shared/dynamic-table/model/InputColumnModel';
import { ColumnModel } from 'src/app/shared/dynamic-table/model/columnModel';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';

@Component({
  selector: 'app-rv-parameter',
  templateUrl: './rv-parameter.component.html',
  styleUrls: ['./rv-parameter.component.scss'],
})
export class RvParameterComponent implements OnInit, OnDestroy {
  public shouldDisableFields: boolean = false;
  public showActions: boolean = false;
  public hourlySalaryNightDriver: number = 0;
  public nightDriverHours: number = 220;
  public hourlySalaryStandardDriver: number = 0;
  public standardDriverHours: number = 220;
  public totalMonthlyHoursOfQuantitativeItems: number = 0;
  public totalMonthlyHoursOfQualitativeItems: number = 0;
  public totalMonthlyHours: number = 0;
  public ceilingRangeDistributionWeighting: number = 0;
  public quantitativeItems: FormGroup[] = [];
  public qualitativeItems: FormGroup[] = [];
  public ceillingDistributionForDriversForm: FormGroup[] = [];
  public rowDescriptionCeillingDistribution: string[] = [];
  public dfdv!: GetDfdvModel;
  public dfdvLoading: boolean = false;
  public dfdvUtils!: DfdvUtils;

  public defaultFormBuilder = this.formBuilder.group({
    description: new FormControl(''),
    monthlyHours: new FormControl(''),
  });

  public columnModel: ColumnModel[] = [];
  public columnModelCeillingDistribution: ColumnModel[] = [];

  // DFDV
  public dfdvModel?: GetDfdvModel;

  // Enums
  public ButtonColorEnum = ButtonColorEnum;
  public DfdvStatusEnum = DfdvStatusEnum;

  // Variables
  public hasErrorMessage: string = '';
  public modalModel: ModalModel = new ModalModel();
  public rowsDescriptionQuantitative: string[] = [];
  public rowsDescriptionQualitative: string[] = [];
  public errorMessagePercent: string = ' Valor do percentual diferente de 100%.';
  public hasErrorPercent: boolean = false;

  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  @ViewChild('ceilling') ceilling!: DynamicTableComponent;
  @ViewChild('quantitative') quantitative!: DynamicTableComponent;
  @ViewChild('qualitative') qualitative!: DynamicTableComponent;

  constructor(
    private formBuilder: FormBuilder,
    public utils: Utils,
    private storageService: StorageService,
    private route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  rvParameterStore$ = this.store
    .select('dfdv')
    .pipe(map((x) => x.dfdv.rvParameter));
  driverFactoStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.driverFactor));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.unSubscribe = this.rvParameterStore$.subscribe((rvParam) => {
      this.rowsDescriptionQualitative = [];
      this.qualitativeItems = [];
      this.quantitativeItems = [];
      this.ceillingDistributionForDriversForm = [];

      if (!rvParam) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');
          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading.loading;
            });
          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' }),
                );
              }
            });
          }
        });
      } else {
        this.populateForm(rvParam);
      }
    });

    this.driverFactoStore$.subscribe((driverFactor) => {
      if (driverFactor) {
        this.hourlySalaryNightDriver = driverFactor.driverSalary / this.nightDriverHours;
        this.hourlySalaryStandardDriver = driverFactor.driverSalary / this.standardDriverHours;
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        const enableForOPL = !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
        const enableForRaizen = this.dfdvUtils.isUserRaizen() && (this.dfdvUtils.isNew() || this.dfdvUtils.isDraft());
        this.shouldDisableFields = !(enableForOPL || enableForRaizen);
        this.showActions = enableForOPL || enableForRaizen;
        this.setColumnModel();
        this.setColumnModelCeillingDistribution();
      }
    });
  }

  setColumnModel() {
    this.columnModel = [
      {
        name: 'description',
        type: ColumnTypeEnum.input,
        hasTotal: false,
        hasAverage: false,
        inputColumnModel: {
          type: InputTypeEnum.text,
          showNumberActions: false,
          placeholder: 'Texto de descrição',
          isDisabled: this.shouldDisableFields,
        } as InputColumnModel,
      },
      {
        name: 'monthlyHours',
        type: ColumnTypeEnum.input,
        hasTotal: true,
        hasAverage: false,
        inputColumnModel: {
          type: InputTypeEnum.text,
          mask: MaskTypeEnum.decimal,
          showNumberActions: false,
          placeholder: '0,00',
          sideIcon: true,
          sideIconLabel: ',00',
          isDisabled: this.shouldDisableFields,
        } as InputColumnModel,
      },
    ] as ColumnModel[];
  }

  setColumnModelCeillingDistribution() {
    this.columnModelCeillingDistribution = [
      {
        name: 'numberOfDrivers',
        type: ColumnTypeEnum.input,
        totalSuffix: '%',
        totalLeftPadding: 4,
        hasTotal: true,
        hasAverage: false,
        inputColumnModel: {
          type: InputTypeEnum.number,
          mask: MaskTypeEnum.number,
          showNumberActions: false,
          placeholder: '0',
          sideIcon: true,
          sideIconLabel: '%',
          isDisabled: this.shouldDisableFields,
          min: 0,
          max: 100,
        } as InputColumnModel,
      },
    ] as ColumnModel[];
  }

  getDfdvError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));
    this.modalModel.buttonLabel = 'ENTENDI';
    this.modalModel.description = `Houve um erro ao tentar recuperar os dados da DFDV: ${message.message}`;
    this.modalModel.success = false;
    this.modalModel.imagePath = '../../../../assets/icons/warning-600.gif';
    this.modalModel.title = 'Erro';
    this.modalModel.showModal = true;
  }

  populateForm(model: RvParameterModel | null) {
    this.rowsDescriptionQualitative = [];
    this.qualitativeItems = [];
    this.quantitativeItems = [];
    this.ceillingDistributionForDriversForm = [];

    if (model) {
      this.hourlySalaryNightDriver = model.hourlySalaryNightDriver;
      this.hourlySalaryStandardDriver = model.hourlySalaryStandardDriver;
      this.totalMonthlyHours = model.totalMonthlyHours;
      this.ceilingRangeDistributionWeighting = model.ceilingRangeDistributionWeighting;

      if (model.quantitativeItems) {
        model.quantitativeItems.forEach((item, index) => {
          this.rowsDescriptionQuantitative.push(`Item ${index + 1}`);
          this.quantitativeItems.push(
            this.formBuilder.group({
              description: [item?.description],
              monthlyHours: [item?.monthlyHours],
            }),
          );
        });
      }
      if (model.qualitativeItems) {
        model.qualitativeItems.forEach((item, index) => {
          this.rowsDescriptionQualitative.push(`Item ${index + 1}`);
          this.qualitativeItems.push(
            this.formBuilder.group({
              description: [item?.description],
              monthlyHours: [item?.monthlyHours],
            }),
          );
        });
      }

      if (model.ceilingRangeDistribution) {
        Object.keys(model?.ceilingRangeDistribution).forEach((key, index) => {
          this.ceillingDistributionForDriversForm.push(
            this.formBuilder.group({
              numberOfDrivers: [model.ceilingRangeDistribution[key], ''],
            }),
          );
          this.rowDescriptionCeillingDistribution.push(`${index * 10}%`);
        });
      }

      this.ceilling.forceChanges();
      this.quantitative.forceChanges();
      this.qualitative.forceChanges();
    }
  }

  isFormValid(form: FormGroup) {
    let isValid = true;
    form.updateValueAndValidity();
    Object.keys(form.controls).forEach((key) => {
      form.get(key)?.updateValueAndValidity();
    });
    isValid = isValid && form.valid;
    return isValid;
  }

  buildModal(
    buttonLabel: string,
    description: string,
    success: boolean,
    title: string,
    icon: string,
  ) {
    this.modalModel.buttonLabel = buttonLabel;
    this.modalModel.description = description;
    this.modalModel.success = success;
    this.modalModel.imagePath = icon;
    this.modalModel.title = title;
    this.modalModel.showModal = true;
  }

  updateDFDVStore() {
    this.hasErrorMessage = '';
    const model = new RvParameterModel();

    const isValid = this.validallForms();

    if (!isValid) {
      this.hasErrorMessage = this.hasErrorPercent ? 'Valor do percentual diferente de 100%' : 'Erro ao processar alterações';
      return;
    }

    this.fillModel(model);
    this.dfdv.rvParameter = model;

    this.store.dispatch(
      DfdvActions.updateSubobject({ subObjectName: 'rvParameter', data: model }),
    );
  }

  validallForms() {
    let isValid = true;

    this.quantitativeItems.forEach((form) => {
      isValid = this.isFormValid(form) && isValid;
    });

    this.qualitativeItems.forEach((form) => {
      isValid = this.isFormValid(form) && isValid;
    });
    let sumPercent = 0;
    this.ceillingDistributionForDriversForm.forEach((form) => {
      isValid = this.isFormValid(form) && isValid;
      const value = form.get('numberOfDrivers')?.value ?? '0';
      sumPercent += (+value);
    });
    isValid = (sumPercent === 100 || sumPercent === 0) && isValid;
    this.hasErrorPercent = sumPercent !== 100 && sumPercent !== 0;

    return isValid;
  }

  fillModel(model: RvParameterModel) {
    const newModel = model;
    newModel.hourlySalaryNightDriver = this.hourlySalaryNightDriver;
    newModel.hourlySalaryStandardDriver = this.hourlySalaryStandardDriver;

    newModel.qualitativeItems = [];
    newModel.quantitativeItems = [];
    newModel.ceilingRangeDistribution = new CeillingRangeDistributionModel();

    this.quantitativeItems.forEach((item) => {
      newModel.quantitativeItems.push({
        description: item.get('description')?.value,
        monthlyHours: item.get('monthlyHours')?.value,
        isUpdated: false,
      });
    });
    this.qualitativeItems.forEach((item) => {
      newModel.qualitativeItems.push({
        description: item.get('description')?.value,
        monthlyHours: item.get('monthlyHours')?.value,
        isUpdated: false,
      });
    });

    newModel.totalMonthlyHours = this.totalMonthlyHours;
    newModel.totalMonthlyHoursOfQualitativeItems = this.totalMonthlyHoursOfQualitativeItems;
    newModel.totalMonthlyHoursOfQuantitativeItems = this.totalMonthlyHoursOfQuantitativeItems;

    const ceillingValues = this.ceillingDistributionForDriversForm
      .map((x) => x.value as { numberOfDrivers: number })
      .map((x) => x.numberOfDrivers);

    Object.keys(newModel.ceilingRangeDistribution).forEach((key, index) => {
      newModel.ceilingRangeDistribution[key] = ceillingValues[index] ? ceillingValues[index] : 0;
    });

    newModel.ceilingRangeDistributionWeighting = this.calcCeilingRangeDistributionWeighting(newModel.ceilingRangeDistribution);
    this.ceilingRangeDistributionWeighting = newModel.ceilingRangeDistributionWeighting;

    return newModel;
  }

  clickModal() {
    this.modalModel.showModal = false;
  }

  totalQualitativeCallback(total: number) {
    setTimeout(() => {
      this.totalMonthlyHoursOfQualitativeItems = total;
      this.calcTotalMonthlyHours();
    }, 0);
  }

  totalQuantitativeCallback(total: number) {
    setTimeout(() => {
      this.totalMonthlyHoursOfQuantitativeItems = total;
      this.calcTotalMonthlyHours();
    }, 0);
  }

  calcTotalMonthlyHours() {
    this.totalMonthlyHours = this.totalMonthlyHoursOfQuantitativeItems + this.totalMonthlyHoursOfQualitativeItems;
  }

  calcCeilingRangeDistributionWeighting(model: CeillingRangeDistributionModel) {
    return 0.1 * model.TenPercent +
      0.2 * model.TwentyPercent +
      0.3 * model.ThirtyPercent +
      0.4 * model.FortyPercent +
      0.5 * model.FiftyPercent +
      0.6 * model.SixtyPercent +
      0.7 * model.SeventyPercent +
      0.8 * model.EightyPercent +
      0.9 * model.NinetyPercent +
      model.HundredPercent;
  }
}
