import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-cumulative-incidence-opl',
  templateUrl: './cumulative-incidence-opl.component.html',
  styleUrls: ['./cumulative-incidence-opl.component.scss'],
})
export class CumulativeIncidenceOplComponent {
  @Input() isLoading: boolean = true;
  @Input() totalBaseIncidenceCount: number = 0;
  @Output() totalBaseIncidence: EventEmitter<{ base: number, inss: number, fgts: number }> = new EventEmitter();
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Forms
  public form = new FormGroup({
    proportionalCompensatedVacation: new FormControl(''),
    oneThirdVacation: new FormControl(''),
    earlyWarning: new FormControl(''),
    legalAbsences: new FormControl(''),
    thirteenthSalary: new FormControl(''),
  });

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
