export class GetUniformMaintenceWarehouseTeamModel {
  [key: string]: number;

  public pantsQuantity: number = 0;
  public pantsUnitCost: number = 0;
  public pantsMonthlyCost: number = 0;
  public pantsHarvestCost: number = 0;

  public shirtQuantity: number = 0;
  public shirtUnitCost: number = 0;
  public shirtMonthlyCost: number = 0;
  public shirtHarvestCost: number = 0;

  public bootsQuantity: number = 0;
  public bootsUnitCost: number = 0;
  public bootsMonthlyCost: number = 0;
  public bootsHarvestCost: number = 0;

  public glovesQuantity: number = 0;
  public glovesUnitCost: number = 0;
  public glovesMonthlyCost: number = 0;
  public glovesHarvestCost: number = 0;

  public glassesQuantity: number = 0;
  public glassesUnitCost: number = 0;
  public glassesMonthlyCost: number = 0;
  public glassesHarvestCost: number = 0;

  public sweatshirtQuantity: number = 0;
  public sweatshirtUnitCost: number = 0;
  public sweatshirtMonthlyCost: number = 0;
  public sweatshirtHarvestCost: number = 0;
}
