<app-modal-generic
  [imagePath]="'assets/icons/question-purple.svg'"
  [title]="title"
  [showModal]="showModal"
  (HandleOutsideClick)="close()"
>
  <div class="content">
    <span>{{ description }}</span>
    <app-attach
      *ngIf="showModal"
      class="attachments"
      [title]="attachLabel"
      [description]="attachDescription"
      [loadingAttach]="loadingAttach"
      [control]="form.controls.attachments"
      [selectedFiles]="selectedFiles"
      [totalSize]="attachTotalSize"
      [isAnalyst]="isAnalyst"
      [extensions]="attachExtensions"
    ></app-attach>
    <div class="buttons">
      <app-secondary-button
        label="Cancelar"
        [enabled]="true"
        (click)="close()"
      ></app-secondary-button>
      <app-primary-button
        [label]="confirmLabel"
        [icon]="confirmIcon"
        [color]="confirmColor"
        [right]="false"
        [enabled]="form.valid"
        (clickButton)="confirm()"
      ></app-primary-button>
    </div>
  </div>
</app-modal-generic>
