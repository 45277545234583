import { SupportTeamDetailModel } from "./support-team-detail.model";
import { SupportTeamTotalizerModel } from "./support-team-totalizer.model";

export class RhAndSsmaModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.total = new SupportTeamTotalizerModel();
    this.operationsAnalyst = new SupportTeamDetailModel();
    this.hrTechnician = new SupportTeamDetailModel();
    this.securityTechnician = new SupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  total: SupportTeamTotalizerModel = new SupportTeamTotalizerModel();
  operationsAnalyst: SupportTeamDetailModel = new SupportTeamDetailModel();
  hrTechnician: SupportTeamDetailModel = new SupportTeamDetailModel();
  securityTechnician: SupportTeamDetailModel = new SupportTeamDetailModel();
}
