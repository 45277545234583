import { BenefitValuesModel } from './benefit-values-model';

export class BenefitKeysModel {
  [key: string] : any;

  foodTicket!: BenefitValuesModel;
  mealTicket!: BenefitValuesModel;
  basicBasket!: BenefitValuesModel;
  transportationVoucher!: BenefitValuesModel;
  healthCare!: BenefitValuesModel;
  lifeInsurance!: BenefitValuesModel;
  pcmso!: BenefitValuesModel;
  ppra!: BenefitValuesModel;
  plr!: BenefitValuesModel;

  constructor() {
    this.foodTicket = new BenefitValuesModel();
    this.mealTicket = new BenefitValuesModel();
    this.basicBasket = new BenefitValuesModel();
    this.transportationVoucher = new BenefitValuesModel();
    this.healthCare = new BenefitValuesModel();
    this.lifeInsurance = new BenefitValuesModel();
    this.pcmso = new BenefitValuesModel();
    this.ppra = new BenefitValuesModel();
    this.plr = new BenefitValuesModel();
  }
}
