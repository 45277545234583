import { ItemsQuantitativeModel } from 'src/app/services/dfdv/rv-parameter/model/itemsquantitative.model';
import { ItemsQualitativeModel } from './itemsqualitative.model';
import { CeillingRangeDistributionModel } from './ceilingRangeDistribution.model';

export class RvParameterModel {
  [key: string]: any;

  hourlySalaryNightDriver: number = 0;
  hourlySalaryStandardDriver: number = 0;
  totalMonthlyHoursOfQuantitativeItems: number = 0;
  totalMonthlyHoursOfQualitativeItems: number = 0;
  totalMonthlyHours: number = 0;
  totalCeilingRangeDistribution: number = 0;
  ceilingRangeDistributionWeighting: number = 0;
  quantitativeItems: ItemsQuantitativeModel[] = [];
  qualitativeItems: ItemsQualitativeModel[] = [];
  ceilingRangeDistribution!: CeillingRangeDistributionModel;
}
