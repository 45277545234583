/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import * as DfdvActions from './dfdv.actions';

export interface ILoadingState {
  loading: boolean;
}

export interface IDfdvState {
  dfdv: GetDfdvModel;
}
export const initialLoadingState: ILoadingState = {
  loading: false,
};

export const initialState: IDfdvState = {
  dfdv: new GetDfdvModel(),
};

export const dfdvReducer = createReducer(
  initialState,
  on(DfdvActions.loadGetDfdvModelSuccess, (state, { data }) => {
    state = {
      ...state,
      dfdv: data,
    };
    return state;
  }),
  on(DfdvActions.updateSubobject, (state, { subObjectName, data }) => {
    state = {
      ...state,
      dfdv: {
        ...state.dfdv,
        [subObjectName]: data,
      },
    };
    return state;
  }),
  on(DfdvActions.updateDfdvObject, (state, { data }) => {
    state = {
      ...state,
      dfdv: data,
    };
    return state;
  }),
  on(DfdvActions.clearDfdvObject, (state) => {
    state = {
      dfdv: new GetDfdvModel(),
    };
    return state;
  })
);

export const loadingReducer = createReducer(
  initialLoadingState,
  on(DfdvActions.setLoading, (state, { isLoading }) => {
    state = {
      ...state,
      loading: isLoading,
    };
    return state;
  })
);
