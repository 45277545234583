<app-card
  icon="../../../../assets/icons/categories-primary-600.svg"
  [title]="'Aprovações'"
>
  <div body *ngIf="isLoaded">
    <app-history>
      <app-history-item
        *ngFor="let item of approvements"
        [accordion]="false"
        [date]="item.date"
        [hasDate]="item.date !== null"
        [type]="
          (item.date !== null && item.status.includes('Aprovado')) ||
          (item.date !== null && item.status.includes('Em Fechamento')) ||
          item.status.includes('Concluído')
            ? eHistoryItemType.approved
            : item.status.includes('Reprovado')
            ? eHistoryItemType.reproved
            : eHistoryItemType.awaiting
        "
      >
        <div header class="header">
          <div class="analyst">
            <span class="analystId">{{ item.analystId }}</span>
            <span class="analystName">
              {{ utils.formatName(item.analystName, 20) }}</span
            >
          </div>
          <app-status
            [status]="
              item.status
                .replace('Aguardando', '')
                .replace('Aprovado', '')
                .replace('Reprovado', '')
            "
            [image]="
              (item.date !== null && item.status.includes('Aprovado')) ||
              (item.date !== null && item.status.includes('Em Fechamento')) ||
              item.status.includes('Concluído')
                ? '../../../../assets/icons/Success-green.svg'
                : item.status.includes('Reprovado')
                ? '../../../../assets/icons/Exclude.svg'
                : '../../../../assets/icons/Timer-yellow.svg'
            "
            [color]="
              (item.date !== null && item.status.includes('Aprovado')) ||
              (item.date !== null && item.status.includes('Em Fechamento')) ||
              item.status.includes('Concluído')
                ? StatusColorEnum.success
                : item.status.includes('Reprovado')
                ? StatusColorEnum.warning
                : StatusColorEnum.attention
            "
          >
          </app-status>
        </div>
        <div body class="body" *ngIf="item.justification">
          <span class="justification">Justificativa:</span>
          <article>
            {{ item.justification }}
          </article>
        </div>
      </app-history-item>
    </app-history>
  </div>
</app-card>
