<app-modal-generic
  [showModal]="showModal"
  imagePath="../../../assets/icons/person.svg"
  [title]="searchModel.name"
  (HandleOutsideClick)="handleOutiseClick()"
>
  <div class="modal-container">
    <header>
      <div class="role">
        <label class="title">Cargo</label>
        <label class="description">{{ searchModel.title }}</label>
      </div>
      <div class="department">
        <label class="title">Departamento</label>
        <label class="description">{{ searchModel.department }}</label>
      </div>
      <div class="office">
        <label class="title">Escritório</label>
        <label class="description">{{ searchModel.office }}</label>
      </div>
    </header>
    <footer>
      <div class="id">
        <img src="../../../assets/icons/digital.svg" alt="" />
        <label class="desc-id">{{ searchModel.analystId }}</label>
      </div>
      <div class="email">
        <img src="../../../assets/icons/arroba.svg" alt="" />
        <label class="desc-email">{{ searchModel.email }}</label>
      </div>
    </footer>
  </div>
</app-modal-generic>
