/* eslint-disable max-len */
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { RolePermissionModel } from 'src/app/pages/solicitation/solicitation-data/solicitation-new/models/role-permission.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { GetAttachmentModel } from 'src/app/services/solicitation/models/get-attachment-model';
import { AttachComponent } from 'src/app/shared/attach/attach.component';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { DfdvStatusEnum } from 'src/app/shared/enums/DfdvStatus';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';
import { Store } from '@ngrx/store';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvService } from '../../../../services/dfdv/dfdv.service';

@Component({
  selector: 'app-sumarize-info',
  templateUrl: './sumarize-info.component.html',
  styleUrls: ['./sumarize-info.component.scss'],
})
export class SumarizeInfoComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean = false;
  @Input() dfdv!: GetDfdvModel;
  @ViewChild(AttachComponent) fileInput!: AttachComponent;

  public form = this.formBuilder.group({
    attachments: [[] as File[]],
  });
  public selectedFiles: GetAttachmentModel[] = [];
  public totalSize: number = 0;
  public isAnalyst!: boolean;
  public loadingDownload: boolean = false;
  public loadingAttach!: boolean;
  public rolePermission: RolePermissionModel = new RolePermissionModel();
  public modalModel: ModalModel = new ModalModel();

  public dfdvUtil!: DfdvUtils;
  public isUserRaizen: boolean = false;
  public fixedCostInvoicing: number = 0;
  public variableCostInvoicing: number = 0;
  public otherCostEstimatedInvoicing: number = 0;
  public preHarvestInvoicing: number = 0;
  public betweenHarvestInvoicing: number = 0;
  public totalInvoicing: number = 0;
  public update: boolean = false;

  public StatusColorEnum = StatusColorEnum;
  public ebuttonColor = ButtonColorEnum;

  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    public dfdvService: DfdvService,
    private storageService: StorageService,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  ngOnInit(): void {
    this.getUserRole();
    this.dfdvUtil = new DfdvUtils(
      this.dfdv,
      this.storageService.getRole(),
      this.store,
    );
    if (!this.update && this.dfdvUtil.isEnableToEdit()) this.update = this.dfdvUtil.updateInvoicing();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dfdv']) {
      this.setAttachments();
      this.setInvoicing();
    }
  }

  getUserRole() {
    const role = this.storageService.getRole();
    this.isUserRaizen = role.name.includes('Admin') || role.name.includes('Analista');
  }

  setAttachments() {
    const attachments = this.dfdv.attachments || [];

    if (attachments.length && !this.selectedFiles.length) {
      attachments.forEach((attach) => {
        const fileAttach = new GetAttachmentModel();
        this.selectedFiles.push({
          ...fileAttach,
          fileName: attach.fileName,
          fileSize: attach.fileSize,
        });
      });
    }
  }

  setInvoicing() {
    if (this.dfdv.invoicing) {
      const {
        preHarvestInvoicing,
        betweenHarvestInvoicing,
        fixedCostInvoicing,
        variableCostInvoicing,
        otherCostEstimatedInvoicing,
        totalInvoicing,
      } = this.dfdv.invoicing;

      this.preHarvestInvoicing = preHarvestInvoicing ?? 0;
      this.betweenHarvestInvoicing = betweenHarvestInvoicing ?? 0;
      this.fixedCostInvoicing = fixedCostInvoicing ?? 0;
      this.variableCostInvoicing = variableCostInvoicing ?? 0;
      this.otherCostEstimatedInvoicing = otherCostEstimatedInvoicing ?? 0;
      this.totalInvoicing = totalInvoicing ?? 0;
    }
  }

  getDfdvStatusColor(status: string) {
    switch (status) {
      case DfdvStatusEnum.Draft:
        return StatusColorEnum.teal;
      case DfdvStatusEnum.Preliminary:
        return StatusColorEnum.indigo;
      case DfdvStatusEnum.Pending:
        return StatusColorEnum.attention;
      case DfdvStatusEnum.Reproved:
        return StatusColorEnum.warning;
      case DfdvStatusEnum.Archived:
        return StatusColorEnum.common;
      case DfdvStatusEnum.Current:
        return StatusColorEnum.primary;
      case DfdvStatusEnum.New:
        return StatusColorEnum.secondary;
      case DfdvStatusEnum.Canceled:
        return StatusColorEnum.rose;
      default:
        return StatusColorEnum.success;
    }
  }

  getDfdvTypeColor(status: string) {
    switch (status) {
      case 'Cotação':
        return StatusColorEnum.attention;
      case 'Efetivo':
        return StatusColorEnum.success;
      default:
        return StatusColorEnum.primary;
    }
  }

  downloadAttatchment(fileName: string) {
    this.loadingAttach = true;
    this.dfdvService.downloadFile(this.dfdv.dfdvId, fileName).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response) {
          this.loadingAttach = false;
          const downloadedFile = new Blob([event?.body ?? ''], {
            type: event?.body?.type,
          });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = fileName;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }
      },
      error: (error) => {
        this.loadingAttach = false;
        const message = JSON.parse(JSON.stringify(error?.status));
        switch (message) {
          case 404:
            this.router.navigate(['access-denied']);
            break;
          case 400:
            this.modalModel.description = 'Você não possui permissão para baixar este arquivo.';
            break;
          default:
            this.modalModel.description = 'Tente novamente mais tarde ou entre em contato com o administrador do sistema.';
            break;
        }
        this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
        this.modalModel.title = 'Erro ao realizar o download do arquivo';
        this.modalModel.buttonLabel = 'Entendi';
        this.modalModel.success = false;
        this.modalModel.showModal = true;
      },
    });
  }

  downloadDfdvReport() {
    if (this.isLoading || this.loadingDownload) return;

    this.loadingDownload = true;
    this.dfdvService.downloadDfdvReport(this.dfdv.dfdvId).subscribe({
      next: this.handleDownloadDfdvReportSuccess.bind(this),
      error: this.handleDownloadDfdvReportError.bind(this),
    });
  }

  handleDownloadDfdvReportSuccess(report: Blob) {
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const date = new Intl.DateTimeFormat('pt-BR', options).format(new Date());

    const fileAnchor = document.createElement('a');
    fileAnchor.download = `DFDV-${this.dfdv.unitName}-${date}.xlsx`;
    fileAnchor.href = window.URL.createObjectURL(report);
    fileAnchor.click();
    fileAnchor.hidden = true;

    window.URL.revokeObjectURL(fileAnchor.href);

    this.loadingDownload = false;
  }

  handleDownloadDfdvReportError(error: HttpErrorResponse) {
    const message = JSON.parse(JSON.stringify(error.error));

    this.modalModel.title = 'Não foi possível realizar o download.';
    this.modalModel.description = error.status >= 400 && error.status < 500 && message
      ? message
      : 'Tente novamente mais tarde ou entre em contato com algum administrador.';
    this.modalModel.buttonLabel = 'Entendi';
    this.modalModel.imagePath = '../../../assets/icons/warning-600.gif';
    this.modalModel.success = false;
    this.modalModel.showModal = true;

    this.loadingDownload = false;
  }

  handleCloseModal() {
    this.modalModel.showModal = false;
  }
}
