<div class="container">
  <section>
    <app-card
      [accordion]="true"
      [icon]="'../../../../assets/icons/units-primary-600.svg'"
      [title]="'Unidades PIMS'"
      [paddingHorizontal]="false"
      [accordion]="false"
    >
      <section actions class="actions" >
        <app-icon-button
          (clickButton)="sendBack()"
          icon="../../../../assets/icons/arrow-previous-primary-600.svg"
          text="Voltar"
          [color]="ebuttonColor.primary"
          [enabled]="true"
        ></app-icon-button>
      </section>
      <div body class="card-container" *ngIf="!showSkeleton">
        <div *ngFor="let unit of units ">
          <div class="card-item" (click)="viewFixedCostCategoryUnit(unit.instanceId)">
            <div class="id">
              <span>{{unit.instanceId}}</span>
            </div>
            <div class="content">
              <div class="content-header">
                <div class="title">
                  {{unit.unitPimsName}}
                </div>
                <div class="status-container">
                  <app-status
                    [color]="statusColorEnum.attention"
                    [image]="'../../../../assets/icons/Timer Yellow Outline.svg'"
                    [status]="unit.totalSolicitationsPending.toString()"
                  ></app-status>
                  <app-status
                    [color]="statusColorEnum.success"
                    [image]="'../../../../assets/icons/Success-green.svg'"
                    [status]="unit.totalSolicitationsSent.toString()"
                  ></app-status>
                  <app-status
                    [color]="statusColorEnum.warning"
                    [image]="'../../../../assets/icons/Cross Red.svg'"
                    [status]="unit.totalSolicitationsNotSent.toString()"
                  ></app-status>
                  <app-status
                    [color]="getStatusColor(unit.fixedCostStatusName)"
                    [image]="getStatusIcon(unit.fixedCostStatusName)"
                    [status]="unit.fixedCostStatusName"
                  ></app-status>
                </div>
              </div>
              <div class="content-body">
                <div class="content-body-item">
                  <span>Valor Total</span>
                  <span class="value-gray">{{unit.totalValue | currency:'R$'}}</span>
                </div>
                <div class="content-body-item">
                  <span>Valor Atual</span>
                  <span
                    class="value-gray"
                    >{{ unit.currentValue | currency:'R$'}}</span
                  >
                </div>
                <div class="content-body-item">
                  <span>Valor Enviado</span>
                  <span class="value-green">{{unit.valueSent | currency:'R$'}}</span>
                </div>
                <div class="content-body-item" *ngIf="unit.fixedCostStatusName !== 'Concluido'">
                  <span>Valor a ser Pago</span>
                  <span
                    class="value-orange"
                    >{{ getCrurrentValue(unit) | currency:'R$'}}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="skeleton" body *ngIf="showSkeleton">
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <app-not-found body *ngIf="showNotFound && !showSkeleton"></app-not-found>
    </app-card>
  </section>
</div>
