export class TaxModel {
  [key: string] : any;

  pis: number = 0;
  cofins: number = 0;
  iss: number = 0;
  icms: number = 0;
  icmsWithoutComplement: number = 0;
  icmsCreditWithoutInput: number = 0;
  payrollEncumbrance: number = 0;
  hasPayrollEncumbrance: boolean = false;
  isIcmsCreditAllowed: boolean = false;
  hasPresumedProfit: boolean = false;
  hasPresumedIcmsCredit: boolean = false;
  isCreditWithoutAssetAllowed: boolean = false;
}
