import { UnitPimsModel } from '../../pims/unit/models/unit-pims.model';
import { GetUnitCategoryModel } from './get-unit-category-model';

export class GetUnitModel {
  unitId: number = 0;
  name: string = '';
  active: boolean = true;
  unitPims: UnitPimsModel[] = [];
  categories: GetUnitCategoryModel[] = [];
}
