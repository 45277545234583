export class ChargeModel {
  totalChargeBase: number = 0;
  totalCumulativeIncidenceBase: number = 0;
  totalCumulativeIncidenceInss: number = 0;
  totalCumulativeIncidenceFgts: number = 0;
  totalCumulativeIncidence: number = 0;
  totalProvision: number = 0;
  totalCharge: number = 0;
  chargeBase: ChargeBaseModel = new ChargeBaseModel();
  cumulativeIncidenceBase: CumulativeIncidenceBaseModel = new CumulativeIncidenceBaseModel();
  cumulativeIncidenceInss: CumulativeIncidenceBaseModel = new CumulativeIncidenceBaseModel();
  cumulativeIncidenceFgts: CumulativeIncidenceBaseModel = new CumulativeIncidenceBaseModel();
}

export class ChargeBaseModel {
  inss: number = 0;
  fgts: number = 0;
  sest: number = 0;
  senat: number = 0;
  incra: number = 0;
  sebrae: number = 0;
  educationSalary: number = 0;
  fap: number = 0;
  rat: number = 0;
  nrat: number = 0;
  fgtsPenalty: number = 0;
  fgtsPenaltySeasonalWorker: number = 0;
  nightAdditional: number = 0;
}

export class CumulativeIncidenceBaseModel {
  proportionalCompensatedVacation: number = 0;
  oneThirdVacation: number = 0;
  earlyWarning: number = 0;
  legalAbsences: number = 0;
  thirteenthSalary: number = 0;
}