import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-scope-fee',
  templateUrl: './scope-fee.component.html',
  styleUrls: ['./scope-fee.component.scss'],
})
export class ScopeFeeComponent implements OnInit {
  @Input() form = new FormGroup({
    dpvat: new FormControl(''),
    licensing: new FormControl(''),
    rctrc: new FormControl(''),
    rcfv: new FormControl(''),
    ipvaAndLicensingFee: new FormControl(''),
    ipvaWithDiscount: new FormControl(''),
    mandatoryInsurance: new FormControl(''),
    crlvEmissionFee: new FormControl(''),
    capitalRemuneration: new FormControl(''),
    hasIpvaAndLicensingFee: new FormControl(false),
  });

  @Input() shouldDisableFields: boolean = false;
  @Input() disableFieldOPL: boolean = false;
  @Input() disableFieldRaizen: boolean = false;
  @Input() isLoading: boolean = false;
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  public disableIpva: boolean = false;

  // Enums
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  constructor() {}

  ngOnInit(): void {
    this.disableIpva =
      !this.form.controls.hasIpvaAndLicensingFee.value ?? false;
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }

  handleCheck(checked: boolean) {
    this.form.controls.hasIpvaAndLicensingFee.patchValue(checked);
    this.disableIpva = !checked;
    this.updateValue.emit();
  }
}
