/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable @ngrx/prefer-selector-in-select */
/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Subject, Subscription, map, takeUntil,
} from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { Utils } from 'src/app/common/utils/utils';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { PlantModel } from 'src/app/services/dfdv/plant/plant.model';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { InvoicingModel } from '../../../../services/dfdv/invoicing/invoicing.model';

@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.scss'],
})
export class PlantComponent implements OnInit, OnDestroy {
  public form = this.formBuilder.group({
    harvestMonths: [''],
    estimatedAvgRadius: [''],
    TCD: [''],
    density: [''],
    startDate: [''],
    endDate: [''],
    volumetry: [''],
    preHarvestDays: [''],
    betweenHarvestNegotiation: [''],
    otherCostEstimatedInvoicing: [''],
  });

  // Control
  public isLoading: boolean = false;
  public hasErrorMessage: string = '';
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public shouldDisableFields: boolean = false;
  public disableFieldOPL: boolean = false;
  public disableFieldRaizen: boolean = false;
  public isUserRaizen: boolean = false;
  public loadingButton: boolean = false;
  public canPopulateForm: boolean = true;
  public dfdvUtils!: DfdvUtils;
  public update: boolean = false;

  // Enums
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Variables
  public errorMessage = 'Este campo é obrigatório';

  constructor(
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  plantStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.plant));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  private destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDFDV() {
    this.isLoading = true;
    this.unSubscribe = this.plantStore$.subscribe((plant) => {
      if (!plant) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.isLoading = isLoading.loading;
            });

          if (!this.isLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' }),
                );
              }
            });
          }
        });
      } else {
        this.isLoading = false;
        this.setPlants(plant, this.dfdv.invoicing);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };

      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(
          dfdv,
          this.storageService.getRole(),
          this.store,
        );
        this.isUserRaizen = this.dfdvUtils.isUserRaizen();
        this.shouldDisableFields = !this.dfdvUtils.isEnableToEdit();
        this.disableFieldOPL = !this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();
        this.disableFieldRaizen = this.dfdvUtils.isUserRaizen() && this.dfdvUtils.isPreliminary();

        if (this.dfdvUtils.isEnableToEdit() && !this.update) {
          this.update = this.dfdvUtils.updateSummary() !== undefined;
        }
      }
      this.setDfdvDates();
      this.setPlants(dfdv.plant, dfdv.invoicing);
    });
  }

  setDfdvDates() {
    if (this.canPopulateForm) {
      const startDate = this.dfdv.startDate
        ? new Date(this.dfdv.startDate).toLocaleDateString()
        : '';
      const endDate = this.dfdv.endDate
        ? new Date(this.dfdv.endDate).toLocaleDateString()
        : '';
      this.form.controls.startDate.patchValue(startDate);
      this.form.controls.endDate.patchValue(endDate);
    }
  }

  setPlants(plant?: PlantModel, invoicing?: InvoicingModel) {
    if (plant) {
      this.setFormValues(plant, invoicing);
    } else {
      this.resetForm();
    }
  }

  setFormValues(
    plant: PlantModel | undefined,
    invoicing: InvoicingModel | undefined,
  ) {
    this.form.controls.TCD.patchValue(plant?.tcd?.toString() ?? '0');
    this.form.controls.density.patchValue(plant?.density?.toString() ?? '0');
    this.form.controls.estimatedAvgRadius.patchValue(
      plant?.estimatedAvgRadius?.toString() ?? '0',
    );
    this.form.controls.harvestMonths.patchValue(
      plant?.harvestMonths?.toString() ?? '0',
    );

    this.form.controls.volumetry.patchValue(
      plant?.volumetry?.toString() ?? '0',
    );
    this.form.controls.preHarvestDays.patchValue(
      plant?.preHarvestDays?.toString() ?? '0',
    );
    this.form.controls.betweenHarvestNegotiation.patchValue(
      plant?.betweenHarvestNegotiation?.toString() ?? '0',
    );

    this.form.controls.otherCostEstimatedInvoicing.patchValue(
      invoicing?.otherCostEstimatedInvoicing?.toString() ?? '0',
    );
  }

  resetForm() {
    Object.keys(this.form.controls).forEach((key) => {
      if (!this.form.get(key)?.value) {
        this.form.get(key)?.reset();
        this.form.get(key)?.setErrors(null);
      }
    });
  }

  updateDFDVStore() {
    this.canPopulateForm = false;
    if (this.allFormsAreValid()) {
      this.loadingButton = true;

      this.dfdv.plant = this.getplantFormValues();
      this.dfdv.invoicing = this.dfdvUtils.isUserRaizen() ? this.getInvoicingFormValues() : new InvoicingModel();

      this.store.dispatch(
        DfdvActions.updateDfdvObject({
          data: this.dfdv,
        }),
      );

      this.dfdv.startDate = this.form.controls.startDate.value !== null
        ? new Utils().convertFromStringToDate(
          this.form.controls.startDate.value,
        )
        : this.dfdv.startDate;
      this.dfdv.endDate = this.form.controls.endDate.value !== null
        ? new Utils().convertFromStringToDate(
          this.form.controls.endDate.value,
        )
        : this.dfdv.endDate;

      this.store.dispatch(
        DfdvActions.updateDfdvObject({
          data: this.dfdv,
        }),
      );
    } else {
      this.hasErrorMessage = 'Os seguintes campos em vermelho e marcados com * são de preenchimento obrigatório.';
    }
  }

  allFormsAreValid() {
    this.updateErrors();

    if (!this.form.valid) {
      return false;
    }
    return true;
  }

  updateErrors() {
    Object.keys(this.form.controls).forEach((key) => {
      if (!this.form.get(key)?.value) {
        this.form.get(key)?.updateValueAndValidity();
      }
    });
  }

  getplantFormValues() {
    const requestModel = new PlantModel();
    const formControls = this.form.controls;

    requestModel.density = +(formControls.density.value ?? '0');
    requestModel.estimatedAvgRadius = +(
      formControls.estimatedAvgRadius.value ?? '0'
    );
    requestModel.harvestMonths = +(formControls.harvestMonths.value ?? '0');
    requestModel.tcd = +(formControls.TCD.value ?? '0');
    requestModel.volumetry = +(formControls.volumetry.value ?? '0');
    requestModel.preHarvestDays = +(formControls.preHarvestDays.value ?? '0');
    requestModel.betweenHarvestNegotiation = +(
      formControls.betweenHarvestNegotiation.value ?? '0'
    );

    return requestModel;
  }

  getInvoicingFormValues() {
    const requestModel = new InvoicingModel();

    requestModel.otherCostEstimatedInvoicing = +(
      this.form.controls.otherCostEstimatedInvoicing.value ?? '0'
    );
    requestModel.preHarvestInvoicing = this.getPreHarvestInvoicing();
    requestModel.betweenHarvestInvoicing = this.getBetweenHarvestInvoicing();
    requestModel.fixedCostInvoicing = this.getFixedCostInvoicing();
    requestModel.variableCostInvoicing = this.getVariableCostInvoicing();
    requestModel.totalInvoicing = this.getTotalInvoicing();

    return requestModel;
  }

  getPreHarvestInvoicing(): number {
    return (
      (this.dfdv.summary.operationalTeamExpense.totalOperationalTeamExpense
        * this.dfdv.scope.equipment.tractorQuantity
        * this.dfdv.plant.preHarvestDays)
      / 30
    );
  }

  getBetweenHarvestInvoicing(): number {
    const { harvestMonths } = this.dfdv.plant;
    const { fixedRemunerationExpense, personalExpense, operationalExpense } = this.dfdv.summary;

    return 12 - harvestMonths === 0
      ? 0
      : (fixedRemunerationExpense.totalFixedRemunerationExpense
          + this.dfdv.fleet.active.averageActiveFixedProfit
          + ((personalExpense.totalPersonalExpense
            + operationalExpense.totalOperationalExpense)
            * this.dfdv.plant.betweenHarvestNegotiation)
            / 100)
          * this.dfdv.scope.equipment.tractorQuantity
          * (12 - harvestMonths);
  }

  getFixedCostInvoicing(): number {
    return (
      this.dfdv.summary.totalFixedCost
      * this.dfdv.scope.equipment.tractorQuantity
      * this.dfdv.plant.harvestMonths
    );
  }

  getVariableCostInvoicing(): number {
    const { density, estimatedAvgRadius, volumetry } = this.dfdv.plant;
    const { lineTractorEstimatedMonthlyKm } = this.dfdv.scope.equipment;
    return density == 0 || lineTractorEstimatedMonthlyKm == 0
      ? 0
      : (this.dfdv.summary.totalVariableCost
          / lineTractorEstimatedMonthlyKm
          / density)
          * estimatedAvgRadius
          * volumetry
          * 2;
  }

  getTotalInvoicing(): number {
    if (!this.dfdv.invoicing) return 0;
    const {
      preHarvestInvoicing,
      betweenHarvestInvoicing,
      fixedCostInvoicing,
      variableCostInvoicing,
      otherCostEstimatedInvoicing,
    } = this.dfdv.invoicing;

    return (
      preHarvestInvoicing
      + betweenHarvestInvoicing
      + fixedCostInvoicing
      + variableCostInvoicing
      + otherCostEstimatedInvoicing
    );
  }

  showValidatorErrors() {
    Object.keys(this.form.controls).forEach((key) => {
      if (!this.form.get(key)?.value && this.form.get(key)?.value !== 0) {
        this.form.get(key)?.reset();
      }
    });
  }
}
