import {
  AfterViewInit, Component, EventEmitter, Input, OnChanges, Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent implements AfterViewInit, OnChanges {
  @Input() isLoading: boolean = true;
  @Input() totalChargesCount: number = 0;
  @Input() shouldDisableFields: boolean = false;
  @Output() totalChargeBase: EventEmitter<number> = new EventEmitter();
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Error Messages
  public errorMessage = 'Este campo é obrigatorio';

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;

  // Forms
  public form = new FormGroup({
    SEST: new FormControl(''),
    FAP: new FormControl(''),
    RAT: new FormControl(''),
    educationSalary: new FormControl(''),
    SEBRAE: new FormControl(''),
    INSS: new FormControl(''),
    FGTS: new FormControl(''),
    SENAT: new FormControl(''),
    INCRA: new FormControl(''),
    NRAT: new FormControl(''),
  });

  ngAfterViewInit(): void {
    this.form.controls.FAP.valueChanges.subscribe({
      next: () => {
        this.updateNRATValue();
      },
    });
    this.form.controls.RAT.valueChanges.subscribe({
      next: () => {
        this.updateNRATValue();
      },
    });
  }

  ngOnChanges(): void {
    this.updateNRATValue();
  }

  updateNRATValue() {
    this.form.controls.NRAT.patchValue(this.getCalcNRAT().toString());
  }

  getCalcNRAT(): number {
    const FAP = +(this.form.controls.FAP.value ?? '0');
    const RAT = +(this.form.controls.RAT.value ?? '0');
    return (FAP * RAT) / 100;
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
