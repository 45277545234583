<app-fleet-table
  [isLoading]="isLoading"
  [title]="'Cavalo Mecânico'"
  [icon]="'assets/icons/truck-common.svg'"
  [hasAction]="activeActions"
  [buttonLabel]="'Nova Configuração'"
  [buttonIcon]="'assets/icons/add-primary.svg'"
  [columnsHeader]="tractorHeader"
  [rowsContent]="tractorContent"
  [rowsStatus]="tractorRowStatus"
  [totalActiveRow]="tractorActiveContent"
  [totalStopedRow]="tractorStopedContent"
  (clickEvent)="handleNewConfig()"
  (rowClickEvent)="handleNewConfig($event)"
></app-fleet-table>
