import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
  @Input() label: string = '';
  @Input() labelColor: string = '#475569';
  @Input() bold: boolean = false;
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Output() check: EventEmitter<any> = new EventEmitter();
  @Output() removeCheck: EventEmitter<any> = new EventEmitter();

  onHover = false;

  checkItem() {
    this.value = true;
    this.check.emit();
  }

  removeCheckItem() {
    this.value = false;
    this.removeCheck.emit();
  }

  handleClick() {
    if (this.value) {
      this.removeCheckItem();
    } else {
      this.checkItem();
    }
  }
}
