import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operator-data',
  templateUrl: './operator-data.component.html',
  styleUrls: ['./operator-data.component.scss']
})
export class OperatorDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
