import { QuantitiesCostAmountValues } from '../quantitiesCostAmountValues.model';

export class UniformOperationalTeamModel {
  [key: string]: QuantitiesCostAmountValues;

  public pants: QuantitiesCostAmountValues;
  public shirt: QuantitiesCostAmountValues;
  public boots: QuantitiesCostAmountValues;
  public gloves: QuantitiesCostAmountValues;
  public glasses: QuantitiesCostAmountValues;
  public helmet: QuantitiesCostAmountValues;
  public hygieneKit: QuantitiesCostAmountValues;
  public hearingProtection: QuantitiesCostAmountValues;
  public leggings: QuantitiesCostAmountValues;
  public sweatshirt: QuantitiesCostAmountValues;
  public raincoat: QuantitiesCostAmountValues;

  constructor() {
    this.pants = new QuantitiesCostAmountValues();
    this.shirt = new QuantitiesCostAmountValues();
    this.boots = new QuantitiesCostAmountValues();
    this.gloves = new QuantitiesCostAmountValues();
    this.glasses = new QuantitiesCostAmountValues();
    this.helmet = new QuantitiesCostAmountValues();
    this.hygieneKit = new QuantitiesCostAmountValues();
    this.hearingProtection = new QuantitiesCostAmountValues();
    this.leggings = new QuantitiesCostAmountValues();
    this.sweatshirt = new QuantitiesCostAmountValues();
    this.raincoat = new QuantitiesCostAmountValues();
  }
}
