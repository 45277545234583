import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-info-template',
  templateUrl: './summary-info-template.component.html',
  styleUrls: ['./summary-info-template.component.scss'],
})
export class SummaryInfoTemplateComponent implements OnInit {
  @Input() tooltipDirection: string = 'bottom';
  @Input() infoText: string = '';
  @Input() tooltipText: string | null = null;
  @Input() value: string | boolean = '';

  public displayValue: string = '';

  ngOnInit(): void {
    if (typeof this.value === 'boolean') {
      this.displayValue = this.value ? 'Sim' : 'Não';
    } else {
      this.displayValue = this.value;
    }
  }
}
