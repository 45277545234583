/* eslint-disable no-param-reassign */
/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { FixedCostsModel } from 'src/app/services/fixedCosts/models/fixedCostById/fixedCosts.model';
import { UpdateFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-fixedCost.model';
import { UpdateSolicitationsFixedCostModel } from 'src/app/services/fixedCosts/models/update/update-solictiationsFixedCost.model';
import * as FixedCostActions from './fixedCost.actions';

export interface ILoadingState {
  loading: boolean;
}

export interface IErrorState {
  errorMessage: any | null;
}

export interface IFixedCostState {
  fixedCost: FixedCostsModel;
  updateSolicitation: UpdateFixedCostModel;
}
export const initialLoadingState: ILoadingState = {
  loading: false,
};

export const initialErrorState: IErrorState = {
  errorMessage: null,
};

export const initialState: IFixedCostState = {
  fixedCost: new FixedCostsModel(),
  updateSolicitation: new UpdateFixedCostModel(),
};

function getSolicitations(fixedCost: FixedCostsModel): UpdateFixedCostModel {
  const solicitations = [] as UpdateSolicitationsFixedCostModel[];

  fixedCost.categories.forEach((category) => {
    category.units.forEach((unit) => {
      unit.fixedCostSolicitationPendings.forEach((sol) => {
        solicitations.push({
          solicitationId: sol.solicitationId,
          approved: true,
        } as UpdateSolicitationsFixedCostModel);
      });
      unit.fixedCostSolicitationsSents.forEach((sol) => {
        solicitations.push({
          solicitationId: sol.solicitationId,
          approved: true,
        } as UpdateSolicitationsFixedCostModel);
      });
    });
  });

  return {
    fixedCostId: fixedCost.fixedCostId,
    solicitations,
  } as UpdateFixedCostModel;
}

function updateSolicitation(
  data: UpdateFixedCostModel,
  solicitationId: number,
  approved: boolean,
): UpdateFixedCostModel {
  let solicitations = [] as UpdateSolicitationsFixedCostModel[];
  data.solicitations.forEach((solicitation) => {
    if (solicitation.solicitationId === solicitationId) {
      solicitations = [
        ...solicitations,
        { solicitationId, approved } as UpdateSolicitationsFixedCostModel,
      ];
    } else {
      solicitations = [...solicitations, solicitation];
    }
  });
  return {
    fixedCostId: data.fixedCostId,
    solicitations,
  } as UpdateFixedCostModel;
}

export const fixedCostReducer = createReducer(
  initialState,
  on(FixedCostActions.loadFixedCostsModelSuccess, (state, { data }) => {
    state = {
      ...state,
      fixedCost: data,
      updateSolicitation: getSolicitations(data),
    };
    return state;
  }),
  on(
    FixedCostActions.updateSolicitation,
    (state, { solicitationId, approved }) => {
      state = {
        ...state,
        fixedCost: {
          ...state.fixedCost,
        },
        updateSolicitation: updateSolicitation(
          state.updateSolicitation,
          solicitationId,
          approved,
        ),
      };
      return state;
    },
  ),
  on(FixedCostActions.clearFixedCost, (state) => {
    state = {
      fixedCost: new FixedCostsModel(),
      updateSolicitation: new UpdateFixedCostModel(),
    };
    return state;
  }),
);

export const loadingFixedCostReducer = createReducer(
  initialLoadingState,
  on(FixedCostActions.setLoading, (state, { isLoading }) => {
    state = {
      ...state,
      loading: isLoading,
    };
    return state;
  }),
);
export const errorFixedCostReducer = createReducer(
  initialErrorState,
  on(FixedCostActions.setError, (state, { errorMessage }) => {
    state = {
      ...state,
      errorMessage,
    };
    return state;
  }),
);
