import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-input',
  templateUrl: './skeleton-input.component.html',
  styleUrls: ['./skeleton-input.component.scss']
})
export class SkeletonInputComponent implements OnInit {
  @Input() input: number = 0;
  public arr:number[] = [];
  constructor() { }

  ngOnInit(): void {
    for (let index = 0; index < this.input; index++) {
      this.arr.push(0);
    }
  }

}
