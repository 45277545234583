import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/common/services/base.service';
import { Injectable } from '@angular/core';
import { UpdateCoverImageModel } from './models/update-cover-image.model';
import { GetContextModel } from './models/get-context-model';
import { GetCoverImageModel } from './models/get-cover-image.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(private http: HttpClient) {
    super('admin');
  }

  getContextsCount() {
    return this.http.get<GetContextModel>(`${this.url}/contexts-count`);
  }

  getCoverImages() {
    return this.http.get<GetCoverImageModel>(`${this.url.replace('admin', 'configurations')}/cover-images`);
  }

  getCoverImagesPublic() {
    return this.http.get<GetCoverImageModel>(`${this.publicUrl.replace('admin', 'configurations')}/cover-images`);
  }

  updateCoverImages(model:UpdateCoverImageModel) {
    const formData: FormData = new FormData();
    formData.append('file', model.content);

    return this.http.put<GetCoverImageModel>(`${this.url.replace('admin', 'configurations')}/cover-images`, formData);
  }
}

