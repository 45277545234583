<div class="container">
  <app-card
    [title]="title"
    icon="../../../../assets/icons/solicitation-primary-600.svg"
  >
    <div class="body" body *ngIf="!isLoading">
      <p *ngIf="!id">
        Favor informar os seguintes dados, para que possamos receber sua
        solicitação.
      </p>
      <div *ngIf="id" class="createBy">
        <span class="createBy-t"> Criado por:</span>
        <span class="createBy-d"> {{ form.controls.createBy.value }}</span>
      </div>
      <div class="inputs-box">
        <app-select
          *ngIf="isAnalyst"
          field="Tipo da Solicitação"
          [singleControl]="form.controls.solicitationType"
          [options]="types"
          [selected]="typeValues"
          property="Type"
          [disabled]="id !== null"
        ></app-select>
        <app-input
          *ngIf="id"
          [control]="form.controls.data"
          label="Data"
          [disabled]="true"
          [showSideIcon]="true"
          [sideIconLabel]="'../../../assets/icons/calendar-slate300.svg'"
          [sideIconType]="eSideIconType.image"
        ></app-input>
        <app-input
          *ngIf="id"
          [control]="form.controls.status"
          label="Status"
          [disabled]="true"
        ></app-input>
        <app-select
          *ngIf="visible"
          field="Empresa"
          [singleControl]="form.controls.opl"
          [options]="opls"
          [selected]="oplValues"
          property="nome"
          [disabled]="id !== null && !rolePermission.opl"
        ></app-select>
        <app-select
          field="Unidade"
          [singleControl]="form.controls.unit"
          [options]="units"
          [selected]="unitValues"
          property="nome"
          [disabled]="id !== null && !rolePermission.unity"
          (statusChanged)="changeSelectedUnit($event)"
          (selectedItemsEmpty)="changeSelectedUnit($event)"
        ></app-select>
        <app-select
          field="Categoria"
          [singleControl]="form.controls.category"
          [options]="categories"
          [selected]="categoryValues"
          [disabled]="!rolePermission.category || !form.controls.unit.value"
          property="nome"
          (statusChanged)="changeSelectedCategory($event)"
          (selectedItemsEmpty)="changeSelectedCategory($event)"
        ></app-select>
        <app-select
          *ngIf="showSubcategory"
          field="Subcategoria"
          [singleControl]="form.controls.subcategory"
          [options]="subcategories"
          [selected]="subCategoryValues"
          property="nome"
          [disabled]="!rolePermission.subcategory"
        ></app-select>
        <app-select
          field="Tipo de Pagamento"
          [singleControl]="form.controls.paymentType"
          [options]="paymentTypes"
          [selected]="paymentTypeValues"
          [disabled]="!rolePermission.paymentType"
          property="nome"
        ></app-select>
        <app-input
          *ngIf="!isOutrosCustos"
          [control]="form.controls.quantity"
          label="Quantidade de Equipamentos"
          [disabled]="isOutrosCustos || !rolePermission.quantity"
          [mask]="eMaskTypeEnum.number"
          [max]="999"
          [min]="0"
        ></app-input>
        <app-input
          *ngIf="!isOutrosCustos"
          [control]="form.controls.unitaryValue"
          label="Valor Unitário"
          [disabled]="isOutrosCustos || !rolePermission.unitaryValue"
          [mask]="eMaskTypeEnum.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
        ></app-input>
        <app-input
          [control]="form.controls.value"
          label="Valor"
          [disabled]="(id !== null && !rolePermission.value) || !isOutrosCustos"
          [isNegative]="!isOutrosCustos"
          [mask]="eMaskTypeEnum.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
        ></app-input>
        <app-input
          *ngIf="id && !hide"
          [control]="form.controls.negotiatedValue"
          label="Valor Negociado"
          [disabled]="!rolePermission.negotiatedValue"
          [isNegative]="!isOutrosCustos"
          [mask]="eMaskTypeEnum.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
        ></app-input>
      </div>
      <div class="inputs-down">
        <app-input
          [control]="form.controls.title"
          label="Título"
          placeholder="Dê um título a sua solicitação"
          [maxlength]="100"
          [disabled]="!rolePermission.title"
        ></app-input>
        <app-text-area
          [control]="form.controls.description"
          label="Descrição"
          description="(opcional)"
          placeholder="Descreva a sua solicitação"
          [maxlength]="1000"
          [disabled]="!rolePermission.description"
        ></app-text-area>
        <app-attach
          #attach
          [loadingAttach]="loadingAttach"
          [control]="form.controls.attachments"
          [selectedFiles]="selectedFiles"
          [disabled]="!rolePermission.attachemnt"
          [totalSize]="totalSize"
          [isAnalyst]="isAnalyst"
          description="
            (arquivos suportados: .xlsx, .docx, .pdf, .jpg, .png, limite individual: 28mb; limite total: 50mb)
          "
          extensions=".xlsx,.docx,.pdf,.jpg,.png"
          (download)="downloadAttatchment($event)"
        ></app-attach>
      </div>
      <div class="inputs-recurrency" *ngIf="isOutrosCustos">
        <app-check-box
          label="Solicitação recorrente para os próximos meses?"
          [value]="recurrencySolicitation"
          [disabled]="!rolePermission.recurrencySolicitation"
          (check)="handleCheckRecurencySolicitation(true)"
          (removeCheck)="handleCheckRecurencySolicitation(false)"
        ></app-check-box>
        <app-input
          *ngIf="recurrencySolicitation && id && showRecurrencyMonth"
          label="Meses de Recorrência"
          [mask]="eMaskTypeEnum.number"
          [min]="minRecurrencyMonths"
          [max]="maxRecurrencyMonths"
          [control]="form.controls.recurrencyMonths"
          [disabled]="!rolePermission.recurrencyMonths"
        >
        </app-input>
      </div>
      <div *ngIf="!id" class="buttons">
        <app-secondary-button
          label="VOLTAR"
          icon="../../../../assets/icons/arrow-slate100.svg"
          [right]="false"
          [loading]="false"
          [enabled]="enabledBack"
          (clickButton)="navigateBack()"
        ></app-secondary-button>
        <app-primary-button
          label="ENVIAR"
          icon="../../../../assets/icons/arrow-right-common-100.svg"
          [color]="ButtonColorEnum.primary"
          [right]="true"
          [loading]="sending"
          [enabled]="true"
          [progress]="uploadInProgress"
          [progressValue]="uploadProgress"
          [showProgressValue]="showProgressValue"
          (clickButton)="id ? update() : create()"
        ></app-primary-button>
      </div>
      <div *ngIf="id" class="buttons">
        <ng-container>
          <app-secondary-button
            label="VOLTAR"
            icon="../../../../../assets/icons/arrow-previous-primary-600.svg"
            [right]="false"
            [loading]="false"
            [enabled]="enabledBack"
            (clickButton)="navigateBack()"
          ></app-secondary-button>
        </ng-container>
        <ng-container *ngIf="sendButtons">
          <ng-container *ngIf="approveButtons">
            <app-primary-button
              label="REPROVAR"
              icon="../../../../assets/icons/disapprove-common-100.svg"
              [color]="ButtonColorEnum.warning"
              [right]="false"
              [loading]="!approval && sending"
              [enabled]="enabledDisapprove"
              (clickButton)="disapprove()"
            ></app-primary-button>
            <app-primary-button
              label="APROVAR"
              icon="../../../../assets/icons/approve-common-100.svg"
              [color]="ButtonColorEnum.success"
              [right]="false"
              [loading]="sendApproval && sending"
              [enabled]="enabledApprove"
              [progress]="sendApproval && uploadInProgress"
              [progressValue]="uploadProgress"
              [showProgressValue]="sendApproval && showProgressValue"
              (clickButton)="approve(false)"
            ></app-primary-button>
          </ng-container>
          <ng-container *ngIf="!approveButtons">
            <app-primary-button
              label="ENVIAR"
              [color]="ButtonColorEnum.primary"
              [right]="false"
              [loading]="approval && sending"
              [enabled]="true"
              [progress]="uploadInProgress"
              [progressValue]="uploadProgress"
              [showProgressValue]="showProgressValue"
              (clickButton)="sendUpdate()"
            ></app-primary-button>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="canValueReview">
        <div class="value-review">
          <div class="review-container">
            <app-primary-button
              label="APROVAR COM REVISÃO DE VALOR"
              icon="../../../../assets/icons/approve-common-100.svg"
              [color]="ButtonColorEnum.attention"
              [right]="false"
              [loading]="sendApprovalValueReview && sending"
              [enabled]="enabledValueReview"
              [progress]="sendApprovalValueReview && uploadInProgress"
              [progressValue]="uploadProgress"
              [showProgressValue]="sendApprovalValueReview && showProgressValue"
              (clickButton)="approve(true)"
            ></app-primary-button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="skeleton" body>
      <app-skeleton-input [input]="6" *ngIf="isLoading"></app-skeleton-input>
      <app-skeleton-box [box]="2" *ngIf="isLoading"></app-skeleton-box>
    </div>
  </app-card>
</div>
<div>
  <app-solicitation-approval-modal
    [showModal]="showModal"
    [approval]="approval"
    [required]="isRequiredJustify"
    [title]="modalTitle"
    [buttonLabel]="buttonLabel"
    [buttonImage]="buttonImage"
    [buttonType]="buttonType"
    [buttonColor]="buttonColor"
    (clickButton)="modalClick($event)"
  ></app-solicitation-approval-modal>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
