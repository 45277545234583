import { SupportTeamLightFleetModel } from './support-team-lightFleet.model';

export class SupportTeamFleetModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.fleetOperationalTeam = new SupportTeamLightFleetModel();
    this.shiftChange = new SupportTeamLightFleetModel();
    this.workshopTruck = new SupportTeamLightFleetModel();
  }

  transportTypeName!: string | null;
  totalLightFleetQuantity: number = 0;
  totalLightFleetValue: number = 0;
  totalLightFleetExpense: number = 0;

  fleetOperationalTeam: SupportTeamLightFleetModel =
    new SupportTeamLightFleetModel();
  shiftChange: SupportTeamLightFleetModel = new SupportTeamLightFleetModel();
  workshopTruck: SupportTeamLightFleetModel = new SupportTeamLightFleetModel();
}
