<ul *ngIf="totalPages > 1">
  <li *ngIf="page > 1" id="previous-button" (click)="setPage(page - 1)">
    <img
      src="../../../assets/icons/arrow-left-primary-600.svg"
      alt="Arrow Left"
    />
  </li>
  <li [ngClass]="{ 'active-index': page === 1 }" (click)="setPage(1)">1</li>
  <li
    id="initial-dots"
    class="dots"
    *ngIf="
      numberOfIndexesDisplayed < totalPages &&
      page > numberOfIndexesDisplayed - 2
    "
  >
    …
  </li>
  <li
    *ngFor="let index of getMiddleIndexes()"
    [ngClass]="{ 'active-index': page === index }"
    (click)="setPage(index)"
  >
    {{ index }}
  </li>
  <li
    id="ending-dots"
    class="dots"
    *ngIf="
      numberOfIndexesDisplayed < totalPages &&
      page < totalPages - (numberOfIndexesDisplayed - 3)
    "
  >
    …
  </li>
  <li
    [ngClass]="{ 'active-index': page === totalPages }"
    (click)="setPage(totalPages)"
  >
    {{ totalPages }}
  </li>
  <li *ngIf="page < totalPages" id="next-button" (click)="setPage(page + 1)">
    <img
      src="../../../assets/icons/arrow-right-primary-600.svg"
      alt="Arrow Right"
    />
  </li>
</ul>
