<div class="container">
  <app-stepper (callBackSubmit)="submit()" [loading]="loading">
    <app-step [label]="'Empresa'" [valid]="companyForm.valid">
      <app-company-step [form]="companyForm"></app-company-step>
    </app-step>
    <app-step [label]="'Endereço'" [valid]="addressForm.valid">
      <app-address-step [form]="addressForm"></app-address-step>
    </app-step>
    <app-step [label]="'Contato'" [valid]="contactForm.valid">
      <app-contact-step [form]="contactForm"></app-contact-step>
    </app-step>
  </app-stepper>
  <app-modal
    [showModal]="showModal"
    [title]="modalModel.title"
    [description]="modalModel.description"
    [buttonLabel]="modalModel.buttonLabel"
    [imagePath]="modalModel.imagePath"
    (clickButton)="clickModal()"
  ></app-modal>
</div>
