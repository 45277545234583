<div class="rental-container">
  <div class="rental-card">
    <app-card
      title="Locação"
      icon="../../../../../assets/icons/truck-common.svg"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="6"></app-skeleton-input>
      </div>
      <section *ngIf="!isLoading" class="body" body>
        <p>Informar os valores gastos <b>por equipamento</b> mensalmente.</p>
        <div class="body-input">
          <app-input
            label="Aluguel Frota Leve (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.lightFleetRental"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="Aluguel Transporte Coletivo (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.collectiveTransportRental"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="Aluguel Caminhão Oficina (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.workshopTruckRental"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="Aluguel Cavalo Mecânico (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.tractorRental"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
          <app-input
            label="Aluguel Conjunto (R$)"
            placeholder="0,00"
            [errorMessage]="errorMessage"
            [control]="form.controls.trailerRental"
            [mask]="eMaskType.money"
            [showSideIcon]="true"
            sideIconLabel="R$"
            [sideIconType]="eSideIconType.text"
            [disabled]="shouldDisableFields || disableFieldRaizen"
            (updateValue)="handleUpdateValue()"
          ></app-input>
        </div>
      </section>
    </app-card>
  </div>
</div>
