import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { FleetConfigurationItemsModel } from 'src/app/services/dfdv/fleet/models/fleet-configuration-items.model';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { FleetTableRowStatusEnum } from 'src/app/shared/fleet-table/enums/fleet-table.enum';
import { CVFleetSpecificationModel } from './models/variable-costs-fleet.model';
import { Store } from '@ngrx/store';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';

@Component({
  selector: 'app-variable-costs-fleet',
  templateUrl: './variable-costs-fleet.component.html',
  styleUrls: ['./variable-costs-fleet.component.scss'],
})
export class VariableCostsFleetComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean = false;
  @Input() dfdv!: GetDfdvModel;

  public specifications: CVFleetSpecificationModel =
    new CVFleetSpecificationModel();
  public dfdvUtils!: DfdvUtils;
  public tableHeaderColumns: string[] = [
    'ATIVO/PARADO',
    'QUANTIDADE',
    'FABRICANTE CAVALO MECÂNICO',
    'MODELO CAVALO MECÂNICO',
    'ANO CAVALO MECÂNICO',
    'ANO CONJUNTO',
    'KM MENSAL ESTIMADO CAVALO MECÂNICO LINHA',
    'KM MENSAL ESTIMADO CAVALO MECÂNICO MANOBRA',
    'TOTAL KM MENSAL ESTIMADO CAVALO MECÂNICO',
    'CONSUMO KM/L',
    'CONSUMO R$/KM',
    'MANUTENÇÃO CAVALO MECÂNICO R$/KM',
    'MANUTENÇÃO CONJUNTO R$/KM',
    'PNEUS R$/KM',
    'LAVAGEM R$/KM',
    'TOTAL CUSTO VARIÁVEL',
  ];
  public tableRowsStatus: FleetTableRowStatusEnum[] = [];
  public tableRowsContent: string[][] = [];
  public totalActiveStopColumns: string[] = [];

  constructor(
    private storageService: StorageService,
    private store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>
  ) {}

  ngOnInit(): void {
    if (this.dfdv.fleet) {
      this.dfdvUtils = new DfdvUtils(
        this.dfdv,
        this.storageService.getRole(),
        this.store
      );
      if(this.dfdvUtils.isEnableToEdit()) this.dfdvUtils.updateFleetDfdv();
      this.setSpecifications();
      this.setData();
    } else {
      this.totalActiveStopColumns = Array(
        this.tableHeaderColumns.length - 1
      ).fill('-');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dfdv'] && !!this.dfdv.fleet) {
      this.setSpecifications();
      this.setData();
    }
  }

  setData() {
    if (this.dfdv.fleet.fleetConfigurationItems?.length) {
      const configItems = this.dfdv.fleet.fleetConfigurationItems;
      if (configItems.length) {
        this.tableRowsContent = [];
        this.tableRowsStatus = [];
        configItems.forEach((item, index) => {
          this.setStatusRow(item);
          this.setActiveStopedRow(item, index);
        });
        this.setTotalRow();
      }
    }
  }

  setSpecifications() {
    if (!this.dfdv.scope) return;

    const { lineTractorEstimatedMonthlyKm, maneuverTractorEstimatedMonthlyKm } =
      this.dfdv.scope.equipment;
    const { arlaCost, anpDieselCost } = this.dfdv.scope.consumption;

    this.specifications.lineTractorEstimatedMonthlyKm = +(
      lineTractorEstimatedMonthlyKm ?? 0
    );
    this.specifications.maneuverTractorEstimatedMonthlyKm = +(
      maneuverTractorEstimatedMonthlyKm ?? 0
    );
    this.specifications.tractorEstimatedMonthlyKm =
      this.specifications.lineTractorEstimatedMonthlyKm +
      this.specifications.maneuverTractorEstimatedMonthlyKm;

    this.specifications.arlaCost = arlaCost ?? 0;
    this.specifications.anpDieselCost = anpDieselCost ?? 0;
    this.specifications.anpDieselWithArlaCost =
      anpDieselCost + arlaCost * (5 / 100) ?? 0;
  }

  setStatusRow(item: FleetConfigurationItemsModel) {
    this.tableRowsStatus.push(
      item.tractor.active
        ? FleetTableRowStatusEnum.ACTIVE
        : FleetTableRowStatusEnum.STOPED
    );
  }

  setActiveStopedRow(item: FleetConfigurationItemsModel, index: number) {
    const isActive = item.tractor.active;
    const {
      tractorConsumption,
      tractorMaintenance,
      trailerMaintenance,
      tireUnitCostPerKm,
      lineTractorEstimatedMonthlyKm,
    } = item.variableCost;
    const { tractorOwnMaintenance, tractorFactoryMaintenance } =
      this.dfdv.cvMaintenance;

    this.tableRowsContent.push([
      (index + 1).toString(),
      item.tractor.quantity.toString(),
      item.tractor.tractorBrand,
      item.tractor.tractorModel,
      item.tractor.tractorYear.toString(),
      item.trailer.trailerYear.toString(),
      (isActive
        ? this.dfdv.scope.equipment.lineTractorEstimatedMonthlyKm
        : 0
      ).toString(),
      (isActive
        ? this.dfdv.scope.equipment.maneuverTractorEstimatedMonthlyKm
        : 0
      ).toString(),
      (isActive ? this.dfdv.fleet.tractorEstimatedMonthlyKm : 0).toString(),
      this.formatNumber(this.dfdv.cvConsumption[0].tractorConsumption),
      `R$ ${this.formatNumber(tractorConsumption)}`,
      `R$ ${this.formatNumber(
        tractorOwnMaintenance === 0
          ? tractorFactoryMaintenance
          : tractorFactoryMaintenance === 0
          ? tractorOwnMaintenance
          : Math.min(tractorOwnMaintenance, tractorFactoryMaintenance)
      )}`,
      `R$ ${this.formatNumber(trailerMaintenance)}`,
      `R$ ${this.formatNumber(tireUnitCostPerKm)}`,
      `R$ ${this.formatNumber(
        lineTractorEstimatedMonthlyKm === 0
          ? 0
          : (this.dfdv.scope.consumption.washingCost * 2) /
              lineTractorEstimatedMonthlyKm
      )}`,
      `R$ ${this.formatNumber(
        isActive
          ? tractorConsumption +
              tractorMaintenance +
              trailerMaintenance +
              tireUnitCostPerKm +
              (lineTractorEstimatedMonthlyKm === 0
                ? 0
                : (this.dfdv.scope.consumption.washingCost * 2) /
                  lineTractorEstimatedMonthlyKm)
          : 0
      )}`,
    ]);
  }

  setTotalRow() {
    this.totalActiveStopColumns = [
      '0',
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
      '0',
      '0',
      '0',
      '0',
      '0',
      '-',
    ];

    let totalQuantity = 0;
    let totalConsumptionPerKM = 0;
    let totalMaintenanceTractor = 0;
    let totalMaintenanceTrailer = 0;
    let totalTires = 0;
    let totalWashing = 0;
    this.tableRowsContent.forEach((row) => {
      totalQuantity += Number(row[1]);
      totalConsumptionPerKM += this.getNumber(row[10]);
      totalMaintenanceTractor += this.getNumber(row[11]);
      totalMaintenanceTrailer += this.getNumber(row[12]);
      totalTires += this.getNumber(row[13]);
      totalWashing += this.getNumber(row[14]);
    });
    this.totalActiveStopColumns[0] = totalQuantity.toString();
    this.totalActiveStopColumns[9] = `R$ ${this.formatNumber(
      this.dfdv.fleet.averageTractorConsumption ?? 0
    )}`;
    this.totalActiveStopColumns[10] = `R$ ${this.formatNumber(
      this.dfdv.fleet.averageTractorMaintenance ?? 0
    )}`;
    this.totalActiveStopColumns[11] = `R$ ${this.formatNumber(
      this.dfdv.fleet.averageTrailerMaintenance ?? 0
    )}`;
    this.totalActiveStopColumns[12] = `R$ ${this.formatNumber(
      this.dfdv.fleet.averageTireUnitCostPerKm ?? 0
    )}`;
    this.totalActiveStopColumns[13] = `R$ ${this.formatNumber(
      this.dfdv.fleet.averageWashingCost ?? 0
    )}`;
  }

  getNumber(value: string): number {
    const valueFormatted = value
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    return Number(valueFormatted);
  }

  formatNumber(value: number): string {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}
