<div
  *ngIf="showMenuItem"
  class="menu-container"
  [@smoothCollapseMenu]="open ? 'initial' : 'final'"
>
  <li
    *ngIf="!hasChild"
    [ngClass]="{ selected: selected, submenu: submenu }"
    (click)="handleClick()"
  >
    <img [src]="icon" [alt]="title" />
    <span>{{ title }}</span>
  </li>
  <li
    *ngIf="hasChild"
    [ngClass]="{ selected: selected, submenu: submenu }"
    (click)="toggleAccordion()"
  >
    <img [src]="icon" [alt]="title" />
    <span>{{ title }}</span>
    <div>
      <img
        [ngClass]="{ 'rotate-animation': !open }"
        src="../../../assets/icons/arrow-up-common-100.svg"
        alt="Arrow icon"
      />
    </div>
  </li>
  <ul *ngIf="hasChild" class="sub-menu">
    <ng-content></ng-content>
  </ul>
</div>
