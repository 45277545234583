<app-card [title]="title" [icon]="icon" [accordion]="true" [paddingHorizontal]="false">
  <div *ngIf="isLoading" class="skeleton" body>
    <app-skeleton-line [line]="5"></app-skeleton-line>
  </div>
  <div *ngIf="hasAction" actions>
    <app-secondary-button
      [label]="buttonLabel"
      [icon]="buttonIcon"
      [enabled]="!isLoading"
      (click)="handleClick()"
    ></app-secondary-button>
  </div>
  <div *ngIf="!isLoading" class="card-body" body>
    <div class="tractor-table">
      <table class="tractor-table__fixed">
        <tr class="tractor-table__row tractor-table__row-header"><th>{{ columnsHeader[0] }}</th></tr>
        <tr
          *ngFor="let column of fixedColumnsList; index as rowHeaderIndex"
          class="tractor-table__row"
          [ngClass]="rowsStatus[rowHeaderIndex]"
          (click)="rowClick(rowHeaderIndex)"
        ><th>{{ column }}</th></tr>
        <tr *ngIf="!isTotalsBoth" class="tractor-table__row tractor-table__row-total success">
          <th>Valores Médios<br> (Ativo)</th>
        </tr>
        <tr *ngIf="!isTotalsBoth" class="tractor-table__row tractor-table__row-total warning">
          <th>Valores Médios<br> (Parado)</th>
        </tr>
        <tr *ngIf="isTotalsBoth" class="tractor-table__row tractor-table__row-total neutral">
          <th>Valores Médios<br> (Ativo e Parado)</th>
        </tr>
      </table>
      <div class="tractor-table__scroll">
        <table class="tractor-table__scroll-content">
          <tr
            class="tractor-table__row tractor-table__row-header"
          >
            <th *ngFor="let headerColumn of headerList">{{ headerColumn }}</th>
          </tr>
          <tr
            *ngFor="let rowContent of rowsList; index as rowContentIndex"
            class="tractor-table__row"
            [ngClass]="rowsStatus[rowContentIndex]"
            (click)="rowClick(rowContentIndex)"
          >
            <td *ngFor="let columnCotent of rowContent">{{ columnCotent }}</td>
          </tr>
          <tr
            *ngIf="!isTotalsBoth"
            class="tractor-table__row tractor-table__row-total success"
          >
            <td *ngFor="let activeColumn of totalActiveRow">{{ activeColumn }}</td>
          </tr>
          <tr
            *ngIf="!isTotalsBoth"
            class="tractor-table__row tractor-table__row-total warning"
          >
            <td *ngFor="let stopedColumn of totalStopedRow">{{ stopedColumn }}</td>
          </tr>
          <tr
            *ngIf="isTotalsBoth"
            class="tractor-table__row tractor-table__row-total neutral"
          >
            <td *ngFor="let bothColumn of totalBothRow">{{ bothColumn }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</app-card>
