import { UpdateSupportTeamDetailModel } from './update-support-team-detail.model';

export class UpdateRhAndSsmaModel {
  [key: string]: any;

  constructor() {
    this.transportTypeName = null;
    this.operationsAnalyst = new UpdateSupportTeamDetailModel();
    this.hrTechnician = new UpdateSupportTeamDetailModel();
    this.securityTechnician = new UpdateSupportTeamDetailModel();
  }

  transportTypeName!: string | null;
  operationsAnalyst: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  hrTechnician: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
  securityTechnician: UpdateSupportTeamDetailModel = new UpdateSupportTeamDetailModel();
}
