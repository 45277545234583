import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, debounceTime, tap } from 'rxjs';
import { OperatorService } from 'src/app/services/operator/operator.service';
import { ButtonsType } from 'src/app/shared/enums/Buttonstypes';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-step',
  templateUrl: './contact-step.component.html',
  styleUrls: ['./contact-step.component.scss'],
})
export class ContactStepComponent implements AfterViewInit {
  @Input() form = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', Validators.email),
    phone: new FormControl(''),
    validatedEmail: new FormControl(''),
  });

  public eInputType = InputTypeEnum;
  public eInputMask = MaskTypeEnum;
  public eButtonsType = ButtonsType;
  public eSideIconType = SideIconTypeEnum;

  public emailLoading: boolean = false;
  public emailIsValid: boolean = false;
  public emailError: string = 'Informe um E-mail valido';
  public sub!: Subscription;

  public get InfoboxTypeEnum() { return InfoboxTypeEnum; }

  constructor(public operatorService: OperatorService) {}

  ngAfterViewInit(): void {
    this.form.controls.email.valueChanges.pipe(
      tap(() => {
        this.emailLoading = false;
        this.emailIsValid = false;
        this.emailError = 'Informe um E-mail valido';
        this.form.controls.validatedEmail.patchValue('');
        if (this.sub) {
          this.sub.unsubscribe();
        }
      }),
      debounceTime(1500),
      tap((value: any) => {
        if (value && value !== '' && !this.form.controls.email.valid) {
          this.form.controls.email.markAsPending();
        }
      }),
    ).subscribe((value) => {
      if (!value || value === '') return;
      this.emailLoading = true;
      this.validateEmail(value);
    });
  }

  validateEmail(value:string) {
    this.sub = this.operatorService.validateEmailsPublic(value ?? '')
      .subscribe({
        next: (result) => {
          this.emailLoading = false;
          if (result) {
            this.emailIsValid = true;
            this.form.controls.email.setErrors(null);
            this.form.controls.validatedEmail.patchValue('validated');
          }
        },
        error: (result: HttpErrorResponse) => {
          if (result instanceof HttpErrorResponse) {
            this.emailError = result.error.Message;
          } else {
            this.emailError = 'Não foi possivel validar o e-mail digitado';
          }
          this.emailLoading = false;
          this.emailIsValid = false;
          this.form.controls.email.setErrors({ corporateNameIsValid: false });
          this.form.controls.validatedEmail.patchValue('');
        },
      });
  }

  onGCertifica() {
    window.open(environment.GCertificaUrl, '_blank');
  }
}
