<div class="container" *ngIf="!isLoading">
  <section>
    <header>Cadastros</header>
    <main class="register">
      <app-card-option
        *ngFor="let card of cards"
        [icon]="card.icon"
        [title]="card.name"
        [description]="card.value"
        (callbackFunc)="navigateToPage(card.name)"
      ></app-card-option>
    </main>
  </section>
  <section>
    <header>Configurações</header>
    <main class="settings">
      <app-card-option
        icon="../../../assets/icons/solicitation-limit-primary-600.svg"
        title="Valor Limite das Solicitações"
        description="Atualizar o valor limite de aprovação para o Gerente de Operação e Gerente Corporativo"
        [fullDescription]="true"
        (callbackFunc)="navigateToValueLimit()"
      ></app-card-option>
      <app-card-option
        icon="../../../assets/icons/image.svg"
        title="Imagem de Capa"
        description="Atualizar a imagem de capa da página de login"
        [fullDescription]="true"
        (callbackFunc)="navigateCoverImage()"
      ></app-card-option>
    </main>
  </section>
</div>
<div class="card-container">
  <app-card icon="../../assets/icons/settings-common-100.svg" *ngIf="isLoading">
    <div class="skeleton" body *ngIf="isLoading">
      <app-skeleton-line [line]="4"></app-skeleton-line>
    </div>
  </app-card>
</div>
