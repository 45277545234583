import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({})),
      state('final', style({
        height: '0',
        paddingBottom: '0',
        opacity: '0',
      })),
      transition('initial <=> final', animate('300ms')),
    ]),
  ],
})
export class CardComponent {
  @Input() image: string = '';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() paddingHorizontal: boolean = true;
  @Input() emphasis: boolean = false;
  @Input() accordion: boolean = false;
  @Input() isLoading!: boolean;
  @Input() overflow: boolean = false;

  public open: boolean = true;

  toggleAccordion() {
    this.open = !this.open;
  }
}
