import { Utils } from 'src/app/common/utils/utils';
import { MenuItem } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ROUTE_DATA_ICON = 'icon';
  static readonly ROUTE_DATA_PARENT = 'parent';

  static readonly styleChild = 'style-child';

  public menuItems: MenuItem[] = [];
  public url = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utils: Utils
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems = this.createBreadcrumbs();
      }
    });
  }

  public createBreadcrumbs(
    route: ActivatedRoute = this.activatedRoute.root,
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const { children } = route;

    if (children === undefined || children.length === 0) {
      this.url = '';
      return breadcrumbs;
    }

    // for (const child of children) {
    const child = children[0];
    const routeURL: string = child.snapshot.url
      .map((segment) => segment.path)
      .join('/');
    if (routeURL !== '') {
      this.url += `/${routeURL}`;
    }

    if (child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PARENT]) {
      let label =
        child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PARENT].name;
      let urlParent =
        child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PARENT].path;

      if (urlParent.includes(':fixedCostId')) {
        let fixedCostId = this.url.slice(routeURL.indexOf('fixed-costs/') + 14);
        fixedCostId = fixedCostId.slice(0, fixedCostId.indexOf('/'));
        urlParent = urlParent.replace(':fixedCostId', fixedCostId);
      }

      if (urlParent.includes(':categoryId')) {
        const { categoryId } = child.snapshot.params;
        urlParent = urlParent.replace(':categoryId', categoryId);
        label = label.replace(':categoryId', categoryId.padStart(5, '0'));
      }

      breadcrumbs.push({
        label,
        url: urlParent,
        routerLink: urlParent,
        routerLinkActiveOptions: { exact: true },
        styleClass: `${BreadcrumbComponent.styleChild} ${
          child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_ICON]
        }`,
      });
    }

    let label = child.snapshot.data[
      BreadcrumbComponent.ROUTE_DATA_BREADCRUMB
    ] as string | undefined;
    if (label !== '' && label !== null && label !== undefined) {
      if (label.includes(':id')) {
        label = label.replace(':id', routeURL.padStart(5, '0'));
      }
      if (label.includes(':instanceId')) {
        label = label.replace(':instanceId', routeURL);
      }
      if (label.includes(':cnpj')) {
        label = label.replace(':cnpj', this.utils.formatCnpj(routeURL));
      }
      breadcrumbs.push({
        label,
        url: this.url,
        routerLink: this.url,
        routerLinkActiveOptions: { exact: true },
        styleClass: `${BreadcrumbComponent.styleChild} ${
          child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_ICON]
        }`,
      });
    }

    return this.createBreadcrumbs(child, breadcrumbs);
  }
}
