<div class="detail-container" (click)="handleDataClick(unit.unitId)">
  <div class="data-container">
    <span id="id">{{ unit.unitId.toString().padStart(5, "0") }}</span>
    <span id="name">{{ unit.name }}</span>
    <div id="status">
      <app-status
        [color]="
          unit.active ? StatusColorEnum.success : StatusColorEnum.warning
        "
        [status]="unit.active ? 'Ativo' : 'Inativo'"
        [image]="
          unit.active
            ? '../../../../../../assets/icons/active.svg'
            : '../../../../../../assets/icons/Exclude.svg'
        "
      ></app-status>
    </div>
  </div>
</div>
