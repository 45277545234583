<app-card [title]="'Histórico'" icon="../../../../assets/icons/categories-primary-600.svg">
  <div body *ngIf="isLoaded && fixedCostHistory && fixedCostHistory.length > 0">
      <app-history>
          <app-history-item  *ngFor="let history of fixedCostHistory"
              [accordion]="true"
              [date]="history.date"
              [type]="historyItemTypeEnum.normal"
              [hasDate]="history.date !== null">

            <div header class="header">
              <div class="analyst">
                  <span class="analystId">{{history.analystId}}</span>
                  <span class="analystName">
                      {{utils.formatName(history.analystName, 20)}}</span>
              </div>
            </div>
            <div body>
              <div class="solicitationBox" *ngIf="history.solicitationsSent && history.solicitationsSent.length > 0">
                <span>Solicitações enviadas</span>
                <div class="content">
                  <header>
                    <div>ID</div>
                    <div>TÍTULO</div>
                    <div>VALOR</div>
                  </header>
                    <div class="content-body" *ngFor="let solicitation of history.solicitationsSent">
                      <div class="content-id">{{solicitation.solicitationId}}</div>
                      <div class="content-title">{{solicitation.title}}</div>
                      <div [class]="getColorValue(solicitation.solicitationTypeName)">{{solicitation.negotiatedValue | currency : "BRL"}}</div>
                    </div>
                </div>
              </div>

              <div class="solicitationBox" *ngIf="history.solicitationsNotSent && history.solicitationsNotSent.length > 0">
                <span>Solicitações não enviadas</span>
                <div class="content">
                  <header>
                    <div>ID</div>
                    <div>TÍTULO</div>
                    <div>VALOR</div>
                  </header>
                    <div class="content-body" *ngFor="let solicitation of history.solicitationsNotSent">
                      <div class="content-id">{{solicitation.solicitationId}}</div>
                      <div class="content-title">{{solicitation.title}}</div>
                      <div [class]="getColorValue(solicitation.solicitationTypeName)">{{solicitation.negotiatedValue | currency : "BRL"}}</div>
                    </div>
                </div>
              </div>
              <div class="solicitationBox" *ngIf="history.solicitationsExcluded && history.solicitationsExcluded.length > 0">
                <span>Solicitações excluídas</span>
                <div class="content">
                  <header>
                    <div>ID</div>
                    <div>TÍTULO</div>
                    <div>VALOR</div>
                  </header>
                    <div class="content-body" *ngFor="let solicitation of history.solicitationsExcluded">
                      <div class="content-id">{{solicitation.solicitationId}}</div>
                      <div class="content-title">{{solicitation.title}}</div>
                      <div [class]="getColorValue(solicitation.solicitationTypeName)">{{solicitation.negotiatedValue | currency : "BRL"}}</div>
                    </div>
                </div>
              </div>
            </div>
          </app-history-item>
      </app-history>
  </div>
  <div body *ngIf="!isLoaded">
    <app-skeleton-text [text]="3"></app-skeleton-text>
  </div>
  <div body *ngIf="isLoaded && fixedCostHistory.length === 0">
    <app-not-found></app-not-found>
  </div>
</app-card>
