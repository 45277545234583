import { SolicitationService } from 'src/app/services/solicitation/solicitation.service';
import { HttpEventType } from '@angular/common/http';
import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalModel } from 'src/app/pages/pre-registration/models/modal.model';
import { GetAttachmentModel } from '../../services/solicitation/models/get-attachment-model';
import { FileUploadService } from './service/file-upload.service';

@Component({
  selector: 'app-attach',
  templateUrl: './attach.component.html',
  styleUrls: ['./attach.component.scss'],
})
export class AttachComponent implements OnInit, OnChanges {
  public allowedExtensions: string[] = [];
  public filesNames: string[] = [];
  public attachments: GetAttachmentModel[] = [];
  public modalModel = new ModalModel();

  @Input() control = new FormControl([] as File[]);
  @Input() selectedFiles: GetAttachmentModel[] = [];
  @Input() description: string = '';
  @Input() extensions: string = '';
  @Input() disabled!: boolean;
  @Input() totalSize: number = 0;
  @Input() isAnalyst!: boolean;
  @Input() loadingAttach!: boolean;
  @Input() title: string = 'Anexos';
  @Input() fileSize: number = 29360128;
  @Input() uploadLimit: number = 52428800;
  @Input() multiple: boolean = true;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() download:EventEmitter<string> = new EventEmitter<string>();
  @Output() fileSelectedCallBack: EventEmitter<any> = new EventEmitter<any>();

  constructor(private solicitationService: SolicitationService) { }

  ngOnChanges(): void {
    this.filesNames = this.selectedFiles.map((x) => x.fileName);
    this.selectedFiles.forEach((x) => {
      if (x.loading) {
        x.loading = this.loadingAttach;
      }
    });
  }

  ngOnInit(): void {
    this.allowedExtensions = this.extensions.split(',');
  }

  getFileExtension(name: string) {
    return name.substring(name.lastIndexOf('.'));
  }

  validateFile(file: File) {
    if (file.size > this.fileSize || (this.totalSize + file.size > this.uploadLimit)) {
      this.modalModel.imagePath = '../../../assets/icons/attention-600.gif';
      this.modalModel.title = 'Problema ao anexar arquivo';
      this.modalModel.description = 'O arquivo selecionado excede o tamanho máximo permitido.';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.modalModel.showModal = true;
      return false;
    }

    const extension = this.getFileExtension(file.name);
    if (!this.allowedExtensions.includes(extension)) {
      this.modalModel.imagePath = '../../../assets/icons/attention-600.gif';
      this.modalModel.title = 'Problema ao anexar arquivo';
      this.modalModel.description = 'Extensão de arquivo não permitida.';
      this.modalModel.buttonLabel = 'Entendi';
      this.modalModel.success = false;
      this.modalModel.showModal = true;
      return false;
    }

    return true;
  }

  closeModal() {
    this.modalModel.showModal = false;
  }

  onFileSelected(event: any) {
    const { files } = event.target as HTMLInputElement;

    Array.from(files || []).forEach((item) => {
      if (this.validateFile(item) && !this.filesNames.includes(item.name)) {
        if (this.selectedFiles.length > 0 && !this.multiple) {
          this.selectedFiles = [];
          this.filesNames = [];
        }
        this.filesNames.push(item.name);
        this.totalSize += item.size;
        this.selectedFiles.push({
          file: item,
          fileName: item.name,
        } as GetAttachmentModel);

        this.control.patchValue(this.selectedFiles.map((x) => x.file));
        this.readFileFromBuffer(item);
      }
    });
  }

  removeFile(file: File) {
    if (this.filesNames.includes(file.name)) {
      this.removeFileFromDataTransfer(file);
      this.filesNames.splice(this.filesNames.findIndex((f) => f === file.name), 1);
      this.selectedFiles.splice(this.selectedFiles.findIndex((f) => f.fileName === file.name), 1);
      this.control.patchValue(this.selectedFiles.map((x) => x.file));
      this.totalSize -= file.size;
    }
  }

  removeFileFromDataTransfer(file: File) {
    const dataTransfer = new DataTransfer();
    const files = Array.from(this.fileInput.nativeElement.files) as File[];
    const index = files.findIndex((f) => f.name === file.name);

    if (index === -1) return;

    files.splice(index, 1);

    files.forEach((f) => {
      dataTransfer.items.add(f);
    });

    this.fileInput.nativeElement.files = dataTransfer.files;
  }

  downloadFile(file: GetAttachmentModel) {
    file.loading = true;
    this.download.emit(file.fileName);
  }

  uploadFile(solicitationId:string) {
    return this.solicitationService.uploadFile(this.selectedFiles
      .filter((x) => !x.uploaded).map((x) => x.file), solicitationId);
  }

  readFileFromBuffer(file: File) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.fileSelectedCallBack.emit(reader.result);
    };
    reader.readAsDataURL(file);
  }
}
