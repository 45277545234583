import {
  Component, ElementRef, Input, OnInit, TemplateRef, ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit {
  @Input() label: string = '';
  @Input() valid: boolean = true;
  @Input() type: string = 'next';

  @ViewChild('stepBox') stepBox?: TemplateRef<ElementRef>;
  @ViewChild('title') title?: TemplateRef<ElementRef>;
  @ViewChild('content') content?: TemplateRef<ElementRef>;

  constructor() { }

  ngOnInit(): void {
  }
}
