/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable max-len */
/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map, mergeMap, catchError, tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FixedCostService } from 'src/app/services/fixedCosts/fixed-costs.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as FixedCostActions from './fixedCost.actions';
import { IFixedCostState } from './fixedCost.state';

@Injectable()
export class FixedCostEffects {
  loadGetFixedCostModel$ = createEffect(() => this.actions$.pipe(
    ofType(FixedCostActions.loadFixedCostsModel),
    tap(() => this.store.dispatch(FixedCostActions.setLoading({ isLoading: true }))),
    mergeMap(
      (action: ReturnType<typeof FixedCostActions.loadFixedCostsModel>) => this.service.getFixedCostById(action.id).pipe(
        map((data) => {
          this.store.dispatch(
            FixedCostActions.setLoading({ isLoading: false }),
          );
          return FixedCostActions.loadFixedCostsModelSuccess({ data });
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(
            FixedCostActions.setError({
              errorMessage: error.error,
            }),
          );
          this.route.navigate(['/fixed-costs']);
          return of({
            type: '[FixedCostStateHandler] Load FixedCost Failed',
          });
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<{ fixedCost: IFixedCostState }>,
    private service: FixedCostService,
    private route: Router,
  ) {}
}
