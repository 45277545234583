<div
  class="icon-button-container"
  [ngClass]="{
    disabled: !enabled,
    common: color === 'common',
    primary: color === 'primary',
    success: color === 'success',
    attention: color === 'attention',
    warning: color === 'warning'
  }"
  (click)="handleClick($event)"
>
  <div *ngIf="loading" class="btn-loading"></div>
  <img *ngIf="!loading" [src]="icon" alt="icon" />
  <strong>{{ text }}</strong>
</div>
