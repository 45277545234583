<div class="container">
  <app-card
    icon="../../../../assets/icons/categories-primary-600.svg"
    [title]="title"
  >
    <div class="form-container" body *ngIf="!isLoading">
      <label for="">{{ description }}</label>
      <app-toogle
        label="Ativo"
        [checked]="checked"
        (check)="handleToggleCheck()"
      ></app-toogle>
      <div class="input-group">
        <app-input
          class="name-input"
          label="Nome"
          [type]="eInputType.text"
          errorMessage="Este campo é obrigatório"
          [control]="form.controls.name"
          [maxlength]="50"
        ></app-input>
        <app-search
          title="Categoria PIMS"
          placeholder="ID Pims da Categoria"
          [loading]="categorySearch.loading"
          [loadingCard]="categorySearch.loadingCard"
          [selected]="categorySearch.selected"
          [control]="form.controls.categoryPimsId"
          [showModal]="categorySearch.showModal"
          [searchModel]="categorySearch.searchModel"
          [item]="categorySearch.item"
          [error]="categorySearch.error"
          [errorMessage]="categorySearch.errorMessage"
          (handleClick)="handleCategorySearchClick($event, categorySearch)"
          (excludeClick)="excludeCard(categorySearch)"
          (handleOutsideClick)="handleCloseModal(categorySearch)"
        ></app-search>
        <app-input
          label="Centro de Custo"
          placeholder="0000000000"
          errorMessage="Este campo é obrigatório"
          [control]="form.controls.costCenter"
          [mask]="eMaskType.number"
          [max]="9999999999"
          [min]="0"
          [showNumberActions]="false"
        ></app-input>
        <app-search
          title="Coordenador de Suprimentos"
          placeholder="ID do Coordenador de Suprimentos"
          [item]="coordSuprimentosSearch.item"
          [loading]="coordSuprimentosSearch.loading"
          [loadingCard]="coordSuprimentosSearch.loadingCard"
          [selected]="coordSuprimentosSearch.selected"
          [control]="form.controls.supCoord"
          [showModal]="coordSuprimentosSearch.showModal"
          [searchModel]="coordSuprimentosSearch.searchModel"
          [item]="coordSuprimentosSearch.item"
          [error]="coordSuprimentosSearch.error"
          [errorMessage]="coordSuprimentosSearch.errorMessage"
          (handleClick)="handleSearchClick($event, coordSuprimentosSearch)"
          (handleCardClick)="handleCardClick(coordSuprimentosSearch)"
          (excludeClick)="excludeCard(coordSuprimentosSearch)"
          (handleOutsideClick)="handleCloseModal(coordSuprimentosSearch)"
        ></app-search>
        <app-search
          title="Coordenador Corporativo"
          placeholder="ID do Coordenador Corporativo"
          [item]="coordCorporativoSearch.item"
          [loading]="coordCorporativoSearch.loading"
          [loadingCard]="coordCorporativoSearch.loadingCard"
          [selected]="coordCorporativoSearch.selected"
          [control]="form.controls.corpCoord"
          [showModal]="coordCorporativoSearch.showModal"
          [searchModel]="coordCorporativoSearch.searchModel"
          [item]="coordCorporativoSearch.item"
          [error]="coordCorporativoSearch.error"
          [errorMessage]="coordCorporativoSearch.errorMessage"
          (handleClick)="handleSearchClick($event, coordCorporativoSearch)"
          (handleCardClick)="handleCardClick(coordCorporativoSearch)"
          (excludeClick)="excludeCard(coordCorporativoSearch)"
          (handleOutsideClick)="handleCloseModal(coordCorporativoSearch)"
        ></app-search>
        <app-search
          title="Gerente Corporativo"
          placeholder="ID do Gerente Corporativo"
          [item]="corporateManagerSearch.item"
          [loading]="corporateManagerSearch.loading"
          [loadingCard]="corporateManagerSearch.loadingCard"
          [selected]="corporateManagerSearch.selected"
          [control]="form.controls.corpManager"
          [showModal]="corporateManagerSearch.showModal"
          [searchModel]="corporateManagerSearch.searchModel"
          [item]="corporateManagerSearch.item"
          [error]="corporateManagerSearch.error"
          [errorMessage]="corporateManagerSearch.errorMessage"
          (handleClick)="handleSearchClick($event, corporateManagerSearch)"
          (handleCardClick)="handleCardClick(corporateManagerSearch)"
          (excludeClick)="excludeCard(corporateManagerSearch)"
          (handleOutsideClick)="handleCloseModal(corporateManagerSearch)"
        ></app-search>
        <app-search
          title="Diretor Corporativo"
          placeholder="ID do Diretor Corporativo"
          [item]="corporateDirectorSearch.item"
          [loading]="corporateDirectorSearch.loading"
          [loadingCard]="corporateDirectorSearch.loadingCard"
          [selected]="corporateDirectorSearch.selected"
          [control]="form.controls.corpDirector"
          [showModal]="corporateDirectorSearch.showModal"
          [searchModel]="corporateDirectorSearch.searchModel"
          [item]="corporateDirectorSearch.item"
          [error]="corporateDirectorSearch.error"
          [errorMessage]="corporateDirectorSearch.errorMessage"
          (handleClick)="handleSearchClick($event, corporateDirectorSearch)"
          (handleCardClick)="handleCardClick(corporateDirectorSearch)"
          (excludeClick)="excludeCard(corporateDirectorSearch)"
          (handleOutsideClick)="handleCloseModal(corporateDirectorSearch)"
        ></app-search>
      </div>
      <div class="btn-group">
        <app-secondary-button
          label="Voltar"
          [enabled]="true"
          icon="../../../assets/icons/arrow-previous-primary-600.svg"
          (clickButton)="goBack()"
        ></app-secondary-button>
        <app-buttons
          [label]="buttonLabel"
          [color]="eButtonsColor.primary"
          [type]="eButtonsType.primary"
          [loading]="loadingButton"
          [enabled]="enableButton"
          (clickButton)="id ? updateHandleButtonClick() : handleButtonClick()"
        ></app-buttons>
      </div>
    </div>
    <div class="skeleton" body>
      <app-skeleton-input [input]="6" *ngIf="isLoading"></app-skeleton-input>
      <app-skeleton-box [box]="2" *ngIf="isLoading"></app-skeleton-box>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
