export class CreateSolicitationModel {
  fantasyName: string = '';
  unitName: string = '';
  categoryName: string = '';
  subcategoryName: string = '';
  paymentTypeName: string = '';
  value: number = 0;
  title: string = '';
  description: string = '';
  attachments: File[] = [];
  solicitationTypeName: string = '';
  equipmentQuantity: number = 0;
  equipmentUnitaryValue: number = 0;
  recurrenceSolicitation: boolean = false;
}
