import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, map, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage.service';
import { IDfdvState } from 'src/app/common/store/dfdv/dfdv.state';
import { ILoadingState } from 'src/app/common/store/fixedCost/fixedCost.state';
import { DfdvUtils } from 'src/app/common/utils/dfdv-utils';
import { GetDfdvModel } from 'src/app/services/dfdv/models/getDfdv.model';
import { TaxModel } from 'src/app/services/dfdv/taxes/models/tax.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';
import * as DfdvActions from '../../../../common/store/dfdv/dfdv.actions';
import { TaxCheckBoxModel } from './models/tax-check-box.model';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
})
export class TaxComponent implements OnInit, OnDestroy {
  // Error Messages
  public errorMessage = 'Este campo é obrigatorio';

  // Enum
  public eInputType = InputTypeEnum;
  public eMaskType = MaskTypeEnum;
  public eSideIconType = SideIconTypeEnum;
  public ButtonColorEnum = ButtonColorEnum;

  // Variables
  public shouldDisableFields: boolean = false;
  public dfdvLoading: boolean = false;
  public isLoading: boolean = true;
  public hasErrorMessage: string = '';
  public dfdv: GetDfdvModel = new GetDfdvModel();
  public dfdvUtils!: DfdvUtils;

  // Check-Box Aux
  public taxCbModel: TaxCheckBoxModel = new TaxCheckBoxModel();

  taxStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv.tax));
  dfdvStore$ = this.store.select('dfdv').pipe(map((x) => x.dfdv));
  loadingStore$ = this.store.select('loading').pipe((x) => x);
  public destroy$ = new Subject<void>();
  public unSubscribe!: Subscription;

  // Form
  public form = this.formBuilder.group({
    pis: [''],
    cofins: [''],
    iss: [''],
    icms: [''],
    icmsWithoutComplement: [''],
    icmsCreditWithoutInput: [''],
    payrollEncumbrance: [''],
    hasPayrollEncumbrance: [false],
    isIcmsCreditAllowed: [false],
    hasPresumedProfit: [false],
    hasPresumedIcmsCredit: [false],
    isCreditWithoutAssetAllowed: [false],
  });

  constructor(
    public formBuilder: FormBuilder,
    public storageService: StorageService,
    public route: ActivatedRoute,
    public store: Store<{ dfdv: IDfdvState; loading: ILoadingState }>,
  ) {}

  ngOnInit(): void {
    this.getDFDV();
  }

  ngOnDestroy() {
    this.unSubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  shouldDisablePayroll() {
    return this.shouldDisableFields || !this.taxCbModel.hasPayrollEncumbrance;
  }

  getDFDV() {
    this.unSubscribe = this.taxStore$.subscribe((tax) => {
      if (!tax) {
        this.route.paramMap.subscribe((params) => {
          const id = params.get('id');

          this.loadingStore$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              this.dfdvLoading = isLoading.loading;
            });

          if (!this.dfdvLoading) {
            this.dfdvStore$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
              if (!x.dfdvId || x.dfdvId !== +(id ?? 0)) {
                this.store.dispatch(
                  DfdvActions.loadGetDfdvModel({ id: id ?? '0' }),
                );
              }
            });
          }
        });
      } else {
        this.isLoading = false;
        this.setTaxes(tax);
      }
    });

    this.dfdvStore$.subscribe((dfdv) => {
      this.dfdv = { ...dfdv };
      if (dfdv) {
        this.dfdvUtils = new DfdvUtils(dfdv, this.storageService.getRole());
        this.shouldDisableFields = !this.dfdvUtils.isEnableToEdit() || !this.dfdvUtils.isUserRaizen();
      }
    });
  }

  onCheckItem(cb: string, state: boolean) {
    if (cb === 'isIcmsCreditAllowed') {
      this.taxCbModel.isIcmsCreditAllowed = state;
      this.form.controls.isIcmsCreditAllowed.patchValue(state);
    } else if (cb === 'hasPayrollEncumbrance') {
      this.taxCbModel.hasPayrollEncumbrance = state;
      this.form.controls.hasPayrollEncumbrance.patchValue(state);
      if (!this.taxCbModel.hasPayrollEncumbrance) {
        this.form.controls.payrollEncumbrance.patchValue('0');
      }
    } else if (cb === 'hasPresumedIcmsCredit') {
      this.taxCbModel.hasPresumedIcmsCredit = state;
      this.form.controls.hasPresumedIcmsCredit.patchValue(state);
    } else if (cb === 'isCreditWithoutAssetAllowed') {
      this.taxCbModel.isCreditWithoutAssetAllowed = state;
      this.form.controls.isCreditWithoutAssetAllowed.patchValue(state);
    } else if (cb === 'hasPresumedProfit') {
      this.taxCbModel.hasPresumedProfit = state;
      this.form.controls.hasPresumedProfit.patchValue(state);
    }

    this.updateDFDVStore();
  }

  setTaxes(model: TaxModel | null) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.patchValue(model ? model[key] : '');
      this.taxCbModel[key] = model ? model[key] : false;
    });
  }

  processForm(model: TaxModel) {
    Object.keys(this.form.controls).forEach((key) => {
      model[key] = this.form.get(key)?.value === null
                || this.form.get(key)?.value === undefined
                || this.form.get(key)?.value === '' ? '0' : this.form.get(key)?.value;
    });
  }

  isFormValid() {
    let isValid = true;
    this.form.updateValueAndValidity();
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.updateValueAndValidity();
    });
    isValid = isValid && this.form.valid;
    return isValid;
  }

  updateDFDVStore() {
    let isValid = true;
    isValid = this.isFormValid() && isValid;
    if (!isValid) {
      this.hasErrorMessage = 'Os seguintes campos em vermelho e marcados com * são de preenchimento obrigatório.';
      return;
    }

    const model = new TaxModel();
    this.processForm(model);
    this.dfdv.tax = model;

    this.store.dispatch(
      DfdvActions.updateSubobject({ subObjectName: 'tax', data: model }),
    );
  }
}
