<div class="tires-container">
  <div class="tires-card">
    <app-card
      icon="../../../../assets/icons/graphic-common.svg"
      [title]="'Totalizadores'"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-line [line]="5"></app-skeleton-line>
      </div>
      <div *ngIf="!isLoading" class="card-body" body>
        <div class="info">
          <label class="info-title">
            <span class="info-text">Custo unitário total</span>
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  Custo Unit. Pneu + Qtd Recauchutagem * Valor Recauchutagem
                </div>
              </div>
            </app-tooltip>
          </label>
          <label class="info-value info-total">
            {{ unitCostTotal | currency : "BRL" }}
          </label>
        </div>
        <div class="info">
          <label class="info-title">
            <span class="info-text">Subtotal com Conserto</span>
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  Custo Unitário Total / Vida Útil do Pneu em KM
                </div>
              </div>
            </app-tooltip>
          </label>
          <label class="info-value info-total">
            {{ subtotalWithFix | currency : "BRL" }}
          </label>
        </div>
        <div class="info">
          <label class="info-title">
            <span class="info-text">Custo Unitário de Pneus/KM</span>
            <app-tooltip [top]="true">
              <div class="tooltip-info">
                <div class="text">
                  Subtotal com Conserto * Quantidade de Pneus
                </div>
              </div>
            </app-tooltip>
          </label>
          <label class="info-value info-total">
            {{ unitCostOfTiresPerKm | currency : "BRL" }}
          </label>
        </div>
      </div>
    </app-card>
    <app-card
      icon="../../../../assets/icons/tyre-common.svg"
      [title]="'Custos Variáveis com Pneus'"
      [accordion]="true"
    >
      <div *ngIf="isLoading" class="skeleton" body>
        <app-skeleton-input [input]="6"></app-skeleton-input>
      </div>
      <div *ngIf="!isLoading" class="body" body>
        <app-input
          label="Marca do Pneu"
          placeholder="Tipo de Pneu"
          [control]="tiresForm.controls.tireBrand"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-select
          [field]="'Tipo de Pneu'"
          placeholder="Tipo de Pneu"
          [singleControl]="tiresForm.controls.tireTypeName"
          [errorMessage]="errorMessage"
          [selected]="selectedTireTypes"
          [options]="optionsTireTypes"
          [multi]="false"
          [disabled]="shouldDisableFields"
          (statusChanged)="handleUpdateValue()"
        ></app-select>
        <app-input
          label="Quantidade de Pneus (Cavalo Mecânico + Conjunto)"
          placeholder="0"
          [type]="eInputType.number"
          [mask]="eInputMask.number"
          [control]="tiresForm.controls.tireQuantity"
          [showNumberActions]="true"
          [max]="999999999999"
          [min]="0"
          [disabled]="disableFieldQuantity"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Custo Unitário do Pneu"
          placeholder="0,00"
          [type]="eInputType.number"
          [mask]="eInputMask.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [control]="tiresForm.controls.tireCost"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Quantidade Recauchutagem"
          placeholder="0"
          [type]="eInputType.number"
          [mask]="eInputMask.number"
          [control]="tiresForm.controls.retreadingQuantity"
          [showNumberActions]="true"
          [max]="999999999999"
          [min]="0"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Valor Recauchutagem"
          placeholder="0,00"
          [type]="eInputType.number"
          [mask]="eInputMask.money"
          [showSideIcon]="true"
          sideIconLabel="R$"
          [sideIconType]="eSideIconType.text"
          [control]="tiresForm.controls.retreadingCost"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
        <app-input
          label="Vida Útil do Pneu em KM"
          placeholder="0"
          [type]="eInputType.number"
          [mask]="eInputMask.number"
          [control]="tiresForm.controls.tireLifespanKm"
          [showNumberActions]="true"
          [max]="999999999999"
          [min]="0"
          [disabled]="shouldDisableFields"
          (updateValue)="handleUpdateValue()"
        ></app-input>
      </div>
    </app-card>
  </div>
</div>
