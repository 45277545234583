import {
  Component, OnInit, ViewChild,
} from '@angular/core';
import { LoggedUserModel } from 'src/app/common/models/logged-user.model';
import { StorageService } from 'src/app/common/services/storage.service';
import { DfdvService } from 'src/app/services/dfdv/dfdv.service';
import { GetDfdvFilterModel } from 'src/app/services/dfdv/models/get-dfdv-filter.model';
import { GetDfdvPaged } from 'src/app/services/dfdv/models/get-dfdv-paged.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { DfdvDetailModel } from '../../services/dfdv/models/dfdv-detail.model';
import { DfdvCreateModalComponent } from './dfdv-create-modal/dfdv-create-modal.component';

@Component({
  selector: 'app-dfdv',
  templateUrl: './dfdv.component.html',
  styleUrls: ['./dfdv.component.scss'],
})
export class DfdvComponent implements OnInit {
  public dfdvFilter = new GetDfdvFilterModel();
  public dfdvList: DfdvDetailModel[] = [];

  public enableNewDFDV: boolean = false;
  public displaySidenav: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = true;
  public isLoadingFilter: boolean = true;
  public filters: GetDfdvFilterModel | undefined;
  public username!: string;
  public userRole: string = '';
  public page!: number;
  public totalPages!: number;
  public loggedUser!: LoggedUserModel;
  public showModal: boolean = false;

  @ViewChild('createModal') createModal!: DfdvCreateModalComponent;

  constructor(
    private dfdvService: DfdvService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.username = this.storageService.getUser();

    const userRole = this.storageService.getRole();
    this.enableNewDFDV = userRole.name.includes('Admin') || userRole.name.includes('Analista');
  }

  public get ButtonColorEnum(): typeof ButtonColorEnum {
    return ButtonColorEnum;
  }

  getDfdvFiltered(filters: GetDfdvFilterModel) {
    this.isLoading = true;
    this.filters = filters;
    this.dfdvService.getDfdvFiltered(filters).subscribe({
      next: this.handleDfdvSuccess.bind(this),
      error: this.handleDfdvError.bind(this),
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  getDfdv() {
    this.dfdvService.getDfdvFiltered(this.dfdvFilter).subscribe({
      next: this.handleDfdvSuccess.bind(this),
      error: this.handleDfdvError.bind(this),
      complete: () => {
        this.isLoading = false;
        this.error = false;
      },
    });
  }

  handleDfdvSuccess(res: GetDfdvPaged) {
    this.dfdvList = [];
    this.dfdvList = res.items?.map(
      (element) => ({
        dfdvId: element.dfdvId,
        unitName: element.unitName,
        categoryName: element.categoryName,
        fantasyName: element.fantasyName,
        operatorId: element.operatorId,
        creationDate: element.creationDate,
        startDate: element.startDate,
        endDate: element.endDate,
        version: element.version,
        previousVersionId: element.previousVersionId,
        nextVersionId: element.nextVersionId,
        dfdvTypeName: element.dfdvTypeName,
        dfdvStatusName: element.dfdvStatusName,
        invoicing: {
          fixedCostInvoicing: element.invoicing?.fixedCostInvoicing ?? 0,
          variableCostInvoicing: element.invoicing?.variableCostInvoicing ?? 0,
          otherCostEstimatedInvoicing: element.invoicing?.otherCostEstimatedInvoicing ?? 0,
          preHarvestInvoicing: element.invoicing?.preHarvestInvoicing ?? 0,
          betweenHarvestInvoicing: element.invoicing?.betweenHarvestInvoicing ?? 0,
          totalInvoicing: element.invoicing?.totalInvoicing ?? 0,
        },
      } as DfdvDetailModel),
    );
    this.page = res.pageNumber;
    this.totalPages = res.items ? res.totalPages : 0;
  }

  handleDfdvError() {
    this.dfdvList = [];
    this.error = true;
    this.isLoading = false;
  }

  handleDfdvCallBack(response: GetDfdvFilterModel) {
    this.dfdvFilter = response;
    this.getDfdv();
  }

  setUserRole(useRole: string) {
    this.userRole = useRole;
  }

  navigateBack(event: boolean) {
    if (event) {
      this.reload();
    }
  }

  reload() {
    this.isLoading = true;
    this.showModal = false;
    if (this.filters) {
      this.getDfdvFiltered(this.filters);
    } else {
      this.getDfdv();
    }
  }

  setPage(index: number) {
    this.isLoading = true;
    if (this.filters) {
      this.filters.pageNumber = index;
      this.getDfdvFiltered(this.filters);
    } else {
      this.dfdvFilter.pageNumber = index;
      this.getDfdv();
    }
  }

  openCreateNewDfdvModal() {
    this.createModal.showModal = true;
  }
}
