<div class="container">
  <app-card title="Imagem de Capa" icon="../../../../../assets/icons/image.svg">
    <section body *ngIf="!isLoading && !error">
      <label class="text"
        >Favor enviar o arquivo desejado, para que possamos atualizar a imagem
        de capa da página de login.</label
      >
      <app-attach
        #attach
        [control]="form.controls.file"
        description="(arquivos suportados: .jpg, .png, com até 2mb)"
        extensions=".jpg,.png"
        title="Imagem"
        [totalSize]="size"
        [fileSize]="size"
        [multiple]="false"
        (fileSelectedCallBack)="showFile($event)"
      ></app-attach>
      <div class="preview" *ngIf="coverImage.content">
        <label class="title">Pré-visualização</label>
        <div class="preview-image">
          <img [src]="coverImage.content" alt="cover image" />
          <div
            class="cropped-image"
            [ngStyle]="{ 'background-image': 'url(' + image + ')' }"
          ></div>
        </div>
      </div>
      <div class="buttons">
        <app-secondary-button
          label="VOLTAR"
          icon="../../../../assets/icons/arrow-slate100.svg"
          [right]="false"
          [enabled]="true"
          (click)="navigateBack()"
        ></app-secondary-button>
        <app-buttons
          label="SALVAR"
          [color]="eButtonsColor.primary"
          [type]="eButtonsType.primary"
          [loading]="loadingButton"
          [enabled]="enableButton"
          (clickButton)="save()"
        ></app-buttons>
      </div>
    </section>
    <div body class="skeleton" *ngIf="isLoading && !error">
      <app-skeleton-line [line]="3"></app-skeleton-line>
    </div>
    <div body class="error" *ngIf="!isLoading && error">
      <app-error (reloadCallBack)="reload()"></app-error>
    </div>
  </app-card>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
