<aside
  *ngIf="displaySidenav"
  (clickOutside)="hideMenu()"
  [@smoothCollapseHorizontal]="openMenu ? 'initial' : 'final'"
  [@smoothCollapseVertical]="showMenu ? 'initial' : 'final'"
>
  <div class="content">
    <header class="header-desktop" (click)="toggleOpenMenu()">
      <img src="../../../assets/icons/logo-primary-400.svg" alt="Portal OPL" />
      <h1>Portal OPL</h1>
    </header>
    <header class="header-mobile">
      <div class="identifier">
        <img
          src="../../../assets/icons/logo-primary-400.svg"
          alt="Portal OPL"
        />
        <h1>Portal OPL</h1>
      </div>
      <div class="menu" (click)="toggleShowMenu()">
        <img
          [src]="
            showMenu
              ? '../../../assets/icons/close-common-100.svg'
              : '../../../assets/icons/menu-common-100.svg'
          "
          alt="Menu"
        />
      </div>
    </header>
    <div class="logged-user" *ngIf="menuLogout">
      <app-logged-user
        text="Seja muito bem vindo"
        [userName]="loggedUser.userName"
        [userId]="loggedUser.userId"
        [userRole]="loggedUser.userRoleName"
        [pipeText]="loggedUser.userRoleName ? '|' : ''"
      ></app-logged-user>
    </div>
    <app-menu #menu>
      <app-menu-item
        title="Pré-Cadastro"
        icon="../../../assets/icons/user-common-100.svg"
        [showMenuItem]="menuPreRegister"
        [selected]="selectedMenu === 0"
        (clickCallBack)="navigateToHome()"
      ></app-menu-item>
      <app-menu-item
        title="Home"
        icon="../../../assets/icons/home-common-100.svg"
        [showMenuItem]="false"
        [selected]="selectedMenu === 1"
        (clickCallBack)="navigateToHome()"
      ></app-menu-item>
      <app-menu-item
        title="Solicitações"
        icon="../../../assets/icons/solicitation-common-100.svg"
        [showMenuItem]="menuSolicitations"
        [selected]="selectedMenu === 2"
        (clickCallBack)="navigateToSolicitation()"
      ></app-menu-item>
      <app-menu-item
        title="Admin"
        icon="../../../assets/icons/settings-common-100.svg"
        [showMenuItem]="menuAdmin"
        [selected]="selectedMenu === 3"
        (clickCallBack)="navigateToAdmin()"
      ></app-menu-item>
      <app-menu-item
        title="DFDV"
        icon="../../../assets/icons/calc.svg"
        [showMenuItem]="menuDfdv"
        [hasChild]="this.id !== null"
        [selected]="selectedMenu >= 5"
        (clickCallBack)="navigateToDfdv()"
        [open]="this.id !== null"
      >
        <div class="DFDV">
          <app-menu-item
            title="Usina"
            [selected]="selectedMenu === 47"
            icon="../../../assets/icons/dot.svg"
            (clickCallBack)="navigateToPlant()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Escopo"
            [selected]="selectedMenu === 55"
            icon="../../../assets/icons/dot.svg"
            (clickCallBack)="navigateToScope()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Fator Motorista"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 40"
            [submenu]="true"
            (clickCallBack)="navigateToDriversFactor()"
          ></app-menu-item>
          <app-menu-item
            title="Lucro"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 52"
            [submenu]="true"
            (clickCallBack)="navigateToProfit()"
          ></app-menu-item>
          <app-menu-item
            title="Benefícios"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 51"
            (clickCallBack)="navigateToBenefits()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Encargos"
            [selected]="selectedMenu === 49"
            icon="../../../assets/icons/dot.svg"
            (clickCallBack)="navigateToCharges()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Impostos"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 48"
            (clickCallBack)="navigateToTaxes()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Parâmetros RV"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 53"
            [submenu]="true"
            (clickCallBack)="navigateToRvParameter()"
          ></app-menu-item>
          <app-menu-item
            title="Despesas Gerais"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 43"
            (clickCallBack)="navigateToGeneralCost()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="EQ Operacional"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 54"
            (clickCallBack)="navigateToOperationalTeam()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="EQ Apoio"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 44"
            (clickCallBack)="navigateToSupportTeam()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Frota"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 45"
            (clickCallBack)="navigateToFleet()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Custo Variável (CV)"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 50"
            (clickCallBack)="navigateToVariableCost()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Resumo"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 56"
            (clickCallBack)="navigateToSummary()"
            [submenu]="true"
          ></app-menu-item>
          <app-menu-item
            title="Tabela"
            icon="../../../assets/icons/dot.svg"
            [selected]="selectedMenu === 57"
            (clickCallBack)="navigateToTable()"
            [submenu]="true"
          ></app-menu-item>
        </div>
      </app-menu-item>
      <app-menu-item
        title="Lançamento Fixo"
        icon="../../../assets/icons/Curve-Arrow-White.svg"
        [showMenuItem]="menuFixedCost"
        [selected]="selectedMenu === 4"
        (clickCallBack)="navigateToFixedCost()"
      ></app-menu-item>
    </app-menu>
    <ul>
      <div class="logged-user-bottom" *ngIf="menuLogout">
        <app-logged-user
          [userName]="loggedUser.userName"
          [userId]="loggedUser.userId"
          [userRole]="loggedUser.userRoleName"
          [pipeText]="loggedUser.userRoleName ? '|' : ''"
        ></app-logged-user>
      </div>
      <li *ngIf="menuLogout" (click)="logout()">
        <img src="../../../assets/icons/logout-common-100.svg" alt="Logout" />
        <span>Logout</span>
      </li>
      <li
        class="pre-register"
        *ngIf="menuPreRegister"
        (click)="navigateToLogin()"
      >
        <img src="../../../assets/icons/logout-common-100.svg" alt="Login" />
        <span>Login</span>
      </li>
    </ul>
  </div>
</aside>
