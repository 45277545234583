import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { UniformOperationalTeamModel } from 'src/app/services/dfdv/general-costs/models/operational-team/uniform-operationalTeam.model';
import { InfoboxTypeEnum } from 'src/app/shared/infobox/enums/infobox-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { GeneralCostUtils } from '../general-cost.utils';

@Component({
  selector: 'app-operational-team-uniform',
  templateUrl: './operational-team-uniform.component.html',
  styleUrls: ['./operational-team-uniform.component.scss'],
})
export class OperationalTeamUniformComponent implements OnInit {
  @Input() isLoading: boolean = false;
  @Input() hasError: boolean = false;
  @Input() harvestMonths: number = 0;
  @Input() shouldDisableFields: boolean = false;
  @Output() updateValue: EventEmitter<boolean> = new EventEmitter();

  // Mobile Control
  public card: boolean = false;
  public widthBreakpoint: number = 768;
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  public uniformOperationalTeam = this.formBuilder.group({
    rows: this.formBuilder.array([this.buildRow()]),
  });

  public utils: GeneralCostUtils = new GeneralCostUtils();

  public height: string = '2.5rem';
  public numberActionsWidth: string = '2rem';
  public eMaskType = MaskTypeEnum;
  public eInfoboxType = InfoboxTypeEnum;

  public rowList: string[] = [
    'Calça (com faixa)',
    'Camisa (com faixa)',
    'Botina',
    'Luva de Raspa / Grafatex',
    'Óculos de Segurança',
    'Capacete de Segurança',
    'Kit Higiene',
    'Protetor Auricular',
    'Perneira',
    'Moletom de Frio',
    'Capa de chuva',
  ];
  public header: string[] = [
    'DESCRIÇÃO',
    'QUANTIDADE',
    'CUSTO UNITÁRIO',
    'CUSTO ANUAL',
    'CUSTO MENSAL',
  ];

  public totalMonth = 0;
  public totalHarvest = 0;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.card = window.innerWidth < this.widthBreakpoint;
    });
    this.buildTable();
  }

  buildTable() {
    Object.keys(new UniformOperationalTeamModel()).forEach(() => {
      this.uniformOperationalTeam.controls.rows.push(this.buildRow());
    });
    this.uniformOperationalTeam.controls.rows.removeAt(0);
  }

  buildRow() {
    return this.formBuilder.group({
      quantity: [''],
      cost: [''],
    });
  }

  calcTotal(harvest: boolean) {
    let total = 0;
    this.uniformOperationalTeam.controls.rows.controls.forEach((element) => {
      const cost = element.get('cost')?.value ?? '0';
      const quantity = element.get('quantity')?.value ?? '0';
      let result = 0;
      if (harvest) {
        result = +cost * +quantity;
      } else {
        result = (+cost * +quantity) / this.harvestMonths;
      }
      total += result;
    });

    return total;
  }

  handleUpdateValue() {
    this.updateValue.emit();
  }
}
