export class GetOperatorModel {
  operatorId: string = '';
  companyName: string = '';
  fantasyName: string = '';
  stateRegistration: string = '';
  municipalRegistration: string = '';
  companySize: string = '';
  employeeName: string = '';
  street: string = '';
  number: string = '';
  complement: string = '';
  district: string = '';
  zipCode: string = '';
  state: string = '';
  city: string = '';
  contactName: string = '';
  email: string = '';
  phone: string = '';
  active!: boolean;
  operatorProfileId?: number;
  createSolicitationAllowed!: boolean;
}
