<div class="card-container">
  <app-card
    title="Aliquotas, Créditos e Outros"
    icon="./assets/icons/graphic-common.svg"
    [accordion]="true"
  >
    <div *ngIf="dfdvLoading" class="skeleton" body>
      <app-skeleton-line [line]="3"></app-skeleton-line>
    </div>
    <div *ngIf="!dfdvLoading" class="card-body" body>
      <app-summary-info-template
        infoText="PIS no Estado"
        tooltipText="<b>PIS:</b> Programa de Integração Social"
        value=" {{ tax.pis | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="COFINS no Estado"
        tooltipText="<b>COFINS:</b> Contribuição para o Financiamento da Seguridade Social"
        value=" {{ tax.cofins | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="ICMS no Estado"
        tooltipText="<b>ICMS:</b> Imposto sobre Circulação de Mercadoriase Prestação de Serviços de Transporte Interestadual e Intermunicipal e de Comunicação"
        value=" {{ tax.icms | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="ISS no Município"
        tooltipText="<b>ISS:</b> Imposto Sobre Serviços"
        value=" {{ tax.iss | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Alíquota ICMS s/ Complemento"
        value=" {{ tax.icmsWithoutComplement | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Crédito ICMS s/ Insumos"
        value=" {{ tax.icmsCreditWithoutInput | number : '1.2-2' }} %"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Permite Crédito ICMS?"
        [value]="tax.isIcmsCreditAllowed"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Lucro Presumido?"
        [value]="tax.hasPresumedProfit"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Crédito ICMS Presumido?"
        [value]="tax.hasPresumedIcmsCredit"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Permite Crédito s/ Ativos?"
        [value]="tax.isCreditWithoutAssetAllowed"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Oneração da Folha de Pagamento?"
        [value]="tax.hasPayrollEncumbrance"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Oneração da Folha de Pagamento"
        value="{{ tax.payrollEncumbrance | number : '1.2-2' }} %"
      ></app-summary-info-template>
    </div>
  </app-card>

  <app-card
    title="Frota"
    icon="./assets/icons/graphic-common.svg"
    [accordion]="true"
  >
    <div *ngIf="dfdvLoading" class="skeleton" body>
      <app-skeleton-line [line]="1"></app-skeleton-line>
    </div>
    <div *ngIf="!dfdvLoading" class="card-body-3" body>
      <app-summary-info-template
        infoText="Quantidade Total Cavalo Mecânico"
        value=" {{ scope?.equipment?.tractorQuantity ?? 0 }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Quantidade Total Conjunto"
        value=" {{ scope?.equipment?.trailerQuantity ?? 0 }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Fator Conjunto"
        value=" {{ scope?.equipment?.jointFactor ?? 0 | number : '1.2-2' }}"
      ></app-summary-info-template>
    </div>
  </app-card>

  <app-card
    title="Lucro"
    icon="./assets/icons/graphic-common.svg"
    [accordion]="true"
  >
    <div *ngIf="dfdvLoading" class="skeleton" body>
      <app-skeleton-line [line]="1"></app-skeleton-line>
    </div>
    <div *ngIf="!dfdvLoading" class="card-body-3" body>
      <app-summary-info-template
        infoText="Lucro Fixo"
        value="{{ fleet.active.averageActiveFixedProfit | currency : 'BRL' }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Lucro Variável"
        value="{{
          fleet.active.averageActiveVariableProfit | currency : 'BRL'
        }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Lucro Total"
        value="{{ fleet.active.averageActiveTotalProfit | currency : 'BRL' }}"
      ></app-summary-info-template>
    </div>
  </app-card>

  <app-card
    title="Totalizadores"
    icon="./assets/icons/graphic-common.svg"
    [accordion]="true"
    class="totalizers"
  >
    <div *ngIf="dfdvLoading" class="skeleton" body>
      <app-skeleton-line [line]="2"></app-skeleton-line>
    </div>
    <div *ngIf="!dfdvLoading" class="card-body-2" body>
      <app-summary-info-template
        infoText="Custo Fixo s/ Imposto"
        tooltipText="Remuneração Fixa + Equipe Operacional + Despesa Pessoal + Despesas Operacionais + Lucro Fixo"
        value="{{ summary?.totalFixedCost | currency : 'BRL' }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Custo Variável s/ Imposto"
        tooltipText="Custos Variáveis Linha + Lucro Variável"
        value="{{ summary?.totalVariableCost | currency : 'BRL' }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Custo Fixo c/ Imposto"
        value="{{ summary?.totalFixedCostWithTax | currency : 'BRL' }}"
      ></app-summary-info-template>
      <app-summary-info-template
        infoText="Custo Variável c/ Imposto"
        value="{{ summary?.totalVariableCostWithTax | currency : 'BRL' }}"
      ></app-summary-info-template>
    </div>
  </app-card>

  <header>Custos Fixos</header>

  <app-dynamic-table
    class="fixed-remunerations"
    [headers]="['DESCRIÇÃO', 'VALOR']"
    [icon]="'assets/icons/hand-money.svg'"
    [title]="'Remuneração Fixa'"
    [accordion]="true"
    [rows]="fixedRemunerationRows"
    [columns]="columns"
    [showTotal]="true"
    [customTotalValue]="summary?.fixedRemunerationExpense?.totalFixedRemunerationExpense | currency : 'BRL'"
    [loading]="dfdvLoading"
    [skeletonSize]="8"
  >
  </app-dynamic-table>

  <app-dynamic-table
    [headers]="['DESCRIÇÃO', 'VALOR']"
    [icon]="'assets/icons/hand-money.svg'"
    [title]="'Equipe Operacional'"
    [accordion]="true"
    [rows]="operationalTeamRows"
    [columns]="columns"
    [showTotal]="true"
    [customTotalValue]="summary?.operationalTeamExpense?.totalOperationalTeamExpense| currency : 'BRL'"
    [loading]="dfdvLoading"
    [skeletonSize]="13"
  >
  </app-dynamic-table>

  <app-dynamic-table
    [headers]="['DESCRIÇÃO', 'VALOR']"
    [icon]="'assets/icons/hand-money.svg'"
    [title]="'Despesa Pessoal'"
    [accordion]="true"
    [rows]="personalExpenseRows"
    [columns]="columns"
    [showTotal]="true"
    [customTotalValue]="summary?.personalExpense?.totalPersonalExpense | currency : 'BRL'"
    [loading]="dfdvLoading"
    [skeletonSize]="9"
  >
  </app-dynamic-table>

  <app-dynamic-table
    [headers]="['DESCRIÇÃO', 'VALOR']"
    [icon]="'assets/icons/hand-money.svg'"
    [title]="'Despesas Operacionais'"
    [accordion]="true"
    [rows]="operationalExpensesRows"
    [columns]="columns"
    [showTotal]="true"
    [customTotalValue]="summary?.operationalExpense?.totalOperationalExpense | currency : 'BRL'"
    [loading]="dfdvLoading"
    [skeletonSize]="5"
  >
  </app-dynamic-table>

  <header>Custos Variáveis</header>

  <app-dynamic-table
    class="variables-costs-line"
    [headers]="['DESCRIÇÃO', 'VALOR']"
    [icon]="'assets/icons/hand-money.svg'"
    [title]="'Custos Variáveis Linha'"
    [accordion]="true"
    [rows]="lineVariableCostsRows"
    [columns]="columns"
    [showTotal]="true"
    [customTotalValue]="summary?.lineVariableCostExpense?.totalLineVariableCostExpense | currency : 'BRL'"
    [loading]="dfdvLoading"
    [skeletonSize]="8"
  >
  </app-dynamic-table>
</div>
