<div class="container">
  <section>
    <header>
      <app-sumarize-header
        currentPageTitle="Impostos"
        [errorMessage]="hasErrorMessage"
      ></app-sumarize-header>
    </header>
    <div class="card-container">
      <app-card
        icon="../../../../../assets/icons/Money-Icon.svg"
        title="{{ 'Impostos' }}"
        [accordion]="true"
      >
        <div body *ngIf="isLoading" class="skeleton" body>
          <app-skeleton-input [input]="8"></app-skeleton-input>
        </div>
        <div class="tax-container" body *ngIf="!isLoading">
          <div class="input-container">
            <app-input
              label="COFINS"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.cofins"
              [mask]="eMaskType.money"
              [showSideIcon]="true"
              [disabled]="shouldDisableFields"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            >
              <app-tooltip [top]="true">
                <div class="tooltip-info">
                  <span class="text"
                    ><b>COFINS:</b> Contribuição para o Financiamento da
                    Seguridade Social</span
                  >
                </div>
              </app-tooltip>
            </app-input>
            <app-input
              label="PIS"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.pis"
              [mask]="eMaskType.money"
              [showSideIcon]="true"
              [disabled]="shouldDisableFields"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            >
              <app-tooltip [top]="true">
                <div class="tooltip-info">
                  <span class="text">
                    <b>PIS:</b> Programa de Integração Social
                  </span>
                </div>
              </app-tooltip>
            </app-input>
            <app-input
              label="ISS"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.iss"
              [mask]="eMaskType.money"
              [disabled]="shouldDisableFields"
              [showSideIcon]="true"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            >
              <app-tooltip [top]="true">
                <div class="tooltip-info">
                  <span class="text"><b>ISS:</b> Imposto Sobre Serviços</span>
                </div>
              </app-tooltip>
            </app-input>
            <app-input
              label="ICMS"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.icms"
              [mask]="eMaskType.money"
              [showSideIcon]="true"
              [disabled]="shouldDisableFields"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            >
              <app-tooltip [top]="true">
                <div class="tooltip-info">
                  <span class="text">
                    <b>ICMS:</b> Imposto sobre Circulação de Mercadorias e
                    Prestação de Serviços de Transporte Interestadual e
                    Intermunicipal e de Comunicação
                  </span>
                </div>
              </app-tooltip>
            </app-input>
            <app-input
              label="ICMS s/ Complemento"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.icmsWithoutComplement"
              [mask]="eMaskType.money"
              [showSideIcon]="true"
              [disabled]="shouldDisableFields"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            ></app-input>
            <app-input
              label="Crédito ICMS s/ Insumos"
              [placeholder]="'0,00'"
              errorMessage="{{ errorMessage }}"
              [control]="form.controls.icmsCreditWithoutInput"
              [mask]="eMaskType.money"
              [disabled]="shouldDisableFields"
              [showSideIcon]="true"
              sideIconLabel="%"
              [sideIconType]="eSideIconType.text"
              (updateValue)="updateDFDVStore()"
            ></app-input>
          </div>
          <div class="checkbox-container">
            <div class="checkbox-grid payroll">
              <app-check-box
                [label]="'Oneração da Folha de Pagamento?'"
                [value]="taxCbModel.hasPayrollEncumbrance"
                [disabled]="shouldDisableFields"
                (check)="onCheckItem('hasPayrollEncumbrance', true)"
                (removeCheck)="onCheckItem('hasPayrollEncumbrance', false)"
              ></app-check-box>
              <app-input
                label="Oneração da Folha de Pagamento"
                [placeholder]="'0,00'"
                [disabled]="shouldDisablePayroll()"
                errorMessage="{{ errorMessage }}"
                [control]="form.controls.payrollEncumbrance"
                [mask]="eMaskType.money"
                [showSideIcon]="true"
                sideIconLabel="%"
                [sideIconType]="eSideIconType.text"
                (updateValue)="updateDFDVStore()"
              ></app-input>
            </div>
            <div class="checkbox-grid">
              <app-check-box
                [label]="'Permite Crédito ICMS?'"
                [value]="taxCbModel.isIcmsCreditAllowed"
                [disabled]="shouldDisableFields"
                (check)="onCheckItem('isIcmsCreditAllowed', true)"
                (removeCheck)="onCheckItem('isIcmsCreditAllowed', false)"
              ></app-check-box>
              <app-check-box
                [label]="'Lucro Presumido?'"
                [value]="taxCbModel.hasPresumedProfit"
                [disabled]="shouldDisableFields"
                (check)="onCheckItem('hasPresumedProfit', true)"
                (removeCheck)="onCheckItem('hasPresumedProfit', false)"
              ></app-check-box>
              <app-check-box
                [label]="'Crédito ICMS Presumido?'"
                [value]="taxCbModel.hasPresumedIcmsCredit"
                [disabled]="shouldDisableFields"
                (check)="onCheckItem('hasPresumedIcmsCredit', true)"
                (removeCheck)="onCheckItem('hasPresumedIcmsCredit', false)"
              ></app-check-box>
              <app-check-box
                [label]="'Permite crédito s/ Ativos?'"
                [value]="taxCbModel.isCreditWithoutAssetAllowed"
                [disabled]="shouldDisableFields"
                (check)="onCheckItem('isCreditWithoutAssetAllowed', true)"
                (removeCheck)="
                  onCheckItem('isCreditWithoutAssetAllowed', false)
                "
              ></app-check-box>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </section>
</div>
