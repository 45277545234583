<button
  class="btn"
  [ngClass]="{ disabled: !enabled, right: right }"
  [disabled]="!enabled || loading"
  (click)="handleClick($event)"
>
  <div
    class="btn-loading"
    [ngStyle]="{ visibility: !loading ? 'hidden' : 'visible' }"
  ></div>
  <img
    *ngIf="icon"
    [src]="icon"
    [ngClass]="{ hide: loading, disabled: !enabled, right: right }"
  />
  <span [ngClass]="{ disabled: !enabled, loading: loading }">
    {{ label }}
  </span>
</button>
