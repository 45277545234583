import { SearchModel } from 'src/app/shared/search/model/search.model';
import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent {
  @Input() showModal: boolean = false;
  @Input() searchModel: SearchModel = new SearchModel();
  @Output() outsideClick:EventEmitter<any> = new EventEmitter<any>();

  handleOutiseClick() {
    this.outsideClick.emit();
  }
}
