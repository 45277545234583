<div class="operation-container">
  <section>
    <header>
      <div class="button-container">
        <app-primary-button
          label="Nova Operação"
          icon="../../../../assets/icons/add-common-100.svg"
          [right]="false"
          [color]="ButtonColorEnum.primary"
          [loading]="loadingButton"
          [enabled]="enableButton"
          (click)="goToNewOperation()"
        ></app-primary-button>
      </div>
    </header>
    <div class="card-container">
      <app-card
        title="Operações"
        icon="../../../../assets/icons/hammer-primary.svg"
        [accordion]="true"
        [paddingHorizontal]="false"
      >
        <section actions class="actions">
          <div class="buttons">
            <app-icon-button
              icon="../../../../assets/icons/sync-primary.svg"
              text="Sincronizar"
              [enabled]="true"
              [color]="ButtonColorEnum.primary"
            ></app-icon-button>
          </div>
        </section>
        <section body class="body">
          <div class="list-container">
            <header class="header-content" *ngIf="operations.length > 0">
              <label id="hcdoperation">CD OPERAÇÃO</label>
              <label id="hdescription">DESCRIÇÃO</label>
              <label id="hstatus">STATUS</label>
            </header>
            <div class="list-content">
              <app-operation-detail
                [operations]="operations"
                class="detail"
              ></app-operation-detail>
              <app-operation-card
                [operations]="operations"
                class="card"
              ></app-operation-card>
            </div>
          </div>
        </section>
        <div body class="skeleton" *ngIf="isLoading">
          <app-skeleton-line [line]="5"></app-skeleton-line>
        </div>
        <div
          body
          class="not-found"
          *ngIf="!isLoading && operations.length === 0 && !error"
        >
          <app-not-found></app-not-found>
        </div>
        <div
          body
          class="error"
          *ngIf="!isLoading && operations.length === 0 && error"
        >
          <app-error (reloadCallBack)="reload()"></app-error>
        </div>
      </app-card>
    </div>
  </section>
</div>
