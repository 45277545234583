import { ProfitModel } from '../../profit/models/profit.model';
import { BenefitModel } from '../benefit/models/benefit.model';
import { ChargeModel } from '../charge/models/charges.model';
import { DriversFactorModel } from '../driversFactor/models/driversFactor.model';
import { FleetUpdateModel } from '../fleet/models/fleet-update.model';
import { FleetModel } from '../fleet/models/fleet.model';
import { GeneralCostsModel } from '../general-costs/models/general-costs.model';
import { GetGeneralCostsModel } from '../general-costs/models/get-general-cost.model';
import { InvoicingModel } from '../invoicing/invoicing.model';
import { PlantModel } from '../plant/plant.model';
import { RvParameterModel } from '../rv-parameter/model/rv-parameter.model';
import { ScopeDfdvModel } from '../scope/model/scope-dfdv.model';
import { TaxModel } from '../taxes/models/tax.model';
import {
  CVConsumptionUpdateModel,
  CVMaintenanceModel,
  CVTireModel,
} from '../variable-costs/models/variable-costs.model';
import { DfdvAttachmentModel } from './getDfdv.model';
import { UpdateSupportTeamModel } from './support-team/update-support-team.model';

export class UpdateDfdvModel {
  dfdvId!: number;
  unitName!: string;
  categoryName!: string;
  fantasyName!: string;
  operatorId!: string;
  copyId!: string;
  creationDate!: Date | null;
  startDate!: Date | null;
  endDate!: Date | null;
  version!: number;
  previousVersionId!: number;
  nextVersionId!: number;
  dfdvTypeName!: string;
  dfdvStatusName!: string;

  attachments!: DfdvAttachmentModel[];
  rvParameter!: RvParameterModel;
  driverFactor!: DriversFactorModel;
  plant!: PlantModel;
  generalCosts!: GetGeneralCostsModel;
  profit!: ProfitModel;
  benefit!: BenefitModel;
  charge!: ChargeModel;
  tax!: TaxModel;
  scope!: ScopeDfdvModel;
  cvMaintenance!: CVMaintenanceModel;
  cvConsumption!: CVConsumptionUpdateModel;
  cvTire!: CVTireModel;
  supportTeam!: UpdateSupportTeamModel;
  fleet!: FleetUpdateModel[];
  invoicing!: InvoicingModel;
}
