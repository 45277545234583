<div class="rv-parameters-container">
  <section>
    <app-sumarize-header
      currentPageTitle="Parâmetros"
      [errorMessage]="hasErrorMessage"
    ></app-sumarize-header>

    <app-card
      [accordion]="true"
      [title]="'Parâmetros do Teto Remuneração Variável'"
      [icon]="'../../../../../assets/icons/graphic-common.svg'"
      class="rv-parameters-card"
    >
      <div class="card-body" body>
        <div class="info night-driver-Value-Hour">
          <label class="info-title"> Valor/Hora Motorista Noturno </label>
          <label class="info-value">
            {{ hourlySalaryNightDriver | currency : "BRL" }}
          </label>
        </div>
        <div class="info night-driver-hours">
          <label class="info-title"> Hora </label>
          <label class="info-value"> {{ totalMonthlyHours }} </label>
        </div>
        <div class="info default-driver-Value-Hour">
          <label class="info-title"> Valor/Hora Motorista Padrão </label>
          <label class="info-value">
            {{ hourlySalaryStandardDriver | currency : "BRL" }}
          </label>
        </div>
        <div class="info default-driver-hours">
          <label class="info-title"> Hora </label>
          <label class="info-value">
            {{ totalMonthlyHours }}
          </label>
        </div>
      </div>
    </app-card>

    <app-dynamic-table
      #quantitative
      [headers]="['DESCRIÇÃO', 'HORAS/MÊS']"
      [rows]="quantitativeItems"
      [columns]="columnModel"
      [rowDescriptions]="rowsDescriptionQuantitative"
      [descriptionLabel]="'ITEM'"
      [showTotal]="true"
      [showAverage]="false"
      [icon]="'../../../../../assets/icons/Money-Icon.svg'"
      [title]="'Itens Quantitativos'"
      [showInfoBox]="true"
      [accordion]="true"
      [actions]="showActions"
      [defaultForm]="defaultFormBuilder"
      [labelTotal]="'Parcial Quantitativo'"
      (total)="totalQuantitativeCallback($event)"
      (updateValue)="updateDFDVStore()"
    ></app-dynamic-table>

    <app-dynamic-table
      #qualitative
      [headers]="['DESCRIÇÃO', 'HORAS/MÊS']"
      [rows]="qualitativeItems"
      [columns]="columnModel"
      [rowDescriptions]="rowsDescriptionQualitative"
      [descriptionLabel]="'ITEM'"
      [showTotal]="true"
      [showAverage]="false"
      [icon]="'../../../../../assets/icons/Money-Icon.svg'"
      [title]="'Itens Qualitativos'"
      [showInfoBox]="true"
      [accordion]="true"
      [actions]="showActions"
      [defaultForm]="defaultFormBuilder"
      [labelTotal]="'Parcial Qualitativo'"
      (total)="totalQualitativeCallback($event)"
      (updateValue)="updateDFDVStore()"
    ></app-dynamic-table>

    <app-dynamic-table
      #ceilling
      [headers]="['Quantidade Motoristas ELEGÍVEL À FAIXA']"
      [rows]="ceillingDistributionForDriversForm"
      [columns]="columnModelCeillingDistribution"
      [showTotal]="true"
      [showAverage]="false"
      [showCustomTotal]="true"
      [customLabel]="'Ponderação'"
      [customValue]="
        utils.numberFormatter(ceilingRangeDistributionWeighting, 2, 2) + ' %'
      "
      [totalLeftPadding]="4"
      [icon]="'../../../../../assets/icons/Money-Icon.svg'"
      [title]="'Distribuição do RV para Motoristas'"
      [showInfoBox]="true"
      [accordion]="true"
      [errorMessage]="errorMessagePercent"
      [rowDescriptions]="rowDescriptionCeillingDistribution"
      [descriptionLabel]="'Faixa do Teto do RV'"
      [hasError]="hasErrorPercent"
      (updateValue)="updateDFDVStore()"
    ></app-dynamic-table>
  </section>
</div>
<app-modal
  [showModal]="modalModel.showModal"
  [title]="modalModel.title"
  [description]="modalModel.description"
  [buttonLabel]="modalModel.buttonLabel"
  [imagePath]="modalModel.imagePath"
  (clickButton)="clickModal()"
></app-modal>
