<div class="content">
  <app-infobox [type]="InfoboxTypeEnum.attention">
    <div content>
      <span>É necessario se cadastrar também na plataforma</span>
      <app-buttons
        label="G-Certifica"
        icon="../../../../assets/icons/arrow-slate100.svg"
        [type]="eButtonsType.navigate"
        [enabled]="true"
        [right]="true"
        (clickButton)="onGCertifica()"
      ></app-buttons>
    </div>
  </app-infobox>
  <app-lgpd-info></app-lgpd-info>
  <form *ngIf="!isLoading">
    <div class="input-box">
      <app-input
        label="CNPJ"
        [disabled]="true"
        errorMessage="Informe CNPJ Valido"
        [control]="form.controls.cnpj"
        [type]="eInputType.text"
        [mask]="eInputMask.cnpj"
        [showSideIcon]="true"
        [sideIconLabel]="'../../../assets/icons/user-common-600.svg'"
        [sideIconType]="eSideIconType.image"
      ></app-input>
      <app-input
        label="Razão Social"
        errorMessage="{{ corporateNameError }}"
        [control]="form.controls.corporateName"
        [type]="eInputType.text"
        [flatLoading]="corporateLoading"
        [validated]="corporateNameIsValid"
      ></app-input>
    </div>
    <app-input
      label="Nome Fantasia"
      errorMessage="{{ fantasyNameError }}"
      [control]="form.controls.tradingName"
      [type]="eInputType.text"
      [maxlength]="50"
      [flatLoading]="fantasyLoading"
      [validated]="fantasyNameIsValid"
    ></app-input>
    <div class="input-box">
      <app-input
        label="Número de Inscrição Estadual"
        placeholder="000000000"
        [control]="form.controls.numberRegistrationState"
        [description]="'(opcional)'"
        [mask]="eInputMask.number"
        [max]="999999999"
        [min]="0"
        [showNumberActions]="false"
      ></app-input>
      <app-input
        label="Número de Inscrição Municipal"
        placeholder="00000000000"
        [control]="form.controls.numberRegistrationMunicipal"
        [description]="'(opcional)'"
        [mask]="eInputMask.number"
        [max]="99999999999"
        [min]="0"
        [showNumberActions]="false"
      ></app-input>
    </div>
    <div class="input-box">
      <app-select
        [field]="'Porte da Empresa'"
        errorMessage="Este campo é obrigatório"
        [singleControl]="form.controls.companySize"
        [options]="porteEmpresa"
      ></app-select>
      <app-select
        [field]="'Número de Funcionários'"
        errorMessage="Este campo é obrigatório"
        [singleControl]="form.controls.employeesCount"
        [options]="nFuncionarios"
      ></app-select>
    </div>
  </form>
  <div class="skeleton" *ngIf="isLoading">
    <app-skeleton-input [input]="8"></app-skeleton-input>
  </div>
</div>
