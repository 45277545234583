export class PersonalExpenseModel {
  managementExpense: number = 0;
  operationalSupportExpense: number = 0;
  maintenanceAndWarehouseExpense: number = 0;
  hrAndSsmaExpense: number = 0;
  adminAndFinancialExpense: number = 0;
  totalUniformExpense: number = 0;
  totalBenefitExpense: number = 0;
  totalPersonalExpense: number = 0;
}
