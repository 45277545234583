import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils/utils';
import { GetUnitModel } from 'src/app/services/unit/models/get-unit-model';
import { StatusColorEnum } from 'src/app/shared/enums/StatusColor';

@Component({
  selector: 'app-unit-list-card',
  templateUrl: './unit-list-card.component.html',
  styleUrls: ['./unit-list-card.component.scss'],
})
export class UnitListCardComponent {
  @Input() unit! : GetUnitModel;

  public StatusColorEnum = StatusColorEnum;
  
  constructor(
    private router: Router,
    public util : Utils,
  ) { }

  handleDataClick(id: number) {
    this.router.navigate(['/admin/unit/', id]);
  }
}
